import moment from "moment";
import {
  SET_STAT_TYPE,
  SET_STAT_PARAMS,
  SET_STAT_TRAN_TYPES,
  SET_STAT_YEAR,
  SET_STAT_LOADING,
  SET_STAT_DATA,
} from "./stat_types";
import { MainTable } from "api";
import {
  HSLToHex,
  getCurrencyFormatter,
  getNumberFormatter,
} from "pages/Panels/Statistics/helpers";
import { API_SIMPLE_DATE_FORMAT } from "utils/constants/time";
import { MONTHS, QUARTERS } from "../../utils/constants/time";
import { YEAR_AMOUNT } from "pages/Panels/Statistics/charts/TotalsByYears/config";

export const setStatType = (type) => ({
  type: SET_STAT_TYPE,
  payload: type,
});

export const setStatParams = (params) => ({
  type: SET_STAT_PARAMS,
  payload: params,
});

export const setStatTranTypes = (tranTypes) => ({
  type: SET_STAT_TRAN_TYPES,
  payload: tranTypes,
});

export const setStatYear = (year) => ({
  type: SET_STAT_YEAR,
  payload: year,
});

const setStatLoading = (statType, isLoading) => ({
  type: SET_STAT_LOADING,
  payload: {
    statType,
    isLoading,
  },
});

const setStatData = (statType, data) => ({
  type: SET_STAT_DATA,
  payload: {
    statType,
    data,
  },
});

export const fetchYearData = (type, year) => (dispatch, getState) => {
  const dateFrom = moment(new Date(year, 0, 1)).format(API_SIMPLE_DATE_FORMAT);
  const dateTo = moment(new Date(year, 11, 31, 23)).format(
    API_SIMPLE_DATE_FORMAT
  );
  const countFormatter = getNumberFormatter(0);
  const amountFormatter = getCurrencyFormatter(2);

  dispatch(setStatLoading(type, true));

  new MainTable()
    .get(dateFrom, dateTo)
    .then(({ terminals }) => {
      const state = getState();
      const params = state.statistics.params[state.statistics.type];

      const allTerminals = terminals.filter(({ ptname }) => ptname !== "Total");
      const terminalOptions = allTerminals.map(
        ({
          ptname,
          ptdescription,
          ptdescription_manual,
          ptaddress,
          transactions_count,
          total_amount,
        }) => ({
          id: ptname,
          description: ptdescription,
          address: ptaddress || ptdescription_manual,
          amount: amountFormatter.format(total_amount),
          count: countFormatter.format(transactions_count),
          disabled: transactions_count === 0,
        })
      );
      const filteredTerminals = allTerminals.filter(
        ({ transactions_count }) => transactions_count > 0
      );

      const data = filteredTerminals.map(
        (
          {
            ptname: ptName,
            ptdescription: ptDescription,
            ptdescription_manual: ptDescriptionManual,
            ptaddress: ptAddress,
            total_amount: totalAmount,
            transactions_count: totalCount,
          },
          i
        ) => {
          const hue = (360 / filteredTerminals.length) * i;
          const color = HSLToHex(hue, 100, hue > 30 && hue < 90 ? 45 : 50);

          return {
            id: ptName,
            description: ptDescription,
            address: ptAddress || ptDescriptionManual,
            amount: totalAmount.toFixed(2),
            value: totalCount,
            color,
          };
        }
      );

      const selectedTerminals = data.map(({ id }) => id);

      dispatch(
        setStatParams({
          ...params,
          selectedTerminals,
          terminals: terminalOptions,
        })
      );
      dispatch(setStatData(type, data));
    })
    .catch((e) => console.log(e))
    .finally(() => {
      dispatch(setStatLoading(type, false));
    });
};

const getMonthPeriods = (year) =>
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => ({
    from: new Date(year, month, 1),
    to: new Date(year, month === 11 ? month : month + 1, month === 11 ? 31 : 0),
  }));

const getQuarterPeriods = (year) =>
  [
    [0, 2],
    [3, 5],
    [6, 8],
    [9, 11],
  ].map(([first, last]) => ({
    from: new Date(year, first, 1),
    to: new Date(year, last === 11 ? last : last + 1, last === 11 ? 31 : 0),
  }));

export const fetchYearByMonthData = (type, year, quarterly) => (dispatch) => {
  dispatch(setStatLoading(type, true));

  const api = new MainTable();
  const periods = quarterly ? getQuarterPeriods(year) : getMonthPeriods(year);
  const NAMES = quarterly ? QUARTERS : MONTHS;

  const statistics = periods.map((item, i) => {
    const { from, to } = item;
    const dateFrom = moment(from).format(API_SIMPLE_DATE_FORMAT);
    const dateTo = moment(to).format(API_SIMPLE_DATE_FORMAT);

    return api.get(dateFrom, dateTo).then(({ terminals }) => {
      const totals = terminals.find(({ ptname }) => ptname === "Total");

      const {
        card_amount: cardAmount,
        card_refund: cardRefund,
        cash_amount: cashAmount,
        cash_refund: cashRefund,
        cashless_amount: cashlessAmount,
        cashless_refund: cashlessRefund,
        qr_amount: qrAmount,
        qr_refund: qrRefund,
      } = totals;

      return {
        name: NAMES[i],
        card: cardAmount - cardRefund,
        cash: cashAmount - cashRefund,
        cashless: cashlessAmount - cashlessRefund,
        qr: qrAmount - qrRefund,
      };
    });
  });

  Promise.all(statistics)
    .then((data) => {
      dispatch(setStatData(type, data));
    })
    .catch((e) => console.log(e))
    .finally(() => {
      dispatch(setStatLoading(type, false));
    });
};

const fetchMonthsThroughYearsData =
  (type, beginYear, endYear) => (dispatch) => {
    dispatch(setStatLoading(type, true));

    const api = new MainTable();
    const years = [...Array(endYear - beginYear + 1)].map(
      (_, i) => beginYear + i
    );
    const statistics = [];

    for (const year of years) {
      const periods = getMonthPeriods(year);
      const yearStatistics = periods.map((item, i) => {
        const { from, to } = item;
        const dateFrom = moment(from).format(API_SIMPLE_DATE_FORMAT);
        const dateTo = moment(to).format(API_SIMPLE_DATE_FORMAT);

        return api.get(dateFrom, dateTo).then(({ terminals }) => {
          const totals = terminals.find(({ ptname }) => ptname === "Total");

          return { name: MONTHS[i], year, total: totals.total_amount };
        });
      });

      statistics.push(...yearStatistics);
    }

    Promise.all(statistics)
      .then((data) => {
        const yearStat = [];

        for (const month of MONTHS) {
          const monthStat = data
            .filter(({ name }) => name === month)
            .reduce(
              (a, c) => {
                const { year, total } = c;

                a[year] = total;

                return a;
              },
              { name: month }
            );

          yearStat.push(monthStat);
        }

        dispatch(setStatData(type, yearStat));
      })
      .catch((e) => console.log(e))
      .finally(() => {
        dispatch(setStatLoading(type, false));
      });
  };

const fetchQuartersThroughYearsData =
  (type, beginYear, endYear) => (dispatch) => {
    dispatch(setStatLoading(type, true));

    const api = new MainTable();
    const years = [...Array(endYear - beginYear + 1)].map(
      (_, i) => beginYear + i
    );
    const statistics = [];

    for (const year of years) {
      const periods = getQuarterPeriods(year);
      const yearStatistics = periods.map((item, i) => {
        const { from, to } = item;
        const dateFrom = moment(from).format(API_SIMPLE_DATE_FORMAT);
        const dateTo = moment(to).format(API_SIMPLE_DATE_FORMAT);

        return api.get(dateFrom, dateTo).then(({ terminals }) => {
          const totals = terminals.find(({ ptname }) => ptname === "Total");

          return { name: QUARTERS[i], year, total: totals.total_amount };
        });
      });

      statistics.push(...yearStatistics);
    }

    Promise.all(statistics)
      .then((data) => {
        const yearStat = [];

        for (const quarter of QUARTERS) {
          const quarterStat = data
            .filter(({ name }) => name === quarter)
            .reduce(
              (a, c) => {
                const { year, total } = c;

                a[year] = total;

                return a;
              },
              { name: quarter }
            );

          yearStat.push(quarterStat);
        }

        dispatch(setStatData(type, yearStat));
      })
      .catch((e) => console.log(e))
      .finally(() => {
        dispatch(setStatLoading(type, false));
      });
  };

export const fetchStatByYears = (type, beginYear, endYear, quarterly) =>
  quarterly
    ? fetchQuartersThroughYearsData(type, beginYear, endYear)
    : fetchMonthsThroughYearsData(type, beginYear, endYear);

export const fetchByYearData = (type, beginYear, endYear) => (dispatch) => {
  dispatch(setStatLoading(type, true));

  const api = new MainTable();
  const years = [...Array(endYear - beginYear + 1)].map(
    (_, i) => beginYear + i
  );
  const periods = years.map((year) => ({
    from: new Date(year, 0, 1),
    to: new Date(year, 11, 31),
  }));

  const statistics = periods.map((item, i) => {
    const { from, to } = item;
    const dateFrom = moment(from).format(API_SIMPLE_DATE_FORMAT);
    const dateTo = moment(to).format(API_SIMPLE_DATE_FORMAT);

    return api.get(dateFrom, dateTo).then(({ terminals }) => {
      const totals = terminals.find(({ ptname }) => ptname === "Total");
      const { total_amount: total } = totals;

      return {
        name: years[i],
        total,
        [YEAR_AMOUNT]: total,
      };
    });
  });

  Promise.all(statistics)
    .then((data) => {
      dispatch(setStatData(type, data));
    })
    .catch((e) => console.log(e))
    .finally(() => {
      dispatch(setStatLoading(type, false));
    });
};
