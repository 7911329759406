import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TRAN_NAMES } from "./config";
import {
  fetchYearByMonthData,
  setStatTranTypes,
} from "store/statistics/stat_actions";
import {
  selectCurrentStatisticsData,
  selectCurrentStatisticsParams,
  selectStatTranTypes,
} from "store/statistics/stat_slicer";
import { DEFAULT_TOTALS, STAT_TYPES } from "../../constants";

const useChartState = () => {
  const chartParams = useSelector(selectCurrentStatisticsParams);
  const { data, isLoading } = useSelector(selectCurrentStatisticsData);
  const [mappedData, setMappedData] = useState(data);
  const [chartTotals, setChartTotals] = useState(DEFAULT_TOTALS);
  const tranTypes = useSelector(selectStatTranTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    const { year, quarterly } = chartParams;

    dispatch(fetchYearByMonthData(STAT_TYPES.BY_TRAN_TYPE, year, quarterly));
  }, [chartParams.year, chartParams.quarterly]);

  useEffect(() => {
    const mappedData = data.map((item) =>
      Object.fromEntries(
        Object.entries(item).filter(
          ([key]) => (key in tranTypes && tranTypes[key]) || !(key in tranTypes)
        )
      )
    );

    const totals = mappedData.map(({ name, card, cash, cashless, qr }) => ({
      name,
      total:
        (card ? card : 0) +
        (cash ? cash : 0) +
        (cashless ? cashless : 0) +
        (qr ? qr : 0),
    }));

    const total = totals.reduce((a, c) => a + c.total, 0);

    setMappedData(
      mappedData.map((item) => {
        let mappedItem = {
          ...item,
        };

        for (const name of Object.keys(TRAN_NAMES)) {
          if (name in item) {
            mappedItem[TRAN_NAMES[name]] = item[name];
          }
        }

        return mappedItem;
      })
    );

    setChartTotals({ total, totals });
  }, [data, tranTypes]);

  const onCheckChartOption = (name) =>
    dispatch(
      setStatTranTypes({
        ...tranTypes,
        [name]: !tranTypes[name],
      })
    );

  return {
    chartTotals,
    chartState: chartParams,
    onCheckChartOption,
    data: mappedData,
    isLoading,
  };
};

export default useChartState;
