import { DROP_ALERT_STATE, SET_ALERT_STATE } from "./alert_types";

export const setAlertState = (newAlertState) => ({
  type: SET_ALERT_STATE,
  payload: newAlertState,
});

export const dropAlertState = () => ({
  type: DROP_ALERT_STATE,
});
