import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useHeader } from "store/header/header.context";
import ChangeTitle from "utils/helpers/changetitle.helpers";
import {
  NewsContainer,
  ButtonsRow,
  ExportButton as ToggleEditButton,
} from "./News.panel.styles";
import { MdEdit, MdOutlineEdit } from "react-icons/md";

// components
import Preloader from "components/additional/PreLoader/Preloader";
import NewsTable from "./components/NewsTable/NewsTable";

const News = () => {
  const { state: news } = useSelector((state) => state.news_table);
  const header = useHeader();

  const [editMode, setEditMode] = useState(false);
  const [selectedNews, setSelectedNews] = useState([]);
  const [newsLocalLoading, setNewsLocalLoading] = useState(false);
  const [isNewsTableFull, setNewsTableFull] = useState(false);
  const [isNewsRequestWorking, setIsNewsRequestWorking] = useState(false);

  useEffect(() => {
    ChangeTitle("Повідомлення", header.dispatch);
  }, []);

  const onToggleEditMode = () => setEditMode((mode) => !mode);

  return (
    <>
      <NewsContainer>
        <ButtonsRow>
          {newsLocalLoading && <Preloader style={{ marginTop: "-20px" }} />}
          <ToggleEditButton
            onClick={onToggleEditMode}
            className={editMode ? "edit-on" : "edit-off"}
          >
            {editMode ? (
              <MdEdit className="toggle-edit" />
            ) : (
              <MdOutlineEdit className="toggle-edit" />
            )}
          </ToggleEditButton>
        </ButtonsRow>

        <NewsTable
          news={news}
          localLoading={newsLocalLoading}
          setLocalLoading={setNewsLocalLoading}
          isTableFull={isNewsTableFull}
          setTableFull={setNewsTableFull}
          isRequestWorking={isNewsRequestWorking}
          setIsRequestWorking={setIsNewsRequestWorking}
          selectedNews={selectedNews}
          setSelectedNews={setSelectedNews}
          editable={editMode}
        />
      </NewsContainer>
    </>
  );
};

export default News;
