export const generateFiscalTransactionStatus = (transaction) => {
  if (!transaction) return 0;

  let resultStatus = transaction.statusTransaction || 0;

  if (transaction.statusTransaction === 4) {
    resultStatus = "R";
  } else if (transaction.statusTransaction === 0) {
    resultStatus = "P";
  } else if (transaction.transactionState === 3) {
    resultStatus = "B";
  } else if (transaction.forceStatus === 1) {
    resultStatus = "C";
  } else if (
    !!transaction.reversalCreated &&
    transaction.statusTransaction === 1
  ) {
    resultStatus = 7;
  } else if (transaction.operation === "cash_inkass") {
    resultStatus = 6;
  } else if (transaction.operation === "cash_podkrep") {
    resultStatus = 5;
  } else if (transaction.refundAmount !== 0) {
    resultStatus = 4;
  } else if (
    transaction.operation === "RRN" ||
    transaction.operation === "cashless_refund" ||
    transaction.operation === "cash_refund" ||
    (transaction.operation === "qr_refund" &&
      transaction.statusTransaction === 1)
  ) {
    resultStatus = 3;
  }

  return resultStatus;
};
