import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useHeader } from "store/header/header.context";
import useInfo from "./useInfo";
import {
  fetchServerTerminals,
  loadStateServerTerminal,
  setGlobalLoading,
  setLocalTerminal,
  setItem,
} from "store/main_table/main_table.actions";
import { selectUserProfile } from "store/user/user_slicer";

import { MainTable, CashAPI, FiskalTable } from "api";

import ChangeTitle, {
  ChangeButton,
  ChangeLeft,
  SetMobileBackButton,
  SetBound,
} from "utils/helpers/changetitle.helpers";

import { validateDisablePRRO } from "api/global-requests";
import { setAlertState, dropAlertState } from "store/alert/alert_actions";
import { TERMINALS_ROUTE } from "utils/constants/routes.constants";
import { INITIAL_DAY_SETTINGS_STATE } from "../../FiscalDaySettings/constants";
import { getDaySettingsService, saveDaySettingService } from "../service";
import {
  checkIfAllShiftTransactionsFiscalOK,
  getOpenShift,
} from "pages/Panels/Fiscal/helpers/fiscalHelper";

const MAX_LINES = 6;
const MAX_ROW_LENGHT = 35;
const IS_MERCHANT_ID_READONLY = false;

const makeDefaultCaption = (
  isMerchantIdReadOnly,
  merchantID,
  terminalDescription
) => {
  return isMerchantIdReadOnly
    ? terminalDescription
    : [terminalDescription, `ПН: ${merchantID}`]
        .filter((item) => item)
        .join("\n");
};

const useTerminalDetails = ({
  state,
  allTerminals,
  commentMandatory,
  setCommentMandatory,
  //paymentTemplate data
  clientPaymentTemplates,
  clientPaymentTemplate,
  setClientPaymentTemplate,
  setErrorMessage,
  //QR data
  isEnabledQR,
  setIsEnabledQR,
  eCommTerminals,
  allECommTerminals,
  selectedTerminal,
  setSelectedTerminal,
  vtErrorMessage,
  setVtErrorMessage,
}) => {
  const showInfo = useInfo();
  const dispatch = useDispatch();
  const history = useHistory();
  const server = new MainTable();
  const dispatchHeader = useHeader().dispatch;
  const userData = JSON.parse(localStorage.getItem("profile"));
  const params = useParams();

  const userProfile = useSelector(selectUserProfile);
  const { merchant_name: merchantName, unp: merchantID } = userProfile || {};

  const [isEveryday, setIsEveryday] = useState(
    state?.settl_time ? +state.settl_time.slice(0, 2) <= 24 : false
  );
  const [time, setTime] = useState(
    isEveryday ? state.settl_time.slice(0, 2) : "24"
  );

  const [isShowPPO, setIsShowPPO] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState(state?.ptdescription_manual || "");
  const [initialPpo, setInitialPpo] = useState(state?.ppo);
  const [ppo, setPPO] = useState(state?.ppo);
  const [ppoServer, setPPOServer] = useState(state?.ppo);
  const [cashBalance, setCashBalance] = useState(null);
  const [tipsActivated, setTipsActivated] = useState(
    state ? state.tips_enabled : false
  );
  const [isHaveOpenFiscalDay, setIsHaveOpenFiscalDay] = useState(false);
  const [fiscalSettingsOpened, setFiscalSettingsOpened] = useState(false);

  const [describe, setDescribe] = useState(
    state?.ptdescription || state?.ptdesctiption || ""
  );
  const [id, setId] = useState(state?.ptname || "");
  const [receipt, setReceipt] = useState(state?.receipt_caption || "");
  const [footer, setFooter] = useState(state?.receipt_footer || "");
  const [receiptScale, setReceiptScale] = useState(state?.receipt_scale || "0");

  const [turnOffTerminalModalIsActive, setTurnOffTerminalModalIsActive] =
    useState(false);
  const [daySettings, setDaySettings] = useState({
    ...JSON.parse(JSON.stringify(INITIAL_DAY_SETTINGS_STATE)),
    autoFdayClose: Boolean(ppo),
  });
  const [enableCash, setEnableCash] = useState(state?.enable_cash);
  const [enableCashless, setEnableCashless] = useState(state?.enable_cashless);
  const [cashRound, setCashRound] = useState(state?.cash_round_enabled);
  const location = useLocation();
  const storedPaymentTemplateId = state?.client_payment_template_id;
  const isEComm = state?.isEComm;
  const clientPaymentTemplateId = clientPaymentTemplate?.value;

  useEffect(() => {
    if (!state && allTerminals.length > 0) {
      return history.push(location.pathname.replace(`/${params.id}`, ""));
    }

    setIsShowPPO(userData.fiskal && userData.global_fiskal_enabled);

    if (!state?.ptname) {
      dispatch(fetchServerTerminals(undefined, true));
    } else {
      if (!(ppo || ppoServer)) {
        dispatch(setGlobalLoading(true));

        server
          .getRRO(state.ptname)
          .then((res) => {
            if (res && typeof res === "string") {
              setPPO(res);
              setInitialPpo(res);
              setPPOServer(res);
            } else {
              setPPO("");
              setInitialPpo("");
              setPPOServer(null);
            }
          })
          .finally(() => dispatch(setGlobalLoading(false)));
      }

      setIsEveryday(
        state?.settl_time ? +state.settl_time.slice(0, 2) <= 24 : false
      );
      setTime(isEveryday ? state.settl_time.slice(0, 2) : "24");

      setName(state?.ptdescription_manual || "");
      setTipsActivated(Boolean(state?.tips_enabled));
      console.log(state);
      setDescribe(state?.ptdescription || state?.ptdesctiption || "");
      setId(state ? state.ptname : "");
      setReceipt(state?.receipt_caption || "");
      setFooter(state?.receipt_footer || "");
      setReceiptScale(state?.receipt_scale || "0");
      setEnableCash(state?.enable_cash);
      setCashRound(state?.cash_round_enabled);
      setEnableCashless(state?.enable_cashless);
      setCommentMandatory(state?.comment_mandatory);
    }

    let terminalStatusLabel;

    if (isEComm) {
      terminalStatusLabel = state.active ? "Активний" : "Заблокований";
    } else {
      if (state?.status_id > 1) {
        terminalStatusLabel = "Заблокований";
      } else if (state?.status_id === 1 && !state?.binded) {
        terminalStatusLabel = "Вільний";
      } else if (state?.status_id === 1 && !!state?.binded) {
        terminalStatusLabel = "Підключений";
      }
    }

    ChangeTitle(
      state?.ptname ? `Термінал ID:${state.ptname}` : "",
      dispatchHeader
    );
    ChangeLeft(dispatchHeader);
    ChangeButton(terminalStatusLabel, dispatchHeader);
    SetMobileBackButton(true, dispatchHeader);
    SetBound(isEComm ? state.active : Boolean(state?.binded), dispatchHeader);
  }, [state, allTerminals]);

  useEffect(() => {
    if (state?.ptname && !isEdit) {
      getDaySettings();
    }
  }, [state?.ptname, isEdit]);

  // useEffect(() => {
  //   if (ppo) {
  //     getDaySettings();
  //   } else {
  //     setDaySettings((settings) => ({ ...settings, autoFdayClose: false }));
  //   }
  // }, [ppo]);

  useEffect(() => {
    if (ppoServer) {
      getDaySettings();
    } else {
      setDaySettings((settings) => ({ ...settings, autoFdayClose: false }));
    }
  }, [ppoServer]);

  function editFalse() {
    setName(state.ptdescription_manual || "");
    setIsEdit(false);
    setIsEveryday(+state.settl_time.slice(0, 2) <= 24);
    setTime(isEveryday ? state.settl_time.slice(0, 2) : "24");
    setTipsActivated(Boolean(state.tips_enabled));
    setDescribe(state.ptdescription || state.ptdesctiption || "");
    setId(state.ptname || "");
    setReceipt(state.receipt_caption || "");
    setFooter(state.receipt_footer || "");
    setReceiptScale(state.receipt_scale || "0");
    setEnableCash(state.enable_cash);
    setCashRound(state.cash_round_enabled);
    setEnableCashless(state.enable_cashless);
    setPPO(ppoServer);
    setCommentMandatory(state.comment_mandatory);
    setClientPaymentTemplate(
      clientPaymentTemplates.find(
        ({ value }) => value === storedPaymentTemplateId
      )
    );
    setErrorMessage(null);
    setVtErrorMessage(null);
    setIsEnabledQR(Boolean(state.enable_qr));
    setSelectedTerminal(
      state.v_terminal
        ? allECommTerminals.find(({ value }) => value === state.v_terminal)
        : null
    );
  }

  function serverRemovePPO() {
    return server.removeRRO(ppoServer).then((res) => {
      if (res && +res.errorCode !== 0) {
        dispatch(
          setAlertState({
            show: true,
            type: "block",
            title: "Помилка",
            subtitle: res.message,
            fnClose: () => dispatch(dropAlertState()),
          })
        );
        setPPO(ppoServer);
        setInitialPpo(ppoServer);
      } else {
        setIsEdit(false);
      }
    });
  }

  function serverAddPPO() {
    return server
      .addPPO({
        terminalId: state.ptname,
        rroId: ppo && ppo !== "" ? ppo : null,
        clientId: userData.client_id,
      })
      .then((res) => {
        if (res && +res.error_code !== 0) {
          if (res.message) {
            dispatch(
              setAlertState({
                show: true,
                type: "block",
                title: "Помилка",
                subtitle: res.message,
                fnClose: () => dispatch(dropAlertState()),
              })
            );
          }
          setPPO(ppoServer);
          setInitialPpo(ppoServer);
        } else {
          setIsEdit(false);
        }
      });
  }

  async function getDaySettings() {
    if (!state) return;

    const daySettings = await getDaySettingsService({
      terminalId: state.ptname,
    });

    const settings =
      daySettings || JSON.parse(JSON.stringify(INITIAL_DAY_SETTINGS_STATE));

    if (!ppo) {
      settings.autoFdayClose = false;
    }

    setDaySettings(settings);
  }

  async function saveDaySettings(updatedDaySettings) {
    setFiscalSettingsOpened(false);

    dispatch(setGlobalLoading(true));

    const saveResult = await saveDaySettingService({
      terminalId: state.ptname,
      daySettings: updatedDaySettings,
    });

    if (Boolean(saveResult)) {
      await getDaySettings();
    } else {
      dispatch(
        setAlertState({
          show: true,
          type: "block",
          title: "Помилка",
          subtitle: "Налаштування автозакриття не збережено",
          fnClose: () => dispatch(dropAlertState()),
        })
      );
    }

    dispatch(setGlobalLoading(false));
  }

  async function editTrue() {
    let hasError = false;

    if (commentMandatory && !clientPaymentTemplateId) {
      hasError = true;
      setErrorMessage("обов'язково для заповнення");
    }

    if (isEnabledQR && !selectedTerminal) {
      hasError = true;
      setVtErrorMessage("обов'язково для заповнення");
    }

    if (hasError) return;

    // check if user doesnt have active fiskal day
    dispatch(setGlobalLoading(true));

    if (ppo !== initialPpo) {
      const checkResult = await validateDisablePRRO({ ptname: id });

      if (!checkResult.success) {
        dispatch(
          setAlertState({
            show: true,
            type: "block",
            title: checkResult.titleMessage,
            subtitle: checkResult.subtitleMessage,
            fnClose: () => dispatch(dropAlertState()),
          })
        );
        return dispatch(setGlobalLoading(false));
      }
    }

    const businessTime = isEveryday ? time : null;
    const v_merchant = isEnabledQR ? selectedTerminal?.v_ptname || null : null;
    const v_terminal = isEnabledQR ? selectedTerminal?.v_ptname || null : null;

    const result = await server.setTerminal(
      id,
      name,
      businessTime,
      receipt,
      footer,
      tipsActivated,
      receiptScale,
      enableCash,
      enableCashless,
      cashRound,
      commentMandatory,
      clientPaymentTemplateId,
      makeDefaultCaption(
        IS_MERCHANT_ID_READONLY,
        merchantID,
        state?.ptdescription
      ),
      isEnabledQR,
      v_merchant,
      v_terminal
    );

    const { ErrorCode, ErrorMessage } = result;
    let changePPOMessage = "";

    if (ErrorCode === 0) {
      const newTerminals = allTerminals.map((terminalGroup) => ({
        ...terminalGroup,
        items: terminalGroup.items.map((terminal) => {
          if (terminal.ptname === result?.ptname) {
            const {
              receipt_caption,
              receipt_footer,
              receipt_scale,
              enable_cash,
              enable_cashless,
              enable_qr,
              cash_round_enabled,
              comment_mandatory,
              client_payment_template_id,
              v_merchant,
              v_terminal,
            } = result;
            const updatedTerminal = {
              ...terminal,
              receipt_caption,
              receipt_caption_default: makeDefaultCaption(
                IS_MERCHANT_ID_READONLY,
                merchantID,
                state?.ptdescription
              ),
              receipt_footer,
              ptdescription_manual:
                result.ptdescription || result.ptdesctiption,
              tips_enabled: tipsActivated,
              receipt_scale,
              enable_cash,
              enable_cashless,
              cash_round_enabled,
              comment_mandatory,
              client_payment_template_id,
              template_name: clientPaymentTemplate?.template_name,
              enable_qr,
              v_merchant,
              v_terminal,
              ppo,
            };

            dispatch(setItem(updatedTerminal));

            return updatedTerminal;
          }

          return terminal;
        }),
      }));

      dispatch(setLocalTerminal(newTerminals));
      setIsEdit(false);

      if (isShowPPO) {
        if (ppo && !ppoServer) {
          try {
            await serverAddPPO();
            setPPOServer(ppo);
          } catch (error) {
            changePPOMessage = error.message;
            console.log(error);
          }
        } else if (!ppo && ppoServer) {
          try {
            await serverRemovePPO();
            setPPOServer("");
          } catch (error) {
            changePPOMessage = error.message;
            console.log(error);
          }
        } else if (ppo && ppo !== ppoServer) {
          try {
            await serverRemovePPO();
            await serverAddPPO();
            setPPOServer(ppo);
          } catch (error) {
            changePPOMessage = error.message;
            console.log(error);
          }
        }
      }

      dispatch(
        setAlertState(
          changePPOMessage
            ? {
                show: true,
                type: "done",
                iconType: "remove",
                title: "Зміни збережено з помилкою",
                subtitle: changePPOMessage,
                fnClose: () => dispatch(dropAlertState()),
              }
            : {
                show: true,
                type: "done",
                title: "Зміни успішно збережено",
                fnClose: () => dispatch(dropAlertState()),
              }
        )
      );
    } else {
      dispatch(
        setAlertState({
          show: true,
          type: "block",
          title: "Помилка!",
          subtitle:
            ErrorMessage || "Оновлені властивості терміналу не збережено",
          fnClose: () => dispatch(dropAlertState()),
        })
      );
    }

    dispatch(setGlobalLoading(false));
  }

  const errorHandler = (errorTitle, errorSubtitle) =>
    dispatch(
      setAlertState({
        show: true,
        type: "block",
        title: errorTitle,
        subtitle: errorSubtitle,
        fnClose: () => dispatch(dropAlertState()),
      })
    );

  const checkNotFiscalTransactions = async () => {
    const shift = await getOpenShift(id);

    if (!shift) {
      return dispatch(
        setAlertState({
          show: true,
          type: "block",
          title: `Помилка ідентифікації відкритої зміни на терміналі ${id}`,
          subtitle: "",
          fnClose: () => dispatch(dropAlertState()),
        })
      );
    }

    const checkResult = await checkIfAllShiftTransactionsFiscalOK(shift);

    const { errorCode, reviseTranList } = checkResult;

    if (errorCode === 0) {
      return reviseTranList.length === 0;
    } else {
      return undefined;
    }
  };

  const detachTerminal = () => {
    dispatch(
      setAlertState({
        show: true,
        type: "remove",
        title:
          "Ви впевнені, що бажаєте відключити термінал ОщадPAY від смартфона?",
        subtitle:
          "Відключений термінал можна підключити до іншого смартфона або знову підключити до цього смартфона",
        fnSubmit: blockTerminal,
        cancelButtonLabel: "Не відключати термінал",
        acceptButtonLabel: "Відключити термінал",
        fnClose: () => dispatch(dropAlertState()),
        height: "450px",
        width: "540px",
      })
    );
  };

  async function setupBlockTerminal() {
    // check if user doesnt have active fiskal day
    dispatch(setGlobalLoading(true));

    const checkResult = await validateDisablePRRO({ ptname: id });

    dispatch(setGlobalLoading(false));

    if (checkResult.error) {
      const { titleMessage, subtitleMessage } = checkResult;

      return errorHandler(titleMessage, subtitleMessage);
    }

    if (!checkResult.success) {
      dispatch(setGlobalLoading(true));

      const cashBalance = await CashAPI.getFiscalTerminalCash({
        terminalId: state.ptname,
        errorHandler,
      });

      dispatch(setGlobalLoading(false));

      if (cashBalance !== null) {
        setIsHaveOpenFiscalDay(true);
        setCashBalance(cashBalance);
        setTurnOffTerminalModalIsActive(true);
      }

      return;
    } else {
      dispatch(setGlobalLoading(true));

      const cashBalance = await CashAPI.getFiscalTerminalCash({
        terminalId: state.ptname,
        errorHandler,
      });

      dispatch(setGlobalLoading(false));

      if (cashBalance === null) {
        return;
      } else {
        if (cashBalance && +cashBalance !== 0) {
          setIsHaveOpenFiscalDay(false);
          setCashBalance(cashBalance);
          setTurnOffTerminalModalIsActive(true);
          return;
        }
      }
    }

    detachTerminal();
  }

  async function blockTerminal() {
    dispatch(dropAlertState());
    dispatch(setGlobalLoading(true));

    const result = await server.blockTerminal(
      state.ptname,
      localStorage.getItem("login")
    );

    dispatch(setGlobalLoading(false));
    if (result) {
      dispatch(
        setAlertState({
          show: true,
          type: "done",
          title: "Успішно!",
          subtitle: "Ви успішно відключили термінал.",
          fnClose: () => {
            dispatch(dropAlertState());
            dispatch(loadStateServerTerminal());
            dispatch(fetchServerTerminals());
            history.push(TERMINALS_ROUTE);
          },
        })
      );
    }
  }

  function handlePPO(val) {
    if (!val) return setPPO("");

    const reg = new RegExp("^[+0-9]+$", "g");

    if (!val.match(reg)) return;

    setPPO(val);
  }

  const onChangeReceiptHandler = (value, cb, isHeader = true) => {
    let splittedArray = value.split("\n");
    let linesCount = 0;

    while (linesCount <= 6 && splittedArray.length > linesCount) {
      let currentLine = splittedArray[linesCount];

      if (isHeader) {
        const IDIndex = currentLine.lastIndexOf("ПН:");

        if (IDIndex > 0) {
          const prefix = currentLine.substring(0, IDIndex);
          const suffix = currentLine.substring(IDIndex);

          splittedArray[linesCount] = prefix;
          splittedArray.push(suffix);

          continue;
        }
      }

      if (currentLine.length > MAX_ROW_LENGHT) {
        let lastSpaceIndex = currentLine.lastIndexOf(" ");

        if (
          lastSpaceIndex > 2 &&
          currentLine.substring(lastSpaceIndex - 3, 3) === "ПН:"
        ) {
          lastSpaceIndex = -1;
        }

        const indexOfLastSpace =
          lastSpaceIndex === -1 ? MAX_ROW_LENGHT : lastSpaceIndex;
        const remnant = currentLine.slice(indexOfLastSpace);

        currentLine = currentLine.slice(0, indexOfLastSpace);
        splittedArray[linesCount] = currentLine;

        if (splittedArray.length > linesCount + 1) {
          splittedArray[linesCount + 1] =
            remnant + splittedArray[linesCount + 1];
        } else {
          splittedArray.push(remnant);
        }

        continue;
      }

      linesCount++;
    }

    if (splittedArray.length > MAX_LINES) {
      splittedArray = splittedArray.slice(0, MAX_LINES);
    }

    cb(splittedArray.join("\n"));
  };

  const onCancelInkassAction = () => {
    if (isHaveOpenFiscalDay) return;
    else {
      setTurnOffTerminalModalIsActive(false);
      detachTerminal();
    }
  };

  const onCanceFiscalDayClosingAction = () => {
    setTurnOffTerminalModalIsActive(false);
    detachTerminal();
  };

  const makeInkassActionFunction = async () => {
    dispatch(setGlobalLoading(true));
    setTurnOffTerminalModalIsActive(false);

    const fnSubmit = () => {
      dispatch(dropAlertState());
      if (isHaveOpenFiscalDay) {
        setTurnOffTerminalModalIsActive(true);
      } else {
        detachTerminal();
      }
    };

    const result = await CashAPI.makeInkassAction({
      terminalId: state.ptname,
      amount: cashBalance,
      skipErrorMessage: true,
    });
    dispatch(setGlobalLoading(false));

    if (result) {
      setCashBalance(null);
      dispatch(
        setAlertState({
          show: true,
          type: "done-button",
          title: "Ви успішно виконали СЛУЖБОВУ ВИДАЧУ",
          subtitle: "Продовжити відключення терміналу?",
          fnSubmit,
          fnClose: () => dispatch(dropAlertState()),
        })
      );
    } else {
      dispatch(
        setAlertState({
          show: true,
          type: "remove",
          title: "Помилка фіскалізації",
          subtitle:
            "Під час виконання службової видачі було отримано помилку фіскалізації. Продовжувати відключення терміналу?",
          fnSubmit,
          btn_close: "продовжити",
          fnClose: () => dispatch(dropAlertState()),
          height: "450px",
        })
      );
    }
  };

  const closeOpenFiscalDay = async () => {
    const server = new FiskalTable();
    const result = await server.closedDay({ rro: ppo });

    if (result) {
      dispatch(
        setAlertState({
          show: true,
          type: "done-button",
          title: "Ви успішно виконали закриття фіскальної зміни.",
          subtitle: "Продовжити відключення терміналу?",
          cancelButtonLabel: "Не відключати термінал",
          acceptButtonLabel: "Відключити термінал",
          fnSubmit: () => detachTerminal(),
          fnClose: () => dispatch(dropAlertState()),
        })
      );
    }
  };

  const closeOpenFiscalDayWithTransactionCheck = async () => {
    setTurnOffTerminalModalIsActive(false);

    const transactionsOK = await checkNotFiscalTransactions();

    if (transactionsOK) {
      closeOpenFiscalDay();
    } else {
      const subtitle =
        transactionsOK === false
          ? `У поточній зміні на терміналі ${id} є нефіскалізовані транзакції. Рекомендуємо перед закриттям фіскальної зміни дофіскалізувати транзакції.`
          : `Спроба визначити присутність нефіскалізованих транзакцій поточної зміни на терміналі ${id} завершилася помилкою. Рекомендуємо перед закриттям фіскальної зміни та відключенням терміналу з'ясувати присутність нефіскалізованих транзакцій і якщо такі є - дофіскалізувати їх.`;

      dispatch(
        setAlertState({
          show: true,
          type: "remove",
          title: "Закриття зміни",
          subtitle,
          btn_close: null,
          cancelButtonLabel: "Не закривати зміну",
          acceptButtonLabel: "Закрити зміну",
          fnClose: () => dispatch(setAlertState({ show: false })),
          fnSubmit: closeOpenFiscalDay,
          height: "500px",
        })
      );
    }
  };

  const handleCloseTurnOffTerminalModal = () => {
    setTurnOffTerminalModalIsActive(false);
  };

  const onReset = () => {
    onChangeReceiptHandler(
      makeDefaultCaption(
        IS_MERCHANT_ID_READONLY,
        merchantID,
        state?.ptdescription
      ),
      setReceipt
    );
    setFooter("");
  };

  return {
    IS_MERCHANT_ID_READONLY,
    daySettings,
    eCommTerminals,
    editFalse,
    editTrue,
    fiscalSettingsOpened,
    handlePPO,
    id,
    setId,
    isEComm,
    merchantID,
    merchantName,
    ppo,
    saveDaySettings,
    serverAddPPO,
    serverRemovePPO,
    setupBlockTerminal,
    showInfo,
    state,
    turnOffTerminalModalIsActive,
    vtErrorMessage,
    onCancelInkassAction,
    onCanceFiscalDayClosingAction,
    makeInkassActionFunction,
    closeOpenFiscalDayWithTransactionCheck,
    handleCloseTurnOffTerminalModal,
    setTurnOffTerminalModalIsActive,
    onReset,
    clientPaymentTemplateId,
    storedPaymentTemplateId,
    ppoServer,
    userData,
    setFiscalSettingsOpened,
    cashBalance,
    isHaveOpenFiscalDay,
    isEdit,
    setIsEdit,
    describe,
    setDescribe,
    isShowPPO,
    name,
    setName,
    tipsActivated,
    setTipsActivated,
    enableCash,
    setEnableCash,
    cashRound,
    setCashRound,
    enableCashless,
    setEnableCashless,
    receipt,
    setReceipt,
    onChangeReceiptHandler,
    footer,
    setFooter,
    initialPpo,
  };
};

export default useTerminalDetails;
