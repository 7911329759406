import React from "react";

import { MdKeyboardArrowDown } from "react-icons/md";
import Preloader from "components/additional/PreLoader/Preloader";
import Alert from "ui/Alert/Alert";

import {
  XRecieptContainer,
  RecieptToggleButton,
  RecieptContent,
  Button,
} from "./x-reciept.styles";

import useXReceipt from "./useXReceipt";

const XReciept = ({ rro_id }) => {
  const {
    opened,
    loadingXReceiptHTML,
    loadingXReceiptPDF,
    showAlert,
    setShowAlert,
    iframeRef,
    getXReceipt,
    getXReceiptPDF,
  } = useXReceipt(rro_id);

  return (
    <>
      {showAlert && (
        <Alert
          type="block"
          title="Помилка"
          subtitle={showAlert.message}
          fnClose={() => setShowAlert(null)}
        />
      )}

      <XRecieptContainer>
        {loadingXReceiptHTML ? (
          <Preloader style={{ marginTop: "0" }} />
        ) : (
          <RecieptToggleButton opened={opened} onClick={getXReceipt}>
            <span>{opened ? "X-звіт" : "Переглянути X-звіт"}</span>
            <MdKeyboardArrowDown className={opened ? "opened" : "closed"} />
          </RecieptToggleButton>
        )}
        <RecieptContent closed={!opened} maxHeight={"1000px"}>
          <iframe
            id="pwr"
            title="xReceipt"
            style={{ border: "none" }}
            height="auto"
            ref={iframeRef}
          />
          <div className="buttons">
            {loadingXReceiptPDF ? (
              <Preloader style={{ marginTop: "0" }} />
            ) : (
              <Button
                disabled={
                  !iframeRef.current?.contentWindow?.document?.querySelector(
                    ".ct-content"
                  )
                }
                onClick={getXReceiptPDF}
              >
                Завантажити PDF
              </Button>
            )}
          </div>
        </RecieptContent>
      </XRecieptContainer>
    </>
  );
};

export default XReciept;
