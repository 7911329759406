import { font_book } from "assets/style/fonts.variables";
import {
  oshad_green,
  grey,
  tiffany,
  white,
  black,
} from "assets/style/variables";
import { ButtonSecondary } from "components/forms/Button/Button.styles";
import styled from "styled-components";
import { ButtonSwitcher } from "../../../Bank/Bank.styles";

export const NewsContainer = styled.section`
  margin: 0;
`;

export const NewsHeader = styled.section`
  display: flex;
  justify-content: space-between;
`;

export const NewsTableContainer = styled.div`
  margin: 0;
  margin-top: 25px;
  width: 100%;

  span {
    font-family: ${font_book};
  }

  a {
    text-decoration: none;
  }
`;

export const TrMain = styled.div`
  display: grid;
  grid-template-columns: ${({ editable }) => {
    return editable ? "auto 40px 1fr auto" : "40px 1fr auto";
  }};
  padding: 5px 12px;
  height: fit-content;
  min-height: 45px;

  @keyframes news-item-animation {
    0% {
      max-height: 0px;
      opacity: 0;
      padding: 0 12px;
    }
    100% {
      max-height: 300px;
      opacity: 1;
    }
  }

  animation: news-item-animation 1s ease;

  p {
    margin-top: 0;
  }

  div.body-cell {
    align-self: center;
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;

    input[type="checkbox"] {
      width: 25px;
      height: 25px;
    }

    &.time {
      color: ${grey};
    }

    &.vcenter {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
    }

    &.icon {
      display: flex;
      justify-content: ${({ editable }) =>
        editable ? "center" : "flex-start"};
      align-items: center;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
      }
    }
  }
  ${(props) =>
    props.column
      ? `
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        margin-top: 12px;
        &:hover{
          cursor: pointer;
          background-color: #eee;
        }
      `
      : null}

  align-items: center;
`;

export const Button = styled(ButtonSecondary)`
  font-size: 14px;
  font-family: ${font_book};
  font-weight: 400;
  background: ${white};
  border: 1px solid rgba(112, 120, 127, 0.2);
  border-radius: 10px;
  padding: 8px 30px;
`;

export const ButtonAdd = styled(ButtonSwitcher)`
  padding: 14px 0;
  max-width: 163px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  justify-content: center;

  .plus-blue {
    margin-right: 10px;
    font-size: 22px;
    color: ${tiffany};
  }
`;

export const IconContainer = styled.span`
  display: inline-block;
  color: ${({ read }) => (read ? grey : oshad_green)};
  font-weight: ${({ read }) => (read ? "normal" : "600")};
  font-size: 17px;
  font-family: "Futura PT", sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const NameContainer = styled.span`
  display: inline-block;
  padding-top: 4px;
  color: ${({ read }) => (read ? black : oshad_green)};
  font-weight: ${({ read }) => (read ? "normal" : "600")};
  font-size: 17px;
  font-family: "Futura PT", sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DataContainer = styled.span`
  font-weight: 500;
  font-size: 14px;
  font-family: "Futura PT", sans-serif;
`;
