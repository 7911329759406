export const STAT_TYPES = {
  BY_TRAN_TYPE: 1,
  BY_YEAR: 2,
  BY_MONTH: 3,
  BY_TERMINAL: 4,
};

export const STAT_NAMES = {
  [STAT_TYPES.BY_TRAN_TYPE]: "Статистика за типами оплат",
  [STAT_TYPES.BY_YEAR]: "Статистика за роками",
  [STAT_TYPES.BY_MONTH]: "Порівняння сум оплат за роками",
  [STAT_TYPES.BY_TERMINAL]: "Розподіл транзакцій за терміналами",
};

export const STAT_OPTIONS = [
  {
    label: STAT_NAMES[STAT_TYPES.BY_TRAN_TYPE],
    value: STAT_TYPES.BY_TRAN_TYPE,
  },
  {
    label: STAT_NAMES[STAT_TYPES.BY_YEAR],
    value: STAT_TYPES.BY_YEAR,
  },
  {
    label: STAT_NAMES[STAT_TYPES.BY_MONTH],
    value: STAT_TYPES.BY_MONTH,
  },
  {
    label: STAT_NAMES[STAT_TYPES.BY_TERMINAL],
    value: STAT_TYPES.BY_TERMINAL,
  },
];

export const DEFAULT_TOTALS = {
  total: 0,
  totals: [],
};

export const STAT_BY_QUARTERS = "поквартально";
