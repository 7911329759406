import React from "react";
import { ChartTotalsContainer } from "./ChartTotals.styles";
import { getCurrencyFormatter } from "../../helpers";

const formatter = getCurrencyFormatter(2);

const ChartTotals = ({ totals = [], chartType, quarterly }) => {
  return (
    <ChartTotalsContainer chartType={chartType} quarterly={quarterly}>
      {totals.map(({ name, total }) => (
        <span key={name}>{formatter.format(total)}</span>
      ))}
    </ChartTotalsContainer>
  );
};

export default ChartTotals;
{
  /* <span key={name}>{`${total.toFixed(2)} ₴`}</span> */
}
