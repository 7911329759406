import { useSelector } from "react-redux";

import Preloader from "components/additional/PreLoader/Preloader";
import ChartHeader from "./components/ChartHeader/ChartHeader";
import Chart from "./components/Chart/Chart";
import { mobileVersion } from "store/main_table/main_table.slicer";
import {
  ChartContainer,
  ChartInfoContainer,
  ChartScrollContainer,
  ChartWrapper,
} from "../../Statistics.styles";
import useChartState from "./useChartState";
import { STAT_TYPES } from "../../constants";
import {
  selectCurrentStatisticsData,
  selectCurrentStatisticsParams,
} from "store/statistics/stat_slicer";
import TerminalPickerModal from "./components/TerminalPicker/TerminalPickerModal";

const TotalsByTerminals = () => {
  const { isLoading } = useSelector(selectCurrentStatisticsData);
  const { showTerminalPicker } = useSelector(selectCurrentStatisticsParams);
  const isMobile = useSelector(mobileVersion) < 1024;
  const { chartState, data } = useChartState();

  return (
    <>
      {showTerminalPicker && <TerminalPickerModal />}
      <ChartHeader chartState={chartState} />
      <ChartContainer>
        <ChartScrollContainer>
          {isLoading ? (
            <ChartInfoContainer>
              <Preloader />
            </ChartInfoContainer>
          ) : (
            <>
              {data && data.length > 0 ? (
                <ChartWrapper
                  isMobile={isMobile}
                  statType={STAT_TYPES.BY_TERMINAL}
                >
                  <Chart data={data} />
                </ChartWrapper>
              ) : (
                <ChartInfoContainer>{"відсутні дані"}</ChartInfoContainer>
              )}
            </>
          )}
        </ChartScrollContainer>
      </ChartContainer>
    </>
  );
};

export default TotalsByTerminals;
