import { Input, TextFieldContainer, SubTitle } from "./TextField.styles";

export default function TextField({
  name,
  placeholder,
  value,
  fn,
  disabled,
  error,
  className,
  fnFocus,
  nameInput,
  typePassw,
  type,
  style,
  inputRef = null,
  ...rest
}) {
  return (
    <TextFieldContainer style={style}>
      <SubTitle error={error}>{name}</SubTitle>
      <Input
        className={className}
        type={type || "text"}
        value={value || ""}
        placeholder={placeholder}
        onChange={(e) => fn(e.target.value)}
        disabled={disabled}
        error={error}
        onFocus={fnFocus}
        name={nameInput}
        ref={inputRef}
        {...rest}
      />
    </TextFieldContainer>
  );
}
