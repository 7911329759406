import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { getCurrencyFormatter } from "pages/Panels/Statistics/helpers";
import { YEAR_COLOR, YEAR_AMOUNT } from "../../config";

const YAxisFormatter = getCurrencyFormatter(0);
const TooltipFormatter = getCurrencyFormatter(2);

const Chart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%" debounce={500}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" width={10} />
        <YAxis tickFormatter={(value) => YAxisFormatter.format(value)} />
        <Tooltip
          cursor={{ fill: "rgba(0 0 0/0.05)" }}
          formatter={(value) => TooltipFormatter.format(value)}
        />
        <Bar dataKey={YEAR_AMOUNT} stackId="a" fill={YEAR_COLOR} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
