import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { ModalSubTitle } from "ui/Modal/modal.styles";
import {
  KeyExpirationModalContainer,
  Title,
  AcceptButtonContainer,
  ContainedButton,
} from "./styles";
import { generateIcon } from "ui/Alert/icons/alertIconHelper";
import { selectUser } from "store/user/user_slicer";

const KeyExpirationModal = ({ onClose }) => {
  const { profile, outOfDateKeys } = useSelector(selectUser);
  const [subtitle, setSubtitle] = useState("");

  useEffect(() => {
    const { user_name, app_login } = profile;

    if (outOfDateKeys.length > 0) {
      const warnings = outOfDateKeys.map(
        ({ validTo, appLogin, cashierName }, i) => {
          const login = appLogin || app_login;
          const name = cashierName || user_name;

          return (
            <span key={i}>
              {"\b\b\b\b" +
                `У касира ${name} (${login}) сертифікат, який застосовується для ПРРО, має термін дії до ${validTo.format(
                  "DD.MM.YYYY HH:mm"
                )}.` +
                (i === outOfDateKeys.length - 1 ? "\n" : "\n\n")}
            </span>
          );
        }
      );

      setSubtitle(<>{warnings}</>);
    } else {
      onClose();
    }
  }, [profile, outOfDateKeys, onClose]);

  return (
    <KeyExpirationModalContainer>
      {generateIcon("remove")}
      <Title>Зверніть увагу!</Title>
      <ModalSubTitle
        style={{
          textAlign: "center",
          whiteSpace: "pre-line",
          padding: "0 1rem",
        }}
      >
        <p
          style={
            outOfDateKeys.length > 1
              ? { textAlign: "left", marginTop: "unset" }
              : { marginTop: "unset" }
          }
        >
          {subtitle}
        </p>
        <p>
          <strong>
            За 24 години до закінчення терміну дії сертифіката фіскалізація для
            касира автоматично відключається!
          </strong>
        </p>
        <p>
          Для відновлення фіскалізації необхідно отримати новий сертифікат в
          АЦСК та завантажити його в електронний кабінет податкової (форма №5)
          та онлайн кабінет ОщадPAY.
        </p>
        <p>
          Деталі в&nbsp;
          <a
            href="https://online.oschadpay.ua/m/Instr-PRRO-OschadPAY.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Інструкція користувача ПРРО “ОщадPAY”
          </a>
          .
        </p>
      </ModalSubTitle>
      <AcceptButtonContainer>
        <ContainedButton onClick={onClose}>ЗРОЗУМІЛО</ContainedButton>
      </AcceptButtonContainer>
    </KeyExpirationModalContainer>
  );
};

export { KeyExpirationModal };
