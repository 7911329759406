import { useRef } from "react";
import { useSelector } from "react-redux";
import Select, { components } from "react-select";

import { mobileVersion } from "store/main_table/main_table.slicer";
import ArrowDown from "./ArrowDown";

import s from "./Select.module.css";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className={s.selectDropdownIndicator}>
        <ArrowDown />
      </div>
    </components.DropdownIndicator>
  );
};

const Menu = (props) => <components.Menu {...props} className={s.menu} />;

const Placeholder = (props) => (
  <components.Placeholder {...props} className={s.placeholder} />
);

const SelectComponent = (props) => {
  const {
    width,
    label,
    placeholder,
    errorMessage,
    className: extClassName,
    innerRef,
    fontSize,
    menuPlacement = "auto",
    isSearchable = false,
    ...other
  } = props;

  const isMobile = useSelector(mobileVersion) < 1024;
  let selectRef = useRef();

  if (innerRef) selectRef = innerRef;

  const invalid = Boolean(errorMessage);

  const styles = (style = {}) => {
    const width = style.width || "auto";

    return {
      noOptionsMessage: (provided, state) => ({
        ...provided,
        fontFamily: "Futura Book, sans-serif",
        fontSize: isMobile ? "12px" : "16px",
        fontWeight: "100",
      }),
      placeholder: (provided, state) => ({
        ...provided,
        fontSize: isMobile ? "12px" : "16px",
        fontWeight: "100",
        whiteSpace: "nowrap",
        textAlign: "left",
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#e8fde7" : "#fff",
        color: "#000",
        borderLeft: state.isSelected
          ? "2px solid #00503C"
          : provided.borderLeft,
        fontFamily: "Futura Book",
        fontSize: isMobile ? "14px" : "16px",
        fontWeight: state.isSelected ? "bold" : "normal",
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        "&:nth-of-type(even)": {
          backgroundColor: state.isFocused ? "#e8fde7" : "rgb(251, 251, 251)",
        },
        textAlign: "left",
      }),
      control: (provided, state) => ({
        ...provided,
        width,
        // height: isMobile ? "unset" : "3rem",
        borderRadius: "11px",
        paddingLeft: "0.25rem",
        paddingRight: "0.5rem",
        paddingTop: "0",
        paddingBottom: "0",
        cursor: "pointer",
        caretColor: "#26CFC7",
        "&:hover": {
          border: "1px solid #dee0e0",
        },
        border: `1px solid ${invalid ? "rgba(255, 0, 0, 0.3)" : "#dee0e0"}`,
        boxShadow: state.isFocused ? "0 0 7px #dee0e0" : "none",
        fontFamily: "Futura Book, sans-serif",
        fontSize: isMobile ? "14px" : "16px",
        color: "#000",
        textAlign: "left",
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: "10px",
        marginTop: "0",
        marginBottom: "0",
        fontFamily: "Futura Demi, sans-serif",
        zIndex: 10,
        overflow: "hidden",
      }),
    };
  };

  const customTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: invalid ? "#FA323C" : "#dee0e0",
    },
  });

  let classNames = [s.inputWrapper, s.inputWrapperSelect];

  if (extClassName) {
    classNames.push(extClassName);
  }

  classNames = classNames.join(" ");

  return (
    <div className={classNames}>
      <span className={`${s.selectLabel} ${invalid ? s.invalid : ""}`}>
        {label}
      </span>
      <div className="error-container">
        <Select
          {...other}
          isSearchable={isSearchable}
          theme={customTheme}
          styles={styles({ width })}
          components={{
            DropdownIndicator,
            Menu,
            IndicatorSeparator: () => null,
            Placeholder,
          }}
          placeholder={placeholder}
          noOptionsMessage={() => "відсутні дані"}
          onKeyDown={(e) => {
            if (e.key === "Escape" && selectRef.current.state.focusedOption)
              e.stopPropagation();
          }}
          menuPlacement={menuPlacement}
          ref={selectRef}
        />
        {invalid && (
          <span className={`errorMessage ${s.errorMessage}`}>
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  );
};

export default SelectComponent;
