import React from "react";
import { AlertGrid } from "./AlertGrid";

const AlertList = ({ items, listType, style }) => {
  let list;

  switch (listType) {
    case "grid":
      list = <AlertGrid rows={items} rowStyle={style} />;
      break;
    default:
  }

  return list;
};

export default AlertList;
