import { useCallback, useEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// components
import PageSwitcher from "./components/PageSwitcher/PageSwitcher";
import FiscalOpen from "./FiscalOpen/FiscalOpen.panel";
import FiscalClosed from "./FiscalClosed/FiscalClosed.panel";
import Refresh from "ui/Refresh/Refresh";

// functions & constants
import { useHeader } from "store/header/header.context";
import ChangeTitle from "utils/helpers/changetitle.helpers";
import {
  FISCAL_DAY_ROUTE,
  FISCAL_DAY_ROUTE_CLOSED,
} from "utils/constants/routes.constants";

//redux
import {
  fetchAllFiscalInfo,
  dropClosedFilteringState,
} from "store/fiscal_table/fiscal_table.actions";
import { fiscalClosedFilteringState } from "store/fiscal_table/fiscal_table.slicer";

import useDownloadAllClosedShiftsZReports from "./useDownloadAllClosedShiftsZReports";

const FiscalPanel = () => {
  const dispatch = useDispatch();
  const { dispatch: headerDispatch } = useHeader();
  const reduxfilteringState = useSelector(fiscalClosedFilteringState);
  const { startDate, endDate } = reduxfilteringState || {};

  useEffect(() => {
    ChangeTitle("Фіскальні зміни", headerDispatch);
  }, [headerDispatch]);

  const onRefreshHandler = useCallback(() => {
    dispatch(dropClosedFilteringState());
    dispatch(fetchAllFiscalInfo({ startDate, endDate }));
  }, [startDate, endDate, dispatch]);

  useEffect(() => {
    dispatch(dropClosedFilteringState());
  }, []);

  const { setShifts, makingAllShiftZReport, downloadAllZReports } =
    useDownloadAllClosedShiftsZReports();

  return (
    <>
      <PageSwitcher
        className="fiscal"
        downloadAllZReports={downloadAllZReports}
        makingAllZReports={makingAllShiftZReport}
      />
      <Route exact path={FISCAL_DAY_ROUTE} component={FiscalOpen} />
      <Route exact path={FISCAL_DAY_ROUTE_CLOSED}>
        <FiscalClosed {...{ setShifts }} />
      </Route>
      <Refresh fn={onRefreshHandler} />
    </>
  );
};

export default FiscalPanel;
