import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ReportsPreloaderModalContainer,
  ContainedButton,
  ModalButtons,
} from "./style";
import { ModalTitle, ModalSubTitle, ModalBody } from "ui/Modal/modal.styles";
import TimesCancelButton from "ui/Modal/TimesCancelButton";
// import { generateIcon } from "ui/Alert/icons/alertIconHelper";
import { RadioButton, RadioGroup } from "../../RadioButton";
import { TRAN_REPORT_TYPE as REPORT_TYPE } from "../../../utils/constants/ui";
import { selectLastTranReportType } from "store/ui/ui.slicer";
import { setLastTranReportType } from "store/ui/ui.actions";

const ReportsPreloaderModal = (props) => {
  const { BASE, EXTENDED } = REPORT_TYPE;
  const { title, subtitle, onExport, onCancel } = props;
  const dispatch = useDispatch();
  const type = useSelector(selectLastTranReportType);
  const [reportType, setReportType] = useState(type);

  useEffect(() => {
    dispatch(setLastTranReportType(reportType));
  }, [reportType, dispatch]);

  return (
    <ReportsPreloaderModalContainer>
      {/* {generateIcon("remove")} */}
      <ModalTitle>{title}</ModalTitle>
      <ModalSubTitle>{subtitle}</ModalSubTitle>
      <RadioGroup direction="row">
        <RadioButton
          title="Базовий"
          onClick={() => setReportType(BASE)}
          labelPosition="right"
          name={BASE}
          value={BASE}
          checked={reportType === BASE}
        />
        <RadioButton
          title="Розширений"
          onClick={() => setReportType(EXTENDED)}
          labelPosition="right"
          name={EXTENDED}
          value={EXTENDED}
          checked={reportType === EXTENDED}
        />
      </RadioGroup>
      <ModalBody>
        <ModalButtons>
          <ContainedButton onClick={() => onExport(reportType)}>
            Експортувати
          </ContainedButton>
          <ContainedButton onClick={onCancel}>Скасувати</ContainedButton>
          <TimesCancelButton onClick={onCancel} right={".65rem"} />
        </ModalButtons>
      </ModalBody>
    </ReportsPreloaderModalContainer>
  );
};

export default ReportsPreloaderModal;
