import { font_book } from "assets/style/fonts.variables";
import {
  black,
  caret,
  grey,
  grey_light_search,
  mouse_pointer,
  button_shadow,
} from "assets/style/variables";
import styled from "styled-components";

const SearchContainer = styled.section`
  position: relative;
  width: 300px;
`;

const SearchInputContainer = styled.section`
  position: relative;
`;

const SearchInput = styled.input`
  padding: 14px;
  outline: none;
  padding-left: 48px;
  font-family: ${font_book};
  font-size: 16px;
  color: ${black};
  width: 100%;
  background-color: ${grey_light_search};
  position: relative;
  border-radius: ${(props) => (props.active ? "12px 12px  0 0" : "12px")};
  border: 0;
  ${caret};
  &::placeholder {
    color: ${grey};
  }
`;

const IconSpan = styled.span`
  position: absolute;
  left: 17px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const IconSpanClose = styled(IconSpan)`
  right: 18px;
  left: auto;
  ${mouse_pointer}
`;

const Result = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  background-color: ${grey_light_search};
  border-radius: 0 0 12px 12px;
  color: ${grey};
  padding-left: 48px;
  padding-bottom: 24px;
  padding-top: 6px;
  font-family: ${font_book};
  box-shadow: ${button_shadow};
  position: absolute;
  width: 100%;
  z-index: 10;
`;

const Li = styled.li`
  list-style: none;
  margin: 10px 0;
  ${mouse_pointer}
  &:hover {
    color: ${black};
  }
`;

export {
  SearchContainer,
  SearchInputContainer,
  SearchInput,
  IconSpan,
  Result,
  Li,
  IconSpanClose,
};
