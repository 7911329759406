import { useDispatch } from "react-redux";
import { PanelContainer } from "./Panel.styles";
import { Switch, Route } from "react-router-dom";
import {
  DASHBOARD_ROUTE,
  TRANSACTION_ROUTE,
  BANK_DAY_ROUTE,
  BANK_DAY_ROUTE_CLOSED,
  BANK_DAY_ROUTE_DETAIL,
  FISCAL_DAY_ROUTE,
  TERMINALS_ROUTE,
  CASHIERS_ROUTE,
  ADD_CASHIER_ROUTE,
  SETTING_FOP_ROUTE,
  SUPPORT_ROUTE,
  QUESTION_ROUTE,
  TRANSACTION_DETAILS_ROUTE,
  TERMINALS_ROUTE_DETAIL,
  CASHIERS_DETAIL_ROUTE,
  GOODS_ROUTE,
  GOODS_ROUTE_DETAIL,
  FISCAL_DAY_ROUTE_CLOSED,
  FISCAL_DAY_ROUTE_DETAIL,
  CASH_DAY_ROUTE,
  NEWS_ROUTE,
  NEWS_ROUTE_DETAIL,
  STATS_ROUTE,
} from "utils/constants/routes.constants";
import { Header } from "components/layouts";

import {
  Bank,
  Cashiers,
  Fiscal,
  Main,
  BankDetail,
  Question,
  Setting,
  Support,
  Terminals,
  Transaction,
  TransactionDetails,
  Goods,
  CreateEditGood,
  Fop,
  FiscalDetail,
  Cash,
  CashierAdd,
  News,
} from "pages/Panels";
import { HeaderProvider } from "store/header/header.context";
import TerminalDetail from "pages/Panels/Terminals/TerminalDetail/TerminalDetail";
import CashiersDetail from "pages/Panels/Cashiers/CashiersDetail/CashiersDetail.panel";
import NewsDetail from "pages/Panels/News/components/NewsDetail/NewsDetail";
import Statistics from "pages/Panels/Statistics/Statistics";

// redux
import { setPanelRef } from "store/main_table/main_table.actions";

export default function Panel() {
  const dispatch = useDispatch();

  return (
    <HeaderProvider>
      <PanelContainer ref={(ref) => dispatch(setPanelRef(ref))}>
        <Header />
        <Switch>
          <Route exact path={DASHBOARD_ROUTE} component={Main} />
          <Route exact path={TRANSACTION_ROUTE} component={Transaction} />
          <Route
            exact
            path={TRANSACTION_DETAILS_ROUTE}
            component={TransactionDetails}
          />
          <Route exact path={BANK_DAY_ROUTE} component={Bank} />
          <Route exact path={BANK_DAY_ROUTE_CLOSED} component={Bank} />
          <Route exact path={BANK_DAY_ROUTE_DETAIL} component={BankDetail} />
          <Route exact path={CASH_DAY_ROUTE} component={Cash} />
          <Route exact path={FISCAL_DAY_ROUTE} component={Fiscal} />
          <Route exact path={FISCAL_DAY_ROUTE_CLOSED} component={Fiscal} />
          <Route
            exact
            path={FISCAL_DAY_ROUTE_DETAIL}
            component={FiscalDetail}
          />
          <Route exact path={TERMINALS_ROUTE} component={Terminals} />
          <Route
            exact
            path={TERMINALS_ROUTE_DETAIL}
            component={TerminalDetail}
          />
          <Route exact path={CASHIERS_ROUTE} component={Cashiers} />
          <Route exact path={ADD_CASHIER_ROUTE} component={CashierAdd} />
          <Route
            exact
            path={CASHIERS_DETAIL_ROUTE}
            component={CashiersDetail}
          />
          <Route exact path={SETTING_FOP_ROUTE} component={Fop} />
          <Route exact path={GOODS_ROUTE} component={Goods} />
          <Route exact path={GOODS_ROUTE_DETAIL} component={CreateEditGood} />
          <Route exact path={SETTING_FOP_ROUTE} component={Setting} />
          <Route exact path={SUPPORT_ROUTE} component={Support} />
          <Route exact path={QUESTION_ROUTE} component={Question} />
          <Route exact path={NEWS_ROUTE} component={News} />
          <Route exact path={NEWS_ROUTE_DETAIL} component={NewsDetail} />
          <Route exact path={STATS_ROUTE} component={Statistics} />
        </Switch>
      </PanelContainer>
    </HeaderProvider>
  );
}
