import React, { useEffect } from 'react';

import { useHeader } from 'store/header/header.context';
import ChangeTitle from 'utils/helpers/changetitle.helpers';
import { TerminalContainer } from './Terminals.styles';
import Table from './Terminals.table';

export default function Terminals() {
  const { dispatch } = useHeader();

  useEffect(() => {
    ChangeTitle('Платіжні термінали', dispatch);
  }, [dispatch]);

  return (
    <TerminalContainer>
      <Table />
    </TerminalContainer>
  );
}
