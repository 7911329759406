import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import Refresh from "ui/Refresh/Refresh";
import CashList from "./CashList/CashList";

// functions
import { useHeader } from "store/header/header.context";
import ChangeTitle from "utils/helpers/changetitle.helpers";

// redux
import { cashRecords } from "store/cash_table/cash_table.slicer";
import {
  getMainCashList,
  dropDilteringState,
} from "store/cash_table/cash_table.actions";

const CashPanel = () => {
  const dispatch = useDispatch();
  const { dispatch: headerDispatch } = useHeader();
  const records = useSelector(cashRecords);

  const onRefreshHandler = useCallback(() => {
    dispatch(dropDilteringState());
    dispatch(getMainCashList());
  }, []);

  useEffect(() => {
    ChangeTitle("Готівка в касах", headerDispatch);
  }, [headerDispatch]);

  useEffect(() => {
    if (!records || records.length === 0) {
      dispatch(getMainCashList());
    }
  }, []);

  return (
    <>
      <CashList />
      <Refresh fn={onRefreshHandler} />
    </>
  );
};

export default CashPanel;
