import React from "react";
import { VscEdit as Edit } from "react-icons/vsc";

import { EditorRowButton } from "./EditorRowActions.styles";

const EditorRowActions = ({ position: { point, index }, onEdit }) => {
  const style = {
    position: "absolute",
    zIndex: "1",
    ...point,
  };

  return (
    <div style={style}>
      {onEdit && (
        <EditorRowButton onClick={() => onEdit(index)} title="Редагувати">
          <Edit size="1.5rem" />
        </EditorRowButton>
      )}
    </div>
  );
};

export default EditorRowActions;
