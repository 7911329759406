import { MainTable } from "api/index";
import {
  SET_PANEL_REF,
  TABLE_SET_SERVER,
  TABLE_SET_LOCAL,
  TABLE_SET_SERVER_FILTERED,
  TABLE_LOAD,
  TABLE_SERVER_LOAD,
  TABLE_CHANGE_BUTTON,
  TABLE_CHANGE_DATE,
  TABLE_SET_HEADER,
  TABLE_SET_SEARCH,
  TABLE_RESET,
  TABLE_SET_FILTER_TRANSACTION,
  TABLE_SET_FILTER_CARDS,
  TABLE_SET_FILTER_CASH,
  TABLE_SET_FILTER_TOTAL,
  TABLE_SET_FILTER_TRANSACTION_VAL,
  TABLE_SET_FILTER_CARDS_VAL,
  TABLE_SET_FILTER_CASH_VAL,
  TABLE_SET_FILTER_TOTAL_VAL,
  TABLE_SET_SEARCH_VAL,
  TABLE_SET_SERVER_TERMINAL,
  TABLE_SET_LOCAL_TERMINAL,
  TABLE_SET_FILTER_TERMINAL_ID,
  TABLE_SET_FILTER_TERMINAL_DESCRIBE,
  TABLE_SET_FILTER_TERMINAL_NAME,
  TABLE_SET_FILTER_TERMINAL_DEVICE,
  TABLE_SET_FILTER_TERMINAL_ID_VAL,
  TABLE_SET_FILTER_TERMINAL_DESCRIBE_VAL,
  TABLE_SET_FILTER_TERMINAL_NAME_VAL,
  TABLE_SET_FILTER_TERMINAL_DEVICE_VAL,
  TABLE_SET_FILTER_TERMINAL_PPO_VAL,
  TABLE_SERVER_LOAD_TERMINAL,
  TABLE_SET_SEARCH_TERMINAL,
  TABLE_SET_ITEM,
  TABLE_SET_TERMINAL,
  TABLE_SET_TRANSACTION,
  TABLE_SET_CARD,
  TABLE_SET_CASH,
  TABLE_SET_CASHLESS,
  TABLE_SET_QR,
  TABLE_SET_AMOUNT,
  SET_GLOBAL_LOADING,
  SET_MOBILE_VERSION,
} from "./main_table.types";

import { terminalStatuses } from "utils/constants/terminal.constants";

const MAIN_HEADERS = {
  card: {
    count: 0,
    amount: 0,
    refund: 0,
    refundCount: 0,
  },
  cash: {
    count: 0,
    amount: 0,
    refund: 0,
    refundCount: 0,
  },
  cashless: {
    count: 0,
    amount: 0,
    refund: 0,
    refundCount: 0,
  },
  qr: {
    count: 0,
    amount: 0,
    refund: 0,
    refundCount: 0,
  },
  result: {
    count: 0,
    amount: 0,
  },
};

export const filterByStatus = () => (dispatch, getState) => {
  const { search_id_val_terminal, state_server_terminal } =
    getState().main_table;

  if (search_id_val_terminal) {
    const state_server = state_server_terminal.filter(
      (item) => item.status === search_id_val_terminal
    );

    dispatch(setLocalTerminal(state_server));
  } else {
    dispatch(setLocalTerminal(state_server_terminal));
  }
};

export const fetchServer =
  (val, withoutLoading) => async (dispatch, getState) => {
    const {
      main_table: { dates },
    } = getState();

    if (!withoutLoading) {
      dispatch(loadStateServer());
      dispatch(loadState());
    }

    const server = new MainTable();

    server.get(dates.dateFrom, dates.dateTo, val).then((response) => {
      let { terminals } = response || {};

      if (!withoutLoading) {
        dispatch(loadStateServer());
        dispatch(loadState());
      }

      if (terminals && terminals.length !== 0) {
        const totals = terminals.filter((el) => el.ptname === "Total");
        let newHeaders = MAIN_HEADERS;

        if (totals.length === 1) {
          const [
            {
              card_amount: cardAmount,
              card_count: cardCount,
              card_refund: cardRefund,
              card_refund_count: cardRefundCount,
              cash_amount: cashAmount,
              cash_count: cashCount,
              cash_refund: cashRefund,
              cash_refund_count: cashRefundCount,
              cashless_amount: cashlessAmount,
              cashless_count: cashlessCount,
              cashless_refund: cashlessRefund,
              cashless_refund_count: cashlessRefundCount,
              total_amount: totalAmount,
              transactions_count: totalCount,
              qr_amount: qrAmount,
              qr_count: qrCount,
              qr_refund: qrRefund,
              qr_refund_count: qrRefundCount,
            },
          ] = totals;

          newHeaders = {
            card: {
              count: cardCount,
              amount: cardAmount,
              refund: cardRefund,
              refundCount: cardRefundCount,
            },
            cash: {
              count: cashCount,
              amount: cashAmount,
              refund: cashRefund,
              refundCount: cashRefundCount,
            },
            cashless: {
              count: cashlessCount,
              amount: cashlessAmount,
              refund: cashlessRefund,
              refundCount: cashlessRefundCount,
            },
            qr: {
              count: qrCount,
              amount: qrAmount,
              refund: qrRefund,
              refundCount: qrRefundCount,
            },
            result: { count: totalCount, amount: totalAmount },
          };
        }

        dispatch(setHeaders(newHeaders));
        dispatch(generationHeadersValues(terminals));
        dispatch(setServer(terminals));
        dispatch(setLocal(terminals));
      } else {
        const headers = MAIN_HEADERS;

        dispatch(setHeaders(headers));
        dispatch(setServer([]));
        dispatch(setLocal([]));
      }
    });
  };

export const filterTerminalsState = () => async (dispatch, getState) => {
  const {
    main_table: {
      state_server,
      main_table_search_terminal,
      main_table_search_transaction,
      main_table_search_card,
      main_table_search_cash,
      main_table_search_cashless,
      main_table_search_amount,
    },
  } = getState();

  const newFilteredTerminalsState = state_server.filter((terminal) => {
    if (main_table_search_terminal && main_table_search_terminal !== "") {
      if (
        !terminal.ptdesctiption
          .toLowerCase()
          .includes(main_table_search_terminal.trim().toLowerCase()) &&
        !terminal.device_model
          .toLowerCase()
          .trim()
          .includes(main_table_search_terminal.trim().toLowerCase())
      ) {
        return false;
      }
    }

    if (main_table_search_transaction) {
      if (
        terminal.transactions_count !== parseInt(main_table_search_transaction)
      ) {
        return false;
      }
    }

    if (main_table_search_card) {
      if (terminal.card_amount !== parseFloat(main_table_search_card)) {
        return false;
      }
    }

    if (main_table_search_cash) {
      if (terminal.cash_amount !== parseFloat(main_table_search_cash)) {
        return false;
      }
    }

    if (main_table_search_cashless) {
      if (terminal.cashless_amount !== parseFloat(main_table_search_cashless)) {
        return false;
      }
    }

    if (main_table_search_amount) {
      if (terminal.total_amount !== parseFloat(main_table_search_amount)) {
        return false;
      }
    }

    return true;
  });

  dispatch(loadStateServerFiltered(newFilteredTerminalsState));
};

function groupByStatus(state) {
  let groups = state.reduce(
    (groups, game) => {
      let status;

      if (game.binded && game.status_id === 1) {
        status = terminalStatuses.bound.status;
      } else if (!game.binded && game.status_id === 1) {
        status = terminalStatuses.free.status;
      } else if (game.isEComm) {
        status = terminalStatuses.ecomm.status;
      } else {
        status = terminalStatuses.blocked.status;
      }

      groups[status].push(game);

      return groups;
    },
    { bound: [], free: [], ecomm: [], blocked: [] }
  );

  const groupArrays = Object.keys(groups).map((status) => {
    return {
      status,
      items: groups[status],
    };
  });

  return groupArrays.filter(({ items }) => items.length > 0);
}

export const fetchServerTerminals =
  (val, showProgress) => async (dispatch, getState) => {
    const server = new MainTable();

    if (showProgress) {
      dispatch(setGlobalLoading(true));
    }

    server
      .getTerminals(val)
      .then((response) => {
        const state = groupByStatus(response.terminals);

        dispatch(setServerTerminal(state));
        dispatch(setLocalTerminal(state));
        dispatch(generationTerminalHeadersValues(response.terminals));
        dispatch(loadStateServerTerminal());
      })
      .finally(() => {
        if (showProgress) {
          dispatch(setGlobalLoading(false));
        }
      });
  };

const generationTerminalHeadersValues = (state) => (dispatch) => {
  const state_search_id = state.map((item) => item.ptname);
  const state_search_name = state.map((item) => item.ptdescription);
  const state_search_describe = state.map((item) => item.ptaddress);
  const state_search_device = state.map((item) => item.device_model);

  dispatch(setSearchId(state_search_id));
  dispatch(setSearchName(state_search_name));
  dispatch(setSearchDescribe(state_search_describe));
  dispatch(setSearchDevice(state_search_device));
};

const generationHeadersValues = (state) => (dispatch) => {
  const state_search_terminal = state
    .map((item) => item.ptname)
    .filter((item) => item !== "Total");

  dispatch(setSearchTransactions(state_search_terminal));
};

export const setServer = (state) => ({
  type: TABLE_SET_SERVER,
  payload: state,
});

export const setLocal = (state) => ({
  type: TABLE_SET_LOCAL,
  payload: state,
});
export const setServerTerminal = (state) => ({
  type: TABLE_SET_SERVER_TERMINAL,
  payload: state,
});

export const setLocalTerminal = (state) => ({
  type: TABLE_SET_LOCAL_TERMINAL,
  payload: state,
});

export const loadState = () => ({
  type: TABLE_LOAD,
});

export const loadStateServer = () => ({
  type: TABLE_SERVER_LOAD,
});

export const loadStateServerFiltered = (state) => ({
  type: TABLE_SET_SERVER_FILTERED,
  payload: state,
});

export const loadStateServerTerminal = () => ({
  type: TABLE_SERVER_LOAD_TERMINAL,
});

export const setItem = (item) => ({
  type: TABLE_SET_ITEM,
  payload: item,
});
export const setActiveButton = (id) => ({
  type: TABLE_CHANGE_BUTTON,
  payload: id,
});

export const setDates = (dates) => ({
  type: TABLE_CHANGE_DATE,
  payload: dates,
});

export const setHeaders = (headers) => ({
  type: TABLE_SET_HEADER,
  payload: headers,
});

export const setSearch = (state) => ({
  type: TABLE_SET_SEARCH,
  payload: state,
});

export const setSearchTerminal = (state) => ({
  type: TABLE_SET_SEARCH_TERMINAL,
  payload: state,
});

export const filterTable = (key, val) => (dispatch, getState) => {
  const { state_local } = getState().main_table;

  switch (key) {
    case "ptdesctiption":
      dispatch(setSearchVal(val));
      break;
    case "transactions_count":
      dispatch(setSearchTransactionsVal(val));
      break;
    case "card_amount":
      dispatch(setSearchCardsVal(val));
      break;
    case "cash_amount":
      dispatch(setSearchCashVal(val));
      break;
    case "total_amount":
      dispatch(setSearchTotalsVal(val));
      break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  const filter_state = state_local.filter(
    (item) => item[key].toString() === val
  );
  dispatch(generationHeadersValues(filter_state));

  dispatch(setLocal(filter_state));
};
const filterState = (key, val, state) =>
  state
    .map((item) => {
      const search_item = new RegExp(val, "i");
      const items = item.items.filter((trans) => {
        const search = !trans[key]
          ? -1
          : trans[key].toString().search(search_item);
        if (search !== -1) {
          return true;
        }
        return false;
      });

      if (items.length > 0) return { ...item, items };
      return undefined;
    })
    .filter((item) => item !== undefined);

export const filterTableTerminal = (key, val) => (dispatch, getState) => {
  const { state_local_terminal } = getState().main_table;

  switch (key) {
    case "ptdescription_manual":
      dispatch(setSearchNameVal(val));
      break;
    case "ptname":
      dispatch(setSearchIdVal(val));
      break;
    case "device_model":
      dispatch(setSearchDeviceVal(val));
      break;
    case "ptdescription":
      dispatch(setSearchDescribeVal(val));
      break;
    case "ppo":
      dispatch(setSearchPPOVal(val));
      break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  const filter_state = filterState(key, val, state_local_terminal);
  const headers = filter_state.map((item) => item.items).flat(1);
  dispatch(generationTerminalHeadersValues(headers));
  dispatch(setLocalTerminal(filter_state));
};

export const resetTable = () => ({ type: TABLE_RESET });

export const resetFilterTerminal = (val) => (dispatch, getState) => {
  switch (val) {
    case "ptdescription_manual":
      dispatch(setSearchNameVal(null));
      break;
    case "ptname":
      dispatch(setSearchIdVal(null));
      break;
    case "device_model":
      dispatch(setSearchDeviceVal(null));
      break;
    case "ptdescription":
      dispatch(setSearchDescribeVal(null));
      break;
    case "ppo":
      dispatch(setSearchPPOVal(null));
      break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  const {
    search_describe_val_terminal,
    search_device_val_terminal,
    search_name_val_terminal,
    search_id_val_terminal,
    state_server_terminal,
  } = getState().main_table;

  let filter_state = state_server_terminal;
  const generateStateByKey = (key, val) => filterState(key, val, filter_state);

  if (search_name_val_terminal)
    filter_state = generateStateByKey(
      "ptdesctiption_manual",
      search_name_val_terminal
    );
  if (search_id_val_terminal)
    filter_state = generateStateByKey("ptname", search_id_val_terminal);
  if (search_device_val_terminal)
    filter_state = generateStateByKey(
      "device_model",
      search_device_val_terminal
    );
  if (search_describe_val_terminal)
    filter_state = generateStateByKey(
      "ptdescription",
      search_describe_val_terminal
    );

  const headers = filter_state.map((item) => item.items).flat(1);
  dispatch(generationTerminalHeadersValues(headers));

  dispatch(setLocalTerminal(filter_state));
};

export const resetFilter = (value) => (dispatch, getState) => {
  switch (value) {
    case "search":
      dispatch(setSearchVal(null));
      break;
    case "transactions":
      dispatch(setSearchTransactionsVal(null));
      break;
    case "cash":
      dispatch(setSearchCashVal(null));
      break;
    case "total":
      dispatch(setSearchTotalsVal(null));
      break;
    case "card":
      dispatch(setSearchCardsVal(null));
      break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  const {
    search_state_val,
    search_transactions_val,
    search_cards_val,
    search_cash_val,
    search_totals_val,
    state_server,
  } = getState().main_table;

  let filter_state = state_server;
  const generateStateByKey = (key, val) =>
    filter_state.filter((item) => item[key].toString() === val);

  if (search_state_val)
    filter_state = generateStateByKey("ptdesctiption", search_state_val);
  if (search_transactions_val)
    filter_state = generateStateByKey(
      "transactions_count",
      search_transactions_val
    );
  if (search_cards_val)
    filter_state = generateStateByKey("card_amount", search_cards_val);
  if (search_cash_val)
    filter_state = generateStateByKey("cash_amount", search_cash_val);
  if (search_totals_val)
    filter_state = generateStateByKey("total_amount", search_totals_val);

  dispatch(generationHeadersValues(filter_state));

  dispatch(setLocal(filter_state));
};

export const setSearchCards = (state) => ({
  type: TABLE_SET_FILTER_CARDS,
  payload: state,
});
export const setSearchTransactions = (state) => ({
  type: TABLE_SET_FILTER_TRANSACTION,
  payload: state,
});
export const setSearchCash = (state) => ({
  type: TABLE_SET_FILTER_CASH,
  payload: state,
});
export const setSearchTotals = (state) => ({
  type: TABLE_SET_FILTER_TOTAL,
  payload: state,
});

export const setSearchCardsVal = (state) => ({
  type: TABLE_SET_FILTER_CARDS_VAL,
  payload: state,
});
export const setSearchTransactionsVal = (state) => ({
  type: TABLE_SET_FILTER_TRANSACTION_VAL,
  payload: state,
});
export const setSearchCashVal = (state) => ({
  type: TABLE_SET_FILTER_CASH_VAL,
  payload: state,
});
export const setSearchTotalsVal = (state) => ({
  type: TABLE_SET_FILTER_TOTAL_VAL,
  payload: state,
});

export const setSearchId = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_ID,
  payload: state,
});
export const setSearchDescribe = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_DESCRIBE,
  payload: state,
});
export const setSearchName = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_NAME,
  payload: state,
});
export const setSearchDevice = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_DEVICE,
  payload: state,
});

export const setSearchIdVal = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_ID_VAL,
  payload: state,
});
export const setSearchDescribeVal = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_DESCRIBE_VAL,
  payload: state,
});
export const setSearchNameVal = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_NAME_VAL,
  payload: state,
});
export const setSearchDeviceVal = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_DEVICE_VAL,
  payload: state,
});
export const setSearchPPOVal = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_PPO_VAL,
  payload: state,
});

export const setSearchVal = (state) => ({
  type: TABLE_SET_SEARCH_VAL,
  payload: state,
});

export const setTerminal = (state) => ({
  type: TABLE_SET_TERMINAL,
  payload: state,
});

export const setTransaction = (state) => ({
  type: TABLE_SET_TRANSACTION,
  payload: state,
});

export const setCash = (state) => ({
  type: TABLE_SET_CASH,
  payload: state,
});

export const setCard = (state) => ({
  type: TABLE_SET_CARD,
  payload: state,
});

export const setCashless = (state) => ({
  type: TABLE_SET_CASHLESS,
  payload: state,
});

export const setQR = (state) => ({
  type: TABLE_SET_QR,
  payload: state,
});

export const setAmount = (state) => ({
  type: TABLE_SET_AMOUNT,
  payload: state,
});

export const setGlobalLoading = (state) => ({
  type: SET_GLOBAL_LOADING,
  payload: state,
});
export const setPanelRef = (state) => ({
  type: SET_PANEL_REF,
  payload: state,
});
export const setMobileVersion = (state) => ({
  type: SET_MOBILE_VERSION,
  payload: state,
});
