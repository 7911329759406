import styled from "styled-components";

import { font_book, font_demi } from "assets/style/fonts.variables";
import { black, caret, grey } from "assets/style/variables";
import {
  Button,
  ButtonSecondaryBorder,
} from "components/forms/Button/Button.styles";
import { size } from "assets/style/global-variables";

export const FopContainer = styled.section`
  margin: 0;

  .image-field {
    margin-top: 20px;
  }
  input {
    margin: 5px 0;
  }
  p {
    top: -1px;
  }
`;
export const GoodsDetailHeader = styled.section`
  display: flex;
  justify-content: flex-end;
  grid-gap: 30px;
  align-items: center;
  margin-bottom: 16px;
`;

export const CashButtonContainer = styled.section`
  margin-top: 30px;
  display: flex;
  grid-gap: 15px;
  align-items: baseline;
  button {
    max-width: 330px;
    font-weight: 500;
    font-size: 20px;
    font-family: ${font_demi};
  }
`;

export const CashInformationSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const CashInformationItem = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 15px;
  font-weight: 400;
  font-family: ${font_book};
  .title {
    font-size: 18px;
    color: ${black};
    margin-bottom: 5px;
  }
  .sub_title {
    font-size: 14px;
    color: ${grey};
  }
`;

export const CashInformationButton = styled(ButtonSecondaryBorder)`
  max-width: 120px;
  padding: 8px 0;
`;

export const TaxTitle = styled.h2`
  font-family: ${font_demi};
  font-size: 24px;
  color: ${black};
  margin-bottom: 22px;

  @media screen and (max-width: ${size.mobileL}) {
    margin-bottom: 15px;
  }
`;

export const TaxTable = styled.table`
  th {
    font-family: ${font_book};
    font-size: 15px;
    margin-bottom: 5px;
    color: ${grey};
  }
  tr {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
    align-items: flex-start;
    grid-gap: 10px;
  }
  input {
    width: 100%;
    border: 1px solid #f6f7fa;
    padding: 1px 10px;
    font-size: 18px;
    font-family: ${font_book};
    color: #000;
    outline: none;
    background: white;
    ${caret};
    height: 30px;
    &::placeholder {
      color: ${grey};
    }
    &:focus {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    }
  }
  button {
    height: 30px;
    padding: 0;
  }
`;

export const ButtonTax = styled(Button)`
  width: 100px;
  margin-top: 20px;
  font-size: 0.9em;
  padding: 10px 0;
  font-family: ${font_book};
`;
export const FopText = styled.div`
  color: ${grey};
  font-family: ${font_book};
  font-size: 16px;
  display: block;
`;

export const FopRightInformationContainter = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
