import Switch from "react-switch";

import { ErrorText } from "assets/style/variables";
import { TextField } from "components/forms";
import {
  Button,
  OutlinedButton,
  TransparentButton,
} from "components/forms/Button/Button.styles";
import { VscDiscard, VscNewFile, VscEdit, VscTrash } from "react-icons/vsc";
import {
  EditorActions,
  ModalActions,
  Container,
  Content,
  Header,
  SwitchContainer,
} from "./PaymentDescriptionTemplates.styles";
import { Select } from "components/forms";
import Editor from "components/editors/EditorWithMentions/Editor";
import { useEditTag, usePaymentDescription } from "./hooks";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import TagDetails from "./components/TagDetails";
import Modal from "ui/Modal/Modal";
import TimesCancelButton from "ui/Modal/TimesCancelButton";
import PreLoader from "components/additional/PreLoader/Preloader";
import LocalLoader from "ui/LocalLoader/LocalLoader";

import {
  ACTION_TYPE,
  EDITOR_ERROR_TEXT_STYLE,
  ERROR_TEXT_STYLE,
} from "./helpers/paymentDescription";

const onKeyDown = (e, dispatch) => {
  if (
    [
      "+",
      "Backspace",
      "Delete",
      " ",
      "ArrowLeft",
      "ArrowRight",
      "ArrowDown",
      "ArrowUp",
      "Tab",
      "Home",
      "End",
      "Enter",
    ].includes(e.key)
  ) {
    if (dispatch)
      dispatch({
        type: ACTION_TYPE.KEY_DOWN_POSITION,
        payload: e.target.selectionStart,
      });
  } else {
    e.preventDefault();
  }
};

const PaymentDescriptionTemplates = ({
  onClose,
  templates,
  setTemplates,
  selectedTemplate: initialTemplate = null,
  setSelectedTemplate: setInitialTemplate,
  storedTemplate,
}) => {
  const {
    deletingTemplate,
    savingTemplate,
    editorMode,
    template,
    mentions,
    disabled,
    onNew,
    onCancelNew,
    onEdit,
    onCancelEdit,
    onDelete,
    onSaveTemplate,
    onTemplateNameChange,
    onTemplateTextChange,
    onDisplayAtReceiptChange,
    onGenerateEachTransactionChange,
    onTagDetailsCancel,
    onTagDetailsSave,
    onStartEditTagCallback,
    onCancelEditTagCallback,
    clearError,
    setSelectedTemplate,
    keyDownHandler,
    changed,
    loading,
    templateNameRef,
    editorRef,
    showTagDetailsModal,
  } = usePaymentDescription({
    templates,
    setTemplates,
    initialTemplate,
    setInitialTemplate,
    onKeyDown,
    storedTemplate,
  });

  const { tag, onStartEditTag, onCancelEditTag, onFinishEditTag } = useEditTag({
    template,
    onStartEditTagCallback,
    onCancelEditTagCallback,
  });
  const actionsDisabled = deletingTemplate || savingTemplate;
  const {
    displayAtReceipt,
    generateEachTransaction,
    templateId,
    templateName,
    templateText,
    errors,
  } = template;
  const selectedTemplate = templateId
    ? templates.find((item) => item.client_payment_template_id === templateId)
    : null;

  const onEditHandler = () => {
    if (templateId > 0) {
      if (disabled) {
        onEdit();
      } else {
        onCancelEdit(
          templates.find(
            (item) => item.client_payment_template_id === templateId
          )
        );
      }
    } else {
      onCancelNew();
    }
  };

  const editIcon = disabled ? (
    <VscEdit fontSize={24} />
  ) : (
    <VscDiscard fontSize={24} />
  );

  const editTitle = disabled ? "Редагувати" : "Скасувати ";

  const uncommittedTagId = template.tags?.find(
    ({ tooltip }) => !tooltip
  )?.tag_name;

  const tagProto = uncommittedTagId
    ? mentions?.find(({ id }) => id === uncommittedTagId)
    : tag
    ? mentions?.find(({ tag_name }) => tag?.tag_name.includes(`#${tag_name}#`))
    : null;

  return (
    <Container>
      {loading && <LocalLoader />}
      {showTagDetailsModal && (uncommittedTagId || tag) && tagProto && (
        <Modal
          onClose={onTagDetailsCancel}
          className="tag-editor-container"
          closeOnBackdrop={false}
          focusSelector="input[type='text']"
          hiddenBackdrop
        >
          <TagDetails
            onClose={onTagDetailsCancel}
            onSave={onTagDetailsSave}
            tagProto={tagProto}
            tag={tag || { tag_name: uncommittedTagId }}
            onCancelEdit={onCancelEditTag}
            onFinishEdit={onFinishEditTag}
          />
        </Modal>
      )}
      <Header>
        <h2>{"Шаблони призначення платежу"}</h2>
        <h3>{editorMode}</h3>
        <TimesCancelButton onClick={onClose}>
          <CloseIcon />
        </TimesCancelButton>
      </Header>
      <Content>
        <Select
          label="Шаблон"
          placeholder="оберіть шаблон"
          options={templates}
          value={selectedTemplate}
          onChange={setSelectedTemplate}
          disabled={actionsDisabled}
        />
        <EditorActions>
          <TransparentButton onClick={onNew} disabled={actionsDisabled}>
            <VscNewFile fontSize={24} />
            <span>Новий</span>
          </TransparentButton>
          <TransparentButton
            onClick={onEditHandler}
            disabled={
              !(selectedTemplate || templateId === null) || actionsDisabled
            }
          >
            {editIcon}
            <span>{editTitle}</span>
          </TransparentButton>
          {deletingTemplate ? (
            <PreLoader
              style={{
                marginTop: 0,
                paddingRight: "1rem",
                height: "64px",
                width: "auto",
              }}
            />
          ) : (
            <TransparentButton
              onClick={() => onDelete()}
              disabled={!selectedTemplate || actionsDisabled}
            >
              <VscTrash fontSize={24} />
              <span>Видалити</span>
            </TransparentButton>
          )}
        </EditorActions>
        <TextField
          name="Назва шаблону"
          placeholder="вкажіть назву шаблону"
          value={templateName || ""}
          fn={onTemplateNameChange}
          className="input-active"
          error={errors.templateName}
          disabled={disabled || actionsDisabled}
          fnFocus={() => {
            clearError("templateName");
          }}
          inputRef={templateNameRef}
        />
        <ErrorText style={ERROR_TEXT_STYLE}>{errors.templateName}</ErrorText>
        <Editor
          singleLine={false}
          label="Текст шаблону"
          mentions={mentions}
          disabled={disabled || actionsDisabled}
          value={templateText}
          onFocus={() => {
            clearError("templateText");
          }}
          onChange={onTemplateTextChange}
          onKeyDown={keyDownHandler}
          onEdit={onStartEditTag}
          error={errors.templateText}
          inputRef={editorRef}
        />
        <ErrorText style={EDITOR_ERROR_TEXT_STYLE}>
          {errors.templateText}
        </ErrorText>
        <SwitchContainer>
          <Switch
            onChange={onDisplayAtReceiptChange}
            checked={Boolean(displayAtReceipt)}
            height={25}
            width={40}
            handleDiameter={17}
            uncheckedIcon={false}
            checkedIcon={false}
            offHandleColor="#fff"
            onHandleColor="#fff"
            activeBoxShadow={"false"}
            offColor="#c5c5c5"
            onColor="#28ccc6"
            className={"switch"}
          />
          <span>показувати призначення платежу в чеку</span>
        </SwitchContainer>
        <SwitchContainer>
          <Switch
            onChange={onGenerateEachTransactionChange}
            checked={Boolean(generateEachTransaction)}
            height={25}
            width={40}
            handleDiameter={17}
            uncheckedIcon={false}
            checkedIcon={false}
            offHandleColor="#fff"
            onHandleColor="#fff"
            activeBoxShadow={"false"}
            offColor="#c5c5c5"
            onColor="#28ccc6"
            className={"switch"}
          />
          <span>нове призначення платежу для кожної транзакції</span>
        </SwitchContainer>
      </Content>
      <ModalActions>
        {savingTemplate ? (
          <PreLoader />
        ) : (
          <Button onClick={onSaveTemplate} disabled={!changed}>
            Зберегти
          </Button>
        )}
        <OutlinedButton onClick={onClose}>Закрити</OutlinedButton>
      </ModalActions>
    </Container>
  );
};

export default PaymentDescriptionTemplates;
