import { useHistory } from "react-router-dom";
import TableHeaders from "components/layouts/Table/Headers/Table.headers";
import { TableContainer, Td } from "components/layouts/Table/Table.styles";
import { TrMain } from "./Main.styles";
import { SEARCH_TYPE } from "utils/constants/table.headers.constants";
import {
  Terminal,
  TerminalText,
  TerminalTitle,
  TerminalDescribe,
} from "./Main.styles";
import { ReactComponent as TerminalIcon } from "assets/icons/main_table_transaction.svg";
import { useEffect } from "react";
import {
  fetchServer,
  filterTerminalsState,
  setTerminal,
  setTransaction,
  setCash,
  setCard,
  setCashless,
  setQR,
  setAmount,
  setItem,
} from "store/main_table/main_table.actions";
import { useDispatch, useSelector } from "react-redux";
import { loadServer, loadLocal } from "store/main_table/main_table.slicer";
import Preloader from "components/additional/PreLoader/Preloader";
import { TERMINALS_ROUTE } from "utils/constants/routes.constants";

export default function Table() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isServerLoaded = useSelector(loadServer);
  const isLocalLoaded = useSelector(loadLocal);
  const state = useSelector((store) => store.main_table.state_server_filtered);

  const termVal = useSelector(
    (state) => state.main_table.main_table_search_terminal
  );
  const transactionVal = useSelector(
    (state) => state.main_table.main_table_search_transaction
  );
  const cardVal = useSelector(
    (state) => state.main_table.main_table_search_card
  );
  const cashVal = useSelector(
    (state) => state.main_table.main_table_search_cash
  );
  const cashlessVal = useSelector(
    (state) => state.main_table.main_table_search_cashless
  );
  const qrVal = useSelector((state) => state.main_table.main_table_search_qr);
  const amountVal = useSelector(
    (state) => state.main_table.main_table_search_amount
  );

  useEffect(() => {
    // if (!isServerLoaded && state.length === 0) {
    dispatch(fetchServer());
    // }
  }, [dispatch]);

  const search = (fn, val) => {
    dispatch(fn(val));
    dispatch(filterTerminalsState());
  };

  return (
    <>
      {!isServerLoaded && <Preloader />}
      {!isLocalLoaded && state.length === 0 && (
        <TerminalTitle style={{ marginTop: "20px" }}>
          Термінали відсутні
        </TerminalTitle>
      )}
      <TableContainer>
        <thead>
          <TrMain>
            <TableHeaders
              choose_state={termVal}
              type={SEARCH_TYPE}
              fn={(val) => search(setTerminal, val)}
              fn_reset={() => search(setTerminal, null)}
              text="Термінал"
            />
            <TableHeaders
              choose_state={transactionVal}
              type={SEARCH_TYPE}
              fn={(val) => search(setTransaction, val)}
              fn_reset={() => search(setTransaction, null)}
              text="Транзакцій"
            />
            <TableHeaders
              choose_state={cardVal}
              type={SEARCH_TYPE}
              text="Карткою"
              fn={(val) => search(setCard, val)}
              fn_reset={() => search(setCard, null)}
            />
            <TableHeaders
              choose_state={cashVal}
              type={SEARCH_TYPE}
              text="Готівкою"
              fn={(val) => search(setCash, val)}
              fn_reset={() => search(setCash, null)}
            />
            <TableHeaders
              choose_state={cashlessVal}
              type={SEARCH_TYPE}
              text="Безготівково"
              fn={(val) => search(setCashless, val)}
              fn_reset={() => search(setCashless, null)}
            />
            <TableHeaders
              choose_state={qrVal}
              type={SEARCH_TYPE}
              text="QR-оплата"
              fn={(val) => search(setQR, val)}
              fn_reset={() => search(setQR, null)}
            />
            <TableHeaders
              choose_state={amountVal}
              type={SEARCH_TYPE}
              fn={(val) => search(setAmount, val)}
              fn_reset={() => search(setAmount, null)}
              text="Всього"
            />
          </TrMain>
        </thead>
        <tbody>
          {!isLocalLoaded &&
            state.length !== 0 &&
            state.map((item, key) => {
              if (!item.binded) return null;

              return (
                <TrMain
                  key={key}
                  column={true}
                  onClick={() => {
                    dispatch(setItem(item));
                    history.push(`${TERMINALS_ROUTE}/${item.ptname}`);
                  }}
                >
                  <Td flex_start={true}>
                    <Terminal>
                      <TerminalIcon />
                      <TerminalText>
                        <TerminalTitle>{item.ptdesctiption}</TerminalTitle>
                        <TerminalDescribe>
                          {item.device_model}, Terminal ID: {item.ptname}
                        </TerminalDescribe>
                      </TerminalText>
                    </Terminal>
                  </Td>
                  <Td>
                    <TerminalText>
                      <TerminalTitle>{item.transactions_count}</TerminalTitle>
                      <TerminalDescribe>Транзакцій</TerminalDescribe>
                    </TerminalText>
                  </Td>
                  <Td>
                    <TerminalText>
                      <TerminalTitle>
                        {item.card_amount.toFixed(2)}₴
                      </TerminalTitle>
                      <TerminalDescribe>Карткою</TerminalDescribe>
                    </TerminalText>
                  </Td>
                  <Td>
                    <TerminalText>
                      <TerminalTitle>
                        {item.cash_amount.toFixed(2)}₴
                      </TerminalTitle>
                      <TerminalDescribe>Готівкою</TerminalDescribe>
                    </TerminalText>
                  </Td>
                  <Td>
                    <TerminalText>
                      <TerminalTitle>
                        {item.cashless_amount.toFixed(2)}₴
                      </TerminalTitle>
                      <TerminalDescribe>Безготівково</TerminalDescribe>
                    </TerminalText>
                  </Td>
                  <Td>
                    <TerminalText>
                      <TerminalTitle>
                        {item.qr_amount.toFixed(2)}₴
                      </TerminalTitle>
                      <TerminalDescribe>QR-оплата</TerminalDescribe>
                    </TerminalText>
                  </Td>
                  <Td>
                    <TerminalText right={true}>
                      <TerminalTitle>
                        {item.total_amount.toFixed(2)}₴
                      </TerminalTitle>
                      <TerminalDescribe>Всього</TerminalDescribe>
                    </TerminalText>
                  </Td>
                </TrMain>
              );
            })}
        </tbody>
      </TableContainer>
    </>
  );
}
