import { font_book } from "assets/style/fonts.variables";
import { grey, tiffany, white } from "assets/style/variables";
import { ButtonSecondary } from "components/forms/Button/Button.styles";
import styled from "styled-components";
import { ButtonSwitcher } from "../../../Bank/Bank.styles";
import { size } from "assets/style/global-variables";

export const GoodsContainer = styled.section`
  margin: 0;
`;

export const GoodsHeader = styled.section`
  display: flex;
  justify-content: space-between;
`;

export const TableContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const GoodsTableContainer = styled.div`
  margin: 0;
  margin-top: 25px;
  width: 100%;
  min-width: 568px;

  span {
    font-family: ${font_book};
  }

  a {
    text-decoration: none;
  }
`;

export const TrMain = styled.div`
  display: grid;
  grid-template-columns: 40px 2fr 1fr 1fr 1fr 1fr;
  padding: 5px 12px;
  height: fit-content;

  @keyframes goods-item-animation {
    0% {
      max-height: 0px;
      opacity: 0;
      padding: 0 12px;
    }
    100% {
      max-height: 300px;
      opacity: 1;
    }
  }

  animation: goods-item-animation 1s ease;

  div.head-cell {
    color: ${grey};
    font-family: ${font_book};
    font-size: 14px;
    font-weight: 400;

    span {
      margin-left: 5px;
      text-align: left;
    }
  }

  p {
    margin-top: 0;
  }

  div.body-cell {
    align-self: center;
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;

    input[type="checkbox"] {
      width: 25px;
      height: 25px;
    }
  }
  ${(props) =>
    props.column
      ? `
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin-top: 12px;
  &:hover{
    cursor: pointer;
    background-color: #eee;
  }
  `
      : null}

  align-items: center;

  .head-cell__photo,
  .body-cell__photo {
    display: none;
  }

  @media screen and (min-width: ${size.tablet}) {
    grid-template-columns: 40px 2fr 1fr 1fr 1fr 1fr 1fr;

    .head-cell__photo,
    .body-cell__photo {
      display: unset;
    }
  }
`;

export const SearchTh = styled.div`
  width: calc(100% - 10px);
  margin-left: 5px;
  border: 1px solid #f1f1f1;
  display: flex;
  align-items: center;
  column-gap: 7px;
  border-radius: 6px;
  padding: 5px 5px;

  svg {
    flex-shrink: 0;
  }

  input {
    width: 100%;
    outline: none;
    border: none;
    font-family: ${font_book};
    font-size: 14px;

    &::placeholder {
      color: #70787f;
      letter-spacing: 0.5px;
    }

    &:-ms-input-placeholder {
      color: #70787f;
      letter-spacing: 0.5px;
    }

    &::-ms-input-placeholder {
      color: #70787f;
      letter-spacing: 0.5px;
    }
  }
`;

export const Button = styled(ButtonSecondary)`
  font-size: 14px;
  font-family: ${font_book};
  font-weight: 400;
  background: ${white};
  border: 1px solid rgba(112, 120, 127, 0.2);
  border-radius: 10px;
  padding: 8px 30px;
`;

export const ButtonAdd = styled(ButtonSwitcher)`
  padding: 14px 0;
  max-width: 163px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  justify-content: center;
  .plus-blue {
    margin-right: 10px;
    font-size: 22px;
    color: ${tiffany};
  }
`;

export const NameContainer = styled.span`
  font-weight: 600;
  font-size: 17px;
  font-family: "Futura PT", sans-serif;
  text-overflow: ellipsis;
`;

export const PriceContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  row-gap: 5px;
`;

export const MainPrice = styled.span`
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  font-family: "Futura PT", sans-serif;
`;

export const PriceWithoutTax = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  justify-content: flex-end;

  span {
    font-weight: 500;
    font-size: 14px;
    color: #798080;
    font-family: "Futura PT", sans-serif;
  }
`;

export const CalculationContainer = styled.span`
  font-weight: 500;
  font-size: 14px;
  font-family: "Futura PT", sans-serif;
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100px;
    border-radius: 3px;
  }
`;
