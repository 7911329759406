import React, { useEffect, useState } from "react";
import {
  SearchContainer,
  SearchInputContainer,
  SearchInput,
  IconSpan,
  IconSpanClose,
} from "./SearchBox.styles";
import { ReactComponent as SearchIcon } from "assets/icons/searchbox.svg";
import { ReactComponent as CloseIcon } from "assets/icons/searchbox_close.svg";

export default function SearchBox({ title, state, fn, fn_reset }) {
  const [isActive, setIsActive] = useState(
    state !== null && state !== undefined
  );
  const [isFocus, setIsFocus] = useState(false);
  const [value, setValue] = useState(state === null ? "" : state);

  useEffect(() => {
    setValue(state === null ? "" : state);
  }, [state]);

  function search(e) {
    const val = e.target.value;
    setValue(val);
  }

  function clearSearch() {
    setValue("");
    setIsActive(false);
    fn_reset();
    setIsFocus(false);
  }

  function generateResult(e) {
    if (e.key === "Enter") {
      if (value.length !== 0) {
        fn(value);
        setIsActive(true);
        return;
      }
      clearSearch();
    }
  }

  return (
    <SearchContainer>
      <SearchInputContainer>
        <SearchInput
          value={value}
          active={isFocus}
          onChange={search}
          placeholder={title}
          onKeyPress={generateResult}
        />
        <IconSpan>
          <SearchIcon />
        </IconSpan>
        {isActive ? (
          <IconSpanClose onClick={clearSearch}>
            <CloseIcon />
          </IconSpanClose>
        ) : null}
      </SearchInputContainer>
    </SearchContainer>
  );
}
