import axios from "axios";

import { LocalStorage } from "services";
import Fetch from "services/Fetch/Fetch.service";
import { URL_SERVER } from "utils/constants/server.constants";

export const GOODS_LIMIT = 20;

export class GoodsTable {
  constructor() {
    this.fetch = new Fetch();
    this.localStorage = new LocalStorage();
    this.merchantId = this.localStorage.get("merchantId");
    this.shopId = this.localStorage.get("shopId");
  }

  generateHeader() {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    return headers;
  }

  get(pagination) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    return axios.get(
      `${URL_SERVER}/api/Producst/${this.merchantId}/shops/${
        this.shopId
      }/products?limit=${GOODS_LIMIT}&offset=${pagination * GOODS_LIMIT}`,
      {
        headers: {
          "Content-Type": "application/json",
          Token: token,
          DeviceId: "WEB_" + device,
        },
      }
    );
  }

  getGoodsByName(name) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    let baseUrl = `${URL_SERVER}/api/Producst/GetProductsFilter/${this.merchantId}?shopId=${this.shopId}&limit=${GOODS_LIMIT}&offset=0&stringMatch=${name}`;

    return axios.get(baseUrl, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });
  }

  getWithFilter({ pagination, filterState, search }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    let baseUrl = `${URL_SERVER}/api/Producst/GetProductsFilter/${
      this.merchantId
    }?shopId=${this.shopId}&limit=${GOODS_LIMIT}&offset=${
      pagination * GOODS_LIMIT
    }`;

    if (search) {
      baseUrl += `&stringMatch=${search}`;
    }

    if (filterState?.name?.value && filterState?.name?.value !== "") {
      baseUrl += `&title=${filterState?.name?.value}`;
    }

    if (filterState?.category?.value) {
      baseUrl += `&categoryId=${filterState.category.value.id}`;
    }

    if (filterState?.calculations?.value) {
      baseUrl += `&unitId=${filterState.calculations.value.unit_id}`;
    }

    if (filterState?.inside_code?.value) {
      baseUrl += `&code=${filterState.inside_code.value}`;
    }

    if (filterState?.price?.value) {
      baseUrl += `&price=${filterState.price.value}`;
    }

    return axios.get(baseUrl, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });
  }

  getOneGoodById(id) {
    return this.fetch
      .get(
        `${URL_SERVER}/api/Producst/${this.merchantId}/shops/${this.shopId}/products/${id}`,
        this.generateHeader()
      )
      .then((res) => res)
      .catch((error) => console.log(error));
  }

  updateGood({ body, id }) {
    return this.fetch.put(
      `${URL_SERVER}/api/Producst/${this.merchantId}/shops/${this.shopId}/products/${id}`,
      body,
      this.generateHeader()
    );
  }

  deleteGood({ id }) {
    return this.fetch.delete(
      `${URL_SERVER}/api/Producst/${this.merchantId}/shops/${this.shopId}/products/${id}`,
      this.generateHeader()
    );
  }

  deleteStack({ ids }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const baseUrl = `${URL_SERVER}/api/Producst/ProductsMassOperation/${this.merchantId}`;

    return axios.post(
      baseUrl,
      {
        poroductIds: ids,
        operationType: "del",
        categoryId: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Token: token,
          DeviceId: "WEB_" + device,
        },
      }
    );
  }

  transerStackToCategory({ ids, categoryId }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const baseUrl = `${URL_SERVER}/api/Producst/ProductsMassOperation/${this.merchantId}`;

    return axios.post(
      baseUrl,
      {
        poroductIds: ids,
        operationType: "move",
        categoryId: categoryId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Token: token,
          DeviceId: "WEB_" + device,
        },
      }
    );
  }

  getCategories() {
    return this.fetch
      .get(
        `${URL_SERVER}/api/Producst/${this.merchantId}/shops/${this.shopId}/productcategories`,
        this.generateHeader()
      )
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  getChildrenCategoriesById(categoryId) {
    return this.fetch
      .get(
        `${URL_SERVER}/api/Producst/${this.merchantId}/shops/${this.shopId}/productcategories/${categoryId}`,
        this.generateHeader()
      )
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  createCategory({ name, parentId }) {
    return this.fetch.post(
      `${URL_SERVER}/api/Producst/${this.merchantId}/shops/${this.shopId}/productcategories`,
      {
        name,
        parentId,
        image: null,
      },
      this.generateHeader()
    );
  }

  deleteCategory(categoryId) {
    return this.fetch.delete(
      `${URL_SERVER}/api/Producst/${this.merchantId}/shops/${this.shopId}/productcategories/${categoryId}`,
      this.generateHeader()
    );
  }

  editCategory({ categoryId, name }) {
    return this.fetch.put(
      `${URL_SERVER}/api/Producst/${this.merchantId}/shops/${this.shopId}/productcategories/${categoryId}`,
      {
        name,
        image: "",
      },
      this.generateHeader()
    );
  }

  getUnits() {
    return this.fetch
      .get(`${URL_SERVER}/api/Goods/Units`, this.generateHeader())
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  getTaxes() {
    return this.fetch
      .get(`${URL_SERVER}/api/Producst/GetTaxesSpr`, this.generateHeader())
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  getAllCategoriesForCreateProd() {
    return this.fetch
      .get(
        `${URL_SERVER}/api/Producst/${this.merchantId}/shops/${this.shopId}/categories`,
        this.generateHeader()
      )
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  createGood(body) {
    return this.fetch.post(
      `${URL_SERVER}/api/Producst/${this.merchantId}/shops/${this.shopId}/products`,
      body,
      this.generateHeader()
    );
  }

  importFile(file, encoding) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const formData = new FormData();

    formData.append("File", file);
    formData.append("FileName", file.name);
    formData.append("Encoding", encoding);

    return axios.post(`${URL_SERVER}/api/Goods/Import`, formData, {
      headers: {
        Token: token,
        DeviceId: "WEB_" + device,
        "Content-Disposition":
          "attachment; filename*=UTF-8''" +
          unescape(encodeURIComponent(file.name)),
      },
    });
  }

  async exportGoods(body) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const baseUrl = `${URL_SERVER}/api/Goods/Export`;

    return await axios({
      baseURL: window.location.origin,
      url: baseUrl,
      method: "POST",
      data: body,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });
  }
}
