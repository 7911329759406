import moment from "moment";

import LocalStorage from "services/LocalStorage/LocalStorage.service";
import Fetch from "services/Fetch/Fetch.service";
import { SessionStorage } from "services";
import { URL_SERVER } from "utils/constants/server.constants";
import download from "utils/helpers/download.helpers";

export class BankOpen {
  constructor() {
    this.fetch = new Fetch();
    this.localStorage = new LocalStorage();
    this.sessionStorage = new SessionStorage();
  }

  async get(
    sync = false,
    search_text,
    dateFrom,
    dateTo,
    trans,
    amount,
    batch,
    term
  ) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    let body = {
      sync_data: sync,
    };

    if (dateFrom) body.oper_date = dateFrom;
    if (dateTo) body.oper_date_to = dateTo;
    if (search_text) body.search_text = search_text;
    if (trans) body.search_transaction = trans;
    if (amount) body.search_amount = amount;
    if (batch) body.search_batch = batch;
    if (term) body.search_terminal = term;

    return (
      this.fetch
        // .put(`${URL_SERVER}/api/BusinessDayTransaction`, body, headers)
        .post(`${URL_SERVER}/api/BusinessDayTotals`, body, headers)
        .catch((error) => console.error(error))
    );
  }

  async closedDay(ptname) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    let body = {
      ptname,
    };

    return this.fetch
      .put(`${URL_SERVER}/api/BusinessDayClose`, body, headers)
      .catch((error) => console.error(error));
  }

  async downloadExcel({
    ptname,
    bdd,
    batch,
    dateFrom,
    dateTo,
    status,
    search_rrn,
    search_amount,
    search_method_payment,
    search_terminal,
    search_cashier,
    extended_rep,
  }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const search_id = localStorage.getItem("business_search_id");
    const ss_ptname = this.sessionStorage.get("ptname");

    if (!ptname) ptname = "ALL";

    const headers = {
      Token: token,
      DeviceId: "WEB_" + device,
      responseType: "blob",
    };

    const body = {
      start_index: -1,
      ptname,
      search_id,
    };

    if (ss_ptname) body.ptname = ss_ptname;
    if (bdd) body.bdd = moment(bdd).format("YYYYMMDD");
    if (batch) body.batch = batch;
    if (dateFrom) body.date_from = dateFrom;
    if (dateTo) body.date_to = dateTo;
    if (status) body.status = status;
    if (search_amount) body.search_amount = search_amount;
    if (search_rrn) body.rrn_search = search_rrn;
    if (search_method_payment)
      body.search_method_payment = search_method_payment;
    if (search_terminal) body.terminal_search = search_terminal;
    if (search_cashier) body.search_cashier = search_cashier;
    body.extended_rep = extended_rep;

    return this.fetch
      .postBlobWithAxios(
        `${URL_SERVER}/api/Reports/TransactionExport/ExportExcel`,
        body,
        headers
      )
      .then((result) => {
        if (!result) return;

        const { fileData, fileName } = result;

        download(
          fileData,
          fileName,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      });
  }

  async downloadPdf({
    ptname,
    bdd,
    batch,
    dateFrom,
    dateTo,
    status,
    search_rrn,
    search_amount,
    search_method_payment,
    search_terminal,
    search_cashier,
    extended_rep,
  }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const search_id = localStorage.getItem("business_search_id");
    const ss_ptname = this.sessionStorage.get("ptname");

    if (!ptname) ptname = "ALL";

    const headers = {
      Token: token,
      DeviceId: "WEB_" + device,
    };

    const body = {
      start_index: -1,
      ptname,
      search_id,
    };

    if (ss_ptname) body.ptname = ss_ptname;
    if (bdd) body.bdd = moment(bdd).format("YYYYMMDD");
    if (batch) body.batch = batch;
    if (dateFrom) body.date_from = dateFrom;
    if (dateTo) body.date_to = dateTo;
    if (status) body.status = status;
    if (search_amount) body.search_amount = search_amount;
    if (search_rrn) body.rrn_search = search_rrn;
    if (search_method_payment)
      body.search_method_payment = search_method_payment;
    if (search_terminal) body.terminal_search = search_terminal;
    if (search_cashier) body.search_cashier = search_cashier;
    body.extended_rep = extended_rep;

    return this.fetch
      .postBlobWithAxios(
        `${URL_SERVER}/api/Reports/TransactionExport/ExportPDF`,
        body,
        headers
      )
      .then((result) => {
        if (!result) return;

        const { fileData, fileName } = result;

        download(fileData, fileName, "application/pdf");
      });
  }
}
