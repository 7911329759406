import styled from "styled-components";

export const NewsDetailsLinkButton = styled.button`
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding-left: 3rem;
  border: none;
  outline: none;
  color: white;
  font-family: inherit;
  font-size: inherit;
  text-align: left;
  cursor: pointer;
`;
