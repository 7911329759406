import React, { useEffect } from "react";
import { useHeader } from "store/header/header.context";
import ChangeTitle from "utils/helpers/changetitle.helpers";

// components
import FileLink from "components/forms/FileLink/FileLink";

// constants
import { getUUID } from "utils/helpers/getUUID";
import {
  // FISCAL_INSTRUCTION_HREF,
  MOBILE_INSTRUCTION_HREF,
  FAQ_INSTRUCTION_HREF,
} from "../../../utils/constants/server.constants";

// styles
import { QuestionsContainer, QuestionsItem } from "./questions.styles";

const LINKS = [
  {
    id: getUUID(),
    caption: "Інструкція користувача мобільного додатку ОщадPay",
    href: MOBILE_INSTRUCTION_HREF,
  },
  // {
  //   id: getUUID(),
  //   caption: "Інструкція користувача ПРРО «ОщадPay»",
  //   href: FISCAL_INSTRUCTION_HREF,
  // },
  {
    id: getUUID(),
    caption: "Відповіді на питання, які часто ставляться",
    href: FAQ_INSTRUCTION_HREF,
  },
];

const Questions = () => {
  const dispatch = useHeader().dispatch;

  useEffect(() => {
    ChangeTitle("Питання та відповіді", dispatch);
  }, [dispatch]);

  return (
    <QuestionsContainer>
      {LINKS.map((link) => (
        <QuestionsItem key={link.id}>
          <FileLink
            href={link.href}
            target="_blank"
            caption={link.caption}
            rel="noreferrer"
          />
        </QuestionsItem>
      ))}
    </QuestionsContainer>
  );
};

export default Questions;
