import React from "react";
import Modal from "ui/Modal/Modal";
import PaymentDescriptionTemplates from "../../PaymentDescriptionTemplates/PaymentDescriptionTemplates";

const TemplateSettingsModal = ({
  setTemplateSettingsOpened,
  clientPaymentTemplates,
  setClientPaymentTemplates,
  setClientPaymentTemplate,
  clientPaymentTemplateId,
  storedPaymentTemplateId,
}) => {
  return (
    <Modal
      onClose={() => setTemplateSettingsOpened(false)}
      className="templates-editor-container"
      closeOnBackdrop={false}
      focusSelector="textarea"
    >
      <PaymentDescriptionTemplates
        onClose={() => setTemplateSettingsOpened(false)}
        templates={clientPaymentTemplates}
        setTemplates={setClientPaymentTemplates}
        selectedTemplate={clientPaymentTemplates.find(
          (item) => item.value === clientPaymentTemplateId
        )}
        setSelectedTemplate={setClientPaymentTemplate}
        storedTemplate={clientPaymentTemplates.find(
          (item) => item.value === storedPaymentTemplateId
        )}
      />
    </Modal>
  );
};

export default TemplateSettingsModal;
