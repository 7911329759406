import TableHeaders from "components/layouts/Table/Headers/Table.headers";
import { Td, Th } from "components/layouts/Table/Table.styles";
import { SEARCH_TYPE } from "utils/constants/table.headers.constants";
import {
  TableContainer,
  TrMain,
  Terminal,
  TerminalText,
  TerminalTitle,
  TerminalDescribe,
} from "./TerminalPickerTable.styles";
import { grey } from "assets/style/variables";

export default function Table({
  idFilterValue,
  setIdFilterValue,
  nameFilterValue,
  setNameFilterValue,
  search,
  onSelectAllTerminals,
  onSelectTerminal,
  filteredTerminals,
  selectedTerminals,
  terminals,
}) {
  const selectedAllTerminals = terminals.length === selectedTerminals.length;

  const Checkbox = ({ checked, disabled, onChange }) => (
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      style={{
        cursor: disabled ? "default" : "pointer",
      }}
    />
  );

  return (
    <TableContainer>
      <thead>
        <TrMain column>
          <Th
            flex_start
            no_border
            style={{
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <TerminalText style={{ width: "100%" }}>
              <TerminalTitle>Вибрано</TerminalTitle>
              <TerminalDescribe>
                <Checkbox
                  checked={selectedAllTerminals}
                  onChange={onSelectAllTerminals}
                />
              </TerminalDescribe>
            </TerminalText>
          </Th>
          <TableHeaders
            choose_state={idFilterValue}
            type={SEARCH_TYPE}
            fn={(val) => search(setIdFilterValue, val, "id")}
            fn_reset={() => search(setIdFilterValue, "", "id")}
            text="ID"
          />
          <TableHeaders
            choose_state={nameFilterValue}
            type={SEARCH_TYPE}
            fn={(val) => search(setNameFilterValue, val, "description")}
            fn_reset={() => search(setNameFilterValue, "", "description")}
            text="Назва"
          />
          <Th flex_end no_border>
            <TerminalText right style={{ width: "100%" }}>
              <TerminalTitle>Транзакцій</TerminalTitle>
              <TerminalDescribe>Сума</TerminalDescribe>
            </TerminalText>
          </Th>
        </TrMain>
      </thead>
      <tbody>
        {filteredTerminals.map((item) => {
          const { id, description, amount, count, disabled } = item;
          const checked = selectedTerminals.find((item) => item.id === id);
          const color = disabled ? grey : "currentColor";
          const onSelectChange = () => onSelectTerminal(item, checked);

          return (
            <TrMain
              key={id}
              column={true}
              disabled={disabled}
              onClick={disabled ? null : onSelectChange}
            >
              <Td flex_start>
                <Checkbox
                  disabled={disabled}
                  checked={checked}
                  onChange={onSelectChange}
                />
              </Td>
              <Td flex_start>
                <Terminal>
                  <TerminalText>
                    <TerminalTitle style={{ color }}>{id}</TerminalTitle>
                  </TerminalText>
                </Terminal>
              </Td>
              <Td flex_start>
                <TerminalText>
                  <TerminalTitle style={{ color }}>{description}</TerminalTitle>
                </TerminalText>
              </Td>
              <Td flex_end>
                <TerminalText right>
                  <TerminalTitle style={{ color }}>{count}</TerminalTitle>
                  <TerminalDescribe>{amount}</TerminalDescribe>
                </TerminalText>
              </Td>
            </TrMain>
          );
        })}
      </tbody>
    </TableContainer>
  );
}
