import styled from "styled-components";

import { oshad_green } from "assets/style/variables";

export const EditorRowButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: auto;
  cursor: pointer;

  &:hover {
    svg {
      color: ${oshad_green};
    }
  }
`;
