import styled from "styled-components";
import { flex_center, black, white } from "assets/style/variables";
import { font_book } from "assets/style/fonts.variables";
import { ButtonTransactionDetail } from "components/forms/Button/Button.styles";

export const BankContainer = styled.section`
  margin: 0;

  &.fiscal {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      width: auto;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .progress {
      display: flex;
      align-items: baseline;
      gap: 1.5rem;
      font-family: ${font_book};
    }

    .ring {
      height: 2rem;
      width: 2rem;

      div {
        height: 2rem;
        width: 2rem;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 22px;
  padding: 18px;
  /* max-width: 400px; */
  ${flex_center}
  grid-gap: 10px;
`;

export const ButtonSwitcher = styled(ButtonTransactionDetail)`
  padding: 8px;
  font-size: 16px;
  ${(props) =>
    !props.active
      ? `
color: ${black};
background: ${white};
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
`
      : null};
`;
