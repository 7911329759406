import { DEF_SORT } from "utils/constants/sort.constants";

import {
  TABLE_SET_SERVER,
  TABLE_SET_LOCAL,
  TABLE_LOAD,
  TABLE_SERVER_LOAD,
  TABLE_CHANGE_BUTTON,
  TABLE_SORT_TYPE,
  TABLE_SORT,
  TABLE_RESET,
  TABLE_SET_FILTER,
  TABLE_SET_FILTER_DATES,
  TABLE_SET_FILTER_METHOD,
  TABLE_SET_FILTER_RRN,
  TABLE_SET_FILTER_TOTAL,
  TABLE_SET_PLUS_INDEX,
  TABLE_SORT_CASHIER,
  TABLE_SORT_TERMINAL,
  TABLE_SORT_DATE,
  TABLE_SORT_PAYMENT,
  TABLE_SORT_NUMBER,
  TABLE_SORT_TOTAL,
  TABLE_SET_ITEM,
  TABLE_SET_FISKAL,
  //
  // clean code
  SET_TRANSACTION_FILTERING_STATE,
  SET_BANK_FILTERING_STATE,
  SET_FISCAL_FILTERING_STATE,
} from "./transaction_table.types";

const initialState = {
  start_index: 0,
  state_server: [],
  state_local: [],

  transactions_filtering_state: {
    status: null,
    startDate: null,
    endDate: null,
    terminal_search: null,
    search_cashier: null,
    search_method_payment: null,
    rrn_search: null,
    amount_search: null,
    fiscal_status: null,
  },
  bank_filtering_state: {
    status: null,
    startDate: null,
    endDate: null,
    terminal_search: null,
    search_cashier: null,
    search_method_payment: null,
    rrn_search: null,
    amount_search: null,

    // static params
    ptname: null,
    bdd: null,
    batch: null,
  },
  fiscal_filtering_state: {
    status: null,
    startDate: null,
    endDate: null,
    terminal_search: null,
    search_cashier: null,
    search_method_payment: null,
    rrn_search: null,
    amount_search: null,
  },

  isLoading: false,
  isInitServer: true,
  sortType: DEF_SORT,
  sortTerminal: DEF_SORT,
  sortCashier: DEF_SORT,
  sortDate: DEF_SORT,
  sortPayment: DEF_SORT,
  sortNumber: DEF_SORT,
  sortTotal: DEF_SORT,

  isFilter: false,
  search_dates: [],
  search_method: [],
  search_rrn: [],
  search_totals: [],

  set_item: [],
  isFiskal: false,
};

export default function transactionTableReducer(state = initialState, action) {
  switch (action.type) {
    // clean code
    case SET_TRANSACTION_FILTERING_STATE:
      return {
        ...state,
        transactions_filtering_state: {
          ...state.transactions_filtering_state,
          [action.payload.field]: action.payload.value,
        },
      };
    case SET_BANK_FILTERING_STATE:
      return {
        ...state,
        bank_filtering_state: {
          ...state.bank_filtering_state,
          [action.payload.field]: action.payload.value,
        },
      };
    case SET_FISCAL_FILTERING_STATE:
      return {
        ...state,
        fiscal_filtering_state: {
          ...state.fiscal_filtering_state,
          [action.payload.field]: action.payload.value,
        },
      };
    //
    case TABLE_SET_FISKAL:
      return {
        ...state,
        isFiskal: action.payload,
      };
    case TABLE_SET_ITEM:
      return {
        ...state,
        set_item: action.payload,
      };
    case TABLE_SET_SERVER:
      return {
        ...state,
        state_server: action.payload,
      };

    case TABLE_SET_LOCAL:
      return {
        ...state,
        state_local: action.payload,
      };
    case TABLE_LOAD:
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    case TABLE_SERVER_LOAD:
      return {
        ...state,
        isInitServer: !state.isInitServer,
      };
    case TABLE_CHANGE_BUTTON:
      return {
        ...state,
        activeButtonId: action.payload,
      };

    case TABLE_SORT_TYPE:
      return {
        ...state,
        sortType: action.payload,
      };
    case TABLE_SORT_TERMINAL:
      return {
        ...state,
        sortTerminal: action.payload,
      };
    case TABLE_SORT_CASHIER:
      return {
        ...state,
        sortCashier: action.payload,
      };
    case TABLE_SORT_DATE:
      return {
        ...state,
        sortDate: action.payload,
      };
    case TABLE_SORT_PAYMENT:
      return {
        ...state,
        sortPayment: action.payload,
      };
    case TABLE_SORT_NUMBER:
      return {
        ...state,
        sortNumber: action.payload,
      };
    case TABLE_SORT_TOTAL:
      return {
        ...state,
        sortTotal: action.payload,
      };

    case TABLE_SORT:
      return {
        ...state,
        state_local: action.payload,
      };

    case TABLE_SET_FILTER_DATES:
      return {
        ...state,
        search_dates: action.payload,
      };
    case TABLE_SET_FILTER_METHOD:
      return {
        ...state,
        search_method: action.payload,
      };
    case TABLE_SET_FILTER_RRN:
      return {
        ...state,
        search_rrn: action.payload,
      };
    case TABLE_SET_FILTER_TOTAL:
      return {
        ...state,
        search_totals: action.payload,
      };

    case TABLE_RESET:
      return {
        ...state,
        state_local: state.state_server,
      };

    case TABLE_SET_PLUS_INDEX:
      return {
        ...state,
        start_index: action.payload,
      };

    case TABLE_SET_FILTER:
      return {
        ...state,
        isFilter: action.payload,
      };

    default:
      return state;
  }
}

export const loadLocal = (state) => state.transaction_table.isLoading;
export const loadServer = (state) => state.transaction_table.isInitServer;
export const stateLocal = (state) => state.transaction_table.state_local;
export const stateFiskal = (state) => state.transaction_table.isFiskal;

export const sortType = (state) => state.transaction_table.sortType;
export const sortCashier = (state) => state.transaction_table.sortCashier;
export const sortTerminal = (state) => state.transaction_table.sortTerminal;
export const sortDate = (state) => state.transaction_table.sortDate;
export const sortPayment = (state) => state.transaction_table.sortPayment;
export const sortNumber = (state) => state.transaction_table.sortNumber;
export const sortTotal = (state) => state.transaction_table.sortTotal;

export const headersValues = (state) => state.transaction_table.headers;
export const searchValues = (state) => state.transaction_table.search_state;

export const searchValuesDates = (state) =>
  state.transaction_table.search_dates;
export const searchValuesMethod = (state) =>
  state.transaction_table.search_method;

export const pageIndex = (state) => state.transaction_table.start_index;
export const loadFilter = (state) => state.transaction_table.isFilter;
export const item = (state) => state.transaction_table.set_item;
