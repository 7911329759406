import React, { useState } from "react";

import { ModalSubTitle } from "ui/Modal/modal.styles";
import {
  DisabledFiscalizationModalContainer,
  Title,
  AcceptButtonContainer,
  CheckboxContainer,
  ContainedButton,
} from "./styles";

const LimitedWorkingModal = ({ onClose }) => {
  const [notShowAgain, setNotShowAgain] = useState(false);

  const handleClose = () => {
    if (notShowAgain) {
      localStorage.setItem(
        "limited-working-modal-state",
        JSON.stringify({ date: Date.now() })
      );
    }
    onClose();
  };

  return (
    <DisabledFiscalizationModalContainer>
      <Title>Зверніть увагу!</Title>
      <ModalSubTitle style={{ textAlign: "center", whiteSpace: "pre-line" }}>
        {`У зв'язку з проведенням технічних робіт, працездатність ОщадPAY може бути обмежена в період: \n з 00:15 30.07.2022 до 3:45 31.07.2022`}
      </ModalSubTitle>
      <AcceptButtonContainer>
        <ContainedButton onClick={handleClose}>ЗРОЗУМІЛО</ContainedButton>
      </AcceptButtonContainer>
      <CheckboxContainer>
        <input
          id="decline_checkbox"
          type="checkbox"
          onChange={() => setNotShowAgain((v) => !v)}
          checked={notShowAgain}
        />
        <label htmlFor="decline_checkbox">
          більше не показувати це повідомлення
        </label>
      </CheckboxContainer>
    </DisabledFiscalizationModalContainer>
  );
};

export default LimitedWorkingModal;
