import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";

import Table from "./Transaction.table";
import ReportsLoader from "ui/ReportsLoader/ReportsLoader";

import { useHeader } from "store/header/header.context";
import ChangeTitle from "utils/helpers/changetitle.helpers";
import {
  fetchServer,
  setLocal,
  setPageIndex,
  setServer,
} from "store/transaction_table/transaction_table.actions";
import { fetchServer as fetchCashiers } from "store/cashier_table/cashier_table.actions";
import { stateLocalTerminal as selectCashiers } from "store/cashier_table/cashier_table.slicer";
import { searchValuesTransaction as selectTerminals } from "store/main_table/main_table.slicer";
import { fetchServer as fetchTerminals } from "store/main_table/main_table.actions";
import { TransactionTable } from "api";

import { TransactionContainer, TransactionHeader } from "./Transaction.styles";
import { Title } from "pages/Auth/Auth.styles";

const Transaction = (props) => {
  const { businessDay, fiscalDay, onSelectTransaction } = props;
  const location = useLocation();
  const { dispatch } = useHeader();
  const reduxDispatch = useDispatch();
  const server = new TransactionTable();

  const {
    status,
    startDate,
    endDate,
    terminal_search,
    search_cashier,
    search_method_payment,
    rrn_search,
    amount_search,
  } = useSelector(
    (state) => state.transaction_table.transactions_filtering_state
  );

  const cashiers = useSelector(selectCashiers);
  const terminals = useSelector(selectTerminals);

  useEffect(() => {
    if (!fiscalDay) {
      ChangeTitle("Журнал транзакцій", dispatch);
    }

    if (!cashiers?.length) {
      reduxDispatch(fetchCashiers());
    }

    if (!terminals?.length) {
      reduxDispatch(fetchTerminals());
    }

    if (fiscalDay) {
      reduxDispatch(
        fetchServer({ value: null, businessDay: false, fiscalDay })
      );
      return;
    }

    if (businessDay || location.state) {
      reduxDispatch(setPageIndex(0));
      reduxDispatch(setServer([]));
      reduxDispatch(setLocal([]));
      sessionStorage.removeItem("business_search_id");
      localStorage.removeItem("business_search_id");
      // reduxDispatch(
      //   fetchServer({ value: location.state, businessDay, fiscalDay: null })
      // );
      sessionStorage.removeItem("bdd");
      sessionStorage.removeItem("batch");
      return;
    }
  }, []);

  async function handleExcelLoad(isExtended) {
    return server.getExcel({
      search_text: sessionStorage.getItem("search_transaction"),
      ptname: "ALL",
      dateFrom: startDate ? moment(startDate).format("YYYYMMDD") : null,
      dateTo: endDate ? moment(endDate).format("YYYYMMDD") : null,
      status: status,
      search_rrn: rrn_search,
      search_amount: amount_search,
      search_method_payment: search_method_payment,
      search_terminal: terminal_search,
      search_cashier: search_cashier,
      extended_rep: isExtended,
    });
  }

  async function handlePdfLoad(isExtended) {
    return server.getPDF({
      search_text: sessionStorage.getItem("search_transaction"),
      ptname: "ALL",
      dateFrom: startDate ? moment(startDate).format("YYYYMMDD") : null,
      dateTo: endDate ? moment(endDate).format("YYYYMMDD") : null,
      status: status,
      search_rrn: rrn_search,
      search_amount: amount_search,
      search_method_payment: search_method_payment,
      search_terminal: terminal_search,
      search_cashier: search_cashier,
      extended_rep: isExtended,
    });
  }

  return (
    <TransactionContainer>
      <TransactionHeader>
        {businessDay && <Title>Журнал транзакцій</Title>}
        {!businessDay && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "620px",
            }}
          />
        )}
        {!fiscalDay && !businessDay && (
          <ReportsLoader
            handleExcelLoad={handleExcelLoad}
            handlePdfLoad={handlePdfLoad}
          />
        )}
      </TransactionHeader>

      <Table
        businessDay={businessDay}
        fiscalDay={fiscalDay}
        onSelectTransaction={onSelectTransaction}
      />
    </TransactionContainer>
  );
};

export default Transaction;
