import Background from "assets/images/login.png";
import BackgroundPlaceholder from "assets/images/loginplaceholder.jpg";
import LogoPath from "assets/images/logo.png";
import { ReactComponent as Block } from "assets/icons/block.svg";
import ImageLoader from "components/additional/ImageLoader/ImageLoader";
import {
  AuthContainer,
  AuthText,
  SubTitle,
  Title,
  FormContainer,
  FormContainerText,
  Logo,
  Link,
  InlineLink,
  FormContainerSize,
  ResetContainer,
  ResetBlock,
} from "./Auth.styles";
import { URL_SERVER } from "utils/constants/server.constants";

import AuthForm from "./AuthForm";
import { useState, useEffect } from "react";
import { TextField, TextFieldPassw } from "components/forms";
import { ErrorText } from "assets/style/variables";
import {
  ButtonLogin,
  ButtonSecondary,
} from "components/forms/Button/Button.styles";
import { Authentication } from "api";
import Preloader from "components/additional/PreLoader/Preloader";
import AuthRemainder from "./AuthRemainder/AuthRemainder";

const blockForm = false;

export default function AuthPage() {
  const auth = new Authentication();

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const [phoneForm, setPhoneForm] = useState(true);
  const [codeForm, setCodeForm] = useState(false);
  const [passwForm, setPasswForm] = useState(false);

  const [resetLoader, setResetLoader] = useState(false);

  const [passw, setPassw] = useState("");
  const [rpassw, setRPassw] = useState("");
  const [showReset, setShowReset] = useState(false);
  const [resetErr, setResetErr] = useState({
    isError: false,
    msg: "",
  });
  const [emailErr, setEmailErr] = useState({
    isError: false,
    msg: "",
  });
  const [codeErr, setCodeErr] = useState({
    isError: false,
    msg: "",
  });
  const [passwErr, setPasswErr] = useState({
    isError: false,
    msg: "",
  });
  const [rpasswErr, setRPasswErr] = useState({
    isError: false,
    msg: "",
  });

  const [authRemainderVisible, setAuthRemainderVisible] = useState(false);

  const hideAuthRemainder = () => {
    if (authRemainderVisible) {
      setAuthRemainderVisible(false);
    }
  };

  function validateFormPassw() {
    let err = false;
    const fields = [
      {
        val: passw,
        error: passwErr,
        setErr: setPasswErr,
      },
      {
        val: rpassw,
        error: rpasswErr,
        setErr: setRPasswErr,
      },
    ];
    // check fields empty
    fields.forEach((el) => {
      if (el.val.length === 0) {
        el.setErr({ isError: true, msg: "Заповніть поле!" });
        err = true;
      } else {
        el.setErr({ isError: false, msg: "" });
      }
    });

    if (err) return false;

    // check passw empty

    if (passw.length < 8) {
      setPasswErr({ isError: true, msg: "Пароль має бути більше 8 символів!" });
      err = true;
    } else {
      setPasswErr({ isError: false, msg: "" });
    }
    if (err) return false;

    if (passw !== rpassw) {
      setRPasswErr({ isError: true, msg: "Паролі не співпадають!" });
      setPasswErr({ isError: true, msg: "Паролі не співпадають!" });
      err = true;
    }
    if (err) return false;
    return true;
  }

  function handleCode(val) {
    const reg = new RegExp("^[0-9]+$", "g");
    if (!val.match(reg)) return;
    if (val.length <= 6) return setCode(val);
  }
  function handlePhone(val) {
    if (val.length === 21) return;
    const reg = new RegExp("^[+0-9]+$", "g");
    if (val.length === 0) return setPhone("+380" + val);
    if (!val.match(reg)) return;
    if (val.length === 4) return setPhone("");
    if (val.length >= 1) return setPhone("+380" + val.slice(4));
  }

  function validateFrom() {
    if (phoneForm) {
      setResetErr({
        isError: false,
        msg: "",
      });
      if (phone.length !== 13) {
        setResetErr({
          isError: true,
          msg: "Введіть коректний номер телефону!",
        });

        return false;
      }
      if (email.length < 3) {
        setEmailErr({
          isError: true,
          msg: "Введіть коректний email!",
        });

        return false;
      }
    }

    if (codeForm) {
      setCodeErr({
        isError: false,
        msg: "",
      });
      if (code.length === 0) {
        setCodeErr({
          isError: true,
          msg: "Заповніть поле!",
        });

        return false;
      }
    }

    if (passwForm) {
      return validateFormPassw();
    }

    return true;
  }

  function submit() {
    if (validateFrom()) {
      if (phoneForm) {
        setResetLoader(true);
        auth.sendSms(phone, email).then((res) => {
          setResetLoader(false);
          if (res.ErrorCode === 0) {
            setPhoneForm(false);
            setCodeForm(true);
          } else {
            setResetErr({
              isError: true,
              msg: res.ErrorMessage,
            });
          }
        });
      }

      if (codeForm) {
        setResetLoader(true);
        auth.sendCode(phone, code).then((res) => {
          setResetLoader(false);
          if (res.ErrorCode !== 0) {
            setCodeErr({
              isError: true,
              msg: res.ErrorMessage,
            });
          } else {
            setCodeForm(false);
            setPasswForm(true);
          }
        });
      }
      if (passwForm) {
        auth.newPassword(phone, { new_app_pass: passw }).then((res) => {
          setShowReset(false);
          setPasswForm(false);
          setPhoneForm(true);
          cancelAlert();
        });
      }
    }
  }

  function cancelAlert() {
    setPhone("");
    setEmail("");
    setPassw("");
    setRPassw("");
    setCode("");
    setShowReset(false);
  }

  useEffect(() => {
    localStorage.clear();
  }, []);

  function resetRender() {
    if (phoneForm) {
      return (
        <>
          <Title
            style={{
              color: "black",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            Для відновлення паролю вкажіть будь ласка номер телефону.
          </Title>
          <TextField
            name="Мобільний телефон"
            placeholder="+380"
            value={phone}
            fn={handlePhone}
            className="input-active"
            error={resetErr.isError}
            disabled={false}
            fnFocus={() => {
              handlePhone(phone);
            }}
          />
          <TextField
            name="Email"
            placeholder="name@mail.com"
            value={email}
            fn={setEmail}
            className="input-active"
            error={emailErr.isError}
            disabled={false}
          />
          <ErrorText style={{ marginBottom: "10px" }} className="error_text">
            {resetErr.msg} {emailErr.msg}
          </ErrorText>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "10px",
            }}
          >
            <ButtonSecondary onClick={cancelAlert}>Відміна</ButtonSecondary>
            {!resetLoader ? (
              <ButtonLogin inactive={blockForm} onClick={submit}>
                Надіслати код
              </ButtonLogin>
            ) : (
              <Preloader style={{ marginTop: "0px" }} />
            )}
          </div>
        </>
      );
    }
    if (codeForm) {
      return (
        <>
          <Title
            style={{
              color: "black",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            Введіть код із СМС.
          </Title>
          <TextField
            name="Код"
            placeholder="Введіть 6 значний код"
            value={code}
            fn={handleCode}
            className="input-active"
            disabled={false}
            error={codeErr.isError}
          />
          <ErrorText style={{ marginBottom: "10px" }} className="error_text">
            {codeErr.msg}
          </ErrorText>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "10px",
            }}
          >
            <ButtonSecondary onClick={cancelAlert}>Відміна</ButtonSecondary>

            {!resetLoader ? (
              <ButtonLogin inactive={blockForm} onClick={submit}>
                Перевірити
              </ButtonLogin>
            ) : (
              <Preloader style={{ marginTop: "0px" }} />
            )}
          </div>
        </>
      );
    }
    if (passwForm) {
      return (
        <>
          <Title
            style={{
              color: "black",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            Введіть новий пароль.
          </Title>
          <TextFieldPassw
            name="Пароль"
            placeholder="Введіть 8-значний пароль"
            value={passw}
            fn={setPassw}
            className="input-active"
            error={passwErr.isError}
            disabled={false}
          />
          <ErrorText className="error_text">{passwErr.msg}</ErrorText>

          <TextFieldPassw
            name="Повторити пароль"
            placeholder="Повторіть пароль"
            value={rpassw}
            fn={setRPassw}
            className="input-active"
            error={rpasswErr.isError}
          />

          <ErrorText className="error_text">{rpasswErr.msg}</ErrorText>
          <ErrorText style={{ marginBottom: "10px" }} className="error_text">
            {resetErr.msg}
          </ErrorText>

          <ButtonLogin inactive={blockForm} onClick={submit}>
            Готово
          </ButtonLogin>
        </>
      );
    }
  }

  return (
    <AuthContainer
      tabIndex={0}
      onClick={hideAuthRemainder}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          hideAuthRemainder();
        }
      }}
    >
      <ResetContainer show={showReset}>
        <ResetBlock onClick={(e) => e.stopPropagation()}>
          {resetRender()}
        </ResetBlock>
      </ResetContainer>
      <ImageLoader
        src={Background}
        placeholder={BackgroundPlaceholder}
        alt="background"
        width="55vw"
      />

      <FormContainer>
        <FormContainerText>
          <Logo src={LogoPath} />
          <Title>Онлайн кабінет ОщадPAY</Title>
          <AuthRemainder
            {...{ authRemainderVisible, setAuthRemainderVisible }}
          />
        </FormContainerText>

        <FormContainerSize>
          <AuthForm />

          <div className="forgot-password">
            <Block className="block-icon" />
            <AuthText
              onClick={() => {
                setPhoneForm(true);
                setCodeForm(false);
                setPasswForm(false);
                setShowReset(true);
              }}
            >
              Забули пароль ?
            </AuthText>
          </div>
        </FormContainerSize>
        <div className="inform">
          <AuthText style={{ textAlign: "center" }}>
            Немає акаунту в ОщадPay?
            <InlineLink
              href="https://www.oschadbank.ua/oschadpay"
              target="_blank"
              rel="noreferrer"
            >
              Подати заявку на створення
            </InlineLink>
          </AuthText>
        </div>
        <div className="inform" style={{ marginTop: "10px" }}>
          <Link
            target="_blank"
            href={`${URL_SERVER}/m/instrNewOschadPAY.pdf`}
            rel="noreferrer"
          >
            Інструкція користувача мобільного додатку ОщадPAY
          </Link>
        </div>
      </FormContainer>
    </AuthContainer>
  );
}
