import React from "react";
import {
  AlertContainer,
  AlertBlock,
  AlertTitle,
  AlertSubTitle,
  AlertButtonContainer,
} from "./Alert.styles";
import { AlertList } from "./AlertList";
import { generateIcon } from "./icons/alertIconHelper";

import {
  Button,
  ButtonSecondaryBorder,
  SubmitSecondaryBorderButton,
} from "components/forms/Button/Button.styles";

const Alert = (props) => {
  const {
    type,
    title,
    subtitle,
    fnClose,
    fnSubmit,
    subtitleClassName,
    btn_close,
    cancelButtonLabel,
    acceptButtonLabel,
    height,
    width,
    iconType,
    subtitleStyle,
    list,
    listType,
    listStyle,
    footer,
    footerStyle,
    reversedButtonAccent = false,
  } = props;

  const alertBlockProps = height
    ? { minHeight: height, maxHeight: "90vh" }
    : {};

  if (width) {
    alertBlockProps.width = width;
  }

  return (
    <AlertContainer>
      <AlertBlock {...alertBlockProps}>
        {generateIcon(iconType || type)}

        <AlertTitle>{title}</AlertTitle>
        <AlertSubTitle className={subtitleClassName} style={subtitleStyle}>
          {subtitle}
        </AlertSubTitle>
        {list && (
          <AlertList items={list} listType={listType} style={listStyle} />
        )}
        {footer && <AlertSubTitle style={footerStyle}>{footer}</AlertSubTitle>}
        {type === "remove" || type === "done-button" ? (
          <AlertButtonContainer>
            {reversedButtonAccent ? (
              <>
                <Button className="font-bold" onClick={fnClose}>
                  {cancelButtonLabel && cancelButtonLabel}
                  {!cancelButtonLabel && "Ні"}
                </Button>
                <SubmitSecondaryBorderButton onClick={fnSubmit}>
                  {acceptButtonLabel && acceptButtonLabel}
                  {!acceptButtonLabel &&
                    (btn_close ? `Так, ${btn_close}` : "Так")}
                </SubmitSecondaryBorderButton>
              </>
            ) : (
              <>
                <ButtonSecondaryBorder className="font-bold" onClick={fnClose}>
                  {cancelButtonLabel && cancelButtonLabel}
                  {!cancelButtonLabel && "Ні"}
                </ButtonSecondaryBorder>
                <Button onClick={fnSubmit}>
                  {acceptButtonLabel && acceptButtonLabel}
                  {!acceptButtonLabel &&
                    (btn_close ? `Так, ${btn_close}` : "Так")}
                </Button>
              </>
            )}
          </AlertButtonContainer>
        ) : null}
        {type === "done" || type === "block" || type === "info" ? (
          <AlertButtonContainer>
            <Button onClick={fnClose}>OK</Button>
          </AlertButtonContainer>
        ) : null}
      </AlertBlock>
    </AlertContainer>
  );
};

export default Alert;
