export const reTagEntry = /@\[.+?\]\(#[A-Za-z_]+?#\d*?\)/g;
export const reTagName = /#[A-Za-z_]+?#\d*/;
export const reTagId = /(#)([A-Za-z_]+?)(#)/;
export const reTagText = /\[.+?\]/;
export const INIT_STATE = {
  template: {
    errors: {},
    tagsChanged: false,
  },
  disabled: true,
  savingTemplate: false,
  deletingTemplate: false,
  updatePosition: 0,
  keyDownPosition: 0,
  changed: false,
};

const NEW_STATE = {
  template: {
    templateId: null,
    templateName: "",
    templateText: "",
    tags: [],
    errors: {},
    tagsChanged: false,
  },
  mentions: [],
  index: 0,
  disabled: false,
  savingTemplate: false,
  deletingTemplate: false,
  updatePosition: 0,
  keyDownPosition: 0,
  changed: false,
};

const appendLineBreaksToTags = (templateText) => {
  const matches = templateText.match(reTagEntry) || [];
  const updatedMatches = Array(matches.length);

  for (let i = 0, length = matches.length; i < length; ++i) {
    updatedMatches[i] = matches[i] + "\n";
  }

  return updatedMatches.join("");
};

const makeTagsOnChange = (state, templateText) => {
  const matches = templateText.match(reTagEntry) || [];
  const {
    template: { tags },
  } = state;
  let updatedTags = [];

  for (const m of matches) {
    const tagName = m.match(reTagName)[0];
    const tag = tags.find((tag) => tag.tag_name === tagName);

    if (tag) {
      updatedTags.push(tag);
    } else {
      const tag_name = tagName;
      const tag = {
        tag_name,
      };

      updatedTags.push(tag);
    }
  }

  return updatedTags;
};

const makeTemplateTextOnCancel = (templateText, cancelledTag) => {
  const matches = templateText.match(reTagEntry) || [];

  return templateText.replace(
    matches.filter((m) => m.match(cancelledTag))[0],
    ""
  );
};

const mapMentions = (mentions, index) => {
  return mentions.map((item) => {
    const { id, display, ...rest } = item;

    return {
      ...rest,
      id: `${id}${index}`,
      display,
    };
  });
};

const indexTags = (mentions, tags) => {
  const mappedTags = [];
  let mappedText = "";
  let i = 0;

  for (const tag of tags) {
    let { tag_name: tagName } = tag;
    const mention = mentions.find(({ id }) => id === `#${tagName}#`);

    if (mention) {
      const { display } = mention;

      tagName = `#${tagName}#${i++}`;
      mappedTags.push({ ...tag, tag_name: tagName });
      mappedText += `@[${display}](${tagName})${"\n"}`;
    }
  }

  return {
    patch: {
      templateText: mappedText,
      tags: mappedTags,
    },
    index: tags.length,
  };
};

const mapTemplate = (template, mentions) => {
  if (!template) {
    return {
      template: {
        errors: {},
      },
      index: 0,
    };
  }

  const {
    client_payment_template_id: templateId,
    template_name: templateName,
    template_text: templateText,
    display_at_receipt: displayAtReceipt,
    generate_each_transaction: generateEachTransaction,
    tags,
    tags_changed: tagsChanged,
  } = JSON.parse(JSON.stringify(template));

  const { patch, index } = indexTags(mentions, tags);

  return {
    template: {
      templateId,
      templateName,
      templateText,
      displayAtReceipt,
      generateEachTransaction,
      tags,
      tagsChanged,
      ...patch,
      errors: {},
    },
    index,
  };
};

export const REQUIRED_ERROR_MESSAGE = "поле обов'язкове для заповнення";
export const NAN_ERROR_MESSAGE = "має бути числом";
export const GT_MAX_ERROR_MESSAGE = "має бути не більше максимальної довжини";
export const LT_MIN_ERROR_MESSAGE = "має бути не менше мінімальної довжини";
export const KEY_DUPLICATES_LIST_ERROR_MESSAGE =
  "Елементи списку (назва - значення) містять повторювані назви.";
export const EMPTY_KEY_VALUE_LIST_ERROR_MESSAGE =
  "Елементи списку (назва - значення) або мають незаповнені назви або значення.";
export const INVALID_KEY_VALUE_LIST_ERROR_MESSAGE =
  "Елементи списку (назва - значення) мають незаповнені рядки або дублікати назв.";

export const ERROR_TEXT_STYLE = {
  marginTop: "-0.75rem",
  marginLeft: "0.85rem",
  height: "1.7rem",
  fontSize: "12px",
  lineHeight: "1.2",
};

export const EDITOR_ERROR_TEXT_STYLE = {
  height: "1.7rem",
  fontSize: "12px",
  lineHeight: "1.2",
};

export const ACTION_TYPE = {
  INIT: "INIT",
  INIT_NEW: "INIT_NEW",
  INIT_WITH_EXISTING: "INIT_WITH_EXISTING",
  NAME_CHANGE: "NAME_CHANGE",
  TEXT_CHANGE: "TEXT_CHANGE",
  TAG_DETAILS_SAVE: "TAG_DETAILS_SAVE",
  EDIT: "EDIT",
  CANCEL_EDIT: "CANCEL_EDIT",
  CANCEL_NEW: "CANCEL_NEW",
  CANCEL: "CANCEL",
  DELETED: "DELETED",
  SET_DISABLED: "SET_DISABLED",
  SET_ERROR: "SET_ERROR",
  DELETING_TEMPLATE: "DELETING_TEMPLATE",
  SAVING_TEMPLATE: "SAVING_TEMPLATE",
  CLEAR_UPDATE_POSITION: "CLEAR_UPDATE_POSITION",
  SAVE_UPDATE_POSITION: "SAVE_UPDATE_POSITION",
  DISPLAY_AT_RECEIPT_CHANGE: "DISPLAY_AT_RECEIPT_CHANGE",
  GENERATE_EACH_TRANSACTION_CHANGE: "GENERATE_EACH_TRANSACTION_CHANGE",
};

export const reducer = (state, action) => {
  const {
    template: { tags, templateText, errors },
    index,
  } = state;
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPE.INIT_NEW:
      return {
        ...NEW_STATE,
        mentions: payload,
      };
    case ACTION_TYPE.INIT_WITH_EXISTING:
      const { template: sourceTemplate, mentions } = payload;
      const { template, index: mappedIndex } = mapTemplate(
        sourceTemplate,
        mentions
      );

      return {
        template,
        mentions: mapMentions(mentions, mappedIndex),
        index: mappedIndex,
        disabled: true,
        updatePosition: 0,
        keyDownPosition: 0,
        changed: false,
      };
    case ACTION_TYPE.NAME_CHANGE: {
      return {
        ...state,
        template: {
          ...state.template,
          templateName: payload,
        },
        changed: true,
      };
    }
    case ACTION_TYPE.TEXT_CHANGE: {
      const templateText = payload;

      return {
        ...state,
        template: {
          ...state.template,
          tags: makeTagsOnChange(state, templateText),
          templateText,
        },
        changed: true,
      };
    }
    case ACTION_TYPE.DISPLAY_AT_RECEIPT_CHANGE: {
      return {
        ...state,
        template: {
          ...state.template,
          displayAtReceipt: payload,
        },
        changed: true,
      };
    }
    case ACTION_TYPE.GENERATE_EACH_TRANSACTION_CHANGE: {
      return {
        ...state,
        template: {
          ...state.template,
          generateEachTransaction: payload,
        },
        changed: true,
      };
    }
    case ACTION_TYPE.DELETED: {
      return INIT_STATE;
    }
    case ACTION_TYPE.EDIT: {
      const exitEdit = Boolean(payload);

      return {
        ...state,
        disabled: exitEdit,
        changed: false,
      };
    }
    case ACTION_TYPE.CANCEL_EDIT: {
      const { template: sourceTemplate, mentions } = payload;
      const { template, index: mappedIndex } = mapTemplate(
        sourceTemplate,
        mentions
      );

      return {
        ...state,
        template,
        mentions: mapMentions(mentions, mappedIndex),
        index: mappedIndex,
        disabled: true,
        changed: false,
      };
    }
    case ACTION_TYPE.TAG_DETAILS_SAVE: {
      const newIndex = index + 1;
      const {
        tag: _tag,
        tooltip,
        isMandatory,
        minLength,
        maxLength,
        mentions,
        keyValueItems,
      } = payload;
      const { template: stateTemplate } = state;
      const template = {
        ...stateTemplate,
        templateText: appendLineBreaksToTags(stateTemplate.templateText),
        tagsChanged: true,
      };

      const tag = _tag.client_payment_tag_id
        ? _tag
        : tags.find(({ tag_name }) => tag_name === _tag.tag_name);

      const tagValues = keyValueItems?.map(
        ({
          keyItem,
          valueItem,
          client_payment_tag_values_id,
          client_payment_tag_id,
        }) => ({
          client_payment_tag_values_id: client_payment_tag_values_id || null,
          client_payment_tag_id: client_payment_tag_id || null,
          tag_value: valueItem,
          tag_value_desc: keyItem,
        })
      );
      const updatedTag = {
        ...tag,
        tooltip,
        is_mandatory: isMandatory,
        min_length: minLength,
        max_length: maxLength,
        tag_values: tagValues,
      };

      const makeTags = (updatedTag) => {
        const tags = state.template.tags;

        if (updatedTag.tooltip) {
          return tags.map((tag) =>
            tag.tag_name === updatedTag.tag_name ? updatedTag : tag
          );
        } else {
          return [
            ...tags.filter((item) => item.tag_name !== updatedTag.tag_name),
            updatedTag,
          ];
        }
      };

      const newState = {
        ...state,
        template: {
          ...template,
          tags: makeTags(updatedTag),
        },
        index: newIndex,
        mentions: mapMentions(mentions, newIndex),
        changed: true,
      };

      return newState;
    }
    case ACTION_TYPE.CANCEL_NEW:
      return INIT_STATE;
    case ACTION_TYPE.CANCEL: {
      const cancelledIndex = tags.findIndex((item) => !item.tooltip);
      const cancelledTag = tags[cancelledIndex];
      const restoredTags = tags.filter((item, i) => i !== cancelledIndex);

      return {
        ...state,
        template: {
          ...state.template,
          tags: restoredTags,
          templateText: makeTemplateTextOnCancel(
            templateText,
            cancelledTag.tag_name
          ),
        },
        changed: false,
      };
    }
    case ACTION_TYPE.SET_ERROR:
      const { name, message } = payload;
      const errData = {
        ...errors,
        [name]: message,
      };
      const filteredErrData = Object.entries(errData)
        .filter(([, value]) => value)
        .reduce((a, [key, value]) => {
          a[key] = value;

          return a;
        }, {});

      return {
        ...state,
        template: {
          ...state.template,
          errors: filteredErrData,
        },
      };
    case ACTION_TYPE.SAVING_TEMPLATE:
      return {
        ...state,
        savingTemplate: payload,
      };
    case ACTION_TYPE.DELETING_TEMPLATE:
      return {
        ...state,
        deletingTemplate: payload,
      };
    case ACTION_TYPE.CLEAR_UPDATE_POSITION:
      return {
        ...state,
        updatePosition: 0,
      };
    case ACTION_TYPE.SAVE_UPDATE_POSITION:
      return {
        ...state,
        updatePosition: payload,
      };
    case ACTION_TYPE.KEY_DOWN_POSITION:
      return {
        ...state,
        keyDownPosition: payload,
      };
    default:
      return state;
  }
};
