import { useState } from "react";
import { useSelector } from "react-redux";

import { selectCurrentStatisticsParams } from "store/statistics/stat_slicer";

const useSelectedTerminals = () => {
  const { terminals, selectedTerminals: selected } = useSelector(
    selectCurrentStatisticsParams
  );
  const [filteredTerminals, setFilteredTerminals] = useState(terminals);
  const [idFilterValue, setIdFilterValue] = useState("");
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [selectedTerminals, setSelectedTerminals] = useState(
    (selected || []).length === 0
      ? filteredTerminals.filter(({ disabled }) => !disabled)
      : terminals.filter(({ id }) => selected.includes(id))
  );

  const search = (fn, val, field) => {
    fn(val);

    if (val) {
      setFilteredTerminals((state) =>
        state.filter((item) => item[field].includes(val))
      );
    } else {
      setFilteredTerminals(terminals);
    }
  };

  const selectedAllTerminals = terminals.length === selectedTerminals.length;

  const onSelectAllTerminals = () => {
    if (selectedAllTerminals) {
      setSelectedTerminals([]);
    } else {
      setSelectedTerminals(terminals);
    }
  };
  const onSelectTerminal = (item, checked) => {
    if (checked) {
      setSelectedTerminals((state) => state.filter(({ id }) => id !== item.id));
    } else {
      setSelectedTerminals((state) => [...state, item]);
    }
  };

  return {
    idFilterValue,
    setIdFilterValue,
    nameFilterValue,
    setNameFilterValue,
    search,
    onSelectAllTerminals,
    onSelectTerminal,
    filteredTerminals,
    selectedTerminals,
    terminals,
  };
};

export default useSelectedTerminals;
