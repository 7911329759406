import styled from 'styled-components';

export const IconContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 0.3s ease;
  border-radius: 50%;
  &:hover {
    background-color: #eee;
  }
`;
