import styled from 'styled-components';

import { mouse_pointer, gradient_green } from 'assets/style/variables';

export const RefreshContainer = styled.div`
  position: fixed;
  right: 40px;
  bottom: 30px;
  background: ${gradient_green};
  border-radius: 20px;
  padding: 5px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  z-index: 15;
  svg {
    width: 30px;
    path {
      fill: white;
    }
  }
  ${mouse_pointer};
  &:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.5);
  }
  transition: transform 0.2s ease, opacity 0.3s ease;

  ${(props) =>
    props.opened ? `transform: scale(1); opacity: 1;` : `transform: scale(0); opacity: 0;`}
`;

export const CloseIconContainer = styled.div`
  position: fixed;
  right: 30px;
  bottom: 75px;
  background-color: orange;
  border-radius: 50%;
  z-index: 16;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease, opacity 0.3s ease, background-color 0.2s ease;

  svg {
    width: 15px;
    height: 15px;
  }

  ${(props) =>
    props.opened ? `transform: scale(1); opacity: 1;` : `transform: scale(0); opacity: 0;`}

  &:hover {
    background-color: #e89700;
  }
`;

export const OpenIconContainer = styled.div`
  position: fixed;
  right: 18px;
  bottom: 30px;
  background-color: #eee;
  padding: 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 16;
  cursor: pointer;
  transition: transform 0.2s ease, opacity 0.3s ease, background-color 0.2s ease;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: #e3e3e3;
  }

  ${(props) =>
    !props.opened ? `transform: scale(1); opacity: 1;` : `transform: scale(0); opacity: 0;`}
`;
