import { SET_PROFILE, SET_KEY, SET_OUT_OF_DATE_KEYS } from "./user_types";

const initialState = {
  profile: !!localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile"))
    : null,
  key: null,
  outOfDateKeys: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_KEY:
      return {
        ...state,
        key: action.payload,
      };
    case SET_OUT_OF_DATE_KEYS:
      return {
        ...state,
        outOfDateKeys: action.payload,
      };
    default:
      return state;
  }
};

export const selectUser = (state) => state.user;
export const selectUserKey = (state) => state.user.key;
export const selectUserOutOfDateKeys = (state) => state.user.outOfDateKeys;
export const selectUserProfile = (state) => state.user.profile;

export default userReducer;
