import React from "react";
import moment from "moment";
import { toast } from "utils/toast/toast";
import { SubTitle } from "./TransactionDetail.styles";
import { QR_SERVER } from "utils/constants/server.constants";

const useTransactionDetails = (transaction, isQR) => {
  const {
    tapx_error_code,
    tapx_error_message,
    host_rc,
    host_rc_actions,
    host_rc_message,
    dfs_error,
    other_message,
    amount,
    status_transaction,
    transaction_date,
    transaction_id,
    transaction_token,
  } = transaction;
  const details = [];

  if (
    tapx_error_code &&
    tapx_error_message &&
    !(host_rc || host_rc_actions || host_rc_message)
  ) {
    details.push(`${tapx_error_code} ${tapx_error_message}`);
  }
  if (!isQR && transaction_date && status_transaction !== 4) {
    details.push(moment(transaction_date).format("DD.MM.YYYY | HH:mm"));
  }
  if (host_rc && host_rc_message) {
    details.push(
      `${
        Number.isNaN(host_rc_message) ? host_rc : ""
      } ${host_rc_message}`.trim()
    );
  }
  if (host_rc_actions) {
    details.push(`${host_rc_actions}`);
  }
  if (dfs_error) {
    details.push(`${dfs_error}`);
  }
  if (other_message) {
    details.push(other_message);
  }

  const copyLinkToClipboard = async () => {
    const link = `Транзакція №${transaction_id}
Оплата товару/послуги на суму ${amount} грн.
Перейдіть за посиланням нижче для виконання оплати.
${QR_SERVER}/api/qr_payment?trantoken=${transaction_token}`;

    try {
      await navigator.clipboard.writeText(link);
      toast.infoMessage("Лінк на сторінку оплати скопійовано у буфер обміну");
    } catch ({ message }) {
      toast.warningMessage(message);
    }
  };

  const transactionDetails = details.map((error) => (
    <SubTitle key={error}>{error}</SubTitle>
  ));

  return { copyLinkToClipboard, transactionDetails };
};

export default useTransactionDetails;
