import React from "react";

import { VERSION_TEXT } from "utils/constants/server.constants";

import { VersionBageContainer, VersionText } from "./style";

const VersionBage = () => {
  return (
    <>
      <VersionBageContainer>
        <VersionText>{VERSION_TEXT()}</VersionText>
      </VersionBageContainer>
    </>
  );
};

export default VersionBage;
