import {
  NEWS_SERVER_INIT,
  NEWS_STATE,
  NEWS_PAGINATION,
  NEWS_LOCAL_INIT,
  NEWS_ITEM,
  NEWS_UPDATE_ITEM_IN_CACHE,
  NEWS_DELETE_ITEM_FROM_CACHE,
} from "./news_table.types";

const initialState = {
  server_init: false,
  local_init: false,
  state: [],
  pagination: 0,
  item: null,
};

export default function newsTableReducer(state = initialState, action) {
  switch (action.type) {
    case NEWS_SERVER_INIT:
      return { ...state, server_init: action.payload };
    case NEWS_LOCAL_INIT:
      return { ...state, local_init: action.payload };
    case NEWS_STATE:
      return { ...state, state: action.payload };
    case NEWS_PAGINATION:
      return { ...state, pagination: action.payload };
    case NEWS_ITEM:
      return { ...state, item: action.payload };
    case NEWS_UPDATE_ITEM_IN_CACHE:
      return {
        ...state,
        state: [
          ...state.state.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ),
        ],
        item: action.payload,
      };
    case NEWS_DELETE_ITEM_FROM_CACHE:
      return {
        ...state,
        state: [...state.state.filter((item) => item.id !== action.payload.id)],
        item: null,
      };
    default:
      return state;
  }
}

export const serverStatus = (state) => state.news_table.server_init;
export const localStatus = (state) => state.news_table.local_init;
export const pagination = (state) => state.news_table.pagination;
export const item = (state) => state.news_table.item;
