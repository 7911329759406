import {
  TRANSACTION_TYPE,
  CARD_TYPE,
  RESULT_TYPE,
  CASH_TYPE,
  RETURN_TYPE,
  CASHLESS_TYPE,
  QR_TYPE,
} from "utils/constants/subtotal.constants";

const buttonState = [
  {
    id: 0,
    title: "Сьогодні",
    active: true,
  },
  {
    id: 1,
    title: "Вчора",
    active: false,
  },
  {
    id: 2,
    title: "Останні 7 днів",
    active: false,
  },
  {
    id: 3,
    title: "Останні 30 днів",
    active: false,
  },
  {
    id: 4,
    title: "Поточний місяць",
    active: false,
  },
];

const subTotalState = [
  {
    type: TRANSACTION_TYPE,
    value: 0,
  },
  {
    type: CASH_TYPE,
    value: 0,
  },
  {
    type: CARD_TYPE,
    value: 0,
  },
  {
    type: CASHLESS_TYPE,
    value: 0,
  },
  {
    type: RETURN_TYPE,
    value: 0,
  },
  {
    type: RESULT_TYPE,
    value: 0,
  },
];

const subTotalMainState = [
  {
    type: CARD_TYPE,
    value: 0,
  },
  {
    type: CASH_TYPE,
    value: 0,
  },
  {
    type: CASHLESS_TYPE,
    value: 0,
  },
  {
    type: QR_TYPE,
    value: 0,
  },
  {
    type: RESULT_TYPE,
    value: 0,
  },
];

export { buttonState, subTotalState, subTotalMainState };
