import React from "react";

import { Select } from "components/forms";
import {
  CommentTemplatesContainer,
  TemplatesButton,
} from "../TerminalDetail.styles";

const CommentTemplates = ({
  isEdit,
  templates = [],
  templateId,
  setTemplate,
  openEditor,
  errorMessage,
  setErrorMessage,
}) => {
  const value = templates.find((item) => item.value === templateId) || null;

  return (
    <CommentTemplatesContainer>
      <Select
        options={templates}
        value={value}
        errorMessage={errorMessage}
        onChange={(v) => setTemplate(v)}
        onFocus={() => setErrorMessage(null)}
        label="Шаблони призначення *"
        placeholder="оберіть шаблон"
        isClearable
        fontSize="inherit"
        menuPlacement="top"
        isDisabled={!isEdit}
      />
      <TemplatesButton
        onClick={openEditor}
        className={`${errorMessage ? "invalid" : null}`}
      >
        <span>Шаблони...</span>
      </TemplatesButton>
    </CommentTemplatesContainer>
  );
};

export default CommentTemplates;
