import moment from "moment";

import { MainTable } from "api";
import {
  INITIAL_DAY_SETTINGS_STATE,
  INITIAL_HOURS_VALUE,
  INITIAL_MINUTES_VALUE,
} from "../FiscalDaySettings/constants";
import { WEEK_DAYS } from "utils/constants/time";

const formDaySettingsState = (response) => {
  const keysArray = Object.keys(response).filter(
    (key) => !!WEEK_DAYS.find((weekDay) => weekDay.field === key)
  );

  const isMyselfSelected = !keysArray.find((key) => !!response[key]);
  const isAutomaticallySelected =
    !isMyselfSelected &&
    keysArray.filter(
      (key) =>
        moment(response[key]).format("HH:mm") ===
        moment(response[keysArray[0]]).format("HH:mm")
    ).length === keysArray.length;

  const isManuallySelected = !isMyselfSelected && !isAutomaticallySelected;

  const result = {
    autoInkass:
      response.autoInkass === 0 || !response.autoInkass ? false : true,
    autoBdayClose:
      response.autoBdayClose === 0 || !response.autoBdayClose ? false : true,
    autoFdayClose:
      response.autoFdayClose === 0 || !response.autoFdayClose ? false : true,
    fiscalClosingRadioboxes: {
      myself: isMyselfSelected,
      automatically: isAutomaticallySelected,
      manually: isManuallySelected,
    },
  };

  if (isAutomaticallySelected) {
    result.autoTime = {
      hours: moment(response[keysArray[0]]).format("HH"),
      minutes: moment(response[keysArray[0]]).format("mm"),
    };
    keysArray.forEach((key) => {
      result[key] = {
        ...WEEK_DAYS.find((el) => el.field === key),
        hours: INITIAL_HOURS_VALUE,
        minutes: INITIAL_MINUTES_VALUE,
        checked: false,
      };
    });
  }

  if (isMyselfSelected) {
    result.autoTime = {
      hours: INITIAL_HOURS_VALUE,
      minutes: INITIAL_MINUTES_VALUE,
    };
    keysArray.forEach((key) => {
      result[key] = {
        ...WEEK_DAYS.find((el) => el.field === key),
        hours: INITIAL_HOURS_VALUE,
        minutes: INITIAL_MINUTES_VALUE,
        checked: false,
      };
    });
  }

  if (isManuallySelected) {
    result.autoTime = {
      hours: INITIAL_HOURS_VALUE,
      minutes: INITIAL_MINUTES_VALUE,
    };
    keysArray.forEach((key) => {
      result[key] = !!response[key]
        ? {
            ...WEEK_DAYS.find((el) => el.field === key),
            hours: moment(response[key]).format("HH"),
            minutes: moment(response[key]).format("mm"),
            checked: true,
          }
        : {
            ...WEEK_DAYS.find((el) => el.field === key),
            hours: INITIAL_HOURS_VALUE,
            minutes: INITIAL_MINUTES_VALUE,
            checked: false,
          };
    });
  }

  return result;
};

export const getDaySettingsService = async ({ terminalId }) => {
  const api = new MainTable();

  let settings = null;

  try {
    const result = await api.getUserDaySettings({ terminalId });
    if (result.data === "") {
      settings = INITIAL_DAY_SETTINGS_STATE;
    } else {
      settings = formDaySettingsState(result.data);
    }
  } catch (error) {
    console.log(error);
  }

  return settings;
};

export const saveDaySettingService = async ({ terminalId, daySettings }) => {
  const merchantId = localStorage.getItem("merchantId");
  const api = new MainTable();
  const body = {
    merchantId,
    terminalId,
    nextStart: !!daySettings.nextStart ? daySettings.nextStart : null,
    autoInkass: !!daySettings.autoInkass ? 1 : 0,
    autoBdayClose: !!daySettings.autoBdayClose ? 1 : 0,
    autoFdayClose: !!daySettings.autoFdayClose ? 1 : 0,
  };

  if (!!daySettings.fiscalClosingRadioboxes.myself) {
    WEEK_DAYS.map((el) => el.field).forEach((field) => {
      body[field] = null;
    });
  }

  if (!!daySettings.fiscalClosingRadioboxes.automatically) {
    WEEK_DAYS.map((el) => el.field).forEach((field) => {
      body[field] = moment()
        .set({
          hour: daySettings.autoTime.hours,
          minute: daySettings.autoTime.minutes,
        })
        .format();
    });
  }

  if (!!daySettings.fiscalClosingRadioboxes.manually) {
    WEEK_DAYS.map((el) => el.field).forEach((field) => {
      body[field] = daySettings[field].checked
        ? moment()
            .set({
              hour: daySettings[field].hours,
              minute: daySettings[field].minutes,
            })
            .format()
        : null;
    });
  }

  try {
    return await api.saveUserDaySettings({ body });
  } catch (error) {
    return null;
  }
};

export const isDaySettingsHaveAutoClosing = (daySettings) => {
  const result =
    daySettings.fiscalClosingRadioboxes.automatically ||
    daySettings.fiscalClosingRadioboxes.manually;

  return result;
};

export const isAutomaticallyFiscalDayClosingSelectedAndCheckboxesNotSelected = (
  daySettings
) => {
  const result =
    daySettings.fiscalClosingRadioboxes.manually &&
    WEEK_DAYS.filter((week_day) => daySettings[week_day.field].checked)
      .length === 0;

  return result;
};
