import { STAT_TYPES } from "../../pages/Panels/Statistics/constants";
import { TRAN_TYPES } from "../../pages/Panels/Statistics/charts/TotalsByTranTypes/config";
import {
  SET_STAT_PARAMS,
  SET_STAT_TRAN_TYPES,
  SET_STAT_TYPE,
  SET_STAT_LOADING,
  SET_STAT_DATA,
} from "./stat_types";

const localStatState = localStorage.getItem("statistics");
const currentYear = new Date().getFullYear();

const defaultState = {
  type: STAT_TYPES.BY_TRAN_TYPE,
  params: {
    [STAT_TYPES.BY_TRAN_TYPE]: {
      year: currentYear,
      quarterly: false,
      tranTypes: Object.fromEntries(
        Object.entries(TRAN_TYPES).map(([key]) => [key, true])
      ),
    },
    [STAT_TYPES.BY_YEAR]: {
      beginYear: 2020,
      endYear: currentYear,
    },
    [STAT_TYPES.BY_MONTH]: {
      beginYear: 2020,
      endYear: currentYear,
      quarterly: false,
    },
    [STAT_TYPES.BY_TERMINAL]: {
      year: currentYear,
      bySelectedTerminals: false,
      selectedTerminals: null,
      terminals: null,
      showTerminalPicker: false,
    },
  },
  data: {
    [STAT_TYPES.BY_TRAN_TYPE]: {
      data: [],
      error: null,
      isLoading: false,
    },
    [STAT_TYPES.BY_YEAR]: {
      data: [],
      error: null,
      isLoading: false,
    },
    [STAT_TYPES.BY_MONTH]: {
      data: [],
      error: null,
      isLoading: false,
    },
    [STAT_TYPES.BY_TERMINAL]: {
      data: [],
      error: null,
      isLoading: false,
    },
  },
};

const initialState = localStatState ? JSON.parse(localStatState) : defaultState;

const statReducer = (state = initialState, { type, payload }) => {
  let newState;

  switch (type) {
    case SET_STAT_TYPE:
      newState = { ...state, type: payload };
      break;
    case SET_STAT_PARAMS:
      newState = {
        ...state,
        params: {
          ...state.params,
          [state.type]: { ...payload },
        },
      };
      break;
    case SET_STAT_TRAN_TYPES:
      newState = {
        ...state,
        params: {
          ...state.params,
          [state.type]: {
            ...state.params[state.type],
            tranTypes: { ...payload },
          },
        },
      };
      break;
    case SET_STAT_LOADING: {
      const { statType, isLoading } = payload;

      newState = {
        ...state,
        data: {
          ...state.data,
          [statType]: {
            ...state.data[statType],
            isLoading,
          },
        },
      };
      break;
    }
    case SET_STAT_DATA: {
      const { statType, data } = payload;

      newState = {
        ...state,
        data: {
          ...state.data,
          [statType]: {
            ...state.data[statType],
            data,
          },
        },
      };
      break;
    }
    default:
      return state;
  }

  return newState;
};

export const selectCurrentStatisticsParams = (state) =>
  state.statistics.params[state.statistics.type];
export const selectCurrentStatisticsData = (state) =>
  state.statistics.data[state.statistics.type];
export const selectCurrentStatType = (state) => state.statistics.type;
export const selectStatTranTypes = (state) =>
  state.statistics.params[state.statistics.type].tranTypes;

export default statReducer;
