import React from "react";
import { Select } from "components/forms";
import { ChartToolbarContainer } from "./ChartToolbar.styles";

const ChartToolbar = ({
  beginYear,
  setBeginYear,
  endYear,
  setEndYear,
  years,
  isLoading,
}) => {
  return (
    <ChartToolbarContainer>
      <Select
        options={years}
        placeholder="початковий рік..."
        label={"Початковий рік"}
        value={beginYear}
        onChange={(value) => {
          if (value?.value > endYear?.value) return;

          setBeginYear(value);
        }}
        width={"120px"}
        isDisabled={isLoading}
      />
      <Select
        options={years}
        placeholder="кінцевий рік..."
        label={"Кінцевий рік"}
        value={endYear}
        onChange={(value) => {
          if (value?.value < beginYear?.value) return;

          setEndYear(value);
        }}
        width={"120px"}
        isDisabled={isLoading}
      />
    </ChartToolbarContainer>
  );
};

export default ChartToolbar;
