import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { BiEnvelope, BiEnvelopeOpen } from "react-icons/bi";

import Alert from "ui/Alert/Alert";
import Refresh from "ui/Refresh/Refresh";
import {
  fetchServer,
  setPagination,
  setServerStatus,
  setItem,
} from "store/news_table/news_table.actions";
import { NEWS_ROUTE } from "utils/constants/routes.constants";
import { NewsAPI } from "api";

// components
import SelectSettings from "../SelectedSettings/SelectedSettings";

// styles
import {
  TrMain,
  NewsTableContainer as TableContainer,
  DataContainer,
  IconContainer,
  NameContainer,
} from "pages/Panels/News/components/NewsTable/styles";
import { TerminalTitle } from "components/layouts/Table/GridHeaders/GridTable.styles";
import NewsPopupContent from "../NewsDetailsLink/NewsPopupContent";
import withHistory from "../NewsDetailsLink/withHistory";

const dateTimeFormat = new Intl.DateTimeFormat("uk", {
  day: "2-digit",
  month: "long",
  hour: "2-digit",
  minute: "2-digit",
});

const formatDate = (dateString) => {
  if (!dateString) return null;

  return dateTimeFormat
    .format(moment(dateString, "DD/MM/YYYY hh:mm:ss").toDate())
    .replace(" о ", ", ");
};

export default function Table({
  news,
  localLoading,
  setLocalLoading,
  isTableFull,
  setTableFull,
  isRequestWorking,
  setIsRequestWorking,
  selectedNews,
  setSelectedNews,
  editable,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const newsAPI = useMemo(() => new NewsAPI(), []);

  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Видалити повідомлення?");
  const [alertType, setAlertType] = useState("remove");

  useEffect(() => {
    resetServer();
  }, []);

  useEffect(() => {
    if (location.state && location.state.successChanged) {
      setAlertType("done");
      setAlertTitle("Ви успішно змінили повідомлення");
      setShowAlert(true);
      resetServer();
    } else if (location.state && location.state.successDeleted) {
      setAlertType("done");
      setAlertTitle("Ви успішно видалили повідомлення");
      setShowAlert(true);
      resetServer();
    }

    if (!!location.state) {
      history.push({ pathname: "/dash/news", state: null });
    }
  }, [location]);

  function toDetailPage(item) {
    dispatch(setItem(item));
    history.push(NEWS_ROUTE + "/" + item.id);
  }

  function checkAll(checkedAll) {
    if (checkedAll) return;

    setSelectedNews(news.map((el) => el.id));
  }

  async function markRead() {
    try {
      const { ErrorCode } = await newsAPI.markMessagesRead(
        selectedNews.join(",")
      );
      setShowAlert(true);
      setAlertTitle(
        ErrorCode === 0 ? "Операція успішна" : "Помилка оновлення повідомлень"
      );
      setAlertType(ErrorCode === 0 ? "done" : "block");
    } catch (error) {
      setShowAlert(true);
      setAlertTitle("Помилка оновлення повідомлень");
      setAlertType("block");
    }
    resetServer();
  }

  async function markNotRead() {
    try {
      const { ErrorCode } = await newsAPI.markMessagesNotRead(
        selectedNews.join(",")
      );
      setShowAlert(true);
      setAlertTitle(
        ErrorCode === 0 ? "Операція успішна" : "Помилка оновлення повідомлень"
      );
      setAlertType(ErrorCode === 0 ? "done" : "block");
    } catch (error) {
      setShowAlert(true);
      setAlertTitle("Помилка оновлення повідомлень");
      setAlertType("block");
    }
    resetServer();
  }

  async function deleteMessages() {
    try {
      const { ErrorCode } = await newsAPI.deleteMessages(
        selectedNews.join(",")
      );
      setShowAlert(true);
      setAlertTitle(
        ErrorCode === 0 ? "Операція успішна" : "Помилка видалення повідомлень"
      );
      setAlertType(ErrorCode === 0 ? "done" : "block");
    } catch (error) {
      setShowAlert(true);
      setAlertTitle("Помилка видалення повідомлень");
      setAlertType("block");
    }
    resetServer();
  }

  function resetServer() {
    setSelectedNews([]);
    dispatch(setServerStatus(false));
    dispatch(setPagination(0));
    setTableFull(false);
    dispatch(
      fetchServer({
        setTableFull,
        setIsRequestWorking,
        setLocalLoading,
        messageComponent: withHistory(NewsPopupContent, history),
      })
    );
  }

  return (
    <>
      <Refresh fn={resetServer} zIndex={8} />
      {editable && (
        <SelectSettings
          selectedLength={selectedNews.length}
          checkedAll={
            !isNaN(news?.length) && selectedNews?.length === news?.length
          }
          onCancel={() => setSelectedNews([])}
          onDelete={deleteMessages}
          onCheckAll={checkAll}
          onMarkRead={markRead}
          onMarkNotRead={markNotRead}
          loading={localLoading}
        />
      )}
      {showAlert ? (
        <Alert
          type={alertType}
          title={alertTitle}
          subtitle={""}
          fnClose={() => setShowAlert(false)}
          fnSubmit={() => setShowAlert(false)}
          btn_close="видалити"
        />
      ) : null}
      <TableContainer>
        {isTableFull && !news && !localLoading && !isRequestWorking && (
          <TerminalTitle style={{ marginTop: "20px" }}>
            Повідомлення відсутні
          </TerminalTitle>
        )}
        {news && news.length === 0 && !localLoading && (
          <TerminalTitle style={{ marginTop: "20px" }}>
            Повідомлень не знайдено.
          </TerminalTitle>
        )}
        {news &&
          news.length !== 0 &&
          news.map((item) => {
            const {
              id,
              message_id,
              message_preview,
              publish_date,
              message_read,
              message_type,
            } = item;
            const isNewsSelected = !!selectedNews.includes(message_id);

            return (
              <TrMain
                message_read={message_read}
                message_type={message_type}
                column
                onClick={() => toDetailPage(item)}
                key={id}
                editable={editable}
              >
                {editable && (
                  <div
                    className="body-cell vcenter"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={isNewsSelected}
                      onChange={() => {
                        if (isNewsSelected) {
                          setSelectedNews(
                            [...selectedNews].filter((el) => el !== item.id)
                          );
                        } else {
                          setSelectedNews([...selectedNews].concat([item.id]));
                        }
                      }}
                    />
                  </div>
                )}
                <div className="body-cell icon">
                  <IconContainer read={message_read}>
                    {message_read ? <BiEnvelopeOpen /> : <BiEnvelope />}
                  </IconContainer>
                </div>
                <div className="body-cell">
                  <NameContainer read={message_read}>
                    {message_preview}
                  </NameContainer>
                </div>
                <div className="body-cell time">
                  <DataContainer>{formatDate(publish_date)}</DataContainer>
                </div>
              </TrMain>
            );
          })}
      </TableContainer>
    </>
  );
}
