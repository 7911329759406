import React, { useState, useMemo } from "react";

import { b64DecodeUnicode } from "utils/helpers/base64";
// styles
import { MdKeyboardArrowDown } from "react-icons/md";
import {
  ZRecieptContainer,
  RecieptToggleButton,
  RecieptContent,
  RecieptText,
  Button,
  AButton,
} from "./z-reciept.styles";

const ZReciept = (props) => {
  const { type, base64String, onError } = props;
  const [opened, setOpened] = useState(false);
  const [receiptError, setReceiptError] = useState();
  const typeIsFiscal = type === "fiscal";

  const mainText = useMemo(() => {
    let receipt = "";

    if (typeIsFiscal && base64String) {
      try {
        receipt = b64DecodeUnicode(base64String);
      } catch ({ message }) {
        receipt = `Z-звіт не сформовано (${message}). Cпробуйте повторно відкрити сторінку фіскальної зміни`;
        setReceiptError(receipt);
        onError(receipt);
      }

      return receipt;
    }

    return null;
  }, [base64String, type]);

  return (
    <ZRecieptContainer>
      <RecieptToggleButton
        opened={opened}
        onClick={() => setOpened((val) => !val)}
        disabled={Boolean(receiptError)}
      >
        <span>{opened ? "Z-звіт" : "Переглянути Z-звіт"}</span>
        <MdKeyboardArrowDown className={opened ? "opened" : "closed"} />
      </RecieptToggleButton>
      <RecieptContent closed={!opened}>
        <RecieptText>{mainText}</RecieptText>
        <div className="buttons">
          {typeIsFiscal && (
            <AButton
              download={"Z-звіт.txt"}
              href={`data:text/plain;base64,${base64String}`}
            >
              Зберегти
            </AButton>
          )}
          {!typeIsFiscal && (
            <Button onClick={onOpenZRecieptInNewWindow}>Чек Z-звіт</Button>
          )}
        </div>
      </RecieptContent>
    </ZRecieptContainer>
  );

  function onOpenZRecieptInNewWindow() {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append("type", type);
    if (type === "bank" || type === "cash") {
      urlSearchParams.append("bdd", props.bdd);
      urlSearchParams.append("batch", props.batch);
      urlSearchParams.append("terminalId", props.terminalId);
    }
    if (type === "fiscal") {
      urlSearchParams.append("rro_id", props.rro_id);
      urlSearchParams.append("dfs_log_id", props.dfs_log_id);
    }

    const url = "/view-z-reciept?" + urlSearchParams.toString();

    window.open(url, "_blank");
  }
};

export default ZReciept;
