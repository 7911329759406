const SORT_TYPE = "sort";
const SEARCH_TYPE = "search";
const DATE_TYPE = "date";
const DEFAULT_TYPE = "default";
const SELECTOR_TYPE = "default";
const UNIT_SELECTOR_TYPE = "unit_selector";
const CATS_SELECTOR_TYPE = "cats_selector";
const MULTI_SELECTOR_TYPE = "multi_selector";

export {
  SORT_TYPE,
  SEARCH_TYPE,
  DATE_TYPE,
  DEFAULT_TYPE,
  SELECTOR_TYPE,
  UNIT_SELECTOR_TYPE,
  CATS_SELECTOR_TYPE,
  MULTI_SELECTOR_TYPE,
};
