import {
  SetDefaultButtonContainer,
  SetDefaultButton,
} from "./CheckCaption.style";

const CheckDefaultsButton = ({ disabled, onClick, text }) => {
  return (
    <SetDefaultButtonContainer>
      <SetDefaultButton onClick={onClick} disabled={disabled}>
        {text}
      </SetDefaultButton>
    </SetDefaultButtonContainer>
  );
};

export default CheckDefaultsButton;
