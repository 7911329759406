import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CashierTable } from "api";

import { useHeader } from "store/header/header.context";
import ChangeTitle, {
  ChangeLeft,
  SetMobileBackButton,
} from "utils/helpers/changetitle.helpers";
import { dropAlertState, setAlertState } from "store/alert/alert_actions";
import {
  fetchServer,
  loadStateServerTerminal,
  setResponse,
} from "store/cashier_table/cashier_table.actions";
import { stateLocalTerminal } from "../../../../store/cashier_table/cashier_table.slicer";
import { CASHIERS_ROUTE } from "utils/constants/routes.constants";

import {
  ErrorText,
  SettingPanel,
  SettingPanelContainer,
} from "assets/style/variables";
import { CashButtonContainer } from "../CashiersDetail/CashiersDetail.styles";
import { TextField, TextFieldPassw } from "components/forms";
import { Button } from "components/forms/Button/Button.styles";
import BackIcon from "ui/BackIcon/BackIcon";
import Preloader from "components/additional/PreLoader/Preloader";

const CashierAdd = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const headerDispatch = useHeader().dispatch;
  const cashiers = useSelector(stateLocalTerminal);

  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState("");
  const [name, setName] = useState("");
  const [passw, setPassw] = useState("");
  const [rpassw, setRPassw] = useState("");
  const [phone, setPhone] = useState("");

  const [loginErr, setLoginErr] = useState({
    isError: false,
    msg: "",
  });
  const [nameErr, setNameErr] = useState({
    isError: false,
    msg: "",
  });
  const [passwErr, setPasswErr] = useState({
    isError: false,
    msg: "",
  });
  const [rpasswErr, setRPasswErr] = useState({
    isError: false,
    msg: "",
  });
  const [phoneErr, setPhoneErr] = useState({
    isError: false,
    msg: "",
  });

  const validateFormPassw = (fn) => {
    let err = false;
    const fields = [
      {
        val: passw,
        error: passwErr,
        setErr: setPasswErr,
      },
      {
        val: rpassw,
        error: rpasswErr,
        setErr: setRPasswErr,
      },
    ];

    // check fields empty
    fields.forEach((el) => {
      if (el.val.length === 0) {
        el.setErr({ isError: true, msg: "Заповніть поле!" });
        err = true;
      } else {
        el.setErr({ isError: false, msg: "" });
      }
    });

    if (err) return;

    // check passw empty

    if (passw.length < 8) {
      setPasswErr({ isError: true, msg: "Пароль має бути більше 8 символів!" });
      err = true;
    } else {
      setPasswErr({ isError: false, msg: "" });
    }
    if (err) return;

    if (passw !== rpassw) {
      setRPasswErr({ isError: true, msg: "Паролі не співпадають!" });
      setPasswErr({ isError: true, msg: "Паролі не співпадають!" });
      err = true;
    } else {
      setRPasswErr({ isError: false, msg: "" });
      setPasswErr({ isError: false, msg: "" });
    }

    if (!err) fn();
  };

  const validateForm = (fn) => {
    let err = false;
    const fields = [
      {
        val: login,
        error: loginErr,
        setErr: setLoginErr,
      },
      {
        val: name,
        error: nameErr,
        setErr: setNameErr,
      },

      {
        val: phone,
        error: phoneErr,
        setErr: setPhoneErr,
      },
      {
        val: passw,
        error: passwErr,
        setErr: setPasswErr,
      },
      {
        val: rpassw,
        error: rpasswErr,
        setErr: setRPasswErr,
      },
    ];

    fields.forEach((el) => {
      if (el.val.length === 0) {
        el.setErr({ isError: true, msg: "Заповніть поле!" });
        err = true;
      } else {
        el.setErr({ isError: false, msg: "" });
      }
    });

    if (err) return;

    if (!err) {
      if (!validateLogin(login)) {
        return dispatch(
          setAlertState({
            show: true,
            type: "block",
            title: "Помилка!",
            subtitle: `Касир з логіном ${login} вже існує!`,
            fnClose: () => {
              dispatch(dropAlertState());
            },
          })
        );
      }

      validateFormPassw(fn);
    }
  };

  const handlePhone = (val) => {
    if (val.length === 0 || val.length === 4) return setPhone("+380");
    if (val.length === 22) return;
    const reg = new RegExp("^[0-9+]+$", "g");
    if (!val.match(reg)) return;

    if (val.length === 1) return setPhone("+380" + val);
    if (val.length >= 1) return setPhone("+380" + val.slice(4));
  };

  const handleName = (val, fn) => {
    if (val.length === 0) return fn(val);
    const reg = new RegExp(
      "^[A-Za-z0-9АБВГҐДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЮЬЯабвгґдеєжзиіїйклмнопрстуфхцчшщьюя'= -]+$",
      "g"
    );
    if (!val.match(reg)) return;
    fn(val);
  };

  const handlePasswLogin = (val, fn) => {
    if (val.length === 0) return fn(val);
    if (val.length === 101) return;

    const reg = new RegExp("^[^!*'();:&=+$,/?%#\\[\\]\\s]+$");

    if (!val.match(reg)) return;

    fn(val);
  };

  const successAddedUser = () => {
    dispatch(
      setAlertState({
        show: true,
        type: "done",
        title: "Успішно!",
        subtitle: "Ви успішно створили нового касира!",
        fnClose: () => {
          dispatch(dropAlertState());
          dispatch(setResponse([]));
          dispatch(loadStateServerTerminal());
          dispatch(fetchServer());

          history.push(CASHIERS_ROUTE);
        },
      })
    );
  };

  function validateLogin(login) {
    return !cashiers
      .map(({ items }) => items)
      .flat()
      .find(({ app_login }) => app_login === login);
  }

  const addUser = () => {
    const cashierApi = new CashierTable();

    setLoading(true);

    cashierApi
      .add({
        app_login: login,
        app_pass: passw,
        user_name: name,
        phone,
        role: "CACHIER",
      })
      .then(() => {
        successAddedUser();
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    ChangeTitle("Додавання Касира", headerDispatch);
    ChangeLeft(headerDispatch);
    SetMobileBackButton(true, headerDispatch);
  }, [headerDispatch]);

  return (
    <>
      <SettingPanel>
        <SettingPanelContainer>
          <TextField
            name="Логін"
            placeholder="name@mail.com"
            value={login}
            fn={(val) => handlePasswLogin(val, setLogin)}
            className="input-active"
            error={loginErr.isError}
          />
          <ErrorText className="error_text">{loginErr.msg}</ErrorText>
          <TextField
            name="Ім’я"
            placeholder="Надайте ім’я"
            value={name}
            fn={(val) => handleName(val, setName)}
            className="input-active"
            error={nameErr.isError}
          />
          <ErrorText className="error_text">{nameErr.msg}</ErrorText>
          <TextField
            name="Мобільний телефон"
            placeholder={"+380"}
            value={phone}
            fn={handlePhone}
            className="input-active"
            error={phoneErr.isError}
            fnFocus={() => {
              handlePhone(phone);
            }}
          />
          <ErrorText className="error_text">{phoneErr.msg}</ErrorText>
          <TextFieldPassw
            name="Пароль"
            placeholder="Введіть 8 значний пароль"
            value={passw}
            fn={setPassw}
            className="input-active"
            error={passwErr.isError}
            showStar={false}
          />
          <ErrorText className="error_text">{passwErr.msg}</ErrorText>
          <TextFieldPassw
            name="Повторити пароль"
            placeholder="Повторіть пароль"
            value={rpassw}
            fn={setRPassw}
            className="input-active"
            error={rpasswErr.isError}
          />
          <ErrorText className="error_text">{rpasswErr.msg}</ErrorText>
          {!loading && (
            <CashButtonContainer>
              <Button onClick={() => validateForm(addUser)}>Додати</Button>
            </CashButtonContainer>
          )}
          {loading && <Preloader style={{ margin: "0" }} />}
        </SettingPanelContainer>
      </SettingPanel>
      <BackIcon className="default" />
    </>
  );
};

export default CashierAdd;
