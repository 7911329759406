import React, { useRef, useState } from 'react';
import { Input, TextFieldContainer, SubTitle } from './TextField.styles';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as EyeClosed } from 'assets/icons/eye_close.svg';

const TextFieldPassw = (props) => {
  const { name, placeholder, value, fn, disabled, error, className, showStar, nameInput } = props;

  const [val, setVal] = useState(value);
  const [passwLocal, setPasswLocal] = useState('');
  const [isPasswordHide, setIsPasswordHide] = useState(true);
  const inputElement = useRef(null);
  const Icon = isPasswordHide ? EyeClosed : Eye;

  const generateStar = (count) => '*'.repeat(count);

  function showPassword() {
    setIsPasswordHide(!isPasswordHide);
    isPasswordHide ? setVal(passwLocal) : setVal(generateStar(val.length));
    inputElement.current.focus();
    inputElement.current.selectionStart = inputElement.current.value.length;
    inputElement.current.selectionEnd = inputElement.current.value.length;
  }

  function handleVal(e) {
    let text = e.target.value;
    if (isPasswordHide) text = changePassw(text);

    setVal(isPasswordHide ? generateStar(text.length) : text);
    setPasswLocal(text);

    fn(text);
  }

  function changePassw(passw) {
    return passw.length < passwLocal.length
      ? passwLocal.slice(0, passw.length)
      : passwLocal + passw.slice(passwLocal.length, passw.length);
  }

  const additionPasswordNotShowStyles =
    isPasswordHide && !!passwLocal && passwLocal.length !== 0 ? { fontFamily: 'Verdana' } : {};

  return (
    <TextFieldContainer>
      <SubTitle error={error}>{name}</SubTitle>
      <Input
        type={isPasswordHide ? 'password' : 'text'}
        value={val}
        placeholder={placeholder}
        onChange={handleVal}
        ref={inputElement}
        disabled={disabled}
        error={error}
        className={className}
        name={nameInput}
        style={{ paddingRight: '40px', ...additionPasswordNotShowStyles }}
      />
      {!disabled && <Icon className='icon' onClick={showPassword} />}
    </TextFieldContainer>
  );
};

export default TextFieldPassw;
