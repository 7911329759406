import {
  ChartHeader as Header,
  ChartTitle as Title,
} from "../../../../Statistics.styles";
import { Total } from "pages/Panels/Statistics/components";
import useHeader from "./useHeader";
import Toolbar from "../ChartToolbar/ChartToolbar";
import { STAT_NAMES, STAT_TYPES } from "pages/Panels/Statistics/constants";

const ChartHeader = ({ total, chartState, isLoading }) => {
  const { beginYear, endYear, years, setBeginYear, setEndYear } =
    useHeader(chartState);

  return (
    <Header>
      <Title>{STAT_NAMES[STAT_TYPES.BY_YEAR]}</Title>
      <Toolbar
        {...{
          beginYear,
          setBeginYear,
          endYear,
          setEndYear,
          years,
          isLoading,
        }}
      />
      <Total total={total} />
    </Header>
  );
};

export default ChartHeader;
