import moment from "moment";
import {
  SubTotalContainer,
  SubTotalItem,
  StrongTitle,
  Value,
  Price,
  TextContainer,
  TextLine,
  MainContainer,
  MainCaption,
} from "./SubTotal.styles";

import {
  TRANSACTION_TYPE,
  CARD_TYPE,
  RESULT_TYPE,
  CASH_TYPE,
  PAYMENT_TYPE,
  RETURN_TYPE,
  CASHLESS_TYPE,
  QR_TYPE,
} from "utils/constants/subtotal.constants";

import {
  TransactionIcon,
  CardIcon,
  CashIcon,
  ReturnIcon,
  ResutIcon,
  PaymentIcon,
  QRIcon,
} from "./SubTotal.icons";
import { API_SIMPLE_DATE_FORMAT, UI_DATE_FORMAT } from "utils/constants/time";

export default function MainSubTotal({ state, dateRange }) {
  const iconOrTitle = (bool, icon, title) => (bool ? icon : title);

  function renderIconOrTitle(type, showIcon) {
    switch (type) {
      case TRANSACTION_TYPE:
        return iconOrTitle(showIcon, <TransactionIcon />, "Транзакцій");

      case CARD_TYPE:
        return iconOrTitle(showIcon, <CardIcon />, "Карткою");

      case CASH_TYPE:
        return iconOrTitle(showIcon, <CashIcon />, "Готівкою");

      case RETURN_TYPE:
        return iconOrTitle(showIcon, <ReturnIcon />, "Повернення");

      case RESULT_TYPE:
        return iconOrTitle(showIcon, <ResutIcon />, "Підсумок");

      case PAYMENT_TYPE:
        return iconOrTitle(showIcon, <PaymentIcon />, "Оплата");

      case CASHLESS_TYPE:
        return iconOrTitle(showIcon, <CardIcon />, "Безготівкові");

      case QR_TYPE:
        return iconOrTitle(showIcon, <QRIcon />, "QR-оплата");

      default:
        throw new Error("this type in subtotal not exist!");
    }
  }

  function renderValue(value, isPositive) {
    const number = Number(value);
    const stringValue = Number.isNaN(number) ? "0.00" : number.toFixed(2);

    const valueAfterDot = (val) => {
      if (val) {
        return val.toString().length === 1 ? "." + val + "0" : "." + val;
      }
      return ".00";
    };

    const val = stringValue.split(".");

    return (
      <Value>
        {+val[0] > 0 ? `${isPositive ? "+" : "-"}${val[0]}` : val[0]}
        <Price>{valueAfterDot(val[1])}₴</Price>
      </Value>
    );
  }

  const renderResultLine = (item) => (
    <TextLine>
      {`Транзакцій:`}
      <Value>{item.value.count}</Value>
      {`на`}
      {renderValue(item.value.amount, true)}
    </TextLine>
  );

  const renderDataLine = (item) => (
    <>
      <TextLine>
        {`Оплата:`}
        <Value>{item.value.count}</Value>
        {`на`}
        {renderValue(item.value.amount, true)}
      </TextLine>
      <TextLine>
        {`Повернення:`}
        <Value>{item.value.refundCount}</Value>
        {`на`}
        {renderValue(item.value.refund, false)}
      </TextLine>
    </>
  );

  const makeCaption = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;

    if (dateFrom === dateTo) {
      return moment(dateFrom, API_SIMPLE_DATE_FORMAT).format(UI_DATE_FORMAT);
    }

    return `${moment(dateFrom, API_SIMPLE_DATE_FORMAT).format(
      UI_DATE_FORMAT
    )} - ${moment(dateTo, API_SIMPLE_DATE_FORMAT).format(UI_DATE_FORMAT)}`;
  };

  return (
    <MainContainer>
      <MainCaption>{makeCaption(dateRange)}</MainCaption>
      <SubTotalContainer>
        {state.map((item, key) => {
          return (
            <SubTotalItem key={key}>
              {renderIconOrTitle(item.type, true)}
              <TextContainer>
                <StrongTitle>{renderIconOrTitle(item.type, false)}</StrongTitle>
                {item.type === RESULT_TYPE
                  ? renderResultLine(item)
                  : renderDataLine(item)}
              </TextContainer>
            </SubTotalItem>
          );
        })}
      </SubTotalContainer>
    </MainContainer>
  );
}
