import React from "react";

import { TerminalDetailHeaderItem } from "../TerminalDetail.styles";
import { ReactComponent as BanIcon } from "assets/icons/terminalDetail/ban.svg";

const TurnOffTerminalButton = ({ state, isEComm, setupBlockTerminal }) => {
  if (!(isEComm || state?.status_id !== 1 || !state?.binded))
    return (
      <TerminalDetailHeaderItem
        onClick={setupBlockTerminal}
        className="button_bg-white"
      >
        <BanIcon /> Відключити
      </TerminalDetailHeaderItem>
    );

  return null;
};

export default TurnOffTerminalButton;
