import moment from "moment";

import {
  TRANSACTION_TYPE,
  PAYMENT_TYPE,
  RESULT_TYPE,
  RETURN_TYPE,
} from "utils/constants/subtotal.constants";

import {
  TABLE_OPEN_GET_SERVER,
  TABLE_OPEN_SET_HEADER,
  FISCAL_DETAIL_LOADING,
  SET_FISCAL_LOADING,
  SET_CLOSED_FILTETING_STATE,
  DROP_CLOSED_FILTERING_STATE,
  TABLE_CLOSED_GET_SERVER,
  TABLE_DETAIL_ITEM,
  FISCAL_DETAIL_ERROR,
  FISKAL_OPENED_LIST_ERROR,
  FISKAL_CLOSED_LIST_ERROR,
  INSERT_EXTENDED_INFO,
} from "./fiscal_table.types";

const initialClosedFilteringState = {
  terminal: null,
  cashier: null,
  startDate: null,
  endDate: null,
  transaction_count: null,
  total_sum: null,
};

const initialState = {
  fiscal_detail_loading: false,
  fiscal_loading: false,
  closedFilteringState: {},

  bank_open_state: [],
  bank_open_subtotal_state: [
    {
      type: TRANSACTION_TYPE,
      value: 0,
    },
    {
      type: PAYMENT_TYPE,
      value: 0,
    },
    {
      type: RETURN_TYPE,
      value: 0,
    },
    {
      type: RESULT_TYPE,
      value: 0,
    },
  ],
  bank_closed_state: [],
  fiscal_detail_error: null,
  fiscal_opened_list_error: null,
  fiscal_closed_list_error: null,

  bank_detail_item: {},
};

export default function fiscalTableReducer(state = initialState, action) {
  switch (action.type) {
    case TABLE_OPEN_GET_SERVER:
      return {
        ...state,
        bank_open_state: action.payload,
      };
    case TABLE_CLOSED_GET_SERVER:
      return {
        ...state,
        bank_closed_state: action.payload,
      };

    case TABLE_OPEN_SET_HEADER:
      return {
        ...state,
        bank_open_subtotal_state: [...action.payload],
      };
    case FISCAL_DETAIL_LOADING:
      return {
        ...state,
        fiscal_detail_loading: action.payload,
      };
    case SET_FISCAL_LOADING:
      return {
        ...state,
        fiscal_loading: action.payload,
      };
    case TABLE_DETAIL_ITEM:
      return {
        ...state,
        bank_detail_item: action.payload,
      };
    case FISCAL_DETAIL_ERROR:
      return {
        ...state,
        fiscal_detail_error: action.payload,
      };
    case FISKAL_OPENED_LIST_ERROR:
      return {
        ...state,
        fiscal_opened_list_error: action.payload,
      };
    case FISKAL_CLOSED_LIST_ERROR:
      return {
        ...state,
        fiscal_closed_list_error: action.payload,
      };
    case SET_CLOSED_FILTETING_STATE:
      return {
        ...state,
        closedFilteringState: action.payload,
      };
    case DROP_CLOSED_FILTERING_STATE:
      return {
        ...state,
        closedFilteringState: initialClosedFilteringState,
      };
    case INSERT_EXTENDED_INFO:
      const { shiftCloseDateTiks, extendedInfo } = action.payload;
      return {
        ...state,
        bank_closed_state: [...state.bank_closed_state].map((el) => ({
          ...el,
          items: el.items.map((el) => {
            if (el.info.shiftCloseDateTiks === shiftCloseDateTiks) {
              return {
                ...el,
                extendedInfo: {
                  ...extendedInfo,
                  transactionsList: extendedInfo.transactionsList.sort(
                    (a, b) => {
                      if (moment(a.ctime) < moment(b.ctime)) {
                        return 1;
                      }
                      if (moment(a.ctime) > moment(b.ctime)) {
                        return -1;
                      }

                      return 0;
                    }
                  ),
                },
              };
            } else return el;
          }),
        })),
      };
    default:
      return state;
  }
}

export const fiscalClosedFilteringState = (state) =>
  state.fiscal_table.closedFilteringState;
export const serverClosedLoading = (state) =>
  state.fiscal_table.bank_closed_loading;

export const bOpenTotal = (state) =>
  state.fiscal_table.bank_open_subtotal_state;
export const bOpenState = (state) => state.fiscal_table.bank_open_state;

export const bClosedState = (state) => state.fiscal_table.bank_closed_state;

export const bankDetailItem = (state) => state.fiscal_table.bank_detail_item;

export const fiscalDetailLoading = (state) =>
  state.fiscal_table.fiscal_detail_loading;
export const fiscalDetailError = (state) =>
  state.fiscal_table.fiscal_detail_error;
export const fiscalOpenedListError = (state) =>
  state.fiscal_table.fiscal_opened_list_error;
export const fiscalClosedListError = (state) =>
  state.fiscal_table.fiscal_closed_list_error;
