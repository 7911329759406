import { useSelector } from "react-redux";

import ChartHeader from "./components/ChartHeader/ChartHeader";
import Chart from "./components/Chart/Chart";
import ChartTotals from "../../components/ChartTotals/ChartTotals";
import Preloader from "components/additional/PreLoader/Preloader";
import { mobileVersion } from "store/main_table/main_table.slicer";
import {
  ChartContainer,
  ChartInfoContainer,
  ChartScrollContainer,
  ChartWrapper,
} from "../../Statistics.styles";
import useChartState from "./useChartState";
import { STAT_TYPES } from "../../constants";

const TotalsPerMonthByYears = () => {
  const isMobile = useSelector(mobileVersion) < 1024;
  const { chartTotals, chartState, data, isLoading } = useChartState();

  return (
    <>
      <ChartHeader
        total={chartTotals.total}
        chartState={chartState}
        isLoading={isLoading}
      />
      <ChartContainer>
        <ChartScrollContainer className="perMonthByYears">
          {isLoading ? (
            <ChartInfoContainer>
              <Preloader />
            </ChartInfoContainer>
          ) : (
            <>
              <ChartWrapper isMobile={isMobile}>
                <Chart data={data} />
              </ChartWrapper>
              <ChartTotals
                totals={chartTotals.totals}
                chartType={STAT_TYPES.BY_MONTH}
                quarterly={chartState.quarterly}
              />
            </>
          )}
        </ChartScrollContainer>
      </ChartContainer>
    </>
  );
};

export default TotalsPerMonthByYears;
