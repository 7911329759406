import styled from "styled-components";

export const FiscalOnContainer = styled.section`
  text-align: center;
`;

export const FiscalOnHeader = styled.h2`
  font-size: 18px;
  margin-bottom: 1rem;
`;

export const FiscalOnInfo = styled.figure``;

export const FiscalOnSteps = styled.ul`
  list-style: inside;
  list-style-type: square;
  text-align: left;
`;

export const FiscalOnStep = styled.li``;

export const MoreInfoOnFiscalOn = styled.figcaption`
  margin-top: 1rem;

  a:visited,
  a:active {
    color: #179c92;
  }
`;
