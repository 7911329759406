import React, { useEffect } from "react";
import moment from "moment";

import TableHeaders from "components/layouts/Table/Headers/Table.headers";
import {
  TableContainer,
  TerminalText,
  TerminalDescribe,
  TerminalTitle,
  Td,
} from "components/layouts/Table/Table.styles";
import { TrMain } from "./BankClosed.table.styles";
import {
  SEARCH_TYPE,
  DATE_TYPE,
  SELECTOR_TYPE,
} from "utils/constants/table.headers.constants";
import { TrTransactionDate } from "pages/Panels/Transaction/Transaction.styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchServerClosed,
  bankClosedSetStateLocal,
  bankClosedSetState,
  bankClosedLoad,
  bankDateTo,
  bankDateFrom,
  setSearchTotalsVal,
  setSearchTransactionVal,
  setSearchTerminalVal,
  setSearchBoxVal,
} from "store/bank_table/bank_table.actions";
import Preloader from "components/additional/PreLoader/Preloader";
import {
  bClosedState,
  serverClosedLoading,
  searchValBox,
  searchValTotal,
  searchValTransaction,
  searchValDatesTo,
  searchValDatesFrom,
  searchValTerm,
} from "store/bank_table/bank_table.slicer";
import { setBankFilteringState } from "store/transaction_table/transaction_table.actions";
import GenerateDay from "utils/helpers/generateDay.helpers";
import Refresh from "ui/Refresh/Refresh";
import GenerateDate from "utils/helpers/date.helpers";
import { API_SIMPLE_DATE_FORMAT } from "utils/constants/time";
import { toDetailPage } from "../bank-helpers";
import { fetchServer as fetchTerminals } from "store/main_table/main_table.actions";
import { searchValuesTransaction as selectTerminals } from "store/main_table/main_table.slicer";

export default function Table({ setBusinessDays }) {
  const state = useSelector(bClosedState);
  const isServerLoaded = useSelector(serverClosedLoading);
  const boxVal = useSelector(searchValBox);
  const totalVal = useSelector(searchValTotal);
  const transactionVal = useSelector(searchValTransaction);
  const termVal = useSelector(searchValTerm);
  const datesToVal = useSelector(searchValDatesTo);
  const datesFromVal = useSelector(searchValDatesFrom);
  const terminalValues = useSelector(selectTerminals);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("bank_exit_with_drop_state", 1);
    dispatch(fetchServerClosed(true));

    if (!terminalValues?.length) {
      dispatch(fetchTerminals());
    }

    return () => {
      if (!!localStorage.getItem("bank_exit_with_drop_state")) {
        localStorage.removeItem("bank-bdd");
        localStorage.removeItem("bank-ptname");
        localStorage.removeItem("bank-batch");
        dispatch(setBankFilteringState({ field: "ptname", value: null }));
        dispatch(setBankFilteringState({ field: "bdd", value: null }));
        dispatch(setBankFilteringState({ field: "batch", value: null }));
      }
    };
  }, []);

  const generate = GenerateDate();

  function chooseCalendarFrom(state) {
    const date = state;

    dispatch(bankDateFrom(date ? generate.format(date) : null));
  }

  function chooseCalendarTo(state) {
    const date = state;

    dispatch(bankDateTo(date ? generate.format(date) : null));
  }

  function dispatchHandler(fn, val) {
    dispatch(fn(val));
    dispatch(bankClosedSetState([]));
    dispatch(bankClosedSetStateLocal([]));
    dispatch(fetchServerClosed());
  }

  function formatDate(val) {
    if (!val) return "";

    return `${val.slice(6, 9)}.${val.slice(4, 6)}.${val.slice(0, 4)}`;
  }

  useEffect(() => {
    const businessDays = state.map(({ items }) => items).flat();

    setBusinessDays(businessDays);
  }, [state]);

  return (
    <>
      <Refresh fn={() => dispatch(fetchServerClosed(true))} />
      {!isServerLoaded && state.length === 0 && (
        <TerminalTitle style={{ marginTop: "20px" }}>
          Відсутні закриті бізнес-дні.
        </TerminalTitle>
      )}
      <TableContainer>
        <thead>
          <TrMain>
            <TableHeaders
              state={terminalValues}
              choose_state={termVal}
              type={SELECTOR_TYPE}
              fn={(val) => {
                dispatchHandler(setSearchTerminalVal, val);
              }}
              fn_reset={() => {
                dispatchHandler(setSearchTerminalVal, null);
              }}
              text="Термінал"
            />
            <TableHeaders
              type={SEARCH_TYPE}
              choose_state={boxVal}
              fn={(val) => dispatchHandler(setSearchBoxVal, val)}
              fn_reset={() => dispatchHandler(setSearchBoxVal, null)}
              text="Пачка"
            />
            <TableHeaders
              choose_state={formatDate(datesFromVal)}
              value={
                datesFromVal
                  ? moment(datesFromVal, API_SIMPLE_DATE_FORMAT).toDate()
                  : null
              }
              rangeFrom={
                datesFromVal
                  ? moment(datesFromVal, API_SIMPLE_DATE_FORMAT).toDate()
                  : null
              }
              rangeTo={
                datesToVal
                  ? moment(datesToVal, API_SIMPLE_DATE_FORMAT).toDate()
                  : null
              }
              type={DATE_TYPE}
              fn={(val) => {
                chooseCalendarFrom(val[0].startDate);
                chooseCalendarTo(val[0].endDate);
                dispatch(bankClosedSetState([]));
                dispatch(bankClosedSetStateLocal([]));
                dispatch(fetchServerClosed());
              }}
              fn_reset={() => {
                dispatch(bankDateFrom(null));
                dispatch(bankClosedLoad(true));
                dispatch(fetchServerClosed());
              }}
              text="Дата відкриття"
              range
              // required
            />
            <TableHeaders
              choose_state={formatDate(datesToVal)}
              value={
                datesToVal
                  ? moment(datesToVal, API_SIMPLE_DATE_FORMAT).toDate()
                  : null
              }
              rangeFrom={
                datesFromVal
                  ? moment(datesFromVal, API_SIMPLE_DATE_FORMAT).toDate()
                  : null
              }
              rangeTo={
                datesToVal
                  ? moment(datesToVal, API_SIMPLE_DATE_FORMAT).toDate()
                  : null
              }
              type={DATE_TYPE}
              fn={(val) => {
                chooseCalendarFrom(val[0].startDate);
                chooseCalendarTo(val[0].endDate);
                dispatch(bankClosedSetState([]));
                dispatch(bankClosedSetStateLocal([]));
                dispatch(fetchServerClosed());
              }}
              fn_reset={() => {
                dispatch(bankDateTo(null));
                dispatch(bankClosedLoad(true));
                dispatch(fetchServerClosed());
              }}
              text="Дата закриття"
              range={true}
              // required
            />
            <TableHeaders
              choose_state={transactionVal}
              type={SEARCH_TYPE}
              text="Транзакцій"
              fn={(val) => dispatchHandler(setSearchTransactionVal, val)}
              fn_reset={() => dispatchHandler(setSearchTransactionVal, null)}
            />
            <TableHeaders
              choose_state={totalVal}
              type={SEARCH_TYPE}
              fn={(val) => dispatchHandler(setSearchTotalsVal, val)}
              fn_reset={() => dispatchHandler(setSearchTotalsVal, null)}
              text="Сума"
            />
          </TrMain>
        </thead>
        <tbody>
          {!isServerLoaded &&
            state.length !== 0 &&
            state.map((item) => {
              return (
                <React.Fragment key={item.id}>
                  <TrTransactionDate>
                    <Td>{GenerateDay(item.date)}</Td>
                  </TrTransactionDate>
                  {item.items.map((term, tKey) => {
                    const closedTime = term.sett_date_time.split(" ");
                    return (
                      <TrMain
                        key={tKey}
                        column
                        onClick={() =>
                          toDetailPage(term, "closed", dispatch, history)
                        }
                      >
                        <Td>
                          <TerminalText>
                            <TerminalTitle>{term.ptdesctiption}</TerminalTitle>
                            <TerminalDescribe>
                              {term.device_name}, Terminal ID: {term.ptname}
                            </TerminalDescribe>
                          </TerminalText>
                        </Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>{term.batch}</TerminalTitle>
                          </TerminalText>
                        </Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>{term.bdd}</TerminalTitle>
                          </TerminalText>
                        </Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>{closedTime[0]}</TerminalTitle>
                            <TerminalDescribe>{closedTime[1]}</TerminalDescribe>
                          </TerminalText>
                        </Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>{term.count_total}</TerminalTitle>
                          </TerminalText>
                        </Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>
                              {" "}
                              {(
                                Math.round(term.amount_total * 100) / 100
                              ).toFixed(2)}
                              ₴
                            </TerminalTitle>
                          </TerminalText>
                        </Td>
                      </TrMain>
                    );
                  })}
                </React.Fragment>
              );
            })}
        </tbody>
      </TableContainer>
      {isServerLoaded && <Preloader />}
    </>
  );
}
