import styled from "styled-components";
import { tiffany } from "assets/style/variables";

import { ButtonSwitcher } from "../Bank/Bank.styles";
import { size } from "assets/style/global-variables";

export const GoodsContainer = styled.section`
  margin: 0;
  margin-bottom: 55px;
  width: 100%;
  min-width: 320px;
`;

export const GoodsHeader = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  @media screen and (min-width: ${size.tablet}) {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1rem;
  }
`;

export const ExportButton = styled(ButtonSwitcher)`
  padding: 14px 0;
  max-width: 163px;
  display: flex;
  align-items: center;
  justify-content: center;

  .export-blue {
    margin-right: 5px;
    font-size: 20px;
    color: ${tiffany};
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;

  button,
  label {
    padding: 12px 10px 12px 9px;
    width: auto;
    border: 1px solid rgba(112, 120, 127, 0.2);
    box-shadow: none;
  }

  label {
    display: flex;
    align-items: center;
    grid-gap: 7px;
    justify-content: center;
    font-family: "Futura Book", sans-serif;
    border-radius: 12px;
    cursor: pointer;
    svg {
      font-size: 22px;
      color: ${tiffany};
    }
  }

  @media screen and (max-width: 1485px) {
    span.optional {
      display: none;
    }
  }

  @media screen and (min-width: ${size.tablet}) {
    column-gap: 10px;

    button,
    label {
      padding: 14px 20px;
    }

    label {
      grid-gap: 10px;
    }
  }
`;
