import { SET_PROFILE, SET_KEY, SET_OUT_OF_DATE_KEYS } from "./user_types";

export const setProfile = (newProfile) => ({
  type: SET_PROFILE,
  payload: newProfile,
});

export const setKey = (newKey) => ({
  type: SET_KEY,
  payload: newKey,
});

export const setOutOfDateKeys = (keys) => ({
  type: SET_OUT_OF_DATE_KEYS,
  payload: keys,
});
