import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentStatisticsData,
  selectCurrentStatisticsParams,
} from "store/statistics/stat_slicer";
import { DEFAULT_TOTALS, STAT_TYPES } from "../../constants";
import { fetchStatByYears } from "store/statistics/stat_actions";

const useChartState = () => {
  const dispatch = useDispatch();
  const chartState = useSelector(selectCurrentStatisticsParams);
  const { data, isLoading } = useSelector(selectCurrentStatisticsData);
  const [chartTotals, setChartTotals] = useState(DEFAULT_TOTALS);

  useEffect(() => {
    const { beginYear, endYear, quarterly } = chartState;

    dispatch(
      fetchStatByYears(STAT_TYPES.BY_MONTH, beginYear, endYear, quarterly)
    );
  }, [chartState]);

  useEffect(() => {
    const totals = data.map(({ name, ...rest }) => ({
      name,
      total: Object.values(rest).reduce((a, c) => a + c),
    }));
    const total = totals.reduce((a, c) => a + c.total, 0);

    setChartTotals({ total, totals });
  }, [data]);

  return {
    chartTotals,
    chartState,
    data,
    isLoading,
  };
};

export default useChartState;
