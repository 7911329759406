import styled from "styled-components";

import { black, mouse_pointer } from "assets/style/variables";
import { font_book } from "assets/style/fonts.variables";

export const ReportsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 20px;
`;

export const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  font-family: ${font_book};
  color: ${black};
  font-size: 16px;
  ${mouse_pointer}

  .ring, .ring div {
    width: 20px;
    height: 20px;
    border-width: 4px;
  }

  span {
    white-space: nowrap;
  }
`;
