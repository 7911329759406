export const trimMessage = (message, endingToCutOff) => {
  const trimmedMessage = String(message).trim();

  if (trimmedMessage.endsWith(endingToCutOff)) {
    const pos = trimmedMessage.indexOf(endingToCutOff);

    return trimmedMessage.substring(0, pos).trim();
  }

  return trimmedMessage;
};

export const replaceMessageEntry = (message, entryToReplace, replacement) => {
  return String(message).trim().replace(entryToReplace, replacement);
};
