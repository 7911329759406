import styled from "styled-components";

import { font_medium, font_book } from "./fonts.variables";
import { size } from "assets/style/global-variables";

const grey = "#70787F";
const grey_light = "rgba(112, 120, 127, 0.2)";
const grey_light_search = "#EDEFF5";
const grey_light_setting = "#F6F7FA";
const grey_input = "#F1F1F1";
const grey_off = "#C5C5C5";
const black = "#111E29";
const tiffany = "#26CFC7";
const tiffany_light = "#29E2DA";
const red = "#DD3C3C";
const white = "#fff";
const oshad_green = "#004B45";
const light_green = "#307B75";
const dark_green = "#002B25";
// const dss = '#dcffee';

const button_shadow = "0px 2px 10px rgba(0, 0, 0, 0.05)";

const gradient_green = `linear-gradient(109.17deg, #179C92 -123.63%, #004B45 270.75%)`;

const ErrorText = styled.p`
  font-size: 16px;
  color: ${red};
  text-align: left;
  font-family: ${font_medium};
`;

const SettingPanel = styled.section`
  background: ${grey_light_setting};
  border-radius: 22px;
  border: 1px solid ${grey_light_search};
  position: relative;
  ${(props) => (props.isFlex ? `display: flex; flex-direction: column;` : " ")}
  ${(props) =>
    !props.isFlex ? `display: grid; grid-template-columns: 1fr 1fr;` : " "}
  
  ${(props) =>
    props.line
      ? `null`
      : `&:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: ${grey_light_search};
    margin: 0 auto;
    left: 0;
    right: 0;
  }`}

  .line-top {
    &::before {
      content: "";
      position: absolute;
      height: 1px;
      width: 50%;
      background: ${grey_light_search};
      margin: 0 auto;
      right: 0;
      top: 50%;
    }
  }

  .required-paragraph {
    margin: 20px;
    margin-top: 5px;
    color: #444;
    font-family: "Futura Book", sans-serif;
    font-size: 18px;
  }

  .template-name {
    align-self: flex-start;
    padding-top: 1rem;
    text-align: left;
  }
`;

const SettingPanelContainer = styled.div`
  padding: 15px 25px;

  input {
    border-color: ${(props) =>
      props.disable ? `${grey_light_setting}` : `none`};
    background: ${grey_light_setting};
  }

  input.input-active {
    background: ${(props) =>
      !props.disable ? `white` : `${grey_light_setting}`};
  }

  .select-active {
    background: ${(props) =>
      !props.disable ? `white` : `${grey_light_setting}`};
  }

  div:has(.payment-description-template) {
    margin-top: 1.75rem;
  }

  p {
    background: none;
  }
  .error_text {
    line-height: 0px;
    margin-left: 10px;
    padding-bottom: 5px;
  }

  @media screen and (max-width: ${size.tablet}) {
    padding: 8px 15px;
  }
`;
const SettingTitle = styled.p`
  font-family: ${font_book};
  font-weight: 400;
  font-size: 20px;
`;

const SettingFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const mouse_pointer = `
  &:hover{
    cursor: pointer;
  }
`;

const caret = `caret-color: ${tiffany}`;

const flex_center = `
display: flex;
align-items:center;
`;

export {
  flex_center,
  caret,
  grey_light_search,
  grey_light_setting,
  oshad_green,
  light_green,
  dark_green,
  gradient_green,
  grey_light,
  grey,
  grey_off,
  black,
  tiffany,
  tiffany_light,
  red,
  ErrorText,
  mouse_pointer,
  white,
  button_shadow,
  grey_input,
  SettingPanel,
  SettingPanelContainer,
  SettingTitle,
  SettingFlex,
};
