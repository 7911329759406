import styled from "styled-components";

export const IconContainer = styled.span`
  position: relative;
  display: block;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : undefined};
  border-radius: 0.75rem;
  height: 3rem;
  width: 3rem;
`;
