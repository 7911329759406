import { font_book, font_demi } from "assets/style/fonts.variables";
import { grey } from "assets/style/variables";
import { Button } from "components/forms/Button/Button.styles";
import styled from "styled-components";

export const TerminalContainer = styled.section``;

export const TrMain = styled.tr`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 0.7fr;
  grid-gap: 6px;
  margin: 12px 0;
  cursor: pointer;

  ${(props) =>
    props.column
      ? `
      padding: 17px;
      align-items: center;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      justify-items: flex-start;`
      : "null"}

  td:last-child {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const TrCashiersHeader = styled.tr`
  color: ${grey};
  font-family: ${font_book};
  font-size: 14px;
  font-weight: 400;

  td {
    padding: 24px 5px 0;
  }
`;

export const TerminalText = styled.section`
  h1 {
    font-family: ${font_demi};
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${grey};
    font-family: ${font_book};
    max-width: 670px;
  }
  a {
    color: #00bdb6;
  }
`;

export const GreenButton = styled(Button)`
  background: ${(props) =>
    props.ok
      ? `linear-gradient(107.25deg, #26CFC7 -28.3%, #DCE123 135.31%)`
      : `linear-gradient(125.73deg, #8CC6EC -143.17%, #F2665E 113.95%)`};
  border-radius: 18px;
  color: #231f20;
  text-align: center;
  padding: 5px 0;
  width: 98px;
  margin-left: auto;
  font-size: 14px;
`;
