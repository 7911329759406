import React, { useState } from "react";

import { TextField } from "components/forms";
import {
  ModalTitle,
  ModalButtons,
  ModalBody,
  ModalContainedButton,
  ModalOutlinedButton,
} from "ui/Modal/modal.styles";
import { SlujOperationContainer, WarningText } from "./style";

const SlujOperation = ({ config, onClose, onAccept }) => {
  const {
    acceptButtonLabel,
    title,
    amountCaption,
    amountPlaceholder,
    descriptionCaption,
    descriptionPlaceholder,
    type,
    terminalId,
    totalCash,
    rro,
  } = config;

  const [amount, setAmount] = useState({
    value: null,
    caption: amountCaption,
    placeholder: amountPlaceholder,
    formatFunc: ({ oldValue, newValue }) => {
      const regex1 = /^\d{0,10}[.,]?\d{0,2}$/;
      const regex2 = /^$/;

      if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

      if (
        newValue[0] === "0" &&
        newValue.length !== 1 &&
        newValue[1] !== "." &&
        newValue[1] !== ","
      ) {
        const splittedArray = newValue.split("");
        splittedArray.shift();

        return splittedArray.join("");
      } else if (
        (newValue[0] === "." || newValue[0] === ",") &&
        newValue.length > 1
      ) {
        const splittedArray = newValue.split("");
        splittedArray.unshift("0");

        return splittedArray.join("");
      }

      return newValue;
    },
  });

  const [description, setDescription] = useState({
    value: null,
    caption: descriptionCaption,
    placeholder: descriptionPlaceholder,
    formatFunc: (value) => value,
  });

  const setNewAmount = ({ value }) => {
    const newValue = amount.formatFunc({
      oldValue: amount.value,
      newValue: value,
    });
    setAmount({ ...amount, value: newValue });
  };

  const setNewDescription = (value) => {
    setDescription((state) => ({ ...state, value }));
  };

  const totalCashIsInvalid = totalCash === 0 && type === "sluj_vid";
  const amountIsBiggerThanTotalCash =
    type === "sluj_vid" && +amount.value > totalCash;
  const acceptButtonIsDisabled =
    !amount.value ||
    /^0?[.,]?$/.test(amount.value) ||
    totalCashIsInvalid ||
    amountIsBiggerThanTotalCash;
  const warningText =
    totalCashIsInvalid || amountIsBiggerThanTotalCash
      ? "Введена сума перевищує суму готівки в касі. Неможливо зробити службову видачу"
      : null;

  return (
    <SlujOperationContainer>
      <ModalTitle>{title}</ModalTitle>
      <ModalBody>
        <TextField
          name={amount.caption}
          placeholder={amount.placeholder}
          value={amount.value || ""}
          fn={(value) => setNewAmount({ value })}
          className="input-active"
          disabled={false}
        />
        <TextField
          name={description.caption}
          placeholder={description.placeholder}
          value={description.value || ""}
          fn={setNewDescription}
          disabled={false}
        />
      </ModalBody>
      <ModalButtons>
        {warningText && <WarningText>{warningText}</WarningText>}
        <ModalContainedButton
          onClick={() =>
            onAccept({
              type,
              terminalId,
              amount: Number(amount.value.replace(",", ".")),
              payment_comment: description.value,
              rro,
            })
          }
          disabled={acceptButtonIsDisabled}
        >
          {acceptButtonLabel}
        </ModalContainedButton>
        <ModalOutlinedButton onClick={onClose}>Скасувати</ModalOutlinedButton>
      </ModalButtons>
    </SlujOperationContainer>
  );
};

export default SlujOperation;
