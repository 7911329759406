import React, { useState, useEffect } from "react";

import { TextField } from "components/forms";
import { EditCategoryContainer, CloseButton } from "../CategoriesTable/styles";
import { SelectingButton } from "../GoodsTypeSwitcher/styles";

// assets
import { ReactComponent as CloseIcon } from "assets/icons/transaction/cancel.svg";

const EditCategory = (props) => {
  const { onEditCategory, oldCategoryName, onClose } = props;
  const [categoryForm, setCategoryForm] = useState({
    name: {
      value: oldCategoryName,
      caption: `Редагувати категорію (${oldCategoryName})`,
      placeholder: "Назва категорії",
      formatFunc: (oldValue, newValue) => {
        const regex1 = /^.{1,200}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        return newValue;
      },
    },
  });

  useEffect(() => {
    setCategoryForm({
      name: {
        value: null,
        caption: `Редагувати категорію (${oldCategoryName})`,
        placeholder: "Назва категорії",
        formatFunc: (oldValue, newValue) => {
          const regex1 = /^.{1,200}$/;
          const regex2 = /^$/;

          if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

          return newValue;
        },
      },
    });
  }, [oldCategoryName]);

  const setNewInputValueToForm = (field, newValue) => {
    setCategoryForm((categoryForm) => ({
      ...categoryForm,
      [field]: {
        ...categoryForm[field],
        value: categoryForm[field].formatFunc(
          categoryForm[field].value,
          newValue
        ),
      },
    }));
  };

  return (
    <EditCategoryContainer>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <TextField
        name={categoryForm.name.caption}
        placeholder={categoryForm.name.placeholder}
        value={categoryForm.name.value}
        fn={(val) => setNewInputValueToForm("name", val)}
        className="input-active"
      />
      <SelectingButton
        onClick={() => {
          onEditCategory(categoryForm.name.value);
          setCategoryForm({
            name: {
              ...categoryForm.name,
              value: null,
            },
          });
        }}
        disabled={!categoryForm.name.value || categoryForm.name.value === ""}
        selected
      >
        Редагувати
      </SelectingButton>
    </EditCategoryContainer>
  );
};

export default EditCategory;
