import LocalStorage from "services/LocalStorage/LocalStorage.service";
import Fetch from "services/Fetch/Fetch.service";
import GenerateDeviceId from "utils/helpers/generateDevice.helpers";
import download from "utils/helpers/download.helpers";
import { URL_SERVER_AUTH, URL_SERVER } from "utils/constants/server.constants";

export class Authentication {
  constructor() {
    this.fetch = new Fetch();
    this.localStorage = new LocalStorage();
  }

  login(name, passw) {
    const deviceId = GenerateDeviceId();
    this.localStorage.set("device", deviceId);

    const data = {
      app_login: name,
      tapx_login: name,
      app_pass: passw,
      device_id: "WEB_" + deviceId,
    };

    return this.fetch
      .post(`${URL_SERVER_AUTH}/api/Auth`, data)
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  exit() {
    const token = this.localStorage.get("token");
    const device_id = this.localStorage.get("device");
    return this.fetch
      .delete(`${URL_SERVER_AUTH}/api/Auth`, token, device_id)
      .then((res) => {
        this.localStorage.clear();
        return res;
      })
      .catch((error) => console.error(error));
  }

  sendSms(phone, email) {
    return this.fetch
      .post(`${URL_SERVER_AUTH}/api/OTP/Send?phone=${phone}&email=${email}`)
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  sendCode(phone, code) {
    return this.fetch
      .post(`${URL_SERVER_AUTH}/api/OTP/Validate/${code}?phone=${phone}`)
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  newPassword(phone, body) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("DeviceId", "WEB");

    return this.fetch
      .put(`${URL_SERVER_AUTH}/api/Password?phone=${phone}`, body, headers)
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  getPDF() {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    const fileName = "instrNewOschadPAY.pdf";

    return this.fetch
      .getBlob(`${URL_SERVER}/m/${fileName}`, headers)
      .then((blob) => download(blob, fileName, "application/pdf"))
      .catch((error) => console.error(error));
  }
}
