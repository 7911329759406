import React, { useMemo, useState } from "react";
import ReactTooltip from "react-tooltip";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

import { TooltipLabel } from "ui/TooltipLabel/tooltip-label.styles";
import Alert from "ui/Alert/Alert";

import { getUUID } from "utils/helpers/getUUID";

import {
  ReadButton,
  NotReadButton,
  DeleteButton,
  CheckAllButton,
  CancelButton,
} from "./styles";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as DeleteIcon } from "./delete.svg";
import { BiEnvelope, BiEnvelopeOpen } from "react-icons/bi";

const SelectSettings = (props) => {
  const {
    checkedAll,
    selectedLength,
    onCancel,
    onMarkRead,
    onMarkNotRead,
    onDelete,
    onCheckAll,
  } = props;

  const deleteId = useMemo(() => getUUID(), []);
  const checkAllId = useMemo(() => getUUID(), []);
  const markReadId = useMemo(() => getUUID(), []);
  const markNotReadId = useMemo(() => getUUID(), []);
  const cancelId = useMemo(() => getUUID(), []);
  const [alertState, setAlertState] = useState({
    opened: false,
    type: "done",
    title: "",
    subtitle: "",
    fnSubmit: () => {},
    btnCloseLabel: "",
  });

  const openConfirmModal = ({ title, subtitle, fnSubmit, btnCloseLabel }) => {
    setAlertState({
      ...alertState,
      opened: true,
      type: "remove",
      title,
      subtitle,
      fnSubmit,
      accessButtonLabel: "Так",
      cancelButtonLabel: "Ні",
    });
  };

  return (
    <>
      {alertState.opened && (
        <Alert
          type={alertState.type}
          title={alertState.title}
          subtitle={alertState.subtitle}
          fnClose={() => setAlertState({ ...alertState, opened: false })}
          fnSubmit={() => {
            setAlertState({ ...alertState, opened: false });
            alertState.fnSubmit();
          }}
          accessButtonLabel={alertState.accessButtonLabel}
          cancelButtonLabel={alertState.cancelButtonLabel}
        />
      )}
      <DeleteButton
        opened={true}
        disabled={selectedLength === 0}
        data-tip
        data-for={deleteId}
        onClick={() =>
          openConfirmModal({
            title: "Видалити повідомлення?",
            subtitle: `Ви впевнені, що бажаєте видалити ${selectedLength} обраних повідомлень?`,
            fnSubmit: onDelete,
            btnCloseLabel: "видалити",
          })
        }
      >
        <DeleteIcon />
      </DeleteButton>
      <ReactTooltip id={deleteId} place="top" effect="solid">
        <TooltipLabel>{"Видалити обрані повідомлення"}</TooltipLabel>
      </ReactTooltip>
      <ReadButton
        opened={true}
        disabled={selectedLength === 0}
        data-tip
        data-for={markReadId}
        onClick={() =>
          openConfirmModal({
            title: "Позначити повідомлення прочитаними?",
            subtitle: `Ви впевнені, що бажаєте позначити ${selectedLength} обраних повідомлень прочитаними?`,
            fnSubmit: onMarkRead,
            btnCloseLabel: "позначити прочитаними",
          })
        }
        style={{ fontSize: "1.5rem" }}
      >
        <BiEnvelopeOpen />
      </ReadButton>
      <ReactTooltip id={markReadId} place="top" effect="solid">
        <TooltipLabel>
          {"Позначити обрані повідомлення як прочитані"}
        </TooltipLabel>
      </ReactTooltip>
      <NotReadButton
        opened={true}
        disabled={selectedLength === 0}
        data-tip
        data-for={markNotReadId}
        onClick={() =>
          openConfirmModal({
            title: "Позначити повідомлення непрочитаними?",
            subtitle: `Ви впевнені, що бажаєте позначити ${selectedLength} обраних повідомлень непрочитаними?`,
            fnSubmit: onMarkNotRead,
            btnCloseLabel: "позначити непрочитаними",
          })
        }
        style={{ fontSize: "1.5rem" }}
      >
        <BiEnvelope />
      </NotReadButton>
      <ReactTooltip id={markNotReadId} place="top" effect="solid">
        <TooltipLabel>
          {"Позначити обрані повідомлення як непрочитані"}
        </TooltipLabel>
      </ReactTooltip>
      <CheckAllButton
        checkedAll={checkedAll}
        opened={true}
        data-tip
        data-for={checkAllId}
        disabled={checkedAll}
        onClick={() => onCheckAll(checkedAll)}
      >
        <IoCheckmarkDoneSharp />
      </CheckAllButton>
      <ReactTooltip id={checkAllId} place="top" effect="solid">
        <TooltipLabel>{"Обрати всі повідомлення"}</TooltipLabel>
      </ReactTooltip>
      <CancelButton
        opened={true}
        disabled={selectedLength === 0}
        data-tip
        data-for={cancelId}
        onClick={onCancel}
      >
        <CloseIcon />
      </CancelButton>
      <ReactTooltip id={cancelId} place="top" effect="solid">
        <TooltipLabel>{"Скасувати вибір"}</TooltipLabel>
      </ReactTooltip>
    </>
  );
};

export default SelectSettings;
