import styled from "styled-components";

export const KeyWindow = styled.section`
  position: absolute;
  z-index: 3;
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  right: 0;
  left: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  max-height: 90vh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .main-cashier-checkbox {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      width: 20px;
      height: 20px;
    }

    label.title {
      margin-left: 10px;
      margin-bottom: 0px;
      cursor: pointer;
    }
  }
`;

export const KeyOptions = styled.div`
  flex: 1 1 100%;
  overflow: auto;
`;

export const KeyContainer = styled.section`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
`;

export const SelectContainer = styled.div`
  position: relative;
  margin: 1rem 0;
`;

export const SelectLabel = styled.label`
  position: absolute;
  left: 1rem;
  top: -10px;
  z-index: 1;
  color: #70787f;
  font-family: "Futura Book", sans-serif;
  font-size: 14px;
`;
