import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { useHeader } from "store/header/header.context";
import ChangeTitle, {
  ChangeLeft,
  SetMobileBackButton,
} from "utils/helpers/changetitle.helpers.js";
import { URL_SERVER } from "utils/constants/server.constants";
import { TransactionDetail } from "api/index.js";
import { item } from "store/transaction_table/transaction_table.slicer.js";
import {
  setPageIndex,
  setServer,
  setLocal,
  fetchServer,
} from "store/transaction_table/transaction_table.actions.js";
import { setTransactionFilteringState } from "store/transaction_table/transaction_table.actions";
import { mobileVersion as rMobileVersion } from "store/main_table/main_table.slicer";

import Preloader from "components/additional/PreLoader/Preloader.js";
import BackIcon from "ui/BackIcon/BackIcon";
import {
  DivContainer,
  SectionInform,
  TransactionDetailsContainer,
  ButtonContainer,
  BodyTr,
  HeaderTr,
  Table,
  Total,
  ProductText,
  Title,
  SubTitle,
  DivContainerIcon,
  ProductContainer,
  DivContainerText,
  Li,
} from "./TransactionDetail.styles.js";
import {
  Value,
  Price,
  Sign,
} from "components/layouts/SubTotal/SubTotal.styles";
import { ButtonTransactionDetail } from "components/forms/Button/Button.styles.js";

import { ReactComponent as ProfileIcon } from "assets/icons/transaction/transaction_detail/profile.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/transaction/transaction_detail/phone.svg";
import { ReactComponent as TerminalIcon } from "assets/icons/transaction/transaction_detail/terminal.svg";
import useTransactionDetails from "./useTransactionDetails.js";
import { FISCAL_STATUS } from "../constants.js";
import useCancelTransaction from "./useCancelTransaction.js";
import { isQrTransaction } from "../helpers/generateTransactionStatus.js";
import {
  generateOperationType,
  generateFiscalPaymentOperationLabel,
} from "../helpers";
import { setGlobalLoading } from "store/main_table/main_table.actions.js";
import moment from "moment";

const generationStatus = (transaction, isQR) => {
  const {
    transaction_state: state,
    force_status: undetermined,
    status_transaction: status,
  } = transaction;

  if (isQR) {
    switch (status) {
      case 0:
        return "Очікування оплати";
      case 4:
        return "Не виконана";
      default:
    }
  }

  if (undetermined) {
    return "Потребує уточнення";
  }

  switch (state) {
    case 1:
      return "Транзакція успішна";
    case 2:
      return "Транзакція неуспішна";
    case 3:
      return "Транзакція скасована";
    default:
      return "";
  }
};

const getDate = (date, isISOFormatted) => {
  if (date) {
    if (isISOFormatted) {
      return moment(date).format("DD.MM.YYYY | HH:mm:ss");
    }

    const new_date = date.split(" ");
    const dates = new_date[0].replace(new RegExp("/", "g"), ".");

    const time = new_date[1];

    return `${dates} | ${time}`;
  }
};

const SERVER_FISCAL_STATUS = true;

export default function TransactionDetails() {
  const history = useHistory();
  const { dispatch } = useHeader();
  const reduxDispach = useDispatch();
  const mobileVersion = useSelector(rMobileVersion) < 780;
  const [state, setState] = useState({});
  const [goods, setGoods] = useState([]);
  const { id } = useParams();
  const isQR = isQrTransaction(state);
  const { copyLinkToClipboard, transactionDetails } = useTransactionDetails(
    state,
    isQR
  );
  const { cancelTransaction, isCancelled, isPending } =
    useCancelTransaction(state);

  useEffect(() => {
    if (!id) return;

    const server = new TransactionDetail();

    reduxDispach(setGlobalLoading(true));

    server
      .getInformation(id)
      .then((res) => {
        setState(res);
        ChangeTitle("Транзакція №" + res.transaction_id, dispatch);
        ChangeLeft(dispatch);
        setGoods(res.goods);
        SetMobileBackButton(true, dispatch);
      })
      .finally(() => reduxDispach(setGlobalLoading(false)));
  }, [dispatch, id]);

  const receipt = () => {
    const win = window.open(`${URL_SERVER}/R/${id}`, "_blank");
    win.focus();
  };

  const toRrnVal = (rrn) => {
    reduxDispach(setPageIndex(0));
    reduxDispach(setServer([]));
    reduxDispach(setLocal([]));
    reduxDispach(
      setTransactionFilteringState({ field: "rrn_search", value: rrn })
    );
    sessionStorage.removeItem("search_id");
    localStorage.removeItem("search_id");

    reduxDispach(
      fetchServer({ value: null, businessDay: null, fiscalDay: null })
    );
    history.push("/dash/transaction");
  };

  const fiscalStatus = useMemo(() => {
    if (SERVER_FISCAL_STATUS) {
      const { fiskal_status } = state;

      return {
        ...{ text: FISCAL_STATUS[fiskal_status] },
        ...{ color: fiskal_status === 3 ? "#DD3C3C" : undefined },
      };
    } else {
      const { fiskal_failed, transaction_state, fiskal_number, dfs_error } =
        state;

      if (!!fiskal_failed) {
        if (
          fiskal_number === null &&
          dfs_error === null &&
          transaction_state === 1
        ) {
          return { text: "Без фіскалізації" };
        }

        return { text: "Помилка фіскалізації", color: "#DD3C3C" };
      }
      if (
        fiskal_number &&
        fiskal_number !== "" &&
        Number(fiskal_number) !== 0
      ) {
        return { text: "Фіскалізована" };
      }

      return { text: "Не фіскалізована" };
    }
  }, [state]);

  const operationIsCash = useMemo(
    () =>
      state
        ? state.operation === "cash" ||
          state.operation === "cash_inkass" ||
          state.operation === "cash_podkrep" ||
          state.operation === "cash_refund"
        : false,
    [state]
  );

  const operationIsCashless = useMemo(
    () =>
      state
        ? state.operation === "cashless_refund" ||
          state.operation === "cashless"
        : // || state.operation === "qr" ||
          // state.operation === "qr_refund"
          false,
    [state]
  );

  const calculateTotalSumValues = (sum) => {
    if (!sum) {
      return {
        totalSumWhole: null,
        totalSumFloat: null,
      };
    }

    const splittedSum = sum.toString().split(".");
    let amountFloat = null;
    if (splittedSum[1] && splittedSum[1].length === 2) {
      amountFloat = splittedSum[1];
    } else if (splittedSum[1] && splittedSum[1].length === 1) {
      amountFloat = splittedSum[1] + "0";
    } else {
      amountFloat = "00";
    }

    return {
      totalSumWhole: Math.floor(sum).toFixed(0) + ".",
      totalSumFloat: amountFloat,
    };
  };

  const isDiscount = !!(
    state?.discountsum &&
    Number(state.discountsum) !== 0 &&
    Number(state.discounttype) !== 0
  );
  const totalDiscountString = isDiscount
    ? state.discounttype === 1
      ? `${state.discountsum} грн.`
      : state.discounttype === 2
      ? `${state.discountsum} %`
      : ""
    : "";

  const { totalSumWhole, totalSumFloat } = calculateTotalSumValues(
    state.amount
  );
  const { totalSumWhole: tipsSumWhole, totalSumFloat: tipsSumFloat } =
    calculateTotalSumValues(state.tips_amount);
  const { totalSumWhole: roundSumWhole, totalSumFloat: roundSumFloat } =
    calculateTotalSumValues(Math.abs(state.round_amount || 0));

  const operationType = generateOperationType(state);

  if (state.length === 0) return <Preloader />;
  return (
    <TransactionDetailsContainer>
      <SectionInform column={mobileVersion} mobile={mobileVersion}>
        <DivContainer>
          <DivContainerIcon>
            <ProfileIcon />
          </DivContainerIcon>
          <DivContainerText>
            <Title>{state.cashier_name}</Title>
            <SubTitle>{state.cashier_id}</SubTitle>
          </DivContainerText>
        </DivContainer>
        <DivContainer>
          <DivContainerIcon>
            <TerminalIcon />
          </DivContainerIcon>
          <DivContainerText>
            <Title>{state.ptdesctiption}</Title>
            {state.ptaddress && <SubTitle>{state.ptaddress}</SubTitle>}
            <SubTitle>{`terminal ID: ${state.ptname}`}</SubTitle>
          </DivContainerText>
        </DivContainer>
        <DivContainer>
          <DivContainerIcon>
            <PhoneIcon />
          </DivContainerIcon>
          <DivContainerText>
            <Title>{state.device_name}</Title>
            <SubTitle>ID: {state.deviceid}</SubTitle>
          </DivContainerText>
        </DivContainer>
      </SectionInform>

      <SectionInform
        style={{ alignItems: "flex-start", flexDirection: "column" }}
      >
        <div className="section-row">
          <DivContainer column>
            <Li>
              <Title bold noTextWrap>
                Метод оплати:{" "}
              </Title>
              <SubTitle>
                {generateFiscalPaymentOperationLabel(state.operation)}
              </SubTitle>
            </Li>
            {isQR && (
              <>
                <Li>
                  <Title bold noTextWrap>
                    Час створення оплати:{" "}
                  </Title>
                  <SubTitle noTextWrap>
                    {getDate(state.transaction_date, true)}
                  </SubTitle>
                </Li>
                <Li>
                  <Title bold noTextWrap>
                    Час транзакції:{" "}
                  </Title>
                  <SubTitle noTextWrap>{getDate(state.utime)}</SubTitle>
                </Li>
              </>
            )}
            {!isQR && (
              <Li>
                <Title bold noTextWrap>
                  Час транзакції:{" "}
                </Title>
                <SubTitle noTextWrap>{getDate(state.ctime)}</SubTitle>
              </Li>
            )}
            {operationType && (
              <Li>
                <Title bold noTextWrap>
                  Тип операції:{" "}
                </Title>
                <SubTitle>{operationType}</SubTitle>
              </Li>
            )}
            <Li>
              {operationIsCash && <Title bold>Готівка</Title>}
              {operationIsCashless && <Title bold>Безготівково</Title>}
              {!operationIsCash &&
                !operationIsCashless &&
                state.payment_system && (
                  <>
                    <Title bold>{state.payment_system + ":" || "-"}</Title>
                    <SubTitle>
                      {state.pan ? " " + state.pan : "Відсутня"}
                    </SubTitle>
                  </>
                )}
            </Li>
            <Li style={{ marginTop: "10px" }}>
              <Title bold noTextWrap>
                Статус фіскалізації:{" "}
              </Title>
              <SubTitle
                style={
                  fiscalStatus.color ? { color: fiscalStatus.color } : undefined
                }
                noTextWrap
              >
                {fiscalStatus.text}
              </SubTitle>
            </Li>
          </DivContainer>
          <DivContainer column>
            <Li>
              <Title bold noTextWrap>
                Код авторизації:{" "}
              </Title>
              <SubTitle> {state.auth_code ?? "Відсутній"}</SubTitle>
            </Li>
            <Li>
              <Title bold noTextWrap>
                Номер чека:{" "}
              </Title>
              <SubTitle>{state.trxid ?? "Відсутній"}</SubTitle>
            </Li>
            <Li>
              <Title bold>RRN: </Title>
              {state.rrn !== null ? (
                <SubTitle
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => toRrnVal(state.rrn)}
                >
                  {state.rrn}
                </SubTitle>
              ) : (
                <SubTitle>Відсутній</SubTitle>
              )}
            </Li>
            {transactionDetails && (
              <Li style={{ display: "block" }}>{transactionDetails}</Li>
            )}
          </DivContainer>
          <DivContainer column>
            <Li>
              <Title bold>Статус: </Title>
              <SubTitle
                style={
                  state.force_status === 1
                    ? { color: "#8F5c65" }
                    : state.transaction_state === 2
                    ? { color: "#DD3C3C" }
                    : null
                }
              >
                {" "}
                {generationStatus(state, isQR)}
              </SubTitle>
            </Li>
            <Li>
              <Title bold noTextWrap>
                Бізнес-день:{" "}
              </Title>
              <SubTitle>
                {state.ctime
                  ? state.ctime.slice(0, 10).replace(new RegExp("/", "g"), ".")
                  : "Відсутній"}
              </SubTitle>
            </Li>
            <Li>
              <Title bold noTextWrap>
                Номер закриття:{" "}
              </Title>
              <SubTitle>2</SubTitle>
            </Li>
          </DivContainer>
        </div>
        <Li
          style={{
            marginTop: "10px",
            alignItems: "flex-start",
          }}
        >
          <Title bold noTextWrap>
            Призначення платежу:{" "}
          </Title>
          <SubTitle style={{ wordBreak: "break-all", marginTop: "4px" }}>
            {state.payment_comment ?? "-"}
          </SubTitle>
        </Li>
      </SectionInform>

      <ProductContainer>
        <ProductText>
          <div
            style={{
              width: "320px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {isDiscount && (
              <Total>
                Заcтосована знижка:
                <Value
                  style={{
                    marginLeft: "10px",
                    fontFamily: "'Futura Book', sans-serif",
                    fontSize: "18px",
                    marginTop: "2px",
                  }}
                >
                  {totalDiscountString}
                </Value>
              </Total>
            )}
            {!!state.tips_amount && +state.tips_amount !== 0 && (
              <Total>
                Обслуговування:
                <Value style={{ marginLeft: "10px" }}>
                  {tipsSumWhole}
                  <Price>{tipsSumFloat + " ₴"}</Price>
                </Value>
              </Total>
            )}
            {Boolean(state?.round_amount) && (
              <Total>
                Застосовано заокруглення:
                <Value style={{ marginLeft: "10px" }}>
                  <Sign>{`${state.round_amount > 0 ? "-" : "+"}`}</Sign>
                  {roundSumWhole}
                  <Price>{roundSumFloat + " ₴"}</Price>
                </Value>
              </Total>
            )}
            <Total>
              Загальна сума:
              <Value style={{ marginLeft: "10px" }}>
                {totalSumWhole}
                <Price>{totalSumFloat + " ₴"}</Price>
              </Value>
            </Total>
          </div>
          {goods ? (
            <Table>
              <thead>
                <HeaderTr>
                  <th>Назва товару</th>
                  <th>Ціна</th>
                </HeaderTr>
              </thead>
              <tbody>
                {goods.map((good, key) => {
                  return (
                    <BodyTr key={key}>
                      <td>
                        <div>
                          <p>{good.goods_name}</p>
                          {good.exciselabel && good.exciselabel !== "" && (
                            <p className="excise-label">
                              Акцизна марка {good.exciselabel}
                            </p>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>
                          <p>Оплата</p>
                          {!!good.discount && +good.discount !== 0 && (
                            <p className="discount-label">Знижка</p>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>
                          <p>
                            {(Math.round(good.price * 100) / 100).toFixed(2)}₴ х{" "}
                            {good.amount}
                          </p>
                        </div>
                        <div style={{ marginLeft: "5px" }}>
                          <p>
                            {(
                              Math.round(good.amount * good.price * 100) / 100
                            ).toFixed(2)}
                            ₴
                          </p>
                          {!!good.discount && +good.discount !== 0 && (
                            <p className="discount-label">
                              - {good.discount} ₴
                            </p>
                          )}
                        </div>
                      </td>
                    </BodyTr>
                  );
                })}
              </tbody>
            </Table>
          ) : null}
        </ProductText>
        <ButtonContainer fiskal={item.fiskal_id !== null}>
          {isPending ? (
            <ButtonTransactionDetail onClick={copyLinkToClipboard}>
              Отримати лінк на оплату
            </ButtonTransactionDetail>
          ) : (
            <ButtonTransactionDetail onClick={receipt}>
              Чек терміналу
            </ButtonTransactionDetail>
          )}
          {isQR && isPending && !isCancelled && (
            <ButtonTransactionDetail onClick={cancelTransaction}>
              Скасувати оплату
            </ButtonTransactionDetail>
          )}
          {/* {item.fiskal_id ? (
            <ButtonTransactionDetail
              onClick={() => {}}
              style={{ marginTop: "20px" }}
            >
              Фіскальний чек
            </ButtonTransactionDetail>
          ) : null} */}
        </ButtonContainer>
      </ProductContainer>
      <BackIcon className="default" />
    </TransactionDetailsContainer>
  );
}
