import React, { useState } from "react";

import { TextField } from "components/forms";
import { CreateCategoryContainer } from "../CategoriesTable/styles";
import { SelectingButton } from "../GoodsTypeSwitcher/styles";

const CreateCategory = (props) => {
  const { onAddNewCategory, transitionState } = props;
  const [categoryForm, setCategoryForm] = useState({
    name: {
      value: "",
      caption: "Додати категорію",
      placeholder: "Назва категорії",
      formatFunc: (oldValue, newValue) => {
        const regex1 = /^.{1,200}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        return newValue;
      },
    },
  });

  const setNewInputValueToForm = (field, newValue) => {
    setCategoryForm((categoryForm) => ({
      ...categoryForm,
      [field]: {
        ...categoryForm[field],
        value: categoryForm[field].formatFunc(
          categoryForm[field].value,
          newValue
        ),
      },
    }));
  };

  return (
    <CreateCategoryContainer className={transitionState}>
      <TextField
        name={categoryForm.name.caption}
        placeholder={categoryForm.name.placeholder}
        value={categoryForm.name.value}
        fn={(val) => setNewInputValueToForm("name", val)}
        className="input-active"
      />
      <SelectingButton
        onClick={() => {
          onAddNewCategory({ name: categoryForm.name.value, parentId: null });
          setCategoryForm({
            name: {
              ...categoryForm.name,
              value: "",
            },
          });
        }}
        disabled={!categoryForm.name.value || categoryForm.name.value === ""}
        selected
      >
        Додати
      </SelectingButton>
    </CreateCategoryContainer>
  );
};

export default CreateCategory;
