import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setAlertState, dropAlertState } from "store/alert/alert_actions";
import { MainTable } from "api";

const useQR = (enableQR, terminalId) => {
  const [isEnabledQR, setIsEnabledQR] = useState(Boolean(enableQR));
  const [terminals, setTerminals] = useState(null);
  const [allTerminals, setAllTerminals] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const dispatch = useDispatch();

  const displayMessage = useCallback(
    (message) => {
      dispatch(
        setAlertState({
          show: true,
          type: "block",
          title: "Помилка",
          subtitle: message,
          fnClose: () => dispatch(dropAlertState()),
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    setIsEnabledQR(Boolean(enableQR));
  }, [enableQR]);

  useEffect(() => {
    if (isEnabledQR && !terminals) {
      new MainTable()
        .getECommTerminals()
        .then((result) => {
          if (!result) return;

          const { ErrorCode, ErrorMessage, terminals } = result;

          if (ErrorCode === 0) {
            setTerminals((terminals || []).filter((t) => t.active));
            setAllTerminals(terminals || []);

            if (terminalId) {
              const terminal = terminals.find(
                ({ value }) => value === terminalId
              );

              if (terminal) {
                setSelectedTerminal(terminal);
              }
            }
          } else {
            if (ErrorMessage) {
              displayMessage(ErrorMessage);
            }
          }
        })
        .catch((message) => {
          displayMessage(message);
        });
    }
  }, [isEnabledQR, terminalId, terminals, displayMessage]);

  return {
    isEnabledQR,
    setIsEnabledQR,
    eCommTerminals: terminals,
    allECommTerminals: allTerminals,
    selectedTerminal,
    setSelectedTerminal,
    errorMessage,
    setErrorMessage,
  };
};

export default useQR;
