import styled from "styled-components";

export const AlertGridComponent = styled.ul`
  list-style: none;
  max-height: 6.5rem;
  padding-right: 0.5rem;
  font-family: "Futura Book", sans-serif;
  color: #70787f;
  overflow: auto;
`;

export const AlertGridRow = styled.li`
  display: grid;
`;

export const AlertGridCell = styled.span`
  white-space: nowrap;
`;
