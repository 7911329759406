import {
  SubTotalContainer,
  SubTotalItem,
  Title,
  Value,
  Price,
  TextContainer,
} from "./SubTotal.styles";

import {
  TRANSACTION_TYPE,
  CARD_TYPE,
  RESULT_TYPE,
  CASH_TYPE,
  PAYMENT_TYPE,
  RETURN_TYPE,
  CASHLESS_TYPE,
  REVERSED_TYPE,
} from "utils/constants/subtotal.constants";

import {
  TransactionIcon,
  CardIcon,
  CashIcon,
  ReturnIcon,
  ResutIcon,
  PaymentIcon,
  ReversedIcon,
} from "./SubTotal.icons";

export default function SubTotal({ state }) {
  const iconOrTitle = (bool, icon, title) => (bool ? icon : title);

  function renderIconOrTitle(type, showIcon) {
    switch (type) {
      case TRANSACTION_TYPE:
        return iconOrTitle(showIcon, <TransactionIcon />, "Транзакцій");

      case CARD_TYPE:
        return iconOrTitle(showIcon, <CardIcon />, "Карткою");

      case CASH_TYPE:
        return iconOrTitle(showIcon, <CashIcon />, "Готівкою");

      case RETURN_TYPE:
        return iconOrTitle(showIcon, <ReturnIcon />, "Повернення");

      case RESULT_TYPE:
        return iconOrTitle(showIcon, <ResutIcon />, "Підсумок");

      case PAYMENT_TYPE:
        return iconOrTitle(showIcon, <PaymentIcon />, "Оплата");

      case CASHLESS_TYPE:
        return iconOrTitle(showIcon, <CardIcon />, "Безготівкові");

      case REVERSED_TYPE:
        return iconOrTitle(showIcon, <ReversedIcon />, "Скасовані");

      default:
        throw new Error("this type in subtotal not exist!");
    }
  }

  function renderValue(value, type) {
    const stringValue = Number(isNaN(value) ? 0 : value).toFixed(2);

    const valueAfterDot = (val) => {
      if (val) {
        return val.toString().length === 1 ? "." + val + "0" : "." + val;
      }
      return ".00";
    };

    if (type === RESULT_TYPE) {
      const val = stringValue.split(".");

      return (
        <Value>
          {+val[0] > 0 ? `+${val[0]}` : val[0]}
          <Price>{valueAfterDot(val[1])}₴</Price>
        </Value>
      );
    }

    if (type !== TRANSACTION_TYPE) {
      let val = stringValue.split(".");

      return (
        <Value>
          {val[0]}
          <Price>{valueAfterDot(val[1])}₴</Price>
        </Value>
      );
    }

    if (type === TRANSACTION_TYPE) {
      return <Value>{value}</Value>;
    }
  }

  return (
    <SubTotalContainer>
      {state.map((item, key) => {
        return (
          <SubTotalItem key={key}>
            {renderIconOrTitle(item.type, true)}
            <TextContainer>
              <Title>{renderIconOrTitle(item.type, false)}</Title>
              {renderValue(item.value, item.type)}
            </TextContainer>
          </SubTotalItem>
        );
      })}
    </SubTotalContainer>
  );
}
