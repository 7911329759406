import React from "react";
import { VscWarning } from "react-icons/vsc";

const FirstLoginRemainder = () => {
  return (
    <article>
      <h3>При першому логіні в кабінет</h3>
      <p>
        Ваш логін - це e-mail, який вказано в договорі на еквайрингове
        обслуговування. Перед першим логіном натисніть кнопку "Забули пароль" та
        створіть пароль за процедурою відновлення
      </p>
      <p style={{ float: "clear" }}>
        <VscWarning
          size={22}
          style={{
            display: "inline-block",
            marginRight: "0.5rem",
            float: "left",
          }}
        />
        Пароль в кабінет та пароль в мобільний застосунок - це різні паролі!
      </p>
    </article>
  );
};

export default FirstLoginRemainder;
