import styled from "styled-components";
import { grey, mouse_pointer, white, red } from "assets/style/variables";
import { font_book } from "assets/style/fonts.variables";

export const CommonSelectContainer = styled.div`
  position: relative;

  .select-icon {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
  }
`;

export const Select = styled.input`
  font-family: ${font_book};
  font-weight: 400;
  font-size: 20px;
  padding: 10px;
  background: white;
  border-radius: 12px;
  border: 1px solid ${(props) => (props.err ? red : "rgba(112,120,127,0.2)")};
  outline: none;
  width: 170px;
  position: relative;
  color: ${grey};

  ${(props) => (!props.disableAction ? `opacity: 0.5;` : `  ${mouse_pointer}`)}

  &.value {
    color: #000;
  }
`;

export const SelectContainer = styled.ul`
  ${(props) => (props.show ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 55px;
  width: 100%;
  left: 0;
  z-index: 2;
  overflow-y: scroll;
  max-height: ${(props) =>
    props.maxHeight ? `${props.maxHeight}px` : "180px"};
  flex-direction: column;
  background: ${white};
  padding-bottom: 10px;
  -webkit-box-shadow: 2px 2px 6px -1px rgba(28, 44, 56, 0.54);
  -moz-box-shadow: 2px 2px 6px -1px rgba(28, 44, 56, 0.54);
  box-shadow: 2px 2px 6px -1px rgba(28, 44, 56, 0.54);

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
`;

export const SelectItem = styled.li`
  list-style-type: none;
  padding: 10px;
  font-size: 20px;
  font-family: "Futura Book", sans-serif;

  ${mouse_pointer}
  &:nth-child(odd) {
    background-color: whitesmoke;
  }

  &:hover {
    background: #c4f7ee;
  }
`;

export const SelectVal = styled.p`
  position: relative;
  .select-icon {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;

export const SelectTitle = styled(SelectVal)`
  position: absolute;
  top: 3px;
  left: 10px;
  background-color: transparent;
  color: ${(props) => (props.err ? red : grey)};
  font-size: 14px;
  font-family: "Futura Book", sans-serif;
  padding-left: 5px;
  padding-right: 10px;
`;
