import { font_book } from "assets/style/fonts.variables";
import { black, white } from "assets/style/variables";
import styled from "styled-components";

export const QRSettingsPanel = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;

  .errorMessage {
    display: none;
  }

  input[type="text"] {
    background-color: ${white};
  }

  img {
    display: inline-block;
    margin-top: 1rem;
    color: ${black};
    font-family: ${font_book};
  }
`;
