import React from 'react';
import { useLocation } from 'react-router-dom';

import FiscalOpenedDetail from './FiscalOpenedDetail/FiscalOpenedDetail';
import FiscalClosedDetail from './FiscalClosedDetail/FiscalClosedDetail';

const FiscalDetail = () => {
  const location = useLocation();

  const isItemOpened = location?.state?.status === 'opened';

  return (
    <>
      {isItemOpened === true && <FiscalOpenedDetail />}
      {isItemOpened === false && <FiscalClosedDetail />}
    </>
  );
};

export default FiscalDetail;
