export const generateFiscalPaymentOperationLabel = (operation) =>
  ({
    cash_inkass: "Службова видача",
    cash_podkrep: "Службове внесення",
    cash: "Готівка",
    cash_refund: "Готівка",
    contactless: "Карткою",
    cancel: "Відміна",
    RRN: "Карткою",
    cashless: "Безготівково",
    cashless_refund: "Безготівково",
    qr: "QR-оплата",
    qr_refund: "QR-оплата",
  }[operation] || "");
