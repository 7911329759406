import React, { useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { TerminalDetailHeaderItem } from "pages/Panels/Terminals/TerminalDetail/TerminalDetail.styles";
import { useHeader } from "store/header/header.context";
import ChangeTitle, {
  ChangeActive,
  ChangeLeft,
  Refresh,
  SetMobileBackButton,
} from "utils/helpers/changetitle.helpers";
import {
  CashButtonContainer,
  CashiersDetailContainer,
  CashiersDetailHeader,
  CashInformationButton,
  CashInformationItem,
  CashInformationSection,
  KeyInformationSection,
  CashierSettingPanel as SettingPanel,
} from "./CashiersDetail.styles";
import { ReactComponent as BanIcon } from "assets/icons/terminalDetail/ban.svg";
import { ReactComponent as CloseIcon } from "assets/icons/user_close.svg";
import { ReactComponent as PPOIcon } from "assets/icons/ppo.svg";
import { ReactComponent as FailedIcon } from "assets/icons/login_failed.svg";
import { ReactComponent as ActivateIcon } from "assets/icons/activate.svg";
import { ErrorText, SettingPanelContainer } from "assets/style/variables";
import { TextField, TextFieldPassw } from "components/forms";
import {
  Button,
  ButtonSecondaryBorder,
} from "components/forms/Button/Button.styles";
import TextFieldImage from "components/forms/TextField/TextFieldImage";
import { CashierTable, Profile, FiskalTable } from "api";
import { item } from "store/cashier_table/cashier_table.slicer";
import { CASHIERS_ROUTE } from "utils/constants/routes.constants";
import { MAIN_CASHIER } from "utils/constants/cashier.constants";
import Alert from "ui/Alert/Alert";
import AlertStack from "ui/Alert/AlertStack";
import {
  fetchServer,
  loadStateServerTerminal,
  setResponse,
  clearKey,
} from "store/cashier_table/cashier_table.actions";
import { validateDisablePRRO } from "api/global-requests";
import Table from "pages/Panels/Terminals/TerminalDetail/Table/TerminalTable";
import BackIcon from "ui/BackIcon/BackIcon";
import { KeyModal } from "../KeyModal/KeyModal";
import { setAlertState, dropAlertState } from "store/alert/alert_actions";
import Preloader from "components/additional/PreLoader/Preloader";
import { setGlobalLoading } from "store/main_table/main_table.actions";

export default function CashiersDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { fiskal, global_fiskal_enabled, key_expiry_date } = useSelector(
    (store) => store.user.profile
  );
  const data = useSelector(item);

  const isMainCachier = data?.role === MAIN_CASHIER;
  const isFiscal = fiskal && global_fiskal_enabled;

  const [keyIsExpired, setKeyIsExpired] = useState(
    isMainCachier && key_expiry_date
      ? moment() > moment(key_expiry_date)
      : false
  );

  const headerDispatch = useHeader().dispatch;

  const [isEdit, setIsEdit] = useState(data === null);

  const [isEditPassw, setIsEditPassw] = useState(false);
  const [image, setImage] = useState();
  const [imageLocal, setImageLocal] = useState(null);
  const [block, setBlock] = useState(false);
  const [isChiefCashier, setIsChiefCashier] = useState(false);
  const [isPanelLoading, setIsPanelLoading] = useState(false);
  const [isKeyInfoMessageShown, setIsKeyInfoMessageShown] = useState(false);

  const returnData = (val, key) =>
    val === null
      ? ""
      : val[key].slice(0, 1) === "+"
      ? val[key].slice(1)
      : val[key];

  const [login, setLogin] = useState(returnData(data, "app_login"));
  const [name, setName] = useState(returnData(data, "user_name"));
  const [oldPassw, setOldPassw] = useState("");
  const [passw, setPassw] = useState("");
  const [rpassw, setRPassw] = useState("");
  const [phone, setPhone] = useState("+" + returnData(data, "phone"));
  const [isShowKey, setIsShowKey] = useState(false);
  const [isReloadingKey, setIsReloadingKey] = useState(false);
  const [isPassType, setIsPassType] = useState(false);
  const [keyText, setKeyText] = useState("Ключ не завантажено");
  const [keyId, setKeyId] = useState(null);
  const [key_id, set_key_id] = useState(null);
  const [keyDate, setKeyDate] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [alertData, setAlertData] = useState({
    show: false,
    title: null,
    subTitle: null,
    type: null,
    subTitleClassName: null,
    submitFunction: () => {},
  });

  const [loading, setLoading] = useState(false);

  const [loginErr, setLoginErr] = useState({
    isError: false,
    msg: "",
  });
  const [nameErr, setNameErr] = useState({
    isError: false,
    msg: "",
  });
  const [passwErr, setPasswErr] = useState({
    isError: false,
    msg: "",
  });
  const [passwOldErr, setOldPasswErr] = useState({
    isError: false,
    msg: "",
  });
  const [rpasswErr, setRPasswErr] = useState({
    isError: false,
    msg: "",
  });
  const [phoneErr, setPhoneErr] = useState({
    isError: false,
    msg: "",
  });

  const cashierApi = new CashierTable();

  useEffect(() => {
    if (!data) {
      return history.push(CASHIERS_ROUTE);
    }

    ChangeTitle("Касир " + data.user_name, headerDispatch);
    ChangeLeft(headerDispatch);
    ChangeActive(
      data.status === "VALIDATED" || data.status === "ACTIVE",
      headerDispatch
    );

    setName(data.user_name);
    setImage(data.url);
    setImageLocal(data.url);

    if (isRefreshing) {
      setIsRefreshing(false);
    } else {
      handleGetKey(true);
    }

    SetMobileBackButton(true, headerDispatch);
  }, [headerDispatch, data]);

  const hideAlert = () => {
    setAlertData({
      show: false,
      title: null,
      subTitle: null,
      type: null,
      subTitleClassName: null,
      submitFunction: () => {},
    });
  };

  function handleGetKey(showProgress) {
    if (showProgress) setIsPanelLoading(true);

    cashierApi
      .getKey(data.client_id)
      .then(async (res) => {
        if (res?.errorCode === 0) {
          setIsChiefCashier(res.chiefCashier);

          if (isMainCachier) {
            setKeyText("Ключ касира завантажено");
            const profile = new Profile();
            const {
              data: { key_expiry_date },
            } = await profile.init();

            if (key_expiry_date) {
              setKeyIsExpired(
                isMainCachier ? moment() > moment(key_expiry_date) : false
              );
            }
          } else {
            setKeyText("Ключ касира завантажено");
            const expirationDate = res.validTo;
            const isDateExpired = moment() > moment(expirationDate);

            setKeyIsExpired(isDateExpired);
          }
          setKeyId(res.subjectKeyIdentifier);
          set_key_id(res.keyId);
          setKeyDate(moment(res.validTo).format("DD.MM.YYYY HH:mm"));
        }
      })
      .finally(() => {
        if (showProgress) setIsPanelLoading(false);
      });
  }

  function validateForm(fn) {
    let err = false;
    const fields = [
      {
        val: login,
        error: loginErr,
        setErr: setLoginErr,
      },
      {
        val: name,
        error: nameErr,
        setErr: setNameErr,
      },

      {
        val: phone,
        error: phoneErr,
        setErr: setPhoneErr,
      },
    ];

    fields.forEach((el) => {
      if (el.val.length === 0) {
        el.setErr({ isError: true, msg: "Заповніть поле!" });
        err = true;
      } else {
        el.setErr({ isError: false, msg: "" });
      }
    });

    if (err) return;

    if (!err) {
      fn();
    }
  }

  function validateFormPassw(fn) {
    let err = false;
    const fields = [
      {
        val: passw,
        error: passwErr,
        setErr: setPasswErr,
      },
      {
        val: rpassw,
        error: rpasswErr,
        setErr: setRPasswErr,
      },
    ];

    if (isMainCachier) {
      fields.push({
        val: oldPassw,
        error: passwOldErr,
        setErr: setOldPasswErr,
      });
    }
    // check fields empty
    fields.forEach((el) => {
      if (el.val.length === 0) {
        el.setErr({ isError: true, msg: "Заповніть поле!" });
        err = true;
      } else {
        el.setErr({ isError: false, msg: "" });
      }
    });

    if (err) return;

    // check passw empty

    if (passw.length < 8) {
      setPasswErr({ isError: true, msg: "Пароль має бути більше 8 символів!" });
      err = true;
    } else {
      setPasswErr({ isError: false, msg: "" });
    }
    if (err) return;

    if (passw !== rpassw) {
      setRPasswErr({ isError: true, msg: "Паролі не співпадають!" });
      setPasswErr({ isError: true, msg: "Паролі не співпадають!" });
      err = true;
    } else {
      setRPasswErr({ isError: false, msg: "" });
      setPasswErr({ isError: false, msg: "" });
    }

    if (!err) fn();
  }

  function refreshDataGoToPage() {
    dispatch(loadStateServerTerminal());
    dispatch(fetchServer(data));

    if (data.role === MAIN_CASHIER) Refresh(headerDispatch);
  }

  function addPhoto() {
    if (imageLocal && isEdit) {
      const profile = new Profile();
      return profile.sendPhoto(imageLocal, login).then(() => {
        setImageLocal(imageLocal);
        setIsEdit(false);
        setAlertData({
          ...alertData,
          type: "done",
          title: "Касира відредаговано",
          subTitle: null,
          subTitleClassName: null,
          show: true,
          width: null,
          height: null,
        });
        return true;
      });
    }

    setAlertData({
      ...alertData,
      type: "done",
      title: "Касира відредаговано",
      subTitle: null,
      subTitleClassName: null,
      show: true,
      width: null,
      height: null,
    });

    return true;
  }

  function handlePhone(val) {
    if (val.length === 0 || val.length === 4) return setPhone("+380");
    if (val.length === 22) return;
    const reg = new RegExp("^[0-9+]+$", "g");
    if (!val.match(reg)) return;

    if (val.length === 1) return setPhone("+380" + val);
    if (val.length >= 1) return setPhone("+380" + val.slice(4));
  }

  function editFalse() {
    setIsEdit(false);
    setPhone(data.phone);
    setName(data.user_name);
    setLogin(data.app_login);
    setImageLocal(image);
  }

  function editFalsePassw() {
    setIsEditPassw(false);
    setPassw("");
    setRPassw("");
    setPasswErr({ isError: false, msg: "" });
    setRPasswErr({ isError: false, msg: "" });
  }

  function editTrue() {
    setIsRefreshing(true);
    dispatch(setGlobalLoading(true));

    cashierApi
      .edit({
        client_id: data.client_id,
        app_login: login,
        app_pass: passw,
        user_name: name,
        phone,
      })
      .then(() => {
        addPhoto();
      })
      .then(() => {
        dispatch(setResponse([]));
        refreshDataGoToPage();
      })
      .finally(() => dispatch(setGlobalLoading(false)));
  }

  function resetPassw() {
    const body = {
      client_id: data.client_id,
      app_login: login,
      app_pass: passw,
      user_name: name,
      phone,
    };

    if (isMainCachier) {
      body.current_pass = oldPassw;
    }
    if (isPassType) {
      body.pass_type = "WEB";
    } else {
      body.pass_type = "APP";
    }

    cashierApi.edit(body).then((res) => {
      if (res?.ErrorCode === 0) {
        editFalsePassw(false);
        setAlertData({
          ...alertData,
          type: "done",
          title: "Пароль змінено",
          subTitle: null,
          show: true,
          subTitleClassName: null,
          width: null,
          height: null,
        });
      } else {
        setAlertData({
          ...alertData,
          type: "block",
          title: "Помилка",
          subTitle: res.ErrorMessage,
          show: true,
          subTitleClassName: null,
          width: null,
          height: null,
        });
      }
    });
  }

  function removeUser() {
    setAlertData({
      ...alertData,
      type: "remove",
      title: "Ви впевнені, що бажаєте видалити касира?",
      subTitle: "Він не зможе більше використовувати ОщадPAY",
      show: true,
      submitFunction: () => {
        hideAlert();
        removeUserSubmit();
      },
      subTitleClassName: null,
      width: null,
      height: null,
    });
    setBlock(false);
  }

  function blockAcceptUser() {
    setAlertData({
      ...alertData,
      type: "remove",
      title: "Ви впевнені, що бажаєте заблокувати касира?",
      subTitle: "Він не зможе більше використовувати ОщадPAY",
      show: true,
      submitFunction: blockUser,
      subTitleClassName: null,
      width: null,
      height: null,
    });
    setBlock(true);
  }

  function blockUser() {
    dispatch(setResponse([]));
    setAlertData({
      ...alertData,
      type: "block",
      title: `Касира ${name} заблоковано`,
      subTitle: "Касир більше не має доступу до онлайн-кабінету ОщадPay",
      show: true,
      submitFunction: () => {},
      subTitleClassName: null,
      width: null,
      height: null,
    });
    cashierApi.block(login).then(() => {
      refreshDataGoToPage();
    });
  }

  function activateUser() {
    dispatch(setResponse([]));
    setAlertData({
      ...alertData,
      type: "done",
      title: `Касира ${name} розблоковано`,
      subTitle: "Касир має доступ до онлайн-кабінету ОщадPay",
      show: true,
      submitFunction: () => {},
      subTitleClassName: null,
      width: null,
      height: null,
    });
    cashierApi.activate(login).then(() => {
      refreshDataGoToPage();
    });
  }

  async function removeUserSubmit() {
    dispatch(setResponse([]));
    dispatch(setGlobalLoading(true));

    const res = await cashierApi.remove(login);

    dispatch(setGlobalLoading(false));

    if (res?.ErrorCode === 0) {
      refreshDataGoToPage();
      history.goBack();
      dispatch(
        setAlertState({
          type: "done",
          title: "Касира видалено",
          subTitle: null,
          show: true,
          fnClose: () => dispatch(dropAlertState()),
        })
      );
    } else {
      setAlertData({
        ...alertData,
        type: "block",
        title: `Касира ${name} не вдається видалити`,
        subTitle: res.ErrorMessage,
        show: true,
        submitFunction: () => {},
        subTitleClassName: null,
        width: null,
        height: null,
      });
    }
  }

  function handleName(val, fn) {
    if (val.length === 0) return fn(val);
    const reg = new RegExp(
      "^[A-Za-z0-9АБВГҐДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЮЬЯабвгґдеєжзиіїйклмнопрстуфхцчшщьюя=' -]+$",
      "g"
    );
    if (!val.match(reg)) return;
    fn(val);
  }

  function handlePasswLogin(val, fn) {
    if (val.length === 0) return fn(val);
    if (val.length === 101) return;
    // const reg = new RegExp("^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,}$");
    const reg = new RegExp("^[A-Za-z0-9._%+-]+@?[A-Za-z0-9.-]*\\.?[A-Za-z]*$");
    if (!val.match(reg)) return;
    fn(val);
  }

  async function trySubmitKey(keyData, showLoader) {
    try {
      const res = await new FiskalTable().getFiscalsDayDetail();

      // setLoading(false);

      if (res?.data?.errorCode === 0) {
        const openShift = (res?.data?.fiskalDetails || []).filter(
          ({ shiftClosed, openClientId }) =>
            !Boolean(shiftClosed) && openClientId === data.client_id
        )[0];

        if (openShift && !keyData.isMainCashier) {
          const { rroId, terminalId } = openShift;

          setLoading(false);
          setAlertData({
            type: "block",
            title: "Увага!",
            subTitle: `Для касира існує відкрита фіскальна зміна на ПРРО ${rroId} (термінал ${terminalId}). Для можливості зміни ключа необхідно спершу закрити цю фіскальну зміну. Зміну може закрити або цей касир або касир з типом підпису “Старший касир”.`,
            show: true,
            submitFunction: () => {},
            subTitleClassName: null,
            subtitleStyle: { textAlign: "justify" },
            width: null,
            height: "500px",
          });
        } else {
          submitKey(keyData, showLoader);
        }
      } else {
        setLoading(false);
        setAlertData({
          type: "block",
          title: "Помилка!",
          subTitle:
            res?.data?.errorMessage ||
            "Помилка визначення присутності відкритих змін.",
          show: true,
          submitFunction: () => {},
          subTitleClassName: null,
          width: null,
          height: "500px",
        });
      }
    } catch (error) {
      setLoading(false);
      setAlertData({
        type: "block",
        title: "Помилка!",
        subTitle: error.message,
        show: true,
        submitFunction: () => {},
        subTitleClassName: null,
        width: null,
        height: "500px",
      });
    }
  }

  function submitKey(
    {
      keyFile,
      signatureFile,
      encryptionFile,
      keyPassword,
      isMainCashier,
      keyRole,
      keyFileName,
      signatureFileName,
      encryptionFileName,
    },
    showLoader
  ) {
    const body = {
      key: keyFile,
      password: keyPassword,
      client_id: data.client_id,
      key_role: keyRole,
      chiefCashier: isMainCashier,
      key_file: keyFileName,
    };

    if (signatureFile) {
      body.cert1 = signatureFile;
      body.cert1_file = signatureFileName;
    }

    if (encryptionFile) {
      body.cert2 = encryptionFile;
      body.cert2_file = encryptionFileName;
    }

    if (showLoader) dispatch(setGlobalLoading(true));

    cashierApi
      .getRRO(data.client_id)
      .then(() => {
        cashierApi
          .postAddKey(body)
          .then((result) => {
            const res = result.data;

            if (res.error_code === 0 && res.key_uniq) {
              setAlertData({
                ...alertData,
                type: "done",
                title: "Ключ завантажено!",
                subTitle: null,
                show: true,
                submitFunction: () => {},
                subTitleClassName: null,
                width: null,
                height: null,
              });
              handleGetKey();
            } else if (res.error_code === 0 && !res.key_uniq) {
              setAlertData({
                ...alertData,
                type: "done",
                title: "Ключ завантажено!",
                subTitle:
                  "Увага! Цей ключ раніше був завантажений для іншого касира. Якщо це не ключ-печатка, одночасне відкриття фіскальних змін на різних ФН ПРРО неможливе.",
                show: true,
                submitFunction: () => {},
                subTitleClassName: "warning",
                width: null,
                height: null,
              });
              handleGetKey();
            } else {
              setAlertData({
                ...alertData,
                type: "block",
                title: "Помилка!",
                subTitle: res.message,
                show: true,
                submitFunction: () => {},
                subTitleClassName: null,
                width: null,
                height: "450px",
              });
            }
            setIsShowKey(false);
          })
          .catch((error) => {
            setAlertData({
              ...alertData,
              type: "block",
              title: "Виникла серверна помилка",
              subTitle: `status: ${error?.response?.status} \n
                        title: ${error?.message} \n
                        data: ${error?.response?.data}`,
              show: true,
              submitFunction: () => {},
              subTitleClassName: null,
              width: null,
              height: null,
            });
          })
          .finally(() => {
            if (showLoader) dispatch(setGlobalLoading(false));

            setLoading(false);
          });
      })
      .catch(() => {
        if (showLoader) dispatch(setGlobalLoading(false));

        setLoading(false);
      });
  }

  async function validateKey({
    keyFile,
    signatureFile,
    encryptionFile,
    keyPassword,
    isMainCashier,
    keyRole,
    keyFileName,
    signatureFileName,
    encryptionFileName,
  }) {
    setLoading(true);

    if (!keyIsExpired) {
      // check if user doesnt have active fiskal day
      const checkResult = await validateDisablePRRO({
        app_login: login,
      });

      if (!checkResult.success) {
        if (isReloadingKey) {
          setAlertData({
            ...alertData,
            type: "remove",
            title: checkResult.titleMessage,
            subTitle: checkResult.subtitleMessage,
            show: true,
            cancelButtonLabel: "Скасувати",
            acceptButtonLabel: "Примусово завантажити",
            submitFunction: () => {
              setAlertData({ ...alertData, show: false });
              trySubmitKey(
                {
                  keyFile,
                  signatureFile,
                  encryptionFile,
                  keyPassword,
                  isMainCashier,
                  keyRole,
                  keyFileName,
                  signatureFileName,
                  encryptionFileName,
                },
                true
              );
            },
            subTitleClassName: null,
            width: "600px",
            height: null,
          });
        } else {
          setAlertData({
            ...alertData,
            type: "block",
            title: checkResult.titleMessage,
            subTitle: checkResult.subtitleMessage,
            show: true,
            submitFunction: () => {},
            subTitleClassName: null,
          });
        }

        setIsShowKey(false);

        return;
      }
    }

    trySubmitKey({
      keyFile,
      signatureFile,
      encryptionFile,
      keyPassword,
      isMainCashier,
      keyRole,
      keyFileName,
      signatureFileName,
      encryptionFileName,
    });
  }

  async function deleteKey() {
    // check if user doesnt have active fiskal day
    const checkResult = await validateDisablePRRO({ app_login: login });

    if (!checkResult.success) {
      setAlertData({
        ...alertData,
        type: "block",
        title: checkResult.titleMessage,
        subTitle: checkResult.subtitleMessage,
        show: true,
        submitFunction: () => {},
        subTitleClassName: null,
        width: null,
        height: null,
      });

      return;
    }

    try {
      await cashierApi.deleteKey({ key_id: key_id });
      dispatch(clearKey(data.client_id));
      setAlertData({
        ...alertData,
        type: "done",
        title: "Ключ успішно видалено!",
        subTitle: null,
        show: true,
        submitFunction: () => {},
        subTitleClassName: null,
        width: null,
        height: null,
      });
      setKeyId(null);
      set_key_id(null);
      setKeyDate(null);
      setKeyText("Ключ не завантажено");
      setKeyIsExpired(false);
    } catch (error) {
      setAlertData({
        ...alertData,
        type: "block",
        title: "Виникла серверна помилка",
        subTitle: null,
        show: true,
        submitFunction: () => setAlertData({ ...alertData, show: false }),
        subTitleClassName: null,
        width: null,
        height: null,
      });
    } finally {
      setLoading(false);
    }
  }

  function prepareDeleteKey() {
    setAlertData({
      ...alertData,
      type: "remove",
      title: "Видалити ключ касира?",
      subTitle: null,
      show: true,
      submitFunction: deleteKey,
      subTitleClassName: null,
      width: null,
      height: null,
    });
  }

  // const onChangeChiefCashierSwitch = async (isChief) => {
  //   const {
  //     status,
  //     data: { errorCode },
  //   } = await cashierApi.setChiefCashier({
  //     keyId: key_id,
  //     isChief,
  //   });

  //   if (status === 200 && errorCode === 0) {
  //     setIsChiefCashier(isChief);
  //     handleGetKey();
  //   }
  // };

  if (!data) {
    return null;
  }

  const handleUpload = () => {
    setIsKeyInfoMessageShown(true);
  };

  return isPanelLoading ? (
    <Preloader />
  ) : (
    <CashiersDetailContainer>
      {isKeyInfoMessageShown && (
        <AlertStack
          {...{
            type: "remove",
            title: "Зверніть увагу!",
            subtitles: [
              "Ключ, який має термін початку роботи в майбутні дати, завантажити неможливо! Необхідно дочекатись початку роботи ключа і тоді виконати завантаження.",

              "Файли сертифікатів завантажувати не обов'язково.  Сертифікати будуть автоматично імпортовані з АЦСК. Але у разі недоступності АЦСК, ви можете вказати і файли сертифікатів.",
            ],
            fnClose: () => {
              setIsKeyInfoMessageShown(false);
              setIsReloadingKey(keyId && !keyIsExpired);
              setIsShowKey(true);
            },
            cancelButtonLabel: "ЗРОЗУМІЛО",
            height: "600px",
          }}
        />
      )}

      {isShowKey && (
        <KeyModal
          onClose={() => setIsShowKey(false)}
          modalData={{
            loading,
            isOnMainCashierPosition: isMainCachier,
          }}
          onUpload={validateKey}
        />
      )}

      {alertData.show && (
        <Alert
          type={alertData.type}
          title={alertData.title}
          subtitle={alertData.subTitle}
          subtitleClassName={alertData.subTitleClassName}
          subtitleStyle={alertData.subtitleStyle}
          fnClose={() => {
            setLoading(false);
            setAlertData({ ...alertData, show: false });
          }}
          fnSubmit={alertData.submitFunction}
          btn_close={block ? "заблокувати" : "видалити"}
          acceptButtonLabel={alertData.acceptButtonLabel}
          cancelButtonLabel={alertData.cancelButtonLabel}
          width={alertData.width}
          height={alertData.height}
        />
      )}

      {!isMainCachier && (
        <CashiersDetailHeader className="header">
          <TerminalDetailHeaderItem
            onClick={removeUser}
            className="button_bg-white"
          >
            <CloseIcon /> Видалити касира
          </TerminalDetailHeaderItem>

          {data.status === "SUSPENDED" ? (
            <TerminalDetailHeaderItem
              onClick={activateUser}
              className="button_bg-white"
            >
              <ActivateIcon /> Розблокувати касира
            </TerminalDetailHeaderItem>
          ) : (
            <TerminalDetailHeaderItem
              onClick={blockAcceptUser}
              className="button_bg-white"
            >
              <BanIcon /> Заблокувати касира
            </TerminalDetailHeaderItem>
          )}
        </CashiersDetailHeader>
      )}

      <SettingPanel>
        <SettingPanelContainer
          disable={!isEdit}
          className="cashier-setting-container"
        >
          {!isMainCachier && (
            <>
              <TextField
                name="Логін"
                placeholder="name@mail.com"
                value={login}
                fn={(val) => handlePasswLogin(val, setLogin)}
                className="input-active"
                error={loginErr.isError}
                disabled={!isEdit}
              />
              <ErrorText className="error_text">{loginErr.msg}</ErrorText>
            </>
          )}

          <TextField
            name="Ім’я"
            placeholder="Надайте ім’я"
            value={name}
            fn={(val) => handleName(val, setName)}
            className="input-active"
            error={nameErr.isError}
            disabled={!isEdit}
          />
          <ErrorText className="error_text">{nameErr.msg}</ErrorText>

          {!isMainCachier && (
            <>
              <TextField
                name="Мобільний телефон"
                placeholder={"+380"}
                value={phone}
                fn={handlePhone}
                className="input-active"
                error={phoneErr.isError}
                disabled={!isEdit}
                fnFocus={() => {
                  handlePhone(phone);
                }}
              />
              <ErrorText className="error_text">{phoneErr.msg}</ErrorText>
            </>
          )}

          {isEditPassw ? (
            <SettingPanelContainer
              style={{ padding: "0" }}
              disable={!isEditPassw}
            >
              {isMainCachier ? (
                <>
                  <TextFieldPassw
                    name="Поточний пароль"
                    placeholder="Введіть поточний пароль"
                    value={oldPassw}
                    fn={setOldPassw}
                    className="input-active"
                    error={passwOldErr.isError}
                    disabled={!isEditPassw}
                    showStar={!isEditPassw}
                  />
                  <ErrorText className="error_text">
                    {passwOldErr.msg}
                  </ErrorText>
                </>
              ) : null}
              <TextFieldPassw
                name="Новий пароль"
                placeholder="Введіть новий пароль"
                value={passw}
                fn={setPassw}
                className="input-active"
                error={passwErr.isError}
                disabled={!isEditPassw}
                showStar={!isEditPassw}
              />
              <ErrorText className="error_text">{passwErr.msg}</ErrorText>
              {isEditPassw && (
                <TextFieldPassw
                  name="Повторіть новий пароль"
                  placeholder="Повторіть новий пароль"
                  value={rpassw}
                  fn={setRPassw}
                  className="input-active"
                  error={rpasswErr.isError}
                  disabled={!isEditPassw}
                />
              )}
              <ErrorText className="error_text">{rpasswErr.msg}</ErrorText>
              {isMainCachier ? (
                <CashInformationItem>
                  <p className="sub_title" style={{ marginLeft: "10px" }}>
                    Ви змінюєте пароль головного касира для входу до
                    {isPassType ? " WEB порталу" : " мобільного додатку"}
                  </p>
                </CashInformationItem>
              ) : null}
            </SettingPanelContainer>
          ) : null}

          <TextFieldImage
            fn={(val) => setImageLocal(val)}
            isEdit={isEdit}
            imgURL={imageLocal}
            avatar_uploaded={data ? data.avatar_uploaded : false}
            className="image-field"
          />
        </SettingPanelContainer>
        <SettingPanelContainer className="cashier-setting-container">
          <CashInformationSection>
            <CashInformationItem>
              <div>
                <FailedIcon />
              </div>
              <div>
                <p className="title">Невдала авторизація</p>
                <p className="sub_title">{data.logon_fails} рази</p>
              </div>
            </CashInformationItem>
          </CashInformationSection>
          {isFiscal ? (
            <>
              <KeyInformationSection>
                <CashInformationItem>
                  <div>
                    <PPOIcon />
                  </div>
                  <div>
                    <p className="title">Ключ</p>
                    {!keyIsExpired && (
                      <>
                        <p className="sub_title">{keyText}</p>
                        <p
                          className="sub_title"
                          style={{
                            wordBreak: "break-word",
                            paddingRight: "10px",
                          }}
                        >
                          {keyId
                            ? "Ідентифікатор ключа суб’єкта: " + keyId
                            : null}
                        </p>
                        <p className="sub_title">
                          {keyDate ? " Діє до: " + keyDate : null}
                        </p>
                      </>
                    )}
                    {keyIsExpired && (
                      <>
                        <p
                          className="sub_title"
                          style={{
                            wordBreak: "break-word",
                            paddingRight: "10px",
                          }}
                        >
                          {keyId && "Ідентифікатор ключа суб’єкта: " + keyId}
                        </p>
                        <p className="sub_title" style={{ color: "red" }}>
                          Термін дії сертифіката завершився:{" "}
                          {moment(key_expiry_date).format("DD.MM.YYYY")}
                        </p>
                      </>
                    )}
                    {keyId && (
                      <p
                        className="sub_title"
                        style={{
                          wordBreak: "break-word",
                          paddingRight: "10px",
                        }}
                      >
                        {`Тип підпису: ${
                          isChiefCashier ? "Старший касир" : "Касир"
                        }`}
                      </p>
                    )}
                  </div>
                </CashInformationItem>
                <div className="key-actions">
                  {!keyId && (
                    <CashInformationButton
                      style={{ width: "120px" }}
                      onClick={() => {
                        handleUpload();
                      }}
                      disabled={loading}
                    >
                      Завантажити ключ
                    </CashInformationButton>
                  )}
                  {keyId && !keyIsExpired && (
                    <>
                      <CashInformationButton
                        style={{
                          width: "160px",
                          maxWidth: "160px",
                          border: "1px solid #9c0a00",
                          backgroundColor: "#ffe5e3",
                        }}
                        onClick={() => prepareDeleteKey()}
                        disabled={loading}
                      >
                        Видалити ключ
                      </CashInformationButton>
                      <CashInformationButton
                        style={{ width: "160px", maxWidth: "160px" }}
                        onClick={() => {
                          handleUpload();
                        }}
                        disabled={loading}
                      >
                        Завантажити ключ
                      </CashInformationButton>
                    </>
                  )}
                  {keyId && keyIsExpired && (
                    <>
                      <CashInformationButton
                        style={{ width: "160px", maxWidth: "160px" }}
                        onClick={() => {
                          handleUpload();
                        }}
                        disabled={loading}
                      >
                        Завантажити новий ключ
                      </CashInformationButton>
                      <CashInformationButton
                        style={{
                          width: "160px",
                          maxWidth: "160px",
                          border: "1px solid #9c0a00",
                          backgroundColor: "#ffe5e3",
                        }}
                        onClick={() => prepareDeleteKey()}
                        disabled={loading}
                      >
                        Видалити ключ
                      </CashInformationButton>
                    </>
                  )}
                </div>
              </KeyInformationSection>
            </>
          ) : null}
        </SettingPanelContainer>
      </SettingPanel>
      <CashButtonContainer>
        {isEdit ? (
          <>
            <Button onClick={() => validateForm(editTrue)}>
              Підтвердити зміни
            </Button>
            <ButtonSecondaryBorder onClick={editFalse}>
              Скасувати
            </ButtonSecondaryBorder>
          </>
        ) : (
          <>
            {data.status !== "SUSPENDED" && (
              <Button
                onClick={() => {
                  setIsEdit(true);
                }}
                disabled={loading}
              >
                Редагувати
              </Button>
            )}
            {!isEditPassw ? (
              <>
                <Button
                  onClick={() => {
                    setIsEditPassw(true);
                    setIsPassType(false);
                    setPassw("");
                  }}
                  disabled={loading}
                >
                  Встановити пароль (додаток)
                </Button>
                {isMainCachier && (
                  <Button
                    onClick={() => {
                      setIsEditPassw(true);
                      setIsPassType(true);
                      setPassw("");
                    }}
                    disabled={loading}
                  >
                    Встановити пароль (WEB)
                  </Button>
                )}
              </>
            ) : (
              <>
                <Button onClick={() => validateFormPassw(resetPassw)}>
                  Підтвердити зміни
                </Button>
                <ButtonSecondaryBorder onClick={editFalsePassw}>
                  Скасувати
                </ButtonSecondaryBorder>
              </>
            )}
          </>
        )}
      </CashButtonContainer>

      {data && (
        <Table title="Останні 20 логінів у термінали" login={data.app_login} />
      )}
      <BackIcon className="default" />
    </CashiersDetailContainer>
  );
}
