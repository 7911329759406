import React, { useState } from "react";

import { ModalSubTitle } from "ui/Modal/modal.styles";
import {
  DisabledFiscalizationModalContainer,
  Title,
  AcceptButtonContainer,
  CheckboxContainer,
  ContainedButton,
} from "./styles";

const DisabledFiscalizationModal = ({ onClose }) => {
  const [notShowAgain, setNotShowAgain] = useState(false);

  const handleClose = () => {
    if (notShowAgain) {
      localStorage.setItem(
        "disabled-fiscalization-modal-state",
        JSON.stringify({ date: Date.now() })
      );
    }
    onClose();
  };

  return (
    <DisabledFiscalizationModalContainer>
      <Title>Зверніть увагу!</Title>
      <ModalSubTitle style={{ textAlign: "center" }}>
        З 11.05 фіскалізація в ОщадPAY буде призупинена через заплановані
        технічні роботи, решта функцій у застосунку працюють у звичному режимі.
        Про запуск оновленого функціоналу фіскалізації буде повідомлено
        додатково. Детальніше:{" "}
        <a href="https://bit.ly/3vN8K2I" target="_blank" rel="noreferrer">
          https://bit.ly/3vN8K2I
        </a>
      </ModalSubTitle>
      <AcceptButtonContainer>
        <ContainedButton onClick={handleClose}>ЗРОЗУМІЛО</ContainedButton>
      </AcceptButtonContainer>
      <CheckboxContainer>
        <input
          id="decline_checkbox"
          type="checkbox"
          onChange={() => setNotShowAgain((v) => !v)}
          checked={notShowAgain}
        />
        <label htmlFor="decline_checkbox">
          більше не показувати це повідомлення
        </label>
      </CheckboxContainer>
    </DisabledFiscalizationModalContainer>
  );
};

export default DisabledFiscalizationModal;
