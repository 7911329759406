import styled from 'styled-components';

import { Button, ButtonSecondaryBorder } from 'components/forms/Button/Button.styles';

export const OpenFiscalModalContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  iframe {
    width: 100%;
    border: 2px solid #d9d9d9;
    flex-grow: 2;
    border-radius: 3px;
    overflow: auto;
  }
`;

export const CheckboxRow = styled.div`
  width: calc(100% - 20px);
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;

  input {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
  }

  span {
    font-family: 'Futura Book', sans-serif;
  }
`;

export const ContainedButton = styled(Button)`
  width: auto;
  padding: 12px 15px;
  font-size: 16px;
  background: #13877e;
  transition: background 0.2s ease, border-radius 0.2s ease;
  cursor: pointer;

  &:hover {
    background: #0c615a;
  }
`;

export const OutlinedButton = styled(ButtonSecondaryBorder)`
  width: auto;
  padding: 12px 15px;
  font-size: 16px;
  background: transparent;
  transition: background 0.2s ease, border-radius 0.2s ease;
  cursor: pointer;

  &:hover {
    background: #eee;
  }
`;
