import styled from "styled-components";

import { font_book } from "assets/style/fonts.variables";

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: ${({ direction = "row" }) => direction};
  gap: 3rem;
  margin: 1rem auto 2rem;
  font-family: ${font_book};
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: ${({ labelPosition }) =>
    labelPosition === "right" ? "row-reverse" : "row"};
  cursor: pointer;
`;

export const RadioTitle = styled.span`
  font-size: 1rem;
  font-weight: 400;
  opacity: 1 !important;
`;

export const RadioContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 24px;
  height: 24px;
  outline: ${({ disabled, color, disabledColor }) =>
    `1px solid ${disabled ? disabledColor : color}`};
  border: 4px solid #fff;
  background: ${({ checked, disabled, disabledColor, color }) =>
    checked ? (disabled ? disabledColor : color) : "transparent"};
  opacity: ${({ disabled }) => (disabled ? "0.8" : "1")};
  border-radius: 50%;
  transition: all 0.25s;
`;

export const Radio = styled.input.attrs({ type: "radio" })`
  width: 0;
  height: 0;
`;
