import { TRAN_REPORT_TYPE } from "utils/constants/ui";
import { UI_LAST_TRAN_REPORT_TYPE } from "./ui.types";

const initialState = {
  ui_last_tran_report_type: TRAN_REPORT_TYPE.BASE,
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case UI_LAST_TRAN_REPORT_TYPE:
      return { ...state, ui_last_tran_report_type: action.payload };
    default:
      return state;
  }
}

export const selectLastTranReportType = (state) =>
  state.ui.ui_last_tran_report_type;
