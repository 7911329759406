export const btn = [
  {
    id: 0,
    text: 'Відкриті бізнес-дні',
    active: true,
  },
  {
    id: 1,
    text: 'Закриті бізнес-дні',
    active: false,
  },
];
