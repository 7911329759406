import React, { useState, useEffect, useRef } from "react";

// functions
import { getUUID } from "utils/helpers/getUUID";

// assets
import { ReactComponent as CloseIcon } from "assets/icons/close_white.svg";

// styles
import {
  Th,
  TableIcon,
  TableInput,
  TableResult,
  TableResultLi,
  TableForm,
  TableFormResult,
  TableIconClose,
} from "../../Table.styles";

const PADDING_FROM_WINDOW_BORDER = 30;
const INITIAL_PADDING_LEFT = 20;

const TableHeaderSelector = (props) => {
  const { title, state, fn, fn_reset, icon, choose_state = null } = props;
  const mainRef = useRef(getUUID());

  const [val, setVal] = useState(choose_state === null ? "" : choose_state);
  const [active, setActive] = useState(
    choose_state !== null && choose_state !== ""
  );
  const [activeResult, setActiveResult] = useState(false);

  useEffect(() => {
    if (choose_state === null || choose_state === "") {
      setVal("");
      setActive(false);
    }
  }, [choose_state]);

  // sizes
  const [maxHeight, setMaxHeight] = useState(0);

  function search() {
    setActiveResult(true);
  }

  function chooseItem(item) {
    setActive(true);
    setVal(item.name);
    fn(item.id);
    setActiveResult(false);
  }

  function close(e) {
    e.stopPropagation();
    fn_reset();
    setActive(false);
    setVal("");
  }

  useEffect(() => {
    if (activeResult && mainRef && mainRef.current) {
      const htmlPosition = mainRef.current.getBoundingClientRect();
      setMaxHeight(
        Math.floor(
          window.innerHeight -
            htmlPosition.top -
            htmlPosition.height -
            PADDING_FROM_WINDOW_BORDER
        )
      );
    }
  }, [activeResult]);

  return (
    <Th
      active={active}
      onClick={search}
      ref={mainRef}
      onMouseLeave={() => {
        if (activeResult) {
          setActiveResult(false);
        }
      }}
    >
      <TableIcon>
        <img src={icon} alt="icon" />
      </TableIcon>
      <div>
        <TableInput
          padding={icon === null}
          placeholder={title}
          value={val}
          style={{ background: "white", cursor: "pointer" }}
          disabled={true}
        />
      </div>
      <TableForm active={active}>
        <TableFormResult
          style={{
            maxWidth: "calc(100% - 10px)",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {val}
        </TableFormResult>
        <TableIconClose onClick={close}>
          <CloseIcon />
        </TableIconClose>
      </TableForm>
      <TableResult
        active={activeResult}
        onMouseEnter={() => setActiveResult(true)}
        onMouseLeave={() => setActiveResult(false)}
        maxHeight={maxHeight}
        style={{ width: "calc(100% + 150px)" }}
      >
        {state.map((item, key) => (
          <TableResultLi
            style={{
              padding: "5px 10px",
              paddingLeft: `${
                item.deepIndex !== 0
                  ? item.deepIndex * INITIAL_PADDING_LEFT + 5
                  : 5
              }px`,
              textAlign: "left",
            }}
            onClick={(e) => {
              e.stopPropagation();
              chooseItem(item);
            }}
            key={key}
            data-val={item?.id}
          >
            {item.name}
          </TableResultLi>
        ))}
      </TableResult>
    </Th>
  );
};

export default TableHeaderSelector;
