const AUTH_ROUTE = "/";
const DASHBOARD_ROUTE = "/dash";
const TRANSACTION_ROUTE = DASHBOARD_ROUTE + "/transaction";
const TRANSACTION_DETAILS_ROUTE = DASHBOARD_ROUTE + "/transaction/:id";
const BANK_DAY_ROUTE = DASHBOARD_ROUTE + "/bank-day";
const BANK_DAY_ROUTE_CLOSED = BANK_DAY_ROUTE + "/closed";
const BANK_DAY_ROUTE_DETAIL = BANK_DAY_ROUTE + "/:id";
const FISCAL_DAY_ROUTE = DASHBOARD_ROUTE + "/fiscal-day";
const FISCAL_DAY_ROUTE_CLOSED = FISCAL_DAY_ROUTE + "/closed-day";
const FISCAL_DAY_ROUTE_DETAIL = FISCAL_DAY_ROUTE + "/:id";
const CASH_DAY_ROUTE = DASHBOARD_ROUTE + "/cash-operation";
const TERMINALS_ROUTE = DASHBOARD_ROUTE + "/terminals";
const TERMINALS_ROUTE_DETAIL = TERMINALS_ROUTE + "/:id";
const CASHIERS_ROUTE = DASHBOARD_ROUTE + "/cashiers";
const ADD_CASHIER_ROUTE = CASHIERS_ROUTE + "/add";
const CASHIERS_DETAIL_ROUTE = CASHIERS_ROUTE + "/:id";
const GOODS_ROUTE = DASHBOARD_ROUTE + "/goods";
const GOODS_ROUTE_DETAIL = GOODS_ROUTE + "/:id";
const NEWS_ROUTE = DASHBOARD_ROUTE + "/news";
const NEWS_ROUTE_DETAIL = NEWS_ROUTE + "/:id";
const STATS_ROUTE = DASHBOARD_ROUTE + "/statistics";

const SETTING_FOP_ROUTE = DASHBOARD_ROUTE + "/setting-fop";
const SUPPORT_ROUTE = DASHBOARD_ROUTE + "/support";
const QUESTION_ROUTE = DASHBOARD_ROUTE + "/questions";

const VIEW_Z_RECIEPT_ROUTE = "/view-z-reciept/";

export {
  GOODS_ROUTE_DETAIL,
  GOODS_ROUTE,
  AUTH_ROUTE,
  DASHBOARD_ROUTE,
  TRANSACTION_ROUTE,
  TRANSACTION_DETAILS_ROUTE,
  BANK_DAY_ROUTE,
  BANK_DAY_ROUTE_CLOSED,
  FISCAL_DAY_ROUTE_CLOSED,
  FISCAL_DAY_ROUTE_DETAIL,
  BANK_DAY_ROUTE_DETAIL,
  FISCAL_DAY_ROUTE,
  TERMINALS_ROUTE,
  TERMINALS_ROUTE_DETAIL,
  CASHIERS_ROUTE,
  ADD_CASHIER_ROUTE,
  CASHIERS_DETAIL_ROUTE,
  SETTING_FOP_ROUTE,
  SUPPORT_ROUTE,
  QUESTION_ROUTE,
  CASH_DAY_ROUTE,
  VIEW_Z_RECIEPT_ROUTE,
  NEWS_ROUTE,
  NEWS_ROUTE_DETAIL,
  STATS_ROUTE,
};
