import axios from "axios";

import LocalStorage from "services/LocalStorage/LocalStorage.service";
import Fetch from "services/Fetch/Fetch.service";
import { URL_SERVER_AUTH } from "utils/constants/server.constants";

export class Profile {
  constructor() {
    this.fetch = new Fetch();
    this.localStorage = new LocalStorage();
  }

  init() {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    return axios.get(`${URL_SERVER_AUTH}/api/UserCurrent`, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });
  }

  async sendPhoto(val, login) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    var formdata = new FormData();
    formdata.append("files", val);

    return this.fetch
      .postImage(`${URL_SERVER_AUTH}/api/Photo/${login}`, formdata, headers)
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  getUserShops({ merchant_id }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    return axios.post(
      `${URL_SERVER_AUTH}/api/Producst/CheckAddShop/${merchant_id}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Token: token,
          DeviceId: "WEB_" + device,
        },
      }
    );
  }
}
