import {
  gradient_green,
  oshad_green,
  black,
  grey,
  tiffany_light,
  button_shadow,
  white,
  grey_light,
  grey_off,
} from "assets/style/variables";
import { size } from "assets/style/global-variables";
import styled from "styled-components";

import { font_demi, font_book } from "assets/style/fonts.variables";

const Button = styled.button`
  color: white;
  outline: none;
  border: 0;
  width: 100%;
  border-radius: 12px;
  background: ${gradient_green};
  text-align: center;
  padding: 15px 0;
  font-family: ${font_demi};
  font-size: 16px;
  transition: all 0.5s ease;
  cursor: ${(props) => (props.disabled ? "not-allowed !important" : "pointer")};
  ${(props) =>
    props.disabled ? `background: #eee !important; color: #aaa;` : null};

  @media screen and (min-width: ${size.laptop}) {
    font-size: 20px;
  }
`;

const ButtonSecondary = styled(Button)`
  background: white;
  color: ${black};
`;

const ButtonSecondaryBorder = styled(ButtonSecondary)`
  color: #231f20;
  font-family: ${font_book};
  font-weight: 400;
  font-size: 14px;
  border: 1px solid rgba(112, 120, 127, 0.2);
`;

const SubmitSecondaryBorderButton = styled(ButtonSecondary)`
  border: 1px solid rgba(112, 120, 127, 0.2);
  font-family: ${font_book};
  font-weight: bold;
`;

const SettingButton = styled(ButtonSecondary)`
  font-size: 14px;
  font-family: ${font_book};
  font-weight: 400;
  background: ${white};
  border: 1px solid rgba(112, 120, 127, 0.2);
  border-radius: 10px;
  padding: 8px 30px;
  max-width: 125px;
`;

const ButtonLogin = styled(Button)`
  background: ${(props) => (props.inactive ? grey : gradient_green)};
  &:focus {
    background: ${(props) => (props.inactive ? grey : tiffany_light)};
  }
`;

const ButtonGreen = styled(Button)`
  box-shadow: ${button_shadow};
  font-size: 14px;
  font-family: ${font_book};
  color: ${(props) => (props.active ? white : black)};
  background: ${(props) => (props.active ? gradient_green : white)};
  max-width: 114px;
  padding: 14px;
  width: auto;
  box-sizing: content-box;
  ${(props) =>
    props.active ? "svg{filter: brightness(0) invert(1);}" : "null"}
  &:hover {
    background: ${gradient_green};
    color: ${white};
  }

  &:disabled {
    color: ${grey_off};
  }
`;

const ButtonTransactionDetail = styled(Button)`
  font-family: ${font_book};
  font-size: 14px;
  padding: 8px 0;
  font-weight: normal;
  background: ${(props) => (props.inactive ? grey : gradient_green)};
`;

const TransparentButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  outline: none;
  font-family: ${font_book};
  font-size: 14px;
  cursor: pointer;
  transition: color 0.2s;

  &:disabled {
    cursor: default;
  }

  &:not(:disabled):hover {
    color: ${oshad_green};
  }
`;

const TransparentStackButton = styled(TransparentButton)`
  flex-direction: column;
`;
const OutlinedButton = styled(Button)`
  color: ${oshad_green};
  background: ${white};
  box-shadow: inset 0 0 0 1px ${oshad_green};
`;

export {
  ButtonSecondaryBorder,
  Button,
  ButtonSecondary,
  ButtonLogin,
  ButtonGreen,
  ButtonTransactionDetail,
  OutlinedButton,
  SettingButton,
  TransparentButton,
  TransparentStackButton,
  SubmitSecondaryBorderButton,
};
