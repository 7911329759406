import styled from "styled-components";

import { black, grey, mouse_pointer } from "assets/style/variables";
import { font_book, font_medium } from "assets/style/fonts.variables";
import { TerminalDescribe } from "components/layouts/Table/Table.styles";

const TransactionContainer = styled.section`
  margin: 0;
  .flex {
    display: flex;
    grid-gap: 20px;
  }
  .link {
    text-decoration: none;
    ${mouse_pointer};
  }
  .loading {
    width: 300px;
    height: 300px;
  }
`;

const TransactionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .searchbox {
    margin-bottom: 0;
  }
`;

const TrMain = styled.tr`
  display: grid;
  grid-template-columns: 1fr 1.4fr 3fr 2fr 2fr 2.1fr 2fr ${(props) =>
      props.bday || props.fday ? null : "1.2fr"};
  grid-gap: 6px;
  margin: 12px 0;
  text-decoration: none;

  ${(props) =>
    props.text
      ? `
      grid-template-columns: 0.4fr ​1.4fr 1fr 1fr 1fr 1fr;
      th {
    text-align: center;
    color: ${grey};
    font-family: ${font_book};
    font-size: 14px;
    font-weight: 400;
  }`
      : null}

  ${(props) =>
    props.column
      ? `
      padding: 17px;
      align-items: center;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      justify-items: center;`
      : "null"}
`;
const Terminal = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 22px;
`;

const TerminalTitle = styled.p`
  color: ${black};
  font-family: ${font_medium};
`;

const TransactionDate = styled.p`
  color: ${grey};
  font-family: ${font_book};
  font-size: 14px;
  margin: 15px 5px;
  font-weight: 400;
`;

const TrTransactionDate = styled.tr`
  color: ${grey};
  font-family: ${font_book};
  font-size: 14px;
  font-weight: 400;

  td {
    padding: 24px 5px 0;
  }
`;

const TransactionTerminalDescribe = styled(TerminalDescribe)`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
`;

export {
  Terminal,
  TransactionDate,
  TrTransactionDate,
  TerminalTitle,
  TrMain,
  TransactionContainer,
  TransactionHeader,
  TransactionTerminalDescribe,
};
