import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useHeader } from "store/header/header.context";
import moment from "moment";

import ChangeTitle, {
  ChangeLeft,
  ChangeSubtitle,
  SetMobileBackButton,
} from "utils/helpers/changetitle.helpers";
import {
  DivContainer,
  SectionInform,
  Title,
  SubTitle,
  DivContainerIcon,
  DivContainerText,
} from "../../../Transaction/TransactionDetail/TransactionDetail.styles";

import XReciept from "components/additional/X-Reciept/X-Reciept";
import { ReactComponent as TerminalIcon } from "assets/icons/transaction/transaction_detail/terminal.svg";
import { ReactComponent as CashierIcon } from "assets/icons/ppo.svg";

import SubTotal from "components/layouts/SubTotal/SubTotal";
import BackIcon from "ui/BackIcon/BackIcon";
import { BankDetailContainer, FiscalActions } from "../FiscalDetail.styles";

import {
  bOpenTotal,
  bankDetailItem,
} from "store/fiscal_table/fiscal_table.slicer";
import { useSelector } from "react-redux";
import Transaction from "pages/Panels/Transaction/Transaction.panel";

const FiscalOpenedDetail = () => {
  const { dispatch } = useHeader();
  const history = useHistory();
  const total = useSelector(bOpenTotal);
  const item = useSelector(bankDetailItem);

  useEffect(() => {
    if (!item || Object.keys(item).length === 0) {
      return history.push("/dash/fiscal-day");
    }
  }, []);

  const dateString = `дата відкриття: ${
    item?.shiftOpened
      ? moment(item?.shiftOpened).format("HH:mm DD.MM.YYYY")
      : "-"
  }`;

  total[0].value = item?.totalCount;
  total[1].value = item?.totalAmountSaccess;
  total[2].value = item?.totalAmountRefaund;
  total[3].value = item?.totalAmount;

  useEffect(() => {
    let date;
    ChangeTitle("Фіскальна зміна", dispatch);
    ChangeLeft(dispatch);
    SetMobileBackButton(true, dispatch);
    if (item?.shiftClosed) {
      date = moment(item?.shiftClosed).format("DD.MM.YYYY");
    }

    ChangeSubtitle(date, dispatch);
  }, [dispatch]);

  return (
    <BankDetailContainer>
      <SectionInform>
        <DivContainer className="width-50">
          <DivContainerIcon>
            <TerminalIcon />
          </DivContainerIcon>
          <DivContainerText>
            <Title>{item?.terminalDescr}</Title>
            <SubTitle>Terminal ID: {item?.terminalId}</SubTitle>
          </DivContainerText>
        </DivContainer>
        <DivContainer className="width-50">
          <DivContainerIcon>
            <CashierIcon />
          </DivContainerIcon>
          <DivContainerText>
            <Title>{item?.cashier}</Title>
            <SubTitle style={{ whiteSpace: "pre-line" }}>{dateString}</SubTitle>
          </DivContainerText>
        </DivContainer>
      </SectionInform>

      <div className="subtotal">
        <SubTotal state={total} />
      </div>

      <FiscalActions>
        <XReciept rro_id={item?.rroId} />
      </FiscalActions>

      <Transaction fiscalDay={item?.transactionsList} />

      <BackIcon className="default" />
    </BankDetailContainer>
  );
};

export default FiscalOpenedDetail;
