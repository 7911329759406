import styled from "styled-components";

import { white } from "assets/style/variables";

const MobileMenuContainer = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 100006;
  /* overflow: hidden; */
  overflow-y: auto;
  background-color: #147b73;
  transition: width 0.2s ease 0s, padding 0.2s ease 0s;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) =>
    props.opened
      ? " width: 100vw; padding: 20px 10px;"
      : "width: 0; padding: 20px 0px;"}

  div.mobile-menu-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    div.logo-and-version {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    a.logo {
      cursor: pointer;
      img {
        width: 50px;
      }
    }

    button.close-button {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: none;
      background-color: transparent;
      cursor: pointer;
      transition: background-color 0.2s ease 0s;
      svg {
        width: 20px;
        height: 20px;
      }

      &:hover {
        background-color: #0f615b;
      }
    }
  }

  ul.main-links,
  ul.additional-links {
    margin-top: 20px;
  }

  a.main-link,
  a.additional-link {
    text-decoration: none;
    color: inherit;
  }

  a.main-link li,
  a.additional-link li {
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 20px;
    padding: 10px 20px;

    span {
      font-family: "Futura Book", sans-serif;
      font-size: 18px;
    }

    .news {
      position: relative;
      max-height: 22.5px;

      .news-count {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        background-color: orange;
        width: 1.15rem;
        height: 1.15rem;
        border-radius: 50%;
        font-size: 0.65rem;
        font-weight: bold;
        line-height: 1.15rem;
        text-align: center;
        transform: translate(8px, -30%);
        color: ${white};
      }
    }
  }

  a.additional-link li {
    font-family: "Futura Book", sans-serif;
    font-size: 14px;
    padding: 10px 20px;
  }

  a.main-link.active li,
  a.additional-link.active li {
    background-color: #fff;
    border-radius: 12px;
    span {
      color: #000;
    }
  }

  a.additional-link.active li {
    color: #000;
  }

  a.main-link.not_active li,
  a.additional-link.not_active li {
    svg {
      filter: brightness(1) invert(1);
    }
    span {
      color: #fff;
    }
  }

  a.additional-link.not_active li {
    color: #fff;
  }

  .news-icon {
    color: inherit !important;
    filter: none;
    font-size: 1.5rem;
  }
`;

export { MobileMenuContainer };
