import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserHistory } from "history";

import SubTotal from "components/layouts/SubTotal/SubTotal";
import { BankOpenContainer } from "../Bank.styles";
import SearchBox from "components/forms/SearchBox/SearchBox";
import Table from "pages/Panels/Bank/BankOpen/BankOpen.table";
import { bOpenTotal, bOpenSearchVal } from "store/bank_table/bank_table.slicer";
import {
  fetchServer,
  bankOpenSetSearch,
} from "store/bank_table/bank_table.actions";

const BankOpen = () => {
  const total = useSelector(bOpenTotal);
  const history = createBrowserHistory();
  const location = useLocation();
  const search_val = useSelector(bOpenSearchVal);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state) {
      dispatch(bankOpenSetSearch(location.state));
      dispatch(fetchServer(true, location.state));
      history.replace();
    }
  }, []);

  return (
    <BankOpenContainer>
      <div className="subtotal">
        <SubTotal state={total} />
      </div>

      <SearchBox
        title={"Пошук"}
        state={location.state ?? search_val}
        fn_reset={() => {
          dispatch(bankOpenSetSearch(null));
          dispatch(fetchServer());
        }}
        fn={(val) => {
          dispatch(bankOpenSetSearch(val));
          dispatch(fetchServer());
        }}
      />

      <Table />
    </BankOpenContainer>
  );
};

export default BankOpen;
