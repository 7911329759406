import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { CashierTable } from "api";
import { MAIN_CASHIER } from "utils/constants/cashier.constants";
import { checkShiftAtFiscalService } from "../../helpers/fiscalHelper";
import { setGlobalLoading } from "store/main_table/main_table.actions";
import { replaceMessageEntry } from "utils/helpers/message.helpers";
import { selectUserKey } from "store/user/user_slicer";

const useCloseShift = ({
  setTerminalId,
  setRro,
  setupCloseFiscalDayModal,
  setAlertState,
}) => {
  const dispatch = useDispatch();
  const [isMainCashier, setIsMainCashier] = useState(false);
  const [hasCashierKey, setHasCashierKey] = useState(false);
  const [isCashierKeyExpiered, setIsCashierKeyExpiered] = useState(false);
  const [isMainCashierKey, setIsMainCashierKey] = useState(false);
  const [cabinetKeyId, setCabinetKeyId] = useState();

  const { profile } = useSelector((state) => state.user);
  const userKey = useSelector(selectUserKey);
  const [cashiers, setCashiers] = useState(
    useSelector((state) => state.cashier_table.response)
  );
  const cashierTable = useMemo(() => new CashierTable(), []);

  useEffect(() => {
    const { role } = profile;

    setIsMainCashier(role === MAIN_CASHIER);

    if (userKey) {
      const { validTo, chiefCashier, subjectKeyIdentifier } = userKey;
      const isExpired = moment() > moment(validTo);

      setCabinetKeyId(subjectKeyIdentifier);
      setHasCashierKey(true);
      setIsCashierKeyExpiered(isExpired);
      setIsMainCashierKey(chiefCashier);
    } else {
      setHasCashierKey(false);
      setIsMainCashierKey(false);
      setCabinetKeyId(null);
    }

    if (!Array.isArray(cashiers) || cashiers.length === 0) {
      cashierTable.get().then((response) => {
        setCashiers(response.users);
      });
    }
  }, [profile, cashiers, cashierTable, userKey]);

  const closeShift = (item) => {
    const { terminalId, rroId } = item;

    setTerminalId(terminalId);
    setRro(rroId);
    setupCloseFiscalDayModal(item);
  };

  const notifyNoAllowedOperation = ({ title, subtitle, height }) => {
    setAlertState((alertState) => ({
      ...alertState,
      show: true,
      title,
      subtitle,
      type: "block",
      fnClose: () => setAlertState({ show: false }),
      height,
    }));
  };

  const tryCloseShift = async (shift, closeFn) => {
    const { rroId, terminalId } = shift;

    dispatch(setGlobalLoading(true));

    const checkResult = await checkShiftAtFiscalService(shift);

    dispatch(setGlobalLoading(false));

    let fiscalOK;

    const { errorCode, errorMessage, nonFiscalTranList, reviseTranList } =
      checkResult;

    if (errorCode === 0) {
      fiscalOK =
        (reviseTranList || []).length === 0 &&
        (nonFiscalTranList || []).length === 0;
    } else {
      return setAlertState({
        show: true,
        title: "Увага!",
        subtitle: `Спроба звірки з Державною податковою службою України статистики по поточній фіскальній зміні на терміналі ${terminalId} завершилася помилкою${
          errorMessage ? ` "${errorMessage}"` : ""
        }.`,
        footer:
          "Рекомендуємо перед закриттям фіскальної зміни з'ясувати присутність нефіскалізованих транзакцій і якщо такі є - дофіскалізувати їх.",
        type: "remove",
        btn_close: null,
        cancelButtonLabel: "Зрозуміло",
        acceptButtonLabel: "Закрити зміну",
        fnClose: () => setAlertState({ show: false }),
        fnSubmit: () => {
          setAlertState({ show: false });
          closeFn({ enterRro: rroId });
        },
        height: "500px",
        reversedButtonAccent: true,
        subtitleStyle: { textAlign: "justify" },
        footerStyle: { textAlign: "justify" },
      });
    }

    if (fiscalOK) {
      closeFn({ enterRro: rroId });
    } else {
      let subtitle, footer, list;
      const differsFromTaxService = (reviseTranList || []).length !== 0;
      const notFiscalTransactionExists = (nonFiscalTranList || []).length !== 0;

      if (differsFromTaxService) {
        subtitle = `Для ПРРО ${rroId} (термінал ${terminalId}) виявлено розбіжність по фіскалізаціях між даними ОщадPAY та кабінетом податкової:`;
        list = reviseTranList.map(({ ctime, transactionId, amount }) => ({
          ctime: moment(ctime).format("DD.MM.YYYY HH:mm:ss"),
          transactionId,
          amount: Number(amount).toFixed(2),
        }));
        footer =
          "Рекомендуємо поки не закривати фіскальну зміну та звернутись до служби підтримки для детального аналізу та виправлення ситуації. Надішліть скріншот цього екрану до служби підтримки.";

        setAlertState({
          show: true,
          title: "Увага!",
          subtitle,
          footer,
          type: "remove",
          btn_close: null,
          cancelButtonLabel: "Зрозуміло",
          acceptButtonLabel: "Закрити зміну",
          fnClose: () => setAlertState({ show: false }),
          fnSubmit: () => {
            setAlertState({
              show: true,
              title: "Увага!",
              subtitle:
                "Примусове закриття фіскальної зміни при розбіжностях між ОщадPAY та кабінетом податкової може призвести до подальших проблем у взаємодії з податковою.",
              type: "remove",
              btn_close: null,
              cancelButtonLabel: "Зрозуміло",
              acceptButtonLabel: "Закрити зміну",
              fnClose: () => setAlertState({ show: false }),
              fnSubmit: () => {
                if (notFiscalTransactionExists) {
                  subtitle = "У поточній зміні є не фіскалізовані транзакції:";
                  list = nonFiscalTranList.map(
                    ({ ctime, transactionId, amount }) => ({
                      ctime: moment(ctime).format("DD.MM.YYYY HH:mm:ss"),
                      transactionId,
                      amount: Number(amount).toFixed(2),
                    })
                  );
                  footer =
                    "Рекомендуємо перед закриттям фіскальної зміни дофіскалізувати транзакції.";

                  setAlertState({
                    show: true,
                    title: "Увага!",
                    subtitle,
                    footer,
                    type: "remove",
                    btn_close: null,
                    cancelButtonLabel: "Зрозуміло",
                    acceptButtonLabel: "Закрити зміну",
                    fnClose: () => setAlertState({ show: false }),
                    fnSubmit: () => {
                      setAlertState({ show: false });
                      closeFn({ enterRro: rroId });
                    },
                    height: "500px",
                    subtitleStyle: { textAlign: "justify" },
                    footerStyle: { textAlign: "justify" },
                    list,
                    listType: "grid",
                    listStyle: {
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                    },
                    reversedButtonAccent: true,
                  });
                } else {
                  setAlertState({ show: false });
                  closeFn({ enterRro: rroId });
                }
              },
              subtitleStyle: { textAlign: "justify" },
              height: "500px",
              reversedButtonAccent: true,
            });
          },
          height: "500px",
          subtitleStyle: { textAlign: "justify" },
          footerStyle: { textAlign: "justify" },
          list,
          listType: "grid",
          listStyle: { display: "grid", gridTemplateColumns: "1fr 1fr 1fr" },
          reversedButtonAccent: true,
        });
      } else {
        subtitle = "У поточній зміні є не фіскалізовані транзакції:";
        list = nonFiscalTranList.map(({ ctime, transactionId, amount }) => ({
          ctime: moment(ctime).format("DD.MM.YYYY HH:mm:ss"),
          transactionId,
          amount: Number(amount).toFixed(2),
        }));
        footer =
          "Рекомендуємо перед закриттям фіскальної зміни дофіскалізувати транзакції.";

        setAlertState({
          show: true,
          title: "Увага!",
          subtitle,
          footer,
          type: "remove",
          btn_close: null,
          cancelButtonLabel: "Зрозуміло",
          acceptButtonLabel: "Закрити зміну",
          fnClose: () => setAlertState({ show: false }),
          fnSubmit: () => {
            setAlertState({ show: false });
            closeFn({ enterRro: rroId });
          },
          height: "500px",
          subtitleStyle: { textAlign: "justify" },
          footerStyle: { textAlign: "justify" },
          list,
          listType: "grid",
          listStyle: { display: "grid", gridTemplateColumns: "1fr 1fr 1fr" },
          reversedButtonAccent: true,
        });
      }
    }
  };

  const onCloseShift = async (shift) => {
    if (!hasCashierKey) {
      return notifyNoAllowedOperation({
        title: "Закриття зміни неможливе!",
        subtitle:
          "Під Головним касиром повинен бути завантажений ключ для можливості виконання цієї функції",
      });
    }

    if (isCashierKeyExpiered) {
      const { app_login, user_name, warn_message } = profile;

      return notifyNoAllowedOperation({
        title: "Закриття зміни неможливе!",
        subtitle:
          (warn_message &&
            replaceMessageEntry(
              warn_message,
              "термін дії ключа",
              "термін дії сертифіката"
            )) ||
          `У користувача ${user_name} (${app_login}) скінчився термін дії сертифіката`,
      });
    }

    if (!isMainCashier) {
      return notifyNoAllowedOperation({
        title:
          "Для закриття зміни необхідно мати повноваження Головного касира",
      });
    }

    let cashier = cashiers.find(
      (item) => item.client_id === shift.openClientId
    );

    if (!cashier) {
      try {
        const data = await cashierTable.get();
        const { ErrorCode, users: cashiers } = data || {};

        if (ErrorCode === 0) {
          setCashiers(cashiers);
          cashier = cashiers.find(
            (item) => item.client_id === shift.openClientId
          );
        }
      } catch (error) {
        return notifyNoAllowedOperation({
          title: "Помилка ідентифікації касира зміни",
        });
      }
    }

    if (cashier) {
      if (cashier.role === MAIN_CASHIER) {
        closeShift(shift);
      } else {
        if (isMainCashierKey) {
          closeShift(shift);
        } else {
          const { rroId, terminalId, cashier: cashierLogin } = shift;
          const { client_id } = cashier;

          dispatch(setGlobalLoading(true));

          try {
            const res = await cashierTable.getKey(client_id);

            if (res?.errorCode === 0) {
              const { subjectKeyIdentifier } = res;

              if (subjectKeyIdentifier === cabinetKeyId) {
                closeShift(shift);
              } else {
                notifyNoAllowedOperation({
                  title: "Операцію заборонено!",
                  subtitle: `на терміналі ${terminalId} з ПРРО ${rroId} відкрита фіскальна зміна касиром ${cashierLogin} з ідентифікатором ключа ${subjectKeyIdentifier}. Логін на вказаний термінал дозволено касиру з таким ідентифікатором ключа або Старшим касирам`,
                  height: "550px",
                });
              }
            } else {
              notifyNoAllowedOperation({
                title: "Операцію заборонено!",
                subtitle: `на терміналі ${terminalId} з ПРРО ${rroId} відкрита фіскальна зміна касиром ${cashierLogin}. Логін на вказаний термінал дозволено цьому касиру або Старшим касирам`,
                height: "450px",
              });
            }
          } catch {
            notifyNoAllowedOperation({
              title: "Операцію заборонено!",
              subtitle: `на терміналі ${terminalId} з ПРРО ${rroId} відкрита фіскальна зміна касиром ${cashierLogin}. Логін на вказаний термінал дозволено цьому касиру або Старшим касирам`,
              height: "450px",
            });
          }

          dispatch(setGlobalLoading(false));
        }
      }
    }
  };

  return { onCloseShift, tryCloseShift };
};

export default useCloseShift;
