import React from "react";

import { isTextFormatted } from "../helpers";
import {
  CheckCaptionContainer,
  CheckCaption,
  CheckCaptionLabel,
  CheckCaptionTitle,
  CheckCaptionViewer,
  CheckCaptionEditor,
} from "./CheckCaption.style";

const CheckHeaderComponent = ({
  label,
  headers,
  placeholder,
  disabled,
  onChange,
  isMerchantIdReadOnly,
}) => {
  const [merchantName, receipt, merchantID] = headers;

  const checkHeaderBody = isMerchantIdReadOnly
    ? receipt
    : receipt || merchantID;

  const isFormatted = isTextFormatted(checkHeaderBody);

  return (
    <CheckCaptionContainer>
      <CheckCaption>
        <CheckCaptionLabel htmlFor="check-caption">{label}</CheckCaptionLabel>
        <CheckCaptionTitle>{merchantName || ""}</CheckCaptionTitle>
        {/* {disabled ? (
          <CheckCaptionViewer>
            {isFormatted ? <pre>{checkHeaderBody}</pre> : checkHeaderBody}
          </CheckCaptionViewer>
        ) : (
          <CheckCaptionEditor
            id="check-caption"
            value={checkHeaderBody}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
            rows={6}
          />
        )} */}
        <CheckCaptionEditor
          id="check-caption"
          value={checkHeaderBody}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          rows={6}
          disabled={disabled}
        />
        {isMerchantIdReadOnly && (
          <CheckCaptionTitle>{merchantID || ""}</CheckCaptionTitle>
        )}
        <hr />
      </CheckCaption>
    </CheckCaptionContainer>
  );
};

export default CheckHeaderComponent;
