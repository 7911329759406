import "./index.css";
import "../fonts/fonts.css";
import ResetStyle from "./reset";

const styles = () => (
  <>
    <ResetStyle />
  </>
);
export default styles;
