import { useState, useEffect } from "react";
import moment from "moment";
import {
  Th,
  TableIcon,
  TableInput,
  TableForm,
  TableFormResult,
  TableIconClose,
} from "../../Table.styles";

import { ReactComponent as CloseIcon } from "assets/icons/close_white.svg";
import CalendarUI from "ui/Calendar/Calendar";
import GenerateDate from "utils/helpers/date.helpers";
import { UI_DATE_FORMAT } from "utils/constants/time";

const makeVal = (choose_state) =>
  !choose_state
    ? ""
    : moment.isMoment(choose_state)
    ? choose_state.format(UI_DATE_FORMAT)
    : choose_state;

export default function TableHeaderDate({
  title,
  fn,
  fn_reset,
  icon,
  choose_state,
  range,
  required,
  rangeFrom,
  rangeTo,
  value,
  editableDateInputs,
}) {
  const [val, setVal] = useState(makeVal(choose_state));
  const [active, setActive] = useState(Boolean(choose_state));
  const [showCalendar, setShowCalendar] = useState(false);
  const isChoosingStart =
    range && rangeFrom
      ? choose_state === moment(rangeFrom).format(UI_DATE_FORMAT)
      : false;

  function chooseItem(value) {
    const generate = GenerateDate();
    const dateValue = range
      ? isChoosingStart
        ? value[0].startDate
        : value[0].endDate
      : value;
    const date =
      dateValue instanceof Date
        ? generate.format(
            range
              ? isChoosingStart
                ? value[0].startDate
                : value[0].endDate
              : value
          )
        : null;
    setVal(
      date ? `${date.slice(6, 8)}.${date.slice(4, 6)}.${date.slice(0, 4)}` : ""
    );
    fn(value);
    setActive(Boolean(date));
    setShowCalendar(false);
  }

  function close() {
    if (!required) {
      fn_reset();
      setActive(false);
      setVal("");
    }

    setShowCalendar(false);
  }

  useEffect(() => {
    if (choose_state) {
      setVal(makeVal(choose_state));
      setActive(true);
    } else {
      setVal("");
      setActive(false);
    }
  }, [choose_state]);

  return (
    <Th
      active={active}
      onClick={!active ? () => setShowCalendar(true) : () => {}}
    >
      <TableIcon>
        <img src={icon} alt="icon" />
        {showCalendar && (
          <CalendarUI
            fn={chooseItem}
            range={range}
            propShow={showCalendar}
            setPropShow={setShowCalendar}
            startDate={rangeFrom}
            endDate={rangeTo}
            value={value}
            required={required}
            editableDateInputs={editableDateInputs}
          />
        )}
      </TableIcon>

      <TableInput
        padding={icon === null}
        placeholder={title}
        value={val}
        disabled={true}
        style={{ background: "white" }}
      />
      <TableForm active={active}>
        <TableFormResult onClick={() => setShowCalendar((v) => !v)}>
          {val}
        </TableFormResult>
        {!required && (
          <TableIconClose onClick={close}>
            <CloseIcon />
          </TableIconClose>
        )}
      </TableForm>
    </Th>
  );
}
