import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";

import { NewsAPI } from "api";
import {
  updateItemInCache,
  deleteItemFromCache,
} from "store/news_table/news_table.actions";
import { setAlertState } from "store/alert/alert_actions";
import { NEWS_ROUTE } from "utils/constants/routes.constants";

const dateTimeFormat = new Intl.DateTimeFormat("uk", {
  day: "2-digit",
  month: "long",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
});

const formatDate = (dateString) => {
  if (!dateString) return null;

  return dateTimeFormat
    .format(moment(dateString, "DD/MM/YYYY hh:mm:ss").toDate())
    .replace(" р. о ", " ");
};

const getMessageBody = async (NewsAPI, messageId, setLoading) => {
  let messageMarkup;

  setLoading(true);

  try {
    const {
      messages: [{ message: markup }],
    } = await NewsAPI.getMessage(messageId);

    messageMarkup = markup;
  } catch (error) {
    messageMarkup = "<p>Помилка запиту повідомлення</p>";
  } finally {
    setLoading(false);
  }

  return messageMarkup;
};

const useNewsDetail = (message) => {
  const history = useHistory();
  const contentRef = useRef();
  const dispatch = useDispatch();
  const {
    publish_date,
    message_id: messageId,
    message_read,
    message: messageMarkup,
  } = message;
  const [loading, setLoading] = useState(false);
  const [markup, setMarkup] = useState(messageMarkup);
  const api = useMemo(() => new NewsAPI(), []);

  useEffect(() => {
    if (!messageId) return;

    getMessageBody(api, messageId, setLoading).then((markup) => {
      setMarkup(markup);
      dispatch(
        updateItemInCache({ ...message, message: markup, message_read: true })
      );
    });

    if (!message_read) api.markMessagesRead(`${messageId}`);
  }, [messageId]);

  if (contentRef.current && markup) {
    contentRef.current.innerHTML = markup;
  }

  const goBack = async () => history.push(NEWS_ROUTE);

  const leaveMessageNotRead = async () => {
    const { ErrorCode, ErrorMessage } = await api.markMessagesNotRead(
      `${messageId}`
    );

    if (ErrorCode === 0) {
      dispatch(updateItemInCache({ ...message, message_read: false }));
      goBack();
    } else {
      dispatch(
        setAlertState({
          type: "block",
          title: "Помилка",
          subtitle: ErrorMessage,
        })
      );
    }
  };

  const deleteMessage = async () => {
    const { ErrorCode, ErrorMessage } = await api.deleteMessages(
      `${messageId}`
    );

    if (ErrorCode === 0) {
      dispatch(deleteItemFromCache(message));
      goBack();
    } else {
      dispatch(
        setAlertState({
          type: "block",
          title: "Помилка",
          subtitle: ErrorMessage,
        })
      );
    }
  };

  return {
    contentRef,
    loading,
    messageDate: formatDate(publish_date),
    goBack,
    leaveMessageNotRead,
    deleteMessage,
  };
};

export default useNewsDetail;
