import React from "react";

import {
  CloseFiscalDayModalContainer,
  ContainedButton,
  ModalButtons,
} from "./style";
import { ModalTitle, ModalSubTitle, ModalBody } from "ui/Modal/modal.styles";
import TimesCancelButton from "ui/Modal/TimesCancelButton";
import { generateIcon } from "ui/Alert/icons/alertIconHelper";

const CloseFiscalDayModal = (props) => {
  const { closedBankDay, makeInkassActionFunction, onCancel, cashBalance } =
    props;

  return (
    <CloseFiscalDayModalContainer>
      {generateIcon("remove")}
      <ModalTitle>Увага! Готівка в касі {cashBalance} грн.</ModalTitle>
      <ModalSubTitle>
        Бажаєте зробити СЛУЖБОВУ ВИДАЧУ перед закриттям фіскальної зміни?
      </ModalSubTitle>
      <ModalBody>
        <ModalButtons>
          <ContainedButton onClick={() => makeInkassActionFunction()}>
            Так, зробити СЛУЖБОВУ ВИДАЧУ.
          </ContainedButton>
          <ContainedButton onClick={() => closedBankDay({ inkass: false })}>
            Ні, закрити фіскальну зміну.
          </ContainedButton>
          <TimesCancelButton onClick={onCancel} right={".65rem"} />
        </ModalButtons>
      </ModalBody>
    </CloseFiscalDayModalContainer>
  );
};

export default CloseFiscalDayModal;
