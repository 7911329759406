const TABLE_GET_SERVER = "cashier_table/get_server";
const TABLE_SET_SERVER = "cashier_table/set_server";
const TABLE_SET_LOCAL = "cashier_table/set_local";
const TABLE_SET_SERVER_TERMINAL = "cashier_table/set_server_terminal";
const TABLE_SET_LOCAL_TERMINAL = "cashier_table/set_local_terminal";
const TABLE_LOAD = "cashier_table/load";
const TABLE_SERVER_LOAD = "cashier_table/server_load";
const TABLE_SERVER_LOAD_TERMINAL = "cashier_table/server_load_terminal";
const TABLE_CHANGE_BUTTON = "cashier_table/chang_active_button";
const TABLE_CHANGE_DATE = "cashier_table/chang_dates";
const TABLE_SORT = "cashier_table/sort";
const TABLE_SORT_NAME = "cashier_table/sort_name";
const TABLE_SORT_DATE = "cashier_table/sort_date";
const TABLE_SORT_PHONE = "cashier_table/sort_phone";
const TABLE_SORT_FISCAL = "cashier_table/sort_fiscal";
const TABLE_SORT_STATUS = "cashier_table/sort_status";
const TABLE_SORT_TYPE = "cashier_table/chang_sort_type";
const TABLE_SORT_TYPE_TERMINAL = "cashier_table/chang_sort_terminal_type";
const TABLE_SET_HEADER = "cashier_table/chang_headers";
const TABLE_SET_SEARCH = "cashier_table/search_box_state";
const TABLE_SET_SEARCH_VAL = "cashier_table/search_box_state_val";
const TABLE_SET_TERMINAL_SEARCH = "cashier_table/search_box_terminal_state";
const TABLE_SET_TERMINAL_SEARCH_VAL =
  "cashier_table/search_box_state_terminal_val";
const TABLE_FILTER = "cashier_table/filter";

const TABLE_SET_FILTER_TRANSACTION = "cashier_table/filter_transaction";
const TABLE_SET_FILTER_CARDS = "cashier_table/filter_cards";
const TABLE_SET_FILTER_CASH = "cashier_table/filter_cash";
const TABLE_SET_FILTER_TOTAL = "cashier_table/filter_totals";

const TABLE_SET_FILTER_TERMINAL_ID = "cashier_table/filter_id";
const TABLE_SET_FILTER_TERMINAL_NAME = "cashier_table/filter_name";
const TABLE_SET_FILTER_TERMINAL_DESCRIBE = "cashier_table/filter_describe";
const TABLE_SET_FILTER_TERMINAL_DEVICE = "cashier_table/filter_device";

const TABLE_SET_FILTER_TERMINAL_ID_VAL = "cashier_table/filter_id_val";
const TABLE_SET_FILTER_TERMINAL_NAME_VAL = "cashier_table/filter_name_val";
const TABLE_SET_FILTER_TERMINAL_DESCRIBE_VAL =
  "cashier_table/filter_describe_val";
const TABLE_SET_FILTER_TERMINAL_DEVICE_VAL = "cashier_table/filter_device_val";

const TABLE_SET_FILTER_TRANSACTION_VAL = "cashier_table/filter_transaction_val";
const TABLE_SET_FILTER_CARDS_VAL = "cashier_table/filter_cards_val";
const TABLE_SET_FILTER_CASH_VAL = "cashier_table/filter_cash_val";
const TABLE_SET_FILTER_TOTAL_VAL = "cashier_table/filter_totals_val";

const TABLE_SET_FILTER_NAME_VAL = "cashier_table/filter_name_val";
const TABLE_SET_FILTER_LAST_LOGIN_VAL = "cashier_table/filter_last_login_val";
const TABLE_SET_FILTER_KEY_EXPIRES_VAL = "cashier_table/filter_key_expires_val";
const TABLE_SET_FILTER_PHONE_VAL = "cashier_table/filter_phone_val";
const TABLE_SET_FILTER_FISCAL_VAL = "cashier_table/filter_fiscal_val";

const TABLE_SET_SEARCH_TERMINAL = "cashier_table/search_box_state";

const TABLE_RESET = "cashier_table/reset";

const TABLE_SET_ITEM = "cashier_table/choose_item";
const TABLE_SET_RESPONSE = "cashier_table/set_response";
const TABLE_SET_CASHIER = "cashier_table/set_cashier";
const TABLE_CLEAR_CASHIER_KEY = "cashier_table/clear_cashier_key";

export {
  TABLE_SET_FILTER_NAME_VAL,
  TABLE_SET_FILTER_LAST_LOGIN_VAL,
  TABLE_SET_FILTER_KEY_EXPIRES_VAL,
  TABLE_SET_FILTER_PHONE_VAL,
  TABLE_SET_FILTER_FISCAL_VAL,
  TABLE_SET_CASHIER,
  TABLE_SORT_NAME,
  TABLE_SORT_DATE,
  TABLE_SORT_PHONE,
  TABLE_SORT_FISCAL,
  TABLE_SORT_STATUS,
  TABLE_SET_RESPONSE,
  TABLE_LOAD,
  TABLE_GET_SERVER,
  TABLE_SET_SERVER,
  TABLE_SET_LOCAL,
  TABLE_SET_SERVER_TERMINAL,
  TABLE_SERVER_LOAD_TERMINAL,
  TABLE_SET_LOCAL_TERMINAL,
  TABLE_SERVER_LOAD,
  TABLE_CHANGE_BUTTON,
  TABLE_CHANGE_DATE,
  TABLE_SORT,
  TABLE_SORT_TYPE,
  TABLE_SORT_TYPE_TERMINAL,
  TABLE_SET_HEADER,
  TABLE_SET_SEARCH,
  TABLE_SET_SEARCH_TERMINAL,
  TABLE_SET_SEARCH_VAL,
  TABLE_SET_TERMINAL_SEARCH,
  TABLE_SET_TERMINAL_SEARCH_VAL,
  TABLE_FILTER,
  TABLE_RESET,
  TABLE_SET_FILTER_TRANSACTION,
  TABLE_SET_FILTER_CARDS,
  TABLE_SET_FILTER_CASH,
  TABLE_SET_FILTER_TOTAL,
  TABLE_SET_FILTER_TRANSACTION_VAL,
  TABLE_SET_FILTER_CARDS_VAL,
  TABLE_SET_FILTER_CASH_VAL,
  TABLE_SET_FILTER_TOTAL_VAL,
  TABLE_SET_FILTER_TERMINAL_ID,
  TABLE_SET_FILTER_TERMINAL_DEVICE,
  TABLE_SET_FILTER_TERMINAL_DESCRIBE,
  TABLE_SET_FILTER_TERMINAL_NAME,
  TABLE_SET_FILTER_TERMINAL_ID_VAL,
  TABLE_SET_FILTER_TERMINAL_DEVICE_VAL,
  TABLE_SET_FILTER_TERMINAL_DESCRIBE_VAL,
  TABLE_SET_FILTER_TERMINAL_NAME_VAL,
  TABLE_SET_ITEM,
  TABLE_CLEAR_CASHIER_KEY,
};
