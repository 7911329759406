import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Title,
  TitleContainer,
  HeaderContainer,
  SubTitle,
  TextContainer,
  HeaderLogo,
  BurgerMenu,
  Badge,
} from "./Header.styles";
import Profile from "../Profile/Profile";
import BackIcon from "ui/BackIcon/BackIcon";
import { useHeader } from "store/header/header.context";
import {
  GreenActiveButton,
  GreenButton,
} from "pages/Panels/Terminals/Terminals.styles";

// mobile
import MobileMenu from "../MobileMenu/MobileMenu";
import { mobileVersion as rMobileVersion } from "store/main_table/main_table.slicer";
import logo from "assets/images/logo.png";
import burger_menu from "assets/icons/burger_black_24.svg";
import StatSelector from "pages/Panels/Statistics/components/StatSelector/StatSelector";
import { PAGE_NAME } from "../../../utils/constants/ui";

const Header = () => {
  const { state } = useHeader();
  const mobileVersion = useSelector(rMobileVersion) < 1024;
  const { state: messages } = useSelector((state) => state.news_table);
  const { title, subtitle, left, mobile_back, button, bound, active } = state;

  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  const newMessages = messages.filter((item) => !item.message_read);

  function generateButton() {
    if (button !== null) {
      return (
        <GreenButton ok={button !== "Заблокований"} bound={bound}>
          {button}
        </GreenButton>
      );
    }
    return "";
  }

  function generateActive() {
    if (active !== null) {
      return (
        <GreenActiveButton ok={active}>
          {active ? "Активний" : "Заблокований"}
        </GreenActiveButton>
      );
    }
    return "";
  }

  const isOnStatPage = title === PAGE_NAME.statistics;

  return (
    <header style={{ marginBottom: mobileVersion ? "16px" : "40px" }}>
      <MobileMenu
        newMessages={newMessages}
        opened={mobileMenuOpened}
        setOpened={setMobileMenuOpened}
      />
      <HeaderContainer isMobile={mobileVersion}>
        <HeaderLogo src={logo} alt="logo" />
        <BurgerMenu
          newMessages={newMessages}
          onClick={() => setMobileMenuOpened((v) => !v)}
        >
          <img src={burger_menu} alt="burger" />
          {newMessages?.length > 0 && <Badge>{newMessages?.length}</Badge>}
        </BurgerMenu>
        {!mobileVersion && (
          <TextContainer left={left}>
            <TitleContainer isMobile={mobileVersion}>
              <Title>
                {title}
                {generateButton()}
                {generateActive()}
              </Title>
              <div
                style={{ display: "flex", gap: "1rem", alignItems: "center" }}
              >
                {isOnStatPage && <StatSelector />}
              </div>
            </TitleContainer>
            {subtitle && <SubTitle>{subtitle}</SubTitle>}
          </TextContainer>
        )}
        <Profile />
      </HeaderContainer>
      {mobileVersion && (
        <HeaderContainer style={{ marginTop: "10px" }} isMobile={mobileVersion}>
          {mobile_back && (
            <BackIcon className="mobile" style={{ marginRight: "10px" }} />
          )}
          <TextContainer left={left} style={{ marginLeft: 0 }}>
            {title && (
              <TitleContainer isMobile={mobileVersion}>
                <Title>
                  {title}
                  {generateButton()}
                  {generateActive()}
                </Title>
                {isOnStatPage && <StatSelector />}
              </TitleContainer>
            )}

            {subtitle && <SubTitle>{subtitle}</SubTitle>}
          </TextContainer>
        </HeaderContainer>
      )}
    </header>
  );
};

export default Header;
