import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setStatParams } from "store/statistics/stat_actions";
import useStatPeriod from "pages/Panels/Statistics/useStatPeriod";

const useHeader = (chartState) => {
  const dispatch = useDispatch();
  const { year } = chartState;
  const { years } = useStatPeriod();

  const statYear = useMemo(
    () => years.find(({ value }) => value === year),
    [years, year]
  );

  return {
    year: statYear,
    years,
    setYear: (year) =>
      dispatch(setStatParams({ ...chartState, year: year?.value })),
  };
};

export default useHeader;
