import { ButtonGreen } from "components/forms/Button/Button.styles";
import { useEffect, useState } from "react";
import moment from "moment";

import { LocalStorage } from "services";
import { useHeader } from "store/header/header.context";
import { buttonState, subTotalMainState } from "./Main.states";
import ChangeTitle from "utils/helpers/changetitle.helpers";
import GenerateDate from "utils/helpers/date.helpers";
import { ButtonContainer, MainPanelContainer } from "./Main.styles";
import MainSubTotal from "components/layouts/SubTotal/MainSubTotal";
import Table from "./Main.table";
import CalendarUI from "ui/Calendar/Calendar";
import Refresh from "ui/Refresh/Refresh";

import { useDispatch, useSelector } from "react-redux";
import {
  setActiveButton,
  setDates,
  fetchServer,
} from "store/main_table/main_table.actions";
import {
  activeButton,
  headersValues,
  selectMainTableDates,
} from "store/main_table/main_table.slicer";
import { API_SIMPLE_DATE_FORMAT } from "../../../utils/constants/time";

export default function Main() {
  const { dispatch, state } = useHeader();
  const reduxDispatch = useDispatch();

  const activeId = useSelector(activeButton);
  const [showCalendar, setShowCalendar] = useState(false /*activeId === 5*/);
  const [buttons, setButtons] = useState(
    generateActiveButton(buttonState, activeId)
  );
  const headers = useSelector(headersValues);

  const [subTotal, setSubTotal] = useState([]);
  const dateRange = useSelector(selectMainTableDates);

  useEffect(() => {
    const localstorage = new LocalStorage();
    let name = localstorage.get("name");

    ChangeTitle(`${name || "Завантаження..."}`, dispatch);
  }, [state.title, dispatch]);

  useEffect(() => {
    const generateHeaders = () => {
      return subTotalMainState.map((item) => {
        return { ...item, value: headers[item.type] || 0 };
      });
    };
    setSubTotal(generateHeaders());
  }, [headers]);

  function generateActiveButton(state, val) {
    return state.map((item) => {
      if (item.id === +val) return { ...item, active: true };
      return { ...item, active: false };
    });
  }

  function handleButtonRedux(id) {
    setShowCalendar(false);
    setButtons(generateActiveButton(buttonState, id));
    reduxDispatch(setActiveButton(id));
    reduxDispatch(setDates(getDates(id)));
    loadServer();
  }

  function loadServer() {
    reduxDispatch(fetchServer());
  }

  function handleButton(e) {
    e.preventDefault();
    const id = e.target.dataset.id;
    if (id) handleButtonRedux(id);
  }

  function getDates(id) {
    const dates = GenerateDate();
    const today = dates.today();

    const getObject = (val1, val2) => ({ dateFrom: val1, dateTo: val2 });

    switch (+id) {
      case 0:
        return getObject(today, today);
      case 1:
        return getObject(dates.yesterday(), dates.yesterday());
      case 2:
        return getObject(dates.lastweek(), today);
      case 3:
        return getObject(dates.lastmonth(), today);
      case 4:
        return getObject(dates.currentMonth(), today);
      case 5:
        return getObject(
          dates.format(
            moment(dateRange.dateFrom, API_SIMPLE_DATE_FORMAT).toDate()
          ),
          dates.format(
            moment(dateRange.dateTo, API_SIMPLE_DATE_FORMAT).toDate()
          )
        );

      default:
        throw new Error("getDates this case no exists!");
    }
  }

  function chooseCalendar(range, id = 5) {
    const [{ startDate, endDate }] = range;

    setShowCalendar(false);
    sessionStorage.setItem("main_date", JSON.stringify(range));
    reduxDispatch(setActiveButton(id));
    reduxDispatch(
      setDates({
        dateFrom: moment(startDate).format(API_SIMPLE_DATE_FORMAT),
        dateTo: moment(endDate).format(API_SIMPLE_DATE_FORMAT),
      })
    );
    setButtons(generateActiveButton(buttonState, id));
    loadServer();
  }

  return (
    <MainPanelContainer>
      <ButtonContainer onClick={handleButton}>
        {buttons.map((item) => (
          <ButtonGreen data-id={item.id} key={item.id} active={item.active}>
            {item.title}
          </ButtonGreen>
        ))}
        <CalendarUI
          fn={chooseCalendar}
          propShow={showCalendar}
          setPropShow={setShowCalendar}
          startDate={moment(
            dateRange.dateFrom,
            API_SIMPLE_DATE_FORMAT
          ).toDate()}
          endDate={moment(dateRange.dateTo, API_SIMPLE_DATE_FORMAT).toDate()}
          required={false}
          range
        />
      </ButtonContainer>

      <div className="subtotal">
        <MainSubTotal state={subTotal} dateRange={dateRange} />
      </div>
      <Table />

      <Refresh fn={loadServer} />
    </MainPanelContainer>
  );
}
