import { useSelector } from "react-redux";
import { ChartLegendHeader, ChartLegendTypes } from "./ChartLegend.styles";
import { ChartSwitch } from "pages/Panels/Statistics/components";
import { TRAN_COLORS, TRAN_TYPES, TRAN_NAMES } from "../../config";
import { selectStatTranTypes } from "store/statistics/stat_slicer";
import { grey_off } from "assets/style/variables";

const ChartLegend = ({ onCheckChartOption, isLoading }) => {
  const { card, cash, cashless, qr } = useSelector(selectStatTranTypes);

  return (
    <ChartLegendHeader>
      <ChartLegendTypes>
        <ChartSwitch
          onChange={() => onCheckChartOption(TRAN_TYPES.card)}
          checked={card}
          offColor={grey_off}
          onColor={TRAN_COLORS.card}
          label={TRAN_NAMES.card}
          disabled={isLoading}
        />
        <ChartSwitch
          onChange={() => onCheckChartOption(TRAN_TYPES.cash)}
          checked={cash}
          offColor={grey_off}
          onColor={TRAN_COLORS.cash}
          label={TRAN_NAMES.cash}
          disabled={isLoading}
        />
        <ChartSwitch
          onChange={() => onCheckChartOption(TRAN_TYPES.cashless)}
          checked={cashless}
          offColor={grey_off}
          onColor={TRAN_COLORS.cashless}
          label={TRAN_NAMES.cashless}
          labelStyle={{ textAlign: "left" }}
          disabled={isLoading}
        />
        <ChartSwitch
          onChange={() => onCheckChartOption("qr")}
          checked={qr}
          offColor={grey_off}
          onColor={TRAN_COLORS.qr}
          label={TRAN_NAMES.qr}
          disabled={isLoading}
        />
      </ChartLegendTypes>
    </ChartLegendHeader>
  );
};

export default ChartLegend;
