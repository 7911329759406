import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectCurrentStatisticsParams,
  selectCurrentStatisticsData,
} from "store/statistics/stat_slicer";
import { DEFAULT_TOTALS, STAT_TYPES } from "../../constants";
import { fetchByYearData } from "store/statistics/stat_actions";

const useChartState = () => {
  const chartParams = useSelector(selectCurrentStatisticsParams);
  const { data, isLoading } = useSelector(selectCurrentStatisticsData);
  const [chartTotals, setChartTotals] = useState(DEFAULT_TOTALS);
  const dispatch = useDispatch();

  useEffect(() => {
    const { beginYear, endYear } = chartParams;

    dispatch(fetchByYearData(STAT_TYPES.BY_YEAR, beginYear, endYear));
  }, [chartParams]);

  useEffect(() => {
    if (!data) return;

    const total = data.reduce((a, c) => a + c.total, 0);

    setChartTotals({ total, totals: data });
  }, [data]);

  return {
    chartTotals,
    chartState: chartParams,
    data,
    isLoading,
  };
};

export default useChartState;
