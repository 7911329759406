import { GoodsTable } from "api";

export const fetchCategories = (data) => {
  const { setLocalLoading, setCategories } = data;
  const goodsTable = new GoodsTable();

  setLocalLoading(true);

  goodsTable
    .getCategories()
    .then((res) => {
      if (!Array.isArray(res)) return;

      setCategories(
        res.map((el) => ({
          ...el,
          level: 0,
          children: [],
          pathIds: [el.id],
        }))
      );
    })
    .finally(() => setLocalLoading(false));
};

export const getCategoryChildren = (data) => {
  const { categories, setCategories, categoryId, pathIds } = data;
  const goodsTable = new GoodsTable();

  goodsTable.getChildrenCategoriesById(categoryId).then((res) => {
    const newCategories = [...categories];
    let newCategoriesElementChanged = newCategories.filter(
      (cat) => cat.id === pathIds[0]
    )[0];
    if (pathIds.length > 1) {
      pathIds.forEach((el, index) => {
        if (index === 0) return;

        newCategoriesElementChanged =
          newCategoriesElementChanged.children.filter(
            (cat) => cat.id === el
          )[0];
      });
    }

    newCategoriesElementChanged.children = res.inverseParent
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((el) => ({
        ...el,
        level: pathIds.length,
        children: [],
        pathIds: [...pathIds, el.id],
        active: true,
      }));

    setCategories(newCategories);
  });
};

export const addNewCategory = ({
  name,
  parentId,
  resetServer,
  setLocalLoading,
  setAlertState,
}) => {
  const goodsTable = new GoodsTable();

  setLocalLoading(true);

  goodsTable
    .createCategory({ name, parentId })
    .then((res) => {
      resetServer();
    })
    .catch(() => {
      setAlertState((val) => ({
        ...val,
        show: true,
        type: "block",
        title: "Виникла помилка при створенні категорії",
        subtitle: "",
        fnSubmit: () => {},
      }));
    })
    .finally(() => setLocalLoading(false));
};

export const switchCategoryChildren = (data) => {
  const { categories, setCategories, pathIds, flag } = data;

  const newCategories = [...categories];
  let newCategoriesElementChanged = newCategories.filter(
    (cat) => cat.id === pathIds[0]
  )[0];
  if (pathIds.length > 1) {
    pathIds.forEach((el, index) => {
      if (index === 0) return;

      newCategoriesElementChanged = newCategoriesElementChanged.children.filter(
        (cat) => cat.id === el
      )[0];
    });
  }

  newCategoriesElementChanged.children =
    newCategoriesElementChanged.children.map((el) => ({
      ...el,
      active: flag,
    }));

  setCategories(newCategories);
};

export const deleteCategory = (data) => {
  const { categoryId, resetServer, setLocalLoading, setAlertState } = data;
  const goodsTable = new GoodsTable();

  setLocalLoading(true);

  goodsTable
    .deleteCategory(categoryId)
    .then((response) => {
      if (response && response.ErrorCode !== 0) {
        setAlertState((val) => ({
          ...val,
          show: true,
          type: "block",
          title: "Виникла помилка при видаленні категорії",
          subtitle: `${response.ErrorMessage}`,
          fnSubmit: () => {},
        }));
      } else {
        resetServer();
      }
    })
    .catch(() => {
      setAlertState((val) => ({
        ...val,
        show: true,
        type: "block",
        title: "Виникла помилка при видаленні категорії",
        subtitle: "",
        fnSubmit: () => {},
      }));
    })
    .finally(() => setLocalLoading(false));
};

export const editCategory = (data) => {
  const {
    name,
    categoryId,
    resetServer,
    setLocalLoading,
    setOldCategory,
    setEditCategoryMode,
    setAlertState,
  } = data;
  const goodsTable = new GoodsTable();

  setLocalLoading(true);

  goodsTable
    .editCategory({ name, categoryId })
    .then(() => {
      resetServer();
      setOldCategory(null);
      setEditCategoryMode(false);
    })
    .catch(() => {
      setAlertState((val) => ({
        ...val,
        show: true,
        type: "block",
        title: "Виникла помилка при редагуванні категорії",
        subtitle: "",
        fnSubmit: () => {},
      }));
    })
    .finally(() => setLocalLoading(false));
};
