import styled from "styled-components";
import { STAT_TYPES } from "../../constants";

export const ChartTotalsContainer = styled.div`
  display: flex;
  justify-content: ${({ chartType, quarterly }) =>
    chartType === STAT_TYPES.BY_MONTH && quarterly
      ? "space-between"
      : "space-around"};
  margin: ${({ chartType, quarterly }) =>
    chartType === STAT_TYPES.BY_MONTH
      ? quarterly
        ? "0 0rem 0 4rem"
        : "0px -1rem 0px 2.5rem"
      : "0 2rem 0 5rem"};
  background-color: whitesmoke;
  font-size: 12px;

  & > * {
    flex: ${({ chartType, quarterly }) =>
      chartType === STAT_TYPES.BY_MONTH && quarterly ? "unset" : "1 1"};
    text-align: ${({ chartType, quarterly }) =>
      chartType === STAT_TYPES.BY_MONTH && quarterly ? "left" : "center"};
  }
`;
