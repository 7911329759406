import React from "react";

import { GlobalLoaderContainer } from "./global-loader.styles";

const GlobalLoader = ({ opacity, top, left }) => {
  return (
    <GlobalLoaderContainer {...{ opacity, top, left }}>
      <div className="lds-dual-ring" />
    </GlobalLoaderContainer>
  );
};

export default GlobalLoader;
