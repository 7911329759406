import _ from "lodash";
import { GoodsTable, GOODS_LIMIT } from "api";
import {
  GOODS_SERVER_INIT,
  GOODS_STATE,
  GOODS_SEARCH,
  GOODS_PAGINATION,
  GOODS_LOCAL_INIT,
  GOODS_ITEM,
  DROP_FILTERING_STATE,
  UPDATE_FILTERING_STATE_ITEM,
} from "./goods_table.types";

export const fetchServer =
  ({
    goods,
    setGoods,
    setTableFull,
    setIsRequestWorking,
    setLocalLoading,
    filterState,
  }) =>
  async (dispatch, getState) => {
    const goodsTable = new GoodsTable();
    const { pagination, search } = getState().goods_table;

    setIsRequestWorking(true);
    setLocalLoading(true);
    dispatch(setLocalStatus(false));

    if (
      (!filterState ||
        ((!filterState.name.value || filterState.name.value === "") &&
          !filterState.category.value &&
          !filterState.calculations.value &&
          (!filterState.inside_code.value ||
            filterState.inside_code.value === "") &&
          (!filterState.price.value || filterState.price.value === ""))) &&
      (!search || search === "")
    ) {
      goodsTable
        .get(pagination)
        .then((res) => {
          const goodsArray = res.data;

          if (goodsArray) {
            if (goodsArray.length === 0 || goodsArray.length < GOODS_LIMIT)
              setTableFull(true);

            if (goods) {
              setGoods((state) => {
                const baseIDs = state.map((item) => item.id);
                const extraIDs = goodsArray.map((item) => item.id);
                const toLoadIDs = _.difference(extraIDs, baseIDs);

                return [
                  ...state,
                  ...goodsArray.filter((item) => toLoadIDs.includes(item.id)),
                ];
              });
            } else {
              setGoods(goodsArray);
            }

            dispatch(setServerStatus(true));
            dispatch(setLocalStatus(true));
          } else {
            goods
              ? dispatch(setServerStatus(true))
              : dispatch(setLocalStatus(true));
          }
        })
        .finally(() => {
          setIsRequestWorking(false);
          setLocalLoading(false);
        });

      return;
    }

    goodsTable
      .getWithFilter({ pagination, search, filterState })
      .then((res) => {
        const goodsArray = res.data;

        if (goodsArray) {
          if (goodsArray.length === 0 || goodsArray.length < GOODS_LIMIT)
            setTableFull(true);
          setGoods(goods ? [...goods, ...goodsArray] : goodsArray);
          dispatch(setServerStatus(true));
          dispatch(setLocalStatus(true));
        } else {
          goods
            ? dispatch(setServerStatus(true))
            : dispatch(setLocalStatus(true));
        }
      })
      .finally(() => {
        setIsRequestWorking(false);
        setLocalLoading(false);
      });
  };

export const setSearch = (string) => ({
  type: GOODS_SEARCH,
  payload: string,
});

export const setServerStatus = (bool) => ({
  type: GOODS_SERVER_INIT,
  payload: bool,
});

export const setLocalStatus = (string) => ({
  type: GOODS_LOCAL_INIT,
  payload: string,
});

export const setPagination = (num) => ({
  type: GOODS_PAGINATION,
  payload: num,
});

export const setState = (state) => ({
  type: GOODS_STATE,
  payload: state,
});
export const setItem = (state) => ({
  type: GOODS_ITEM,
  payload: state,
});

export const dropFilteringState = () => ({
  type: DROP_FILTERING_STATE,
  payload: null,
});

export const updateFilteringStateItem = ({ field, value }) => ({
  type: UPDATE_FILTERING_STATE_ITEM,
  payload: { field, value },
});
