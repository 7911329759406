import { useSelector } from "react-redux";
import NormalSwitch from "components/forms/Switch/Switch";
import SmallSwitch from "components/forms/Switch/SmallSwitch";
import { ChartSwitchWrapper } from "./ChartSwitch.styles";
import { mobileVersion } from "store/main_table/main_table.slicer";

const ChartSwitch = ({
  checked,
  offColor,
  onColor,
  onChange,
  label,
  labelStyle,
  type,
  disabled,
}) => {
  const isMobile = useSelector(mobileVersion) < 1024;
  const Switch =
    type === "small"
      ? SmallSwitch
      : type === "normal"
      ? NormalSwitch
      : isMobile
      ? SmallSwitch
      : NormalSwitch;

  return (
    <ChartSwitchWrapper>
      <Switch
        onChange={onChange}
        checked={checked}
        offColor={offColor}
        onColor={onColor}
        disabled={disabled}
      />
      <span style={labelStyle}>{label}</span>
    </ChartSwitchWrapper>
  );
};

export default ChartSwitch;
