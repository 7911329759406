import { createGlobalStyle } from "styled-components";

import { gradient_green } from "assets/style/variables";
import { font_demi } from "assets/style/fonts.variables";

export default createGlobalStyle`
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

.ocshad-pay-solid-button {
  color: white;
  outline: none;
  border: 0;
  width: 100%;
  border-radius: 12px;
  background: ${gradient_green};
  text-align: center;
  padding: 15px 0;
  font-family: ${font_demi};
  font-size: 20px;
  transition: all 0.5s ease;
  &:disabled {
    background: #eee !important; color: #aaa;
  }
  &.disabled {
    background: #eee !important; color: #aaa;
  }
}

.oschadpay-list-modal {
  position: absolute;
  z-index: 100000;
  font-family: 'Open-Sans', sans-serif;
}
`;
