import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchYearData } from "store/statistics/stat_actions";
import {
  selectCurrentStatisticsParams,
  selectCurrentStatisticsData,
} from "store/statistics/stat_slicer";
import { STAT_TYPES } from "../../constants";

const useChartState = () => {
  const chartParams = useSelector(selectCurrentStatisticsParams);
  const { selectedTerminals, year } = chartParams;
  const { data } = useSelector(selectCurrentStatisticsData);
  const dispatch = useDispatch();

  const [chartData, setChartData] = useState(data);

  useEffect(() => {
    dispatch(fetchYearData(STAT_TYPES.BY_TERMINAL, year));
  }, [year]);

  useEffect(() => {
    setChartData(
      (selectedTerminals || []).length === 0
        ? data
        : data.filter(({ id }) => selectedTerminals.includes(id))
    );
  }, [selectedTerminals, data]);

  return {
    chartState: chartParams,
    data: chartData,
  };
};

export default useChartState;
