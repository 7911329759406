import styled from "styled-components";

export const LocalLoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff7e;

  .lds-dual-ring {
    display: inline-block;
    width: 120px;
    height: 120px;
    opacity: 1;
  }

  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 11px solid #13877e;
    border-color: #13877e transparent #13877e transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
