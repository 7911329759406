import { CashAPI } from 'api';

import { SET_LOADING, SET_CASH_RECORDS, SET_FILTETING_STATE } from './cash_table.types';

export const getMainCashList = (data) => {
  return async (dispatch) => {
    const server = new CashAPI();

    dispatch(cashSetLoading(true));

    try {
      const result = await server.get();

      if (result.data && result.data.cash && result.data.cash.length !== 0) {
        return dispatch(cashSetRecords(result.data.cash));
      }
      dispatch(cashSetRecords([]));
    } catch (error) {
      console.log(error);
      cashSetRecords([]);
    } finally {
      dispatch(cashSetLoading(false));
      if (data && data.finallyAction) data.finallyAction();
    }
  };
};

export const dropDilteringState = () => {
  return (dispatch) => {
    dispatch(
      cashSetFilteringState({
        terminal_id: null,
        terminal_name: null,
        terminal_description: null,
        device_id: null,
      })
    );
  };
};

export const cashSetLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});

export const cashSetRecords = (records) => ({
  type: SET_CASH_RECORDS,
  payload: records || [],
});

export const cashSetFilteringState = (filtetingState) => ({
  type: SET_FILTETING_STATE,
  payload: filtetingState,
});
