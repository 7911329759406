import React from "react";

const LostPasswordRemainder = () => {
  return (
    <article>
      <h3>Якщо ви втратили свій пароль</h3>
      <p>
        Будь ласка, натисніть кнопку "Забули пароль" та відновіть його за
        номером телефону.
      </p>
    </article>
  );
};

export default LostPasswordRemainder;
