import React from "react";
import TableHeaders from "components/layouts/Table/Headers/Table.headers";
import {
  TableContainer,
  Td,
  TerminalDescribe,
} from "components/layouts/Table/Table.styles";
import { GreenButton, TrCashiersHeader, TrMain } from "./CashiersTable.styles";
import {
  DATE_TYPE,
  SEARCH_TYPE,
  SELECTOR_TYPE,
} from "utils/constants/table.headers.constants";

import {
  Terminal,
  TerminalImage,
  TerminalText,
  TerminalTitle,
} from "pages/Panels/Main/Main.styles";
import { useEffect } from "react";
import {
  fetchServer,
  setItem,
  loadStateServerTerminal,
  setSearchTransactionsVal,
  filterByStatus,
  filterTableCashier,
  resetFilterCashier,
} from "store/cashier_table/cashier_table.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  loadServerTerminal,
  loadLocal,
  stateLocalTerminal,
  transactionVal,
} from "store/cashier_table/cashier_table.slicer";
import Preloader from "components/additional/PreLoader/Preloader";
import { useHistory } from "react-router-dom";
import { CASHIERS_ROUTE } from "utils/constants/routes.constants";
import Refresh from "ui/Refresh/Refresh";

export default function Table() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isServerLoaded = useSelector(loadServerTerminal);
  const isLocalLoaded = useSelector(loadLocal);
  const state = useSelector(stateLocalTerminal);
  const statusVal = useSelector(transactionVal);

  const loginVal = useSelector(
    (state) => state.cashier_table.search_name_cashier
  );
  const phoneVal = useSelector(
    (state) => state.cashier_table.search_phone_cashier
  );
  const lastVal = useSelector(
    (state) => state.cashier_table.search_last_cashier
  );
  const keyExpiresVal = useSelector(
    (state) => state.cashier_table.search_key_expires
  );

  const status_items = ["Активні", "Заблоковані"];

  useEffect(() => {
    dispatch(fetchServer());
  }, [dispatch]);

  if (!isServerLoaded) return <Preloader />;

  if (!isLocalLoaded && state.length === 0)
    return <TerminalTitle>Дані відсутні.</TerminalTitle>;

  function chooseItem(item) {
    dispatch(setItem(item));
    history.push(CASHIERS_ROUTE + "/" + item.app_login);
  }

  function chooseStatus(state) {
    dispatch(setSearchTransactionsVal(state));
    switch (state) {
      case "Активні":
        state = true;
        break;
      case "Заблоковані":
        state = false;
        break;

      default:
        state = null;
        break;
    }

    dispatch(filterByStatus(state));
  }

  const generateStatus = (val) => val === "ACTIVE" || val === "VALIDATED";

  return (
    <>
      <Refresh
        fn={() => {
          dispatch(loadStateServerTerminal());
          dispatch(fetchServer());
        }}
      />
      <TableContainer>
        <thead>
          <TrMain>
            <TableHeaders
              choose_state={loginVal}
              type={SEARCH_TYPE}
              text="Ім’я чи логін касира"
              fn={(val) => dispatch(filterTableCashier("user_name", val))}
              fn_reset={() => dispatch(resetFilterCashier("user_name"))}
            />
            <TableHeaders
              choose_state={keyExpiresVal}
              type={DATE_TYPE}
              text="Термін дії ключа"
              fn={(val) => dispatch(filterTableCashier("key_expires", val))}
              fn_reset={() => dispatch(resetFilterCashier("key_expires"))}
            />
            <TableHeaders
              choose_state={lastVal}
              type={DATE_TYPE}
              text="Останній логін"
              fn={(val) => dispatch(filterTableCashier("last_logon", val))}
              fn_reset={() => dispatch(resetFilterCashier("last_logon"))}
            />
            <TableHeaders
              choose_state={phoneVal}
              type={SEARCH_TYPE}
              text="Телефон"
              fn={(val) => dispatch(filterTableCashier("phone", val))}
              fn_reset={() => dispatch(resetFilterCashier("phone"))}
            />
            <TableHeaders
              state={status_items}
              choose_state={statusVal}
              type={SELECTOR_TYPE}
              fn={chooseStatus}
              fn_reset={() => {
                chooseStatus(null);
              }}
              text="Статус"
            />
          </TrMain>
        </thead>
        <tbody>
          {state.map((item, key) => {
            return (
              <React.Fragment key={key}>
                <TrCashiersHeader>
                  <Td>{item.status}</Td>
                </TrCashiersHeader>
                {item.items.map((cashier) => {
                  const { key_expires, last_logon } = cashier;
                  let key_expires_date = "Ключ не завантажено",
                    key_expires_time = "";

                  if (key_expires) {
                    const keyExpires = key_expires.split(" ");

                    key_expires_date = keyExpires[0];
                    key_expires_time = keyExpires[1];
                  }
                  const [last_logon_date, last_logon_time] =
                    last_logon.split(" ");
                  return (
                    <TrMain
                      key={cashier.client_id}
                      onClick={() => chooseItem(cashier)}
                      column={true}
                    >
                      <Td flex_start={true}>
                        <Terminal>
                          <TerminalImage src={cashier.url} />
                          <TerminalText>
                            <TerminalTitle>{cashier.user_name}</TerminalTitle>
                            <TerminalDescribe>
                              {cashier.app_login}
                            </TerminalDescribe>
                          </TerminalText>
                        </Terminal>
                      </Td>
                      <Td>
                        <TerminalText>
                          <TerminalTitle>{key_expires_date}</TerminalTitle>
                          <TerminalDescribe>
                            {key_expires_time}
                          </TerminalDescribe>
                        </TerminalText>
                      </Td>
                      <Td>
                        <TerminalText>
                          <TerminalTitle>
                            {cashier.last_logon ? last_logon_date : null}
                          </TerminalTitle>
                          <TerminalDescribe>
                            {cashier.last_logon ? last_logon_time : null}
                          </TerminalDescribe>
                        </TerminalText>
                      </Td>
                      <Td>
                        <TerminalText>
                          <TerminalTitle>{cashier.phone}</TerminalTitle>
                        </TerminalText>
                      </Td>
                      <Td>
                        <TerminalText>
                          <GreenButton ok={generateStatus(cashier.status)}>
                            {generateStatus(cashier.status)
                              ? "Активний"
                              : "Заблокований"}
                          </GreenButton>
                        </TerminalText>
                      </Td>
                    </TrMain>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
      </TableContainer>
    </>
  );
}
