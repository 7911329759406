import { DEF_SORT } from "utils/constants/sort.constants";
import {
  TABLE_SET_SERVER,
  TABLE_SET_LOCAL,
  TABLE_SET_SERVER_TERMINAL,
  TABLE_SET_LOCAL_TERMINAL,
  TABLE_LOAD,
  TABLE_SERVER_LOAD,
  TABLE_CHANGE_BUTTON,
  TABLE_CHANGE_DATE,
  TABLE_SORT_TYPE,
  TABLE_SORT_TYPE_TERMINAL,
  TABLE_SORT,
  TABLE_SET_HEADER,
  TABLE_SET_SEARCH,
  TABLE_RESET,
  TABLE_SET_FILTER_TRANSACTION,
  TABLE_SET_FILTER_CARDS,
  TABLE_SET_FILTER_CASH,
  TABLE_SET_FILTER_TOTAL,
  TABLE_SET_FILTER_TRANSACTION_VAL,
  TABLE_SET_FILTER_CARDS_VAL,
  TABLE_SET_FILTER_CASH_VAL,
  TABLE_SET_FILTER_TOTAL_VAL,
  TABLE_SET_SEARCH_VAL,
  TABLE_SET_FILTER_TERMINAL_DESCRIBE,
  TABLE_SET_FILTER_TERMINAL_ID,
  TABLE_SET_FILTER_TERMINAL_NAME,
  TABLE_SET_FILTER_TERMINAL_DEVICE,
  TABLE_SERVER_LOAD_TERMINAL,
  TABLE_SET_FILTER_TERMINAL_ID_VAL,
  TABLE_SET_FILTER_TERMINAL_DEVICE_VAL,
  TABLE_SET_FILTER_TERMINAL_DESCRIBE_VAL,
  TABLE_SET_FILTER_TERMINAL_NAME_VAL,
  TABLE_SET_SEARCH_TERMINAL,
  TABLE_SET_ITEM,
  TABLE_SET_RESPONSE,
  TABLE_SORT_NAME,
  TABLE_SORT_DATE,
  TABLE_SORT_PHONE,
  TABLE_SORT_FISCAL,
  TABLE_SORT_STATUS,
  TABLE_SET_CASHIER,
  TABLE_SET_FILTER_NAME_VAL,
  TABLE_SET_FILTER_LAST_LOGIN_VAL,
  TABLE_SET_FILTER_PHONE_VAL,
  TABLE_SET_FILTER_FISCAL_VAL,
  TABLE_SET_FILTER_KEY_EXPIRES_VAL,
  TABLE_CLEAR_CASHIER_KEY,
} from "./cashier_table.types";

import { groupByStatus } from "./cashier_table.actions";

const initialState = {
  response: [],
  state_server: [],
  state_local: [],
  state_server_terminal: [],
  state_local_terminal: [],
  isLoading: true,
  isInitServer: false,
  isInitServerTerminal: false,
  activeButtonId: 0,
  sortType: DEF_SORT,
  sortTypeTerminal: DEF_SORT,
  sortTypeName: DEF_SORT,
  sortTypeDate: DEF_SORT,
  sortTypePhone: DEF_SORT,
  sortTypeFiscal: DEF_SORT,
  sortTypeStatus: DEF_SORT,
  search_transactions: [],
  search_cards: [],
  search_cash: [],
  search_totals: [],
  search_state_val: null,
  search_transactions_val: null,
  search_cards_val: null,
  search_cash_val: null,
  search_totals_val: null,

  search_terminal: null,
  search_id_terminal: [],
  search_name_terminal: [],
  search_device_terminal: [],
  search_describe_terminal: [],
  search_id_val_terminal: null,
  search_name_val_terminal: null,
  search_device_val_terminal: null,
  search_describe_val_terminal: null,

  item: null,
  search_cashier: [],

  search_name_cashier: null,
  search_phone_cashier: null,
  search_fiscal_cashier: null,
  search_last_cashier: null,
  search_key_expires: null,
};

export default function cashierTableReducer(state = initialState, action) {
  switch (action.type) {
    case TABLE_SET_FILTER_FISCAL_VAL:
      return {
        ...state,
        search_fiscal_cashier: action.payload,
      };
    case TABLE_SET_FILTER_PHONE_VAL:
      return {
        ...state,
        search_phone_cashier: action.payload,
      };
    case TABLE_SET_FILTER_KEY_EXPIRES_VAL:
      return {
        ...state,
        search_key_expires: action.payload,
      };
    case TABLE_SET_FILTER_LAST_LOGIN_VAL:
      return {
        ...state,
        search_last_cashier: action.payload,
      };
    case TABLE_SET_FILTER_NAME_VAL:
      return {
        ...state,
        search_name_cashier: action.payload,
      };
    case TABLE_SET_CASHIER:
      return {
        ...state,
        search_cashier: action.payload,
      };
    case TABLE_SET_RESPONSE:
      return {
        ...state,
        response: action.payload,
      };
    case TABLE_SET_ITEM:
      return {
        ...state,
        item: action.payload,
      };
    case TABLE_SET_SERVER:
      return {
        ...state,
        state_server: action.payload,
      };

    case TABLE_SET_LOCAL:
      return {
        ...state,
        state_local: action.payload,
      };
    case TABLE_SET_SERVER_TERMINAL:
      return {
        ...state,
        state_server_terminal: action.payload,
      };

    case TABLE_SET_LOCAL_TERMINAL:
      return {
        ...state,
        state_local_terminal: action.payload,
      };
    case TABLE_LOAD:
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    case TABLE_SERVER_LOAD:
      return {
        ...state,
        isInitServer: !state.isInitServer,
      };
    case TABLE_SERVER_LOAD_TERMINAL:
      return {
        ...state,
        isInitServerTerminal:
          action.payload === undefined ? true : action.payload, //!state.isInitServerTerminal,
      };
    case TABLE_CHANGE_BUTTON:
      return {
        ...state,
        activeButtonId: action.payload,
      };
    case TABLE_CHANGE_DATE:
      return {
        ...state,
        dates: action.payload,
      };

    case TABLE_SORT_TYPE:
      return {
        ...state,
        sortType: action.payload,
      };
    case TABLE_SORT_TYPE_TERMINAL:
      return {
        ...state,
        sortTypeTerminal: action.payload,
      };

    case TABLE_SORT:
      return {
        ...state,
        state_local: action.payload,
      };
    case TABLE_SORT_NAME:
      return {
        ...state,
        sortTypeName: action.payload,
      };
    case TABLE_SORT_DATE:
      return {
        ...state,
        sortTypeDate: action.payload,
      };
    case TABLE_SORT_PHONE:
      return {
        ...state,
        sortTypePhone: action.payload,
      };
    case TABLE_SORT_FISCAL:
      return {
        ...state,
        sortTypeFiscal: action.payload,
      };
    case TABLE_SORT_STATUS:
      return {
        ...state,
        sortTypeStatus: action.payload,
      };

    case TABLE_SET_HEADER:
      return {
        ...state,
        headers: action.payload,
      };

    case TABLE_SET_SEARCH:
      return {
        ...state,
        search_state: action.payload,
      };
    case TABLE_SET_FILTER_TRANSACTION:
      return {
        ...state,
        search_transactions: action.payload,
      };
    case TABLE_SET_FILTER_CARDS:
      return {
        ...state,
        search_cards: action.payload,
      };
    case TABLE_SET_FILTER_CASH:
      return {
        ...state,
        search_cash: action.payload,
      };
    case TABLE_SET_FILTER_TOTAL:
      return {
        ...state,
        search_totals: action.payload,
      };
    case TABLE_SET_FILTER_TERMINAL_DESCRIBE:
      return {
        ...state,
        search_describe_terminal: action.payload,
      };
    case TABLE_SET_FILTER_TERMINAL_ID:
      return {
        ...state,
        search_id_terminal: action.payload,
      };
    case TABLE_SET_FILTER_TERMINAL_NAME:
      return {
        ...state,
        search_name_terminal: action.payload,
      };
    case TABLE_SET_FILTER_TERMINAL_DEVICE:
      return {
        ...state,
        search_device_terminal: action.payload,
      };
    case TABLE_SET_FILTER_TERMINAL_DESCRIBE_VAL:
      return {
        ...state,
        search_describe_val_terminal: action.payload,
      };
    case TABLE_SET_FILTER_TERMINAL_ID_VAL:
      return {
        ...state,
        search_id_val_terminal: action.payload,
      };
    case TABLE_SET_FILTER_TERMINAL_NAME_VAL:
      return {
        ...state,
        search_name_val_terminal: action.payload,
      };
    case TABLE_SET_FILTER_TERMINAL_DEVICE_VAL:
      return {
        ...state,
        search_device_val_terminal: action.payload,
      };
    case TABLE_SET_SEARCH_TERMINAL:
      return {
        ...state,
        search_terminal: action.payload,
      };
    case TABLE_SET_SEARCH_VAL:
      return {
        ...state,
        search_state_val: action.payload,
      };
    case TABLE_SET_FILTER_TRANSACTION_VAL:
      return {
        ...state,
        search_transactions_val: action.payload,
      };
    case TABLE_SET_FILTER_CARDS_VAL:
      return {
        ...state,
        search_cards_val: action.payload,
      };
    case TABLE_SET_FILTER_CASH_VAL:
      return {
        ...state,
        search_cash_val: action.payload,
      };
    case TABLE_SET_FILTER_TOTAL_VAL:
      return {
        ...state,
        search_totals_val: action.payload,
      };

    case TABLE_RESET:
      return {
        ...state,
        state_local: state.state_server,
      };

    case TABLE_CLEAR_CASHIER_KEY: {
      const cashiers = state.response.map((item) =>
        item.client_id === action.payload
          ? { ...item, key_expires: null }
          : item
      );
      const newState = {
        ...state,
        response: cashiers,
        state_local_terminal: groupByStatus(cashiers),
      };

      return newState;
    }

    default:
      return state;
  }
}

export const loadLocal = (state) => state.cashier_table.isLoading;
export const loadServer = (state) => state.cashier_table.isInitServer;
export const loadServerTerminal = (state) =>
  state.cashier_table.isInitServerTerminal;
export const stateLocal = (state) => state.cashier_table.state_local;
export const stateLocalTerminal = (state) =>
  state.cashier_table.state_local_terminal;
export const activeButton = (state) => state.cashier_table.activeButtonId;
export const sortType = (state) => state.cashier_table.sortType;

export const sortTypeName = (state) => state.cashier_table.sortTypeName;
export const sortTypeDate = (state) => state.cashier_table.sortTypeDate;
export const sortTypePhone = (state) => state.cashier_table.sortTypePhone;
export const sortTypeFiscal = (state) => state.cashier_table.sortTypeFiscal;
export const sortTypeStatus = (state) => state.cashier_table.sortTypeStatus;

export const sortTypeTerm = (state) => state.cashier_table.sortTypeTerminal;
export const headersValues = (state) => state.cashier_table.headers;
export const searchValues = (state) => state.cashier_table.search_state;

export const searchValuesCard = (state) => state.cashier_table.search_cards;
export const searchValuesTransaction = (state) =>
  state.cashier_table.search_transactions;
export const searchValuesCash = (state) => state.cashier_table.search_cash;
export const searchValuesTotal = (state) => state.cashier_table.search_totals;

export const searchValuesId = (state) => state.cashier_table.search_id_terminal;
export const searchValuesName = (state) =>
  state.cashier_table.search_name_terminal;
export const searchValuesDescribe = (state) =>
  state.cashier_table.search_describe_terminal;
export const searchValuesDevice = (state) =>
  state.cashier_table.search_device_terminal;

export const searchValId = (state) =>
  state.cashier_table.search_id_val_terminal;
export const searchValName = (state) =>
  state.cashier_table.search_name_val_terminal;
export const searchValDescribe = (state) =>
  state.cashier_table.search_describe_val_terminal;
export const searchValDevice = (state) =>
  state.cashier_table.search_device_val_terminal;

export const searchValCash = (state) => state.cashier_table.search_cash_val;
export const searchValTotal = (state) => state.cashier_table.search_totals_val;

export const searchTerminalVal = (state) => state.cashier_table.search_terminal;
export const item = (state) => state.cashier_table.item;
export const transactionVal = (state) =>
  state.cashier_table.search_transactions_val;
