import { KeyValue } from "react-key-value";

import { KeyValueListEditor } from "./TagKeyValueListEditor.style";

const TagKeyValueList = ({ keyValueItems, handleKeyValueItems, error }) => {
  return (
    <KeyValueListEditor error={error}>
      <span className="key-value-list-editor-caption">
        Елементи списку (назва - значення)
      </span>
      <KeyValue
        rows={keyValueItems}
        hideLabels
        onChange={handleKeyValueItems}
      />
    </KeyValueListEditor>
  );
};

export default TagKeyValueList;
