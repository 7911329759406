import { useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  HSLToHex,
  getCurrencyFormatter,
} from "pages/Panels/Statistics/helpers";
import { TooltipContainer } from "../../../Chart.styles";

const YAxisFormatter = getCurrencyFormatter(0);
const TooltipFormatter = getCurrencyFormatter(2);

const CustomTooltip = ({ keys, active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <p className="label">{label}</p>
        <ul className="values">
          {keys
            .map((name) => payload.find((item) => item.name === name))
            .filter((item) => item)
            .map(({ name, value, color }) => (
              <li
                key={name}
                style={{ color }}
              >{`${name}: ${TooltipFormatter.format(value)}`}</li>
            ))}
        </ul>
      </TooltipContainer>
    );
  }

  return null;
};

const Chart = ({ data }) => {
  const keySet = data.map(({ name, ...rest }) => rest)[0];
  const keys = keySet ? Object.keys(keySet) : [];
  const tooltipKeys = useMemo(() => keys.toReversed(), [keySet]);

  if (!keySet) return null;

  return (
    <ResponsiveContainer width="100%" height="100%" debounce={500}>
      <LineChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={(value) => YAxisFormatter.format(value)} />
        <Tooltip
          cursor={{ fill: "rgba(0 0 0/0.05)" }}
          content={<CustomTooltip keys={tooltipKeys} />}
        />
        <Legend />
        {keys.map((key, i) => {
          const hue = (360 / keys.length) * i;
          const stroke = HSLToHex(hue, 100, hue > 30 && hue < 90 ? 45 : 50);

          return (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              activeDot={{ r: 8 }}
              stroke={stroke}
              strokeWidth={2}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
