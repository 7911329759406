import styled from "styled-components";

import { font_book } from "assets/style/fonts.variables";

export const VersionBageContainer = styled.div`
  padding: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;

export const VersionText = styled.span`
  color: #000;
  font-size: 14px;
  font-weight: 600;
  font-family: ${font_book};
`;
