import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { getCurrencyFormatter } from "pages/Panels/Statistics/helpers";
import { TRAN_COLORS, TRAN_NAMES } from "../../config";
import { TooltipContainer } from "../../../Chart.styles";

const YAxisFormatter = getCurrencyFormatter(0);
const TooltipFormatter = getCurrencyFormatter(2);

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <p className="label">{label}</p>
        <ul className="values">
          {Object.values(TRAN_NAMES)
            .map((name) => payload.find((item) => item.name === name))
            .filter((item) => item)
            .map(({ name, value, color }) => (
              <li
                key={name}
                style={{ color }}
              >{`${name}: ${TooltipFormatter.format(value)}`}</li>
            ))}
        </ul>
      </TooltipContainer>
    );
  }

  return null;
};

const Chart = ({ data }) => {
  const { card, cash, cashless, qr } = TRAN_COLORS;

  return (
    <ResponsiveContainer width="100%" height="100%" debounce={500}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" width={10} />
        <YAxis tickFormatter={(value) => YAxisFormatter.format(value)} />
        <Tooltip
          cursor={{ fill: "rgba(0 0 0/0.05)" }}
          content={<CustomTooltip />}
        />
        <Bar dataKey={TRAN_NAMES.qr} stackId="a" fill={qr} />
        <Bar dataKey={TRAN_NAMES.cashless} stackId="a" fill={cashless} />
        <Bar dataKey={TRAN_NAMES.cash} stackId="a" fill={cash} />
        <Bar dataKey={TRAN_NAMES.card} stackId="a" fill={card} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
