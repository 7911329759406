import styled from 'styled-components';

export const ErrorComponentContainer = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid rgb(218, 78, 59);
  background-color: rgb(254, 242, 244);
  border-radius: 10px;
  padding: 20px;
  span {
    text-align: center;
    color: rgb(218, 78, 59);
    font-family: 'Futura Demi', sans-serif;
  }
`;
