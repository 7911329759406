import React from "react";

import Preloader from "../PreLoader/Preloader";
import { ReactComponent as DownloadIcon } from "assets/icons/download_green.svg";

import { Button } from "./PreloaderActionButton.styles";

const PreloaderActionButton = ({
  actionTitle,
  inProgressTitle,
  inProgress,
  onClick,
  disabled,
}) => {
  if (disabled) return null;

  const reportsMaker = inProgress ? (
    <div>
      <div className="progress">
        <Preloader
          style={{
            height: "2rem",
            width: "auto",
            marginTop: "0",
          }}
        />
        <span>{inProgressTitle}</span>
      </div>
    </div>
  ) : (
    <Button onClick={onClick} disabled={disabled}>
      <DownloadIcon /> {actionTitle}
    </Button>
  );

  return reportsMaker;
};

export default PreloaderActionButton;
