import axios from "axios";
import { toast } from "utils/toast/toast";

import { URL_SERVER } from "utils/constants/server.constants";
import LocalStorage from "services/LocalStorage/LocalStorage.service";
import Fetch from "services/Fetch/Fetch.service";
import store from "store";
import { dropAlertState, setAlertState } from "store/alert/alert_actions";

export class CashAPI {
  constructor() {
    this.fetch = new Fetch();
    this.localStorage = new LocalStorage();
  }

  async get() {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const url = `${URL_SERVER}/api/TransactionCash/CashSubtotals`;

    return axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });
  }

  async getCashBalanceInTerminal({ terminalId }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const url = `${URL_SERVER}/api/Dfs/GetCashBalance/${terminalId}`;

    return axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });
  }

  async updateSum({ terminalid, amount }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const url = `${URL_SERVER}/api/TransactionCash/ModifyCashBalance`;

    return axios.post(
      url,
      { terminalid, amount },
      {
        headers: {
          "Content-Type": "application/json",
          Token: token,
          DeviceId: "WEB_" + device,
        },
      }
    );
  }

  async inkass({ terminalId, amount, payment_comment }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const url = `${URL_SERVER}/api/TransactionCash/Inkass`;
    const { app_login } = JSON.parse(localStorage.getItem("profile"));

    return axios.post(
      url,
      {
        tapx_login: app_login,
        amount,
        payment_comment,
        ccy: "UAH",
        ptname: terminalId,
        goods: [],
      },
      {
        headers: {
          "Content-Type": "application/json",
          Token: token,
          DeviceId: "WEB_" + device,
        },
      }
    );
  }

  async podkrep({ terminalId, amount, payment_comment }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const url = `${URL_SERVER}/api/TransactionCash/Podkrep`;
    const { app_login } = JSON.parse(localStorage.getItem("profile"));

    return axios.post(
      url,
      {
        tapx_login: app_login,
        amount,
        payment_comment,
        ccy: "UAH",
        ptname: terminalId,
        goods: [],
      },
      {
        headers: {
          "Content-Type": "application/json",
          Token: token,
          DeviceId: "WEB_" + device,
        },
      }
    );
  }

  async cancelCashTransaction({ transactionToken }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const url = `${URL_SERVER}/api/TransactionCash/Cancel/${transactionToken}`;

    return axios.post(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Token: token,
          DeviceId: "WEB_" + device,
        },
      }
    );
  }

  async slujVidacha({ terminalId, amount }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const { client_id } = JSON.parse(localStorage.getItem("profile"));
    const numberAmount = amount.toString().replace(",", ".");

    const url = `${URL_SERVER}/api/Dfs/InkassWithTransaction/${client_id}/${terminalId}/${numberAmount}`;

    return axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });
  }

  static async getFiscalTerminalCash({ terminalId, errorHandler }) {
    const defaultCashErrorMessage =
      "Виникла помилка при спробі взяти суму готівки у касі";
    try {
      const server = new CashAPI();
      const result = await server.getCashBalanceInTerminal({
        terminalId: terminalId,
      });

      if (
        !result ||
        !result.data ||
        !result.data.cash ||
        Number(result.data.error_code) !== 0
      ) {
        const errorMessage = result?.data?.message || defaultCashErrorMessage;

        errorHandler
          ? errorHandler(errorMessage)
          : toast.errorMessage(errorMessage);

        return null;
      }

      return result?.data?.cash;
    } catch (error) {
      errorHandler
        ? errorHandler(defaultCashErrorMessage)
        : toast.errorMessage(defaultCashErrorMessage);

      return null;
    }
  }

  static async makeInkassAction({ terminalId, amount, skipErrorMessage }) {
    const defaultCashErrorMessage =
      "Виникла помилка при спробі зробити службову видачу";

    try {
      const server = new CashAPI();
      const result = await server.slujVidacha({ terminalId, amount });

      if (!result || !result.data || Number(result.data.errorCode) !== 0) {
        return null;
      }

      return result;
    } catch (error) {
      store.dispatch(
        setAlertState({
          show: true,
          type: "block",
          title: "Помилка!",
          subtitle: defaultCashErrorMessage,
          fnClose: () => store.dispatch(dropAlertState()),
        })
      );
      return null;
    }
  }
}
