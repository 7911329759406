import {
  font_medium,
  font_book,
  font_demi,
} from "assets/style/fonts.variables";
import { black, grey } from "assets/style/variables";
import styled from "styled-components";

const TrMain = styled.tr`
  display: grid;
  grid-template-columns: 1fr 4fr 3fr 2fr;
  grid-gap: 6px;
  margin: 12px 0;

  th {
    font-size: 14px;
    font-family: ${font_book};
    color: ${grey};
    font-weight: 400;
  }

  ${(props) =>
    props.column
      ? `
      padding: 17px;
      align-items: center;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      td{
        &:last-child{
            width: 100%;
            p{
                text-align: right;
            }
        }
      }
      justify-items: flex-start;`
      : "null"}
`;

const Terminal = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 22px;
`;

const TerminalText = styled.div`
  font-size: 14px;
  text-align: ${(props) => (props.right ? "right" : "left")};
`;
const TerminalTitle = styled.p`
  color: ${black};
  font-family: ${font_medium};
`;

const TerminalDescribe = styled.p`
  color: ${grey};
  line-height: 20px;
  font-family: ${font_book};
  text-align: ${(props) => (props.right ? "right" : "left")};
`;

const TableTitle = styled.h2`
  color: black;
  font-size: 24px;
  margin-top: 34px;
  font-family: ${font_demi};
`;

export {
  TableTitle,
  TrMain,
  Terminal,
  TerminalText,
  TerminalTitle,
  TerminalDescribe,
};
