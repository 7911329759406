import _ from "lodash";
import { toast } from "react-toastify";

import { NewsAPI } from "api";
import {
  NEWS_SERVER_INIT,
  NEWS_STATE,
  NEWS_PAGINATION,
  NEWS_LOCAL_INIT,
  NEWS_ITEM,
  NEWS_UPDATE_ITEM_IN_CACHE,
  NEWS_DELETE_ITEM_FROM_CACHE,
} from "./news_table.types";

export const loadNews = (messageComponent) => async (dispatch, getState) => {
  new NewsAPI()
    .getMessages()
    .then((res) => {
      const { messages, ErrorCode } = res || {};

      if (ErrorCode !== 0) return;

      const items = (messages || []).map((item) => ({
        ...item,
        id: item.message_id,
      }));

      const currentItems = getState().news_table.state;
      const difference = _.difference(
        items.map((item) => item.id),
        currentItems.map((item) => item.id)
      ).filter((item) => !item.message_read);

      dispatch(setState(items));

      if (difference.length > 0) {
        const MessageComponent = messageComponent;

        for (const id of difference) {
          const item = items.find((item) => item.id === id);

          toast.info(
            messageComponent ? (
              <MessageComponent
                messageId={id}
                messageTitle={item.message_preview}
              />
            ) : (
              item.message_preview
            ),
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      }
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const fetchServer =
  ({
    setTableFull,
    setIsRequestWorking,
    setLocalLoading,
    checkNewItems = true,
    messageComponent,
  } = {}) =>
  async (dispatch, getState) => {
    if (setIsRequestWorking) setIsRequestWorking(true);
    if (setLocalLoading) setLocalLoading(true);
    dispatch(setLocalStatus(false));

    new NewsAPI()
      .getMessages()
      .then((res) => {
        const { messages, ErrorCode } = res || {};

        if (ErrorCode !== 0) return;

        const items =
          messages?.map((item) => ({
            ...item,
            id: item.message_id,
          })) || [];

        if (items.length === 0) {
          if (setTableFull) setTableFull(true);
        }

        if (checkNewItems && items.length > 0) {
          const currentItems = getState().news_table.state;
          const difference = _.difference(
            items.map((item) => item.id),
            currentItems.map((item) => item.id)
          ).filter((item) => !item.message_read);

          if (difference.length > 0) {
            const MessageComponent = messageComponent;

            for (const id of difference) {
              const item = items.find((item) => item.id === id);

              toast.info(
                messageComponent ? (
                  <MessageComponent
                    messageId={id}
                    messageTitle={item.message_preview}
                  />
                ) : (
                  item.message_preview
                ),
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                }
              );
            }
          }
        }

        dispatch(setServerStatus(true));
        dispatch(setLocalStatus(true));
        dispatch(setState(items));

        if (setIsRequestWorking) setIsRequestWorking(false);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        if (setLocalLoading) setLocalLoading(false);
      });
  };

export const setServerStatus = (bool) => ({
  type: NEWS_SERVER_INIT,
  payload: bool,
});

export const setLocalStatus = (string) => ({
  type: NEWS_LOCAL_INIT,
  payload: string,
});

export const setPagination = (num) => ({
  type: NEWS_PAGINATION,
  payload: num,
});

export const setState = (state) => ({
  type: NEWS_STATE,
  payload: state,
});

export const setItem = (state) => ({
  type: NEWS_ITEM,
  payload: state,
});

export const updateItemInCache = (item) => ({
  type: NEWS_UPDATE_ITEM_IN_CACHE,
  payload: item,
});

export const deleteItemFromCache = (item) => ({
  type: NEWS_DELETE_ITEM_FROM_CACHE,
  payload: item,
});
