import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Label, TextFieldContainer } from "./TextField.styles";
import { ReactComponent as PhotoIcon } from "assets/icons/document.svg";
import { ReactComponent as CloseIcon } from "assets/icons/image_close.svg";
import { setAlertState, dropAlertState } from "store/alert/alert_actions";

export default function TextFieldFile({
  fn,
  isEdit,
  avatar_uploaded,
  className,
  text,
  id,
  accept,
  disabled,
}) {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(null);
  const [fileName, setFileName] = useState(null);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function uploadFile(e, accept) {
    try {
      const file = e.target.files[0];
      const extension = file.name.slice(file.name.lastIndexOf("."));

      if (Array.isArray(accept) && !accept.includes(extension)) {
        e.target.value = "";

        return dispatch(
          setAlertState({
            show: true,
            type: "block",
            title: "Увага!",
            subtitle: `Дозволяється завантажувати тільки файли ЕЦП (файли з розширенням ${accept.join(
              ", "
            )})!`,
            fnClose: () => {
              dispatch(dropAlertState());
            },
          })
        );
      }

      const fileName = file.name.slice(0, file.name.lastIndexOf("."));
      const fileShortName =
        fileName.length <= 5
          ? fileName
          : fileName.split("").splice(0, 4).join("") + "...";

      setUrl(URL.createObjectURL(file));
      const base = await toBase64(file);

      setFileName(fileShortName + extension);
      fn(base.split("base64,")[1], file.name);
    } catch (error) {
      console.log(error);
    }
  }

  function fileUploaded() {
    if (avatar_uploaded) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gridGap: "20px",
            marginLeft: "15px",
          }}
        >
          <span className="photo__loaded">Файл завантажено</span>
        </div>
      );
    }
    return null;
  }

  return (
    <TextFieldContainer className={className} disabled={disabled}>
      {isEdit ? (
        <>
          <input
            type="file"
            name="file"
            id={id}
            className={"inputfile"}
            onChange={(e) => uploadFile(e, accept)}
            accept={Array.isArray(accept) ? accept.join(",") : undefined}
            disabled={disabled}
          />
          <Label
            htmlFor={id}
            wide={url !== null}
            style={{
              minHeight: "70px",
              padding: "10px 8px",
              maxWidth: "none",
            }}
          >
            {url ? (
              <>
                <span>
                  {text.toString().slice(0, 1).toUpperCase()}
                  {text.toString().slice(1)} завантажено: {fileName}
                </span>
                <div
                  style={{ padding: "10px", flexShrink: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setUrl(null);
                    fn(null);
                    document.getElementById(id).value = "";
                  }}
                >
                  <CloseIcon className="close-file" />
                </div>
              </>
            ) : (
              <>
                <PhotoIcon />
                <span>{text}</span>
              </>
            )}
          </Label>
        </>
      ) : (
        fileUploaded()
      )}
    </TextFieldContainer>
  );
}
