import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import SubTotal from "components/layouts/SubTotal/SubTotal";
import Table from "pages/Panels/Fiscal/FiscalOpen/FiscalOpen.table";

// redux & functions
import { bOpenTotal, bOpenState } from "store/fiscal_table/fiscal_table.slicer";
import {
  generateHeader,
  bankOpenSetSubtotal,
} from "store/fiscal_table/fiscal_table.actions";

import { FiscalOpenContainer } from "./FiscalOpen.table.styles";

export default function FiscalOpen() {
  const dispatch = useDispatch();
  const total = useSelector(bOpenTotal);
  const state = useSelector(bOpenState);

  useEffect(() => {
    if (state) {
      return dispatch(bankOpenSetSubtotal(generateHeader(total, state)));
    }
  }, []);

  return (
    <FiscalOpenContainer>
      <div className="subtotal">
        <SubTotal state={total} />
      </div>
      <Table />
    </FiscalOpenContainer>
  );
}
