import styled from 'styled-components';

import { font_demi, font_book } from 'assets/style/fonts.variables';

export const ZRecieptPreviewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  padding: 40px;

  .error-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;

    span {
      font-size: 24px;
      font-family: ${font_demi};
      color: #444;
    }
  }

  .loading-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;

    span {
      font-size: 20px;
      font-family: ${font_book};
      color: #444;
    }
  }
`;
