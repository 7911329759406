import { useState, useEffect } from "react";
import {
  Th,
  TableIcon,
  TableInput,
  TableForm,
  TableFormResult,
  TableIconClose,
} from "../GridTable.styles";

import { ReactComponent as CloseIcon } from "assets/icons/close_white.svg";
import CalendarUI from "ui/Calendar/Calendar";
import GenerateDate from "utils/helpers/date.helpers";

export default function TableHeaderDate({
  title,
  fn,
  fn_reset,
  icon,
  choose_state,
  range = true,
}) {
  const [val, setVal] = useState(choose_state === null ? "" : choose_state);
  const [active, setActive] = useState(
    choose_state !== null && choose_state !== ""
  );
  const [showCalendar, setShowCalendar] = useState(false);

  function chooseItem(value) {
    setActive(true);
    const generate = GenerateDate();
    const date = generate.format(range ? value[0].startDate : value);
    setVal(`${date.slice(6, 8)}.${date.slice(4, 6)}.${date.slice(0, 4)}`);
    fn(value);
    setShowCalendar(false);
  }

  function close() {
    fn_reset();
    setActive(false);
    setVal("");
    setShowCalendar(false);
  }

  useEffect(() => {
    if (choose_state === null || choose_state === "") {
      setVal("");
      setActive(false);
    }
  }, [choose_state]);

  return (
    <Th
      active={active}
      onClick={!active ? () => setShowCalendar(true) : () => {}}
    >
      <TableIcon>
        <img src={icon} alt="icon" />
        {showCalendar && (
          <CalendarUI
            fn={chooseItem}
            range={range}
            propShow={showCalendar}
            setPropShow={setShowCalendar}
          />
        )}
      </TableIcon>

      <TableInput
        padding={icon === null}
        placeholder={title}
        value={val}
        disabled={true}
        style={{ background: "white" }}
      />
      <TableForm active={active}>
        <TableFormResult onClick={() => setShowCalendar((v) => !v)}>
          {val}
        </TableFormResult>
        <TableIconClose onClick={close}>
          <CloseIcon />
        </TableIconClose>
      </TableForm>
    </Th>
  );
}
