import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiUpload as ExportIcon } from "react-icons/fi";
import { Transition } from "react-transition-group";
import languageEncoding from "detect-file-encoding-and-language";

import { useHeader } from "store/header/header.context";
import ChangeTitle from "utils/helpers/changetitle.helpers";
import {
  GoodsContainer,
  GoodsHeader,
  ButtonsRow,
  ExportButton,
} from "./Goods.table.styles";
import { ReactComponent as PlusIcon } from "assets/icons/plus-tiffany.svg";
import { ReactComponent as ImportIcon } from "assets/icons/import-tiffany.svg";
import { ButtonAdd } from "../Cashiers/Cashiers.styles";
import { searchVal } from "store/goods_table/goods_table.slicer";
import {
  setSearch,
  setServerStatus,
  fetchServer,
  setPagination,
  setItem,
  dropFilteringState,
  updateFilteringStateItem,
} from "store/goods_table/goods_table.actions";
import { flatCategories } from "./service";
import { setGlobalLoading } from "store/main_table/main_table.actions";
import { GoodsTable as GoodsTableAPI } from "api";
import { useHistory } from "react-router";
import { GOODS_ROUTE } from "utils/constants/routes.constants";

// components
import Alert from "ui/Alert/Alert";
import SearchBox from "components/forms/SearchBox/SearchBox";
import GoodsTypeSwitcher from "./components/GoodsTypeSwitcher/GoodsTypeSwitcher";
import {
  GoodsTypeSwitcherContainer,
  SelectingButton,
} from "./components/GoodsTypeSwitcher/styles";
import GoodsTable from "./components/GoodsTable/GoodsTable";
import CategoriesTable from "./components/CategoriesTable/CategoriesTable";
import { CloseButton } from "./components/CategoriesTable/styles";
import { ReactComponent as CloseIcon } from "assets/icons/transaction/cancel.svg";

const NAME_FIELD_TYPE = "name";
const CATEGORY_FIELD_TYPE = "category";
const CALCULATION_FIELD_TYPE = "calculations";
const INSIDE_CODE_FIELD_TYPE = "inside_code";
const PRICE_CODE_FIELD_TYPE = "price";
const GOODS_MODE = "goods";

let timeoutAdress;

const Goods = () => {
  const header = useHeader();
  const history = useHistory();
  const search_val = useSelector(searchVal);
  const filteringStateFromRedux = useSelector(
    (state) => state.goods_table.filteringState
  );
  const dispatch = useDispatch();

  const [panelMode, setPanelMode] = useState(GOODS_MODE);
  // alert
  const [alertType, setAlertType] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertSubtitle, setAlertSubtitle] = useState("");
  const [triggerReloadGoods, setTriggerReloadGoods] = useState(false);
  const [triggerReloadFilter, setTriggerReloadFilter] = useState(false);

  // goods
  const [goods, setGoods] = useState(null);
  const [selectedGoods, setSelectedGoods] = useState([]);
  const [categories, setCategories] = useState(null);
  const [goodsLocalLoading, setGoodsLocalLoading] = useState(false);
  const [isGoodsTableFull, setGoodsTableFull] = useState(false);
  const [isGoodsRequestWorking, setIsGoodsRequestWorking] = useState(false);
  const [isCreatingMainCategory, setIsCreatingMainCategory] = useState(false);

  const triggerFilterReloadFunction = () => {
    dispatch(setSearch(null));
    dispatch(setServerStatus(false));
    setGoods(null);
    dispatch(setPagination(0));
    setTriggerReloadFilter((val) => !val);
  };

  const INITIAL_FILTERING_STATE = {
    [NAME_FIELD_TYPE]: {
      value: filteringStateFromRedux.name || "",
      placeholder: "Назва товару",
      formatFunction: ({ oldValue, newValue }) => {
        const regex1 = /^.{1,200}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) {
          dispatch(
            updateFilteringStateItem({ field: "name", value: oldValue })
          );
          return oldValue;
        }

        clearTimeout(timeoutAdress);

        timeoutAdress = setTimeout(
          () => {
            triggerFilterReloadFunction();
          },
          !timeoutAdress ? 0 : 1000
        );

        dispatch(updateFilteringStateItem({ field: "name", value: newValue }));
        return newValue;
      },
    },
    [CATEGORY_FIELD_TYPE]: {
      value: filteringStateFromRedux.category || null,
      placeholder: "Категорія",
    },
    [CALCULATION_FIELD_TYPE]: {
      value: filteringStateFromRedux.calculation || null,
      placeholder: "Одиниці виміру",
    },
    [INSIDE_CODE_FIELD_TYPE]: {
      value: filteringStateFromRedux.inside_code || "",
      placeholder: "Внутрішній код",
      formatFunction: ({ oldValue, newValue }) => {
        const regex1 = /^\d{1,50}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) {
          dispatch(
            updateFilteringStateItem({ field: "inside_code", value: oldValue })
          );
          return oldValue;
        }

        clearTimeout(timeoutAdress);

        timeoutAdress = setTimeout(
          () => {
            triggerFilterReloadFunction();
          },
          !timeoutAdress ? 0 : 1000
        );

        dispatch(
          updateFilteringStateItem({ field: "inside_code", value: newValue })
        );
        return newValue;
      },
    },
    [PRICE_CODE_FIELD_TYPE]: {
      value: filteringStateFromRedux.price || "",
      placeholder: "Ціна",
      formatFunction: ({ oldValue, newValue }) => {
        const regex1 = /^\d{1,10}[.]?\d{0,2}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) {
          dispatch(
            updateFilteringStateItem({ field: "price", value: oldValue })
          );
          return oldValue;
        }

        clearTimeout(timeoutAdress);

        timeoutAdress = setTimeout(
          () => {
            triggerFilterReloadFunction();
          },
          !timeoutAdress ? 0 : 1000
        );

        dispatch(updateFilteringStateItem({ field: "price", value: newValue }));
        return newValue;
      },
    },
  };
  const [filteringState, setFilteringState] = useState(INITIAL_FILTERING_STATE);

  // categories
  const [categoriesLocalLoading, setCategoriesLocalLoading] = useState(false);

  useEffect(() => {
    ChangeTitle("Управління товарами", header.dispatch);
  }, []);

  useEffect(() => {
    setIsCreatingMainCategory(false);
  }, [panelMode]);

  function search({ searchValue }) {
    setGoodsTableFull(false);
    dispatch(dropFilteringState());
    setFilteringState(INITIAL_FILTERING_STATE);
    dispatch(setSearch(searchValue));
    dispatch(setServerStatus(false));
    setGoods(null);
    dispatch(setPagination(0));
    dispatch(
      fetchServer({
        setLocalLoading: setGoodsLocalLoading,
        setTableFull: setGoodsTableFull,
        setIsRequestWorking: setIsGoodsRequestWorking,
        filterState: null,
        goods: null,
        setGoods,
      })
    );
  }

  const onSelectFileHandler = async (event) => {
    const file = event.target.files[0];
    const goodsAPI = new GoodsTableAPI();

    const { encoding } = await languageEncoding(file);

    try {
      const result = await goodsAPI.importFile(
        file,
        encoding === "UTF-8" ? "UTF-8" : "windows-1251"
      );

      if (result.data.ErrorCode === 0) {
        setAlertType("done");
        setAlertSubtitle("");
        setAlertTitle("Імпорт данних пройшов успішно");
        setShowAlert(true);
      } else {
        setAlertType("block");
        setAlertTitle("Помилка при імпорті");
        setAlertSubtitle(result.data.ErrorMessage);
        setShowAlert(true);
      }
      setTriggerReloadGoods((val) => !val);
    } catch (e) {
      setAlertType("block");
      setAlertTitle("Помилка при імпорті");

      if (e && e.response && e.response.data && e.response.data.ErrorMessage) {
        setAlertSubtitle(e.response.data.ErrorMessage);
      }
      setShowAlert(true);
    } finally {
      const categoriesResult = await goodsAPI.getAllCategoriesForCreateProd();
      const newCategories = flatCategories(categoriesResult);

      setCategories(newCategories);
      setGoodsLocalLoading(false);
      dispatch(setGlobalLoading(false));
    }
  };

  const createMainCategoryHandler = () =>
    setIsCreatingMainCategory((state) => !state);

  const onExportGoods = async () => {
    const goods = selectedGoods.length ? selectedGoods.join(",") : "";
    const goodsAPI = new GoodsTableAPI();

    setGoodsLocalLoading(true);
    dispatch(setGlobalLoading(true));

    try {
      let result = await goodsAPI.exportGoods({
        format: "CSV",
        ids: goods,
      });

      if (!result) {
        throw new Error();
      }

      const url = window.URL.createObjectURL(result.data);
      const a = document.createElement("a");

      a.href = url;
      a.download = "goods.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      setAlertType("block");
      setAlertTitle("Помилка при експорті товарів");
      setAlertSubtitle("");
      setShowAlert(true);
    } finally {
      setGoodsLocalLoading(false);
      dispatch(setGlobalLoading(false));
    }
  };

  return (
    <>
      {showAlert ? (
        <Alert
          type={alertType}
          title={alertTitle}
          subtitle={alertSubtitle}
          fnClose={() => setShowAlert(false)}
          fnSubmit={() => setShowAlert(false)}
        />
      ) : null}
      <GoodsContainer>
        <GoodsTypeSwitcherContainer>
          <GoodsTypeSwitcher
            panelMode={panelMode}
            setPanelMode={setPanelMode}
          />
          {panelMode !== GOODS_MODE && (
            <SelectingButton
              selected
              onClick={createMainCategoryHandler}
              disabled={isCreatingMainCategory}
            >
              Додати категорію
            </SelectingButton>
          )}
          <Transition
            in={isCreatingMainCategory}
            timeout={400}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            {(state) => (
              <CloseButton
                onClick={() => setIsCreatingMainCategory(false)}
                className={state}
              >
                <CloseIcon />
              </CloseButton>
            )}
          </Transition>
        </GoodsTypeSwitcherContainer>
        {panelMode === GOODS_MODE && (
          <>
            <GoodsHeader>
              <SearchBox
                title="Пошук товару"
                fn_reset={() => {
                  search({ searchValue: null });
                }}
                fn={(val) => search({ searchValue: val })}
                state={search_val}
              />
              <ButtonsRow>
                <ExportButton onClick={onExportGoods}>
                  <ExportIcon className="export-blue" />
                  <p>
                    {"Експорт "}
                    <span className="optional">даних</span>
                  </p>
                </ExportButton>
                <label className="import-button" htmlFor="import-field">
                  <ImportIcon />
                  <p>
                    {"Імпорт "}
                    <span className="optional">даних</span>
                  </p>
                </label>
                <input
                  id="import-field"
                  value=""
                  onChange={onSelectFileHandler}
                  type="file"
                  accept="text/csv"
                  style={{ display: "none" }}
                />
                <ButtonAdd
                  onClick={() => {
                    dispatch(setItem(null));
                    history.push(GOODS_ROUTE + "/add");
                  }}
                >
                  <PlusIcon />
                  <p>
                    {"Додати "}
                    <span className="optional">товар</span>
                  </p>
                </ButtonAdd>
              </ButtonsRow>
            </GoodsHeader>

            <GoodsTable
              goods={goods}
              setGoods={setGoods}
              categories={categories}
              setCategories={setCategories}
              localLoading={goodsLocalLoading}
              setLocalLoading={setGoodsLocalLoading}
              isTableFull={isGoodsTableFull}
              setTableFull={setGoodsTableFull}
              isRequestWorking={isGoodsRequestWorking}
              setIsRequestWorking={setIsGoodsRequestWorking}
              triggerReload={triggerReloadGoods}
              triggerReloadFilter={triggerReloadFilter}
              triggerFilterReloadFunction={triggerFilterReloadFunction}
              filteringState={filteringState}
              setFilteringState={setFilteringState}
              selectedGoods={selectedGoods}
              setSelectedGoods={setSelectedGoods}
            />
          </>
        )}
        {panelMode === "categories" && (
          <>
            <CategoriesTable
              localLoading={categoriesLocalLoading}
              setLocalLoading={setCategoriesLocalLoading}
              isCreatingMainCategory={isCreatingMainCategory}
              setIsCreatingMainCategory={setIsCreatingMainCategory}
            />
          </>
        )}
      </GoodsContainer>
    </>
  );
};

export default Goods;
