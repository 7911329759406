import React, { useEffect, useState } from 'react';

import { TextField } from 'components/forms';
import { EditCategoryContainer, CloseButton } from '../CategoriesTable/styles';

// assets
import { ReactComponent as CloseIcon } from 'assets/icons/transaction/cancel.svg';

const CreateChildrenCategory = (props) => {
  const {
    onCreateChildrenCategory,
    parentCategoryName,
    onClose,
    transitionState,
  } = props;
  const [categoryForm, setCategoryForm] = useState({
    name: {
      value: '',
      caption: `Додати підкатегорію - ${parentCategoryName}`,
      placeholder: 'Назва підкатегорії',
      formatFunc: (oldValue, newValue) => {
        const regex1 = /^.{1,200}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        return newValue;
      },
    },
  });

  useEffect(() => {
    setCategoryForm({
      name: {
        value: '',
        caption: `Додати підкатегорію - ${parentCategoryName}`,
        placeholder: 'Назва підкатегорії',
        formatFunc: (oldValue, newValue) => {
          const regex1 = /^.{1,200}$/;
          const regex2 = /^$/;

          if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

          return newValue;
        },
      },
    });
  }, [parentCategoryName]);

  const setNewInputValueToForm = (field, newValue) => {
    setCategoryForm((categoryForm) => ({
      ...categoryForm,
      [field]: {
        ...categoryForm[field],
        value: categoryForm[field].formatFunc(
          categoryForm[field].value,
          newValue
        ),
      },
    }));
  };

  return (
    <EditCategoryContainer className={transitionState}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <TextField
        name={categoryForm.name.caption}
        placeholder={categoryForm.name.placeholder}
        value={categoryForm.name.value}
        fn={(val) => setNewInputValueToForm('name', val)}
        className='input-active'
      />
      <button
        onClick={() => {
          onCreateChildrenCategory(categoryForm.name.value);
          setCategoryForm({
            name: {
              ...categoryForm.name,
              value: '',
            },
          });
        }}
        className={
          !categoryForm.name.value || categoryForm.name.value === ''
            ? 'disabled'
            : ''
        }
      >
        Додати
      </button>
    </EditCategoryContainer>
  );
};

export default CreateChildrenCategory;
