const qrOperations = ["qr", "qr_refund"];

export const isQrTransaction = (transaction) =>
  qrOperations.includes(transaction?.operation);

export const isQrTranPending = (transaction) => {
  return transaction?.status_transaction === 0;
};

export const generateTransactionStatus = (transaction) => {
  if (!transaction) return 0;

  let resultStatus = transaction.status_transaction || 0;

  if (transaction.status_transaction === 4) {
    resultStatus = "R";
  } else if (transaction.status_transaction === 0) {
    resultStatus = "P";
  } else if (transaction.transaction_state === 3) {
    resultStatus = "B";
  } else if (transaction.force_status === 1) {
    resultStatus = "C";
  } else if (
    !!transaction.reversal_created &&
    transaction.status_transaction === 1
  ) {
    resultStatus = 7;
  } else if (transaction.operation === "cash_inkass") {
    resultStatus = 6;
  } else if (transaction.operation === "cash_podkrep") {
    resultStatus = 5;
  } else if (transaction.refund_amount !== 0) {
    resultStatus = 4;
  } else if (
    transaction.operation === "RRN" ||
    transaction.operation === "cashless_refund" ||
    transaction.operation === "cash_refund" ||
    (transaction.operation === "qr_refund" &&
      transaction.status_transaction === 1)
  ) {
    resultStatus = 3;
  }

  return resultStatus;
};
