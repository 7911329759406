import { BankOpen } from "api";
import { DEF_SORT, ASC_SORT, DESC_SORT } from "utils/constants/sort.constants";
import {
  TABLE_SET_FILTER_TERMINAL_VAL,
  TABLE_OPEN_SET_HEADER,
  TABLE_OPEN_GET_SERVER,
  TABLE_OPEN_LOAD,
  TABLE_OPEN_SET_SEARCH,
  TABLE_CLOSED_GET_SERVER,
  TABLE_CLOSED_SET_SERVER_LOCAL,
  TABLE_CLOSED_LOAD,
  TABLE_CLOSED_SET_SEARCH,
  TABLE_CLOSED_DATE_FROM,
  TABLE_CLOSED_DATE_TO,
  TABLE_DETAIL_ITEM,
  TABLE_SET_FILTER_DATES_FROM,
  TABLE_SET_FILTER_TOTAL,
  TABLE_SET_FILTER_TRANSACTION,
  TABLE_SET_FILTER_BOX,
  TABLE_SET_FILTER_DATES_TO,
  TABLE_SET_FILTER_DATES_FROM_VAL,
  TABLE_SET_FILTER_TOTAL_VAL,
  TABLE_SET_FILTER_TRANSACTION_VAL,
  TABLE_SET_FILTER_BOX_VAL,
  TABLE_SET_FILTER_DATES_TO_VAL,
  TABLE_SORT,
  TABLE_SORT_PACKET,
  TABLE_SORT_DATE_FROM,
  TABLE_SORT_DATE_TO,
  TABLE_SORT_STATUS,
  TABLE_SORT_TRANSACTION,
  TABLE_SORT_TOTAL,
} from "./bank_table.types";

import { getUUID } from "utils/helpers/getUUID";
import { setGlobalLoading } from "store/main_table/main_table.actions";
import { setAlertState, dropAlertState } from "store/alert/alert_actions";

function groupByDate(state) {
  const groups = state.reduce((groups, game) => {
    const date = game.sett_date_time.split(" ")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(game);
    return groups;
  }, {});

  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date,
      items: groups[date],
    };
  });

  return groupArrays;
}

const generateHeader = (state, response) => {
  const initialState = JSON.parse(JSON.stringify(state));

  for (const item of initialState) {
    item.value = 0;
  }

  const updatedState = response.reduce((a, c) => {
    a[0].value += c["count_total"] || 0;
    a[1].value += c["amount_success"] || 0;
    a[2].value += c["amount_rrn"] || 0;
    a[3].value += c["amount_reversed"] || 0;
    a[4].value += c["amount_total"] || 0;

    return a;
  }, initialState);

  return updatedState;
};

export const closedDay = (id) => async (dispatch, getState) => {
  const defaultClosedBankSuccess = "Банківський бізнес-день закрито успішно";
  const server = new BankOpen();
  let { bank_open_state, bank_open_subtotal_state } = getState().bank_table;

  bank_open_state = bank_open_state.filter((item) => item.ptname !== id);

  dispatch(setGlobalLoading(true));

  const result = await server.closedDay(id);

  dispatch(setGlobalLoading(false));

  if (result?.status === 0) {
    dispatch(
      setAlertState({
        show: true,
        title: "Успішно!",
        subtitle: result?.data?.message || defaultClosedBankSuccess,
        type: "done",
        fnSubmit: () => {},
        fnClose: () => dispatch(dropAlertState()),
      })
    );
    dispatch(bankOpenSetState(bank_open_state));
    dispatch(
      bankOpenSetSubtotal(
        generateHeader(bank_open_subtotal_state, bank_open_state)
      )
    );
    dispatch(fetchServerClosed());
  }
};

export const fetchServer =
  (sync = false, val, id) =>
  async (dispatch, getState) => {
    const server = new BankOpen();

    dispatch(bankOpenLoad(true));

    const { bank_open_subtotal_state, bank_open_search_val } =
      getState().bank_table;
    const value = bank_open_search_val ? bank_open_search_val : val;

    server.get(sync, value).then((response) => {
      const subtotals = response?.subtotals || [];

      dispatch(
        bankOpenSetSubtotal(generateHeader(bank_open_subtotal_state, subtotals))
      );
      dispatch(bankOpenSetState(subtotals));
      dispatch(bankOpenLoad(false));

      if (id) {
        const item = subtotals.find(({ ptname }) => ptname === id);

        if (item) {
          dispatch(bankDetailItem(item));
        }
      }
    });
  };

const generationHeadersValues = (response) => (dispatch, getState) => {
  response = response.map((item) => item.items).flat(1);
  const state_date_from = response.map((item) => item.bdd);
  const state_date_to = response.map(
    (item) => item.sett_date_time.split(" ")[0]
  );
  const state_boxes = [...new Set(response.map((item) => item.batch))];
  const state_transactions = response.map((item) => item.count_total);
  const state_total = response.map((item) => item.amount_total);

  dispatch(setSearchDatesFrom(state_date_from));
  dispatch(setSearchDatesTo(state_date_to));
  dispatch(setSearchBox(state_boxes));
  dispatch(setSearchTransaction(state_transactions));
  dispatch(setSearchTotals(state_total));
};

export const fetchServerClosed =
  (sync = false, id, openDate, closeDate) =>
  async (dispatch, getState) => {
    const server = new BankOpen();

    dispatch(bankClosedLoad(true));

    const {
      bank_closed_search_val,
      date_to,
      date_from,
      search_transaction_val,
      search_total_val,
      search_box_val,
      search_terminal_val,
    } = getState().bank_table;

    server
      .get(
        sync,
        bank_closed_search_val,
        date_from,
        date_to,
        search_transaction_val,
        search_total_val,
        search_box_val,
        search_terminal_val
      )
      .then((response) => {
        if (!response?.subtotals) {
          dispatch(bankClosedSetState([]));
          dispatch(bankClosedSetStateLocal([]));
          dispatch(bankClosedLoad(false));
          return;
        }

        response = response.subtotals
          .filter((item) => item.sett_date_time !== null)
          .map((item) => {
            const ctime = item.sett_date_time.split(" ");
            const regex = new RegExp("/", "g");
            const bdd = `${item.bdd.slice(6, 8)}.${item.bdd.slice(
              4,
              6
            )}.${item.bdd.slice(0, 4)}`;

            const date = ctime[0].replace(regex, ".");

            return {
              ...item,
              sett_date_time: `${date} ${ctime[1].slice(0, 5)}`,
              bdd,
            };
          });
        response = groupByDate(response).map((el) => ({
          ...el,
          id: getUUID(),
        }));

        dispatch(bankClosedSetState(response));
        dispatch(bankClosedSetStateLocal(response));
        dispatch(bankClosedLoad(false));

        if (id && openDate && closeDate) {
          const group = response.find(({ date }) => date === openDate);

          if (group) {
            const item = group.items.find(
              ({ ptname, sett_date_time }) =>
                ptname === id && sett_date_time === closeDate
            );

            if (item) {
              dispatch(bankDetailItem(item));
            }
          }
        }
      });
  };

const filterState = (key, val, state) =>
  state
    .map((item) => {
      const items = item.items.filter((trans) => {
        if (key === "sett_date_time") {
          return trans[key].split(" ")[0].toString() === val.toString();
        }
        return trans[key].toString() === val.toString();
      });
      if (items.length > 0) return { date: item.date, items };
      return undefined;
    })
    .filter((item) => item !== undefined);

export const filterTable = (key, val) => (dispatch, getState) => {
  const { bank_closed_state_server } = getState().bank_table;
  let state_local = bank_closed_state_server;
  let filter_state = [];

  switch (key) {
    case "amount_total":
      dispatch(setSearchTotalsVal(val));
      break;
    case "count_total":
      dispatch(setSearchTransactionVal(val));
      break;
    case "bdd":
      dispatch(setSearchDatesFromVal(val));
      break;
    case "sett_date_time":
      dispatch(setSearchDatesToVal(val));
      break;
    case "batch":
      dispatch(setSearchBoxVal(val));
      break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  filter_state = filterState(key, val, state_local).map((el) => ({
    ...el,
    id: getUUID(),
  }));
  dispatch(generationHeadersValues(filter_state));
  dispatch(bankClosedSetState(filter_state));
};

export const resetFilter = (value) => (dispatch, getState) => {
  switch (value) {
    case "amount_total":
      dispatch(setSearchTotalsVal(null));
      break;
    case "count_total":
      dispatch(setSearchTransactionVal(null));
      break;
    case "bdd":
      dispatch(setSearchDatesFromVal(null));
      break;
    case "sett_date_time":
      dispatch(setSearchDatesToVal(null));
      break;
    case "batch":
      dispatch(setSearchBoxVal(null));
      break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  const {
    search_dates_from_val,
    search_dates_to_val,
    search_totals_val,
    search_box_val,
    search_transaction_val,
    bank_closed_state_server,
  } = getState().bank_table;

  let filter_state = bank_closed_state_server;

  if (search_dates_from_val) {
    filter_state = filterState("bdd", search_dates_from_val, filter_state);
  }
  if (search_dates_to_val) {
    filter_state = filterState(
      "sett_date_time",
      search_dates_to_val,
      filter_state
    );
  }
  if (search_totals_val) {
    filter_state = filterState("amount_total", search_totals_val, filter_state);
  }
  if (search_transaction_val) {
    filter_state = filterState(
      "count_total",
      search_transaction_val,
      filter_state
    );
  }
  if (search_box_val) {
    filter_state = filterState("batch", search_box_val, filter_state);
  }
  filter_state =
    filter_state.length !== 0
      ? filter_state.map((el) => ({ ...el, id: getUUID() }))
      : filter_state;

  dispatch(generationHeadersValues(filter_state));
  dispatch(bankClosedSetState(filter_state));
};

export const sortColumn = (key, type, fn) => (dispatch, getState) => {
  const {
    bank_table: { bank_closed_state, bank_closed_state_server },
  } = getState();

  let state_local = bank_closed_state,
    state_server = bank_closed_state_server;

  let sortState, sortType;
  switch (type) {
    case DEF_SORT:
      sortState = state_local.map((item) => {
        const sortItems = item.items.sort((a, b) => (a[key] > b[key] ? 1 : -1));
        return { ...item, items: sortItems };
      });
      sortType = ASC_SORT;
      break;
    case ASC_SORT:
      sortState = state_local.map((item) => {
        const sortItems = item.items.sort((a, b) => (a[key] > b[key] ? -1 : 1));
        return { ...item, items: sortItems };
      });
      sortType = DESC_SORT;
      break;

    default:
      sortState = [...state_server];
      sortType = DEF_SORT;
      break;
  }

  dispatch(fn(sortType));
  dispatch(
    bankClosedSetState([...sortState].map((el) => ({ ...el, id: getUUID() })))
  );
};

export const bankOpenSetSearch = (state) => ({
  type: TABLE_OPEN_SET_SEARCH,
  payload: state,
});
export const bankOpenSetState = (state) => ({
  type: TABLE_OPEN_GET_SERVER,
  payload: state,
});
export const bankOpenSetSubtotal = (state) => ({
  type: TABLE_OPEN_SET_HEADER,
  payload: state,
});
export const bankOpenLoad = (state) => ({
  type: TABLE_OPEN_LOAD,
  payload: state,
});

export const bankClosedSetSearch = (state) => ({
  type: TABLE_CLOSED_SET_SEARCH,
  payload: state,
});
export const bankClosedSetState = (state) => ({
  type: TABLE_CLOSED_GET_SERVER,
  payload: state,
});
export const bankClosedSetStateLocal = (state) => ({
  type: TABLE_CLOSED_SET_SERVER_LOCAL,
  payload: state,
});
export const bankClosedLoad = (state) => ({
  type: TABLE_CLOSED_LOAD,
  payload: state,
});
export const bankDateFrom = (state) => ({
  type: TABLE_CLOSED_DATE_FROM,
  payload: state,
});
export const bankDateTo = (state) => ({
  type: TABLE_CLOSED_DATE_TO,
  payload: state,
});

export const bankDetailItem = (state) => ({
  type: TABLE_DETAIL_ITEM,
  payload: state,
});

export const setSearchDatesFrom = (state) => ({
  type: TABLE_SET_FILTER_DATES_FROM,
  payload: state,
});
export const setSearchDatesTo = (state) => ({
  type: TABLE_SET_FILTER_DATES_TO,
  payload: state,
});
export const setSearchBox = (state) => ({
  type: TABLE_SET_FILTER_BOX,
  payload: state,
});
export const setSearchTransaction = (state) => ({
  type: TABLE_SET_FILTER_TRANSACTION,
  payload: state,
});
export const setSearchTerminalVal = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_VAL,
  payload: state,
});
export const setSearchTotals = (state) => ({
  type: TABLE_SET_FILTER_TOTAL,
  payload: state,
});

export const setSearchDatesFromVal = (state) => ({
  type: TABLE_SET_FILTER_DATES_FROM_VAL,
  payload: state,
});
export const setSearchDatesToVal = (state) => ({
  type: TABLE_SET_FILTER_DATES_TO_VAL,
  payload: state,
});
export const setSearchBoxVal = (state) => ({
  type: TABLE_SET_FILTER_BOX_VAL,
  payload: state,
});
export const setSearchTransactionVal = (state) => ({
  type: TABLE_SET_FILTER_TRANSACTION_VAL,
  payload: state,
});
export const setSearchTotalsVal = (state) => ({
  type: TABLE_SET_FILTER_TOTAL_VAL,
  payload: state,
});

export const setSortType = (state) => ({
  type: TABLE_SORT,
  payload: state,
});
export const setSortTypePacket = (state) => ({
  type: TABLE_SORT_PACKET,
  payload: state,
});
export const setSortTypeDateFrom = (state) => ({
  type: TABLE_SORT_DATE_FROM,
  payload: state,
});
export const setSortTypeDateTo = (state) => ({
  type: TABLE_SORT_DATE_TO,
  payload: state,
});
export const setSortTypeStatus = (state) => ({
  type: TABLE_SORT_STATUS,
  payload: state,
});
export const setSortTypeTransaction = (state) => ({
  type: TABLE_SORT_TRANSACTION,
  payload: state,
});
export const setSortTypeTotal = (state) => ({
  type: TABLE_SORT_TOTAL,
  payload: state,
});
