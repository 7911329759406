import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { GoodsTable } from "api";
import { useHeader } from "store/header/header.context";
import ChangeTitle, {
  ChangeLeft,
  SetMobileBackButton,
} from "utils/helpers/changetitle.helpers";
import { flatCategories } from "../../service";

import Alert from "ui/Alert/Alert";
import { setAlertState, dropAlertState } from "store/alert/alert_actions";
import Preloader from "components/additional/PreLoader/Preloader";
import BackIcon from "ui/BackIcon/BackIcon";
import { SettingPanel } from "assets/style/variables";
import { TextField, TexareaField } from "components/forms";
import SelectComponent from "components/forms/Select/Select";

import { ReactComponent as TrashIcon } from "assets/icons/trash-red.svg";

import {
  CashButtonContainer,
  GoodsDetailContainer,
  DeleteGoodButton,
  GoodNameLine,
  BodyLine,
  BodyLineColumn,
  Image,
  ImageContainer,
  ErrorBlock,
  SimpleBodyLine,
} from "./styles";
import { Button } from "components/forms/Button/Button.styles";

const GOOD_TYPES = [
  { label: "Ваговий", value: true },
  { label: "Поштучний", value: false },
];

const CreateEditGood = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const headerDispatch = useHeader().dispatch;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Видалити товар?");
  const [alertType, setAlertType] = useState("remove");

  const [mode, setMode] = useState(params.id !== "add" ? "view" : "create");

  useEffect(() => {
    if (params.id !== "add") {
      return setMode("view");
    }
    if (params.id === "add") {
      return setMode("create");
    }
  }, [params]);

  const [goodForm, setGoodForm] = useState({
    name: {
      value: null,
      caption: "Назва товару*",
      placeholder: "Додайте назву товару",
      formatFunc: (oldValue, newValue) => {
        const regex1 = /^.{1,120}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        return newValue;
      },
      touched: false,
    },
    type: {
      value: GOOD_TYPES[0],
      caption: "Тип товару*",
      placeholder: "Оберіть тип товару",
      isSelectOpened: false,
      touched: false,
      types: GOOD_TYPES,
    },
    category: {
      value: null,
      caption: "Категорія*",
      placeholder: "Оберіть категорію",
      isSelectOpened: false,
      categoried: [],
      touched: false,
    },
    price: {
      value: null,
      caption: "Ціна*",
      placeholder: "Надайте ціну ₴",
      touched: false,
      formatFunc: (oldValue, newValue) => {
        const regex1 = /^\d{0,10}[.,]?\d{0,2}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        if (
          newValue[0] === "0" &&
          newValue.length !== 1 &&
          newValue[1] !== "." &&
          newValue[1] !== ","
        ) {
          const splittedArray = newValue.split("");
          splittedArray.shift();

          return splittedArray.join("");
        } else if (
          (newValue[0] === "." || newValue[0] === ",") &&
          newValue.length > 1
        ) {
          const splittedArray = newValue.split("");
          splittedArray.unshift("0");

          return splittedArray.join("");
        }

        return newValue.replace(",", ".");
      },
    },
    weight: {
      // hard coded
      value: 10,
      caption: "Вага",
      placeholder: "Додайте вагу",
      touched: false,
      formatFunc: (oldValue, newValue) => {
        const regex1 = /^\d{1,10}[.,]?\d{0,2}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        if (newValue !== "" && Number(newValue) === 0) return oldValue;

        return newValue;
      },
    },
    in_stock: {
      // hard coded
      value: 10,
      caption: "В наявності",
      placeholder: "Надайте кількість",
      touched: false,
      formatFunc: (oldValue, newValue) => {
        const regex1 = /^\d{1,8}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        if (mode === "create" && newValue !== "" && Number(newValue) === 0)
          return oldValue;

        if (
          mode !== "create" &&
          oldValue !== "" &&
          Number(oldValue) === 0 &&
          newValue !== ""
        )
          return oldValue;

        return newValue;
      },
    },
    calculation: {
      value: null,
      caption: "Одиниці виміру*",
      placeholder: "Оберіть",
      isSelectOpened: false,
      calculations: [],
      touched: false,
    },
    PDV_tax: {
      value: null,
      caption: "ПДВ*",
      placeholder: "Оберіть ПДВ",
      isSelectOpened: false,
      pdv_taxes: [],
      touched: false,
    },
    excise_tax: {
      value: null,
      caption: "Акциз*",
      placeholder: "Оберіть акциз",
      isSelectOpened: false,
      excise_taxes: [],
      touched: false,
    },
    description: {
      value: null,
      caption: "Опис товару",
      placeholder: "Додайте опис товару",
      touched: false,
      formatFunc: (oldValue, newValue) => {
        const regex1 = /^.{1,500}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        return newValue;
      },
    },
    barcode: {
      value: null,
      caption: "Код товару",
      placeholder: "Додайте код товару",
      touched: false,
      formatFunc: (oldValue, newValue) => {
        const regex1 = /^.{1,50}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        return newValue;
      },
    },
    internal_code: {
      value: null,
      caption: "Внутрішній код*",
      placeholder: "Додайте внутрішній код",
      touched: false,
      formatFunc: (oldValue, newValue) => {
        const regex1 = /^.{1,50}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        return newValue;
      },
    },
    image: {
      value: "",
      caption: "Фото",
      placeholder: "Введіть посилання на фото",
      touched: false,
      formatFunc: (oldValue, newValue) => {
        const regex1 = /^.{1,1000}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        return newValue;
      },
    },
  });

  const setNewValueToForm = (field, value) => {
    setGoodForm((goodsForm) => ({
      ...goodsForm,
      [field]: {
        ...goodsForm[field],
        value: value,
        touched: true,
      },
    }));
  };

  const setNewInputValueToForm = (field, newValue) => {
    setGoodForm((goodForm) => ({
      ...goodForm,
      [field]: {
        ...goodForm[field],
        value: goodForm[field].formatFunc(goodForm[field].value, newValue),
        touched: true,
      },
    }));
  };

  useEffect(() => {
    const goodsAPI = new GoodsTable();

    setLoading(true);

    if (mode === "create") {
      Promise.all([
        goodsAPI.getUnits(),
        goodsAPI.getTaxes(),
        goodsAPI.getAllCategoriesForCreateProd(),
      ])
        .then((globalRes) => {
          const calculations = globalRes[0].units;
          const taxes = globalRes[1];
          const categories = flatCategories(globalRes[2]);

          const pdv_taxes = taxes.filter(
            (el) => ![1, 8].includes(Number(el.taxesType))
          );
          const excise_taxes = taxes
            .filter(
              (el) =>
                (el.taxesType && Number(el.taxesType) === 0) ||
                Number(el.taxesType) === 1
            )
            .concat({ taxesName: "Не визначено", taxesType: 0 });

          setGoodForm((goodForm) => ({
            ...goodForm,
            calculation: {
              ...goodForm.calculation,
              calculations: calculations,
            },
            PDV_tax: {
              ...goodForm.PDV_tax,
              pdv_taxes: pdv_taxes,
            },
            excise_tax: {
              ...goodForm.excise_tax,
              excise_taxes: excise_taxes,
            },
            category: {
              ...goodForm.category,
              categories: categories,
            },
          }));
        })
        .finally(() => setLoading(false));
    } else {
      Promise.all([
        goodsAPI.getUnits(),
        goodsAPI.getTaxes(),
        goodsAPI.getAllCategoriesForCreateProd(),
        goodsAPI.getOneGoodById(params.id),
      ])
        .then((globalRes) => {
          const calculations = globalRes[0].units;
          const taxes = globalRes[1];
          const categories = flatCategories(globalRes[2]);
          const goodInfo = globalRes[3];

          const pdv_taxes = taxes.filter(
            (el) => ![1, 8].includes(Number(el.taxesType))
          );

          const excise_taxes = taxes
            .filter(
              (el) =>
                (el.taxesType && Number(el.taxesType) === 0) ||
                Number(el.taxesType) === 1
            )
            .concat({ taxesName: "Не визначено", taxesType: 0 });

          const current_pdv_tax_value = pdv_taxes.find(
            (el) => el.taxesId === goodInfo.taxId
          );
          const current_excise_tax_value =
            goodInfo.taxCodes && goodInfo.taxCodes.length === 2
              ? taxes.find(
                  (el) =>
                    el.taxesCode ===
                    goodInfo.taxCodes[goodInfo.taxCodes.length - 1]
                )
              : excise_taxes.find((el) => el.taxesType === 0);

          setGoodForm((goodForm) => ({
            ...goodForm,
            name: {
              ...goodForm.name,
              value: goodInfo.title,
            },
            type: {
              ...goodForm.type,
              value: {
                ...GOOD_TYPES.find((el) => el.value === goodInfo.isBulk),
              },
            },
            category: {
              ...goodForm.category,
              categories: categories,
              value: goodInfo.productMerchantProductToCategories[0]
                ? categories.find(
                    (el) =>
                      el.id ===
                      goodInfo.productMerchantProductToCategories[0].categoryId
                  )
                : null,
            },
            price: {
              ...goodForm.price,
              value: goodInfo.price,
            },
            weight: {
              ...goodForm.weight,
              value: goodInfo.weight,
            },
            calculation: {
              ...goodForm.calculation,
              value: calculations.find((el) => el.unit_id === goodInfo.unitId),
              calculations: calculations,
            },
            PDV_tax: {
              ...goodForm.PDV_tax,
              pdv_taxes: pdv_taxes,
              value: current_pdv_tax_value,
            },
            excise_tax: {
              ...goodForm.excise_tax,
              excise_taxes: excise_taxes,
              value: current_excise_tax_value,
            },
            description: {
              ...goodForm.description,
              value: goodInfo.description,
            },
            image: {
              ...goodForm.image,
              value: goodInfo.image,
            },
            barcode: {
              ...goodForm.barcode,
              value: goodInfo.barcode,
            },
            internal_code: {
              ...goodForm.internal_code,
              value: goodInfo.internalCode,
            },
          }));
        })
        .catch((error) => {
          console.log(error);
          history.push("/dash/goods");
        })
        .finally(() => setLoading(false));
    }

    const newTitle = mode === "create" ? "Додати новий товар" : "Деталі товару";

    ChangeTitle(newTitle, headerDispatch);
    ChangeLeft(headerDispatch);
    SetMobileBackButton(true, headerDispatch);
  }, [params, headerDispatch, mode, history]);

  const calculationDisabled = !goodForm.type.value || mode === "view";
  const priceDisabled =
    !goodForm.type.value || !goodForm.calculation.value || mode === "view";

  const formIsValid =
    goodForm.name.value &&
    goodForm.name.value !== "" &&
    goodForm.type.value &&
    goodForm.category.value &&
    goodForm.price.value &&
    goodForm.price.value !== "" &&
    goodForm.calculation.value &&
    goodForm.PDV_tax.value &&
    goodForm.excise_tax.value &&
    goodForm.internal_code.value &&
    goodForm.internal_code.value !== "";

  if (loading) return <Preloader />;

  if (error)
    return (
      <ErrorBlock>
        <span>{error}</span>
        <button onClick={() => setError(null)}>OK</button>
      </ErrorBlock>
    );

  return (
    <>
      <div style={{ position: "relative" }}>
        {showAlert ? (
          <Alert
            type={alertType}
            title={alertTitle}
            fnClose={() => setShowAlert(false)}
            fnSubmit={deleteGood}
            btn_close="видалити"
          />
        ) : null}
        {mode !== "create" && (
          <DeleteGoodButton
            onClick={() => {
              setAlertType("remove");
              setAlertTitle("Ви впевнені, що бажаєте видалити товар?");
              setShowAlert(true);
            }}
          >
            <TrashIcon />
            <span>Видалити</span>
          </DeleteGoodButton>
        )}
        <GoodsDetailContainer>
          <SettingPanel line isFlex={true}>
            <GoodNameLine>
              <TextField
                name={goodForm.name.caption}
                placeholder={goodForm.name.placeholder}
                value={goodForm.name.value}
                fn={(val) => setNewInputValueToForm("name", val)}
                className="input-active"
                error={
                  (!goodForm.name.value || goodForm.name.value === "") &&
                  goodForm.name.touched
                }
                disabled={mode === "view"}
              />
            </GoodNameLine>
            <SimpleBodyLine>
              <BodyLineColumn>
                <SelectComponent
                  data={{
                    caption: goodForm.calculation.caption,
                    placeholder: goodForm.calculation.placeholder,
                    selectedCaption: goodForm.calculation.value?.unit_name,
                    disabled: calculationDisabled || mode === "view",
                    onSelectItem: (item) =>
                      setNewValueToForm("calculation", item),
                    listInfo: {
                      items: goodForm.calculation.calculations,
                      data_id: "unit_id",
                      data_uk: "unit_uk",
                      data_caption: "unit_name",
                    },
                    style: {
                      width: "100%",
                      overflowY: "hide",
                      margin: "13px 0",
                      padding: "11px 36px 11px 16px",
                    },
                  }}
                />
              </BodyLineColumn>
              <BodyLineColumn>
                <SelectComponent
                  data={{
                    caption: goodForm.category.caption,
                    placeholder: goodForm.category.placeholder,
                    selectedCaption: goodForm.category.value?.name,
                    disabled: mode === "view",
                    onSelectItem: (item) => setNewValueToForm("category", item),
                    listInfo: {
                      items: goodForm.category?.categories,
                      data_id: "id",
                      data_uk: "name",
                      data_caption: "name",
                    },
                    renderingType: "category",
                    style: {
                      width: "100%",
                      overflowY: "hide",
                      margin: "13px 0",
                      padding: "11px 36px 11px 16px",
                    },
                  }}
                />
              </BodyLineColumn>
              <BodyLineColumn>
                <TextField
                  name={goodForm.price.caption}
                  placeholder={goodForm.price.placeholder}
                  value={goodForm.price.value}
                  fn={(val) => setNewInputValueToForm("price", val)}
                  className="input-active"
                  error={
                    (!goodForm.price.value || goodForm.price.value === "") &&
                    goodForm.price.touched
                  }
                  disabled={priceDisabled}
                />
              </BodyLineColumn>
              <BodyLineColumn>
                <TextField
                  name={goodForm.internal_code.caption}
                  placeholder={goodForm.internal_code.placeholder}
                  value={goodForm.internal_code.value}
                  fn={(val) => setNewInputValueToForm("internal_code", val)}
                  className="input-active"
                  error={
                    (!goodForm.internal_code.value ||
                      goodForm.internal_code.value === "") &&
                    goodForm.internal_code.touched
                  }
                  disabled={mode === "view"}
                />
              </BodyLineColumn>
              <BodyLineColumn>
                <SelectComponent
                  data={{
                    caption: goodForm.PDV_tax.caption,
                    placeholder: goodForm.PDV_tax.placeholder,
                    selectedCaption: goodForm.PDV_tax.value?.taxesName,
                    disabled: mode === "view",
                    onSelectItem: (item) => setNewValueToForm("PDV_tax", item),
                    listInfo: {
                      items: goodForm.PDV_tax?.pdv_taxes,
                      data_id: "taxesName",
                      data_uk: "taxesName",
                      data_caption: "taxesName",
                    },
                    style: {
                      width: "100%",
                      overflowY: "hide",
                      margin: "13px 0",
                      padding: "11px 36px 11px 16px",
                    },
                  }}
                />
              </BodyLineColumn>
              <BodyLineColumn>
                <SelectComponent
                  data={{
                    caption: goodForm.excise_tax.caption,
                    placeholder: goodForm.excise_tax.placeholder,
                    selectedCaption: goodForm.excise_tax.value?.taxesName,
                    disabled: mode === "view",
                    onSelectItem: (item) =>
                      setNewValueToForm("excise_tax", item),
                    listInfo: {
                      items: goodForm.excise_tax?.excise_taxes,
                      data_id: "taxesName",
                      data_uk: "taxesName",
                      data_caption: "taxesName",
                    },
                    style: {
                      width: "100%",
                      overflowY: "hide",
                      margin: "13px 0",
                      padding: "11px 36px 11px 16px",
                    },
                  }}
                />
              </BodyLineColumn>
            </SimpleBodyLine>
            <BodyLine column>
              <TextField
                name={goodForm.barcode.caption}
                placeholder={goodForm.barcode.placeholder}
                value={goodForm.barcode.value}
                fn={(val) => setNewInputValueToForm("barcode", val)}
                className="input-active"
                disabled={mode === "view"}
              />
              <TexareaField
                name={goodForm.description.caption}
                placeholder={goodForm.description.placeholder}
                value={goodForm.description.value}
                fn={(val) => setNewInputValueToForm("description", val)}
                className="input-active"
                disabled={mode === "view"}
              />
              <TextField
                name={goodForm.image.caption}
                placeholder={goodForm.image.placeholder}
                value={goodForm.image.value}
                fn={(val) => setNewInputValueToForm("image", val)}
                className="input-active"
                disabled={mode === "view"}
              />
              {goodForm.image.value && (
                <ImageContainer>
                  <Image src={goodForm.image.value} />
                </ImageContainer>
              )}
            </BodyLine>
            <span className="required-paragraph">
              * - поля обов'язкові для заповнення
            </span>
          </SettingPanel>
          <CashButtonContainer>
            {mode === "view" && (
              <Button onClick={() => setMode("edit")}>{"Редагувати"}</Button>
            )}
            {mode === "create" && (
              <Button onClick={() => onCreateNewGood()} disabled={!formIsValid}>
                {"Додати"}
              </Button>
            )}
            {mode === "edit" && (
              <Button onClick={() => onEditGood()} disabled={!formIsValid}>
                {"Зберегти"}
              </Button>
            )}
          </CashButtonContainer>
        </GoodsDetailContainer>
      </div>
      <BackIcon className="default" />
    </>
  );

  function createGood(goodsAPI, body) {
    goodsAPI
      .createGood(body)
      .then((res) => {
        if (res.errorCode !== 0) {
          setError(res.errorMessage);
        } else {
          history.push({
            pathname: "/dash/goods",
            state: {
              successCreated: true,
            },
          });
        }
      })
      .catch((error) => {
        setError("Виникла помилка створення товару");
        console.log(error);
      });
  }

  function onCreateNewGood() {
    const goodsAPI = new GoodsTable();

    let taxCodes = [];
    if (goodForm.PDV_tax.value.taxesType !== 0) {
      taxCodes.push(goodForm.PDV_tax.value.taxesCode);
    }
    if (goodForm.excise_tax.value.taxesType !== 0) {
      taxCodes.push(goodForm.excise_tax.value.taxesCode);
    }
    taxCodes = taxCodes.join("");

    const body = {
      barcode:
        goodForm.barcode.value && goodForm.barcode.value !== ""
          ? goodForm.barcode.value
          : "-",
      internalCode: goodForm.internal_code.value,
      title: goodForm.name.value,
      description: goodForm.description.value ? goodForm.description.value : "",
      price: goodForm.price.value,
      weight: goodForm.weight.value,
      volume: "0",
      image: goodForm.image.value,
      availableQuantity: goodForm.in_stock.value,
      isBulk: goodForm.type.value.value,
      params: "0",
      taxId: goodForm.PDV_tax.value.taxesId,
      taxCodes: taxCodes,
      unitId: goodForm.calculation.value.unit_id,
      productMerchantProductToCategories: [
        {
          categoryId: goodForm.category.value.id,
        },
      ],
    };

    setLoading(true);

    goodsAPI
      .getGoodsByName(goodForm.name.value)
      .then(({ data }) => {
        if (data?.length > 0) {
          dispatch(
            setAlertState({
              show: true,
              type: "remove",
              title: "Увага!",
              subtitle: `Товар з назвою "${goodForm.name.value}" вже існує у вашому Довіднику.
                Додати і цей товар також?`,
              fnClose: () => dispatch(dropAlertState()),
              fnSubmit: () => {
                dispatch(dropAlertState());
                createGood(goodsAPI, body);
              },
              acceptButtonLabel: "Додати",
              cancelButtonLabel: "Скасувати",
            })
          );
        } else {
          createGood(goodsAPI, body);
        }
      })
      .catch((message) => console.error(message))
      .finally(() => setLoading(false));
  }

  function onEditGood() {
    const goodsAPI = new GoodsTable();

    let taxCodes = [];
    if (goodForm.PDV_tax.value.taxesType !== 0) {
      taxCodes.push(goodForm.PDV_tax.value.taxesCode);
    }
    if (goodForm.excise_tax.value.taxesType !== 0) {
      taxCodes.push(goodForm.excise_tax.value.taxesCode);
    }
    taxCodes = taxCodes.join("");

    const body = {
      barcode:
        goodForm.barcode.value && goodForm.barcode.value !== ""
          ? goodForm.barcode.value
          : "-",
      internalCode: goodForm.internal_code.value,
      title: goodForm.name.value,
      description: goodForm.description.value ? goodForm.description.value : "",
      price: goodForm.price.value,
      weight: goodForm.weight.value,
      volume: "0",
      image: goodForm.image.value,
      availableQuantity: goodForm.in_stock.value,
      isBulk: false,
      params: "0",
      taxId: goodForm.PDV_tax.value.taxesId,
      taxCodes: taxCodes,
      unitId: goodForm.calculation.value.unit_id,
      productMerchantProductToCategories: [
        {
          categoryId: goodForm.category.value.id,
        },
      ],
    };

    setLoading(true);

    goodsAPI
      .updateGood({ body, id: params.id })
      .then((res) => {
        if (res.errorCode !== 0) {
          setLoading(false);
          setError(res.errorMessage);
        } else {
          history.push({
            pathname: "/dash/goods",
            state: {
              successChanged: true,
            },
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Виникла помилка редагування товару");
      });
  }

  function deleteGood() {
    const goodsAPI = new GoodsTable();

    setLoading(true);

    goodsAPI
      .deleteGood({ id: params.id })
      .then(() => {
        history.push({
          pathname: "/dash/goods",
          state: {
            successDeleted: true,
          },
        });
      })
      .catch(() => setError("Виникла помилка видалення товару"))
      .finally(() => setLoading(false));
  }
};

export default CreateEditGood;
