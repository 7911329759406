import { useSelector } from "react-redux";
import { Select } from "components/forms";
import { ChartToolbarContainer } from "./ChartToolbar.styles";
import { selectCurrentStatisticsData } from "store/statistics/stat_slicer";

const ChartToolbar = ({ year, setYear, years }) => {
  const { isLoading } = useSelector(selectCurrentStatisticsData);

  return (
    <ChartToolbarContainer>
      <Select
        options={years}
        placeholder="рік..."
        value={year}
        onChange={setYear}
        width={"120px"}
        isDisabled={isLoading}
      />
    </ChartToolbarContainer>
  );
};

export default ChartToolbar;
