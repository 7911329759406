import { useState, useEffect } from "react";

const useEditTag = ({
  template,
  onStartEditTagCallback,
  onCancelEditTagCallback,
}) => {
  const [tag, setTag] = useState(null);

  useEffect(() => {
    if (tag) {
      onStartEditTagCallback();
    } else {
      onCancelEditTagCallback();
    }
  }, [tag, onStartEditTagCallback, onCancelEditTagCallback]);

  const onStartEditTag = (index) => {
    setTag(template.tags[index]);
  };

  const onCancelEditTag = () => {
    setTag(null);
  };

  const onFinishEditTag = () => {
    setTag(null);
  };

  return { tag, onStartEditTag, onCancelEditTag, onFinishEditTag };
};

export default useEditTag;
