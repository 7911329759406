import { tiffany } from "assets/style/variables";
import styled from "styled-components";
import { ButtonSwitcher } from "../Bank/Bank.styles";

export const CashiersContainer = styled.section`
  margin: 0;
`;

export const CashiersHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ButtonAdd = styled(ButtonSwitcher)`
  padding: 14px 0;
  max-width: 163px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  justify-content: center;
  .plus-blue {
    margin-right: 10px;
    font-size: 22px;
    color: ${tiffany};
  }
`;
