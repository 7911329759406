import styled from "styled-components";

import { font_book, font_demi } from "assets/style/fonts.variables";
import { black, grey } from "assets/style/variables";
import { size } from "assets/style/global-variables";

export const AlertContainer = styled.section`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100003;
  background: rgba(112, 120, 127, 0.2);
`;
export const AlertBlock = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  position: absolute;
  max-height: ${(props) => (props.height ? props.height : "fit-content")};
  min-height: 420px;
  max-width: ${(props) => (props.width ? props.width : "420px")};
  min-width: 375px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  overflow: auto;

  li > span.amount {
    text-align: right;
  }

  @media screen and (max-width: ${size.tablet}) {
    max-width: 350px;
    max-height: calc(100% - 50px);
    height: min-content;
    padding: 18px;
  }

  @media screen and (max-width: ${size.mobileM}) {
    max-width: 300px;
    max-height: calc(100% - 50px);
    height: min-content;
  }
`;

export const AlertTitle = styled.p`
  color: ${black};
  font-size: 28px;
  font-family: ${font_demi};

  @media screen and (max-width: ${size.tablet}) {
    margin: 10px 0;
    font-size: 20px;
  }
`;
export const AlertSubTitle = styled.div`
  font-family: ${font_book};
  color: ${grey};
  font-size: 18px;
  word-break: break-word;

  span {
    display: block;
    margin-top: 1rem;
    text-align: justify;
    text-indent: 2rem;
  }

  &.warning {
    color: rgb(156, 10, 0);
  }

  &.info {
    align-self: stretch;
    overflow: auto;

    p {
      text-indent: 2rem;
      text-align: justify;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }

  @media screen and (max-width: ${size.tablet}) {
    font-size: 16px;
  }
`;

export const AlertList = styled.ul`
  list-style: none;
  flex: 1;
  align-self: stretch;
  max-height: 7rem;
  overflow-y: auto;
  font-family: ${font_book};
  color: ${grey};
  font-size: 16px;
  text-align: left;

  &.warning {
    color: rgb(156, 10, 0);
  }

  @media screen and (max-width: ${size.tablet}) {
    font-size: 14px;
  }
`;

export const AlertButtonContainer = styled.section`
  display: flex;
  grid-gap: 17px;
  margin-top: 40px;
  justify-content: space-between;
  width: 100%;
  .font-bold {
    font-size: 20px;
    font-family: ${font_demi};
  }

  @media screen and (max-width: ${size.tablet}) {
    margin-top: 20px;
    * {
      font-size: 16px;
    }
  }
`;
