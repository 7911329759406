import { SessionStorage } from "services";
import { bankDetailItem } from "store/bank_table/bank_table.actions";
import { setBankFilteringState } from "store/transaction_table/transaction_table.actions";

import { BANK_DAY_ROUTE } from "utils/constants/routes.constants";

export function toDetailPage(item, status, dispatch, history) {
  const ss = new SessionStorage();
  const { bdd, batch, ptname, sett_date_time } = item;

  ss.set("bdd", bdd);
  ss.set("batch", batch);
  localStorage.setItem("bank-bdd", bdd);
  localStorage.setItem("bank-ptname", ptname);
  localStorage.setItem("bank-batch", batch);
  dispatch(setBankFilteringState({ field: "ptname", value: ptname }));
  dispatch(setBankFilteringState({ field: "bdd", value: bdd }));
  dispatch(setBankFilteringState({ field: "batch", value: batch }));
  dispatch(bankDetailItem(item));
  localStorage.removeItem("bank_exit_with_drop_state");

  history.push({
    pathname: BANK_DAY_ROUTE + "/" + ptname,
    state: { status, openDate: bdd, closeDate: sett_date_time },
  });
}
