import styled from "styled-components";

import { white, black, mouse_pointer } from "assets/style/variables";
import { font_book } from "assets/style/fonts.variables";
import { size } from "assets/style/global-variables";

const rounding = `
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  background: radial-gradient(circle, transparent 94px, white 100px) 100px
    100px / 200px 200px;
  transform: scale(0.3);
`;

const NavContainer = styled.section`
  .animation-text-show {
    .icon-container,
    .link span {
      animation: show 1s ease forwards;
    }
  }
  .animation-text {
    .link,
    .link-small::after,
    .link-small::before,
    .icon-container,
    .link span {
      animation: hide 0.45s ease forwards;
    }
  }
  .min-menu {
    .link {
      animation: show 3s ease forwards;
    }
    animation: width 0.45s ease forwards;

    .link-small:hover::after,
    .link-small:hover::before,
    .link-small.active::after,
    .link-small.active::before,
    .icon-container,
    .link span {
      display: none;
    }
    img {
      margin: 0;
      width: 46px;
      margin-bottom: 30px;
      ${mouse_pointer}
    }

    .link {
      padding: 15px 0;
      padding-right: 0;
      padding-left: 11px;
    }

    .link:hover::after,
    .link.active::after {
      transform: scale(0.2);
    }
    .link:hover::after,
    .link.active::after {
      bottom: 25%;
      left: -7%;
    }
    .link:hover::before,
    .link.active::before {
      top: 26%;
      left: -8%;
      transform: rotate(270deg) scale(0.2);
    }
    .link-small.active {
      background: none;
    }
    .link-small.active div {
      background: ${white};
    }
    .link-small {
      padding: 17px 10px;
      &:hover {
        background: none;
        .icon {
          background: ${white};
        }
      }
    }
    div:nth-child(2) {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: ${size.laptop}) {
    display: none;
  }
`;

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  min-height: 100vh;
  padding: 46px 0;
  padding-left: 27px;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  position: sticky;
  top: 0;
  /* overflow-y: auto;
  overflow-x: hidden; */

  .news {
    position: relative;
    max-height: 22.5px;

    .news-count {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      background-color: orange;
      width: 1.15rem;
      height: 1.15rem;
      border-radius: 50%;
      font-size: 0.65rem;
      font-weight: bold;
      line-height: 1.15rem;
      text-align: center;
      transform: translate(-11px, -30%);
      color: ${white};
    }
  }

  .icon {
    margin-right: 18px;
    filter: brightness(1) invert(1);

    &.news-icon {
      color: inherit;
      filter: none;
      font-size: 1.5rem;
    }
  }
  .link {
    display: flex;
    align-items: center;
    color: ${white};
    text-decoration: none;
    padding: 12px 18px;
    font-weight: 400;
    font-size: 18px;
    font-family: ${font_book};
    white-space: pre;
    position: relative;
  }
  .link:hover {
    background-color: ${white};
    color: ${black};
    border-radius: 12px 0 0 12px;
    transition: background-color 0.5s ease;
  }
  .link:hover .icon {
    filter: brightness(0);
  }
  .link::after {
    ${rounding}
    bottom: 25%;
    right: -14%;
    opacity: 0;
  }
  .link::before {
    ${rounding}
    opacity: 0;
    top: 25%;
    right: -14%;
    transform: rotate(270deg) scale(0.3);
  }

  .link:hover::after,
  .link:hover::before {
    animation: sheen 0.45s ease forwards;
  }

  .link-small:hover::after,
  .link-small:hover::before,
  .link-small.active::after,
  .link-small.active::before {
    right: -12%;
  }
  .link-small:hover::after,
  .link-small.active::after {
    bottom: 12%;
  }
  .link-small:hover::before,
  .link-small.active::before {
    top: 15%;
  }

  .link-small {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    width: 130%;
  }

  @keyframes sheen {
    100% {
      opacity: 1;
    }
  }

  @keyframes width {
    0% {
      width: 289px;
    }
    100% {
      width: 64px;
    }
  }
  @keyframes hide {
    100% {
      opacity: 0;
    }
  }
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const TopMenu = styled.div`
  margin: 0;
`;

const BottomMenu = styled.div`
  margin: 0;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  .active {
    background-color: ${white};
    color: ${black};
    border-radius: 12px 0 0 12px;
    .icon {
      filter: brightness(0) invert(0);
    }
    &::after,
    &::before {
      opacity: 1;
    }
    &::after {
      ${rounding}
      bottom: 25%;
      right: -14%;
    }
    &::before {
      ${rounding}
      top: 25%;
      right: -14%;
      transform: rotate(270deg) scale(0.3);
    }
  }
`;

const Logo = styled.img`
  width: 60px;
`;

const LogoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-left: 20px;
  .icon-container {
    background-color: rgba(0, 75, 69, 0.5);
    padding: 7px 5px;
    border-radius: 20px 0 0 20px;
    ${mouse_pointer}
  }
  svg {
    filter: brightness(0) invert(1);
    transform: rotate(180deg);
  }

  ${(props) =>
    props.isMinimize
      ? `
    margin-left: 0;
    margin-right: 20px;
  `
      : ""}
`;

export const LogoAndVersion = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const Circle = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  background: rgba(207, 228, 226, 1);
  width: 7px;
  height: 7px;
  border-radius: 50%;
  ${(props) => (props.show ? "animation: show 1s ease forwards;" : "")}
  &:hover {
    cursor: pointer;
    background: ${white};
  }
`;

export {
  NavContainer,
  Circle,
  LogoContainer,
  Nav,
  TopMenu,
  BottomMenu,
  Logo,
  List,
};
