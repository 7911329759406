import { Select } from "components/forms";
import { ChartSwitch } from "pages/Panels/Statistics/components";
import { ChartToolbarContainer } from "./ChartToolbar.styles";
import { STAT_BY_QUARTERS } from "pages/Panels/Statistics/constants";
import { grey, grey_off } from "assets/style/variables";
import { CHART_SWITCH_LABEL_NORMAL_STYLE } from "pages/Panels/Statistics/Statistics.styles";

const ChartToolbar = ({
  beginYear,
  setBeginYear,
  endYear,
  setEndYear,
  years,
  quarterly,
  setQuarterly,
  isLoading,
}) => {
  return (
    <ChartToolbarContainer>
      <Select
        options={years}
        placeholder="початковий рік..."
        label={"Початковий рік"}
        value={beginYear}
        onChange={(value) => {
          if (value?.value > endYear?.value) return;

          setBeginYear(value);
        }}
        width={"120px"}
        isDisabled={isLoading}
      />
      <Select
        options={years}
        placeholder="кінцевий рік..."
        label={"Кінцевий рік"}
        value={endYear}
        onChange={(value) => {
          if (value?.value < beginYear?.value) return;

          setEndYear(value);
        }}
        width={"120px"}
        isDisabled={isLoading}
      />
      <ChartSwitch
        onChange={setQuarterly}
        checked={quarterly}
        offColor={grey_off}
        onColor={grey}
        label={STAT_BY_QUARTERS}
        labelStyle={CHART_SWITCH_LABEL_NORMAL_STYLE}
        disabled={isLoading}
      />
    </ChartToolbarContainer>
  );
};

export default ChartToolbar;
