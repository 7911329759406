import React from "react";

import NewsDetailsLink from "./NewsDetailsLink";
import { NEWS_ROUTE } from "utils/constants/routes.constants";

const MessagePopupComponent = ({ history, messageId, messageTitle }) => {
  return (
    <NewsDetailsLink
      title={messageTitle}
      onClick={() => history?.push(`${NEWS_ROUTE}/${messageId}`)}
    />
  );
};

export default MessagePopupComponent;
