import styled from "styled-components";

import {
  grey,
  grey_light,
  black,
  tiffany,
  white,
  mouse_pointer,
} from "assets/style/variables";
import { font_demi, font_book } from "assets/style/fonts.variables";
import { size } from "assets/style/global-variables";

const AuthContainer = styled.div`
  display: grid;
  grid-template-columns: 55% 45%;
  @media screen and (max-width: ${size.laptop}) {
    grid-template-columns: 100%;
    padding: 0 10px;
  }
  width: 100vw;
  height: 100vh;
  align-items: center;
  outline: none;

  .login {
    margin-top: 20px;
  }

  .forgot-password {
    display: flex;
    justify-content: center;
    margin: 30px auto;
    ${mouse_pointer}

    @media screen and (max-width: ${size.laptop}) {
      margin: 20px auto;
    }
  }

  .block-icon {
    width: 16px;
    height: auto;
    path {
      fill: ${tiffany};
    }
  }

  .inform {
    display: flex;
    grid-gap: 5px;
    justify-content: center;
  }
  .ring {
    height: 44px;
  }
  .ring div {
    width: 44px;
    height: 44px;
  }
`;
const Form = styled.div`
  padding: 40px;
  background: white;
`;

const Logo = styled.img`
  max-width: 100px;
`;
const ImageBackground = styled.img`
  height: 100vh;
  width: 55vw;
  object-fit: cover;
  @media screen and (max-width: ${size.laptop}) {
    display: none;
  }
`;

const FormContainer = styled.div`
  width: 80%;
  margin: 0 auto;

  @media screen and (max-width: ${size.laptop}) {
    width: 100%;
  }
`;

const FormContainerSize = styled.div`
  margin-bottom: 70px;
  margin-top: 30px;
  max-width: 330px;
  margin: 0 auto;
`;

const FormContainerText = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  margin-bottom: 10px;
  text-align: center;
  align-items: center;

  @media screen and (max-width: ${size.laptop}) {
    grid-gap: 10px;
    margin-bottom: 5px;
  }
`;

const Title = styled.h1`
  color: #000;
  font-size: 28px;
  font-family: ${font_demi};
  font-weight: bold;
`;

const SubTitle = styled.p`
  font-size: 18px;
  font-family: ${font_book};
  color: ${grey};
`;

const AuthRemainderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 330px;
  margin-bottom: 0.75rem;
  border: 1px solid ${grey_light};
  border-radius: 12px;
  font-size: 18px;
  font-family: ${font_book};
  color: ${grey};
  background-color: ${white};
`;

const AuthRemainderButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.65rem 1rem;
  border: 1px solid transparent;
  outline: none;
  border-radius: 12px;
  background-color: ${white};
  cursor: pointer;
`;

const AuthRemainderContent = styled.div`
  position: absolute;
  z-index: 1;
  top: 60px;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 1rem;
  text-align: left;
  width: 100%;
  padding: 0.65rem 1rem;
  border-radius: 14px;
  background-color: #fff;
  box-shadow: 0 0 20px #aaa;

  p {
    margin-top: 0.5rem;
  }
`;

const AuthText = styled.p`
  font-size: 16px;
  font-family: ${font_book};
  margin-left: 10px;
  color: ${black};
  ${(props) => (props.link ? mouse_pointer : "")}
`;

const Link = styled.a`
  text-decoration: none;
  font-size: 1em;
  border-bottom: 2px solid ${tiffany};
  color: ${tiffany};
  font-family: ${font_book};

  @media screen and (max-width: ${size.laptop}) {
    border-bottom: none;
    text-decoration: underline;
    text-align: center;
  }
`;

export const InlineLink = styled(Link)`
  text-align: left;
  margin-left: 10px;
`;

const ResetContainer = styled.section`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 5;
`;

const ResetBlock = styled.div`
  position: fixed;
  top: 30%;
  right: 0;
  left: 0;
  margin: 0 auto;
  background-color: white;
  max-width: 500px;
  border-radius: 10px;
  padding: 20px;

  @media screen and (max-width: ${size.laptop}) {
    max-width: calc(100% - 20px);
  }
`;

export {
  ResetContainer,
  ResetBlock,
  AuthContainer,
  Form,
  FormContainer,
  Title,
  AuthText,
  Link,
  SubTitle,
  ImageBackground,
  FormContainerText,
  Logo,
  FormContainerSize,
  AuthRemainderContainer,
  AuthRemainderButton,
  AuthRemainderContent,
};
