import styled from "styled-components";

import { grey_light, red } from "assets/style/variables";

export const EditorContainer = styled.div`
  position: relative;
  border: ${({ error }) =>
    `1px solid ${error ? "rgba(255, 0, 0, 0.3)" : grey_light}`};
  border-radius: 12px;
  padding: 6px;

  &:has(textarea:focus) {
    box-shadow: 0 0 10px ${grey_light};
  }

  input,
  textarea {
    &::placeholder {
      font-size: 17px !important;
      line-height: 1.2;
    }
  }

  ul {
    position: fixed;
    max-height: 100%;
    min-height: 375px;
    overflow: auto;
    top: 50%;
    left: 50%;
    height: auto;
    width: 300px;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 10px ${grey_light};

    li {
      background-color: #f8f8f8;
    }
  }
`;

export const EditorLabel = styled.span`
  position: absolute;
  top: -0.7rem;
  left: 1rem;
  z-index: 1;
  font-family: inherit;
  font-size: 14px;
  color: ${({ error }) => (error ? red : "#70787f")};
`;
