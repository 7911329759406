import React, { useState, useEffect } from "react";

import s from "./ImageLoader.module.css";

const ImageLoader = ({ src, placeholder, alt, width }) => {
  const [imageSrc, setImageSrc] = useState(placeholder);

  useEffect(() => {
    const image = new Image();

    image.src = src;
    image.onload = () => setImageSrc(src);
  }, [src]);

  return (
    <img className={s.image} src={imageSrc} alt={alt || ""} width={width} />
  );
};

export default ImageLoader;
