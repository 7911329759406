import styled from "styled-components";
import { size } from "assets/style/global-variables";
import { grey } from "../../../../../assets/style/variables";
import { font_book } from "assets/style/fonts.variables";

export const Total = styled.span`
  display: block;
  width: 200px;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  align-content: center;

  .title {
    font-family: ${font_book}, serif;
    font-weight: normal;
    color: ${grey};
  }

  @media screen and (min-width: ${size.laptop}) {
    font-size: 14px;
  }
`;
