import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { getCurrencyFormatter } from "pages/Panels/Statistics/helpers";

const formatter = getCurrencyFormatter(2);
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  // color,
  id,
  address,
  description,
  amount,
}) => {
  const radius = innerRadius + 1.15 * outerRadius;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const color = "currentColor";

  return (
    <g textAnchor={x > cx ? "start" : "end"} style={{ fontSize: "normal" }}>
      <text x={x} y={y - 14} fill={color} dominantBaseline="central" width={50}>
        {`${"\b"}термінал ${id}${address ? ` ${address}` : ""}`}
      </text>
      <text x={x} y={y - 2} fill={color} dominantBaseline="central" width={50}>
        {`${"\b"}${description}`}
      </text>
      <text x={x} y={y + 10} fill={color} dominantBaseline="central" width={50}>
        {`${"\b"}${(percent * 100).toFixed(0)}% (${formatter.format(amount)})`}
      </text>
    </g>
  );
};

const Chart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={true}
          label={renderCustomizedLabel}
          dataKey="value"
          nameKey="name"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Chart;
