import { useDispatch, useSelector } from "react-redux";
import { setStatParams } from "store/statistics/stat_actions";
import { selectCurrentStatisticsParams } from "store/statistics/stat_slicer";

const useTerminalPicker = () => {
  const dispatch = useDispatch();
  const statParams = useSelector(selectCurrentStatisticsParams);
  const onCancel = () =>
    dispatch(setStatParams({ ...statParams, showTerminalPicker: false }));
  const onOK = (selectedOptions) =>
    dispatch(
      setStatParams({
        ...statParams,
        selectedTerminals: selectedOptions.map(({ id }) => id),
        showTerminalPicker: false,
      })
    );

  return {
    onOK,
    onCancel,
  };
};

export default useTerminalPicker;
