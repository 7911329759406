import { useState } from "react";
import {
  GT_MAX_ERROR_MESSAGE,
  LT_MIN_ERROR_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
  INVALID_KEY_VALUE_LIST_ERROR_MESSAGE,
  KEY_DUPLICATES_LIST_ERROR_MESSAGE,
  EMPTY_KEY_VALUE_LIST_ERROR_MESSAGE,
} from "../helpers/paymentDescription";

const reMinMax = /^([1-9]\d*)?$/;

const useTagDetails = (hasLengthRestrictions, hasKeyValueList, tag) => {
  const [resetErr, setResetErr] = useState({
    tooltip: {
      isError: false,
      msg: "",
    },
    minLength: {
      isError: false,
      msg: "",
    },
    maxLength: {
      isError: false,
      msg: "",
    },
    keyValueItems: {
      isError: false,
      msg: "",
    },
  });

  let {
    tooltip: _tooltip = null,
    is_mandatory: _isMandatory = false,
    min_length: _minLength = null,
    max_length: _maxLength = null,
    tag_values: _tagValues = [],
  } = tag || {};

  if (_tagValues?.length > 0) {
    _tagValues = _tagValues.map(({ tag_value, tag_value_desc, ...rest }) => ({
      ...rest,
      tag_value,
      tag_value_desc,
      keyItem: tag_value_desc,
      valueItem: tag_value,
    }));
  }

  const [tooltip, setTooltip] = useState(_tooltip);
  const [isMandatory, setIsMandatory] = useState(_isMandatory);
  const [minLength, setMinLength] = useState(_minLength);
  const [maxLength, setMaxLength] = useState(_maxLength);
  const [keyValueItems, setKeyValueItems] = useState(_tagValues || []);

  function handleTooltip(val) {
    setTooltip(val);
  }

  function handleMinLength(val) {
    if (reMinMax.test(val)) {
      setMinLength(Number(val));
    }
  }

  function handleMaxLength(val) {
    if (reMinMax.test(val)) {
      setMaxLength(Number(val));
    }
  }

  function handleKeyValueItems(val) {
    setKeyValueItems(val);
  }

  const validateKeyValueItems = () => {
    const noItems = keyValueItems.length === 0;
    const containsEmptyKeyValues =
      keyValueItems.filter(({ keyItem, valueItem }) => !(keyItem && valueItem))
        .length > 0;
    const duplicatesCounter = keyValueItems
      .filter(({ keyItem, valueItem }) => keyItem && valueItem)
      .reduce((a, { keyItem }) => {
        const value = a[keyItem];
        a[keyItem] = value ? value + 1 : 1;

        return a;
      }, {});
    const containsDuplicates =
      Object.values(duplicatesCounter).filter((item) => item > 1).length > 0;

    let errorMessage = "";

    if (noItems) {
      errorMessage = REQUIRED_ERROR_MESSAGE;
    } else {
      if (containsEmptyKeyValues && containsDuplicates) {
        errorMessage = INVALID_KEY_VALUE_LIST_ERROR_MESSAGE;
      } else {
        if (containsEmptyKeyValues) {
          errorMessage = EMPTY_KEY_VALUE_LIST_ERROR_MESSAGE;
        } else {
          errorMessage = KEY_DUPLICATES_LIST_ERROR_MESSAGE;
        }
      }
    }

    if (noItems || containsEmptyKeyValues || containsDuplicates) {
      return setResetErr((state) => ({
        ...state,
        keyValueItems: {
          isError: true,
          msg: errorMessage,
        },
      }));
    } else {
      setResetErr((state) => ({
        ...state,
        keyValueItems: {
          isError: false,
          msg: "",
        },
      }));
    }

    return true;
  };

  const validateTooltip = () => {
    if (!tooltip) {
      return setResetErr((state) => ({
        ...state,
        tooltip: {
          isError: true,
          msg: REQUIRED_ERROR_MESSAGE,
        },
      }));
    } else {
      setResetErr((state) => ({
        ...state,
        tooltip: {
          isError: false,
          msg: "",
        },
      }));
    }

    return true;
  };

  const validateMinLength = () => {
    if (!minLength) {
      return setResetErr((state) => ({
        ...state,
        minLength: {
          isError: true,
          msg: REQUIRED_ERROR_MESSAGE,
        },
      }));
    } else if (!isNaN(minLength) && minLength > maxLength) {
      return setResetErr((state) => ({
        ...state,
        minLength: {
          isError: true,
          msg: GT_MAX_ERROR_MESSAGE,
        },
      }));
    } else {
      setResetErr((state) => ({
        ...state,
        minLength: {
          isError: false,
          msg: "",
        },
      }));
    }

    return true;
  };

  const validateMaxLength = () => {
    if (!maxLength) {
      return setResetErr((state) => ({
        ...state,
        maxLength: {
          isError: true,
          msg: REQUIRED_ERROR_MESSAGE,
        },
      }));
    } else if (!isNaN(maxLength) && minLength > maxLength) {
      return setResetErr((state) => ({
        ...state,
        maxLength: {
          isError: true,
          msg: LT_MIN_ERROR_MESSAGE,
        },
      }));
    } else {
      setResetErr((state) => ({
        ...state,
        maxLength: {
          isError: false,
          msg: "",
        },
      }));
    }

    return true;
  };

  const validate = () => {
    const isTooltipValid = validateTooltip();
    let isMinMaxLengthValid = true;
    let isKeyValueListValid = true;

    if (hasLengthRestrictions) {
      const isMinLengthValid = validateMinLength();
      const isMaxLengthValid = validateMaxLength();

      isMinMaxLengthValid = isMinLengthValid && isMaxLengthValid;
    }

    if (hasKeyValueList) {
      isKeyValueListValid = validateKeyValueItems();
    }

    return isTooltipValid && isMinMaxLengthValid && isKeyValueListValid;
  };

  const resetFieldError = (fieldName) => {
    setResetErr((state) => ({
      ...state,
      [fieldName]: { isError: false, msg: "" },
    }));
  };

  return {
    resetErr,
    resetFieldError,
    tooltip,
    handleTooltip,
    isMandatory,
    handleIsMandatory: (value) => setIsMandatory(value), //(e) => setIsMandatory(e.target.checked),
    minLength,
    handleMinLength,
    maxLength,
    handleMaxLength,
    validate,
    keyValueItems,
    handleKeyValueItems,
  };
};

export default useTagDetails;

export const needsMandatoryCheck = (tagName) => {
  const TAGS_WITH_MANDATORY_CHECK = [
    "COMMENT",
    "CONST",
    "RRO",
    "TERMINALID",
    "TRAN_AMOUNT",
    "CASHIER",
  ];

  return !TAGS_WITH_MANDATORY_CHECK.includes(tagName);
};

export const getLabel = (tagName) => {
  const items = {
    COMMENT: "Текст на екрані",
    CONST: "Константа",
  };

  return items[tagName] || "Підказка";
};

export const getPlaceholder = (tagName) => {
  const items = {
    COMMENT: "вкажіть текст",
    CONST: "вкажіть константу",
  };

  return items[tagName] || "вкажіть підказку елементу шаблону";
};
