import React, { useMemo, useEffect } from "react";
import { createPortal } from "react-dom";

// styles
import { Backdrop, ModalContainer } from "./modal.styles";

const Modal = ({
  children,
  style,
  onClose,
  className,
  closeOnBackdrop,
  focusSelector,
  hiddenBackdrop,
}) => {
  const target = document.body;

  const el = useMemo(() => document.createElement("div"), []);
  el.setAttribute("id", "modal-root");
  el.setAttribute(
    "style",
    "position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; z-index: 100000"
  );

  useEffect(() => {
    target.appendChild(el);

    if (focusSelector) {
      const focusOwner = el.querySelector(focusSelector);

      if (focusOwner) {
        focusOwner.focus();
      }
    }

    return () => {
      target.removeChild(el);
    };
  }, [el, target]);

  return createPortal(
    <>
      <Backdrop
        onClick={closeOnBackdrop ? onClose : () => {}}
        style={hiddenBackdrop ? { opacity: 0 } : null}
      />
      <ModalContainer style={style} className={className}>
        {children}
      </ModalContainer>
    </>,
    el
  );
};

export default Modal;
