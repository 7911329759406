import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHeader } from "store/header/header.context";
import moment from "moment";

import ChangeTitle, {
  ChangeLeft,
  ChangeSubtitle,
  SetMobileBackButton,
} from "utils/helpers/changetitle.helpers";
import {
  DivContainer,
  SectionInform,
  Title,
  SubTitle,
  DivContainerIcon,
  DivContainerText,
} from "../../../Transaction/TransactionDetail/TransactionDetail.styles";

import ZReciept from "components/additional/Z-Reciept/Z-Reciept";
import Preloader from "components/additional/PreLoader/Preloader";
import Transaction from "pages/Panels/Transaction/Transaction.panel";
import BackIcon from "ui/BackIcon/BackIcon";

import { ReactComponent as TerminalIcon } from "assets/icons/transaction/transaction_detail/terminal.svg";
import { ReactComponent as CashierIcon } from "assets/icons/ppo.svg";

import SubTotal from "components/layouts/SubTotal/SubTotal";
import ReportsLoader from "ui/ReportsLoader/ReportsLoader";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import { BankDetailContainer, FiscalActions } from "../FiscalDetail.styles";

import { FiskalTable } from "api/fiskal.api";
import { loadSingleFiscalClosedDay } from "store/fiscal_table/fiscal_table.actions";
import {
  bOpenTotal,
  bankDetailItem,
  fiscalDetailLoading,
  fiscalDetailError,
} from "store/fiscal_table/fiscal_table.slicer";
import { setAlertState, dropAlertState } from "store/alert/alert_actions";

const FiscalClosedDetail = () => {
  const { dispatch } = useHeader();
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const total = useSelector(bOpenTotal);
  const item = useSelector(bankDetailItem);
  const loading = useSelector(fiscalDetailLoading);
  const error = useSelector(fiscalDetailError);
  const {
    status,
    startDate,
    endDate,
    terminal_search,
    search_cashier,
    search_method_payment,
    rrn_search,
    amount_search,
  } = useSelector((state) => state.transaction_table.fiscal_filtering_state);
  const api = new FiskalTable();

  const itemInfo = item.extendedInfo;
  const dateString = itemInfo
    ? `дата відкриття: ${
        item?.info?.openDate
          ? moment(item?.info?.openDate).format("HH:mm DD.MM.YYYY")
          : "-"
      } \n дата закриття: ${
        item?.info?.closeDate
          ? moment(item?.info?.closeDate).format("HH:mm DD.MM.YYYY")
          : "-"
      }`
    : "";

  total[0].value = itemInfo && itemInfo?.totalCount;
  total[1].value = itemInfo && itemInfo?.totalAmountSaccess;
  total[2].value = itemInfo && itemInfo?.totalAmountRefaund;
  total[3].value = itemInfo && itemInfo?.totalAmount;

  const handleExcelLoad = async (isExtended) =>
    api.downloadExcel({
      dfs_shift_id: itemInfo.id,
      dateFrom: startDate ? moment(startDate).format("YYYYMMDD") : null,
      dateTo: endDate ? moment(endDate).format("YYYYMMDD") : null,
      status: status,
      search_rrn: rrn_search,
      search_amount: amount_search,
      search_method_payment: search_method_payment,
      search_terminal: terminal_search,
      search_cashier: search_cashier,
      extended_rep: isExtended,
    });

  const handlePdfLoad = async (isExtended) =>
    api.downloadPdf({
      dfs_shift_id: itemInfo.id,
      dateFrom: startDate ? moment(startDate).format("YYYYMMDD") : null,
      dateTo: endDate ? moment(endDate).format("YYYYMMDD") : null,
      status: status,
      search_rrn: rrn_search,
      search_amount: amount_search,
      search_method_payment: search_method_payment,
      search_terminal: terminal_search,
      search_cashier: search_cashier,
      extended_rep: isExtended,
    });

  useEffect(() => {
    if (!item || Object.keys(item).length === 0) {
      return history.push("/dash/fiscal-day");
    }

    if (!item.extendedInfo) {
      reduxDispatch(
        loadSingleFiscalClosedDay({
          shiftCloseDateTiks: item.info.shiftCloseDateTiks,
          info: item.info,
        })
      );
    }
  }, []);

  useEffect(() => {
    ChangeTitle("Фіскальна зміна", dispatch);
    ChangeLeft(dispatch);
    SetMobileBackButton(true, dispatch);
    if (item?.info?.closeDate) {
      const date = moment(item.info.closeDate).format("DD.MM.YYYY");
      ChangeSubtitle(date, dispatch);
    }
  }, [dispatch]);

  if (loading) {
    return <Preloader />;
  }

  if (error && error.shiftCloseDateTiks === item.info.shiftCloseDateTiks) {
    return (
      <BankDetailContainer>
        <ErrorComponent title={error.error} />
        <BackIcon className="default" />
      </BankDetailContainer>
    );
  }

  const onError = (message) => {
    reduxDispatch(
      setAlertState({
        show: true,
        title: "Увага!",
        subtitle: message,
        type: "block",
        iconType: "remove",
        fnClose: () => reduxDispatch(dropAlertState()),
        height: "475px",
      })
    );
  };

  if (!item.extendedInfo) return <></>;

  return (
    <BankDetailContainer>
      <SectionInform>
        <DivContainer className="width-50">
          <DivContainerIcon>
            <TerminalIcon />
          </DivContainerIcon>
          <DivContainerText>
            <Title>{itemInfo?.terminalDescr}</Title>
            <SubTitle>Terminal ID: {itemInfo?.terminalId}</SubTitle>
          </DivContainerText>
        </DivContainer>
        <DivContainer className="width-50">
          <DivContainerIcon>
            <CashierIcon />
          </DivContainerIcon>
          <DivContainerText>
            <Title>{itemInfo?.cashier}</Title>
            <SubTitle style={{ whiteSpace: "pre-line" }}>{dateString}</SubTitle>
          </DivContainerText>
        </DivContainer>
      </SectionInform>

      <div className="subtotal">
        <SubTotal state={total} />
      </div>

      <FiscalActions>
        <ZReciept
          type={"fiscal"}
          base64String={itemInfo?.z_report_visual}
          rro_id={itemInfo?.rroId}
          dfs_log_id={itemInfo?.id}
          onError={onError}
        />
        <ReportsLoader
          handleExcelLoad={handleExcelLoad}
          handlePdfLoad={handlePdfLoad}
        />
      </FiscalActions>

      <Transaction fiscalDay={itemInfo?.transactionsList} />

      <BackIcon className="default" />
    </BankDetailContainer>
  );
};

export default FiscalClosedDetail;
