import { useDispatch, useSelector } from "react-redux";
import StatTypeSelect from "../StatTypeSelect";
import {
  ChartToolbarContainer,
  StatSelectorContainer,
} from "../../charts/TotalsByTerminals/components/ChartToolbar/ChartToolbar.styles";
import { TransparentButton } from "components/forms/Button/Button.styles";
import { ReactComponent as TerminalIcon } from "assets/icons/terminal.svg";
import { selectCurrentStatisticsParams } from "store/statistics/stat_slicer";

import { setStatParams } from "store/statistics/stat_actions";

const StatSelector = () => {
  const dispatch = useDispatch();
  const statParams = useSelector(selectCurrentStatisticsParams);
  const { bySelectedTerminals } = statParams;
  const onClick = () =>
    dispatch(setStatParams({ ...statParams, showTerminalPicker: true }));

  return (
    <StatSelectorContainer>
      <StatTypeSelect />
      {bySelectedTerminals && (
        <ChartToolbarContainer>
          <TransparentButton onClick={onClick}>
            <TerminalIcon className="terminal-icon" />
            <span>вибрати термінали</span>
          </TransparentButton>
        </ChartToolbarContainer>
      )}
    </StatSelectorContainer>
  );
};

export default StatSelector;
