import React, { useState } from "react";

import SelectComponent from "components/forms/Select/Select";
import {
  ModalTitle,
  ModalSubTitle,
  ModalButtons,
  ModalBody,
} from "ui/Modal/modal.styles";
import {
  TransferModalContainer,
  ContainedButton,
  OutlinedButton,
} from "./style";
import { ReactComponent as CornerIcon } from "../CategoriesTable/corner.svg";

const TransferModal = (props) => {
  const { selectedLength, onAccept, onClose, categories } = props;

  const [selectedCategory, setSelectedCategory] = useState(null);
  const acceptButtonDisabled = !selectedCategory;

  return (
    <TransferModalContainer>
      <ModalTitle>Змінити категорію</ModalTitle>
      <ModalSubTitle>
        змінити категорію {selectedLength} обраних товарів
      </ModalSubTitle>
      <ModalBody>
        <SelectComponent
          data={{
            caption: "Нова категорія",
            placeholder: "Оберіть нову категорію",
            selectedCaption: selectedCategory?.name,
            disabled: false,
            onSelectItem: (item) => setSelectedCategory(item),
            listInfo: {
              items: categories,
              data_id: "name",
              data_uk: "name",
              data_caption: "name",
            },
            style: {
              width: "100%",
              overflowY: "hide",
              margin: "13px 0",
              padding: "11px 16px",
            },
            renderingType: "category",
            subItemIcon: CornerIcon,
          }}
        />
      </ModalBody>
      <ModalButtons>
        <ContainedButton
          onClick={() => onAccept(selectedCategory.id)}
          disabled={acceptButtonDisabled}
        >
          Змінити
        </ContainedButton>
        <OutlinedButton onClick={onClose}>Відміна</OutlinedButton>
      </ModalButtons>
    </TransferModalContainer>
  );
};

export default TransferModal;
