import { useEffect, useRef, useState } from "react";
import { MentionsInput, Mention } from "react-mentions";

import EditorRowActions from "./row-actions/EditorRowActions";

import { EditorContainer, EditorLabel } from "./Editor.style";
import defaultMentionStyle from "./defaulMentionStyle";
import defaultStyle from "./defaultStyle";

const Editor = ({
  singleLine,
  label,
  disabled,
  mentions = [],
  value,
  onFocus,
  onChange,
  onKeyDown,
  onEdit,
  error,
  inputRef,
}) => {
  const editorRef = useRef(null);

  const [elemRects, setElemRects] = useState(null);
  const [actionsPosition, setActionsPosition] = useState(null);
  const [editorRect, setEditorRect] = useState(null);

  useEffect(() => {
    if (elemRects) {
      const elems = editorRef.current.querySelectorAll("strong");
      const rects = [...elems].map((elem) => elem.getBoundingClientRect());

      setElemRects(rects);
      setActionsPosition(null);
    }
  }, [value]);

  useEffect(() => {
    if (disabled) {
      setActionsPosition(null);
    }
  }, [disabled]);

  useEffect(() => {
    const onScroll = () => {
      setElemRects(null);
      setActionsPosition(null);
    };
    const editorElem = editorRef.current;

    if (editorElem) {
      const editor = editorElem.querySelector("textarea");

      if (editor) {
        editor.addEventListener("scroll", onScroll);
      }
    }

    return () => {
      if (editorElem) {
        const editor = editorElem.querySelector("textarea");

        if (editor) {
          editor.removeEventListener("scroll", onScroll);
        }
      }
    };
  }, [editorRef]);

  return (
    <EditorContainer
      error={error}
      ref={editorRef}
      onDoubleClick={() => {
        if (!disabled && actionsPosition) onEdit(actionsPosition.index);
      }}
      onClick={(e) => {
        let rects = null;
        if (!elemRects) {
          const elems = editorRef.current.querySelectorAll("strong");

          rects = [...elems].map((elem) => elem.getBoundingClientRect());
          setElemRects(rects);
        }

        rects = rects || elemRects;

        const { pageX, pageY } = e;
        const rect = rects.find(
          ({ left, top, width, height }) =>
            pageY >= top &&
            pageY <= top + height &&
            pageX >= left &&
            pageX <= left + width
        );

        if (!rect) return;

        const parentRect =
          editorRect || editorRef.current.getBoundingClientRect();

        if (!editorRect) {
          setEditorRect(parentRect);
        }

        let index = -1;

        if (rect) {
          index = rects.indexOf(rect);
        }

        setActionsPosition({
          point: rect
            ? {
                top: `${Math.floor(rect.top - parentRect.top - 3)}px`,
                right: "1.75rem",
              }
            : null,
          index,
        });
      }}
    >
      <EditorLabel error={error}>{label}</EditorLabel>
      <MentionsInput
        singleLine={singleLine}
        style={defaultStyle}
        value={value || ""}
        onFocus={onFocus}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
        placeholder='створіть новий шаблон або відредагуйте обраний шаблон. Використовуйте "+" для додавання елементів шаблону'
        a11ySuggestionsListLabel="Suggested mentions"
        disabled={disabled}
        inputRef={inputRef}
        allowSuggestionsAboveCursor
      >
        <Mention
          data={mentions}
          style={defaultMentionStyle}
          trigger="+"
          appendSpaceOnAdd
        />
      </MentionsInput>
      {!disabled && actionsPosition && (
        <EditorRowActions position={actionsPosition} onEdit={onEdit} />
      )}
    </EditorContainer>
  );
};

export default Editor;
