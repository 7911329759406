export const terminalStatuses = {
  bound: { status: "bound", label: "Підключений", groupLabel: "Підключені" },
  free: { status: "free", label: "Вільний", groupLabel: "Вільні" },
  ecomm: {
    status: "ecomm",
    label: "Термінал для QR-оплати",
    groupLabel: "QR-оплата",
  },
  blocked: {
    status: "blocked",
    label: "Заблокований",
    groupLabel: "Заблоковані",
  },
};
