import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import { useDispatch } from "react-redux";

import ToastProvider from "components/providers/ToastProvider/ToastProvider";
import GlobalLoaderProvider from "components/providers/GlobalLoaderProvider/GlobalLoaderProvider";
import AlertProvider from "components/providers/AlertProvider/AlertProvider";
import { InitialModalsProvider } from "components/providers";
import AuthPage from "pages/Auth/AuthPage";
import ZReciept from "pages/ZReciept/ZReciept";
import DashboardPage from "pages/Dashboard/DashboardPage";
import PrivateRoute from "components/routing/PrivateRoute/PrivateRoute";
import PublicRoute from "components/routing/PublicRoute/PublicRoute";

import {
  AUTH_ROUTE,
  DASHBOARD_ROUTE,
  VIEW_Z_RECIEPT_ROUTE,
} from "utils/constants/routes.constants";
import { setMobileVersion } from "store/main_table/main_table.actions";
import { LocalStorage, SessionStorage } from "services";
import { backStateToRedux } from "utils/helpers/backStateToRedux";
import IdleTimer from "utils/helpers/session.helpers";

const App = () => {
  const dispatch = useDispatch();

  // storages manager
  useEffect(() => {
    const ss = new SessionStorage();
    const local = new LocalStorage();

    ss.clear();
    backStateToRedux({ dispatch });

    const timer = new IdleTimer({
      timeout: 60 * 15,
      onTimeout: () => {
        local.clear();
        window.location.assign(AUTH_ROUTE);
      },
      onExpired: () => {
        local.clear();
        window.location.assign(AUTH_ROUTE);
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, [dispatch]);

  // mobile version manager
  useEffect(() => {
    const changeMobileVersion = () => {
      // dispatch(setMobileVersion(window.outerWidth));
      dispatch(setMobileVersion(window.innerWidth));
    };
    changeMobileVersion();
    window.addEventListener("resize", changeMobileVersion);

    return () => {
      window.removeEventListener("resize", changeMobileVersion);
    };
  }, [dispatch]);

  return (
    <ToastProvider>
      <AlertProvider>
        <GlobalLoaderProvider>
          <Router>
            <Switch>
              <PublicRoute
                restricted={true}
                component={AuthPage}
                path={AUTH_ROUTE}
                exact
              />
              <PrivateRoute
                component={ZReciept}
                path={VIEW_Z_RECIEPT_ROUTE}
                wrapper={InitialModalsProvider}
              />
              <PrivateRoute
                component={DashboardPage}
                path={DASHBOARD_ROUTE}
                wrapper={InitialModalsProvider}
              />
              <Route exact path="*">
                <Redirect push to={DASHBOARD_ROUTE} />
              </Route>
            </Switch>
          </Router>
        </GlobalLoaderProvider>
      </AlertProvider>
    </ToastProvider>
  );
};

export default App;
