import { Th, TableIconSort, TableText } from "../../Table.styles";
import { ReactComponent as SortIcon } from "assets/icons/sort_line.svg";
import {DESC_SORT} from 'utils/constants/sort.constants'
export default function TableHeaderSort({ title, fn, sort }) {
  return (
    <Th onClick={fn}>
      <TableIconSort sort={sort===DESC_SORT}>
        <SortIcon />
      </TableIconSort>
      <TableText>{title}</TableText>
    </Th>
  );
}
