import { getUUID } from "utils/helpers/getUUID";

export const MINUTES_INTERVAL = 5;
export const MINUTES_IN_HOUR = 60;

export const WEEK_DAYS = [
  { label: "Понеділок", id: getUUID(), field: "fiskalMon" },
  { label: "Вівторок", id: getUUID(), field: "fiskalTue" },
  { label: "Середа", id: getUUID(), field: "fiskalWed" },
  { label: "Четвер", id: getUUID(), field: "fiskalThu" },
  { label: "П'ятниця", id: getUUID(), field: "fiskalFri" },
  { label: "Субота", id: getUUID(), field: "fiskalSat" },
  { label: "Неділя", id: getUUID(), field: "fiskalSun" },
];

let HOURS = new Array(24).fill(0);
HOURS = HOURS.map((_, index) => ({
  id: getUUID(),
  value: index < 10 ? `0${index}` : index.toString(),
}));

let MINUTES = new Array(MINUTES_IN_HOUR / MINUTES_INTERVAL).fill(0);
MINUTES = MINUTES.map((_, index) => {
  let minutes = index * MINUTES_INTERVAL;

  return {
    id: getUUID(),
    value: minutes < 10 ? `0${minutes}` : minutes.toString(),
  };
});

export { HOURS, MINUTES };

export const UI_DATE_FORMAT = "DD.MM.YYYY";
export const API_SIMPLE_DATE_FORMAT = "YYYYMMDD";
export const API_ISO_DATE_TIME_FORMAT = "YYYY-MM-DD";

export const MONTHS = [
  "січень",
  "лютий",
  "березень",
  "квітень",
  "травень",
  "червень",
  "липень",
  "серпень",
  "вересень",
  "жовтень",
  "листопад",
  "грудень",
];

export const QUARTERS = [
  "I квартал",
  "II квартал",
  "III квартал",
  "IV квартал",
];
