import Modal from "ui/Modal/Modal";
import TerminalPickerTable from "./TerminalPickerTable";
import useTerminalPicker from "./useTerminalPicker";
import useSelectedTerminals from "./useSelectedTerminals";
import {
  ModalTitle,
  ModalContainedButton,
  ModalOutlinedButton,
  ModalButtons,
} from "ui/Modal/modal.styles";
import TimesCancelButton from "ui/Modal/TimesCancelButton";

const TerminalPickerModal = () => {
  const { onOK, onCancel } = useTerminalPicker();
  const {
    idFilterValue,
    setIdFilterValue,
    nameFilterValue,
    setNameFilterValue,
    search,
    onSelectAllTerminals,
    onSelectTerminal,
    filteredTerminals,
    selectedTerminals,
    terminals,
  } = useSelectedTerminals();

  return (
    <Modal className="terminal-picker">
      <ModalTitle>Вибір терміналів</ModalTitle>
      <div className="table-wrapper">
        <TerminalPickerTable
          {...{
            idFilterValue,
            setIdFilterValue,
            nameFilterValue,
            setNameFilterValue,
            search,
            onSelectAllTerminals,
            onSelectTerminal,
            filteredTerminals,
            selectedTerminals,
            terminals,
          }}
        />
      </div>
      <ModalButtons>
        <ModalContainedButton
          onClick={() => onOK(selectedTerminals)}
          disabled={selectedTerminals.length === 0}
          active={selectedTerminals.length > 0}
        >
          Зберегти
        </ModalContainedButton>
        <ModalOutlinedButton onClick={onCancel}>Скасувати</ModalOutlinedButton>
        <TimesCancelButton onClick={onCancel} right={".7rem"} top={".7rem"} />
      </ModalButtons>
    </Modal>
  );
};

export default TerminalPickerModal;
