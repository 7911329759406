import styled, { keyframes } from "styled-components";
import { font_book } from "assets/style/fonts.variables";
import { grey_input } from "assets/style/variables";
// create category

const createCategoryAnimation = keyframes`
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: auto;
  }
`;

export const CreateCategoryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;

  &.entering {
    animation: ${createCategoryAnimation} 0.4s forwards;
  }

  &.exiting {
    animation: ${createCategoryAnimation} 0.4s reverse;
  }

  > div {
    width: 100%;
  }

  > button {
    padding: 11px 20px;
    border-radius: 12px;
    border: none;
    font-size: 16px;
    color: #fff;
    font-family: ${font_book};
    background: linear-gradient(109.17deg, #179c92 -123.63%, #004b45 270.75%);
    cursor: pointer;
  }
  > button.disabled {
    background: #eee !important;
    color: #aaa !important;
    cursor: not-allowed !important;
  }
`;

export const EditCategoryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  border: 1px solid ${grey_input};
  padding: 20px 10px;
  border-radius: 22px;
  position: relative;

  &.entering {
    animation: ${createCategoryAnimation} 0.4s forwards;
  }

  &.exiting {
    animation: ${createCategoryAnimation} 0.4s reverse;
  }

  > div {
    width: 100%;
  }

  > button {
    padding: 11px 20px;
    border-radius: 12px;
    border: none;
    font-size: 16px;
    color: #fff;
    font-family: ${font_book};
    background: linear-gradient(109.17deg, #179c92 -123.63%, #004b45 270.75%);
    cursor: pointer;
  }
  > button.disabled {
    background: #eee !important;
    color: #aaa !important;
    cursor: not-allowed !important;
  }
`;

export const CategoriesTitle = styled.div`
  text-align: left;
  font-size: 14px;
  font-family: ${font_book};
  color: #70787f;
  margin-top: 30px;
  margin-bottom: 12px;
`;

export const CategoriesNotFound = styled.span`
  text-align: left;
  font-size: 16px;
  font-family: "Futura PT", sans-serif;
  color: #000;
  font-weight: 600;
`;

export const CategoriesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CategoryItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
  overflow: hidden;

  @keyframes category-item-animation {
    0% {
      max-height: 0px;
      opacity: 0;
    }
    100% {
      max-height: 100px;
      opacity: 1;
    }
  }

  animation: category-item-animation 1s ease-in-out;
`;

export const CategoryItem = styled.div`
  width: calc(100% - ${(props) => props.row * 40}px);
  margin-left: auto;
  padding: 15px 10px;
  margin: 5px 0;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;

  * {
    font-family: "Futura PT", sans-serif;
    outline: none;
  }

  ${(props) =>
    props.hasDown
      ? `cursor: pointer; &:hover { border: 1px solid #aaa }`
      : null}
  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    span {
      font-family: "Futura Medium", sans-serif;
      font-size: 20px;
      font-weight: 500;
    }

    img.star-img {
      width: 13px;
    }
  }

  .right {
    display: flex;
    align-items: center;
    column-gap: 8px;
    svg {
      width: 18px;
      cursor: pointer;
    }
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: auto !important;
  height: fit-content;
  cursor: pointer;
  svg {
    width: 20px;
  }

  &.entering {
    animation: ${createCategoryAnimation} 0.4s forwards;
  }

  &.exiting {
    animation: ${createCategoryAnimation} 0.4s reverse;
  }
`;
