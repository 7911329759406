import {
  grey,
  red,
  grey_light,
  caret,
  white,
  mouse_pointer,
} from "assets/style/variables";
import { font_book } from "assets/style/fonts.variables";

import styled from "styled-components";

const Input = styled.input`
  width: 100%;
  border: 1px solid
    ${(props) => (props.error ? "rgba(255, 0, 0, 0.3) !important" : grey_light)};
  padding: 11px 16px;
  font-size: 20px;
  margin: 13px 0;
  height: 48px;
  font-family: ${font_book};
  border-radius: 12px;
  color: #000;
  outline: none;
  ${caret};
  &::placeholder {
    font-size: smaller;
  }
  &:focus {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }

  transition: border 0.2s;
`;

export const Textarea = styled.textarea`
  width: 100%;
  border: 1px solid
    ${(props) => (props.error ? "rgba(255, 0, 0, 0.3) !important" : grey_light)};
  padding: 11px 16px;
  font-size: 20px;
  margin: 13px 0;
  font-family: ${font_book};
  border-radius: 12px;
  color: #000;
  outline: none;
  ${caret};
  resize: vertical;

  &:focus {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }
`;

const TextFieldContainer = styled.div`
  position: relative;
  .photo__loaded {
    font-family: ${font_book};
    font-size: 16px;
    font-weight: 400;
  }
  .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    filter: opacity(0.7);
    &:hover {
      cursor: pointer;
    }
  }
  .line::after {
    content: "/";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
  }
  #file,
  input[type="file"] {
    display: none;
  }

  &.file-field {
    svg path {
      stroke: ${({ disabled }) => (disabled ? grey : "#26CFC7")};
    }
  }

  &,
  & * {
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")} !important;
  }

  label span {
    color: ${({ disabled }) => (disabled ? grey : "inherit")};
  }
`;
export const Label = styled.label`
  background: ${white};
  font-size: 16px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  font-weight: 400;
  font-family: ${font_book};
  max-width: ${(props) => (props.wide ? "230px" : "190px")};
  padding: 18px 25px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(92, 92, 92, 0.07);
  margin: 8px 0;
  ${mouse_pointer}
  position: relative;
  .close {
    position: absolute;
    top: 2px;
    bottom: 0;
    right: 16px;
    margin: auto;
  }

  .close-file {
  }
`;

const SubTitle = styled.p`
  position: absolute;
  top: 3px;
  left: 10px;
  background-color: transparent;
  color: ${(props) => (props.error ? red : grey)};
  font-size: 14px;
  font-family: ${font_book};
  padding-left: 5px;
  padding-right: 10px;
`;

export { Input, TextFieldContainer, SubTitle };
