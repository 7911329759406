import React from "react";
// import { Transition } from "react-transition-group";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import FirstLoginRemainder from "./FirstLoginRemainder";
import LostPasswordRemainder from "./LostPasswordRemainder";

import { grey } from "assets/style/variables";

import {
  AuthRemainderContainer,
  AuthRemainderButton,
  AuthRemainderContent,
  SubTitle,
} from "../Auth.styles";

const AuthRemainder = ({ authRemainderVisible, setAuthRemainderVisible }) => {
  return (
    <AuthRemainderContainer>
      <AuthRemainderButton
        onClick={() => setAuthRemainderVisible((state) => !state)}
      >
        <SubTitle>Де знайти логін та пароль</SubTitle>
        {authRemainderVisible ? (
          <FaChevronUp color={grey} />
        ) : (
          <FaChevronDown color={grey} />
        )}
      </AuthRemainderButton>
      {authRemainderVisible && (
        <AuthRemainderContent onClick={(e) => e.stopPropagation()}>
          <FirstLoginRemainder />
          <LostPasswordRemainder />
        </AuthRemainderContent>
      )}
      {/* <Transition
        in={authRemainderVisible}
        timeout={400}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {(state) => (
          <AuthRemainderContent className={state}>
            <FirstLoginRemainder />
            <LostPasswordRemainder />
          </AuthRemainderContent>
        )}
      </Transition> */}
    </AuthRemainderContainer>
  );
};

export default AuthRemainder;
