import { font_medium, font_book } from "assets/style/fonts.variables";
import { black, grey } from "assets/style/variables";
import styled from "styled-components";

const TableContainer = styled.table`
  margin: 0;
  margin-top: 25px;
  width: 100%;
  min-width: 560px;

  a {
    text-decoration: none;
  }
`;

const TrMain = styled.tr`
  display: grid;
  grid-template-columns: 56px 100px 1fr 0.5fr;
  grid-gap: 6px;
  margin: 12px 0;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  ${(props) =>
    props.column
      ? `
      padding: 17px;
      align-items: center;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      justify-items: center;`
      : "null"}
`;

const ButtonContainer = styled.div`
  display: flex;
  grid-gap: 12px;
`;

const Terminal = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 22px;
`;

const TerminalText = styled.div`
  font-size: 14px;
  text-align: ${(props) => (props.right ? "right" : "left")};
`;
const TerminalTitle = styled.p`
  color: ${black};
  font-family: ${font_medium};
`;

const TerminalDescribe = styled.span`
  color: ${grey};
  line-height: 20px;
  font-family: ${font_book};
`;

export {
  TableContainer,
  TrMain,
  ButtonContainer,
  Terminal,
  TerminalText,
  TerminalTitle,
  TerminalDescribe,
};
