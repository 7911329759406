import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "ui/Modal/Modal";
import DisabledFiscalizationModal from "components/initial_modals/DisabledFiscalizationModalProvider/DisabledFiscalizationModal/DisabledFiscalizationModal";
import { DISABLED_FISCALIZATION_MODAL_WARNING } from "utils/constants/server.constants";

import { onCloseInitialModal } from "store/initial_modals/initial_modals_actions";

const MAXIMAL_DATE_DIFFERENCE = 1000 * 60 * 60 * 24 * 3;

export const DisabledFiscalizationModalProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { initial_modals_list, active_initial_modal_index } = useSelector(
    (state) => state.initial_modals
  );

  const [modalIsActive, setModalIsActive] = React.useState(false);

  useEffect(() => {
    if (initial_modals_list[active_initial_modal_index] === undefined) {
      setModalIsActive(false);
    }

    const modalWithCurrentIndexIsActive =
      initial_modals_list[active_initial_modal_index] &&
      initial_modals_list[active_initial_modal_index].id ===
        "disabled-fiscalization-modal";

    if (
      !DISABLED_FISCALIZATION_MODAL_WARNING &&
      modalWithCurrentIndexIsActive
    ) {
      dispatch(onCloseInitialModal());
    }

    if (DISABLED_FISCALIZATION_MODAL_WARNING && modalWithCurrentIndexIsActive) {
      const pastDate = localStorage.getItem(
        "disabled-fiscalization-modal-state"
      )
        ? JSON.parse(localStorage.getItem("disabled-fiscalization-modal-state"))
            .date
        : null;

      if (pastDate && Date.now() - pastDate <= MAXIMAL_DATE_DIFFERENCE) {
        dispatch(onCloseInitialModal());
        setModalIsActive(false);
      } else {
        setModalIsActive(true);
      }
    }
  }, [active_initial_modal_index]);

  return (
    <>
      {children}
      {modalIsActive && (
        <Modal
          closeOnBackdrop={false}
          onClose={() => dispatch(onCloseInitialModal())}
          className={"disabled-fiscalization"}
        >
          <DisabledFiscalizationModal
            onClose={() => dispatch(onCloseInitialModal())}
          />
        </Modal>
      )}
    </>
  );
};
