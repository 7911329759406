import styled from "styled-components";

import {
  grey,
  grey_light,
  grey_light_setting,
  tiffany,
} from "assets/style/variables";
import { font_book } from "assets/style/fonts.variables";

export const CheckCaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.85rem;
  font-family: ${font_book};

  &.check-footer {
    align-self: stretch;
  }
`;

export const CheckCaption = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid ${grey_light};
  border-radius: 12px;
  width: 300px;
  min-height: 186px;
  height: 100%;
  padding: 0.25rem 1rem 1rem;

  &:has(textarea:focus) {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }
`;

export const CheckCaptionLabel = styled.label`
  position: absolute;
  top: -0.75rem;
  left: 0.75rem;
  color: ${grey};
  font-size: 14px;
`;

export const CheckCaptionTitle = styled.span`
  font-size: 20px;
  text-align: center;
  padding: 0.5rem 0;
`;

export const CheckCaptionEditor = styled.textarea.attrs(
  ({ rows = undefined }) => ({ rows })
)`
  flex: 1;
  padding: 0.5rem 0;
  resize: none;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 16px;
  text-align: center;
  caret-color: ${tiffany};
`;

export const CheckCaptionViewer = styled.div`
  height: auto;
  padding: 0.5rem 0;
  outline: none;
  font-size: 16px;
  text-align: center;
  background-color: ${grey_light_setting};

  pre {
    font-family: inherit;
  }
`;

export const SetDefaultButton = styled.button`
  border: none;
  outline: none;
  margin-left: 0.75rem;
  font-family: ${font_book};
  text-align: left;
  text-decoration: underline;
  color: ${({ disabled }) => (disabled ? grey : "navy")};
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")} !important;
`;

export const SetDefaultButtonContainer = styled.div`
  width: 300px;
`;
