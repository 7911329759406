import styled from "styled-components";
import { font_book } from "assets/style/fonts.variables";
import {
  grey,
  grey_light,
  grey_light_setting,
  gradient_green,
  oshad_green,
  red,
  tiffany,
  white,
} from "assets/style/variables";

export const KeyValueListEditorContainer = styled.div`
  position: relative;
`;

export const KeyValueListEditor = styled.div`
  border: 1px solid
    ${({ error }) => (error ? "rgba(255, 0, 0, 0.3)" : grey_light)};
  border-radius: 0.75rem;
  height: 15rem;
  margin-bottom: 1rem;
  overflow: hidden;

  p {
    color: ${red} !important;
  }

  .key-value-list-editor-caption {
    position: absolute;
    left: 1rem;
    top: -0.85rem;
    color: ${({ error }) => (error ? red : grey)};
  }

  .key-value-add-new {
    position: absolute;
    right: 1rem;
    bottom: -1.25rem;

    button {
      position: relative;
      border: none;
      outline: none;
      background-color: transparent;
      color: transparent;
      width: 0;
      height: 0;

      &::after {
        content: "Додати елемент списку";
        position: absolute;
        z-index: 1;
        top: 0;
        right: -0.5rem;
        width: auto;
        padding: 0.25rem 0.5rem;
        white-space: nowrap;
        background: ${white};
        border: 1px solid ${oshad_green};
        border-radius: 0.5rem;
        color: ${oshad_green};
        cursor: pointer;
        font-family: ${font_book};
        font-size: 1rem;
        transition: 0.2s;
      }

      &:hover::after {
        border-color: transparent;
        background: ${gradient_green};
        color: ${white};
      }
    }
  }

  .key-value-rows {
    width: 100%;
    max-height: calc(15rem - 2px);
    padding: 1rem 0.5rem 0.5rem;
    overflow: auto;

    .key-value-row {
      display: grid;
      grid-template-columns: 1fr 1fr 2rem;

      &:nth-child(odd) {
        background-color: ${grey_light_setting};
      }

      &:nth-child(even) {
        background-color: #fbfbfb;
      }

      &:hover {
        background-color: ${grey_light};

        .key-value-row-remove {
          button {
            display: initial;
            opacity: 1;
            cursor: pointer;
          }
        }
      }

      &:has(input:focus) {
        background-color: #dfe;

        .key-value-row-remove {
          button {
            display: initial;
            cursor: pointer;
            opacity: 1;
          }
        }

        input {
          border: 1px solid ${grey};
          border-radius: 0.5rem;
          padding: 0.5rem;
        }
      }

      &:last-child {
        .key-value-row-key-item,
        .key-value-row-value-item,
        .key-value-row-remove {
          border-bottom: none;
        }
      }
    }

    .key-value-row-key-item,
    .key-value-row-value-item,
    .key-value-row-remove {
      padding: 0.5rem;
    }

    .key-value-row-key-item {
      width: 100%;
    }

    .key-value-row-value-item {
      width: 100%;
    }

    .key-value-row-remove {
      button {
        position: relative;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        color: transparent;
        background-color: transparent;
        opacity: 0;
        transition: all 0.3s ease;

        &::after {
          content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 32 32"><path d="M 15 4 C 14.476563 4 13.941406 4.183594 13.5625 4.5625 C 13.183594 4.941406 13 5.476563 13 6 L 13 7 L 7 7 L 7 9 L 8 9 L 8 25 C 8 26.644531 9.355469 28 11 28 L 23 28 C 24.644531 28 26 26.644531 26 25 L 26 9 L 27 9 L 27 7 L 21 7 L 21 6 C 21 5.476563 20.816406 4.941406 20.4375 4.5625 C 20.058594 4.183594 19.523438 4 19 4 Z M 15 6 L 19 6 L 19 7 L 15 7 Z M 10 9 L 24 9 L 24 25 C 24 25.554688 23.554688 26 23 26 L 11 26 C 10.445313 26 10 25.554688 10 25 Z M 12 12 L 12 23 L 14 23 L 14 12 Z M 16 12 L 16 23 L 18 23 L 18 12 Z M 20 12 L 20 23 L 22 23 L 22 12 Z"></path></svg>');
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid ${grey_light};
      outline: none;
      font-family: ${font_book};
      font-size: 1.125rem;
      caret-color: ${tiffany};
      background-color: transparent;
    }
  }
`;
