import styled from "styled-components";

import { font_book } from "assets/style/fonts.variables";

export const Button = styled.button`
  display: flex;
  gap: 0.65rem;
  align-items: center;
  height: auto;
  width: auto;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  white-space: nowrap;
  background-color: transparent;
  font-family: ${font_book};
  font-size: 1rem;
`;
