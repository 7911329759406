import { TableContainer, Td } from "components/layouts/Table/Table.styles";
import {
  TrMain,
  Terminal,
  TerminalText,
  TerminalTitle,
  TerminalDescribe,
  TableTitle,
} from "./TerminalTable.styles";
import { useEffect } from "react";
import {
  fetchServer,
  setFilter,
  setLocal,
  setServer,
} from "store/terminal_table/terminal_table.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  loadLocal,
  stateLocal,
  isFilter,
} from "store/terminal_table/terminal_table.slicer";
import { panelRef as reduxPanelRef } from "store/main_table/main_table.slicer";
import Preloader from "components/additional/PreLoader/Preloader";
import InfiniteScroll from "react-infinite-scroller";

const Table = ({ title, login }) => {
  const dispatch = useDispatch();
  const isLocalLoaded = useSelector(loadLocal);
  const state = useSelector(stateLocal);
  const isMore = useSelector(isFilter);
  const panelRef = useSelector(reduxPanelRef);

  useEffect(() => {
    dispatch(setServer([]));
    dispatch(setLocal([]));
    dispatch(setFilter(false));
    dispatch(fetchServer(login, 1));
  }, [dispatch, login]);

  function fetchMoreItems() {
    dispatch(fetchServer(login, state.length + 1));
  }

  if (!isLocalLoaded && state.length === 0) return null;

  return (
    <>
      <TableTitle>{title}</TableTitle>
      <InfiniteScroll
        pageStart={0}
        initialLoad={true}
        useWindow={true}
        loadMore={fetchMoreItems}
        hasMore={isMore}
        loader={<Preloader key="preloader" />}
        getScrollParent={() => panelRef}
        style={{
          width: "100%",
          overflowX: "auto",
        }}
      >
        <TableContainer
          style={{
            margin: "0",
            width: "100%",
            overflowX: "auto",
          }}
        >
          <thead>
            <TrMain>
              <th>Дата і Час</th>
              <th>Термінал</th>
              <th>Пристрій</th>
              <th>Статус</th>
            </TrMain>
          </thead>
          <tbody>
            {state &&
              state.map((item, i) => {
                return (
                  <TrMain column={true} key={i}>
                    <Td>
                      <Terminal>
                        <TerminalText>
                          <TerminalTitle>
                            {item.ctime.split(" ")[0]}
                          </TerminalTitle>
                          <TerminalDescribe>
                            {item.ctime.split(" ")[1]}
                          </TerminalDescribe>
                        </TerminalText>
                      </Terminal>
                    </Td>
                    <Td style={{ marginLeft: "35px" }}>
                      <Terminal>
                        <TerminalText>
                          <TerminalTitle>
                            {item.ptdescription || "-"}
                          </TerminalTitle>
                          <TerminalDescribe>
                            {item.ptaddress || "-"}
                          </TerminalDescribe>
                        </TerminalText>
                      </Terminal>
                    </Td>
                    <Td style={{ marginLeft: "14px" }}>
                      <TerminalText>
                        <TerminalTitle>{item.device_model}</TerminalTitle>
                        <TerminalDescribe>
                          Terminal ID: {item.ptname || "-"}
                        </TerminalDescribe>
                      </TerminalText>
                    </Td>
                    <Td>
                      <TerminalText>
                        <TerminalTitle right>{item.login_result}</TerminalTitle>
                        <TerminalDescribe right>
                          {item.login_message}
                        </TerminalDescribe>
                      </TerminalText>
                    </Td>
                  </TrMain>
                );
              })}
          </tbody>
        </TableContainer>
      </InfiniteScroll>
    </>
  );
};

export default Table;
