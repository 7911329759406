import styled from "styled-components";

import { font_book, font_demi } from "assets/style/fonts.variables";
import { black, grey } from "assets/style/variables";
import { size } from "assets/style/global-variables";

import { Logo } from "../Navigation/Navigation.styles";

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;

  @media screen and (max-width: ${size.laptop}) {
    justify-content: flex-start;
    margin-bottom: ${({ isMobile }) => (isMobile ? "0" : "40px")};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;

  align-items: center;

  & > *:last-child {
    flex-shrink: 1;

    @media screen and (max-width: ${size.laptop}) {
      position: ${({ isMobile }) => (isMobile ? "static" : "absolute")};
      top: 72px;
      left: 0;
    }
  }

  @media screen and (min-width: ${size.laptop}) and (max-width: ${size.laptopL}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  font-size: 2.5em;
  font-family: ${font_demi};
  font-weight: 500;
  color: ${black};
  display: flex;
  grid-gap: 20px;
  align-items: center;

  @media screen and (max-width: ${size.laptop}) {
    font-size: 24px;
  }

  @media screen and (max-width: ${size.tablet}) {
    font-size: 18px;
  }

  @media screen and (max-width: ${size.mobileL}) {
    font-size: 16px;
  }
`;

const TextContainer = styled.div`
  ${(props) => (props.left ? "margin-left: 90px;" : "")}

  @media screen and (max-width: ${size.laptop}) {
    margin-left: 20px;
  }

  @media screen and (max-width: ${size.tablet}) {
    margin-left: 15px;
  }
`;

const SubTitle = styled.p`
  color: ${grey};
  font-weight: 400;
  font-family: ${font_book};
  font-size: 18px;
`;

const HeaderLogo = styled(Logo)`
  display: none;
  margin: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;

  @media screen and (max-width: ${size.laptop}) {
    display: block;
  }
`;

const BurgerMenu = styled.button`
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border: none;
  border-radius: 5px;

  @media screen and (max-width: ${size.laptop}) {
    display: flex;
    /* margin-left: 30px; */
  }

  /* @media screen and (max-width: ${size.tablet}) {
    margin-left: 20px;
  }

  @media screen and (max-width: ${size.tablet}) {
    margin-left: 10px;
  } */
`;

const Badge = styled.span`
  position: absolute;
  right: -0.35rem;
  top: -0.35rem;
  display: block;
  width: 1.15rem;
  height: 1.15rem;
  border-radius: 50%;
  font-size: 0.65rem;
  font-weight: bold;
  line-height: 1.15rem;
  text-align: center;
  font-family: sans-serif;
  background-color: orange;
  color: white;
`;

const StatSelectorWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export {
  SubTitle,
  TextContainer,
  Title,
  TitleContainer,
  HeaderContainer,
  HeaderLogo,
  BurgerMenu,
  Badge,
  StatSelectorWrapper,
};
