import axios from "axios";
import { toast } from "utils/toast/toast";

class Fetch {
  async post(url, data, headers) {
    if (!headers) headers = new Headers();
    headers.append("Content-Type", "application/json");
    const response = await fetch(url, {
      method: "POST",
      headers,
      redirect: "follow",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async postImage(url, data, headers) {
    if (!headers) headers = new Headers();
    const response = await fetch(url, {
      method: "POST",
      headers,
      redirect: "follow",
      body: data,
    });
    return response.json();
  }

  async put(url, data, headers) {
    if (!headers) headers = new Headers();
    headers.append("Content-Type", "application/json");
    const response = await fetch(url, {
      method: "PUT",
      headers,
      redirect: "follow",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async postBlob(url, data, headers) {
    if (!headers) headers = new Headers();
    headers.append("Content-Type", "application/json");
    const response = await fetch(url, {
      method: "POST",
      headers,
      redirect: "follow",
      body: JSON.stringify(data),
    });
    return response.blob();
  }

  async postBlobWithAxios(url, data, headers) {
    let result;

    try {
      result = await axios({
        baseURL: window.location.origin,
        url,
        method: "POST",
        redirect: "follow",
        data,
        responseType: "blob",
        headers,
      });

      let fileName = result.headers["content-disposition"]
        ? result.headers["content-disposition"]
            .split(";")
            .map((item) => item.trim())
            .filter((item) => item.startsWith("filename"))
            .filter((item) => !item.includes("*="))[0]
            ?.split("=")[1]
        : "";

      if (fileName.startsWith('"')) fileName = fileName.substring(1);
      if (fileName.endsWith('"'))
        fileName = fileName.substring(0, fileName.length - 1);

      return { fileData: result.data, fileName };
    } catch (e) {
      if (e.response?.data && e.response.data.type === "application/json") {
        const r = new FileReader();

        r.onload = () => {
          const response = JSON.parse(r.result);
          const { ErrorMessage } = response;

          toast.errorMessage(ErrorMessage || e.message);
        };
        r.readAsText(e.response.data);
      } else {
        toast.errorMessage(e.message);
      }
    }

    return result;
  }

  async get(url, headers) {
    if (!headers) headers = new Headers();

    return fetch(url, { headers }).then((response) => {
      return response.json();
    });
  }

  async getBlob(url, headers) {
    if (!headers) headers = new Headers();

    const response = await fetch(url, { headers });
    return response.blob();
  }

  async delete(url, token, deviceid) {
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: deviceid,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => data);
  }

  async postDelete(url, data, headers) {
    if (!headers) headers = new Headers();
    headers.append("Content-Type", "application/json");
    const response = await fetch(url, {
      method: "DELETE",
      headers,
      body: JSON.stringify(data),
    });
    return response.json();
  }
}

export default Fetch;
