import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "components/forms";
import { STAT_OPTIONS } from "../constants";
import { setStatType } from "../../../../store/statistics/stat_actions";
import { selectCurrentStatType } from "../../../../store/statistics/stat_slicer";

const StatTypeSelect = () => {
  const dispatch = useDispatch();
  const statType = useSelector(selectCurrentStatType);
  const statTypeValue = useMemo(
    () => STAT_OPTIONS.find(({ value }) => value === statType),
    [statType]
  );
  const onStatTypeChange = ({ value }) => dispatch(setStatType(value));

  return (
    <Select
      options={STAT_OPTIONS}
      value={statTypeValue}
      onChange={onStatTypeChange}
    />
  );
};

export default StatTypeSelect;
