import React from "react";

// components
import Table from "./FiscalClosed.table";

// styles
import { FiscalClosedContainer } from "./FiscalClosed.table.styles";

const FiscalClosed = ({ setShifts }) => {
  return (
    <FiscalClosedContainer>
      <Table {...{ setShifts }} />
    </FiscalClosedContainer>
  );
};

export default FiscalClosed;
