import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Navigation, Panel } from "components/layouts";
import { DashBoard } from "./DashBoard.styles";
import MessagePopupComponent from "pages/Panels/News/components/NewsDetailsLink/NewsPopupContent";
import withHistory from "pages/Panels/News/components/NewsDetailsLink/withHistory";

import { loadNews } from "store/news_table/news_table.actions";

export default function DashboardPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const timerId = setInterval(
      () => dispatch(loadNews(withHistory(MessagePopupComponent, history))),
      60000
    );

    return () => clearInterval(timerId);
  }, []);

  return (
    <DashBoard>
      <Navigation />
      <Panel />
    </DashBoard>
  );
}
