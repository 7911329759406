import React from "react";

import {
  Radio,
  RadioContainer,
  RadioLabel,
  RadioTitle,
} from "./RadioButton.styles";

const RadioButton = ({
  title,
  labelPosition,
  checked,
  value,
  name,
  disabled,
  color = "#28ccc6",
  disabledColor = "#c5c5c5",
  onClick,
}) => {
  return (
    <RadioLabel labelPosition={labelPosition}>
      <RadioTitle>{title}</RadioTitle>
      <RadioContainer {...{ checked, disabled, color, disabledColor }}>
        <Radio
          name={name}
          value={value}
          disabled={disabled}
          onClick={onClick}
        />
      </RadioContainer>
    </RadioLabel>
  );
};

export default RadioButton;
