import { font_book } from "assets/style/fonts.variables";
import { grey, white } from "assets/style/variables";
import { ButtonSecondary } from "components/forms/Button/Button.styles";
import styled from "styled-components";

export const FiscalOpenContainer = styled.section`
  .subtotal {
    margin: 20px 0;
  }
`;

export const TrMain = styled.tr`
  display: grid;
  grid-template-columns: 1.5fr 1.2fr 1fr 1fr 1fr 1fr;
  th {
    text-align: left;
    color: ${grey};
    font-family: ${font_book};
    font-size: 14px;
    font-weight: 400;
  }

  td:last-child {
    align-self: center;
  }
  ${(props) =>
    props.column
      ? `
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 12px;
  margin-top: 12px;
  &:hover{
    cursor: pointer;
  }
  `
      : null}
`;

export const Button = styled(ButtonSecondary)`
  font-size: 14px;
  font-family: ${font_book};
  font-weight: 400;
  color: ${white};
  background: #0c615a; /*${white};*/
  border: 1px solid #0c615a; /*rgba(112, 120, 127, 0.2);*/
  border-radius: 10px;
  padding: 8px 30px;

  /* ${(props) =>
    props.disabled ? `cursor: not-allowed;  background: #eee;` : ""} */

  ${(props) =>
    props.disabled
      ? `cursor: not-allowed;  background: #eee; border: 1px solid rgba(112, 120, 127, 0.2);`
      : ""}

  &:disabled {
    background-color: #ccc !important;
    cursor: not-allowed !important;
  }
`;
