import { combineReducers } from "@reduxjs/toolkit";
import mainTableReducer from "store/main_table/main_table.slicer";
import bankTableReducer from "./bank_table/bank_table.slicer";
import fiscalTableReducer from "./fiscal_table/fiscal_table.slicer";
import cashierTableReducer from "./cashier_table/cashier_table.slicer";
import goodsTableReducer from "./goods_table/goods_table.slicer";
import TerminalTableReducer from "./terminal_table/terminal_table.slicer";
import transactionTableReducer from "./transaction_table/transaction_table.slicer";
import cashTableReducer from "./cash_table/cash_table.slicer";
import alertReducer from "./alert/alert_slicer";
import initialModalsReducer from "./initial_modals/initial_modals_slicer";
import userReducer from "./user/user_slicer";
import newsTableReducer from "./news_table/news_table.slicer";
import uiReducer from "./ui/ui.slicer";
import statReducer from "./statistics/stat_slicer";

const rootReducer = combineReducers({
  main_table: mainTableReducer,
  terminal_table: TerminalTableReducer,
  transaction_table: transactionTableReducer,
  bank_table: bankTableReducer,
  fiscal_table: fiscalTableReducer,
  cashier_table: cashierTableReducer,
  goods_table: goodsTableReducer,
  cash_table: cashTableReducer,
  alert: alertReducer,
  initial_modals: initialModalsReducer,
  user: userReducer,
  news_table: newsTableReducer,
  ui: uiReducer,
  statistics: statReducer,
});

export default rootReducer;
