import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "ui/Modal/Modal";
import { KeyExpirationModal } from "components/initial_modals/KeyExpirationProvider/KeyExpirationModal/KeyExpirationModal";

import { onCloseInitialModal } from "store/initial_modals/initial_modals_actions";
import { selectUser } from "store/user/user_slicer";
import { stateFiskal } from "store/transaction_table/transaction_table.slicer";

export const KeyExpirationProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { initial_modals_list, active_initial_modal_index } = useSelector(
    (state) => state.initial_modals
  );
  const { outOfDateKeys } = useSelector(selectUser);
  const isFiskal = useSelector(stateFiskal);

  const modalIsActive =
    initial_modals_list[active_initial_modal_index] &&
    initial_modals_list[active_initial_modal_index].id ===
      "key-expiration-modal";

  if (!(isFiskal && outOfDateKeys.length > 0)) {
    return children;
  }

  return (
    <>
      {children}
      {modalIsActive && (
        <Modal
          closeOnBackdrop={false}
          onClose={() => dispatch(onCloseInitialModal())}
          className={"key-expiration"}
        >
          <KeyExpirationModal onClose={() => dispatch(onCloseInitialModal())} />
        </Modal>
      )}
    </>
  );
};
