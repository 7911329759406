import axios from "axios";
import { LocalStorage } from "services";
import Fetch from "services/Fetch/Fetch.service";
import { URL_SERVER } from "utils/constants/server.constants";

export class CashierTable {
  constructor() {
    this.fetch = new Fetch();
    this.localStorage = new LocalStorage();
  }

  generateHeader() {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    return headers;
  }

  get() {
    const headers = this.generateHeader();

    return this.fetch
      .get(`${URL_SERVER}/api/User/ALL?status=0`, headers)
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  add(body) {
    const headers = this.generateHeader();

    return this.fetch
      .post(`${URL_SERVER}/api/User`, body, headers)
      .then((res) => res)
      .catch((error) => console.error(error));
  }
  edit(body) {
    const headers = this.generateHeader();

    return this.fetch
      .put(`${URL_SERVER}/api/User`, body, headers)
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  remove(id) {
    return this.modify("Delete", id);
  }

  block(id) {
    return this.modify("Suspend", id);
  }
  activate(id) {
    return this.modify("Activate", id);
  }

  modify(method, id) {
    const headers = this.generateHeader();
    return this.fetch
      .put(`${URL_SERVER}/api/User/${method}/${id}`, null, headers)
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  getPhoto({ login }) {
    const headers = this.generateHeader();

    return this.fetch
      .getBlob(`${URL_SERVER}/api/Photo/${login}`, headers)
      .then((res) => {
        const img = URL.createObjectURL(res);
        return img;
      })
      .catch((error) => console.error(error));
  }

  getKey(clientId) {
    const headers = this.generateHeader();

    return this.fetch
      .get(`${URL_SERVER}/api/Dfs/CheckKey/${clientId}`, headers)
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  deleteKey({ key_id }) {
    const token = localStorage.getItem("token");
    const device = localStorage.getItem("device");
    const url = `${URL_SERVER}/api/Dfs/DeleteKey/${key_id}`;

    return axios.post(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Token: token,
          DeviceId: "WEB_" + device,
        },
      }
    );
  }

  getRRO(clientId) {
    const headers = this.generateHeader();
    return this.fetch
      .get(`${URL_SERVER}/api/Dfs/GetRroIdByClientId/${clientId}`, headers)
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  async postAddKey(body) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    return axios.post(`${URL_SERVER}/api/Dfs/AddKey`, body, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });
  }

  async setChiefCashier({ keyId, isChief }) {
    const token = localStorage.getItem("token");
    const device = localStorage.getItem("device");
    const url = `${URL_SERVER}/api/Dfs/SetChiefCashier/${keyId}/${isChief}`;

    return axios.post(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Token: token,
          DeviceId: "WEB_" + device,
        },
      }
    );
  }

  async getMerchantKeys(merchantId) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    return axios
      .get(`${URL_SERVER}/api/Dfs/MerchantKeysExpired/${merchantId}`, {
        headers: {
          "Content-Type": "application/json",
          Token: token,
          DeviceId: "WEB_" + device,
        },
      })
      .then((res) => res?.data || [])
      .catch((error) => {
        console.error(error.message);
      });
  }
}
