import { useEffect } from "react";
import {
  ButtonAdd,
  CashiersContainer,
  CashiersHeader,
} from "./Cashiers.styles";
import Table from "./Cashiers.table";

import { ReactComponent as PlusIcon } from "assets/icons/plus-tiffany.svg";
import { useHeader } from "store/header/header.context";
import ChangeTitle from "utils/helpers/changetitle.helpers";
import { useHistory } from "react-router-dom";
import { CASHIERS_ROUTE } from "utils/constants/routes.constants";
import { useDispatch } from "react-redux";
import { setItem } from "store/cashier_table/cashier_table.actions";

export default function Cashiers() {
  const headerDispatch = useHeader().dispatch;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    ChangeTitle("Управління касирами", headerDispatch);
  }, [headerDispatch]);

  return (
    <CashiersContainer>
      <CashiersHeader>
        <ButtonAdd
          onClick={() => {
            dispatch(setItem(null));
            history.push(CASHIERS_ROUTE + "/add");
          }}
        >
          <PlusIcon />
          Додати касира
        </ButtonAdd>
      </CashiersHeader>

      <Table />
    </CashiersContainer>
  );
}
