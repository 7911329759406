import { INCREMENT_INITIAL_MODAL_INDEX } from "./initial_modals_types";

import {
  DisabledFiscalizationModalProvider,
  KeyExpirationProvider,
  LimitedWorkingModalProvider,
} from "components/initial_modals";

const initialState = {
  initial_modals_list: [
    {
      id: "disabled-fiscalization-modal",
      provider: DisabledFiscalizationModalProvider,
    },
    {
      id: "key-expiration-modal",
      provider: KeyExpirationProvider,
    },
    {
      id: "limited-working-modal",
      provider: LimitedWorkingModalProvider,
    },
  ],
  active_initial_modal_index: 0,
};

const initialModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_INITIAL_MODAL_INDEX:
      return {
        ...state,
        active_initial_modal_index: state.active_initial_modal_index + 1,
      };
    default:
      return state;
  }
};

export default initialModalReducer;
