import styled from "styled-components";

export const BankDetailContainer = styled.section`
  .width-50 {
    width: 50%;
  }
  .subtotal {
    margin-bottom: 20px;
  }
`;

export const BankDetailActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
