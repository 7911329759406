import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";

// functions
import { getUUID } from "utils/helpers/getUUID";

// assets
import { ReactComponent as SelectIcon } from "assets/icons/terminalDetail/select.svg";

// styles
import {
  CommonSelectContainer,
  Select,
  SelectContainer,
  SelectItem,
  SelectTitle,
} from "./select.styles";

const PADDING_FROM_WINDOW_BORDER = 30;

const SelectComponent = (props) => {
  const { data } = props;
  const {
    placeholder,
    disabled,
    selectedCaption,
    caption,
    listInfo,
    onSelectItem,
    style,
    svgStyle,
    listStyle,
    renderingType,
    subItemIcon: SubItemIcon,
  } = data;
  const { items, data_id, data_uk, data_caption } = listInfo;

  const selectRef = useRef(getUUID());
  const [isOpened, setIsOpened] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    if (isOpened && selectRef && selectRef.current) {
      const htmlPosition = selectRef.current.getBoundingClientRect();
      setMaxHeight(
        Math.floor(
          window.innerHeight -
            htmlPosition.top -
            htmlPosition.height -
            PADDING_FROM_WINDOW_BORDER
        )
      );
    }

    const onCloseSelect = (event) => {
      if (event.keyCode === 27) {
        setIsOpened(false);
      }
    };

    if (isOpened) {
      document.addEventListener("keydown", onCloseSelect);
    }

    return () => {
      document.removeEventListener("keydown", onCloseSelect);
    };
  }, [isOpened]);

  const showList = !disabled && isOpened;

  return (
    <CommonSelectContainer
      ref={selectRef}
      onClick={!disabled && !isOpened ? () => setIsOpened(true) : null}
      onMouseLeave={!disabled ? () => setIsOpened(false) : null}
    >
      <Select
        style={style}
        className={cn("input-active", { value: !!selectedCaption })}
        disableAction={!disabled}
        value={selectedCaption ? selectedCaption : placeholder}
        onChange={() => {}}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            setIsOpened(true);
          }
        }}
      />
      <SelectTitle>{caption}</SelectTitle>
      <SelectContainer show={showList} maxHeight={maxHeight} style={listStyle}>
        {items &&
          items.length !== 0 &&
          items.map((item) => {
            return (
              <SelectItem
                data-id={item[data_id]}
                data-uk={item[data_uk]}
                key={item[data_id]}
                onClick={() => {
                  setIsOpened(false);
                  onSelectItem(item);
                }}
                style={{
                  paddingLeft:
                    renderingType === "category"
                      ? `${item.deepIndex * 30 + 5}px`
                      : "5px",
                  fontWeight: item.deepIndex === 0 ? "bold" : "normal",
                }}
              >
                {item.deepIndex > 0 && SubItemIcon && <SubItemIcon />}
                {` ${item[data_caption]}`}
              </SelectItem>
            );
          })}
      </SelectContainer>
      <SelectIcon className="select-icon" style={svgStyle} />
    </CommonSelectContainer>
  );
};

export default SelectComponent;
