import styled from "styled-components";

import { tiffany, white, black } from "assets/style/variables";

export const ButtonContainer = styled.div`
  border-radius: 10px;
  padding: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  &:hover {
    box-shadow: ${(props) =>
      props.disabled ? "" : "0px 1px 8px rgba(0, 0, 0, 0.5)"};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
  transition: transform 0.2s ease, opacity 0.3s ease;

  ${(props) =>
    props.opened
      ? `transform: scale(1); opacity: 1;`
      : `transform: scale(0); opacity: 0;`}
`;

export const DeleteButton = styled(ButtonContainer)`
  position: fixed;
  right: 210px;
  bottom: 30px;
  background: rgb(239, 55, 67);
  svg {
    width: 20px;
    path {
      fill: #000;
    }
  }
`;

export const TransferButton = styled(ButtonContainer)`
  position: fixed;
  right: 260px;
  bottom: 30px;
  background: #fcba03;
  svg {
    width: 20px;
    path {
      fill: #000;
    }
  }
`;

export const CheckAllButton = styled(ButtonContainer)`
  position: fixed;
  right: ${(props) => (props.isOnly ? "110px" : "160px")};
  bottom: 30px;
  font-size: larger;
  background: ${(props) => (props.disabled ? "#d1d1d1" : tiffany)};
  color: ${(props) => (props.disabled ? white : black)};
`;

export const CancelButton = styled(ButtonContainer)`
  position: fixed;
  right: 110px;
  bottom: 30px;
  background: #b1b1b1;
  svg {
    width: 20px;
    path {
      fill: #000;
    }
  }
`;
