import { useRef, useState } from "react";

import { TransactionTable } from "api";
import download from "../../../utils/helpers/download.helpers";

const useXReceipt = (rro_id) => {
  const [opened, setOpened] = useState(false);
  const [loadingXReceiptHTML, setLoadingXReceiptHTML] = useState(false);
  const [loadingXReceiptPDF, setLoadingXReceiptPDF] = useState(false);
  const [showAlert, setShowAlert] = useState(null);

  const iframeRef = useRef();

  const FORMAT = {
    html: "HTML",
    pdf: "PDF",
  };

  const getXReceipt = async () => {
    if (opened) {
      setOpened((val) => !val);
      const document = iframeRef.current.contentWindow.document;

      document.open();
      document.write("");
      document.close();
    } else {
      setLoadingXReceiptHTML(true);

      try {
        const receipt = await new TransactionTable().getXReceipt(
          rro_id,
          FORMAT.html
        );
        const xReceipt = await receipt.text();

        if (iframeRef.current) {
          const iframe = iframeRef.current;
          const {
            contentWindow: { document },
            style,
          } = iframe;

          iframe.onload = function () {
            style.height = +document.body.scrollHeight + "px";
          };

          document.open();
          document.write(xReceipt || "");
          document.close();
        }

        setOpened((val) => !val);
      } catch (error) {
        setShowAlert({ message: "Помилка запиту Х-звіту" });
      } finally {
        setLoadingXReceiptHTML(false);
      }
    }
  };

  const getXReceiptPDF = async () => {
    setLoadingXReceiptPDF(true);
    try {
      const receipt = await new TransactionTable().getXReceipt(
        rro_id,
        FORMAT.pdf
      );

      await download(receipt, "x-receipt.pdf");
    } catch (error) {
      setShowAlert({ message: "Помилка завантаження Х-звіту" });
    } finally {
      setLoadingXReceiptPDF(false);
    }
  };

  return {
    opened,
    loadingXReceiptHTML,
    loadingXReceiptPDF,
    showAlert,
    setShowAlert,
    iframeRef,
    getXReceipt,
    getXReceiptPDF,
  };
};

export default useXReceipt;
