import React, { useState } from "react";

import {
  KeyContainer,
  KeyWindow,
  KeyOptions,
  // SelectContainer,
  // SelectLabel,
} from "./KeyModal.styles";
import TextFieldFile from "components/forms/TextField/TextFieldFile";
import { TextFieldPassw } from "components/forms";
import {
  Button,
  ButtonSecondaryBorder,
} from "components/forms/Button/Button.styles";
import Preloader from "components/additional/PreLoader/Preloader";
import {
  CashButtonContainer,
  MainCashierCheckInformationItem,
  MainCashierCheckNoteItem,
  FootnoteItemContainer,
  OneLineFootnoteItem,
  FootnoteItem,
} from "../CashiersDetail/CashiersDetail.styles";
import Alert from "ui/Alert/Alert";
// import Select from "components/forms/Select/ReactSelect/Select";
// import { PB_ROLES, checkIfIsPBKey } from "./helpers";
// import { NODE_ENV } from "utils/constants/server.constants.js";

//DEBUG-TAP-212
// const IS_NOT_PROD = NODE_ENV !== "production";

const signatureExtensions = [".jks", ".dat", ".pfx", ".ZS2"];

const KeyModal = ({ onClose, modalData, onUpload }) => {
  const { loading, isOnMainCashierPosition } = modalData;
  const [keyFile, setKeyFile] = useState(null);
  const [keyFileName, setKeyFileName] = useState(null);
  // const [isPBKey, setIsPBKey] = useState(false);
  const [signatureFile, setSignatureFile] = useState(null);
  const [signatureFileName, setSignatureFileName] = useState(null);
  const [encryptionFile, setEncryptionFile] = useState(null);
  const [encryptionFileName, setEncryptionFileName] = useState(null);
  const [keyPassword, setKeyPassword] = useState("");
  const [isMainCashier, setIsMainCashier] = useState(isOnMainCashierPosition);
  const [askForSettingMainCashierFlag, setAskForSettingMainCashierFlag] =
    useState(false);
  // const [keyRole, setKeyRole] = useState(null);

  const handleClose = () => {
    onClose();
  };

  const uploadKeyHandler = (forceMain) => {
    onUpload({
      keyFile,
      signatureFile,
      encryptionFile,
      keyPassword,
      isMainCashier: forceMain ? true : isMainCashier,
      // keyRole: keyRole?.value,
      keyFileName,
      signatureFileName,
      encryptionFileName,
    });
  };

  const handleUpload = () => {
    if (isOnMainCashierPosition && !isMainCashier) {
      setAskForSettingMainCashierFlag(true);
    } else {
      uploadKeyHandler();
    }
  };

  const handleSetKeyFile = (content, name) => {
    setKeyFile(content);
    setKeyFileName(name);
  };
  const handleSetSignatureFile = (content, name) => {
    setSignatureFile(content);
    setSignatureFileName(name);
  };
  const handleSetEncryptionFile = (content, name) => {
    setEncryptionFile(content);
    setEncryptionFileName(name);
  };

  const uploadButtonIsActive = !!keyFile && keyPassword && keyPassword !== "";

  // const keyRoleOptions = useMemo(
  //   () =>
  //     Object.entries(PB_ROLES).map(([key, value]) => ({
  //       key_role: key,
  //       value: key,
  //       label: value,
  //     })),
  //   [PB_ROLES]
  // );

  // useEffect(() => {
  //   if (IS_NOT_PROD) {
  //     const isPBKey = checkIfIsPBKey(keyFile, keyFileName);

  //     setIsPBKey(isPBKey);

  //     if (isPBKey)
  //       setKeyRole(keyRoleOptions.find((item) => item.value === "personal"));
  //   }
  // }, [keyFile, keyFileName]);

  const noCertificates = !keyFileName || keyFileName.endsWith(".jks");

  return (
    <KeyContainer>
      {askForSettingMainCashierFlag && (
        <Alert
          {...{
            type: "remove",
            title: "Увага!",
            subtitle:
              'Для Головного касира рекомендується надати ключу ознаку "Старший касир". В іншому випадку деякі функції можуть бути недоступні.',
            fnClose: () => {
              setAskForSettingMainCashierFlag(false);
              uploadKeyHandler();
            },
            fnSubmit: () => {
              setIsMainCashier(true);
              setAskForSettingMainCashierFlag(false);
              uploadKeyHandler(true);
            },
            cancelButtonLabel: 'Зберегти без "Старший касир"',
            acceptButtonLabel: 'Встановити "Старший касир"',
            height: "500px",
          }}
        />
      )}
      <KeyWindow onClick={(e) => e.stopPropagation()}>
        <KeyOptions>
          <TextFieldFile
            fn={(content, name) => handleSetKeyFile(content, name)}
            isEdit
            text="Особистий ключ"
            id="key_file"
            accept={signatureExtensions}
            disabled={loading}
          />
          <TextFieldFile
            fn={(content, name) => handleSetSignatureFile(content, name)}
            isEdit
            className="file-field"
            text="Сертифікат для підпису *"
            id="key_file_one"
            disabled={noCertificates || loading}
          />
          <TextFieldFile
            fn={(content, name) => handleSetEncryptionFile(content, name)}
            isEdit
            className="file-field"
            text="Сертифікат для шифрування *"
            id="key_file_two"
            disabled={noCertificates || loading}
          />
          <TextFieldPassw
            value={keyPassword}
            fn={setKeyPassword}
            className="input-active"
            name="Пароль захисту ключа"
            disabled={loading}
          />

          <MainCashierCheckInformationItem>
            <div className="main-cashier-checkbox">
              <input
                type="checkbox"
                id="main-cashier-check"
                checked={isMainCashier}
                onChange={() => setIsMainCashier((v) => !v)}
                disabled={loading}
              />
              <label htmlFor="main-cashier-check" className="title">
                Старший касир
              </label>
            </div>
            <MainCashierCheckNoteItem>
              Тип підпису касира повинен бути того ж типу, що вказаний у Формі №
              5-ПРРО, яку ви подаєте в кабінеті податкової.
            </MainCashierCheckNoteItem>
          </MainCashierCheckInformationItem>
          {/* {IS_NOT_PROD && isPBKey && (
          <SelectContainer>
            <SelectLabel>Роль ключа</SelectLabel>
            <Select
              placeholder="роль ключа"
              options={keyRoleOptions}
              value={keyRole}
              onChange={(value) => setKeyRole(value)}
            />
          </SelectContainer>
        )} */}
        </KeyOptions>
        {loading && <Preloader style={{ marginTop: "0px" }} />}
        {!loading && (
          <CashButtonContainer style={{ marginTop: "10px" }}>
            <Button onClick={handleUpload} disabled={!uploadButtonIsActive}>
              Завантажити ключ
            </Button>
          </CashButtonContainer>
        )}

        <CashButtonContainer
          style={{
            marginTop: "10px",
          }}
        >
          <ButtonSecondaryBorder
            onClick={handleClose}
            disabled={loading}
            className="cancel-button"
          >
            Скасувати
          </ButtonSecondaryBorder>
        </CashButtonContainer>
        <FootnoteItemContainer>
          <OneLineFootnoteItem>* -</OneLineFootnoteItem>
          <FootnoteItem>
            Необов'язкові поля для заповнення. Сертифікати будуть автоматично
            імпортовані з АЦСК. У разі недоступності АЦСК, ви маєте можливість
            вручну вказати сертифікати
          </FootnoteItem>
        </FootnoteItemContainer>
      </KeyWindow>
    </KeyContainer>
  );
};

export { KeyModal };
