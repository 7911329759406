import moment from "moment";
import { FiskalTable } from "api";

export const getShift = async (terminalId) => {
  let shift = await getOpenShift(terminalId);

  if (shift) return shift;

  return await getClosedShift(terminalId);
};

export const getClosedShift = async (terminalId) => {
  try {
    const shiftInfo = await new FiskalTable().getFiscalDays({
      startDate: moment().add(-30, "days").format("DD.MM.YYYY"),
      endDate: moment().format("DD.MM.YYYY"),
    });
    if (!shiftInfo) {
      return null;
    }

    const {
      data: { errorCode, fiskalDays },
    } = shiftInfo;

    if (!Array.isArray(fiskalDays)) {
      return null;
    }

    const shifts = fiskalDays;

    if (errorCode === 0 && Array.isArray(shifts)) {
      return shifts.find((shift) => shift.terminalId === terminalId);
    } else {
      return null;
    }
  } catch {
    return null;
  }
};

export const getOpenShift = async (terminalId) => {
  try {
    const openShiftInfo = await new FiskalTable().getFiscalsDayDetail();
    if (!openShiftInfo) {
      return null;
    }

    const {
      data: { errorCode, fiskalDetails },
    } = openShiftInfo;

    if (!Array.isArray(fiskalDetails)) {
      return null;
    }

    const openShifts = fiskalDetails.map((item) => ({
      ...item,
      userId:
        item.openClientId === null ? item.closeClientId : item.openClientId,
      userName: item.cashier,
    }));

    if (errorCode === 0 && Array.isArray(openShifts)) {
      return openShifts.find((shift) => shift.terminalId === terminalId);
    } else {
      return null;
    }
  } catch {
    return null;
  }
};

export const checkIfAllShiftTransactionsFiscalOK = async (shift) => {
  const { rroId, openClientId: clientId, terminalId, cashier } = shift;

  try {
    const result = await new FiskalTable().getNotFiscalTransactions({
      rroId,
      clientId,
    });

    return result.data;
  } catch (error) {
    return {
      errorCode: -1,
      errorMessage:
        error.message ||
        `Помилка запиту нефіскалізованих транзакцій терміналу ${terminalId} та касира ${cashier}`,
    };
  }
};

export const checkShiftAtFiscalService = async (shift) => {
  const { rroId } = shift;

  try {
    const result = await new FiskalTable().checkAtFiscalService(rroId);

    return result.data;
  } catch (error) {
    return {
      errorCode: -1,
      errorMessage:
        error.message ||
        "Помилка звірки статистики зміни з Державною податковою службою України",
    };
  }
};
