const TRANSACTION_TYPE = "transaction";
const CARD_TYPE = "card";
const CASH_TYPE = "cash";
const RETURN_TYPE = "return";
const RESULT_TYPE = "result";
const PAYMENT_TYPE = "payment";
const CASHLESS_TYPE = "cashless";
const REVERSED_TYPE = "reversed";
const QR_TYPE = "qr";

export {
  RETURN_TYPE,
  RESULT_TYPE,
  TRANSACTION_TYPE,
  CASH_TYPE,
  CARD_TYPE,
  PAYMENT_TYPE,
  CASHLESS_TYPE,
  REVERSED_TYPE,
  QR_TYPE,
};
