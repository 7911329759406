import LocalStorage from "services/LocalStorage/LocalStorage.service";
import Fetch from "services/Fetch/Fetch.service";
import { URL_SERVER } from "utils/constants/server.constants";
import download from "utils/helpers/download.helpers";
import { SessionStorage } from "services";

export class TransactionTable {
  constructor() {
    this.fetch = new Fetch();
    this.localStorage = new LocalStorage();
    this.sessionStorage = new SessionStorage();
  }

  get(data) {
    const {
      businessDay,
      fiscalDay,
      start_index,
      search_text,
      ptname,
      bdd,
      batch,
      dateFrom,
      dateTo,
      tran_type,
      search_rrn,
      search_amount,
      search_method_payment,
      search_terminal,
      search_cashier,
      fiscal_type,
    } = data;

    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    let search_id = !!businessDay
      ? null
      : localStorage.getItem("search_id") || null;
    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    const body = {
      start_index,
      ptname,
      search_text: !!businessDay || !!fiscalDay ? null : search_text,
    };

    if (bdd) body.bdd = bdd;
    if (batch) body.batch = batch;
    if (dateFrom) body.date_from = dateFrom;
    if (dateTo) body.date_to = dateTo;
    if (tran_type) body.tran_type = tran_type;
    if (fiscal_type) body.fiskal_status = fiscal_type;
    if (search_amount) body.amount_search = search_amount;
    if (search_rrn) body.rrn_search = search_rrn;
    if (search_method_payment)
      body.search_method_payment = search_method_payment;
    if (search_terminal) body.terminal_search = search_terminal;
    if (search_cashier) body.search_cashier = search_cashier;

    if (search_id) body.search_id = search_id;

    return this.fetch
      .post(`${URL_SERVER}/api/Transaction`, body, headers)
      .catch((error) => console.error(error));
  }

  async getExcel({
    search_text,
    ptname,
    dateFrom,
    dateTo,
    status,
    search_rrn,
    search_amount,
    search_method_payment,
    search_terminal,
    search_cashier,
    extended_rep,
  }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const ss_ptname = this.sessionStorage.get("ptname");
    const search_id = sessionStorage.getItem("search_id");

    if (!ptname) ptname = "ALL";

    const headers = {
      Token: token,
      DeviceId: "WEB_" + device,
      responseType: "blob",
    };

    const body = {
      start_index: -1,
      ptname,
      search_id,
    };

    if (ss_ptname) body.ptname = ss_ptname;
    if (search_text) body.search_text = search_text;
    if (dateFrom) body.date_from = dateFrom;
    if (dateTo) body.date_to = dateTo;
    if (status) body.status = status;
    if (search_amount) body.search_amount = search_amount;
    if (search_rrn) body.rrn_search = search_rrn;
    if (search_method_payment)
      body.search_method_payment = search_method_payment;
    if (search_terminal) body.terminal_search = search_terminal;
    if (search_cashier) body.search_cashier = search_cashier;
    body.extended_rep = extended_rep;

    return this.fetch
      .postBlobWithAxios(
        `${URL_SERVER}/api/Reports/TransactionExport/ExportExcel`,
        body,
        headers
      )
      .then((result) => {
        if (!result) return;

        const { fileData, fileName } = result;

        download(
          fileData,
          fileName,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      });
  }

  async getPDF({
    search_text,
    ptname,
    dateFrom,
    dateTo,
    status,
    search_rrn,
    search_amount,
    search_method_payment,
    search_terminal,
    search_cashier,
    extended_rep,
  }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const search_id = localStorage.getItem("search_id");
    const ss_ptname = this.sessionStorage.get("ptname");

    if (!search_text) search_text = "";
    if (!ptname) ptname = "ALL";

    const headers = {
      Token: token,
      DeviceId: "WEB_" + device,
      responseType: "blob",
    };

    const body = {
      start_index: -1,
      ptname,
      search_id,
    };

    if (ss_ptname) body.ptname = ss_ptname;
    if (search_text) body.search_text = search_text;
    if (dateFrom) body.date_from = dateFrom;
    if (dateTo) body.date_to = dateTo;
    if (status) body.status = status;
    if (search_amount) body.search_amount = search_amount;
    if (search_rrn) body.rrn_search = search_rrn;
    if (search_method_payment)
      body.search_method_payment = search_method_payment;
    if (search_terminal) body.terminal_search = search_terminal;
    if (search_cashier) body.search_cashier = search_cashier;
    body.extended_rep = extended_rep;

    return this.fetch
      .postBlobWithAxios(
        `${URL_SERVER}/api/Reports/TransactionExport/ExportPDF`,
        body,
        headers
      )
      .then((result) => {
        if (!result) return;

        const { fileData, fileName } = result;

        download(fileData, fileName, "application/pdf");
      });
  }

  getXReceipt(rroId, fileFormat) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const headers = new Headers();

    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    return this.fetch
      .getBlob(
        `${URL_SERVER}/api/Transaction/X/${rroId}/${fileFormat}`,
        headers
      )
      .catch((error) => console.error(error));
  }
}
