import { WEEK_DAYS, HOURS, MINUTES } from "utils/constants/time";

export const INITIAL_HOURS_VALUE = HOURS[HOURS.length - 1].value;
export const INITIAL_MINUTES_VALUE = MINUTES[MINUTES.length - 1].value;

export const INITIAL_DAY_SETTINGS_STATE = {
  fiskalMon: {
    ...WEEK_DAYS.find((el) => el.field === "fiskalMon"),
    hours: INITIAL_HOURS_VALUE,
    minutes: INITIAL_MINUTES_VALUE,
    checked: false,
  },
  fiskalTue: {
    ...WEEK_DAYS.find((el) => el.field === "fiskalTue"),
    hours: INITIAL_HOURS_VALUE,
    minutes: INITIAL_MINUTES_VALUE,
    checked: false,
  },
  fiskalWed: {
    ...WEEK_DAYS.find((el) => el.field === "fiskalWed"),
    hours: INITIAL_HOURS_VALUE,
    minutes: INITIAL_MINUTES_VALUE,
    checked: false,
  },
  fiskalThu: {
    ...WEEK_DAYS.find((el) => el.field === "fiskalThu"),
    hours: INITIAL_HOURS_VALUE,
    minutes: INITIAL_MINUTES_VALUE,
    checked: false,
  },
  fiskalFri: {
    ...WEEK_DAYS.find((el) => el.field === "fiskalFri"),
    hours: INITIAL_HOURS_VALUE,
    minutes: INITIAL_MINUTES_VALUE,
    checked: false,
  },
  fiskalSat: {
    ...WEEK_DAYS.find((el) => el.field === "fiskalSat"),
    hours: INITIAL_HOURS_VALUE,
    minutes: INITIAL_MINUTES_VALUE,
    checked: false,
  },
  fiskalSun: {
    ...WEEK_DAYS.find((el) => el.field === "fiskalSun"),
    hours: INITIAL_HOURS_VALUE,
    minutes: INITIAL_MINUTES_VALUE,
    checked: false,
  },
  autoTime: {
    hours: INITIAL_HOURS_VALUE,
    minutes: INITIAL_MINUTES_VALUE,
  },
  autoInkass: true, //false,
  autoBdayClose: true, //false,
  autoFdayClose: true, //false,
  fiscalClosingRadioboxes: {
    myself: true,
    automatically: false,
    manually: false,
  },
};
