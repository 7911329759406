import styled from "styled-components";

export const Container = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  font-family: "Futura Book", sans-serif;
  padding: 0.5rem;
  max-height: 96vh;
  overflow-x: hidden;
  overflow-y: auto;

  .ring {
    height: 44px;

    div {
      width: 44px;
      height: 44px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  align-items: stretch;
  padding: 0 0.5rem 0;

  label {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    input[type="checkbox"] {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
`;

export const SizeContainer = styled.div`
  display: flex;
  gap: 1rem;

  & > * {
    flex-basis: 50%;
  }
`;

export const TagDetailsFormControl = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-family: "Futura Book", sans-serif;
    text-align: center;
  }

  h2,
  h3 {
    padding: 0 2rem 0 1.5rem;
    transition: all 0.2s;
  }

  h3 {
    height: 24px;
  }
`;

export const TemplateCaption = styled.h3`
  height: 24px;
  font-family: "Futura Book", sans-serif;
  text-align: center;
`;

export const EditorActions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: auto;
  width: 100%;
  /* height: 81.47px; */
  height: 3.25rem;

  .ring {
    width: 64px;
    height: 64px;
  }

  button {
    width: auto;
    padding: 0.5rem 1rem;
    font-size: 14px;
    white-space: nowrap;
  }
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: auto;
  padding-bottom: 0.5rem;

  .ring {
    margin: 0;
    padding: 0;
    margin-top: -3.5rem;
  }

  button {
    width: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

export const SwitchContainer = styled.div`
  width: 100%;
  padding-left: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;

  &:last-of-type {
    margin-bottom: 1rem;
  }

  span {
    font-family: "Futura Book", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
  }
`;
