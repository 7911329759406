import { useCallback, useMemo } from "react";
import { getStatYears } from "./helpers";

const useStatPeriod = () => {
  const getYears = useCallback(() => getStatYears(), []);
  const years = useMemo(
    () => getYears().map((y) => ({ value: y, label: String(y) })),
    []
  );

  return { years };
};

export default useStatPeriod;
