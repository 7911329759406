import { NewsDetailsLinkButton } from "./NewsDetailsLink.styles";

const NewsDetailsLink = ({ title, onClick }) => {
  return (
    <NewsDetailsLinkButton onClick={onClick} aria-label="message details">
      {title}
    </NewsDetailsLinkButton>
  );
};

export default NewsDetailsLink;
