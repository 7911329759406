import styled from "styled-components";

import { tiffany, white, black } from "assets/style/variables";

export const ButtonContainer = styled.div`
  border-radius: 10px;
  padding: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  &:hover {
    box-shadow: ${(props) =>
      props.disabled ? "" : "0px 1px 8px rgba(0, 0, 0, 0.5)"};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
  transition: transform 0.2s ease, opacity 0.3s ease;

  ${(props) =>
    props.opened
      ? `transform: scale(1); opacity: 1;`
      : `transform: scale(0); opacity: 0;`}
`;

export const ReadButton = styled(ButtonContainer)`
  position: fixed;
  right: 310px;
  bottom: 30px;
  /* background: lightgreen; */
  background: ${(props) => (props.disabled ? "#d1d1d1" : "lightgreen")};
  color: ${(props) => (props.disabled ? "grey" : black)};
  /* & > * {
    color: ${(props) => (props.disabled ? "grey" : "black")};
  } */

  /* svg {
    width: 20px;
    path {
      fill: #000;
    }
  } */
`;

export const NotReadButton = styled(ButtonContainer)`
  position: fixed;
  right: 260px;
  bottom: 30px;
  /* background: #fcba03; */
  background: ${(props) => (props.disabled ? "#d1d1d1" : "#fcba03")};
  color: ${(props) => (props.disabled ? "grey" : black)};
  /* & > * {
    color: ${(props) => (props.disabled ? "grey" : "black")};
  } */
  /* svg {
    width: 20px;
    path {
      fill: #000;
    }
  } */
`;

export const DeleteButton = styled(ButtonContainer)`
  position: fixed;
  right: 210px;
  bottom: 30px;
  /* background: rgb(239, 55, 67); */
  background: ${(props) => (props.disabled ? "#d1d1d1" : "rgb(239, 55, 67)")};

  svg {
    width: 20px;
    path {
      stroke: ${(props) => (props.disabled ? "grey" : "black")};
      fill: #000;
    }
  }
`;

export const CheckAllButton = styled(ButtonContainer)`
  position: fixed;
  right: ${(props) => (props.isOnly ? "110px" : "160px")};
  bottom: 30px;
  font-size: larger;
  background: ${(props) => (props.disabled ? "#d1d1d1" : tiffany)};
  color: ${(props) => (props.disabled ? "grey" : black)};
`;

export const CancelButton = styled(ButtonContainer)`
  position: fixed;
  right: 110px;
  bottom: 30px;
  /* background: #b1b1b1; */
  background: ${(props) => (props.disabled ? "#d1d1d1" : "#b1b1b1")};
  /* color: ${(props) => (props.disabled ? white : black)}; */
  svg {
    width: 20px;
    path {
      stroke: ${(props) => (props.disabled ? "grey" : "black")};
      fill: #000;
    }
  }
`;
