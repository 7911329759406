import React from "react";
import { useSelector } from "react-redux";

import GlobalLoader from "ui/GlobalLoader/GlobalLoader";

const GlobalLoaderProvider = ({ children }) => {
  const globalLoading = useSelector((state) => state.main_table.global_loading);

  return (
    <>
      {globalLoading && <GlobalLoader />}
      {children}
    </>
  );
};

export default GlobalLoaderProvider;
