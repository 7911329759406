import styled from 'styled-components';

import { font_book } from 'assets/style/fonts.variables';
import { grey } from 'assets/style/variables';
import { size } from 'assets/style/global-variables';

export const SupportContainer = styled.section`
  margin: 0;
`;

export const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  column-gap: 10px;
  p {
    font-size: 22px;
    font-family: ${font_book};
    margin-top: 5px;
  }
  a {
    font-size: 18px;
    font-family: ${font_book};
    color: ${grey};
  }

  @media screen and (max-width: ${size.mobileL}) {
    p {
      font-size: 16px;
      margin-top: 15px;
    }
    a {
      font-size: 16px;
    }
  }
`;
