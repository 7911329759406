import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import TableHeaders from "components/layouts/Table/Headers/Table.headers";
import ErrorComponent from "../components/ErrorComponent/ErrorComponent";
import Preloader from "components/additional/PreLoader/Preloader";

// functions
import {
  bankDetailItem,
  setClosedFilteringState,
  fetchClosedFiscalInfo,
} from "store/fiscal_table/fiscal_table.actions";
import {
  bClosedState,
  fiscalClosedFilteringState,
  fiscalClosedListError,
} from "store/fiscal_table/fiscal_table.slicer";
import GenerateDay from "utils/helpers/generateDay.helpers";
import { FISCAL_DAY_ROUTE } from "utils/constants/routes.constants";
import {
  SEARCH_TYPE,
  DATE_TYPE,
  SELECTOR_TYPE,
} from "utils/constants/table.headers.constants";

// styles
import {
  TableContainer,
  TerminalText,
  TerminalDescribe,
  TerminalTitle,
  Td,
} from "components/layouts/Table/Table.styles";
import { TrMain } from "./FiscalClosed.table.styles";
import { TrTransactionDate } from "pages/Panels/Transaction/Transaction.styles";

import { UI_DATE_FORMAT } from "utils/constants/time";

const FiscalClosedTable = ({ setShifts }) => {
  const state = useSelector(bClosedState);
  const reduxfilteringState = useSelector(fiscalClosedFilteringState);
  const terminalValues = useSelector(
    (state) => state.main_table.search_transactions
  );
  const cashierValues = useSelector((state) => state.main_table.cashiers).map(
    (el) => el.user_name
  );
  const error = useSelector(fiscalClosedListError);
  const loading = useSelector((state) => state.fiscal_table.fiscal_loading);

  const history = useHistory();
  const dispatch = useDispatch();

  const [isMounted, setIsMounted] = useState(false);
  const [filteredRecords, setFilteredRecords] = useState(state);

  const FILTERING_FORM = ({
    terminal = null,
    cashier = null,
    startDate = moment().add(-30, "days").format(UI_DATE_FORMAT),
    endDate = moment().format(UI_DATE_FORMAT),
    transaction_count = null,
    total_sum = null,
  }) => ({
    terminal: {
      value: terminal,
      placeholder: "Термінал",
    },
    cashier: {
      value: cashier,
      placeholder: "Касир",
    },
    startDate: {
      value: startDate,
      placeholder: "Дата відкриття",
    },
    endDate: {
      value: endDate,
      placeholder: "Дата закриття",
    },
    transaction_count: {
      value: transaction_count,
      placeholder: "Транзакцій",
      formatFunction: ({ oldValue, newValue }) => {
        const regex1 = /^\d{1,5}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        return newValue;
      },
    },
    total_sum: {
      value: total_sum,
      placeholder: "Сума",
      formatFunction: ({ oldValue, newValue }) => {
        const regex1 = /^\d{1,8}[.]?\d{0,2}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        return newValue;
      },
    },
  });

  const [filteringState, setFilteringState] = useState(
    reduxfilteringState
      ? FILTERING_FORM({
          terminal: reduxfilteringState.terminal,
          cashier: reduxfilteringState.cashier,
          endDate:
            reduxfilteringState.endDate || moment().format(UI_DATE_FORMAT),
          startDate:
            reduxfilteringState.startDate ||
            moment().add(-30, "days").format(UI_DATE_FORMAT),
          transaction_count: reduxfilteringState.transaction_count,
          total_sum: reduxfilteringState.total_sum,
        })
      : FILTERING_FORM({})
  );

  const filterRecords = useCallback(() => {
    let newFilteredRecords = [...state];

    if (filteringState.terminal.value) {
      newFilteredRecords = newFilteredRecords
        .map((el) => ({
          ...el,
          items: el.items.filter(
            (el) => el.info?.terminalId === filteringState.terminal.value
          ),
        }))
        .filter((el) => el.items.length !== 0);
    }

    if (filteringState.cashier.value) {
      newFilteredRecords = newFilteredRecords
        .map((el) => ({
          ...el,
          items: el.items.filter(
            (el) => el.info.userName === filteringState.cashier.value
          ),
        }))
        .filter((el) => el.items.length !== 0);
    }

    if (
      filteringState.transaction_count.value &&
      filteringState.transaction_count.value !== ""
    ) {
      newFilteredRecords = newFilteredRecords
        .map((el) => ({
          ...el,
          items: el.items.filter(
            (el) =>
              el.info.total_transactions_count.toString() ===
              filteringState.transaction_count.value.toString()
          ),
        }))
        .filter((el) => el.items.length !== 0);
    }

    if (
      filteringState.total_sum.value &&
      filteringState.total_sum.value !== ""
    ) {
      newFilteredRecords = newFilteredRecords
        .map((el) => ({
          ...el,
          items: el.items.filter(
            (el) =>
              Number(el.info.total_transactions_sum) ===
              Number(filteringState.total_sum.value)
          ),
        }))
        .filter((el) => el.items.length !== 0);
    }

    setFilteredRecords(newFilteredRecords);
  }, [filteringState, state]);

  const toDetailPage = (item) => {
    dispatch(bankDetailItem(item));

    history.push({
      pathname: `${FISCAL_DAY_ROUTE}/${item.info.terminalId}`,
      state: { status: "closed" },
    });
  };

  useEffect(() => {
    setIsMounted(true);
    dispatch(
      fetchClosedFiscalInfo({
        startDate: filteringState.startDate.value,
        endDate: filteringState.endDate.value,
      })
    );
  }, []);

  useEffect(() => {
    if (!filteredRecords || filteredRecords.length === 0) {
      setFilteredRecords(state);
    }
  }, [state]);

  useEffect(() => {
    if (!filteredRecords || filteredRecords.length === 0) {
      setShifts([]);
    } else {
      setShifts(filteredRecords);
    }
  }, [filteredRecords]);

  useEffect(() => {
    dispatch(
      setClosedFilteringState({
        terminal: filteringState.terminal.value,
        cashier: filteringState.cashier.value,
        startDate: filteringState.startDate.value,
        endDate: filteringState.endDate.value,
        transaction_count: filteringState.transaction_count.value,
        total_sum: filteringState.total_sum.value,
      })
    );
    filterRecords();
  }, [dispatch, filteringState, filterRecords]);

  useEffect(() => {
    if (isMounted) {
      dispatch(
        fetchClosedFiscalInfo({
          startDate: filteringState.startDate.value,
          endDate: filteringState.endDate.value,
        })
      );
    }
  }, [filteringState.startDate, filteringState.endDate]);

  if (loading) {
    return <Preloader />;
  }

  if (!!error) {
    return <ErrorComponent title={error} />;
  }

  return (
    <>
      {filteredRecords && filteredRecords.length === 0 && (
        <TerminalTitle style={{ marginTop: "20px" }}>
          Відсутні закриті фіскальні зміни.
        </TerminalTitle>
      )}
      <TableContainer>
        <thead>
          <TrMain>
            <TableHeaders
              state={terminalValues}
              choose_state={filteringState.terminal.value}
              type={SELECTOR_TYPE}
              fn={(newValue) =>
                setFilteringItem({ field: "terminal", newValue })
              }
              fn_reset={() =>
                setFilteringItem({ field: "terminal", newValue: null })
              }
              text={filteringState.terminal.placeholder}
            />
            <TableHeaders
              state={cashierValues}
              choose_state={filteringState.cashier.value}
              type={SELECTOR_TYPE}
              fn={(newValue) =>
                setFilteringItem({ field: "cashier", newValue })
              }
              fn_reset={() =>
                setFilteringItem({ field: "cashier", newValue: null })
              }
              text={filteringState.cashier.placeholder}
            />
            <TableHeaders
              choose_state={
                filteringState.startDate.value
                  ? moment(filteringState.startDate.value, UI_DATE_FORMAT)
                  : null
              }
              value={
                filteringState.startDate.value
                  ? moment(
                      filteringState.startDate.value,
                      UI_DATE_FORMAT
                    ).toDate()
                  : null
              }
              rangeFrom={
                filteringState.startDate.value
                  ? moment(
                      filteringState.startDate.value,
                      UI_DATE_FORMAT
                    ).toDate()
                  : null
              }
              rangeTo={
                filteringState.endDate.value
                  ? moment(
                      filteringState.endDate.value,
                      UI_DATE_FORMAT
                    ).toDate()
                  : null
              }
              type={DATE_TYPE}
              fn={(val) => {
                setFilteringItem({
                  field: "startDate",
                  newValue: moment(val[0].startDate).format(UI_DATE_FORMAT),
                });
                setFilteringItem({
                  field: "endDate",
                  newValue: moment(val[0].endDate).format(UI_DATE_FORMAT),
                });
              }}
              fn_reset={() => {
                setFilteringItem({ field: "startDate", newValue: null });
                setFilteringItem({ field: "endDate", newValue: null });
              }}
              text={filteringState.startDate.placeholder}
              required
              range
            />
            <TableHeaders
              choose_state={
                filteringState.endDate.value
                  ? moment(filteringState.endDate.value, UI_DATE_FORMAT)
                  : null
              }
              value={
                filteringState.endDate.value
                  ? moment(
                      filteringState.endDate.value,
                      UI_DATE_FORMAT
                    ).toDate()
                  : null
              }
              rangeFrom={
                filteringState.startDate.value
                  ? moment(
                      filteringState.startDate.value,
                      UI_DATE_FORMAT
                    ).toDate()
                  : null
              }
              rangeTo={
                filteringState.endDate.value
                  ? moment(
                      filteringState.endDate.value,
                      UI_DATE_FORMAT
                    ).toDate()
                  : null
              }
              type={DATE_TYPE}
              fn={(val) => {
                setFilteringItem({
                  field: "startDate",
                  newValue: moment(val[0].startDate).format(UI_DATE_FORMAT),
                });
                setFilteringItem({
                  field: "endDate",
                  newValue: moment(val[0].endDate).format(UI_DATE_FORMAT),
                });
              }}
              fn_reset={() => {
                setFilteringItem({ field: "startDate", newValue: null });
                setFilteringItem({ field: "endDate", newValue: null });
              }}
              text={filteringState.endDate.placeholder}
              required
              range={true}
            />
            <TableHeaders
              choose_state={filteringState.transaction_count.value}
              type={SEARCH_TYPE}
              text={filteringState.transaction_count.placeholder}
              fn={(newValue) =>
                setFilteringItem({ field: "transaction_count", newValue })
              }
              fn_reset={() =>
                setFilteringItem({ field: "transaction_count", newValue: "" })
              }
              formatFunction={filteringState.transaction_count.formatFunction}
            />
            <TableHeaders
              choose_state={filteringState.total_sum.value}
              type={SEARCH_TYPE}
              text={filteringState.total_sum.placeholder}
              fn={(newValue) =>
                setFilteringItem({ field: "total_sum", newValue })
              }
              fn_reset={() =>
                setFilteringItem({ field: "total_sum", newValue: "" })
              }
              formatFunction={filteringState.total_sum.formatFunction}
            />
          </TrMain>
        </thead>
        <tbody>
          {filteredRecords &&
            filteredRecords.length !== 0 &&
            filteredRecords.map((group) => {
              if (!group.items || group.items.length === 0) return null;

              return (
                <React.Fragment key={group.__id}>
                  <TrTransactionDate>
                    <Td>{GenerateDay(group.date)}</Td>
                  </TrTransactionDate>
                  {group.items.map((fiscalDay) => {
                    return (
                      <TrMain
                        key={fiscalDay.info.__id}
                        column
                        onClick={() => toDetailPage(fiscalDay)}
                      >
                        <Td>
                          <TerminalText>
                            <TerminalTitle>
                              {fiscalDay.info.terminalId}
                            </TerminalTitle>
                          </TerminalText>
                        </Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>
                              {fiscalDay.info.userName}
                            </TerminalTitle>
                          </TerminalText>
                        </Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>
                              {fiscalDay.info.openDate
                                ? moment(fiscalDay.info.openDate).format(
                                    UI_DATE_FORMAT
                                  )
                                : "-"}
                            </TerminalTitle>
                            <TerminalDescribe>
                              {fiscalDay.info.openDate &&
                                moment(fiscalDay.info.openDate).format("HH:mm")}
                            </TerminalDescribe>
                          </TerminalText>
                        </Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>
                              {fiscalDay.info.closeDate
                                ? moment(fiscalDay.info.closeDate).format(
                                    UI_DATE_FORMAT
                                  )
                                : "-"}
                            </TerminalTitle>
                            <TerminalDescribe>
                              {fiscalDay.info.closeDate &&
                                moment(fiscalDay.info.closeDate).format(
                                  "HH:mm"
                                )}
                            </TerminalDescribe>
                          </TerminalText>
                        </Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>
                              {fiscalDay.info.total_transactions_count}
                            </TerminalTitle>
                          </TerminalText>
                        </Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>{`${fiscalDay.info.total_transactions_sum.toFixed(
                              2
                            )} ₴`}</TerminalTitle>
                          </TerminalText>
                        </Td>
                      </TrMain>
                    );
                  })}
                </React.Fragment>
              );
            })}
        </tbody>
      </TableContainer>
    </>
  );

  function setFilteringItem({ newValue, field }) {
    setFilteringState((state) => ({
      ...state,
      [field]: { ...state[field], value: newValue },
    }));
  }
};

export default FiscalClosedTable;
