const SET_CASHIERS = "SET_CASHIERS";
const SET_PANEL_REF = "SET_PANEL_REF";
const SET_GLOBAL_LOADING = "SET_GLOBAL_LOADING";

const TABLE_GET_SERVER = "main_table/get_server";
const TABLE_SET_SERVER = "main_table/set_server";
const TABLE_SET_SERVER_FILTERED = "main_table/set_server_filtered";
const TABLE_SET_LOCAL = "main_table/set_local";
const TABLE_SET_SERVER_TERMINAL = "terminal_table/set_server_terminal";
const TABLE_SET_LOCAL_TERMINAL = "terminal_table/set_local_terminal";
const TABLE_LOAD = "main_table/load";
const TABLE_SERVER_LOAD = "main_table/server_load";
const TABLE_SERVER_LOAD_TERMINAL = "main_table/server_load_terminal";
const TABLE_CHANGE_BUTTON = "main_table/chang_active_button";
const TABLE_CHANGE_DATE = "main_table/chang_dates";
const TABLE_SORT = "main_table/sort";
const TABLE_SET_HEADER = "main_table/chang_headers";
const TABLE_SET_SEARCH = "main_table/search_box_state";
const TABLE_SET_SEARCH_VAL = "main_table/search_box_state_val";
const TABLE_SET_TERMINAL_SEARCH = "main_table/search_box_terminal_state";
const TABLE_SET_TERMINAL_SEARCH_VAL =
  "main_table/search_box_state_terminal_val";
const TABLE_FILTER = "main_table/filter";

const TABLE_SET_FILTER_TRANSACTION = "terminal_table/filter_transaction";
const TABLE_SET_FILTER_CARDS = "terminal_table/filter_cards";
const TABLE_SET_FILTER_CASH = "terminal_table/filter_cash";
const TABLE_SET_FILTER_TOTAL = "terminal_table/filter_totals";

const TABLE_SET_FILTER_TERMINAL_ID = "terminal_table/filter_id";
const TABLE_SET_FILTER_TERMINAL_DESCRIBE = "terminal_table/filter_describe";
const TABLE_SET_FILTER_TERMINAL_DEVICE = "terminal_table/filter_device";

const TABLE_SET_FILTER_TERMINAL_NAME = "terminal_table/filter_name";
const TABLE_SET_FILTER_TERMINAL_ID_VAL = "terminal_table/filter_id_val";
const TABLE_SET_FILTER_TERMINAL_NAME_VAL = "terminal_table/filter_name_val";
const TABLE_SET_FILTER_TERMINAL_DESCRIBE_VAL =
  "terminal_table/filter_describe_val";
const TABLE_SET_FILTER_TERMINAL_DEVICE_VAL = "terminal_table/filter_device_val";
const TABLE_SET_FILTER_TERMINAL_PPO_VAL = "terminal_table/filter_ppo_val";

const TABLE_SET_FILTER_TRANSACTION_VAL =
  "terminal_table/filter_transaction_val";
const TABLE_SET_FILTER_CARDS_VAL = "terminal_table/filter_cards_val";
const TABLE_SET_FILTER_CASH_VAL = "terminal_table/filter_cash_val";
const TABLE_SET_FILTER_TOTAL_VAL = "terminal_table/filter_totals_val";

const TABLE_SET_SEARCH_TERMINAL = "terminal_table/search_box_state";

const TABLE_SET_TERMINAL = "main_table/set_terminal";
const TABLE_SET_TRANSACTION = "main_table/set_transaction";
const TABLE_SET_CARD = "main_table/set_card";
const TABLE_SET_CASH = "main_table/set_cash";
const TABLE_SET_CASHLESS = "main_table/set_cashless";
const TABLE_SET_QR = "main_table/set_qr";
const TABLE_SET_AMOUNT = "main_table/set_amount";
export const SET_MOBILE_VERSION = "main_table/set_mobile_version";

const TABLE_RESET = "main_table/reset";

const TABLE_SET_ITEM = "terminal_table/choose_item";

export {
  SET_CASHIERS,
  SET_PANEL_REF,
  SET_GLOBAL_LOADING,
  TABLE_SET_TERMINAL,
  TABLE_SET_TRANSACTION,
  TABLE_SET_CARD,
  TABLE_SET_CASH,
  TABLE_SET_CASHLESS,
  TABLE_SET_QR,
  TABLE_SET_AMOUNT,
  TABLE_LOAD,
  TABLE_GET_SERVER,
  TABLE_SET_SERVER,
  TABLE_SET_SERVER_FILTERED,
  TABLE_SET_LOCAL,
  TABLE_SET_SERVER_TERMINAL,
  TABLE_SERVER_LOAD_TERMINAL,
  TABLE_SET_LOCAL_TERMINAL,
  TABLE_SERVER_LOAD,
  TABLE_CHANGE_BUTTON,
  TABLE_CHANGE_DATE,
  TABLE_SORT,
  TABLE_SET_HEADER,
  TABLE_SET_SEARCH,
  TABLE_SET_SEARCH_TERMINAL,
  TABLE_SET_SEARCH_VAL,
  TABLE_SET_TERMINAL_SEARCH,
  TABLE_SET_TERMINAL_SEARCH_VAL,
  TABLE_FILTER,
  TABLE_RESET,
  TABLE_SET_FILTER_TRANSACTION,
  TABLE_SET_FILTER_CARDS,
  TABLE_SET_FILTER_CASH,
  TABLE_SET_FILTER_TOTAL,
  TABLE_SET_FILTER_TRANSACTION_VAL,
  TABLE_SET_FILTER_CARDS_VAL,
  TABLE_SET_FILTER_CASH_VAL,
  TABLE_SET_FILTER_TOTAL_VAL,
  TABLE_SET_FILTER_TERMINAL_ID,
  TABLE_SET_FILTER_TERMINAL_DEVICE,
  TABLE_SET_FILTER_TERMINAL_DESCRIBE,
  TABLE_SET_FILTER_TERMINAL_NAME,
  TABLE_SET_FILTER_TERMINAL_ID_VAL,
  TABLE_SET_FILTER_TERMINAL_DEVICE_VAL,
  TABLE_SET_FILTER_TERMINAL_DESCRIBE_VAL,
  TABLE_SET_FILTER_TERMINAL_NAME_VAL,
  TABLE_SET_FILTER_TERMINAL_PPO_VAL,
  TABLE_SET_ITEM,
};
