import React, { useEffect } from "react";
import TableHeaders from "components/layouts/Table/Headers/Table.headers";
import { TableContainer, Td } from "components/layouts/Table/Table.styles";
import { GreenButton, TrTerminalHeader, TrMain } from "./Terminals.styles";
import {
  SEARCH_TYPE,
  SELECTOR_TYPE,
} from "utils/constants/table.headers.constants";

import {
  Terminal,
  TerminalText,
  TerminalTitle,
} from "pages/Panels/Main/Main.styles";
import { ReactComponent as TerminalIcon } from "assets/icons/terminal_icon.svg";
import QRIcon from "components/ui/QRIcon";

import {
  fetchServerTerminals,
  setItem,
  loadStateServerTerminal,
  filterByStatus,
  setSearchIdVal,
  filterTableTerminal,
  resetFilterTerminal,
} from "store/main_table/main_table.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  loadLocal,
  stateLocalTerminal,
  searchValId,
  searchValDevice,
  searchValName,
  searchValDescribe,
  searchValPPO,
} from "store/main_table/main_table.slicer";

import Preloader from "components/additional/PreLoader/Preloader";
import { useHistory } from "react-router-dom";
import { TERMINALS_ROUTE } from "utils/constants/routes.constants";
import Refresh from "ui/Refresh/Refresh";
import { terminalStatuses } from "utils/constants/terminal.constants";
import { grey } from "assets/style/variables";
import { size } from "assets/style/global-variables";

export default function Table() {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(loadLocal);
  const state = useSelector(stateLocalTerminal);

  const dateVal = useSelector(searchValId);
  const deviceVal = useSelector(searchValDevice);
  const nameVal = useSelector(searchValName);
  const descrVal = useSelector(searchValDescribe);
  const ppoVal = useSelector(searchValPPO);

  const status_items = Object.values(terminalStatuses).map(
    (item) => item.label
  );

  useEffect(() => {
    dispatch(fetchServerTerminals());
  }, [dispatch]);

  function chooseItem(item) {
    dispatch(setItem(item));
    history.push(TERMINALS_ROUTE + "/" + item.ptname);
  }

  function chooseStatus(state) {
    let searchValue;

    switch (state) {
      case terminalStatuses.bound.label:
        searchValue = terminalStatuses.bound.status;
        break;
      case terminalStatuses.free.label:
        searchValue = terminalStatuses.free.status;
        break;
      case terminalStatuses.blocked.label:
        searchValue = terminalStatuses.blocked.status;
        break;
      case terminalStatuses.ecomm.label:
        searchValue = terminalStatuses.ecomm.status;
        break;
      default:
        searchValue = null;
        break;
    }

    dispatch(setSearchIdVal(searchValue));
    dispatch(filterByStatus());
  }

  return (
    <>
      <Refresh
        fn={() => {
          dispatch(loadStateServerTerminal());
          dispatch(fetchServerTerminals());
        }}
      />
      {!loading && state.length === 0 && (
        <TerminalTitle>На жаль дані відсутні.</TerminalTitle>
      )}
      <TableContainer style={{ minWidth: `${size.laptop}` }}>
        <thead>
          <TrMain>
            <TableHeaders
              fn={(val) => dispatch(filterTableTerminal("ptname", val))}
              fn_reset={() => dispatch(resetFilterTerminal("ptname"))}
              type={SEARCH_TYPE}
              choose_state={deviceVal}
              text="ID терміналу"
            />
            <TableHeaders
              fn={(val) => dispatch(filterTableTerminal("ppo", val))}
              fn_reset={() => dispatch(resetFilterTerminal("ppo"))}
              type={SEARCH_TYPE}
              choose_state={ppoVal}
              text="ФН ПРРО"
            />
            <TableHeaders
              fn={(val) =>
                dispatch(filterTableTerminal("ptdescription_manual", val))
              }
              fn_reset={() =>
                dispatch(resetFilterTerminal("ptdescription_manual"))
              }
              type={SEARCH_TYPE}
              choose_state={nameVal}
              text="Назва терміналу"
            />
            <TableHeaders
              fn={(val) => dispatch(filterTableTerminal("ptdescription", val))}
              fn_reset={() => dispatch(resetFilterTerminal("ptdescription"))}
              type={SEARCH_TYPE}
              choose_state={descrVal}
              text="Опис терміналу"
            />
            <TableHeaders
              fn={(val) => dispatch(filterTableTerminal("device_model", val))}
              fn_reset={() => dispatch(resetFilterTerminal("device_model"))}
              type={SEARCH_TYPE}
              choose_state={deviceVal}
              text="Пристрій (Device ID)"
            />
            <TableHeaders
              state={status_items}
              choose_state={dateVal}
              type={SELECTOR_TYPE}
              fn={chooseStatus}
              fn_reset={() => {
                chooseStatus(null);
              }}
              text="Статус"
            />
          </TrMain>
        </thead>
        <tbody>
          {!loading &&
            state.length !== 0 &&
            state.map((item, key) => {
              return (
                <React.Fragment key={item.status}>
                  <TrTerminalHeader>
                    <Td>{terminalStatuses[item.status].groupLabel}</Td>
                  </TrTerminalHeader>

                  {item.items.map((term) => {
                    const isMobile = !term.isEComm;
                    let terminalStatusLabel = "";
                    let greenTerminalColor = false;

                    if (isMobile) {
                      if (term.status_id > 1) {
                        terminalStatusLabel = terminalStatuses.blocked.label;
                      } else if (term.status_id === 1 && !term.binded) {
                        terminalStatusLabel = terminalStatuses.free.label;
                      } else if (term.status_id === 1 && !!term.binded) {
                        terminalStatusLabel = terminalStatuses.bound.label;
                      }

                      greenTerminalColor = term.status_id === 1;
                    } else {
                      terminalStatusLabel = term.active
                        ? "Активний"
                        : "Заблокований";
                      greenTerminalColor = term.active;
                    }

                    return (
                      <TrMain
                        style={{ cursor: "pointer" }}
                        onClick={() => chooseItem(term)}
                        key={term.ptname}
                        column={true}
                      >
                        <Td flex_start={true}>
                          <Terminal>
                            {isMobile ? (
                              <TerminalIcon />
                            ) : (
                              <QRIcon color={grey} />
                            )}
                            <TerminalText>
                              <TerminalTitle>{term.ptname}</TerminalTitle>
                            </TerminalText>
                          </Terminal>
                        </Td>
                        <Td flex_start={true}>
                          <Terminal>
                            <TerminalText>
                              <TerminalTitle>{term.ppo}</TerminalTitle>
                            </TerminalText>
                          </Terminal>
                        </Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>
                              {term.ptdescription_manual}
                            </TerminalTitle>
                          </TerminalText>
                        </Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>{term.ptdescription}</TerminalTitle>
                          </TerminalText>
                        </Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>{term.device_model}</TerminalTitle>
                          </TerminalText>
                        </Td>
                        <Td>
                          {isMobile && (
                            <TerminalText>
                              <GreenButton
                                ok={greenTerminalColor}
                                bound={term.binded}
                              >
                                {terminalStatusLabel}
                              </GreenButton>
                            </TerminalText>
                          )}
                          {!isMobile && (
                            <TerminalText>
                              <GreenButton ok={term.active} bound={true}>
                                {terminalStatusLabel}
                              </GreenButton>
                            </TerminalText>
                          )}
                        </Td>
                      </TrMain>
                    );
                  })}
                </React.Fragment>
              );
            })}
        </tbody>
      </TableContainer>
      {loading && <Preloader />}
    </>
  );
}
