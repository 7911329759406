export const TRAN_COLORS = {
  card: "#0080FF",
  cash: "#098040",
  cashless: "#00BF30",
  qr: "#FF8000",
};

export const TRAN_TYPES = {
  card: "card",
  cash: "cash",
  cashless: "cashless",
  qr: "qr",
};

export const TRAN_NAMES = {
  card: "Карткові",
  cash: "Готівкові",
  cashless: "Безготівкові (інші)",
  qr: "QR-оплати",
};
