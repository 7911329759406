import React from "react";
import {
  AlertContainer,
  AlertBlock,
  AlertTitle,
  AlertSubTitle,
  AlertButtonContainer,
} from "./Alert.styles";
import { generateIcon } from "./icons/alertIconHelper";

import { Button } from "components/forms/Button/Button.styles";

const Alert = (props) => {
  const {
    type,
    title,
    subtitles,
    fnClose,
    fnSubmit,
    subtitleClassName,
    btn_close,
    cancelButtonLabel,
    acceptButtonLabel,
    height,
    width,
  } = props;

  const alertBlockProps = height ? { height } : {};

  if (width) {
    alertBlockProps.width = width;
  }

  return (
    <AlertContainer>
      <AlertBlock {...alertBlockProps}>
        {generateIcon(type)}

        <AlertTitle>{title}</AlertTitle>
        {subtitles.map((item, i) => (
          <AlertSubTitle key={i} className={subtitleClassName}>
            <span>{item}</span>
          </AlertSubTitle>
        ))}
        {type === "remove" || type === "done-button" ? (
          <AlertButtonContainer>
            <Button onClick={fnClose}>
              {cancelButtonLabel && cancelButtonLabel}
              {!cancelButtonLabel && "Ні"}
            </Button>
            {fnSubmit && (
              <Button onClick={fnSubmit}>
                {acceptButtonLabel && acceptButtonLabel}
                {!acceptButtonLabel &&
                  (btn_close ? `Так, ${btn_close}` : "Так")}
              </Button>
            )}
          </AlertButtonContainer>
        ) : null}
        {type === "done" || type === "block" ? (
          <AlertButtonContainer>
            <Button onClick={fnClose}>OK</Button>
          </AlertButtonContainer>
        ) : null}
      </AlertBlock>
    </AlertContainer>
  );
};

export default Alert;
