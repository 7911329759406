import { useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";

import { useHeader } from "store/header/header.context";
import {
  BANK_DAY_ROUTE,
  BANK_DAY_ROUTE_CLOSED,
} from "utils/constants/routes.constants";
import ChangeTitle from "utils/helpers/changetitle.helpers";
import { BankContainer, ButtonContainer, ButtonSwitcher } from "./Bank.styles";
import BankOpen from "./BankOpen/BankOpen.panel";
import BankClosed from "./BankClosed/BankClosed.panel";
import { btn } from "./Bank.states";
import PreloaderActionButton from "components/additional/PreloaderActionButton/PreloaderActionButton";
import useDownloadClosedZReports from "./useDownloadClosedDaysZReports";

export default function Bank() {
  const history = useHistory();
  const { dispatch } = useHeader();
  const [buttons, setButtons] = useState(generateActiveButton);

  useEffect(() => {
    ChangeTitle("Банківські бізнес-дні", dispatch);
  }, [dispatch]);

  function handleButton(e) {
    if (e.target.dataset.id) {
      const id = e.target.dataset.id;
      id.toString() === "0"
        ? history.push(BANK_DAY_ROUTE)
        : history.push(BANK_DAY_ROUTE_CLOSED);

      setButtons(generateActiveButton());
    }
  }

  function generateActiveButton() {
    const id = history.location.pathname === BANK_DAY_ROUTE_CLOSED ? 1 : 0;
    return btn.filter((item) => {
      item.id === id ? (item.active = true) : (item.active = false);
      return item;
    });
  }

  const {
    businessDays,
    setBusinessDays,
    makingAllClosedZReports,
    downloadAllZReports,
  } = useDownloadClosedZReports();

  return (
    <>
      <BankContainer className="businessDay">
        <ButtonContainer onClick={handleButton}>
          {buttons.map((item) => (
            <ButtonSwitcher
              key={item.id}
              data-id={item.id}
              active={item.active}
            >
              {item.text}
            </ButtonSwitcher>
          ))}
        </ButtonContainer>
        {history.location.pathname === BANK_DAY_ROUTE_CLOSED && (
          <PreloaderActionButton
            actionTitle="Усі звіти в zip-архів"
            inProgressTitle="Формування архіву ..."
            onClick={downloadAllZReports}
            inProgress={makingAllClosedZReports}
            disabled={businessDays.length === 0}
          />
        )}
      </BankContainer>

      <Route exact path={BANK_DAY_ROUTE} component={BankOpen} />
      <Route exact path={BANK_DAY_ROUTE_CLOSED}>
        <BankClosed setBusinessDays={setBusinessDays} />
      </Route>
    </>
  );
}
