const TABLE_GET_SERVER = 'transaction_table/get_server';
const TABLE_SET_SERVER = 'transaction_table/set_server';
const TABLE_SET_LOCAL = 'transaction_table/set_local';
const TABLE_LOAD = 'transaction_table/load';
const TABLE_SERVER_LOAD = 'transaction_table/server_load';
const TABLE_CHANGE_BUTTON = 'transaction_table/chang_active_button';
const TABLE_CHANGE_DATE = 'transaction_table/change_dates';

const TABLE_SORT = 'transaction_table/sort';
const TABLE_SORT_TYPE = 'transaction_table/chang_sort_type';
const TABLE_SORT_TERMINAL = 'transaction_table/chang_sort_terminal';
const TABLE_SORT_CASHIER = 'transaction_table/chang_sort_cashier';
const TABLE_SORT_DATE = 'transaction_table/chang_sort_date';
const TABLE_SORT_PAYMENT = 'transaction_table/chang_sort_payment';
const TABLE_SORT_NUMBER = 'transaction_table/chang_sort_number';
const TABLE_SORT_TOTAL = 'transaction_table/chang_sort_total';

const TABLE_SET_HEADER = 'transaction_table/chang_headers';
const TABLE_FILTER = 'transaction_table/filter';

const TABLE_SET_SEARCH = 'transaction_table/search_box_state';
const TABLE_SET_FILTER_DATES = 'transaction_table/filter_dates';
const TABLE_SET_FILTER_METHOD = 'transaction_table/filter_method';
const TABLE_SET_FILTER_RRN = 'transaction_table/filter_rrn';
const TABLE_SET_FILTER_TOTAL = 'transaction_table/filter_totals';

const TABLE_SET_SEARCH_VAL = 'transaction_table/search_box_state_val';
const TABLE_SET_FISKAL = 'transaction_table/isFiskal';

const TABLE_SET_PLUS_INDEX = 'transaction_table/plus_index';
const TABLE_RESET = 'transaction_table/reset';

const TABLE_SET_ITEM = 'transaction_table/set_item';
const TABLE_SET_FILTER = 'transaction_table/is_filter';

const TABLE_SET_RRN = 'transaction_table/set_rrn';
const TABLE_SET_AMOUNT = 'transaction_table/set_amount';

// clean code
const SET_TRANSACTION_FILTERING_STATE = 'transaction_table/set_transaction_filtering_state';
const SET_BANK_FILTERING_STATE = 'transaction_table/set_bank_filtering_state';
const SET_FISCAL_FILTERING_STATE = 'transaction_table/set_fiscal_filtering_state';

export {
  TABLE_SET_FISKAL,
  TABLE_SET_RRN,
  TABLE_SET_AMOUNT,
  TABLE_SET_ITEM,
  TABLE_SORT_DATE,
  TABLE_SORT_PAYMENT,
  TABLE_SORT_NUMBER,
  TABLE_SORT_TOTAL,
  TABLE_SORT_CASHIER,
  TABLE_SORT_TERMINAL,
  TABLE_LOAD,
  TABLE_GET_SERVER,
  TABLE_SET_SERVER,
  TABLE_SET_LOCAL,
  TABLE_SERVER_LOAD,
  TABLE_CHANGE_BUTTON,
  TABLE_CHANGE_DATE,
  TABLE_SORT,
  TABLE_SORT_TYPE,
  TABLE_SET_HEADER,
  TABLE_SET_SEARCH,
  TABLE_SET_SEARCH_VAL,
  TABLE_FILTER,
  TABLE_RESET,
  TABLE_SET_FILTER,
  TABLE_SET_FILTER_DATES,
  TABLE_SET_FILTER_METHOD,
  TABLE_SET_FILTER_RRN,
  TABLE_SET_FILTER_TOTAL,
  TABLE_SET_PLUS_INDEX,
  //
  // clean code
  SET_TRANSACTION_FILTERING_STATE,
  SET_BANK_FILTERING_STATE,
  SET_FISCAL_FILTERING_STATE,
};
