import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogin } from "utils/helpers/auth.helpers";
import { AUTH_ROUTE } from "utils/constants/routes.constants";

export default function PrivateRoute({
  component: Component,
  wrapper: Wrapper,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {isLogin() && (
              <>
                {Wrapper && (
                  <Wrapper>
                    <Component {...props} />
                  </Wrapper>
                )}
                {!Wrapper && <Component {...props} />}
              </>
            )}
            {!isLogin() && <Redirect to={AUTH_ROUTE} />}
          </>
        );
      }}
    />
  );
}
