import moment from "moment";

import { getUUID } from "utils/helpers/getUUID";
import { FiskalTable } from "api";
import { setAlertState, dropAlertState } from "store/alert/alert_actions";
import {
  FISCAL_DETAIL_LOADING,
  SET_FISCAL_LOADING,
  SET_CLOSED_FILTETING_STATE,
  DROP_CLOSED_FILTERING_STATE,
  TABLE_OPEN_SET_HEADER,
  TABLE_OPEN_GET_SERVER,
  TABLE_CLOSED_GET_SERVER,
  TABLE_DETAIL_ITEM,
  FISCAL_DETAIL_ERROR,
  FISKAL_OPENED_LIST_ERROR,
  FISKAL_CLOSED_LIST_ERROR,
  INSERT_EXTENDED_INFO,
} from "./fiscal_table.types";

import { UI_DATE_FORMAT } from "utils/constants/time";

const groupByDate = (state) => {
  const groups = state.reduce((groups, game) => {
    const date = game.info.closeDate.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(game);
    return groups;
  }, {});

  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date: moment(date).format(UI_DATE_FORMAT),
      items: groups[date],
      __id: getUUID(),
    };
  });

  return groupArrays;
};

export const generateHeader = (state, response) => {
  const values = response.reduce(
    (a, c) => {
      a.totalCount = c.totalCount || 0;
      a.totalAmountSaccess = c.totalAmountSaccess || 0;
      a.totalAmountRefaund = c.totalAmountRefaund || 0;
      a.totalAmount = c.totalAmount || 0;

      return a;
    },
    {
      totalCount: 0,
      totalAmountSaccess: 0,
      totalAmountRefaund: 0,
      totalAmount: 0,
    }
  );

  state[0].value = values.totalCount.toFixed(0);
  state[1].value = values.totalAmountSaccess.toFixed(2);
  state[2].value = values.totalAmountRefaund.toFixed(2);
  state[3].value = values.totalAmount.toFixed(2);

  return state;
};

export const closedDay =
  ({ rro, startDate, endDate }) =>
  async (dispatch) => {
    const server = new FiskalTable();
    const defaultClosedDaySuccess = "Зміна була закрита успішно";

    const result = await server.closedDay({ rro });

    if (result) {
      dispatch(
        setAlertState({
          show: true,
          title: "Успішно!",
          subtitle: result?.data?.message || defaultClosedDaySuccess,
          type: "done",
          fnSubmit: () => {},
          fnClose: () => dispatch(dropAlertState()),
        })
      );
      dispatch(fetchAllFiscalInfo({ startDate, endDate }));
    }
  };

const loadOpenedFiscals = ({
  response,
  bank_open_subtotal_state,
  dispatch,
}) => {
  const filteredShifts = response.data.fiskalDetails.filter(
    (item) => !item.shiftClosed
  );

  const openedFiscalShifts = filteredShifts.map((el) => ({
    ...el,
    _id: getUUID(),
    transactionsList: el.transactionsList.sort((a, b) => {
      if (moment(a.ctime) < moment(b.ctime)) {
        return 1;
      }
      if (moment(a.ctime) > moment(b.ctime)) {
        return -1;
      }

      return 0;
    }),
  }));

  dispatch(
    bankOpenSetSubtotal(
      generateHeader(bank_open_subtotal_state, openedFiscalShifts)
    )
  );
  dispatch(bankOpenSetState(openedFiscalShifts));
};

const loadClosedFiscals = async ({ response, dispatch, server }) => {
  let reduxClosedFiscals = response.data.fiskalDays.map((el) => {
    const joinedOpenDate = el.openDate ? el.openDate.split(" ").join("") : null;
    const normalOpenDate = joinedOpenDate
      ? joinedOpenDate.slice(6, 10) +
        "-" +
        joinedOpenDate.slice(0, 2) +
        "-" +
        joinedOpenDate.slice(3, 5) +
        "T" +
        joinedOpenDate.slice(10, 19)
      : null;

    return {
      info: {
        ...el,
        __id: getUUID(),
        openDate: normalOpenDate,
        total_transactions_count: el.totalCount || 0,
        total_transactions_sum: el.totalAmount || 0,
      },
    };
  });

  dispatch(bankClosedSetState(groupByDate(reduxClosedFiscals)));
};

export const fetchAllFiscalInfo = (args) => {
  return async (dispatch, getState) => {
    const server = new FiskalTable();

    dispatch(setFiscalLoading(true));

    dispatch(fiscalOpenedListError(null));
    dispatch(fiscalClosedListError(null));

    const { bank_open_subtotal_state } = getState().fiscal_table;

    const openedFiscalsRequest = server.getFiscalsDayDetail();
    const closedFiscalsRequest = server.getFiscalDays(args);
    let openedFiscalsRequestIsReady = false;
    let closedFiscalsRequestIsReady = false;
    const defaultOpenedFiscalError =
      'Виникла серверна помилка при спробі отримати "Відкриті фіскальні зміни"';
    const defaultClosedFiscalError =
      'Виникла серверна помилка при спробі отримати "Закриті фіскальні зміни"';

    // load opened fiscal requests
    openedFiscalsRequest
      .then((response) => {
        if (!response.data || +response.data.errorCode !== 0) {
          dispatch(
            bankOpenSetSubtotal(generateHeader(bank_open_subtotal_state, []))
          );
          return dispatch(
            fiscalOpenedListError(
              response.data.errorMessage || defaultOpenedFiscalError
            )
          );
        }
        loadOpenedFiscals({
          response,
          bank_open_subtotal_state,
          dispatch,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          bankOpenSetSubtotal(generateHeader(bank_open_subtotal_state, []))
        );
        dispatch(fiscalOpenedListError(defaultOpenedFiscalError));
      })
      .finally(() => {
        openedFiscalsRequestIsReady = true;
        if (closedFiscalsRequestIsReady) dispatch(setFiscalLoading(false));
      });

    // load closed fiscal requests
    closedFiscalsRequest
      .then((response) => {
        if (!response.data || +response.data.errorCode !== 0) {
          dispatch(
            bankOpenSetSubtotal(generateHeader(bank_open_subtotal_state, []))
          );
          return dispatch(
            fiscalClosedListError(
              response.data.errorMessage || defaultClosedFiscalError
            )
          );
        }
        loadClosedFiscals({
          response,
          dispatch,
          server,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          bankOpenSetSubtotal(generateHeader(bank_open_subtotal_state, []))
        );
        dispatch(fiscalClosedListError(defaultClosedFiscalError));
      })
      .finally(() => {
        closedFiscalsRequestIsReady = true;
        if (openedFiscalsRequestIsReady) dispatch(setFiscalLoading(false));
      });
  };
};

export const fetchOpenFiscalInfo = () => {
  return async (dispatch, getState) => {
    dispatch(setFiscalLoading(true));
    dispatch(fiscalOpenedListError(null));
    dispatch(fiscalClosedListError(null));

    const server = new FiskalTable();
    const { bank_open_subtotal_state } = getState().fiscal_table;
    const openedFiscalsRequest = server.getFiscalsDayDetail();
    const defaultOpenedFiscalError =
      'Виникла серверна помилка при спробі отримати "Відкриті фіскальні зміни"';

    openedFiscalsRequest
      .then((response) => {
        if (!response.data || +response.data.errorCode !== 0) {
          dispatch(
            bankOpenSetSubtotal(generateHeader(bank_open_subtotal_state, []))
          );
          return dispatch(
            fiscalOpenedListError(
              response.data.errorMessage || defaultOpenedFiscalError
            )
          );
        }
        loadOpenedFiscals({
          response,
          bank_open_subtotal_state,
          dispatch,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          bankOpenSetSubtotal(generateHeader(bank_open_subtotal_state, []))
        );
        dispatch(fiscalOpenedListError(defaultOpenedFiscalError));
      })
      .finally(() => {
        dispatch(setFiscalLoading(false));
      });
  };
};

export const fetchClosedFiscalInfo = (args) => {
  return async (dispatch) => {
    dispatch(setFiscalLoading(true));
    dispatch(fiscalClosedListError(null));

    const server = new FiskalTable();
    const closedFiscalsRequest = server.getFiscalDays(args);
    const defaultClosedFiscalError =
      'Виникла серверна помилка при спробі отримати "Закриті фіскальні зміни"';

    closedFiscalsRequest
      .then((response) => {
        if (!response.data || +response.data.errorCode !== 0) {
          return dispatch(
            fiscalClosedListError(
              response.data.errorMessage || defaultClosedFiscalError
            )
          );
        }
        loadClosedFiscals({
          response,
          dispatch,
          server,
        });
      })
      .catch((error) => {
        dispatch(fiscalClosedListError(defaultClosedFiscalError));
      })
      .finally(() => {
        dispatch(setFiscalLoading(false));
      });
  };
};

export const loadSingleFiscalClosedDay = ({ shiftCloseDateTiks, info }) => {
  return async (dispatch) => {
    const server = new FiskalTable();
    dispatch(fiskalDetailLoading(true));
    dispatch(setFiscalDetailError(null));

    const defaultError = `Виникла серверна помилка при спробі завантажити "Закриту фіскальну зміну ${moment(
      info?.closeDate
    ).format("DD.MM.YYYY HH:mm")}"`;

    try {
      const getClosedResult = await server.getFiscalsDayDetail(
        shiftCloseDateTiks
      );
      if (
        !getClosedResult ||
        !getClosedResult.data ||
        !getClosedResult.data.fiskalDetails ||
        getClosedResult.data.fiskalDetails.length === 0 ||
        +getClosedResult.data.errorCode !== 0
      ) {
        dispatch(
          setFiscalDetailError({
            shiftCloseDateTiks,
            error: getClosedResult.data.errorMessage || defaultError,
          })
        );
      }
      dispatch(
        insertNewExtendedInfoToSingleClosedDay({
          shiftCloseDateTiks,
          extendedInfo: getClosedResult.data.fiskalDetails[0],
        })
      );
      dispatch(
        bankDetailItem({
          info,
          extendedInfo: getClosedResult.data.fiskalDetails[0],
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        setFiscalDetailError({
          shiftCloseDateTiks,
          error: defaultError,
        })
      );
    } finally {
      dispatch(fiskalDetailLoading(false));
    }
  };
};

export const bankOpenSetState = (state) => ({
  type: TABLE_OPEN_GET_SERVER,
  payload: state,
});
export const bankOpenSetSubtotal = (state) => ({
  type: TABLE_OPEN_SET_HEADER,
  payload: state,
});

export const fiskalDetailLoading = (state) => ({
  type: FISCAL_DETAIL_LOADING,
  payload: state,
});

export const setFiscalLoading = (state) => ({
  type: SET_FISCAL_LOADING,
  payload: state,
});

export const dropClosedFilteringState = () => ({
  type: DROP_CLOSED_FILTERING_STATE,
});
export const setClosedFilteringState = (state) => ({
  type: SET_CLOSED_FILTETING_STATE,
  payload: state,
});
export const insertNewExtendedInfoToSingleClosedDay = (state) => ({
  type: INSERT_EXTENDED_INFO,
  payload: state,
});

export const bankClosedSetState = (state) => ({
  type: TABLE_CLOSED_GET_SERVER,
  payload: state,
});

export const bankDetailItem = (state) => ({
  type: TABLE_DETAIL_ITEM,
  payload: state,
});

export const fiscalOpenedListError = (state) => ({
  type: FISKAL_OPENED_LIST_ERROR,
  payload: state,
});
export const fiscalClosedListError = (state) => ({
  type: FISKAL_CLOSED_LIST_ERROR,
  payload: state,
});
export const setFiscalDetailError = (state) => ({
  type: FISCAL_DETAIL_ERROR,
  payload: state,
});
