import styled, { keyframes } from "styled-components";

import { font_book } from "assets/style/fonts.variables";
import { mouse_pointer, black, grey, white, red } from "assets/style/variables";
import { size } from "assets/style/global-variables";

const profileMenuAnimation = keyframes`
  0% {
    opacity: 0;
    height: 0;
  }
  50% {
    z-index: 1;
  }
  100% {
    z-index: 1;
    opacity: 1;
    height: auto;
  }
`;

const ProfileContainer = styled.section`
  position: relative;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  .menu {
    z-index: -1;
    height: 0;
    opacity: 0;
    animation: ${profileMenuAnimation} ease-in 0.2s 0.8s both;
  }

  &:hover {
    border-radius: 16px 16px 0 0;
    transition: all ease-in 0.2s 0.8s;

    .menu {
      display: block;
      opacity: 1;
      height: auto;
    }
  }

  &.menu__container {
    .menu {
      animation: ${profileMenuAnimation} ease-in 0.8s both;
    }

    &:hover {
      transition: none;
    }
  }

  @media screen and (max-width: ${size.laptop}) {
    margin-left: auto;
  }
`;

const ProfileHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 7px;
  padding-left: 15px;
  grid-column-gap: 12px;
  text-align: right;
  ${mouse_pointer}
  font-family: ${font_book};

  .email {
    font-size: 18px;
    color: ${black};
    margin-bottom: 5px;
  }

  .inform {
    color: ${grey};
    font-size: 12px;
  }

  @media screen and (max-width: ${size.laptop}) {
    padding: 7px;
  }
`;
const ProfileInformation = styled.div`
  margin: 0;
  white-space: nowrap;
`;

const ProfileImg = styled.img`
  width: 43px;
  height: 43px;
  object-fit: cover;
  border-radius: 12px;
`;

const ProfileMenu = styled.div`
  position: absolute;
  right: 0;
  border-top: 1px solid #f6f7fa;
  background: ${white};
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 16px 16px;
  width: 100%;
  display: none;
  min-width: 200px;
  overflow: visible;

  @media screen and (max-width: ${size.laptop}) {
    padding: 10px 15px;
  }
`;

const ProfileList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-items: center;
`;

const Li = styled.li`
  padding: 10px 15px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: ${font_book};
  transition: background-color 0.2s;

  ${mouse_pointer}
  a {
    display: flex;
    text-decoration: none;
  }

  &:hover {
    background-color: #eeffee;
  }

  &:last-child {
    border-radius: 0 0 16px 16px;
  }
`;

const Icon = styled.span`
  margin-right: 10px;
`;

const LiText = styled.p`
  color: ${(props) => (props.red ? red : black)};
`;

export {
  ProfileContainer,
  ProfileHeader,
  ProfileInformation,
  ProfileImg,
  ProfileMenu,
  ProfileList,
  Li,
  Icon,
  LiText,
};
