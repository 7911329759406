import React, { useState } from "react";
import ReactTooltip from "react-tooltip";

import { getUUID } from "utils/helpers/getUUID";

import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";
import { ReactComponent as CloseIcon } from "./close-icon.svg";
import { ReactComponent as RefreshSmall } from "./refresh-small.svg";
import {
  RefreshContainer,
  CloseIconContainer,
  OpenIconContainer,
} from "./Refresh.styles";
import { TooltipLabel } from "ui/TooltipLabel/tooltip-label.styles";

const Refresh = ({ fn, zIndex }) => {
  const [opened, setOpened] = useState(true);

  const componentId = getUUID();

  return (
    <>
      <RefreshContainer
        onClick={fn}
        opened={opened}
        style={zIndex ? { zIndex } : null}
      >
        <RefreshIcon />
      </RefreshContainer>
      <CloseIconContainer
        onClick={() => setOpened(false)}
        opened={opened}
        style={zIndex ? { zIndex: +zIndex + 1 } : null}
      >
        <CloseIcon />
      </CloseIconContainer>
      <OpenIconContainer
        onClick={() => setOpened(true)}
        opened={opened}
        data-tip
        data-for={componentId}
      >
        <RefreshSmall />
      </OpenIconContainer>
      {!opened && (
        <ReactTooltip id={componentId} place="top" effect="solid">
          <TooltipLabel>{"Відкрити рефреш"}</TooltipLabel>
        </ReactTooltip>
      )}
    </>
  );
};

export default Refresh;
