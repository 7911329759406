import React from "react";
import { useSelector } from "react-redux";

import Alert from "ui/Alert/Alert";

const AlertProvider = ({ children }) => {
  const alertState = useSelector((state) => state.alert);

  return (
    <>
      {alertState.show && <Alert {...alertState} />}
      {children}
    </>
  );
};

export default AlertProvider;
