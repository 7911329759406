import { ReactComponent as TransactionIcon } from "assets/icons/total_transaction.svg";
import { ReactComponent as CardIcon } from "assets/icons/total_card.svg";
import { ReactComponent as CashIcon } from "assets/icons/total_cash.svg";
import { ReactComponent as ReturnIcon } from "assets/icons/total_return.svg";
import { ReactComponent as ResutIcon } from "assets/icons/total_result.svg";
import { ReactComponent as PaymentIcon } from "assets/icons/transaction/accept.svg";
import { ReactComponent as ReversedIconComponent } from "assets/icons/transaction/reversed.svg";
import { ReactComponent as UndeterminedIconComponent } from "assets/icons/transaction/clock.svg";
import { ReactComponent as QRIconComponent } from "assets/icons/qr.svg";

const ReversedIcon = () => {
  return (
    <ReversedIconComponent
      style={{
        background: "#FFF8CE",
        width: "48px",
        height: "auto",
        borderRadius: "12px",
        padding: "2px",
      }}
    />
  );
};

const UndeterminedIcon = () => {
  return (
    <UndeterminedIconComponent
      style={{
        background: "#FFF8CE",
        width: "48px",
        height: "auto",
        borderRadius: "12px",
        padding: "6px 2px 2px 6px",
      }}
    />
  );
};

const QRIcon = () => {
  return (
    <QRIconComponent
      style={{
        background: "#d7f2d5",
        width: "48px",
        height: "auto",
        borderRadius: "12px",
        padding: "6px 2px 2px 6px",
      }}
    />
  );
};

export {
  TransactionIcon,
  CardIcon,
  CashIcon,
  ReturnIcon,
  ResutIcon,
  PaymentIcon,
  ReversedIcon,
  UndeterminedIcon,
  QRIcon,
};
