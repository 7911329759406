import styled from "styled-components";

import {
  Button,
  ButtonSecondaryBorder,
} from "components/forms/Button/Button.styles";

import { size } from "assets/style/global-variables";

export const ReportsPreloaderModalContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  h4 {
    font-size: 28px;
  }

  span {
    font-size: 18px;
    opacity: 0.75;
  }

  @media screen and (max-width: ${size.tablet}) {
    h4 {
      font-size: 20px;
    }

    span {
      font-size: 16px;
    }
  }
`;

export const ModalButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const ContainedButton = styled(Button)`
  width: auto;
  padding: 12px 15px;
  font-size: 18px; /*16px;*/
  background: #13877e;
  transition: background 0.2s ease, border-radius 0.2s ease;
  cursor: pointer;

  &:hover {
    background: #0c615a;
  }
`;

export const OutlinedButton = styled(ButtonSecondaryBorder)`
  width: auto;
  padding: 12px 15px;
  font-size: 18px; /*16px;*/
  background: transparent;
  transition: background 0.2s ease, border-radius 0.2s ease;
  cursor: pointer;

  &:hover {
    background: #eee;
  }
`;
