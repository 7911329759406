import React, { useState } from "react";

import {
  TurnOffTerminalContainer,
  ContainedButton,
  ModalButtons,
} from "./style";
import { ModalTitle, ModalSubTitle, ModalBody } from "ui/Modal/modal.styles";
import TimesCancelButton from "ui/Modal/TimesCancelButton";
import { generateIcon } from "../../../../../ui/Alert/icons/alertIconHelper.js";

const CloseFiscalDayModal = ({
  makeInkassActionFunction,
  onCancelInkassAction,
  closeOpenFiscalDay,
  onCanceFiscalDayClosingAction,
  onCancel,
  // cashBalance,
  startHaveCashBalance,
  haveOpenFiscalDay,
}) => {
  const [haveCashBalance, setHaveCashBalance] = useState(startHaveCashBalance);

  const texts = {
    have_cash: {
      title: "Увага! На терміналі присутня готівка в касі.", //`Увага! Готівка в касі ${cashBalance} грн.`,
      subtitle:
        "Перед відключенням терміналу від смартфона рекомендуємо виконати службову видачу готівки.",
      action: "Виконати СЛУЖБОВУ ВИДАЧУ",
      reject: "Не виконувати СЛУЖБОВУ ВИДАЧУ",
      actionFunction: makeInkassActionFunction,
      rejectAction: () => {
        setHaveCashBalance(false);
        onCancelInkassAction();
      },
    },
    have_open_fiscal_day: {
      title: "Увага! У вас є відкрита фіскальна зміна.",
      subtitle: "Бажаєте закрити фіскальну зміну перед відключенням терміналу?",
      action: "Так, закрити фіскальну зміну.",
      reject: "Ні, відключити термінал.",
      actionFunction: () => closeOpenFiscalDay(),
      rejectAction: () => onCanceFiscalDayClosingAction(),
    },
  };
  const currentField = haveCashBalance
    ? "have_cash"
    : haveOpenFiscalDay
    ? "have_open_fiscal_day"
    : "";

  return (
    <TurnOffTerminalContainer>
      {generateIcon("remove")}
      <TimesCancelButton onClick={onCancel} top={".65rem"} right={"1.15rem"} />
      <ModalTitle>{texts[currentField]?.title}</ModalTitle>
      <ModalSubTitle style={{ textAlign: "center" }}>
        {texts[currentField]?.subtitle}
      </ModalSubTitle>
      <ModalBody>
        <ModalButtons style={{ justifyContent: "center" }}>
          <ContainedButton
            onClick={() => texts[currentField]?.actionFunction()}
          >
            {texts[currentField]?.action}
          </ContainedButton>
          <ContainedButton onClick={() => texts[currentField]?.rejectAction()}>
            {texts[currentField]?.reject}
          </ContainedButton>
        </ModalButtons>
      </ModalBody>
    </TurnOffTerminalContainer>
  );
};

export default CloseFiscalDayModal;
