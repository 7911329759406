import LocalStorage from "services/LocalStorage/LocalStorage.service";
import Fetch from "services/Fetch/Fetch.service";
import { URL_SERVER } from "utils/constants/server.constants";
export class LogTable {
  constructor() {
    this.fetch = new Fetch();
    this.localStorage = new LocalStorage();
  }

  generateHeader() {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    return headers;
  }

  getUser(login, index) {
    const headers = this.generateHeader();

    return this.fetch
      .put(`${URL_SERVER}/api/User/History/${login}/${index}`, {}, headers)
      .then((res) => res)
      .catch((error) => console.error(error));
  }
  
}
