import React from "react";

import {
  CheckCaptionContainer,
  CheckCaption,
  CheckCaptionLabel,
  CheckCaptionTitle,
  CheckCaptionViewer,
  CheckCaptionEditor,
  SetDefaultButton,
} from "./CheckCaption.style";
import { isTextFormatted } from "../helpers";

const CheckCaptionComponent = ({
  label,
  title,
  text,
  placeholder,
  disabled,
  onChange,
  onReset,
  className,
}) => {
  const isFormatted = isTextFormatted(text);

  return (
    <CheckCaptionContainer className={className}>
      <CheckCaption>
        <CheckCaptionLabel htmlFor="check-caption">{label}</CheckCaptionLabel>
        <CheckCaptionTitle>{title || ""}</CheckCaptionTitle>
        {disabled ? (
          <CheckCaptionViewer>
            {isFormatted ? <pre>{text}</pre> : text}
          </CheckCaptionViewer>
        ) : (
          <CheckCaptionEditor
            id="check-caption"
            value={text}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
        <hr />
      </CheckCaption>
      {onReset && (
        <SetDefaultButton onClick={onReset} disabled={disabled}>
          Скинути до початкового стану
        </SetDefaultButton>
      )}
    </CheckCaptionContainer>
  );
};

export default CheckCaptionComponent;
