export const generateOperationType = ({ operation, payment_system }) => {
  switch (operation) {
    case "cash_podkrep":
      return "Службове внесення";
    case "cash_inkass":
      return "Службова видача";
    case "qr_refund":
    case "RRN":
      return `Повернення${payment_system ? ` ${payment_system}` : ""}`;
    default:
      return `Оплата ${payment_system || "картка"}`;
  }
};
