import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { setAlertState, dropAlertState } from "store/alert/alert_actions";
import { URL_SERVER } from "utils/constants/server.constants";

const useCancelTransaction = (transaction) => {
  const [isCancelled, setIsCancelled] = useState(false);
  const dispatch = useDispatch();

  const cancelTransaction = useCallback(
    (transaction) => {
      const { transaction_token: token } = transaction;

      return fetch(`${URL_SERVER}/api/QrPayment/canceltransactionqr/${token}`, {
        method: "POST",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error(`${res.status} ${res.statusText}`);
        })
        .then((res) => {
          const { ErrorCode, ErrorMessage } = res;

          if (ErrorCode === 0) {
            setIsCancelled(true);
            dispatch(
              setAlertState({
                type: "done",
                title: "Транзакцію скасовано",
                fnClose: () => {
                  dispatch(dropAlertState());
                },
                show: true,
              })
            );
          } else {
            dispatch(
              setAlertState({
                type: "block",
                title: "Помилка",
                subtitle: `Транзакцію не скасовано. ${ErrorMessage}`,
                fnClose: () => {
                  dispatch(dropAlertState());
                },
                show: true,
              })
            );
          }
        })
        .catch(({ message }) => {
          dispatch(
            setAlertState({
              type: "block",
              title: "Помилка",
              subtitle: message,
              fnClose: () => {
                dispatch(dropAlertState());
              },
              show: true,
            })
          );
        });
    },
    [dispatch]
  );

  const confirmTranCancel = useCallback(
    () =>
      dispatch(
        setAlertState({
          type: "remove",
          title: "Скасувати транзакцію?",
          btn_close: "скасувати",
          fnSubmit: () => {
            dispatch(dropAlertState());
            cancelTransaction(transaction);
          },
          fnClose: () => {
            dispatch(dropAlertState());
          },
          show: true,
        })
      ),
    [dispatch, transaction, cancelTransaction]
  );

  return {
    cancelTransaction: confirmTranCancel,
    isCancelled,
    isPending: transaction.status_transaction === 0,
  };
};

export default useCancelTransaction;
