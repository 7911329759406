import moment from "moment";
import { API_SIMPLE_DATE_FORMAT } from "utils/constants/time";

export default function GenerateDate() {
  const date = moment();

  const formatDate = (date) => date.format(API_SIMPLE_DATE_FORMAT);

  return {
    today() {
      return formatDate(date);
    },

    yesterday() {
      return formatDate(moment().add(-1, "days"));
    },

    lastweek() {
      return formatDate(moment().add(-6, "days"));
    },

    lastmonth() {
      return formatDate(moment().add(-29, "days"));
    },
    currentMonth() {
      return formatDate(moment().startOf("month"));
    },

    format(date) {
      return formatDate(moment(date));
    },
  };
}
