import { Ring, PreloaderContainer } from "./Preloader.styles";

export default function Preloader({ style }) {
  return (
    <PreloaderContainer style={style}>
      <Ring className="ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Ring>
    </PreloaderContainer>
  );
}
