import LocalStorage from "services/LocalStorage/LocalStorage.service";
import Fetch from "services/Fetch/Fetch.service";
import { URL_SERVER } from "utils/constants/server.constants";
import download from "utils/helpers/download.helpers";
export class TransactionDetail {
  constructor() {
    this.fetch = new Fetch();
    this.localStorage = new LocalStorage();
  }

  getBody() {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const body = {};
    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    return { body, headers };
  }

  getInformation(id) {
    const { headers } = this.getBody();
    return this.fetch
      .get(`${URL_SERVER}/api/Transaction/${id}`, headers)
      .then((res) => res)
      .catch((error) => console.error(error));
  }
  getGoods(id) {
    const { body, headers } = this.getBody();

    return this.fetch
      .post(`${URL_SERVER}/api/TransactionGoods/${id}`, body, headers)
      .then((res) => res)
      .catch((error) => console.error(error));
  }
  getReceipt(id) {
    const { headers } = this.getBody();

    return this.fetch
      .getBlob(`${URL_SERVER}/R/${id}`, headers)
      .then((res) => {
        download(
          res,
          res.type === "image/png" ? "receipt.png" : "receipt.pdf",
          res.type
        );
      })
      .catch((error) => console.error(error));
  }
}
