import { useEffect } from 'react';
import { useHeader } from 'store/header/header.context';
import ChangeTitle from 'utils/helpers/changetitle.helpers';
import { SupportContainer, Item } from './Support.styles';

export default function Support() {
  const dispatch = useHeader().dispatch;

  useEffect(() => {
    ChangeTitle(`Контактні дані`, dispatch);
  }, [dispatch]);

  return (
    <SupportContainer>
      <Item>
        <p>Зателефонувати:</p> <a href='tel:+380442478599'>044-247-8599</a>
      </Item>
      <Item>
        <p>Чат у Viber:</p> <a href='viber://chat?number=%2B380638569265'> 063-856-9265</a>
      </Item>
      <Item>
        <p>Написати E-mail:</p> <a href='mailto:oschadpay@oschadbank.ua'>oschadpay@oschadbank.ua</a>
      </Item>
    </SupportContainer>
  );
}
