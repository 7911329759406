const style = {
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
    width: "100%",
  },

  "&multiLine": {
    control: {
      fontFamily: "'Futura Book', sans-serif",
      minHeight: 150,
      backgroundColor: "white",
    },
    highlighter: {
      display: "inline-block",
      lineHeight: "1.6",
      height: 250,
    },
    input: {
      border: "none",
      borderRadius: 12,
      outline: "none",
      caretColor: "#26CFC7",
      lineHeight: "1.6",
      height: 250,
      overflow: "auto",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: "100%",
    margin: "8px 0",

    highlighter: {
      padding: 1,
      border: "none",
    },
    input: {
      padding: 1,
      border: "none",
      outline: "none",
      caretColor: "#26CFC7",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};

export default style;
