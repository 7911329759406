import React from "react";
import Modal from "ui/Modal/Modal";
import TurnOffTerminal from "../../TurnOffTerminal/TurnOffTerminal";

const TurnOffTerminalModal = ({
  handleCloseTurnOffTerminalModal,
  cashBalance,
  isHaveOpenFiscalDay,
  makeInkassActionFunction,
  onCancelInkassAction,
  closeOpenFiscalDayWithTransactionCheck,
  onCanceFiscalDayClosingAction,
}) => {
  return (
    <Modal
      onClose={handleCloseTurnOffTerminalModal}
      className="turn-off-terminal"
    >
      <TurnOffTerminal
        cashBalance={cashBalance}
        startHaveCashBalance={!!cashBalance && +cashBalance !== 0}
        haveOpenFiscalDay={isHaveOpenFiscalDay}
        makeInkassActionFunction={makeInkassActionFunction}
        onCancelInkassAction={onCancelInkassAction}
        closeOpenFiscalDay={closeOpenFiscalDayWithTransactionCheck}
        onCanceFiscalDayClosingAction={onCanceFiscalDayClosingAction}
        onCancel={handleCloseTurnOffTerminalModal}
      />
    </Modal>
  );
};

export default TurnOffTerminalModal;
