import { font_book, font_medium } from "assets/style/fonts.variables";
import {
  mouse_pointer,
  black,
  grey_input,
  white,
  grey,
  caret,
  red,
  tiffany,
} from "assets/style/variables";
import styled, { keyframes } from "styled-components";

const ulAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Tr = styled.tr`
  margin: 12px 0;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
`;

const Th = styled.th`
  width: 100%;
  border: ${({ no_border }) => (no_border ? "none" : "2px solid #f1f1f1")};
  ${(props) => (props.active ? "border-width: 0px" : "")};
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  ${mouse_pointer};
  display: flex;
  align-content: center;
  transition: all 0s;

  ${(props) =>
    props.flex_start
      ? "justify-self: flex-start"
      : props.flex_end
      ? "justify-self: flex-end"
      : ""}
`;

const Td = styled.td`
  background: ${white};
  ${(props) =>
    props.flex_start
      ? "justify-self: flex-start"
      : props.flex_end
      ? "justify-self: flex-end"
      : ""}
`;

const TableContainer = styled.table`
  margin: 0;
  margin-top: 25px;
  width: 100%;
  a {
    text-decoration: none;
  }
`;

const TableIcon = styled.span`
  position: absolute;
  left: 10px;
  display: flex;
  top: 0;
  bottom: 0px;
  align-items: center;
  .calendar-button {
    width: 65px;
    height: 30px;
    position: absolute;
    padding: 0;
    opacity: 0;
    .calendar-icon {
      display: none;
    }
  }
`;

const TableIconSort = styled(TableIcon)`
  outline: none;
  ${(props) => (props.sort ? `transform: rotate(180deg);` : null)}
`;

const TableText = styled.p`
  margin-left: 24px;
  font-size: 14px;
  font-family: ${font_book};
  color: ${grey};
  display: flex;
  align-items: center;
  font-weight: 400;
`;

const TableInput = styled.input`
  outline: none;
  border: 0;
  width: 100%;
  padding: 5px 0;
  padding-left: ${(props) => (props.padding ? "10px" : "30px")};
  font-size: 14px;
  font-family: ${font_book};
  ${caret};
  &::placeholder {
    color: ${grey};
  }
`;

const TableResult = styled.ul`
  display: ${(props) => (props.active ? "block" : "none")};
  color: ${black};
  font-size: 16px;
  position: absolute;
  z-index: 1;
  top: 30px;
  background: ${white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  list-style: none;
  max-height: ${(props) =>
    props.maxHeight ? `${props.maxHeight}px` : "200px"};
  ${(props) =>
    props.maxWidth ? `max-with: ${props.maxWidth}px` : "width: 100%;"}
  overflow: auto;
  -webkit-box-shadow: 2px 2px 6px -1px rgba(28, 44, 56, 0.54);
  -moz-box-shadow: 2px 2px 6px -1px rgba(28, 44, 56, 0.54);
  box-shadow: 2px 2px 6px -1px rgba(28, 44, 56, 0.54);
  animation: ${ulAnimation} 0.4s forwards;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    margin: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bababa;
    border-radius: 10px;
  }
`;

const TableResultLi = styled.li`
  padding: 5px 24px;
  font-family: ${font_book};
  font-weight: 400;
  &:hover {
    background: ${grey_input};
  }
  white-space: nowrap;
`;

const Span = styled.span`
  color: ${grey};
`;

const TableForm = styled.div`
  display: ${(props) => (props.active ? "flex" : "none")};
  position: absolute;
  color: ${white};
  background: ${tiffany};
  width: 100%;
  height: 100%;
  border-radius: 6px;
  padding: 0px 10px;
  justify-content: space-between;
  align-items: center;
  border: 2px solid ${tiffany};
`;

const TableFormResult = styled.p`
  height: 1rem;
  width: 100%;
  flex-basis: 100%;
  font-family: ${font_book};
  color: ${white};
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  ${(props) => (props.style ? props.style : null)}
`;

const TableIconClose = styled.div`
  fill: ${white};
`;

const TerminalText = styled.div`
  font-size: 14px;
  text-align: ${(props) => (props.right ? "right" : "left")};
`;
const TerminalTitle = styled.div`
  color: ${black};
  font-family: ${font_medium};
  /* margin-top: 20px; */

  &.line-through {
    position: relative;
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 40%;
      width: 100%;
      border-bottom: 1px solid;
    }
  }
`;

const TerminalDescribe = styled.span`
  color: ${(props) => (props.error ? red : grey)};
  line-height: 20px;
  font-family: ${font_book};
`;

const MultiSelectorLi = styled.li`
  padding: 5px 24px;
  font-family: ${font_book};
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  &:hover {
    background: ${grey_input};
  }

  input {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  label {
    white-space: nowrap;
  }
`;

export {
  TableResult,
  TableResultLi,
  MultiSelectorLi,
  Th,
  Tr,
  Td,
  Span,
  TableContainer,
  TableIcon,
  TableIconSort,
  TableText,
  TableInput,
  TableForm,
  TableFormResult,
  TableIconClose,
  TerminalDescribe,
  TerminalTitle,
  TerminalText,
};
