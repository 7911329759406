import Switch from "react-switch";
import { ErrorText } from "assets/style/variables";
import TimesCancelButton from "ui/Modal/TimesCancelButton";
import { TextField } from "components/forms";
import { Button, OutlinedButton } from "components/forms/Button/Button.styles";
import {
  ModalActions,
  Container,
  Content,
  Header,
  SizeContainer,
  SwitchContainer,
  TagDetailsFormControl,
} from "../PaymentDescriptionTemplates.styles";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import useTagDetails, {
  getLabel,
  getPlaceholder,
  needsMandatoryCheck,
} from "./useTagDetails";
import { KeyValueListEditorContainer as TagKeyValueListEditorContainer } from "./key-value-list-editor/TagKeyValueListEditor.style";

import { ERROR_TEXT_STYLE } from "../helpers/paymentDescription";
import TagKeyValueListEditor from "./key-value-list-editor/TagKeyValueListEditor";

const TagDetailsModal = ({
  onClose,
  onSave,
  tagProto,
  tag,
  onCancelEdit,
  onFinishEdit,
}) => {
  const {
    auto_populate: autoPopulate,
    hasLengthRestrictions,
    tag_name: tagName,
  } = tagProto || {};
  const hasValueList = tagName === "COMBO";

  const {
    resetErr,
    resetFieldError,
    tooltip,
    handleTooltip,
    isMandatory,
    handleIsMandatory,
    minLength,
    handleMinLength,
    maxLength,
    handleMaxLength,
    validate,
    keyValueItems,
    handleKeyValueItems,
  } = useTagDetails(hasLengthRestrictions, hasValueList, tag);

  const label = getLabel(tagName);
  const placeholder = getPlaceholder(tagName);
  const hasIsMandatoryCheck = needsMandatoryCheck(tagName);

  return (
    <Container>
      <Header>
        <h2>Властивості елементу шаблону</h2>
        <TimesCancelButton onClick={() => onClose(tag)}>
          <CloseIcon />
        </TimesCancelButton>
      </Header>
      <Content>
        <TagDetailsFormControl>
          <TextField
            name={label}
            placeholder={placeholder}
            value={tooltip || ""}
            fn={handleTooltip}
            className="input-active"
            error={resetErr.tooltip.isError}
            fnFocus={() => {
              resetFieldError("tooltip");
            }}
            autoFocus
          />
          <ErrorText style={ERROR_TEXT_STYLE}>{resetErr.tooltip.msg}</ErrorText>
        </TagDetailsFormControl>
        {hasIsMandatoryCheck && (
          <TagDetailsFormControl style={{ marginBottom: "0" }}>
            <SwitchContainer>
              <Switch
                onChange={handleIsMandatory}
                checked={Boolean(isMandatory)}
                height={25}
                width={40}
                handleDiameter={17}
                uncheckedIcon={false}
                checkedIcon={false}
                offHandleColor="#fff"
                onHandleColor="#fff"
                activeBoxShadow={"false"}
                offColor="#c5c5c5"
                onColor="#28ccc6"
                className={"switch"}
              />
              <span>елемент шаблону обов'язковий</span>
            </SwitchContainer>
          </TagDetailsFormControl>
        )}
        {hasValueList && (
          <TagKeyValueListEditorContainer
            style={{ marginTop: "1rem", marginBottom: "4rem" }}
          >
            <TagKeyValueListEditor
              {...{
                keyValueItems,
                handleKeyValueItems,
                error: resetErr.keyValueItems.isError,
              }}
            />
            <ErrorText style={ERROR_TEXT_STYLE}>
              {resetErr.keyValueItems.msg}
            </ErrorText>
          </TagKeyValueListEditorContainer>
        )}
        {hasLengthRestrictions && (
          <SizeContainer>
            <TagDetailsFormControl>
              <TextField
                type="number"
                name="Мінімальна довжина"
                placeholder="уведіть мінімальну довжину"
                value={minLength || ""}
                fn={handleMinLength}
                className="input-active"
                error={resetErr.minLength.isError}
                fnFocus={() => {
                  resetFieldError("minLength");
                  resetFieldError("maxLength");
                }}
                disabled={autoPopulate}
              />
              <ErrorText style={ERROR_TEXT_STYLE}>
                {resetErr.minLength.msg}
              </ErrorText>
            </TagDetailsFormControl>
            <TagDetailsFormControl>
              <TextField
                type="number"
                name="Максимальна довжина"
                placeholder="уведіть максимальну довжину"
                value={maxLength || ""}
                fn={handleMaxLength}
                className="input-active"
                error={resetErr.maxLength.isError}
                fnFocus={() => {
                  resetFieldError("minLength");
                  resetFieldError("maxLength");
                }}
                disabled={autoPopulate}
              />
              <ErrorText style={ERROR_TEXT_STYLE}>
                {resetErr.maxLength.msg}
              </ErrorText>
            </TagDetailsFormControl>
          </SizeContainer>
        )}
      </Content>
      <ModalActions>
        <Button
          onClick={() => {
            onSave({
              tag,
              tooltip,
              isMandatory,
              minLength,
              maxLength,
              keyValueItems,
              validate,
            });
            if (tag.tooltip) onFinishEdit();
          }}
        >
          Зберегти
        </Button>
        <OutlinedButton
          onClick={() => {
            if (tag.tooltip) onCancelEdit();
            onClose(tag);
          }}
        >
          Скасувати
        </OutlinedButton>
      </ModalActions>
    </Container>
  );
};

export default TagDetailsModal;
