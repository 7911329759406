import React from "react";

import { ReactComponent as SettingsIcon } from "../settings.svg";
import {
  DaysInfo,
  InfoLine,
  InfoTerm,
  InfoDescription,
  SettingsButton,
} from "../TerminalDetail.styles";
import useAutoCloseInfo from "./useAutoCloseInfo";
import { FISCAL_AUTO_CLOSING_HELPER_TEXT } from "utils/constants/server.constants";
import { DAY_TYPE_INFO } from "./day_type_info";

const InfoDelimiter = () => <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>;

const AutoCloseInfo = ({ daySettings, setFiscalSettingsOpened }) => {
  const { myself, noBDayClose, noFDayClose, bDayInfoMessage, fDayInfoMessage } =
    useAutoCloseInfo(daySettings);

  return (
    <DaysInfo>
      <InfoLine>
        <InfoTerm>
          {`${FISCAL_AUTO_CLOSING_HELPER_TEXT}${
            myself ? " не налаштовано" : "."
          }`}
        </InfoTerm>
      </InfoLine>
      <InfoLine>
        <InfoTerm>Банківський бізнес-день</InfoTerm>
        <InfoDelimiter />
        <InfoDescription type={DAY_TYPE_INFO.INFO}>
          {noBDayClose
            ? bDayInfoMessage
            : 'закривається автоматично за розкладом (див. "Налаштування закриття")'}
        </InfoDescription>
      </InfoLine>
      <InfoLine>
        <InfoTerm>Фіскальна зміна</InfoTerm>
        <InfoDelimiter />
        <InfoDescription type={DAY_TYPE_INFO.INFO}>
          {noFDayClose
            ? fDayInfoMessage
            : 'закривається автоматично за розкладом (див. "Налаштування закриття")'}
        </InfoDescription>
      </InfoLine>
      <SettingsButton onClick={() => setFiscalSettingsOpened(true)}>
        <SettingsIcon />
        <span>Налаштування закриття</span>
      </SettingsButton>
    </DaysInfo>
  );
};

export default AutoCloseInfo;
