import { Total } from "pages/Panels/Statistics/components";

const ChartTotal = ({ total }) => {
  return (
    <div style={{ paddingTop: "1rem" }}>
      <Total total={total} />
    </div>
  );
};

export default ChartTotal;
