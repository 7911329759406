import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";

import { MainTable } from "api";
import { setAlertState, dropAlertState } from "store/alert/alert_actions";
import {
  INIT_STATE,
  REQUIRED_ERROR_MESSAGE,
  ACTION_TYPE,
  reducer,
  reTagEntry,
  reTagId,
  reTagName,
} from "../helpers/paymentDescription";

const TAGS_RESTRICTED_BY_LENGTH = [
  "FREE_TEXT",
  "FREE_TEXT_LATIN",
  "FREE_TEXT_CYRILLIC",
  "NUMERIC",
];

const HIDDEN_TAGS = ["^#TRAN_DATE#", "^#TRAN_TIME#"];

const usePaymentDescription = ({
  storedTemplates,
  setTemplates,
  initialTemplate,
  setInitialTemplate,
  onKeyDown,
  storedTemplate,
}) => {
  const reduxDispatch = useDispatch();
  const [value, dispatch] = useReducer(reducer, INIT_STATE);
  const {
    disabled,
    deletingTemplate,
    savingTemplate,
    mentions,
    template,
    updatePosition,
    keyDownPosition,
    changed,
  } = value;
  const [initialized, setInitialized] = useState(false);
  const [mentionDictionary, setMentionDictionary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTagDetailsModal, setShowTagDetailsModal] = useState(false);
  const { templateId, tags = [], templateText = "", tagsChanged } = template;
  const templateNameRef = useRef(null);
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      const position = keyDownPosition || updatePosition;

      editorRef.current.selectionStart = position;
      editorRef.current.selectionEnd = position;
    }
  }, [updatePosition]);

  useEffect(() => {
    if (!initialized) {
      setLoading(true);
      setInitialized(true);

      new MainTable()
        .getClientPaymentTags()
        .then((result) => {
          const { ErrorCode, ErrorMessage, tags } = result;

          if (ErrorCode === 0) {
            const mentions = tags.map((tag) => ({
              ...tag,
              hasLengthRestrictions: TAGS_RESTRICTED_BY_LENGTH.includes(
                tag.tag_name
              ),
            }));

            setMentionDictionary(mentions);
          } else {
            reduxDispatch(
              setAlertState({
                show: true,
                type: "block",
                title: "Помилка!",
                subtitle: ErrorMessage,
                fnClose: () => reduxDispatch(dropAlertState()),
              })
            );
          }
        })
        .finally(() => setLoading(false));
    }
  }, [initialized, reduxDispatch]);

  useEffect(() => {
    if (!templateId && initialTemplate && mentionDictionary.length > 0) {
      dispatch({
        type: ACTION_TYPE.INIT_WITH_EXISTING,
        payload: {
          template: initialTemplate,
          mentions: mentionDictionary,
        },
      });
    }
  }, [initialTemplate, mentionDictionary]);

  useEffect(() => {
    const newTag = tags.find((tag) => !tag.tooltip);

    if (newTag) {
      const { tag_name } = newTag;
      const hasAttrs =
        HIDDEN_TAGS.map((tag) => tag_name.match(tag)).filter((item) => item)
          .length === 0;

      if (hasAttrs) {
        setShowTagDetailsModal(true);
      } else {
        newTag.tooltip = newTag.tag_name;
      }
    }
  }, [tags, setShowTagDetailsModal]);

  useEffect(() => {
    if (templateNameRef.current && !disabled) {
      templateNameRef.current.focus();
    }
  }, [disabled, templateNameRef]);

  const setDeletingTemplate = (deleting) => {
    dispatch({
      type: ACTION_TYPE.DELETING_TEMPLATE,
      payload: deleting,
    });
  };

  const setSavingTemplate = (saving) => {
    dispatch({
      type: ACTION_TYPE.SAVING_TEMPLATE,
      payload: saving,
    });
  };

  const onNew = () => {
    if (templateNameRef.current) {
      templateNameRef.current.focus();
    }

    dispatch({ type: ACTION_TYPE.INIT_NEW, payload: mentionDictionary });
  };

  const onCancelNew = () => {
    dispatch({
      type: ACTION_TYPE.CANCEL_NEW,
    });
  };

  const onEdit = (exitEdit = false) => {
    dispatch({
      type: ACTION_TYPE.EDIT,
      payload: exitEdit,
    });
  };

  const onCancelEdit = (template) => {
    dispatch({
      type: ACTION_TYPE.CANCEL_EDIT,
      payload: { template, mentions: mentionDictionary },
    });
  };

  const onSelect = (template) => {
    dispatch({
      type: ACTION_TYPE.INIT_WITH_EXISTING,
      payload: { template, mentions: mentionDictionary },
    });
  };

  const onDelete = () => {
    const alertState =
      storedTemplate?.client_payment_template_id === templateId
        ? {
            show: true,
            type: "block",
            title: "Видалення заборонено!",
            subtitle: `Шаблон '${template.templateName}' є активним шаблоном призначення платежу для даного терміналу та видаленню не підлягає.`,
            fnClose: () => reduxDispatch(dropAlertState()),
          }
        : {
            show: true,
            type: "remove",
            title: `Видалити шаблон '${template.templateName}'?`,
            btn_close: "видалити",
            fnClose: () => reduxDispatch(dropAlertState()),
            fnSubmit: async () => {
              reduxDispatch(dropAlertState());
              setDeletingTemplate(true);

              if (await deleteTemplate(template)) {
                if (
                  initialTemplate?.client_payment_template_id === templateId
                ) {
                  setInitialTemplate(storedTemplate || null);
                }

                if (!(await getTemplates())) {
                  setTemplates(
                    storedTemplates.filter(({ value }) => value !== templateId)
                  );
                }
              }

              setDeletingTemplate(false);
            },
          };

    reduxDispatch(setAlertState(alertState));
  };

  const saveTemplate = async (tagsRequired = true) => {
    const {
      templateId: client_payment_template_id,
      templateName: template_name,
      templateText: template_text,
      displayAtReceipt: display_at_receipt,
      generateEachTransaction: generate_each_transaction,
      tags,
      tagsChanged: tags_changed,
    } = template;

    if (!(template_name && template_text)) {
      validate("templateName", template_name ? null : REQUIRED_ERROR_MESSAGE);
      validate(
        "templateText",
        template_text
          ? tagsRequired && tags.length === 0
            ? "Шаблон призначення платежу має містити підстановки."
            : null
          : REQUIRED_ERROR_MESSAGE
      );

      return;
    }

    const matches = templateText.match(reTagEntry) || [];
    const normalizedMatches = [];
    const orderedTags = [];

    for (const m of matches) {
      const name = m.match(reTagName)[0];
      const tag = tags.find((item) => item.tag_name === name);
      const tagId = tag.tag_name.match(reTagId)[2];

      normalizedMatches.push(tagId);
      orderedTags.push({ ...tag, tag_name: tagId });
    }

    const requestBody = {
      client_payment_template_id,
      display_at_receipt,
      generate_each_transaction,
      template_name,
      template_text: normalizedMatches.join(" "),
      tags: orderedTags,
      tags_changed,
    };

    setSavingTemplate(true);

    try {
      const result = await new MainTable().saveClientPaymentTemplate(
        requestBody
      );

      const {
        data: { ErrorCode, ErrorMessage, templates },
      } = result;

      if (ErrorCode === 0) {
        const templatesToStore = (templates || []).map((item) => ({
          ...item,
          value: item.client_payment_template_id,
          label: item.template_name,
        }));

        setTemplates(templatesToStore);
        reduxDispatch(
          setAlertState({
            show: true,
            type: "done",
            title: "ОК",
            subtitle: "Шаблон успішно збережено",
            fnClose: () => reduxDispatch(dropAlertState()),
          })
        );

        if (client_payment_template_id) {
          onEdit(true);
          onSelect(
            templatesToStore.find(
              ({ value }) => value === client_payment_template_id
            )
          );
        } else {
          const newTemplate = _.orderBy(
            templatesToStore,
            ["value"],
            ["desc"]
          )[0];
          onSelect(newTemplate);
        }

        return;
      }

      reduxDispatch(
        setAlertState({
          show: true,
          type: "block",
          title: "Помилка!",
          subtitle:
            ErrorMessage || "Помилка збереження шаблону призначення платежу",
          fnClose: () => reduxDispatch(dropAlertState()),
        })
      );
    } catch (error) {
      reduxDispatch(
        setAlertState({
          show: true,
          type: "block",
          title: "Помилка!",
          subtitle:
            error.message || "Помилка збереження шаблону призначення платежу",
          fnClose: () => reduxDispatch(dropAlertState()),
        })
      );
    } finally {
      setSavingTemplate(false);
    }
  };

  const onSaveTemplate = () => {
    if (templateId && tagsChanged) {
      reduxDispatch(
        setAlertState({
          show: true,
          type: "remove",
          title: "Увага!",
          // subtitle:
          //   "В шаблон внесено зміни. Усі призначення платежів по попередньому шаблону, що збережені у відкладених чеках, буде видалено. Скасувати ці зміни скасуванням редагування властивостей терміналу буде неможливо. Продовжувати?",
          subtitle:
            "В шаблон внесено зміни. Усі призначення платежів по попередньому шаблону, що збережені у відкладених чеках, буде видалено.",
          acceptButtonLabel: "OK",
          cancelButtonLabel: "Скасувати",
          fnClose: () => reduxDispatch(dropAlertState()),
          fnSubmit: saveTemplate,
          height: "500px",
        })
      );
    } else {
      saveTemplate();
    }
  };

  const getTemplates = async () => {
    try {
      const { ErrorCode, ErrorMessage, templates } =
        await new MainTable().getClientPaymentTemplates();

      if (ErrorCode === 0) {
        setTemplates(templates || []);

        return true;
      } else {
        reduxDispatch(
          setAlertState({
            show: true,
            type: "block",
            title: "Помилка!",
            subtitle:
              ErrorMessage || "Помилка запиту шаблонів призначення платежу",
            fnClose: () => reduxDispatch(dropAlertState()),
          })
        );
      }
    } catch (error) {
      reduxDispatch(
        setAlertState({
          show: true,
          type: "block",
          title: "Помилка!",
          subtitle:
            error.message || "Помилка запиту шаблонів призначення платежу",
          fnClose: () => reduxDispatch(dropAlertState()),
        })
      );
    }

    return false;
  };

  const deleteTemplate = async (template) => {
    const { templateId } = template;

    try {
      const { ErrorCode, ErrorMessage } =
        await new MainTable().deleteClientPaymentTemplate(templateId);

      if (ErrorCode === 0) {
        reduxDispatch(
          setAlertState({
            show: true,
            type: "done",
            title: "ОК",
            subtitle: "Шаблон видалено",
            fnClose: () => reduxDispatch(dropAlertState()),
          })
        );

        dispatch({ type: ACTION_TYPE.DELETED });

        return true;
      } else {
        reduxDispatch(
          setAlertState({
            show: true,
            type: "block",
            title: "Помилка!",
            subtitle:
              ErrorMessage || "Помилка видалення шаблону призначення платежу",
            fnClose: () => reduxDispatch(dropAlertState()),
          })
        );
      }
    } catch (error) {
      reduxDispatch(
        setAlertState({
          show: true,
          type: "block",
          title: "Помилка!",
          subtitle:
            error.message || "Помилка видалення шаблону призначення платежу",
          fnClose: () => reduxDispatch(dropAlertState()),
        })
      );
    }

    return false;
  };

  const onTemplateNameChange = (value) => {
    dispatch({
      type: ACTION_TYPE.NAME_CHANGE,
      payload: value,
    });
  };

  const onTemplateTextChange = (value) => {
    dispatch({
      type: ACTION_TYPE.TEXT_CHANGE,
      payload: value,
    });
  };

  const onDisplayAtReceiptChange = (value) => {
    dispatch({
      type: ACTION_TYPE.DISPLAY_AT_RECEIPT_CHANGE,
      payload: value,
    });
  };

  const onGenerateEachTransactionChange = (value) => {
    dispatch({
      type: ACTION_TYPE.GENERATE_EACH_TRANSACTION_CHANGE,
      payload: value,
    });
  };

  const onTagDetailsCancel = (tag) => {
    setShowTagDetailsModal(false);

    if (!tag.tooltip) {
      dispatch({ type: ACTION_TYPE.CLEAR_UPDATE_POSITION });
      dispatch({ type: ACTION_TYPE.CANCEL });
      dispatch({
        type: ACTION_TYPE.SAVE_UPDATE_POSITION,
        payload: editorRef.current?.selectionStart,
      });
    }

    editorRef.current?.focus();
  };

  const onTagDetailsSave = ({
    tag,
    tooltip,
    isMandatory,
    minLength,
    maxLength,
    keyValueItems,
    validate,
  }) => {
    const isValid = validate();

    if (!isValid) return;

    setShowTagDetailsModal(false);

    if (editorRef?.current) {
      dispatch({
        type: ACTION_TYPE.CLEAR_UPDATE_POSITION,
      });
    }

    dispatch({
      type: ACTION_TYPE.TAG_DETAILS_SAVE,
      payload: {
        tag,
        tooltip,
        isMandatory,
        minLength,
        maxLength,
        mentions: mentionDictionary,
        keyValueItems,
      },
    });

    if (editorRef?.current) {
      dispatch({
        type: ACTION_TYPE.SAVE_UPDATE_POSITION,
        payload: editorRef.current.selectionStart,
      });
    }

    editorRef.current?.focus();
  };

  const validate = (name, message) => {
    dispatch({
      type: ACTION_TYPE.SET_ERROR,
      payload: {
        name,
        message,
      },
    });
  };

  const clearError = (name) => {
    dispatch({
      type: ACTION_TYPE.SET_ERROR,
      payload: {
        name,
        message: null,
      },
    });
  };

  let editorMode;

  switch (templateId) {
    case undefined:
      editorMode = "";
      break;
    case null:
      editorMode = " (новий)";
      break;
    default:
      editorMode = disabled ? "" : " (редагування)";
  }

  const keyDownHandler = (e) => onKeyDown(e, dispatch);

  const onStartEditTagCallback = useCallback(
    () => setShowTagDetailsModal(true),
    []
  );
  const onCancelEditTagCallback = useCallback(
    () => setShowTagDetailsModal(false),
    []
  );

  return {
    updatePosition,
    deletingTemplate,
    savingTemplate,
    editorMode,
    template,
    mentions,
    disabled,
    dispatch,
    onNew,
    onCancelNew,
    onEdit,
    onCancelEdit,
    onSaveTemplate,
    onDelete,
    onTemplateNameChange,
    onTemplateTextChange,
    onDisplayAtReceiptChange,
    onGenerateEachTransactionChange,
    onTagDetailsCancel,
    onTagDetailsSave,
    onStartEditTagCallback,
    onCancelEditTagCallback,
    clearError,
    setSelectedTemplate: onSelect,
    keyDownHandler,
    changed,
    loading,
    templateNameRef,
    editorRef,
    showTagDetailsModal,
  };
};

export default usePaymentDescription;
