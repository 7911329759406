import React, { useMemo, useState } from "react";
import ReactTooltip from "react-tooltip";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

import { TooltipLabel } from "ui/TooltipLabel/tooltip-label.styles";
import Alert from "ui/Alert/Alert";
import Modal from "ui/Modal/Modal";
import TransferModal from "../TransferModal/TransferModal";

import { getUUID } from "utils/helpers/getUUID";

import {
  DeleteButton,
  TransferButton,
  CancelButton,
  CheckAllButton,
} from "./styles";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as DeleteIcon } from "./delete.svg";
import { ReactComponent as TransferIcon } from "./transfer.svg";

const SelectSettings = (props) => {
  const {
    active,
    checkedAllActive,
    checkedAll,
    selectedLength,
    categories,
    onCancel,
    onTransfer,
    onDelete,
    onCheckAll,
  } = props;

  const deleteId = useMemo(() => getUUID(), []);
  const checkAllId = useMemo(() => getUUID(), []);
  const transferId = useMemo(() => getUUID(), []);
  const cancelId = useMemo(() => getUUID(), []);
  const [alertState, setAlertState] = useState({
    opened: false,
    type: "done",
    title: "",
    subtitle: "",
    fnSubmit: () => {},
    btnCloseLabel: "",
  });
  const [transferModalOpened, setTransferModalIsOpened] = useState(false);

  const openDeleteModal = () => {
    setAlertState({
      ...alertState,
      opened: true,
      type: "remove",
      title: "Видалити товари",
      subtitle: `Ви впевнені, що бажаєте видалити ${selectedLength} обраних товарів?`,
      fnSubmit: onDelete,
      btnCloseLabel: "видалити",
    });
  };

  return (
    <>
      {transferModalOpened && (
        <Modal
          onClose={() => setTransferModalIsOpened(false)}
          style={{ width: "600px" }}
        >
          <TransferModal
            selectedLength={selectedLength}
            categories={categories}
            onClose={() => setTransferModalIsOpened(false)}
            onAccept={(categoryId) => {
              onTransfer(categoryId);
              setTransferModalIsOpened(false);
            }}
          />
        </Modal>
      )}
      {alertState.opened && (
        <Alert
          type={alertState.type}
          title={alertState.title}
          subtitle={alertState.subtitle}
          fnClose={() => setAlertState({ ...alertState, opened: false })}
          fnSubmit={() => {
            setAlertState({ ...alertState, opened: false });
            alertState.fnSubmit();
          }}
          btn_close={alertState.btnCloseLabel}
        />
      )}
      <DeleteButton
        opened={active}
        data-tip
        data-for={deleteId}
        onClick={openDeleteModal}
      >
        <DeleteIcon />
      </DeleteButton>
      {active && (
        <ReactTooltip id={deleteId} place="top" effect="solid">
          <TooltipLabel>{"Видалити обрані товари"}</TooltipLabel>
        </ReactTooltip>
      )}
      <TransferButton
        opened={active}
        data-tip
        data-for={transferId}
        onClick={() => setTransferModalIsOpened(true)}
      >
        <TransferIcon />
      </TransferButton>
      {active && (
        <ReactTooltip id={transferId} place="top" effect="solid">
          <TooltipLabel>
            {"Перемістити обрані товари у іншу категорію"}
          </TooltipLabel>
        </ReactTooltip>
      )}
      <CheckAllButton
        isOnly={!active}
        checkedAll={checkedAll}
        opened={checkedAllActive}
        data-tip
        data-for={checkAllId}
        disabled={checkedAll}
        onClick={() => onCheckAll(checkedAll)}
      >
        <IoCheckmarkDoneSharp />
      </CheckAllButton>
      {checkedAllActive && (
        <ReactTooltip id={checkAllId} place="top" effect="solid">
          <TooltipLabel>{"Обрати всі товари"}</TooltipLabel>
        </ReactTooltip>
      )}
      <CancelButton
        opened={active}
        data-tip
        data-for={cancelId}
        onClick={onCancel}
      >
        <CloseIcon />
      </CancelButton>
      {active && (
        <ReactTooltip id={cancelId} place="top" effect="solid">
          <TooltipLabel>{"Відмінити вибір"}</TooltipLabel>
        </ReactTooltip>
      )}
    </>
  );
};

export default SelectSettings;
