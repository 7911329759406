const TABLE_OPEN_GET_SERVER = "bank_table_open/get_server";
const TABLE_OPEN_SET_HEADER = "bank_table_open/set_headers";
const TABLE_OPEN_LOAD = "bank_table_open/loading";
const TABLE_OPEN_SET_SEARCH = "bank_table_open/set_search";

const TABLE_CLOSED_GET_SERVER = "bank_table_closed/get_server";
const TABLE_CLOSED_SET_SERVER_LOCAL = "bank_table_closed/set_server";
const TABLE_CLOSED_LOAD = "bank_table_closed/loading";
const TABLE_CLOSED_SET_SEARCH = "bank_table_closed/set_search";
const TABLE_CLOSED_DATE_TO = "bank_table_closed/date_to";
const TABLE_CLOSED_DATE_FROM = "bank_table_closed/date_from";

const TABLE_SET_FILTER_DATES_FROM = "bank_table_closed/filter_dates_from";
const TABLE_SET_FILTER_DATES_TO = "bank_table_closed/filter_dates_to";
const TABLE_SET_FILTER_BOX = "bank_table_closed/filter_box";
const TABLE_SET_FILTER_TRANSACTION = "bank_table_closed/filter_transaction";
const TABLE_SET_FILTER_TOTAL = "bank_table_closed/filter_totals";

const TABLE_SET_FILTER_DATES_FROM_VAL =
  "bank_table_closed/filter_dates_from_val";
const TABLE_SET_FILTER_DATES_TO_VAL = "bank_table_closed/filter_dates_to_val";
const TABLE_SET_FILTER_BOX_VAL = "bank_table_closed/filter_box_val";
const TABLE_SET_FILTER_TRANSACTION_VAL =
  "bank_table_closed/filter_transaction_val";
const TABLE_SET_FILTER_TERMINAL_VAL =
  "bank_table_closed/filter_terminal_val";
const TABLE_SET_FILTER_TOTAL_VAL = "bank_table_closed/filter_totals_val";

const TABLE_SORT = "bank_table_closed/sort";
const TABLE_SORT_PACKET = "bank_table_closed/sort_packet";
const TABLE_SORT_DATE_FROM = "bank_table_closed/sort_date_from";
const TABLE_SORT_DATE_TO = "bank_table_closed/sort_date_to";
const TABLE_SORT_STATUS = "bank_table_closed/sort_status";
const TABLE_SORT_TRANSACTION = "bank_table_closed/sort_transaction";
const TABLE_SORT_TOTAL = "bank_table_closed/sort_total";

const TABLE_DETAIL_ITEM = "bank_table_detail/item";
export {
  TABLE_SET_FILTER_TERMINAL_VAL,
  TABLE_SORT_PACKET,
  TABLE_SORT_DATE_FROM,
  TABLE_SORT_DATE_TO,
  TABLE_SORT_STATUS,
  TABLE_SORT_TRANSACTION,
  TABLE_SORT_TOTAL,
  TABLE_CLOSED_SET_SERVER_LOCAL,
  TABLE_SET_FILTER_DATES_FROM,
  TABLE_SET_FILTER_TOTAL,
  TABLE_SET_FILTER_TRANSACTION,
  TABLE_SET_FILTER_BOX,
  TABLE_SET_FILTER_DATES_TO,
  TABLE_SET_FILTER_DATES_FROM_VAL,
  TABLE_SET_FILTER_TOTAL_VAL,
  TABLE_SET_FILTER_TRANSACTION_VAL,
  TABLE_SET_FILTER_BOX_VAL,
  TABLE_SET_FILTER_DATES_TO_VAL,
  TABLE_OPEN_GET_SERVER,
  TABLE_OPEN_SET_HEADER,
  TABLE_OPEN_LOAD,
  TABLE_OPEN_SET_SEARCH,
  TABLE_CLOSED_GET_SERVER,
  TABLE_CLOSED_LOAD,
  TABLE_CLOSED_SET_SEARCH,
  TABLE_CLOSED_DATE_TO,
  TABLE_CLOSED_DATE_FROM,
  TABLE_DETAIL_ITEM,
  TABLE_SORT,
};
