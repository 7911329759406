import LocalStorage from "services/LocalStorage/LocalStorage.service";
import Fetch from "services/Fetch/Fetch.service";
import { URL_SERVER } from "utils/constants/server.constants";

export class NewsAPI {
  constructor(errorHandler) {
    this.errorHandler = errorHandler;
    this.fetch = new Fetch();
    this.localStorage = new LocalStorage();
  }

  getMessages() {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    return this.fetch.get(`${URL_SERVER}/api/Messages`, headers);
  }

  getMessage(messageId) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    return this.fetch.get(
      `${URL_SERVER}/api/Messages/Message/${messageId}`,
      headers
    );
  }

  markMessagesRead(ids) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    return this.fetch.put(`${URL_SERVER}/api/Messages/${ids}/1`, null, headers);
  }

  markMessagesNotRead(ids) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    return this.fetch.put(`${URL_SERVER}/api/Messages/${ids}/0`, null, headers);
  }

  deleteMessages(ids) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    return this.fetch.delete(
      `${URL_SERVER}/api/Messages/${ids}`,
      token,
      "WEB_" + device
    );
  }
}
