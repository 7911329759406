import React from "react";
import { BankClosedContainer } from "../Bank.styles";
import Table from "./BankClosed.table";

export default function BankClosed({ setBusinessDays }) {
  return (
    <BankClosedContainer>
      <Table setBusinessDays={setBusinessDays} />
    </BankClosedContainer>
  );
}
