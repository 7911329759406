import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

import PreloaderActionButton from "components/additional/PreloaderActionButton/PreloaderActionButton";

// functions
import { getUUID } from "../../../../../utils/helpers/getUUID";

// constants
import {
  FISCAL_DAY_ROUTE,
  FISCAL_DAY_ROUTE_CLOSED,
} from "utils/constants/routes.constants";

// styles
import {
  BankContainer,
  ButtonContainer,
  ButtonSwitcher,
} from "./PageSwitcher.styles";

const PageSwitcher = ({
  downloadAllZReports,
  className,
  makingAllZReports,
}) => {
  const history = useHistory();

  const buttons = useMemo(
    () => [
      {
        id: getUUID(),
        text: "Відкриті фіскальні зміни",
        active: history.location.pathname === FISCAL_DAY_ROUTE,
        onClickHandler: () => history.push(FISCAL_DAY_ROUTE),
      },
      {
        id: getUUID(),
        text: "Закриті фіскальні зміни",
        active: history.location.pathname === FISCAL_DAY_ROUTE_CLOSED,
        onClickHandler: () => history.push(FISCAL_DAY_ROUTE_CLOSED),
      },
    ],
    [history.location]
  );

  return (
    <BankContainer className={className}>
      <ButtonContainer>
        {buttons.map(({ id, active, text, onClickHandler }) => (
          <ButtonSwitcher
            key={id}
            data-id={id}
            active={active}
            onClick={onClickHandler}
          >
            {text}
          </ButtonSwitcher>
        ))}
      </ButtonContainer>
      {history.location.pathname === FISCAL_DAY_ROUTE_CLOSED && (
        <PreloaderActionButton
          actionTitle="Усі звіти в zip-архів"
          inProgressTitle="Формування архіву ..."
          onClick={downloadAllZReports}
          inProgress={makingAllZReports}
        />
      )}
    </BankContainer>
  );
};

export default PageSwitcher;
