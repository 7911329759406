import { useCallback, useEffect, useState } from "react";

const usePaymentTemplates = ({
  commentMandatory,
  dispatch,
  MainTable,
  storedPaymentTemplateId,
  setAlertState,
  dropAlertState,
}) => {
  const [clientPaymentTemplates, setClientPaymentTemplates] = useState([]);
  const [clientPaymentTemplate, setClientPaymentTemplate] = useState(null);
  const [errorMessage, setErrorMessage] = useState();

  const getTemplates = useCallback(() => {
    new MainTable()
      .getClientPaymentTemplates()
      .then(({ ErrorCode, ErrorMessage, templates }) => {
        if (ErrorCode === 0) {
          return setClientPaymentTemplates(templates);
        }

        dispatch(
          setAlertState({
            show: true,
            type: "block",
            title: "Помилка",
            subtitle: ErrorMessage,
            fnClose: () => dispatch(dropAlertState()),
          })
        );
      })
      .catch((e) => {
        dispatch(
          setAlertState({
            show: true,
            type: "block",
            title: "Помилка",
            subtitle: e.message,
            fnClose: () => dispatch(dropAlertState()),
          })
        );
      });
  }, [MainTable, dispatch, setAlertState, dropAlertState]);

  useEffect(() => {
    if (commentMandatory) {
      getTemplates();
    }
  }, [commentMandatory, getTemplates]);

  useEffect(() => {
    if (!clientPaymentTemplates?.length) return;

    const template = clientPaymentTemplates.find(
      ({ value }) =>
        value === (clientPaymentTemplate?.value || storedPaymentTemplateId)
    );

    setClientPaymentTemplate(template || null);
  }, [clientPaymentTemplates]);

  return {
    getTemplates,
    clientPaymentTemplates,
    setClientPaymentTemplates,
    clientPaymentTemplate,
    setClientPaymentTemplate,
    errorMessage,
    setErrorMessage,
  };
};

export default usePaymentTemplates;
