import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Transition } from "react-transition-group";

// components
import Alert from "ui/Alert/Alert";
import Preloader from "components/additional/PreLoader/Preloader";
import Refresh from "ui/Refresh/Refresh";
import CreateCategory from "../CreateCategory/CreateCategory";
import EditCategory from "../EditCategory/EditCategory";
import CreateChildrenCategory from "../CreateChildrenCategory/CreateChildrenCategory";

// functions
import {
  fetchCategories,
  getCategoryChildren,
  addNewCategory,
  switchCategoryChildren,
  deleteCategory,
  editCategory,
} from "./service";

// styles
import {
  CategoriesTitle,
  CategoriesNotFound,
  CategoriesContainer,
  CategoryItemContainer,
  CategoryItem,
} from "./styles";
import { ReactComponent as CornerIcon } from "./corner.svg";
import { ReactComponent as DeleteIcon } from "./delete.svg";
import { ReactComponent as PlusIcon } from "./plus.svg";
import { ReactComponent as EditIcon } from "./edit.svg";
import starIcon from "assets/images/star.png";

const CategoriesTable = (props) => {
  const {
    localLoading,
    setLocalLoading,
    isCreatingMainCategory,
    setIsCreatingMainCategory,
  } = props;

  const [alertState, setAlertState] = useState({
    show: false,
    title: null,
    subtitle: "",
    type: "done",
    fnSubmit: () => {},
  });

  const [categories, setCategories] = useState(null);

  const [oldCategory, setOldCategory] = useState(null);
  const [editCategoryMode, setEditCategoryMode] = useState(false);

  const [parentCategory, setParentCategory] = useState(null);
  const [createDownCategoryMode, setCreateDownCategoryMode] = useState(false);

  function resetServer() {
    fetchCategories({ setLocalLoading, setCategories });
  }

  useEffect(() => {
    fetchCategories({ setLocalLoading, setCategories });
  }, []);

  useEffect(() => {
    if (isCreatingMainCategory) {
      if (createDownCategoryMode) setCreateDownCategoryMode(false);
      if (editCategoryMode) setEditCategoryMode(false);
    }
  }, [isCreatingMainCategory, createDownCategoryMode, editCategoryMode]);

  if (localLoading) return <Preloader />;

  const categoriesMapper = (category) => {
    return (
      <React.Fragment key={category.id}>
        <CategoryItemContainer key={category.name}>
          {category.level > 0 && <CornerIcon />}
          <CategoryItem
            row={category.level}
            hasDown={category.inverseParent.length !== 0}
            onClick={
              !category.children || category.children.length === 0
                ? category.inverseParent.length !== 0
                  ? () =>
                      onDownloadNextChildrenCategory(
                        category.id,
                        category.pathIds
                      )
                  : null
                : category.children[0].active
                ? () => onDisabledCategoryChildren(category.pathIds)
                : () => onAnableCategoryChildren(category.pathIds)
            }
          >
            <div className="left">
              <span>{category.name}</span>
              {category.inverseParent.length !== 0 && (
                <img className="star-img" src={starIcon} alt="" />
              )}
            </div>
            <div className="right">
              <EditIcon
                data-tip
                data-for="editTip"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditCategoryMode(true);
                  setOldCategory({ ...category });
                  setCreateDownCategoryMode(false);
                  setParentCategory(null);
                  if (isCreatingMainCategory) {
                    setIsCreatingMainCategory(false);
                  }
                }}
              />
              <ReactTooltip id="editTip" place="top" effect="solid">
                Редагувати категорію
              </ReactTooltip>
              <DeleteIcon
                data-tip
                data-for="deleteTip"
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteCategory({ categoryId: category.id });
                }}
              />
              <ReactTooltip id="deleteTip" place="top" effect="solid">
                Видалити категорію
              </ReactTooltip>
              <PlusIcon
                data-tip
                data-for="plusTip"
                onClick={(e) => {
                  e.stopPropagation();
                  setCreateDownCategoryMode(true);
                  setParentCategory({ ...category });
                  setEditCategoryMode(false);
                  setOldCategory(null);
                  if (isCreatingMainCategory) {
                    setIsCreatingMainCategory(false);
                  }
                }}
              />
              <ReactTooltip id="plusTip" place="top" effect="solid">
                Додати підкатегорію
              </ReactTooltip>
            </div>
          </CategoryItem>
        </CategoryItemContainer>
        {category.children &&
          category.children.length !== 0 &&
          category.children[0].active &&
          category.children.map(categoriesMapper)}
      </React.Fragment>
    );
  };

  return (
    <>
      <Refresh fn={resetServer} />
      {alertState.show ? (
        <Alert
          type={alertState.type}
          title={alertState.title}
          subtitle={alertState.subtitle}
          fnClose={() => setAlertState((val) => ({ ...val, show: !val.show }))}
          fnSubmit={() => alertState.fnSubmit()}
          btn_close={""}
        />
      ) : null}
      <Transition
        in={isCreatingMainCategory}
        timeout={400}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {(state) => (
          <CreateCategory
            onAddNewCategory={onAddNewCategory}
            transitionState={state}
          />
        )}
      </Transition>
      {editCategoryMode && (
        <EditCategory
          onEditCategory={onEditCategory}
          oldCategoryName={oldCategory.name}
          onClose={() => {
            setEditCategoryMode(false);
            setOldCategory(null);
          }}
        />
      )}
      <Transition
        in={createDownCategoryMode}
        timeout={400}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {(state) => (
          <CreateChildrenCategory
            onCreateChildrenCategory={onCreateChildrenCategory}
            parentCategoryName={parentCategory?.name}
            onClose={() => {
              setCreateDownCategoryMode(false);
              setParentCategory(null);
            }}
            transitionState={state}
          />
        )}
      </Transition>
      <CategoriesTitle>Категорії</CategoriesTitle>
      {categories && categories.length === 0 && (
        <CategoriesNotFound>
          Категорій не знайдено. Створіть першу
        </CategoriesNotFound>
      )}
      {categories && categories.length !== 0 && (
        <CategoriesContainer>
          {categories.map(categoriesMapper).flat()}
        </CategoriesContainer>
      )}
    </>
  );

  function onDownloadNextChildrenCategory(categoryId, pathIds) {
    getCategoryChildren({ categories, setCategories, categoryId, pathIds });
  }

  function onDisabledCategoryChildren(pathIds) {
    switchCategoryChildren({ categories, setCategories, pathIds, flag: false });
  }

  function onAnableCategoryChildren(pathIds) {
    switchCategoryChildren({ categories, setCategories, pathIds, flag: true });
  }

  function onAddNewCategory({ name, parentId }) {
    addNewCategory({
      name,
      parentId,
      resetServer,
      setLocalLoading,
      setAlertState,
    });
    setParentCategory(null);
    setCreateDownCategoryMode(false);
    setOldCategory(null);
    setEditCategoryMode(false);
    setIsCreatingMainCategory(false);
  }

  function onDeleteCategory({ categoryId }) {
    setAlertState((val) => ({
      ...val,
      title: "Ви точно хочете видалити категорію?",
      subtitle: "Це призведе до видалення всіх підкатегорій цієї категорії.",
      type: "remove",
      show: true,
      fnSubmit: () => {
        setAlertState((val) => ({ ...val, show: false }));
        deleteCategory({
          categoryId,
          resetServer,
          setLocalLoading,
          setAlertState,
        });
      },
    }));
  }

  function onEditCategory(newName) {
    editCategory({
      name: newName,
      categoryId: oldCategory.id,
      resetServer,
      setLocalLoading,
      setOldCategory,
      setEditCategoryMode,
      setAlertState,
    });
  }

  function onCreateChildrenCategory(newName) {
    addNewCategory({
      name: newName,
      parentId: parentCategory.id,
      resetServer,
      setLocalLoading,
      setAlertState,
    });
    setParentCategory(null);
    setCreateDownCategoryMode(false);
  }
};

export default CategoriesTable;
