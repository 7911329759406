import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setStatParams } from "store/statistics/stat_actions";
import useStatPeriod from "pages/Panels/Statistics/useStatPeriod";

const useHeader = (chartState) => {
  const dispatch = useDispatch();
  const { beginYear, endYear } = chartState;
  const { years } = useStatPeriod();

  const statBeginYear = useMemo(
    () => years.find(({ value }) => value === beginYear),
    [years, beginYear]
  );
  const statEndYear = useMemo(
    () => years.find(({ value }) => value === endYear),
    [years, endYear]
  );

  return {
    beginYear: statBeginYear,
    endYear: statEndYear,
    years,
    setBeginYear: (year) =>
      dispatch(setStatParams({ ...chartState, beginYear: year?.value })),
    setEndYear: (year) =>
      dispatch(setStatParams({ ...chartState, endYear: year?.value })),
  };
};

export default useHeader;
