import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";

// redux & functions
import { bOpenTotal, bankDetailItem } from "store/bank_table/bank_table.slicer";
import { useHeader } from "store/header/header.context";
import { BankOpen } from "api/bank_open.api";
import ChangeTitle, {
  ChangeLeft,
  ChangeSubtitle,
  SetMobileBackButton,
} from "utils/helpers/changetitle.helpers";
import { setBankFilteringState } from "store/transaction_table/transaction_table.actions";

// assets
import { ReactComponent as TerminalIcon } from "assets/icons/transaction/transaction_detail/terminal.svg";
import { ReactComponent as PaketIcon } from "assets/icons/packet.svg";

// components
import Transaction from "pages/Panels/Transaction/Transaction.panel";
import SubTotal from "components/layouts/SubTotal/SubTotal";
import ZReciept from "components/additional/Z-Reciept/Z-Reciept";
import ReportsLoader from "ui/ReportsLoader/ReportsLoader";
import BackIcon from "ui/BackIcon/BackIcon";

// styles
import {
  DivContainer,
  SectionInform,
  Title,
  SubTitle,
  DivContainerIcon,
  DivContainerText,
} from "../../Transaction/TransactionDetail/TransactionDetail.styles";
import { BankDetailContainer, BankDetailActions } from "./BankDetail.styles";

import {
  fetchServer,
  fetchServerClosed,
} from "store/bank_table/bank_table.actions";

export default function BankDetail() {
  const { dispatch } = useHeader();
  const reduxDispatch = useDispatch();
  const location = useLocation();
  const api = new BankOpen();
  let total = useSelector(bOpenTotal);
  const item = useSelector(bankDetailItem);
  const {
    status,
    startDate,
    endDate,
    terminal_search,
    search_cashier,
    search_method_payment,
    rrn_search,
    amount_search,
  } = useSelector((state) => state.transaction_table.bank_filtering_state);
  total[0].value = item.count_total;
  total[1].value = item.amount_success;
  total[2].value = item.amount_rrn;
  total[3].value = item.amount_reversed;
  total[4].value = item.amount_total;

  const [isItemOpened, setIsItemOpened] = useState(false);

  const convertBddToDate = (bdd) => {
    if (!bdd) return null;

    bdd = bdd.split(".");

    return moment(bdd[1] + "/" + bdd[0] + "/" + bdd[2]).format("YYYYMMDD");
  };

  const handleExcelLoad = async (isExtended) =>
    api.downloadExcel({
      bdd: convertBddToDate(item.bdd),
      ptname: item.ptname,
      batch: item.batch,
      dateFrom: startDate ? moment(startDate).format("YYYYMMDD") : null,
      dateTo: endDate ? moment(endDate).format("YYYYMMDD") : null,
      status: status,
      search_rrn: rrn_search,
      search_amount: amount_search,
      search_method_payment: search_method_payment,
      search_terminal: terminal_search,
      search_cashier: search_cashier,
      extended_rep: isExtended,
    });

  const handlePdfLoad = async (isExtended) =>
    api.downloadPdf({
      bdd: convertBddToDate(item.bdd),
      ptname: item.ptname,
      batch: item.batch,
      dateFrom: startDate ? moment(startDate).format("YYYYMMDD") : null,
      dateTo: endDate ? moment(endDate).format("YYYYMMDD") : null,
      status: status,
      search_rrn: rrn_search,
      search_amount: amount_search,
      search_method_payment: search_method_payment,
      search_terminal: terminal_search,
      search_cashier: search_cashier,
      extended_rep: isExtended,
    });

  const params = useParams();
  const { id } = params;
  const {
    state: { openDate, closeDate },
  } = location;

  useEffect(() => {
    localStorage.setItem("bank_exit_with_drop_state", 1);

    if (!closeDate) {
      setIsItemOpened(true);
    }

    if (Object.keys(item).length === 0) {
      if (closeDate) {
        reduxDispatch(fetchServerClosed(false, id, openDate, closeDate));
      } else {
        reduxDispatch(fetchServer(false, null, id));
      }
    }

    return () => {
      if (!!localStorage.getItem("bank_exit_with_drop_state")) {
        localStorage.removeItem("bank-bdd");
        localStorage.removeItem("bank-ptname");
        localStorage.removeItem("bank-batch");
        reduxDispatch(setBankFilteringState({ field: "ptname", value: null }));
        reduxDispatch(setBankFilteringState({ field: "bdd", value: null }));
        reduxDispatch(setBankFilteringState({ field: "batch", value: null }));
      }
    };
  }, []);

  useEffect(() => {
    let date;
    if (item.bdd) {
      date = `${item.bdd.slice(6, 8)}.${item.bdd.slice(
        4,
        6
      )} - ${item.bdd.slice(6, 8)}.${item.bdd.slice(4, 6)}.${item.bdd.slice(
        2,
        4
      )}`;
    }
    if (item.sett_date_time) {
      if (item.sett_date_time.indexOf("/") !== -1) {
        const regex = new RegExp("/", "g");
        date = `${item.bdd.slice(6, 8)}.${item.bdd.slice(
          4,
          6
        )}.${item.bdd.slice(2, 4)} - ${item.sett_date_time
          .replace(regex, ".")
          .slice(0, 5)}.${item.sett_date_time.slice(
          8,
          10
        )} ${item.sett_date_time.slice(11, 16)}`;
      } else {
        const closedTime = item.sett_date_time.split(" ");
        date = `${item.bdd.slice(0, 5)}.${item.bdd.slice(
          8,
          10
        )} — ${closedTime[0].slice(0, 5)}.${closedTime[0].slice(8, 10)} ${
          closedTime[1]
        }`;
      }
    }
    ChangeTitle("Банківські бізнес-дні", dispatch);
    ChangeLeft(dispatch);
    ChangeSubtitle(date, dispatch);
    SetMobileBackButton(true, dispatch);
  }, [dispatch, item.sett_date_time, item.bdd]);

  return (
    <BankDetailContainer>
      <SectionInform>
        <DivContainer className="width-50">
          <DivContainerIcon>
            <TerminalIcon />
          </DivContainerIcon>
          <DivContainerText>
            <Title>{item.ptdesctiption}</Title>
            <SubTitle>{item.ptaddress}</SubTitle>
          </DivContainerText>
        </DivContainer>
        <DivContainer className="width-50">
          <DivContainerIcon>
            <PaketIcon />
          </DivContainerIcon>
          <DivContainerText>
            <Title>Пачка №{item.batch}</Title>
            <SubTitle>Номер пачки у бізнес-дні</SubTitle>
          </DivContainerText>
        </DivContainer>
      </SectionInform>

      <div className="subtotal">
        <SubTotal state={total} />
      </div>

      {!isItemOpened && (
        <BankDetailActions>
          <ZReciept
            type={"bank"}
            bdd={item.bdd}
            batch={item.batch}
            terminalId={item.ptname}
          />
          <ReportsLoader
            handleExcelLoad={handleExcelLoad}
            handlePdfLoad={handlePdfLoad}
          />
        </BankDetailActions>
      )}

      <Transaction
        businessDay={true}
        onSelectTransaction={() =>
          localStorage.removeItem("bank_exit_with_drop_state")
        }
      />

      <BackIcon className="default" />
    </BankDetailContainer>
  );
}
