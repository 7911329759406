import React from "react";
import Switch from "react-switch";
import { useSelector } from "react-redux";

import GlobalSelect from "ui/GlobalSelect/GlobalSelect";
import { RadioItem } from "../TerminalDetail/TerminalDetail.styles";
import { ReactComponent as CopyIcon } from "./copy.svg";

import {
  FISCAL_MANUALLY_CLOSING_HELPER_TEXT,
  FISCAL_AUTO_CLOSING_WARNING_TEXT,
  FISCAL_AUTO_CLOSING_ERROR_WARNING,
  FISCAL_AUTO_CLOSING_HELPER_TEXT,
} from "utils/constants/server.constants";
import { mobileVersion as rMobileVersion } from "store/main_table/main_table.slicer";

import { HOURS, MINUTES, WEEK_DAYS } from "utils/constants/time";

const FiscalDayContent = (props) => {
  const {
    currentSettings,
    onSelectRadioBox,
    onTogglerTrigger,
    selectNewDate,
    onDuplicateDateToAll,
    onSelectCheckbox,
    rroDisabled,
    fiscalizationDisabled,
  } = props;

  const mobileVersion = useSelector(rMobileVersion) < 900;

  const selectorStyle = {
    width: "68px",
    overflowY: "hide",
    padding: "3px 9px",
    margin: "0 5px",
  };

  const svgStyle = {
    right: "5px",
    width: "10px",
  };

  const listStyle = {
    top: "35px",
  };

  const getListHeight = (i) => {
    switch (i) {
      case 5:
        return "140";
      case 6:
        return "120";
      default:
        return "180";
    }
  };

  return (
    <>
      <div className="block">
        <RadioItem
          onClick={() =>
            onSelectRadioBox({
              name: "myself",
              parentField: "fiscalClosingRadioboxes",
            })
          }
          style={{ marginBottom: "0" }}
        >
          <input
            type="radio"
            name="by_myself"
            checked={currentSettings.fiscalClosingRadioboxes.myself}
            onChange={() => {}}
          />
          <label htmlFor="by_myself" className="bold">
            {FISCAL_MANUALLY_CLOSING_HELPER_TEXT}
          </label>
        </RadioItem>
        {mobileVersion && (
          <span className="block-warning">
            {FISCAL_AUTO_CLOSING_WARNING_TEXT}
          </span>
        )}
      </div>
      <div className="block">
        <h3 className="block-title">{FISCAL_AUTO_CLOSING_HELPER_TEXT}</h3>
        <RadioItem
          onClick={() =>
            onSelectRadioBox({
              name: "automatically",
              parentField: "fiscalClosingRadioboxes",
            })
          }
        >
          <input
            type="radio"
            name="automatically"
            checked={currentSettings.fiscalClosingRadioboxes.automatically}
            onChange={() => {}}
          />
          <label htmlFor="automatically" className="block-label">
            Щодня о
            <GlobalSelect
              data={{
                caption: null,
                placeholder: currentSettings.autoTime.hours,
                selectedCaption: currentSettings.autoTime.hours,
                disabled:
                  !currentSettings.fiscalClosingRadioboxes.automatically,
                onSelectItem: (item) =>
                  selectNewDate({
                    field: "hours",
                    parentField: "autoTime",
                    value: item.value,
                  }),
                listInfo: {
                  items: HOURS,
                  data_id: "id",
                  data_uk: "value",
                  data_caption: "value",
                },
                style: selectorStyle,
                svgStyle: svgStyle,
                listStyle: listStyle,
              }}
            />
            :
            <GlobalSelect
              data={{
                caption: null,
                placeholder: currentSettings.autoTime.minutes,
                selectedCaption: currentSettings.autoTime.minutes,
                disabled:
                  !currentSettings.fiscalClosingRadioboxes.automatically,
                onSelectItem: (item) =>
                  selectNewDate({
                    field: "minutes",
                    parentField: "autoTime",
                    value: item.value,
                  }),
                listInfo: {
                  items: MINUTES,
                  data_id: "id",
                  data_uk: "value",
                  data_caption: "value",
                },
                style: selectorStyle,
                svgStyle: svgStyle,
                listStyle: listStyle,
              }}
            />
          </label>
        </RadioItem>
        <RadioItem
          onClick={() =>
            onSelectRadioBox({
              name: "manually",
              parentField: "fiscalClosingRadioboxes",
            })
          }
        >
          <input
            type="radio"
            name="manually"
            checked={currentSettings.fiscalClosingRadioboxes.manually}
            onChange={() => {}}
          />
          <label htmlFor="manually">В обрані дні у вказаний час</label>
          <div className="checkbox-item"></div>
        </RadioItem>
        <div className="checkbox-group">
          {WEEK_DAYS.map((el, i) => (
            <div className="block-checkbox-item" key={el.id}>
              <input
                type="checkbox"
                disabled={!currentSettings.fiscalClosingRadioboxes.manually}
                checked={currentSettings[el.field].checked}
                onChange={() =>
                  onSelectCheckbox({
                    name: "checked",
                    parentField: el.field,
                    value: !currentSettings[el.field].checked,
                  })
                }
              />
              <label>
                <span className="week-day-label">{el.label} -</span>
                <GlobalSelect
                  data={{
                    caption: null,
                    placeholder: currentSettings[el.field].hours,
                    selectedCaption: currentSettings[el.field].hours,
                    disabled:
                      !currentSettings.fiscalClosingRadioboxes.manually ||
                      !currentSettings[el.field].checked,
                    onSelectItem: (item) =>
                      selectNewDate({
                        field: "hours",
                        parentField: el.field,
                        value: item.value,
                      }),
                    listInfo: {
                      items: HOURS,
                      data_id: "id",
                      data_uk: "value",
                      data_caption: "value",
                    },
                    style: selectorStyle,
                    svgStyle: svgStyle,
                    listStyle: listStyle,
                    maxHeight: mobileVersion ? undefined : getListHeight(i),
                  }}
                />
                :
                <GlobalSelect
                  data={{
                    caption: null,
                    placeholder: currentSettings[el.field].minutes,
                    selectedCaption: currentSettings[el.field].minutes,
                    disabled:
                      !currentSettings.fiscalClosingRadioboxes.manually ||
                      !currentSettings[el.field].checked,
                    onSelectItem: (item) =>
                      selectNewDate({
                        field: "minutes",
                        parentField: el.field,
                        value: item.value,
                      }),
                    listInfo: {
                      items: MINUTES,
                      data_id: "id",
                      data_uk: "value",
                      data_caption: "value",
                    },
                    style: selectorStyle,
                    svgStyle: svgStyle,
                    listStyle: listStyle,
                    maxHeight: mobileVersion ? undefined : getListHeight(i),
                  }}
                />
              </label>
              {currentSettings.fiscalClosingRadioboxes.manually &&
                currentSettings[el.field].checked && (
                  <button
                    className="copy-date"
                    data-tip
                    data-for={`copy-date-tooltip`}
                    onClick={() =>
                      onDuplicateDateToAll({
                        hours: currentSettings[el.field].hours,
                        minutes: currentSettings[el.field].minutes,
                      })
                    }
                  >
                    <CopyIcon />
                  </button>
                )}
            </div>
          ))}
        </div>
        <h3 className="block-title">Послідовність дій у вказаний час</h3>
        <div className="block-radio-item">
          <Switch
            onChange={() =>
              onTogglerTrigger({
                name: "autoBdayClose",
                value: !currentSettings.autoBdayClose,
              })
            }
            checked={currentSettings.autoBdayClose}
            height={25}
            width={40}
            handleDiameter={17}
            uncheckedIcon={false}
            checkedIcon={false}
            offHandleColor="#fff"
            onHandleColor="#fff"
            offColor="#c5c5c5"
            onColor="#28ccc6"
            className={"switch"}
          />
          <span>Виконати закриття банківського бізнес дня.</span>
        </div>
        <div className="block-radio-item">
          <Switch
            onChange={() =>
              onTogglerTrigger({
                name: "autoInkass",
                value: !currentSettings.autoInkass,
              })
            }
            checked={currentSettings.autoInkass}
            height={25}
            width={40}
            handleDiameter={17}
            uncheckedIcon={false}
            checkedIcon={false}
            offHandleColor="#fff"
            onHandleColor="#fff"
            offColor="#c5c5c5"
            onColor="#28ccc6"
            className={"switch"}
            // disabled={fiscalizationDisabled}
          />
          <span>Виконати операцію "СЛУЖБОВА ВИДАЧА" на повну суму каси.</span>
        </div>
        <div className="block-radio-item">
          <Switch
            onChange={() =>
              onTogglerTrigger({
                name: "autoFdayClose",
                value: !currentSettings.autoFdayClose,
              })
            }
            checked={currentSettings.autoFdayClose}
            height={25}
            width={40}
            handleDiameter={17}
            uncheckedIcon={false}
            checkedIcon={false}
            offHandleColor="#fff"
            onHandleColor="#fff"
            offColor="#c5c5c5"
            onColor="#28ccc6"
            className={"switch"}
            disabled={rroDisabled || fiscalizationDisabled}
          />
          <span>Виконати закриття фіскальної зміни.</span>
        </div>
        {mobileVersion && (
          <span className="block-warning">
            {FISCAL_AUTO_CLOSING_ERROR_WARNING}
          </span>
        )}
      </div>
    </>
  );
};

export default FiscalDayContent;
