import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

// api
import { ZRecieptAPI } from "api/z-reciept";

// components
import Preloader from "components/additional/PreLoader/Preloader";

// styles
import { ReactComponent as RemoveIcon } from "../../ui/Alert/icons/remove.svg";
import { AButton } from "../../components/additional/Z-Reciept/z-reciept.styles";
import { ZRecieptPreviewContainer } from "./z-receipt.styles";

const ZReciept = () => {
  const locationSearch = useLocation().search;
  const search = useMemo(
    () => new URLSearchParams(locationSearch),
    [locationSearch]
  );
  const serverAPI = useMemo(() => new ZRecieptAPI(), []);
  const type = search.get("type");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receiptImg, setReceiptImg] = useState(null);

  useEffect(() => {
    localStorage.setItem("hasMoreAttempts", true);

    const setUpFunc = async () => {
      const request =
        type === "fiscal"
          ? serverAPI.fiscalZReciept({
              rro_id: Number(search.get("rro_id")),
              dfs_log_id: Number(search.get("dfs_log_id")),
            })
          : type === "bank"
          ? serverAPI.bankZReciept({
              bdd: search.get("bdd"),
              batch: Number(search.get("batch")),
              terminalId: Number(search.get("terminalId")),
            })
          : type === "cash"
          ? serverAPI.cashZReciept({
              bdd: search.get("bdd"),
              batch: Number(search.get("batch")),
              terminalId: Number(search.get("terminalId")),
            })
          : null;

      setLoading(true);
      try {
        const result = await request;

        const hasMoreAttempts = localStorage.getItem("hasMoreAttempts");

        if (result.data === "" && hasMoreAttempts) {
          localStorage.removeItem("hasMoreAttempts");
          return setUpFunc();
        }
        setReceiptImg(result.data);
      } catch (error) {
        console.log(error);
        setError(true);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };

    setUpFunc();

    return () => {
      localStorage.removeItem("hasMoreAttempts");
    };
  }, [search, type, serverAPI]);

  if (error) {
    return (
      <ZRecieptPreviewContainer>
        <div className="error-container">
          <RemoveIcon />
          <span>Виникла помилка завантаження чека... Спробуйте ще раз.</span>
        </div>
      </ZRecieptPreviewContainer>
    );
  }

  if (loading) {
    return (
      <ZRecieptPreviewContainer>
        <div className="loading-container">
          <Preloader />
          <span>Триває завантаження чека. Зачекайте.</span>
        </div>
      </ZRecieptPreviewContainer>
    );
  }

  return (
    <ZRecieptPreviewContainer>
      <img src={`data:image/png;base64,${receiptImg}`} alt="" />
      <AButton
        download={"Z-звіт.png"}
        href={`data:image/png;base64,${receiptImg}`}
      >
        Завантажити звіт
      </AButton>
    </ZRecieptPreviewContainer>
  );
};

export default ZReciept;
