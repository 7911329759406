import React from "react";

import {
  SORT_TYPE,
  SEARCH_TYPE,
  DATE_TYPE,
  DEFAULT_TYPE,
  SELECTOR_TYPE,
  UNIT_SELECTOR_TYPE,
  CATS_SELECTOR_TYPE,
  MULTI_SELECTOR_TYPE,
} from "utils/constants/table.headers.constants";
import TableHeaderSearch from "./Types/Table.header.search";
import TableHeaderSort from "./Types/Table.header.sort";
import SearchIcon from "assets/icons/search_small.svg";
import DateIcon from "assets/icons/calendar_header.svg";
import SortIcon from "assets/icons/sort_line.svg";
import TableHeaderDate from "./Types/Table.header.date";
import TableHeaderSelector from "./Types/Table.header.selector";
import TableHaderMultiSelector from "./Types/Table.header.multi_selector";
import TableHeaderCatsSelector from "./Types/catsSelector";
import TableHeaderUnitSelector from "./Types/unitSelector";

export default function TableHeaders({
  type,
  text,
  state,
  fn,
  sort,
  fn_reset,
  choose_state,
  range = true,
  formatFunction = null,
}) {
  switch (type) {
    case SORT_TYPE:
      return <TableHeaderSort title={text} fn={fn} sort={sort} />;
    case SEARCH_TYPE:
      return (
        <TableHeaderSearch
          fn_reset={fn_reset}
          title={text}
          fn={fn}
          state={state}
          icon={SearchIcon}
          choose_state={choose_state}
          formatFunction={formatFunction}
        />
      );
    case DATE_TYPE:
      return (
        <TableHeaderDate
          fn_reset={fn_reset}
          title={text}
          fn={fn}
          state={state}
          icon={DateIcon}
          choose_state={choose_state}
          range={range}
        />
      );
    case SELECTOR_TYPE:
      return (
        <TableHeaderSelector
          fn_reset={fn_reset}
          title={text}
          fn={fn}
          state={state}
          icon={SortIcon}
          choose_state={choose_state}
        />
      );
    case UNIT_SELECTOR_TYPE:
      return (
        <TableHeaderUnitSelector
          fn_reset={fn_reset}
          title={text}
          fn={fn}
          state={state}
          icon={SortIcon}
          choose_state={choose_state}
        />
      );
    case CATS_SELECTOR_TYPE:
      return (
        <TableHeaderCatsSelector
          fn_reset={fn_reset}
          title={text}
          fn={fn}
          state={state}
          icon={SortIcon}
          choose_state={choose_state}
        />
      );
    case MULTI_SELECTOR_TYPE:
      return (
        <TableHaderMultiSelector
          fn_reset={fn_reset}
          title={text}
          fn={fn}
          state={state}
          icon={SortIcon}
          choose_state={choose_state}
        />
      );
    case DEFAULT_TYPE:
      return (
        <TableHeaderSearch
          fn_reset={fn_reset}
          title={text}
          fn={fn}
          state={state}
          icon={null}
        />
      );

    default:
      return "this type table headers not exist!";
  }
}
