import axios from "axios";
import moment from "moment";
import JSON_BIGINT from "json-bigint";

import { URL_SERVER } from "utils/constants/server.constants";
import { LocalStorage } from "services";
import Fetch from "services/Fetch/Fetch.service";
import download from "utils/helpers/download.helpers";
import store from "store";
import { setGlobalLoading } from "store/main_table/main_table.actions";
import { dropAlertState, setAlertState } from "store/alert/alert_actions";
import { UI_DATE_FORMAT, API_ISO_DATE_TIME_FORMAT } from "utils/constants/time";

export class FiskalTable {
  constructor() {
    this.fetch = new Fetch();
    this.localStorage = new LocalStorage();
  }

  generateHeader() {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    return headers;
  }

  turnOn() {
    const body = {};
    const headers = this.generateHeader();

    return this.fetch.post(`${URL_SERVER}/api/Options/Fiskal/1`, body, headers);
  }

  turnOff() {
    const body = {};
    const headers = this.generateHeader();

    return this.fetch.post(`${URL_SERVER}/api/Options/Fiskal/0`, body, headers);
  }

  getFiscalDays(args = {}) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const { merchant_id } = JSON.parse(this.localStorage.get("profile"));

    let { startDate, endDate } = args;
    let dateFrom, dateTill;

    if (startDate)
      dateFrom = moment(startDate, UI_DATE_FORMAT).format(
        API_ISO_DATE_TIME_FORMAT
      );
    else dateFrom = moment().add(-30, "days").format(API_ISO_DATE_TIME_FORMAT);
    if (endDate)
      dateTill = moment(endDate, UI_DATE_FORMAT).format(
        API_ISO_DATE_TIME_FORMAT
      );
    else dateTill = moment().format(API_ISO_DATE_TIME_FORMAT);

    const url = `${URL_SERVER}/api/Dfs/GetFiskalDays/${merchant_id}/${dateFrom}/${dateTill}`;

    return axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
      transformResponse: [
        (data) => {
          const result = JSON_BIGINT.parse(data);

          result.fiskalDays = result.fiskalDays.map((item) => ({
            ...item,
            id: item.id.toString(),
          }));

          return result;
        },
      ],
    });
  }

  getFiscalsDayDetail(shiftClosedDate) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const { merchant_id } = JSON.parse(this.localStorage.get("profile"));
    const url = shiftClosedDate
      ? `/api/Dfs/FiskalDayDetails/${merchant_id}/${shiftClosedDate}`
      : `/api/Dfs/FiskalDayDetails/${merchant_id}`;

    return axios.get(`${URL_SERVER}${url}`, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
      transformResponse: [
        (data) => {
          const result = JSON_BIGINT.parse(data);

          result.fiskalDetails = result.fiskalDetails.map((item) => ({
            ...item,
            id: item.id.toString(),
          }));

          return result;
        },
      ],
    });
  }

  async closedDay({ rro }) {
    const defaultClosedDayError = "Помилка при спробі закрити фіскальну зміну";
    store.dispatch(setGlobalLoading(true));

    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const profile = JSON.parse(this.localStorage.get("profile"));
    const client_id = !!profile ? profile.client_id : null;

    try {
      const res = await axios.post(
        `${URL_SERVER}/api/Dfs/CloseShift/${rro}/${client_id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Token: token,
            DeviceId: "WEB_" + device,
          },
        }
      );
      store.dispatch(setGlobalLoading(false));
      if (!res || !res.data || Number(res.data.error_code) !== 0) {
        store.dispatch(
          setAlertState({
            show: true,
            title: "Виникла помилка!",
            subtitle: res?.data?.message || defaultClosedDayError,
            type: "block",
            fnSubmit: () => {},
            fnClose: () => store.dispatch(dropAlertState()),
          })
        );
        return null;
      }
      return res;
    } catch (error) {
      console.log(error);
      store.dispatch(setGlobalLoading(false));
      store.dispatch(
        setAlertState({
          show: true,
          title: "Виникла помилка!",
          subtitle: defaultClosedDayError,
          type: "block",
          fnSubmit: () => {},
          fnClose: () => store.dispatch(dropAlertState()),
        })
      );
    }
  }

  async downloadExcel({
    dfs_shift_id,
    dateFrom,
    dateTo,
    status,
    search_rrn,
    search_amount,
    search_method_payment,
    search_terminal,
    search_cashier,
    extended_rep,
  }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = {
      Token: token,
      DeviceId: "WEB_" + device,
      responseType: "blob",
    };

    const body = {
      dfs_shift_id,
    };

    if (dateFrom) body.date_from = dateFrom;
    if (dateTo) body.date_to = dateTo;
    if (status) body.status = status;
    if (search_amount) body.search_amount = search_amount;
    if (search_rrn) body.rrn_search = search_rrn;
    if (search_method_payment)
      body.search_method_payment = search_method_payment;
    if (search_terminal) body.terminal_search = search_terminal;
    if (search_cashier) body.search_cashier = search_cashier;
    body.extended_rep = extended_rep;

    return this.fetch
      .postBlobWithAxios(
        `${URL_SERVER}/api/Reports/TransactionExport/ExportExcel`,
        body,
        headers
      )
      .then((result) => {
        if (!result) return;

        const { fileData, fileName } = result;

        download(
          fileData,
          fileName,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      });
  }

  async downloadPdf({
    dfs_shift_id,
    dateFrom,
    dateTo,
    status,
    search_rrn,
    search_amount,
    search_method_payment,
    search_terminal,
    search_cashier,
    extended_rep,
  }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = {
      Token: token,
      DeviceId: "WEB_" + device,
    };

    const body = {
      dfs_shift_id,
    };

    if (dateFrom) body.date_from = dateFrom;
    if (dateTo) body.date_to = dateTo;
    if (status) body.status = status;
    if (search_amount) body.search_amount = search_amount;
    if (search_rrn) body.rrn_search = search_rrn;
    if (search_method_payment)
      body.search_method_payment = search_method_payment;
    if (search_terminal) body.terminal_search = search_terminal;
    if (search_cashier) body.search_cashier = search_cashier;
    body.extended_rep = extended_rep;

    return this.fetch
      .postBlobWithAxios(
        `${URL_SERVER}/api/Reports/TransactionExport/ExportPDF`,
        body,
        headers
      )
      .then((result) => {
        if (!result) return;

        const { fileData, fileName } = result;

        download(fileData, fileName, "application/pdf");
      });
  }

  getNotFiscalTransactions({ rroId, clientId }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    return axios.get(
      `${URL_SERVER}/api/Dfs/ReviseZreport/${rroId}/${clientId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Token: token,
          DeviceId: "WEB_" + device,
        },
      }
    );
  }

  checkAtFiscalService(rroId) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    return axios.get(`${URL_SERVER}/api/Dfs/ReviseBeforeClose/${rroId}`, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });
  }
}
