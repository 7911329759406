import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import JSZip from "jszip";
import moment from "moment";
import { b64ToPng } from "utils/helpers/base64";
import { dropAlertState, setAlertState } from "store/alert/alert_actions";
import { ZRecieptAPI } from "api/z-reciept";

const useDownloadClosedZReports = () => {
  const dispatch = useDispatch();
  const [businessDays, setBusinessDays] = useState([]);
  const [makingAllClosedZReports, setMakingAllClosedZReports] = useState(false);
  const serverAPI = useMemo(() => new ZRecieptAPI(), []);

  const errorHandler = useCallback(
    (iconType, message, height) => {
      const state = {
        show: true,
        type: "block",
        title: "Увага!",
        subtitle: message,
        fnClose: () => dispatch(dropAlertState()),
        iconType,
      };

      if (height) {
        state.height = height;
      }

      dispatch(setAlertState(state));
    },
    [dispatch]
  );

  const downloadAllZReports = useCallback(() => {
    setMakingAllClosedZReports(true);

    Promise.allSettled(
      businessDays.map(({ bdd, batch, ptname }) =>
        serverAPI.bankZReciept({
          bdd,
          batch,
          terminalId: ptname,
        })
      )
    )
      .then((receipts) => {
        const files = receipts.map(({ status, value: { data } }, i) => {
          if (status !== "fulfilled") {
            return null;
          }

          const { ptname, sett_date_time } = businessDays[i];
          const filename = `${ptname}_${moment(
            sett_date_time,
            "DD/MM/YYYY HH:mm:ss"
          ).format("DD-MM-YYYY_HH-mm-ss")}_z_БД.png`;

          return { filename, content: b64ToPng(filename, data) };
        });

        const succeededFiles = files.filter((file) => file);
        const zip = new JSZip();

        succeededFiles.forEach(({ filename, content }) =>
          zip.file(filename, content)
        );

        zip
          .generateAsync({ type: "blob" })
          .then(function (content) {
            const link = document.createElement("a");

            link.href = URL.createObjectURL(content);
            link.download = `${moment().format(
              "DD-MM-YYYY_HH-mm-ss"
            )}_z_БД.zip`;
            link.click();
            link.remove();

            setMakingAllClosedZReports(false);
          })
          .then(() => {
            if (succeededFiles.length !== businessDays.length) {
              errorHandler(
                "remove",
                "Не всі Z-звіти банківських бізнес-днів сформовано успішно, перевірте вміст архіву"
              );
            }
          })
          .catch(({ message }) => {
            setMakingAllClosedZReports(false);
            errorHandler(
              null,
              `Архів не сформовано${message ? `: ${message}` : "."}`
            );
          });
      })
      .catch(({ message }) => {
        setMakingAllClosedZReports(false);
        errorHandler(
          null,
          `Отримання Z-звітів банківських бізнес-днів завершилося помилкою, спробуйте завантажити окремо по кожному банківському бізнес-дню. (${message})`,
          "500px"
        );
      });
  }, [businessDays, serverAPI, errorHandler]);

  const askForDownloadingAllZReports = useCallback(() => {
    dispatch(
      setAlertState({
        show: true,
        type: "remove",
        title: "Увага!",
        subtitle:
          "Процес експорту звітів у zip-архів може тривати певний час. Після створення архіву його буде запропоновано скачати.",
        fnClose: () => dispatch(dropAlertState()),
        fnSubmit: () => {
          dispatch(dropAlertState());
          downloadAllZReports();
        },
        acceptButtonLabel: "Експортувати",
        cancelButtonLabel: "Скасувати",
      })
    );
  }, [dispatch, downloadAllZReports]);

  return {
    businessDays,
    setBusinessDays,
    makingAllClosedZReports,
    downloadAllZReports: askForDownloadingAllZReports,
  };
};

export default useDownloadClosedZReports;
