import React from "react";

import FileLink from "components/forms/FileLink/FileLink";
import {
  FiscalOnContainer,
  FiscalOnHeader,
  FiscalOnInfo,
  FiscalOnSteps,
  FiscalOnStep,
  MoreInfoOnFiscalOn,
} from "./styles";

const FiscalOnSubtitle = () => {
  return (
    <FiscalOnContainer>
      <FiscalOnHeader>Завершіть налаштування фіскалізації:</FiscalOnHeader>
      <FiscalOnInfo>
        <FiscalOnSteps>
          <FiscalOnStep>
            Для кожного платіжного терміналу, на якому необхідно виконувати
            фіскальні операції, пропишіть ФН ПРРО.
          </FiscalOnStep>
          <FiscalOnStep>
            Для кожного касира, який буде проводити фіскальні операції,
            завантажте КЕП.
          </FiscalOnStep>
        </FiscalOnSteps>
        <MoreInfoOnFiscalOn>
          Більше інформації в{" "}
          <FileLink
            href=" https://online.oschadpay.ua/m/instrNewOschadPAY.pdf"
            target="_blank"
            caption={"Інструкції по ОщадPAY"}
          />{" "}
          розділ "Налаштування фіскалізації в ОщадPAY".
        </MoreInfoOnFiscalOn>
      </FiscalOnInfo>
    </FiscalOnContainer>
  );
};

export default FiscalOnSubtitle;
