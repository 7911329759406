import React from 'react';

import { ErrorComponentContainer } from './styles';

const ErrorComponent = (props) => {
  const { title } = props;

  return (
    <ErrorComponentContainer>
      <span>{title}</span>
    </ErrorComponentContainer>
  );
};

export default ErrorComponent;
