import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Alert from "ui/Alert/Alert";

import {
  Logo,
  List,
  Nav,
  TopMenu,
  BottomMenu,
  LogoContainer,
  LogoAndVersion,
  Circle,
  NavContainer,
} from "./Navigation.styles";

import {
  HomeIcon,
  TransactionIcon,
  BankIcon,
  FiskalIcon,
  TerminalIcon,
  CashierIcon,
  BackIcon,
  GoodsIcon,
  MoneyIcon,
  NewsIcon,
  StatsIcon,
} from "./Navigation.icons";

import {
  DASHBOARD_ROUTE,
  TRANSACTION_ROUTE,
  BANK_DAY_ROUTE,
  FISCAL_DAY_ROUTE,
  TERMINALS_ROUTE,
  CASHIERS_ROUTE,
  SETTING_FOP_ROUTE,
  SUPPORT_ROUTE,
  QUESTION_ROUTE,
  GOODS_ROUTE,
  CASH_DAY_ROUTE,
  NEWS_ROUTE,
  STATS_ROUTE,
} from "utils/constants/routes.constants";
import { VERSION_TEXT } from "utils/constants/server.constants";
import VersionBage from "ui/VersionBage/VersionBage";
import { fetchServer as getMessages } from "store/news_table/news_table.actions";
import { PAGE_NAME } from "../../../utils/constants/ui";

import logo from "assets/images/logo.png";

export default function Navigation() {
  const dispatch = useDispatch();
  const { opened, ...alert } = useSelector((state) => state.alert);
  const { state: messages } = useSelector((state) => state.news_table);
  const [isMinimize, setIsMinimize] = useState(false);
  const [menu, setMenu] = useState("");

  useEffect(() => {
    dispatch(getMessages({ checkNewItems: false }));
  }, [dispatch]);

  function minimizeMenu() {
    setIsMinimize(!isMinimize);
    if (isMinimize) {
      setMenu("animation-text-show");
    } else {
      setMenu("animation-text");
      setTimeout(() => {
        setMenu((val) => val + " min-menu");
      }, 400);
    }
  }

  const newMessages = messages.filter((item) => !item.message_read);

  return (
    <NavContainer>
      {opened && <Alert {...alert} />}
      <Nav className={menu}>
        <TopMenu>
          <LogoContainer isMinimize={isMinimize}>
            <LogoAndVersion>
              <Logo onClick={minimizeMenu} src={logo} alt="logo" />
              {!isMinimize && VERSION_TEXT() && <VersionBage />}
            </LogoAndVersion>
            <span onClick={minimizeMenu} className="icon-container">
              <BackIcon />
            </span>
          </LogoContainer>

          <List>
            <NavLink
              exact
              activeClassName="active"
              className="link"
              to={DASHBOARD_ROUTE}
            >
              <HomeIcon className="icon" /> <span> Головна</span>
            </NavLink>
            <NavLink className="link" to={STATS_ROUTE}>
              <StatsIcon className="icon" />
              <span> {PAGE_NAME.statistics}</span>
            </NavLink>
            <NavLink className="link" to={TRANSACTION_ROUTE}>
              <TransactionIcon className="icon" />{" "}
              <span> Журнал транзакцій</span>
            </NavLink>
            <NavLink className="link" to={BANK_DAY_ROUTE}>
              <BankIcon className="icon" /> <span> Банківські бізнес-дні</span>
            </NavLink>
            <NavLink className="link" to={FISCAL_DAY_ROUTE}>
              <FiskalIcon className="icon" /> <span> Фіскальні зміни</span>
            </NavLink>
            <NavLink className="link" to={CASH_DAY_ROUTE}>
              <MoneyIcon className="icon" /> <span> Готівка в касах</span>
            </NavLink>
            <NavLink className="link" to={TERMINALS_ROUTE}>
              <TerminalIcon className="icon" />{" "}
              <span> Управління терміналами</span>
            </NavLink>
            <NavLink className="link" to={CASHIERS_ROUTE}>
              <CashierIcon className="icon" /> <span> Управління касирами</span>
            </NavLink>
            <NavLink className="link" to={GOODS_ROUTE}>
              <GoodsIcon className="icon" /> <span> Управління товарами</span>
            </NavLink>
            <NavLink className="link" to={NEWS_ROUTE}>
              <figure className="news">
                <NewsIcon className="icon news-icon" />
                {newMessages.length > 0 && (
                  <figcaption className="news-count">
                    {newMessages.length}
                  </figcaption>
                )}
              </figure>
              <span> Повідомлення</span>
            </NavLink>
          </List>
        </TopMenu>
        <BottomMenu>
          <List>
            <NavLink className="link link-small" to={SETTING_FOP_ROUTE}>
              <Circle show={isMinimize} /> <span>Налаштування Торговця</span>
            </NavLink>
            <NavLink className="link link-small" to={SUPPORT_ROUTE}>
              <Circle show={isMinimize} /> <span>Зв’язатися з підтримкою</span>
            </NavLink>
            <NavLink className="link link-small" to={QUESTION_ROUTE}>
              <Circle show={isMinimize} /> <span>Питання та відповіді </span>
            </NavLink>
          </List>
        </BottomMenu>
      </Nav>
    </NavContainer>
  );
}
