import styled from "styled-components";

import { Button } from "components/forms/Button/Button.styles";
import { font_demi } from "assets/style/fonts.variables";
import { size } from "assets/style/global-variables";

export const KeyExpirationModalContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 100%;
  padding: 10px;
`;

export const Title = styled.h4`
  font-family: ${font_demi};
  font-size: 32px;
  letter-spacing: 0.5px;
  margin-bottom: 15px;

  @media screen and (max-width: ${size.mobileL}) {
    font-size: 22px;
    margin-bottom: 10px;
  }
`;

export const AcceptButtonContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const ContainedButton = styled(Button)`
  width: auto;
  padding: 12px 15px;
  font-size: 16px;
  background: #13877e;
  transition: background 0.2s ease, border-radius 0.2s ease;
  cursor: pointer;

  &:hover {
    background: #0c615a;
  }
`;
