import styled from "styled-components";

import { font_book, font_demi } from "assets/style/fonts.variables";
import {
  black,
  grey,
  grey_light,
  grey_light_search,
  grey_light_setting,
  mouse_pointer,
  white,
  red,
} from "assets/style/variables";
import { size } from "assets/style/global-variables";

export const TerminalDetailContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 375px;
`;

export const TerminalButtons = styled.div`
  display: flex;
  grid-gap: 12px;
  order: ${({ isMobile }) => (isMobile ? 0 : 3)};
  padding-bottom: 20px;
  padding-top: ${({ isMobile }) => (isMobile ? 0 : "24px")};

  button {
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
    font-family: ${font_demi};
    font-size: 20px;
  }

  @media screen and (min-width: ${size.laptop}) {
    padding-top: 20px;

    button {
      width: 328px;
      padding: 15px 0;
    }
  }

  @media screen and (min-width: ${size.laptopL}) {
    padding-top: 42px;
    padding-bottom: 0;
  }
`;

export const TerminalDetailHeader = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
`;

export const TerminalDetailHeaderContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 15px;
  max-width: 375px;

  @media screen and (min-width: 575px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* padding: 24px 0 0; */
  }
`;

export const TerminalDetailHeaderItem = styled.a`
  display: flex;
  text-decoration: none;
  font-family: ${font_book};
  font-weight: 400;
  color: ${black};
  grid-gap: 8px;
  align-items: baseline;
  ${mouse_pointer};

  &.button_bg-white {
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    background-color: ${white};
    white-space: nowrap;
  }
`;

export const MobileFlex = styled.div`
  display: flex;
  gap: 3rem;
  align-items: flex-start;

  &.terminal-id {
    flex-direction: column;
    gap: 1rem;

    .button_bg-white {
      margin-left: 4rem;
    }
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  margin-top: 10px;
  grid-gap: 16px;
  align-items: center;
  font-family: ${font_book};
  font-weight: 400;
  .text {
    &__title {
      font-size: 18px;
      color: ${black};
      max-width: 345px;
    }
    &__subtitle {
      color: ${grey};
      font-size: 14px;
    }
  }
`;

export const RadioItem = styled.div`
  display: flex;
  grid-gap: 12px;
  align-items: center;
  ${mouse_pointer}
  margin-bottom: 10px;
  input {
    ${mouse_pointer}
    width: 18px;
    height: 18px;
    filter: hue-rotate(318deg);
    flex-shrink: 0;
  }

  label {
    ${mouse_pointer}
    font-family: ${font_book};
    font-weight: 400;
    font-size: 16px;
  }

  label.bold {
    font-weight: 600;
    font-family: "Futura Demi", sans-serif;
    color: #444;
  }

  label.block-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const Select = styled.input`
  font-family: ${font_book};
  font-weight: 400;
  font-size: 20px;
  padding: 10px;
  background: white;
  border-radius: 12px;
  border: 1px solid ${(props) => (props.err ? red : "rgba(112,120,127,0.2)")};
  outline: none;
  width: 170px;
  position: relative;
  color: ${grey};

  ${(props) => (!props.disableAction ? `opacity: 0.5;` : `  ${mouse_pointer}`)}
`;

export const GlobalSelect = styled.div`
  font-family: ${font_book};
  font-weight: 400;
  font-size: 20px;
  padding: 10px;
  background: white;
  border-radius: 12px;
  border: 1px solid ${(props) => (props.err ? red : "rgba(112,120,127,0.2)")};
  outline: none;
  width: 170px;
  position: relative;
  color: ${grey};

  ${(props) => (!props.disableAction ? `opacity: 0.5;` : `  ${mouse_pointer}`)}
  ${(props) => (props.disabled ? `opacity: 1; color: #333;` : "")}
`;

export const SelectVal = styled.p`
  position: relative;
  .select-icon {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;

export const SelectPlaceholder = styled(SelectVal)`
  color: ${grey};
`;
export const SelectTitle = styled(SelectVal)`
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: transparent;
  color: ${(props) => (props.err ? red : grey)};
  font-size: 14px;
  font-family: "Futura Book", sans-serif;
  padding-left: 5px;
  padding-right: 10px;
`;

export const SelectContainer = styled.ul`
  ${(props) => (props.show ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 40px;
  width: 100%;
  left: 0;
  z-index: 2;
  overflow-y: scroll;
  max-height: ${(props) =>
    props.maxHeight ? `${props.maxHeight}px` : "180px"};
  flex-direction: column;
  background: ${white};
  padding-bottom: 10px;
  -webkit-box-shadow: 2px 2px 6px -1px rgba(28, 44, 56, 0.54);
  -moz-box-shadow: 2px 2px 6px -1px rgba(28, 44, 56, 0.54);
  box-shadow: 2px 2px 6px -1px rgba(28, 44, 56, 0.54);

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
`;

export const SelectItem = styled.li`
  list-style-type: none;
  padding: 10px;

  ${mouse_pointer}
  &:hover {
    background: ${grey_light_search};
  }
`;

export const ReceiptContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.75rem 1.5rem;
  margin-bottom: 0.25rem;

  input,
  textarea {
    border-color: ${(props) => (props.disabled ? "#f6f7fa" : "none")};
    background-color: ${(props) =>
      props.disabled ? "#f6f7fa !important" : "#fff"};
  }

  /*TAP-239 to be removed*/
  textarea {
    margin-bottom: 0 !important;
  }

  .receipt-footer {
    font-family: ${font_book};
    font-size: 16px !important;
    font-weight: 400;
    margin-bottom: 0 !important;
  }

  @media screen and (min-width: ${size.laptop}) {
    justify-content: flex-start;
  }
`;

export const ReceiptLabel = styled.span`
  color: #70787f;
  font-size: 14px;
  font-family: "Futura Book", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
`;

export const InfoLine = styled.p``;

export const InfoTerm = styled.span`
  font-weight: bold;
  color: #333;
`;

export const InfoDescription = styled.span`
  color: ${({ type }) => (type === "warning" ? red : "#333")};
`;

export const SettingsButton = styled.button`
  width: 256px;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  border-radius: 5px;
  border: none;
  background-color: #0f8178;
  color: white;
  font-family: ${font_book};
  transition: background-color 0.3s ease;

  &:disabled {
    background-color: #bababa;
    cursor: not-allowed;
  }

  svg {
    width: 20px;
    height: 20px;
    transition: transform 0.1s ease;
  }

  span {
    font-family: ${font_demi}, sans-serif;
    font-size: 16px;
  }

  &:not(:disabled):hover {
    background-color: #0d6b64;

    svg {
      transform: rotate(360deg);
    }
  }
`;

export const DaysInfo = styled.section`
  width: 100%;
  padding-top: 1.25rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  span:not(button span) {
    font-family: ${font_book};
  }
`;

export const CashRoundContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;

  &.extra-services {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.75rem;
    border-bottom: 1px solid ${grey_light};
    padding-bottom: 1rem;

    &.service-item {
      height: 3rem;
      margin-top: 25px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
    }

    *.extra-service-item {
      max-width: 39rem;
    }

    *.extra-service-item:nth-child(3) {
      height: 3rem;
      margin-top: -0.7rem;
    }

    *.extra-service-item:last-child {
      height: 3rem;
      margin-top: 0.5rem;
    }

    .nowrap {
      white-space: nowrap;
    }

    .switch-title {
      display: flex;
      gap: 0.4rem;
      align-items: center;
    }
  }

  .terminal-payment-templates {
    justify-self: end;
  }

  span {
    font-family: "Futura Book", sans-serif;
    font-weight: 500;
    font-size: 18px;
  }
`;

export const SwitchContainerTextLine = styled.p`
  margin: 25px 0 10px;
  font-family: "Futura Book", sans-serif;
  font-size: 14px;
  color: ${grey};
`;

export const SwitchContainerItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 20px;

  span,
  a {
    font-family: "Futura Book", sans-serif;
    font-weight: 500;
    font-size: 18px;
  }

  &.cash {
    width: auto;
  }

  &.cash-round {
    column-gap: 0.5rem;

    span {
      white-space: nowrap;
    }
  }
`;

export const CommentTemplatesContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;

  .errorMessage {
    display: none;
  }

  & > *:first-child {
    flex-grow: 1;
    min-width: 12rem;
  }
`;

export const TemplatesButton = styled(SettingsButton)`
  align-self: center;
  width: auto;
  height: 40px;

  span {
    font-size: 13px;
    font-weight: 600;
  }
`;

export const QRSettingsContainer = styled.div``;

export const InfoIconButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
`;

export const switchGridStyle = {
  display: "grid",
  gridTemplateColumns: "auto 14rem 1fr",
  gap: "1rem",
  width: "100%",
};

export const infoIconStyle = {
  display: "inline-block",
  minWidth: "1.5rem",
  maxWidth: "1.5rem",
  cursor: "pointer",
};

export const linkStyle = { color: "#179c92" };

export const TerminalSettingPanel = styled.section`
  background: ${grey_light_setting};
  border-radius: 22px;
  border: 1px solid ${grey_light_search};
  padding-bottom: 1rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  overflow: auto;

  .required-paragraph {
    margin: 20px;
    margin-top: 5px;
    color: #444;
    font-family: "Futura Book", sans-serif;
    font-size: 18px;
  }

  .template-name {
    align-self: flex-start;
    padding-top: 1rem;
    text-align: left;
  }

  .terminal-setting-container {
    width: 100%;
    min-width: fit-content;

    &:last-child {
      padding-top: 0;
    }
  }

  @media screen and (min-width: ${size.laptopL}) {
    padding-top: 1rem;
    flex-wrap: nowrap;

    .terminal-setting-container {
      flex: 1 1 50%;
      width: auto;
      max-width: 50%;

      &:first-child {
        border-right: 1px solid ${grey_light};
      }

      .extra-services {
        border-bottom: none;
      }
    }

    .terminal-setting-container:last-child {
      min-width: auto;
    }
  }
`;
