import styled from "styled-components";

import {
  Button,
  ButtonSecondaryBorder,
} from "components/forms/Button/Button.styles";
import { font_book, font_demi } from "assets/style/fonts.variables";
import { size } from "assets/style/global-variables";

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.55);
  opacity: 0.7;
  z-index: 100001;
  transition: all ease;
  overflow: hidden;
  margin-right: -15px;
`;

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 100002;
  background-color: #fff;
  width: 400px;
  max-width: 95vw;
  border-radius: 5px;
  padding: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;

  &.open-fiscal-mode {
    width: 600px;

    @media screen and (max-width: ${size.tablet}) {
      width: 400px;
    }

    @media screen and (max-width: ${size.mobileL}) {
      width: 310px;
    }
  }

  &.autoclosing-fiscal-day {
    width: 600px;
    min-height: 400px;
    padding: 0;

    @media screen and (max-width: ${size.tablet}) {
      width: 400px;
      padding: 10px 0;
    }

    @media screen and (max-width: ${size.mobileL}) {
      width: 310px;
    }
  }

  &.turn-off-terminal {
    width: 700px;
    padding: 0;

    @media screen and (max-width: ${size.tablet}) {
      width: 400px;
      padding: 10px 0;
    }

    @media screen and (max-width: ${size.mobileL}) {
      width: 310px;
    }
  }

  &.disabled-fiscalization,
  &.key-expiration {
    width: 600px;
    padding: 0;

    @media screen and (max-width: ${size.tablet}) {
      width: 400px;
      padding: 10px 0;
    }

    @media screen and (max-width: ${size.mobileL}) {
      width: 310px;
    }
  }

  &.key-expiration {
    textarea:focus {
      box-shadow: 0 0 7px #dee0e0;
    }
  }

  &.templates-editor-container {
    width: 418px;
  }

  &.tag-editor-container {
    width: 600px;
    box-shadow: 0 0 10px grey;
  }

  &.terminal-picker {
    max-height: 95vh;
    width: 600px;
    padding: 0.75rem 1rem 1.5rem;
    display: flex;
    flex-direction: column;

    & > *:nth-child(2) {
      flex: 1;
      overflow-y: auto;

      table {
        margin-top: 0;
        min-width: unset;
        overflow-y: auto;

        thead {
          position: sticky;
          top: 0;

          tr {
            margin-top: 0;
            margin-bottom: 0;
            background-color: #fff;
          }
        }
      }
    }
  }
`;

export const ModalTitle = styled.h4`
  font-family: ${font_demi};
  font-size: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 15px;

  @media screen and (max-width: ${size.mobileL}) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

export const ModalSubTitle = styled.div`
  font-family: ${font_book};
  font-size: 18px;
  margin-bottom: 10px;
  padding: 0 10px 10px;

  p {
    margin-top: 1rem;
  }

  .warning {
    display: block;
  }

  .subject-key-identifier {
    display: inline-block;
    width: 120px;
  }
`;

export const ModalBody = styled.div`
  width: 100%;
  margin: 5px 0;
`;

export const ModalButtons = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 10px;
`;

export const CancelButton = styled.button`
  position: absolute;
  top: ${(props) => props.top || 0};
  right: ${(props) => props.right || 0};
  width: auto;
  padding: 0;
  border: none;
  outline: none;
  font-size: 2rem;
  font-weight: bold;
  background: transparent;
  transition: text-shadow 0.1s;
  cursor: pointer;

  &:hover {
    text-shadow: 0 0 1px grey;
  }
`;

export const ModalContainedButton = styled(Button)`
  width: auto;
  padding: 12px 15px;
  font-size: 16px;
  background: #13877e;
  transition: background 0.2s ease, border-radius 0.2s ease;
  cursor: pointer;

  &:hover {
    background: #0c615a;
  }
`;

export const ModalOutlinedButton = styled(ButtonSecondaryBorder)`
  width: auto;
  padding: 12px 15px;
  font-size: 16px;
  background: transparent;
  transition: background 0.2s ease, border-radius 0.2s ease;
  cursor: pointer;

  &:hover {
    background: #eee;
  }
`;
