import React, { useState, useEffect, useRef } from "react";

// functions
import { getUUID } from "utils/helpers/getUUID";

// assets
import { ReactComponent as CloseIcon } from "assets/icons/close_white.svg";

// styles
import {
  Th,
  TableIcon,
  TableInput,
  TableResult,
  MultiSelectorLi,
  TableForm,
  TableFormResult,
  TableIconClose,
} from "../../Table.styles";

const PADDING_FROM_WINDOW_BORDER = 30;

const TableHeaderMultiSelector = (props) => {
  const { title, state, fn, fn_reset, icon, choose_state = null } = props;
  const mainRef = useRef(getUUID());
  const listRef = useRef(getUUID());

  const [val, setVal] = useState(
    choose_state && choose_state.length !== 0 ? choose_state.join(", ") : ""
  );
  const [active, setActive] = useState(
    choose_state && choose_state.length !== 0
  );
  const [activeResult, setActiveResult] = useState(false);
  const [temporaryResult, setTemporaryResult] = useState(choose_state || []);

  // sizes
  const [maxHeight, setMaxHeight] = useState(0);
  const [maxWidth, setMaxWidth] = useState(0);

  function search() {
    setActiveResult(true);
  }

  const chooseTemporaryItem = (value) => {
    const temporaryResultIncludesItem = temporaryResult.includes(value);

    if (temporaryResultIncludesItem)
      return setTemporaryResult(
        temporaryResult.filter((item) => item !== value)
      );

    const newTemporaryResult = [...temporaryResult];
    newTemporaryResult.push(value);
    return setTemporaryResult(newTemporaryResult);
  };

  const chooseItem = () => {
    const result = temporaryResult;

    if (result.length === 0) return;

    setActive(true);
    setVal(result.join(", "));
    fn(result);
  };

  function close(e) {
    e.stopPropagation();
    setActive(false);
    setTemporaryResult([]);
    setVal("");
    fn_reset();
  }

  useEffect(() => {
    if (choose_state === "" || choose_state === null) {
      setVal("");
      setActive(false);
    }
  }, [choose_state]);

  useEffect(() => {
    const onMouseOver = () => {
      setActiveResult(true);
    };

    const onMouseLeave = () => {
      setActiveResult(false);
      chooseItem();
    };

    if (listRef && listRef.current) {
      listRef.current.addEventListener("mouseover", onMouseOver);

      listRef.current.addEventListener("mouseleave", onMouseLeave);
    }

    return () => {
      if (listRef && listRef.current) {
        listRef.current.removeEventListener("mouseover", onMouseOver);

        listRef.current.removeEventListener("mouseleave", onMouseLeave);
      }
    };
  }, [listRef, temporaryResult]);

  useEffect(() => {
    if (activeResult && mainRef && mainRef.current) {
      const htmlPosition = mainRef.current.getBoundingClientRect();
      setMaxHeight(
        Math.floor(
          window.innerHeight -
            htmlPosition.top -
            htmlPosition.height -
            PADDING_FROM_WINDOW_BORDER
        )
      );
      setMaxWidth(
        Math.floor(
          window.innerWidth - htmlPosition.left - PADDING_FROM_WINDOW_BORDER
        )
      );
    }
  }, [activeResult]);

  return (
    <Th active={active} onClick={search} ref={mainRef}>
      <TableIcon>
        <img src={icon} alt="icon" />
      </TableIcon>
      <div>
        <TableInput
          padding={icon === null}
          placeholder={title}
          value={val}
          style={{ background: "white", cursor: "pointer" }}
          disabled={true}
        />
      </div>
      <TableForm active={active}>
        <TableFormResult
          style={{
            maxWidth: "calc(100% - 10px)",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {val}
        </TableFormResult>
        <TableIconClose onClick={close}>
          <CloseIcon />
        </TableIconClose>
      </TableForm>
      <TableResult
        active={activeResult}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        ref={listRef}
      >
        {state.map((item, key) => (
          <MultiSelectorLi
            style={{ padding: "5px 10px" }}
            key={key}
            data-val={item.toString()}
          >
            <input
              id={item.toString()}
              type="checkbox"
              onChange={() => chooseTemporaryItem(item)}
              checked={temporaryResult.includes(item)}
            />
            <label htmlFor={item.toString()}>{item}</label>
          </MultiSelectorLi>
        ))}
      </TableResult>
    </Th>
  );
};

export default TableHeaderMultiSelector;
