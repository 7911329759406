import { ChartToolbar as Toolbar, ChartLegend as Legend } from "../";
import {
  ChartHeader as Header,
  ChartTitle as Title,
  ChartTitleType as TitleType,
  ChartTitleDetails as TitleDetails,
  ChartSwitchContainer,
  CHART_SWITCH_LABEL_SMALL_STYLE,
} from "../../../../Statistics.styles.js";
import { ChartSwitch } from "pages/Panels/Statistics/components";
import useHeader from "./useHeader";
import {
  STAT_BY_QUARTERS,
  STAT_NAMES,
  STAT_TYPES,
} from "pages/Panels/Statistics/constants";
import { grey, grey_off } from "assets/style/variables";

const ChartHeader = ({ chartState, onCheckChartOption, isLoading }) => {
  const { year, years, setYear, quarterly, setQuarterly } =
    useHeader(chartState);

  return (
    <Header>
      <Title className="byTranTypes">
        <TitleType>{`${STAT_NAMES[STAT_TYPES.BY_TRAN_TYPE]}`}</TitleType>
        <TitleDetails>
          <span>за</span>
          <Toolbar {...{ year, setYear, years, isLoading }} />
          <span>рік</span>
          <ChartSwitchContainer>
            <ChartSwitch
              onChange={setQuarterly}
              checked={quarterly}
              offColor={grey_off}
              onColor={grey}
              label={STAT_BY_QUARTERS}
              labelStyle={CHART_SWITCH_LABEL_SMALL_STYLE}
              disabled={isLoading}
            />
          </ChartSwitchContainer>
        </TitleDetails>
      </Title>
      <Legend onCheckChartOption={onCheckChartOption} isLoading={isLoading} />
    </Header>
  );
};

export default ChartHeader;
