import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";

// components
import TableHeaders from "components/layouts/Table/Headers/Table.headers";
import Preloader from "components/additional/PreLoader/Preloader";
import Refresh from "ui/Refresh/Refresh";

// styles
import {
  TableContainer,
  Td,
  TerminalText,
  TerminalTitle,
  TerminalDescribe,
} from "components/layouts/Table/Table.styles";
import {
  TrMain,
  Terminal,
  TrTransactionDate,
  TransactionTerminalDescribe,
} from "./Transaction.styles";

// functions
import GenerateDay from "utils/helpers/generateDay.helpers";
import {
  setTransactionFilteringState,
  setBankFilteringState,
  setFiscalFilteringState,
} from "store/transaction_table/transaction_table.actions";
import {
  fetchServer,
  setPageIndex,
  setItem,
  setLocal,
  setServer,
} from "store/transaction_table/transaction_table.actions";
import {
  loadServer,
  loadLocal,
  stateLocal,
  loadFilter,
} from "store/transaction_table/transaction_table.slicer";
import { panelRef as reduxPanelRef } from "store/main_table/main_table.slicer";

// constants
import {
  DATE_TYPE,
  SEARCH_TYPE,
  SELECTOR_TYPE,
  MULTI_SELECTOR_TYPE,
} from "utils/constants/table.headers.constants";
import { TRANSACTION_ROUTE } from "utils/constants/routes.constants";
import { PAYMENT_TYPES } from "utils/constants/server.constants";
import {
  STATUS_VALUES,
  generateStatusValueMap,
  generateFiscalStatusValueMap,
  FISCAL_STATUS_VALUES,
  FISCAL_STATUS,
} from "./constants";
import {
  generateFiscalTransactionStatus,
  generateTransactionStatus,
  isQrTransaction,
  generateFiscalPaymentOperationLabel,
} from "./helpers";

import { ReactComponent as CardIcon } from "assets/icons/card_grey.svg";
import { ReactComponent as ReturnIcon } from "assets/icons/total_return.svg";
import { ReactComponent as AcceptIcon } from "assets/icons/transaction/accept.svg";
import { ReactComponent as CancelIcon } from "assets/icons/transaction/cancel.svg";
import { ReactComponent as RefundGreenIcon } from "assets/icons/transaction/refund.svg";
import { ReactComponent as EnterIcon } from "assets/icons/enter_gray_24.svg";
import { ReactComponent as ExitIcon } from "assets/icons/exit_gray_24.svg";
import { ReactComponent as CashIcon } from "assets/icons/cash_gray_24.svg";
import { ReactComponent as EnterGreenIcon } from "assets/icons/enter_green_24.svg";
import { ReactComponent as ExitGreenIcon } from "assets/icons/exit_green_24.svg";
import { ReactComponent as CardPaymentIcon } from "assets/icons/card_payment_gray_24.svg";
import { ReactComponent as ReversalIcon } from "assets/icons/ic_clock.svg";
import {
  ReversedIcon,
  UndeterminedIcon,
} from "components/layouts/SubTotal/SubTotal.icons";
import IconWithQR from "./components/IconWithQR";
import HourglassIcon from "./components/HourglassIcon";
import QRIcon from "components/ui/QRIcon.jsx";
import { grey } from "assets/style/variables";

const TransactionsTable = (props) => {
  const { businessDay, fiscalDay, onSelectTransaction } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const transactionFilteringState = useSelector(
    (state) => state.transaction_table.transactions_filtering_state
  );
  const bankFilteringState = useSelector(
    (state) => state.transaction_table.bank_filtering_state
  );
  const fiscalFilteringState = useSelector(
    (state) => state.transaction_table.fiscal_filtering_state
  );

  const isFilter = useSelector(loadFilter);
  const isServerLoaded = useSelector(loadServer);
  const isLoading = useSelector(loadLocal);
  const state = useSelector(stateLocal);
  const panelRef = useSelector(reduxPanelRef);
  const terminalValues = useSelector(
    (state) => state.main_table.search_transactions
  );
  const cashierValues = useSelector(
    (state) => state.cashier_table.search_cashier
  );

  const generateIcon = (transaction) => {
    let icon, status;

    if (fiscalDay) {
      status = generateFiscalTransactionStatus(transaction);
    } else {
      status = generateTransactionStatus(transaction);
    }

    const hasForeIcon = isQrTransaction(transaction);
    const withForeIcon = (icon, color) => (
      <IconWithQR icon={icon} color={color} />
    );

    switch (status) {
      case "B":
        icon = hasForeIcon ? (
          withForeIcon(ReversedIcon, "#EFE311")
        ) : (
          <ReversedIcon />
        );
        break;
      case "C":
        icon = hasForeIcon ? (
          withForeIcon(UndeterminedIcon, "#EDD328")
        ) : (
          <UndeterminedIcon />
        );
        break;
      case 7:
        icon = hasForeIcon ? (
          withForeIcon(ReversalIcon, "#111E29")
        ) : (
          <ReversalIcon />
        );
        break;
      case 6:
        icon = hasForeIcon ? (
          withForeIcon(ExitGreenIcon, "#38BC2D")
        ) : (
          <ExitGreenIcon />
        );
        break;
      case 5:
        icon = hasForeIcon ? (
          withForeIcon(EnterGreenIcon, "#38BC2D")
        ) : (
          <EnterGreenIcon />
        );
        break;
      case 4:
        icon = hasForeIcon ? (
          withForeIcon(RefundGreenIcon, "#38BC2D")
        ) : (
          <RefundGreenIcon />
        );
        break;
      case 3:
        icon = hasForeIcon ? (
          withForeIcon(ReturnIcon, "#FF7A00")
        ) : (
          <ReturnIcon />
        );
        break;
      case 2:
        icon = hasForeIcon ? (
          withForeIcon(CancelIcon, "#DD3C3C")
        ) : (
          <CancelIcon />
        );
        break;
      case 1:
        icon = hasForeIcon ? (
          withForeIcon(AcceptIcon, "#38BC2D")
        ) : (
          <AcceptIcon />
        );
        break;
      case "P":
        icon = (
          <IconWithQR
            icon={HourglassIcon}
            color="rgb(63, 209, 224)"
            backgroundColor="rgb(211, 246, 252)"
            style={{ padding: "0.65rem" }}
          />
        );
        break;
      case "R":
        icon = (
          <IconWithQR
            icon={HourglassIcon}
            color="rgb(118, 123, 127)"
            backgroundColor="rgb(227, 228, 230)"
            style={{ padding: "0.65rem" }}
          />
        );
        break;
      default:
        icon = <></>;
    }

    return { icon, status };
  };

  const getLabelFromPaymentValue = (selectedPaymentTypeValue) => {
    if (!selectedPaymentTypeValue) {
      return null;
    }

    return PAYMENT_TYPES.find((el) => el.value === selectedPaymentTypeValue)
      .label;
  };

  const generateNewMethodPayment = (selectedPaymentTypeLabel) => {
    const newPaymentRequestType = PAYMENT_TYPES.find(
      (el) => el.label === selectedPaymentTypeLabel
    ).value;

    return newPaymentRequestType;
  };

  const loadServerTransaction = useCallback(() => {
    dispatch(setPageIndex(0));
    dispatch(setServer([]));
    dispatch(setLocal([]));
    sessionStorage.removeItem("search_id");
    localStorage.removeItem("search_id");
    dispatch(fetchServer({ value: null, businessDay, fiscalDay }));
  }, [businessDay, fiscalDay, dispatch]);

  function fetchMoreItems() {
    if (isServerLoaded && state.length !== 0) {
      let count = 0;
      state.forEach((item) => (count += item.items.length));
      dispatch(setPageIndex(count + 1));
      dispatch(fetchServer({ value: null, businessDay, fiscalDay }));
    }
  }

  function chooseItem(item) {
    dispatch(setItem(item));
    if (item.token) {
      return history.push(TRANSACTION_ROUTE + "/" + item.token);
    }

    if (onSelectTransaction) {
      onSelectTransaction(item);
    }

    return history.push(TRANSACTION_ROUTE + "/" + item.transaction_token);
  }

  const operationMatchIcon = () => {
    return {
      cash_inkass: <ExitIcon style={{ width: "12px" }} />,
      cash_podkrep: <EnterIcon style={{ width: "12px" }} />,
      cash: <CashIcon style={{ width: "14px" }} />,
      cash_refund: <CashIcon style={{ width: "14px" }} />,
      contactless: <CardIcon />,
      cashless: <CardPaymentIcon style={{ width: "14px" }} />,
      cashless_refund: <CardPaymentIcon style={{ width: "14px" }} />,
      qr: <QRIcon color={grey} style={{ width: "13px" }} />,
      qr_refund: <QRIcon color={grey} style={{ width: "13px" }} />,
    };
  };
  const operationMatchField = "operation";

  useEffect(() => {
    loadServerTransaction();
  }, [loadServerTransaction]);

  const transactionsTableHead = useMemo(
    () => (
      <>
        <TableHeaders
          state={STATUS_VALUES}
          choose_state={generateStatusValueMap({
            value: transactionFilteringState.status,
            isHumanty: false,
          })}
          type={MULTI_SELECTOR_TYPE}
          fn={(value) => {
            dispatch(
              setTransactionFilteringState({
                field: "status",
                value: generateStatusValueMap({ value, isHumanty: true }),
              })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setTransactionFilteringState({ field: "status", value: null })
            );
            loadServerTransaction();
          }}
          text={"Тип"}
        />
        <TableHeaders
          choose_state={
            transactionFilteringState.startDate
              ? moment(transactionFilteringState.startDate).format("DD.MM.YYYY")
              : null
          }
          rangeFrom={transactionFilteringState.startDate}
          rangeTo={transactionFilteringState.endDate}
          type={DATE_TYPE}
          fn={(newDate) => {
            dispatch(
              setTransactionFilteringState({
                field: "startDate",
                value: newDate[0].startDate,
              })
            );
            dispatch(
              setTransactionFilteringState({
                field: "endDate",
                value: newDate[0].endDate,
              })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setTransactionFilteringState({ field: "startDate", value: null })
            );
            dispatch(
              setTransactionFilteringState({ field: "endDate", value: null })
            );
            loadServerTransaction();
          }}
          text={"Дата"}
          range
        />
        <TableHeaders
          state={terminalValues}
          choose_state={transactionFilteringState.terminal_search}
          type={SELECTOR_TYPE}
          fn={(value) => {
            dispatch(
              setTransactionFilteringState({ field: "terminal_search", value })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setTransactionFilteringState({
                field: "terminal_search",
                value: null,
              })
            );
            loadServerTransaction();
          }}
          text={"Термінал"}
        />
        <TableHeaders
          state={cashierValues}
          choose_state={transactionFilteringState.search_cashier}
          type={SELECTOR_TYPE}
          fn={(value) => {
            dispatch(
              setTransactionFilteringState({ field: "search_cashier", value })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setTransactionFilteringState({
                field: "search_cashier",
                value: null,
              })
            );
            loadServerTransaction();
          }}
          text={"Касир"}
        />
        <TableHeaders
          state={PAYMENT_TYPES.map((el) => el.label)}
          choose_state={getLabelFromPaymentValue(
            transactionFilteringState.search_method_payment
          )}
          type={SELECTOR_TYPE}
          fn={(value) => {
            dispatch(
              setTransactionFilteringState({
                field: "search_method_payment",
                value: generateNewMethodPayment(value),
              })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setTransactionFilteringState({
                field: "search_method_payment",
                value: null,
              })
            );
            loadServerTransaction();
          }}
          text={"Метод оплати"}
        />
        <TableHeaders
          state={FISCAL_STATUS_VALUES}
          choose_state={generateFiscalStatusValueMap(
            transactionFilteringState.fiscal_status
          )}
          type={MULTI_SELECTOR_TYPE}
          fn={(value) => {
            dispatch(
              setTransactionFilteringState({
                field: "fiscal_status",
                value: generateFiscalStatusValueMap(value, true),
              })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setTransactionFilteringState({
                field: "fiscal_status",
                value: null,
              })
            );
            loadServerTransaction();
          }}
          text={"Статус фіскалізації"}
        />
        <TableHeaders
          choose_state={transactionFilteringState.rrn_search}
          type={SEARCH_TYPE}
          fn={(value) => {
            dispatch(
              setTransactionFilteringState({ field: "rrn_search", value })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setTransactionFilteringState({ field: "rrn_search", value: null })
            );
            loadServerTransaction();
          }}
          text={"RRN"}
        />
        <TableHeaders
          choose_state={transactionFilteringState.amount_search}
          type={SEARCH_TYPE}
          fn={(value) => {
            dispatch(
              setTransactionFilteringState({ field: "amount_search", value })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setTransactionFilteringState({
                field: "amount_search",
                value: null,
              })
            );
            loadServerTransaction();
          }}
          text={"Сума ₴"}
        />
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      businessDay,
      fiscalDay,
      terminalValues,
      cashierValues,
      transactionFilteringState,
    ]
  );

  const bankTableHead = useMemo(
    () => (
      <>
        <TableHeaders
          state={STATUS_VALUES}
          choose_state={generateStatusValueMap({
            value: bankFilteringState.status,
            isHumanty: false,
          })}
          type={MULTI_SELECTOR_TYPE}
          fn={(value) => {
            dispatch(
              setBankFilteringState({
                field: "status",
                value: generateStatusValueMap({ value, isHumanty: true }),
              })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(setBankFilteringState({ field: "status", value: null }));
            loadServerTransaction();
          }}
          text={"Тип"}
        />
        <TableHeaders
          choose_state={
            bankFilteringState.startDate
              ? moment(bankFilteringState.startDate).format("DD.MM.YYYY")
              : null
          }
          type={DATE_TYPE}
          fn={(newDate) => {
            dispatch(
              setBankFilteringState({
                field: "startDate",
                value: newDate,
              })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setBankFilteringState({ field: "startDate", value: null })
            );
            dispatch(setBankFilteringState({ field: "endDate", value: null }));
            loadServerTransaction();
          }}
          text={"Дата"}
        />
        <TableHeaders
          state={cashierValues}
          choose_state={bankFilteringState.search_cashier}
          type={SELECTOR_TYPE}
          fn={(value) => {
            dispatch(setBankFilteringState({ field: "search_cashier", value }));
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setBankFilteringState({ field: "search_cashier", value: null })
            );
            loadServerTransaction();
          }}
          text={"Касир"}
        />
        <TableHeaders
          state={PAYMENT_TYPES.map((el) => el.label)}
          choose_state={getLabelFromPaymentValue(
            bankFilteringState.search_method_payment
          )}
          type={SELECTOR_TYPE}
          fn={(value) => {
            dispatch(
              setBankFilteringState({
                field: "search_method_payment",
                value: generateNewMethodPayment(value),
              })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setBankFilteringState({
                field: "search_method_payment",
                value: null,
              })
            );
            loadServerTransaction();
          }}
          text={"Метод оплати"}
        />
        <div></div>
        <TableHeaders
          choose_state={bankFilteringState.rrn_search}
          type={SEARCH_TYPE}
          fn={(value) => {
            dispatch(setBankFilteringState({ field: "rrn_search", value }));
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setBankFilteringState({ field: "rrn_search", value: null })
            );
            loadServerTransaction();
          }}
          text={"RRN"}
        />
        <TableHeaders
          choose_state={bankFilteringState.amount_search}
          type={SEARCH_TYPE}
          fn={(value) => {
            dispatch(setBankFilteringState({ field: "amount_search", value }));
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setBankFilteringState({ field: "amount_search", value: null })
            );
            loadServerTransaction();
          }}
          text={"Сума ₴"}
        />
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [businessDay, fiscalDay, terminalValues, cashierValues]
  );

  const fiscalTableHead = useMemo(
    () => (
      <>
        <TableHeaders
          state={STATUS_VALUES}
          choose_state={generateStatusValueMap({
            value: fiscalFilteringState.status,
            isHumanty: false,
          })}
          type={MULTI_SELECTOR_TYPE}
          fn={(value) => {
            dispatch(
              setFiscalFilteringState({
                field: "status",
                value: generateStatusValueMap({ value, isHumanty: true }),
              })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(setFiscalFilteringState({ field: "status", value: null }));
            loadServerTransaction();
          }}
          text={"Тип"}
        />
        <TableHeaders
          choose_state={
            fiscalFilteringState.startDate
              ? moment(fiscalFilteringState.startDate).format("DD.MM.YYYY")
              : null
          }
          type={DATE_TYPE}
          fn={(newDate) => {
            dispatch(
              setFiscalFilteringState({
                field: "startDate",
                value: newDate,
              })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setFiscalFilteringState({ field: "startDate", value: null })
            );
            dispatch(
              setFiscalFilteringState({ field: "endDate", value: null })
            );
            loadServerTransaction();
          }}
          text={"Дата"}
        />
        <TableHeaders
          state={terminalValues}
          choose_state={fiscalFilteringState.terminal_search}
          type={SELECTOR_TYPE}
          fn={(value) => {
            dispatch(
              setFiscalFilteringState({ field: "terminal_search", value })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setFiscalFilteringState({ field: "terminal_search", value: null })
            );
            loadServerTransaction();
          }}
          text={"Термінал"}
        />
        <TableHeaders
          state={PAYMENT_TYPES.map((el) => el.label)}
          choose_state={getLabelFromPaymentValue(
            fiscalFilteringState.search_method_payment
          )}
          type={SELECTOR_TYPE}
          fn={(value) => {
            dispatch(
              setFiscalFilteringState({
                field: "search_method_payment",
                value: generateNewMethodPayment(value),
              })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setFiscalFilteringState({
                field: "search_method_payment",
                value: null,
              })
            );
            loadServerTransaction();
          }}
          text={"Метод оплати"}
        />
        <div></div>
        <TableHeaders
          choose_state={fiscalFilteringState.rrn_search}
          type={SEARCH_TYPE}
          fn={(value) => {
            dispatch(setFiscalFilteringState({ field: "rrn_search", value }));
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setFiscalFilteringState({ field: "rrn_search", value: null })
            );
            loadServerTransaction();
          }}
          text={"RRN"}
        />
        <TableHeaders
          choose_state={fiscalFilteringState.amount_search}
          type={SEARCH_TYPE}
          fn={(value) => {
            dispatch(
              setFiscalFilteringState({ field: "amount_search", value })
            );
            loadServerTransaction();
          }}
          fn_reset={() => {
            dispatch(
              setFiscalFilteringState({ field: "amount_search", value: null })
            );
            loadServerTransaction();
          }}
          text={"Сума ₴"}
        />
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [businessDay, fiscalDay, terminalValues, cashierValues]
  );

  return (
    <>
      <Refresh fn={onPressRefreshButton} />
      {!isLoading && state.length === 0 && (
        <TerminalTitle>Транзакції відсутні.</TerminalTitle>
      )}
      <InfiniteScroll
        pageStart={0}
        initialLoad={false}
        useWindow={true}
        loadMore={fetchMoreItems}
        hasMore={!isFilter && !fiscalDay}
        getScrollParent={() => panelRef}
        loader={<Preloader key="preloader" />}
      >
        <TableContainer>
          {!fiscalDay && !businessDay && (
            <thead>
              <TrMain bday={false}>{transactionsTableHead}</TrMain>
            </thead>
          )}
          {!!businessDay && (
            <thead>
              <TrMain bday={true}>{bankTableHead}</TrMain>
            </thead>
          )}
          {!!fiscalDay && (
            <thead>
              <TrMain fday={true}>{fiscalTableHead}</TrMain>
            </thead>
          )}

          <tbody>
            {state.map((items, key) => {
              return (
                <React.Fragment key={key}>
                  <TrTransactionDate key={key}>
                    <Td>{GenerateDay(items.date)}</Td>
                  </TrTransactionDate>
                  {items.items.map((item) => {
                    const dayDate = item.ctime.split(" ")[0];
                    const timeDate = item.ctime.split(" ")[1];

                    const paymentIcon = operationMatchIcon()[
                      item[operationMatchField]
                    ] || <CardIcon />;

                    const { icon, status } = generateIcon(item);

                    return (
                      <TrMain
                        bday={businessDay}
                        fday={fiscalDay}
                        column={true}
                        text={fiscalDay !== null}
                        className="link"
                        onClick={() => chooseItem(item)}
                        key={item.transaction_id || item.transactionId}
                      >
                        <Td flex_start={true}>{icon}</Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>{dayDate}</TerminalTitle>
                            <TerminalDescribe>{timeDate}</TerminalDescribe>
                          </TerminalText>
                        </Td>
                        {!businessDay && fiscalDay ? (
                          <Td>
                            <Terminal>
                              <TerminalText>
                                <TerminalTitle tcenter>
                                  {item.ptname}
                                </TerminalTitle>
                              </TerminalText>
                            </Terminal>
                          </Td>
                        ) : !businessDay ? (
                          <Td flex_start={true}>
                            <Terminal>
                              <TerminalText>
                                <TerminalTitle>
                                  {item.ptdescription_manual}
                                </TerminalTitle>
                                <TerminalDescribe>
                                  {item.ptdesctiption}
                                  <br />
                                  Terminal ID: {item.ptname}
                                </TerminalDescribe>
                              </TerminalText>
                            </Terminal>
                          </Td>
                        ) : null}
                        {!fiscalDay ? (
                          <Td flex_start={true}>
                            <TerminalText>
                              <TerminalTitle>{item.cashier_name}</TerminalTitle>
                              <TerminalDescribe>
                                {item.cashier_id}
                              </TerminalDescribe>
                            </TerminalText>
                          </Td>
                        ) : null}

                        <Td>
                          <TerminalText>
                            <TransactionTerminalDescribe>
                              {paymentIcon}
                              <span>
                                {generateFiscalPaymentOperationLabel(
                                  item.operation
                                )}
                              </span>
                            </TransactionTerminalDescribe>
                          </TerminalText>
                        </Td>
                        <Td style={{ verticalAlign: "center" }}>
                          <TerminalText>
                            <TransactionTerminalDescribe>
                              {FISCAL_STATUS[item.fiskal_status]}
                            </TransactionTerminalDescribe>
                          </TerminalText>
                        </Td>
                        <Td>
                          <TerminalText>
                            <TerminalTitle>
                              {item.rrn ? item.rrn : null}
                            </TerminalTitle>
                          </TerminalText>
                        </Td>
                        <Td>
                          <TerminalText right={true}>
                            <TerminalTitle
                              className={status === "B" ? "line-through" : ""}
                            >
                              {(Math.round(item.amount * 100) / 100).toFixed(2)}
                              ₴
                            </TerminalTitle>
                          </TerminalText>
                        </Td>
                      </TrMain>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </TableContainer>
      </InfiniteScroll>
      {/* {isLoading && <Preloader />} */}
    </>
  );

  function onPressRefreshButton() {
    dispatch(setPageIndex(0));
    dispatch(setServer([]));
    dispatch(setLocal([]));
    sessionStorage.removeItem("search_id");
    localStorage.removeItem("search_id");
    dispatch(fetchServer({ value: null, businessDay, fiscalDay }));
  }
};

export default TransactionsTable;
