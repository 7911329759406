import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { CashierTable } from "api";
import { MAIN_CASHIER } from "utils/constants/cashier.constants";
import { getShift } from "../../../Fiscal/helpers/fiscalHelper";
import { setGlobalLoading } from "store/main_table/main_table.actions";
import { replaceMessageEntry } from "utils/helpers/message.helpers";
import { selectUserKey } from "store/user/user_slicer";
import { MainTable } from "api";

const useCashOperations = ({ setSlujModalInfo }) => {
  const dispatch = useDispatch();
  const [isMainCashier, setIsMainCashier] = useState(false);
  const [isMainCashierKey, setIsMainCashierKey] = useState(false);
  const [hasOperableKey, setHasOperableKey] = useState(false);
  const [isKeyExpired, setIsKeyExpired] = useState(false);
  const [slujActionAlertState, setSlujActionAlertState] = useState({
    show: false,
    title: "",
    subtitle: "",
    type: "block",
    fnSubmit: () => {},
  });
  const [cabinetKeyId, setCabinetKeyId] = useState();
  const [cashiers, setCashiers] = useState(
    useSelector((state) => state.cashier_table.response)
  );

  const { profile } = useSelector((state) => state.user);
  const userKey = useSelector(selectUserKey);
  const cashierTable = useMemo(() => new CashierTable(), []);

  useEffect(() => {
    const { role } = profile;

    setIsMainCashier(role === MAIN_CASHIER);

    if (userKey) {
      const { validTo, chiefCashier, subjectKeyIdentifier } = userKey;
      const isExpired = moment() > moment(validTo);

      setHasOperableKey(true);
      setIsKeyExpired(isExpired);
      setIsMainCashierKey(chiefCashier);
      setCabinetKeyId(subjectKeyIdentifier);
    } else {
      setHasOperableKey(false);
      setIsMainCashierKey(false);
      setCabinetKeyId(null);
    }

    if (!Array.isArray(cashiers) || cashiers.length === 0) {
      cashierTable.get().then((response) => {
        setCashiers(response.users);
      });
    }
  }, [cashiers, cashierTable, profile, userKey]);

  const setSlujAlertShow = (boolean) =>
    setSlujActionAlertState((state) => ({
      ...state,
      show: boolean,
    }));

  const notifyNoAllowedOperation = ({ title, subtitle, height }) =>
    setSlujActionAlertState((state) => ({
      ...state,
      show: true,
      title,
      subtitle,
      fnClose: () => setSlujAlertShow(false),
      height,
    }));

  const checkIfOperationIsValid = async (terminalId, rro) => {
    if (!hasOperableKey) {
      return notifyNoAllowedOperation({
        title: "Виконання операції неможливе!",
        subtitle:
          "Під Головним касиром повинен бути завантажений ключ для можливості виконання цієї функції",
      });
    }

    if (isKeyExpired) {
      const { app_login, user_name, warn_message } = profile;

      return notifyNoAllowedOperation({
        title: "Виконання операції неможливе!",
        subtitle:
          (warn_message &&
            replaceMessageEntry(
              warn_message,
              "термін дії ключа",
              "термін дії сертифіката"
            )) ||
          `У користувача ${user_name} (${app_login}) скінчився термін дії сертифіката`,
      });
    }

    if (!isMainCashier) {
      return notifyNoAllowedOperation({
        title:
          "Для можливості виконання цієї функції необхідно мати повноваження Головного касира",
      });
    }

    if (rro) {
      dispatch(setGlobalLoading(true));

      const shift = await getShift(terminalId);

      dispatch(setGlobalLoading(false));

      if (shift) {
        let cashier = cashiers.find((item) => item.client_id === shift.userId);

        if (!cashier) {
          try {
            const data = await cashierTable.get();
            const { ErrorCode, users: cashiers } = data || {};

            if (ErrorCode === 0) {
              setCashiers(cashiers);
              cashier = cashiers.find(
                (item) => item.client_id === shift.openClientId
              );
            }
          } catch (error) {
            return notifyNoAllowedOperation({
              title: "Помилка ідентифікації касира зміни",
            });
          }
        }

        if (cashier) {
          if (cashier.role !== MAIN_CASHIER) {
            if (!isMainCashierKey) {
              const { rroId, terminalId, cashier: cashierLogin } = shift;
              const { client_id } = cashier;

              try {
                dispatch(setGlobalLoading(true));

                const res = await cashierTable.getKey(client_id);

                dispatch(setGlobalLoading(false));

                if (res?.errorCode === 0) {
                  const { subjectKeyIdentifier } = res;

                  if (subjectKeyIdentifier !== cabinetKeyId) {
                    return notifyNoAllowedOperation({
                      title: "Операцію заборонено!",
                      subtitle: `на терміналі ${terminalId} з ПРРО ${rroId} відкрита фіскальна зміна касиром ${cashierLogin} з ідентифікатором ключа ${subjectKeyIdentifier}. Логін на вказаний термінал дозволено касиру з таким ідентифікатором ключа або Старшим касирам`,
                      height: "500px",
                    });
                  }
                } else {
                  return notifyNoAllowedOperation({
                    title: "Операцію заборонено!",
                    subtitle: `на терміналі ${terminalId} з ПРРО ${rroId} відкрита фіскальна зміна касиром ${cashierLogin}. Логін на вказаний термінал дозволено цьому касиру або Старшим касирам`,
                  });
                }
              } catch (e) {
                return notifyNoAllowedOperation({
                  title: "Операцію заборонено!",
                  subtitle: `на терміналі ${terminalId} з ПРРО ${rroId} відкрита фіскальна зміна касиром ${cashierLogin}. Логін на вказаний термінал дозволено цьому касиру або Старшим касирам`,
                });
              }
            }
          }
        }
      } else {
        if (profile.fiskal) {
          return notifyNoAllowedOperation({
            title: "Зміну для виконання операції не знайдено",
          });
        }
      }
    }

    return true;
  };

  const onServiceIssuing = async (terminal) => {
    const { terminalid, cash } = terminal;

    dispatch(setGlobalLoading(true));

    const rro = await new MainTable().getRRO(terminalid);
    const rroValue = typeof rro === "string" ? rro : "";

    dispatch(setGlobalLoading(false));

    if (!(await checkIfOperationIsValid(terminalid, rroValue))) {
      return;
    }

    setSlujModalInfo({
      show: true,
      type: "sluj_vid",
      acceptButtonLabel: "Підтвердити службову видачу",
      title: `Службова видача у терміналі ${terminalid}`,
      amountCaption: "Сума службової видачі",
      amountPlaceholder: "Введіть суму службової видачі",
      descriptionCaption: "Призначення службової видачі (не обов'язково)",
      descriptionPlaceholder: "Введіть призначення видачі",
      terminalId: terminalid,
      totalCash: cash,
      rro: rroValue,
    });
  };

  const onServicePayment = async (terminal) => {
    const { terminalid } = terminal;

    dispatch(setGlobalLoading(true));

    const rro = await new MainTable().getRRO(terminalid);
    const rroValue = typeof rro === "string" ? rro : "";

    dispatch(setGlobalLoading(false));

    if (!(await checkIfOperationIsValid(terminalid, rroValue))) {
      return;
    }

    setSlujModalInfo({
      show: true,
      type: "sluj_vnes",
      acceptButtonLabel: "Підтвердити службове внесення",
      title: `Службове внесення у терміналі ${terminalid}`,
      amountCaption: "Сума службового внесення",
      amountPlaceholder: "Введіть суму службового внесення",
      descriptionCaption: "Призначення службового внесення (не обов'язково)",
      descriptionPlaceholder: "Введіть призначення внесення",
      terminalId: terminalid,
      rro: rroValue,
    });
  };

  return {
    slujActionAlertState,
    onServicePayment,
    onServiceIssuing,
  };
};

export default useCashOperations;
