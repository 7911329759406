import styled from "styled-components";

import { size } from "assets/style/global-variables";

export const BackIconContainer = styled.div`
  &.default {
    position: absolute;
    top: 40px;
    cursor: pointer;

    @media screen and (max-width: ${size.laptop}) {
      display: none;
    }
  }

  &.mobile {
    cursor: pointer;
    svg {
      width: 40px;
      height: 40px;
    }
  }
`;
