import lodash from "lodash";

// export const flatCategories = (cats) => {
//   let newCats = [];

//   const catsMapper = (el, deepIndex) => {
//     newCats.push({ ...el, deepIndex });
//     const children = lodash.sortBy(el.inverseParent, [
//       (el) => el.name.toLowerCase(),
//     ]);

//     if (children && children.length !== 0) {
//       for (const cat of children) {
//         catsMapper(cat, deepIndex + 1);
//       }
//     }
//   };

//   if (cats && cats[Symbol.iterator]) {
//     for (const cat of lodash.sortBy(cats, [(cat) => cat.name.toLowerCase()])) {
//       catsMapper(cat, 0);
//     }
//   }

//   return newCats;
// };

export const flatCategories = (cats) => {
  let newCats = [];

  const catsMapper = (el, deepIndex) => {
    newCats.push({ ...el, deepIndex });

    const children = el.inverseParent;

    if (children && children.length !== 0) {
      for (const cat of children) {
        catsMapper(cat, deepIndex + 1);
      }
    }
  };

  if (cats && cats[Symbol.iterator]) {
    for (const cat of cats) {
      catsMapper(cat, 0);
    }
  }

  const sortedCategories = [];
  const undefCategory = newCats.find(({ name }) => name === "Не визначено");

  if (undefCategory) {
    sortedCategories.push(undefCategory);
  }
  const items = newCats.filter((item) => item !== undefCategory);

  sortedCategories.push(
    ...lodash.sortBy(items, [(cat) => cat.name.toLowerCase()])
  );

  return sortedCategories;
};
