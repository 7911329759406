import React from "react";
import Modal from "ui/Modal/Modal";
import FiscalDaySettings from "../../../FiscalDaySettings/FiscalDaySettings";

const FiscalSettingsModal = ({
  daySettings,
  ppoServer,
  userData,
  saveDaySettings,
  setFiscalSettingsOpened,
}) => {
  return (
    <Modal
      onClose={() => setFiscalSettingsOpened(false)}
      className="autoclosing-fiscal-day"
      closeOnBackdrop={false}
    >
      <FiscalDaySettings
        onClose={() => setFiscalSettingsOpened(false)}
        saveDaySettings={saveDaySettings}
        daySettings={JSON.parse(JSON.stringify(daySettings))}
        rroDisabled={!ppoServer}
        fiscalizationDisabled={
          !(userData.fiskal && userData.global_fiskal_enabled)
        }
      />
    </Modal>
  );
};

export default FiscalSettingsModal;
