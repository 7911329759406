import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import JSZip from "jszip";
import moment from "moment";

import { setAlertState, dropAlertState } from "store/alert/alert_actions";
import { b64DecodeUnicode } from "utils/helpers/base64";

const useDownloadAllClosedShiftsZReports = () => {
  const dispatch = useDispatch();
  const [shifts, setShifts] = useState([]);
  const [makingAllShiftZReport, setMakingAllShiftZReport] = useState(false);

  const downloadAllZReports = useCallback(() => {
    // setMakingAllShiftZReport(true);

    // try {
    //   const files = shifts
    //     .map(({ items }) => items)
    //     .flat()
    //     .map(({ info }) => info)
    //     .filter((shift) => shift.z_report_visual)
    //     .map(({ closeDate, terminalId, z_report_visual }) => ({
    //       fileName: `${terminalId}_${moment(closeDate).format(
    //         "DD-MM-YYYY_HH-mm-ss"
    //       )}_z_ПРРО.txt`,
    //       content: b64DecodeUnicode(z_report_visual),
    //     }));

    //   setTimeout(() => {
    //     if (files.length) {
    //       const zip = new JSZip();

    //       files.forEach(({ fileName, content }) => zip.file(fileName, content));
    //       zip
    //         .generateAsync({ type: "blob" })
    //         .then(function (content) {
    //           const link = document.createElement("a");

    //           link.href = URL.createObjectURL(content);
    //           link.download = `${moment().format(
    //             "DD-MM-YYYY_HH-mm-ss"
    //           )}_z_ПРРО.zip`;
    //           link.click();
    //           link.remove();
    //         })
    //         .catch(({ message }) => {
    //           dispatch(
    //             setAlertState({
    //               show: true,
    //               type: "block",
    //               title: "Увага!",
    //               subtitle: `Архів не сформовано${
    //                 message ? `: ${message}` : "."
    //               }`,
    //               fnClose: () => dispatch(dropAlertState()),
    //             })
    //           );
    //         })
    //         .finally(() => setMakingAllShiftZReport(false));
    //     } else {
    //       setMakingAllShiftZReport(false);
    //       dispatch(
    //         setAlertState({
    //           show: true,
    //           type: "block",
    //           title: "Увага!",
    //           subtitle:
    //             "Відсутні Z-звіти змін, спробуйте роздрукувати окремо по кожній зміні",
    //           fnClose: () => dispatch(dropAlertState()),
    //         })
    //       );
    //     }
    //   });
    // } catch ({ message }) {
    //   setMakingAllShiftZReport(false);
    //   dispatch(
    //     setAlertState({
    //       show: true,
    //       type: "block",
    //       title: "Помилка!",
    //       subtitle: message,
    //       fnClose: () => dispatch(dropAlertState()),
    //     })
    //   );
    // }

    const errDates = [];
    const errHandler = (title, subtitle) => {
      dispatch(
        setAlertState({
          show: true,
          type: "block",
          title,
          subtitle,
          fnClose: () => dispatch(dropAlertState()),
        })
      );
    };
    const arcErrHandler = (errorDates) => {
      const dates = errorDates
        .map((date) => moment(date).format("DD.MM.YYYY HH:mm:ss"))
        .join(", ");

      errHandler(
        "Увага!",
        `Не вдалося заархівувати Z-звіти за ${dates}, спробуйте роздрукувати окремо`
      );
    };

    Promise.resolve(setMakingAllShiftZReport(true))
      .then(() => {
        const data = [];
        const tmp = shifts
          .map(({ items }) => items)
          .flat()
          .map(({ info }) => info)
          .filter((shift) => shift.z_report_visual);

        for (let i = 0, n = tmp.length; i < n; ++i) {
          const { closeDate, terminalId, z_report_visual } = tmp[i];

          try {
            data.push({
              fileName: `${terminalId}_${moment(closeDate).format(
                "DD-MM-YYYY_HH-mm-ss"
              )}_z_ПРРО.txt`,
              content: b64DecodeUnicode(z_report_visual),
            });
          } catch (error) {
            errDates.push(closeDate);
          }
        }

        return data;
      })
      .then((files) => {
        if (files.length) {
          const zip = new JSZip();

          files.forEach(({ fileName, content }) => zip.file(fileName, content));
          zip
            .generateAsync({ type: "blob" })
            .then(function (content) {
              const link = document.createElement("a");

              link.href = URL.createObjectURL(content);
              link.download = `${moment().format(
                "DD-MM-YYYY_HH-mm-ss"
              )}_z_ПРРО.zip`;
              link.click();
              link.remove();

              if (errDates.length > 0) {
                setTimeout(() => arcErrHandler(errDates), 5000);
              }
            })
            .catch(({ message }) => {
              errHandler(
                "Увага!",
                `Архів не сформовано${message ? `: ${message}` : "."}`
              );
            })
            .finally(() => setMakingAllShiftZReport(false));
        } else {
          setMakingAllShiftZReport(false);

          if (errDates.length === 0) {
            errHandler(
              "Увага!",
              "Відсутні Z-звіти змін, спробуйте роздрукувати окремо по кожній зміні"
            );
          } else {
            arcErrHandler(errDates);
          }
        }
      })
      .catch(({ message }) => {
        setMakingAllShiftZReport(false);
        errHandler("Помилка!", message);
      });
  }, [shifts, dispatch]);

  const askForDownloadingAllZReports = useCallback(() => {
    dispatch(
      setAlertState({
        show: true,
        type: "remove",
        title: "Увага!",
        subtitle:
          "Процес експорту звітів у zip-архів може тривати певний час. Після створення архіву його буде запропоновано скачати.",
        fnClose: () => dispatch(dropAlertState()),
        fnSubmit: () => {
          dispatch(dropAlertState());
          downloadAllZReports();
        },
        acceptButtonLabel: "Експортувати",
        cancelButtonLabel: "Скасувати",
      })
    );
  }, [dispatch, downloadAllZReports]);

  return {
    setShifts,
    makingAllShiftZReport,
    downloadAllZReports: askForDownloadingAllZReports,
  };
};

export default useDownloadAllClosedShiftsZReports;
