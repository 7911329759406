import { useMemo /*, useState*/ } from "react";
// import Switch from "react-switch";
// import { SwitchContainerItem } from "../TerminalDetail.styles";
import { QRSettingsPanel } from "./QRSettings.styles";
// import { TextField } from "components/forms";
import Select from "components/forms/Select/ReactSelect/Select";

const QRSettings = ({
  terminal: eCommTerminal,
  terminals: eCommTerminals,
  onChangeTerminal,
  isEdit,
  errorMessage,
  setErrorMessage,
}) => {
  const terminals = useMemo(() => eCommTerminals, [eCommTerminals]);
  // const [selectedTerminal, setSelectedTerminal] = useState(null);

  // const { single_qr, v_merchant, v_terminal } = eCommTerminal || {};
  // const [useSingleQR, setUseSingleQR] = useState(Boolean(single_qr));
  // const [vMerchantId, setVMerchantId] = useState(v_merchant || "");
  // const [vTerminalId, setVTerminalId] = useState(v_terminal || "");
  // const [QRImage, setQRImage] = useState(null);
  // const [terminals, setTerminals] = useState(
  //   eCommTerminals.map((item) => ({
  //     ...item,
  //     label: item.v_description,
  //     value: item.v_ptname,
  //   }))
  // );

  // const ptname = eCommTerminal?.v_ptname;

  return (
    <QRSettingsPanel>
      <Select
        options={terminals}
        label="Термінал для QR-оплати *"
        placeholder="Оберіть термінал"
        value={eCommTerminal}
        onChange={onChangeTerminal}
        isDisabled={!isEdit}
        isClearable
        errorMessage={errorMessage}
        onFocus={() => setErrorMessage(null)}
        fontSize="inherit"
        menuPlacement="top"
      />
      {/* <TextField
        name="Ідентифікатор V-POS-термінала"
        value={ptname}
        // value={vTerminalId}
        // fn={setVTerminalId}
        disabled={true}
      />
      <TextField
        name="Ідентифікатор Торговця"
        value={ptname}
        // value={vMerchantId}
        // fn={setVMerchantId}
        disabled={true}
      /> */}
      {/* <SwitchContainerItem>
        <Switch
          onChange={() => setUseSingleQR((v) => !v)}
          checked={useSingleQR}
          // disabled={!isEdit}
          height={25}
          width={40}
          handleDiameter={17}
          uncheckedIcon={false}
          checkedIcon={false}
          offHandleColor="#fff"
          onHandleColor="#fff"
          offColor="#c5c5c5"
          onColor="#28ccc6"
          className={"switch"}
        />
        <span>Використовувати один QR код</span>
      </SwitchContainerItem> */}
      {/* {useSingleQR && QRImage && <img src={QRImage} alt="QR" />} */}
    </QRSettingsPanel>
  );
};

export default QRSettings;
