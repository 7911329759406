import { font_book } from "assets/style/fonts.variables";
import { size } from "assets/style/global-variables";
import styled from "styled-components";

export const ChartLegendHeader = styled.div`
  display: flex;
  align-items: center;
  font-family: ${font_book};
  font-weight: normal;
  font-size: 1rem;

  @media screen and (max-width: ${size.tablet}) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const ChartLegendTypes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  flex: 1 1;

  @media screen and (max-width: 575px) {
    gap: 0.5rem;
  }
`;
