import { useDispatch, useSelector } from "react-redux";
import {
  ChartHeader as Header,
  ChartTitle as Title,
  ChartTitleType as TitleType,
  ChartTitleDetails as TitleDetails,
  ChartSwitchContainer,
  CHART_SWITCH_LABEL_SMALL_STYLE,
} from "../../../../Statistics.styles";
import useHeader from "./useHeader";
import Toolbar from "../ChartToolbar/ChartToolbar";
import { STAT_NAMES, STAT_TYPES } from "pages/Panels/Statistics/constants";
import { ChartSwitch } from "pages/Panels/Statistics/components";
import {
  selectCurrentStatisticsParams,
  selectCurrentStatisticsData,
} from "store/statistics/stat_slicer";
import { setStatParams } from "store/statistics/stat_actions";
import { grey, grey_off } from "assets/style/variables";

const ChartHeader = ({ chartState }) => {
  const { year, years, setYear } = useHeader(chartState);
  const statParams = useSelector(selectCurrentStatisticsParams);
  const { isLoading } = useSelector(selectCurrentStatisticsData);
  const dispatch = useDispatch();
  const onChangeTerminalRange = (value) =>
    dispatch(setStatParams({ ...statParams, bySelectedTerminals: value }));

  const { bySelectedTerminals } = statParams;

  return (
    <Header>
      <Title className="byTerminals">
        <TitleType>{STAT_NAMES[STAT_TYPES.BY_TERMINAL]}</TitleType>
        <TitleDetails>
          <span>за</span>
          <Toolbar
            {...{
              year,
              setYear,
              years,
            }}
          />
          <span>рік</span>
          <ChartSwitchContainer>
            <ChartSwitch
              onChange={onChangeTerminalRange}
              checked={bySelectedTerminals}
              offColor={grey_off}
              onColor={grey}
              label="вибірково"
              labelStyle={CHART_SWITCH_LABEL_SMALL_STYLE}
              disabled={isLoading}
            />
          </ChartSwitchContainer>
        </TitleDetails>
      </Title>
    </Header>
  );
};

export default ChartHeader;
