import { SET_LOADING, SET_CASH_RECORDS, SET_FILTETING_STATE } from './cash_table.types';

const initialState = {
  loading: false,
  cash_records: [],
  cash_filtered_records: [],
  filteringState: null,
};

export default function cashTableReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_CASH_RECORDS:
      return {
        ...state,
        cash_records: action.payload,
      };
    case SET_FILTETING_STATE:
      return {
        ...state,
        filteringState: action.payload,
      };
    default:
      return state;
  }
}

export const cashLoading = (state) => state.cash_table.loading;
export const cashRecords = (state) => state.cash_table.cash_records;
export const cashFilteringState = (state) => state.cash_table.filteringState;
