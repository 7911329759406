import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { DateRange, Calendar } from "react-date-range";
import { format } from "date-fns";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import locale from "./locale";
import { tiffany } from "assets/style/variables";

import {
  Button,
  ButtonSecondaryBorder,
  ButtonGreen,
} from "components/forms/Button/Button.styles";
import { CalendarContainer, ButtonContainer } from "./Calendar.styles";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { setAlertState, dropAlertState } from "store/alert/alert_actions";

const dateDisplayFormat = "dd.MM.yyyy";
const keyRegExp = /[0-9.]/;
const dateRegExp = /^\d{0,2}\.?\d{0,2}\.?\d{0,4}$/;

const keyDownHandler = (e, falsyValueHandler) => {
  if (
    !(
      e.ctrlKey ||
      keyRegExp.test(e.key) ||
      [
        "ArrowLeft",
        "ArrowRight",
        "ArrowDown",
        "ArrowUp",
        "Backspace",
        "Delete",
        "Tab",
        "Enter",
      ].includes(e.key)
    )
  ) {
    e.preventDefault();
  } else {
    if (e.key === "Enter" && !e.target.value) {
      if (falsyValueHandler) falsyValueHandler();
    }
  }
};

const onPastingHandler = (e) => {
  const data = e.clipboardData.getData("text/plain");

  if (!dateRegExp.test(data)) e.preventDefault();
};

const converseState = (state) => {
  if (Array.isArray(state)) {
    const errorMessage = "invalid date";
    const { startDate, endDate, key } = state[0];
    const value = {
      startDate:
        startDate instanceof Date &&
        startDate.toString().toLowerCase() !== errorMessage
          ? startDate
          : null,
      endDate:
        endDate instanceof Date &&
        endDate.toString().toLowerCase() !== errorMessage
          ? endDate
          : null,
      key,
    };

    return [value];
  } else {
    return state;
  }
};

export default function CalendarUI({
  fn,
  active,
  range,
  propShow,
  setPropShow,
  startDate,
  endDate,
  value,
  required,
  editableDateInputs,
}) {
  const dispatch = useDispatch();
  const [state, setState] = useState(
    range
      ? [
          {
            startDate: startDate || null,
            endDate: endDate || null,
            key: "selection",
          },
        ]
      : value || new Date()
  );

  const startDateText = useRef(
    startDate ? format(startDate, dateDisplayFormat) : ""
  );
  const endDateText = useRef(endDate ? format(endDate, dateDisplayFormat) : "");
  const ref = useRef();
  const inputControls = useRef();

  const chooseDates = () => {
    fn(converseState(state, required));
  };

  useEffect(() => {
    if (!(range && editableDateInputs)) return;

    const startDateKeyDownHandler = (e) => {
      startDateText.current = e.target.value;
      keyDownHandler(e, () =>
        setState((state) => [{ ...state[0], startDate: null }])
      );
    };

    const endDateKeyDownHandler = (e) => {
      endDateText.current = e.target.value;
      keyDownHandler(e, () =>
        setState((state) => [
          {
            ...state[0],
            endDate: null,
          },
        ])
      );
    };

    const startDateInputHandler = (e) => {
      if (!dateRegExp.test(e.target.value)) {
        e.target.value = startDateText.current;
      }
    };

    const endDateInputHandler = (e) => {
      if (!dateRegExp.test(e.target.value)) {
        e.target.value = endDateText.current;
      }
    };

    const startDateFocusHandler = (e) => {
      if (!e.target.value) {
        setState((state) =>
          converseState([{ ...state[0], startDate: new Date() }])
        );
      }
    };

    const endDateFocusHandler = (e) => {
      if (!e.target.value) {
        setState((state) =>
          converseState([{ ...state[0], endDate: new Date() }])
        );
      }
    };

    const inputs = ref.current?.querySelectorAll("input");

    inputControls.current = inputs;

    if (inputs?.length) {
      inputs.forEach((input, i) => {
        input.addEventListener("paste", onPastingHandler, true);

        if (i === 0) {
          input.addEventListener("keydown", startDateKeyDownHandler, true);
          input.addEventListener("input", startDateInputHandler);
          input.addEventListener("focus", startDateFocusHandler);
        } else {
          input.addEventListener("keydown", endDateKeyDownHandler, true);
          input.addEventListener("input", endDateInputHandler);
          input.addEventListener("focus", endDateFocusHandler);
        }
      });

      return () => {
        inputs.forEach((input, i) => {
          input.removeEventListener("paste", onPastingHandler, true);

          if (i === 0) {
            input.removeEventListener("keydown", startDateKeyDownHandler, true);
            input.removeEventListener("input", startDateInputHandler);
            input.removeEventListener("focus", startDateFocusHandler);
          } else {
            input.removeEventListener("keydown", endDateKeyDownHandler, true);
            input.removeEventListener("input", endDateInputHandler);
            input.removeEventListener("focus", endDateFocusHandler);
          }
        });
      };
    }
  }, [state]);

  return (
    <>
      <ButtonGreen
        className="calendar-button"
        onClick={(e) => {
          e.stopPropagation();
          setPropShow((v) => !v);
        }}
        active={active}
      >
        <CalendarIcon className="calendar-icon" />
      </ButtonGreen>
      {propShow ? (
        <CalendarContainer className="calendar-container" ref={ref}>
          {range ? (
            <DateRange
              dateDisplayFormat={dateDisplayFormat}
              locale={locale}
              color={tiffany}
              months={2}
              startDatePlaceholder="Від"
              endDatePlaceholder="До"
              rangeColors={["#3D8077", "blue", "red"]}
              editableDateInputs={Boolean(editableDateInputs)}
              direction={"horizontal"}
              ranges={state}
              moveRangeOnFirstSelection={false}
              retainEndDateOnFirstSelection={true}
              onChange={(item) => setState(converseState([item.selection]))}
            />
          ) : (
            <Calendar
              locale={locale}
              color={tiffany}
              direction={"horizontal"}
              date={state}
              onChange={(item) => setState(item)}
              showMonthAndYearPickers={true}
              className="calendar-selection"
            />
          )}
          <ButtonContainer>
            <ButtonSecondaryBorder
              onClick={(e) => {
                e.stopPropagation();
                setPropShow(false);
              }}
            >
              Скасувати
            </ButtonSecondaryBorder>
            <Button
              className="button_green"
              onClick={(e) => {
                if (
                  range &&
                  required &&
                  !(state[0].startDate && state[0].endDate)
                ) {
                  return dispatch(
                    setAlertState({
                      show: true,
                      title: "Заповнені не всі поля дат!",
                      type: "block",
                      fnSubmit: () => {},
                      fnClose: () => dispatch(dropAlertState()),
                    })
                  );
                }

                e.stopPropagation();
                setPropShow(false);
                chooseDates(e);
              }}
            >
              Обрати
            </Button>
          </ButtonContainer>
        </CalendarContainer>
      ) : null}
    </>
  );
}
