import moment from "moment";

import { MainTable } from "api/index";
import { LogTable } from "api/log.api";
import { DEF_SORT, ASC_SORT, DESC_SORT } from "utils/constants/sort.constants";
import {
  TABLE_SET_SERVER,
  TABLE_SET_LOCAL,
  TABLE_LOAD,
  TABLE_SERVER_LOAD,
  TABLE_CHANGE_BUTTON,
  TABLE_CHANGE_DATE,
  TABLE_SORT_TYPE,
  TABLE_SET_HEADER,
  TABLE_SET_SEARCH,
  TABLE_RESET,
  TABLE_SET_FILTER_TRANSACTION,
  TABLE_SET_FILTER_CARDS,
  TABLE_SET_FILTER_CASH,
  TABLE_SET_FILTER_TOTAL,
  TABLE_SET_FILTER_TRANSACTION_VAL,
  TABLE_SET_FILTER_CARDS_VAL,
  TABLE_SET_FILTER_CASH_VAL,
  TABLE_SET_FILTER_TOTAL_VAL,
  TABLE_SET_SEARCH_VAL,
  TABLE_SET_SERVER_TERMINAL,
  TABLE_SET_LOCAL_TERMINAL,
  TABLE_SET_FILTER_TERMINAL_ID,
  TABLE_SET_FILTER_TERMINAL_DESCRIBE,
  TABLE_SET_FILTER_TERMINAL_NAME,
  TABLE_SET_FILTER_TERMINAL_DEVICE,
  TABLE_SET_FILTER_TERMINAL_ID_VAL,
  TABLE_SET_FILTER_TERMINAL_DESCRIBE_VAL,
  TABLE_SET_FILTER_TERMINAL_NAME_VAL,
  TABLE_SET_FILTER_TERMINAL_DEVICE_VAL,
  TABLE_SORT_TYPE_TERMINAL,
  TABLE_SERVER_LOAD_TERMINAL,
  TABLE_SET_SEARCH_TERMINAL,
  TABLE_SET_ITEM,
  TABLE_SET_FILTER,
  TABLE_SORT_DATE,
  TABLE_SORT_TERMINAL,
  TABLE_SORT_DEVICE,
  TABLE_SORT_STATUS,
} from "./terminal_table.types";

export const fetchServer = (val, index) => async (dispatch, getState) => {
  const server = new LogTable();
  const { state_server } = getState().terminal_table;
  server.getUser(val, index).then((response) => {
    dispatch(loadState());
    if (!response.history) {
      if (state_server.length !== 0) {
        dispatch(setFilter(true));
      }
      dispatch(loadStateServer());
      dispatch(loadState());
      return;
    }

    if (response.history) {
      if (response.history.length === state_server.length) {
        dispatch(setFilter(true));
        return;
      }
    }

    response = response.history
      .filter((item) => item.ctime !== null)
      .sort((a, b) => {
        const aDate = moment(a.ctime, "DD/MM/YYYY HH:mm:ss");
        const bDate = moment(b.ctime, "DD/MM/YYYY HH:mm:ss");

        if (aDate.isAfter(bDate)) {
          return -1;
        }
        if (aDate.isBefore(bDate)) {
          return 1;
        }
        return 0;
      })
      .map((item) => {
        const ctime = item.ctime.split(" ");
        const regex = new RegExp("/", "g");
        const date = ctime[0].replace(regex, ".");

        return {
          ...item,
          ctime: `${date} ${ctime[1].slice(0, 5)}`,
        };
      });

    dispatch(setServer([...state_server, ...response]));
    dispatch(setLocal([...state_server, ...response]));

    if (index === 1) {
      dispatch(loadStateServer());
      dispatch(loadState());
    }
  });
};

function groupByStatus(state) {
  const groups = state.reduce((groups, game) => {
    const status = game.binded;
    if (!groups[status]) {
      groups[status] = [];
    }
    groups[status].push(game);
    return groups;
  }, {});

  const groupArrays = Object.keys(groups).map((status) => {
    return {
      status,
      items: groups[status],
    };
  });

  return groupArrays;
}

export const filterByStatus = () => async (dispatch, getState) => {
  const { search_id_val_terminal, state_server_terminal } =
    getState().terminal_table;

  if (search_id_val_terminal) {
    let state_server = state_server_terminal.filter(
      (item) => item.bind === search_id_val_terminal
    );
    dispatch(setLocalTerminal(state_server));
  } else {
    dispatch(setLocalTerminal(state_server_terminal));
  }
};

export const fetchServerTerminals = (val) => async (dispatch, getState) => {
  const server = new MainTable();

  server.getTerminals(val).then((response) => {
    const state = groupByStatus(response.terminals);

    dispatch(setServerTerminal(state));
    dispatch(setLocalTerminal(state));
    dispatch(generationTerminalHeadersValues(response.terminals));

    dispatch(loadStateServerTerminal());
  });
};

const generationTerminalHeadersValues = (state) => (dispatch, getState) => {
  const state_search_id = state.map((item) => item.ptname);
  const state_search_name = state.map((item) => item.ptdescription);
  const state_search_describe = state.map((item) => item.ptaddress);
  const state_search_device = state.map((item) => item.device_model);

  dispatch(setSearchId(state_search_id));
  dispatch(setSearchName(state_search_name));
  dispatch(setSearchDescribe(state_search_describe));
  dispatch(setSearchDevice(state_search_device));
};

const generationHeadersValues = (state) => (dispatch, getState) => {
  const state_search_transaction = state.map((item) => item.transactions_count);
  const state_search_cash = state.map((item) => item.cash_amount);
  const state_search_card = state.map((item) => item.card_amount);
  const state_search_total = state.map((item) => item.total_amount);

  dispatch(setSearchTransactions(state_search_transaction));
  dispatch(setSearchCards(state_search_card));
  dispatch(setSearchCash(state_search_cash));
  dispatch(setSearchTotals(state_search_total));
};

export const setServer = (state) => ({
  type: TABLE_SET_SERVER,
  payload: state,
});

export const setFilter = (bool) => ({
  type: TABLE_SET_FILTER,
  payload: bool,
});
export const setLocal = (state) => ({
  type: TABLE_SET_LOCAL,
  payload: state,
});
export const setServerTerminal = (state) => ({
  type: TABLE_SET_SERVER_TERMINAL,
  payload: state,
});

export const setLocalTerminal = (state) => ({
  type: TABLE_SET_LOCAL_TERMINAL,
  payload: state,
});

export const loadState = () => ({
  type: TABLE_LOAD,
});

export const loadStateServer = () => ({
  type: TABLE_SERVER_LOAD,
});
export const loadStateServerTerminal = () => ({
  type: TABLE_SERVER_LOAD_TERMINAL,
});

export const setItem = (item) => ({
  type: TABLE_SET_ITEM,
  payload: item,
});
export const setActiveButton = (id) => ({
  type: TABLE_CHANGE_BUTTON,
  payload: id,
});

export const setDates = (dates) => ({
  type: TABLE_CHANGE_DATE,
  payload: dates,
});

export const sortColumnTerminal = () => (dispatch, getState) => {
  let { state_local_terminal, sortTypeTerminal } = getState().main_table;
  let sortState,
    sortType = sortTypeTerminal;

  sortState = state_local_terminal.reverse();
  sortType === DEF_SORT ? (sortType = DESC_SORT) : (sortType = DEF_SORT);

  dispatch(setLocalTerminal(sortState));
  dispatch(setSortTypeTerminal(sortType));
};
export const sortColumn = (key, type, fn) => (dispatch, getState) => {
  const state = getState().terminal_table;
  let sortState, sortType;
  switch (type) {
    case DEF_SORT:
      sortState = state.state_local.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      sortType = ASC_SORT;
      break;
    case ASC_SORT:
      sortState = state.state_local.sort((a, b) => (b[key] > a[key] ? 1 : -1));
      sortType = DESC_SORT;
      break;

    default:
      sortState = [...state.state_server];
      sortType = DEF_SORT;
      break;
  }
  dispatch(loadState());
  dispatch(setLocal(sortState));
  dispatch(loadState());
  dispatch(fn(sortType));
};

export const setSortType = (type) => ({
  type: TABLE_SORT_TYPE,
  payload: type,
});
export const setSortTypeTerminal = (type) => ({
  type: TABLE_SORT_TYPE_TERMINAL,
  payload: type,
});

export const setSortTerminal = (type) => ({
  type: TABLE_SORT_TERMINAL,
  payload: type,
});
export const setSortDate = (type) => ({
  type: TABLE_SORT_DATE,
  payload: type,
});
export const setSortDevice = (type) => ({
  type: TABLE_SORT_DEVICE,
  payload: type,
});
export const setSortStatus = (type) => ({
  type: TABLE_SORT_STATUS,
  payload: type,
});

export const setHeaders = (headers) => ({
  type: TABLE_SET_HEADER,
  payload: headers,
});

export const setSearch = (state) => ({
  type: TABLE_SET_SEARCH,
  payload: state,
});

export const setSearchTerminal = (state) => ({
  type: TABLE_SET_SEARCH_TERMINAL,
  payload: state,
});

export const filterTable = (key, val) => (dispatch, getState) => {
  const { state_local } = getState().main_table;

  switch (key) {
    case "ptdesctiption":
      dispatch(setSearchVal(val));
      break;
    case "transactions_count":
      dispatch(setSearchTransactionsVal(val));
      break;
    case "card_amount":
      dispatch(setSearchCardsVal(val));
      break;
    case "cash_amount":
      dispatch(setSearchCashVal(val));
      break;
    case "total_amount":
      dispatch(setSearchTotalsVal(val));
      break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  const filter_state = state_local.filter(
    (item) => item[key].toString() === val
  );
  dispatch(generationHeadersValues(filter_state));

  dispatch(loadState());
  dispatch(setLocal(filter_state));
  dispatch(loadState());
};

const filterState = (key, val, state) =>
  state
    .map((item) => {
      const items = item.items.filter((trans) => {
        const search = trans[key].toString().search(/val.toString()/i);
        if (search !== -1) {
          return true;
        }
        return false;
      });
      if (items.length > 0) return { date: item.date, items };
      return undefined;
    })
    .filter((item) => item !== undefined);

export const filterTableTerminal = (key, val) => (dispatch, getState) => {
  const { state_local_terminal } = getState().main_table;

  switch (key) {
    case "ptdescription_manual":
      dispatch(setSearchNameVal(null));
      break;
    case "ptname":
      dispatch(setSearchIdVal(null));
      break;
    case "device_model":
      dispatch(setSearchDeviceVal(null));
      break;
    case "ptdescription":
      dispatch(setSearchDescribeVal(null));
      break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  const filter_state = filterState(key, val, state_local_terminal);
  const headers = filter_state.map((item) => item.items).flat(1);
  dispatch(generationTerminalHeadersValues(headers));

  dispatch(setLocalTerminal(filter_state));
};

export const resetTable = () => ({ type: TABLE_RESET });

export const resetFilterTerminal = (val) => (dispatch, getState) => {
  switch (val) {
    case "ptdescription_manual":
      dispatch(setSearchNameVal(null));
      break;
    case "ptname":
      dispatch(setSearchIdVal(null));
      break;
    case "device_model":
      dispatch(setSearchDeviceVal(null));
      break;
    case "ptdescription":
      dispatch(setSearchDescribeVal(null));
      break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  const {
    search_describe_val_terminal,
    search_device_val_terminal,
    search_name_val_terminal,
    search_id_val_terminal,
    state_server_terminal,
  } = getState().main_table;

  let filter_state = state_server_terminal;
  const generateStateByKey = (key, val) => filterState(key, val, filter_state);

  if (search_name_val_terminal)
    filter_state = generateStateByKey(
      "ptdesctiption_manual",
      search_name_val_terminal
    );
  if (search_id_val_terminal)
    filter_state = generateStateByKey("ptname", search_id_val_terminal);
  if (search_device_val_terminal)
    filter_state = generateStateByKey(
      "device_model",
      search_device_val_terminal
    );
  if (search_describe_val_terminal)
    filter_state = generateStateByKey(
      "ptdescription",
      search_describe_val_terminal
    );
  const headers = filter_state.map((item) => item.items).flat(1);
  dispatch(generationTerminalHeadersValues(headers));

  dispatch(loadState());
  dispatch(setLocalTerminal(filter_state));
  dispatch(loadState());
};

export const resetFilter = (value) => (dispatch, getState) => {
  switch (value) {
    case "search":
      dispatch(setSearchVal(null));
      break;
    case "transactions":
      dispatch(setSearchTransactionsVal(null));
      break;
    case "cash":
      dispatch(setSearchCashVal(null));
      break;
    case "total":
      dispatch(setSearchTotalsVal(null));
      break;
    case "card":
      dispatch(setSearchCardsVal(null));
      break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  const {
    search_state_val,
    search_transactions_val,
    search_cards_val,
    search_cash_val,
    search_totals_val,
    state_server,
  } = getState().main_table;

  let filter_state = state_server;
  const generateStateByKey = (key, val) =>
    filter_state.filter((item) => item[key].toString() === val);

  if (search_state_val)
    filter_state = generateStateByKey("ptdesctiption", search_state_val);
  if (search_transactions_val)
    filter_state = generateStateByKey(
      "transactions_count",
      search_transactions_val
    );
  if (search_cards_val)
    filter_state = generateStateByKey("card_amount", search_cards_val);
  if (search_cash_val)
    filter_state = generateStateByKey("cash_amount", search_cash_val);
  if (search_totals_val)
    filter_state = generateStateByKey("total_amount", search_totals_val);

  dispatch(generationHeadersValues(filter_state));

  dispatch(loadState());
  dispatch(setLocal(filter_state));
  dispatch(loadState());
};

export const setSearchCards = (state) => ({
  type: TABLE_SET_FILTER_CARDS,
  payload: state,
});
export const setSearchTransactions = (state) => ({
  type: TABLE_SET_FILTER_TRANSACTION,
  payload: state,
});
export const setSearchCash = (state) => ({
  type: TABLE_SET_FILTER_CASH,
  payload: state,
});
export const setSearchTotals = (state) => ({
  type: TABLE_SET_FILTER_TOTAL,
  payload: state,
});

export const setSearchCardsVal = (state) => ({
  type: TABLE_SET_FILTER_CARDS_VAL,
  payload: state,
});
export const setSearchTransactionsVal = (state) => ({
  type: TABLE_SET_FILTER_TRANSACTION_VAL,
  payload: state,
});
export const setSearchCashVal = (state) => ({
  type: TABLE_SET_FILTER_CASH_VAL,
  payload: state,
});
export const setSearchTotalsVal = (state) => ({
  type: TABLE_SET_FILTER_TOTAL_VAL,
  payload: state,
});

export const setSearchId = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_ID,
  payload: state,
});
export const setSearchDescribe = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_DESCRIBE,
  payload: state,
});
export const setSearchName = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_NAME,
  payload: state,
});
export const setSearchDevice = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_DEVICE,
  payload: state,
});

export const setSearchIdVal = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_ID_VAL,
  payload: state,
});
export const setSearchDescribeVal = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_DESCRIBE_VAL,
  payload: state,
});
export const setSearchNameVal = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_NAME_VAL,
  payload: state,
});
export const setSearchDeviceVal = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_DEVICE_VAL,
  payload: state,
});

export const setSearchVal = (state) => ({
  type: TABLE_SET_SEARCH_VAL,
  payload: state,
});
