import { setBankFilteringState } from 'store/transaction_table/transaction_table.actions';

export const backBankState = ({ dispatch }) => {
  const calculateBdd = (startBdd) =>
    startBdd.indexOf('.') !== -1
      ? `${startBdd.slice(6, 10)}${startBdd.slice(3, 5)}${startBdd.slice(0, 2)}`
      : startBdd;

  if (!!localStorage.getItem('bank-bdd')) {
    dispatch(
      setBankFilteringState({ field: 'bdd', value: calculateBdd(localStorage.getItem('bank-bdd')) })
    );
  }

  if (!!localStorage.getItem('bank-ptname')) {
    dispatch(
      setBankFilteringState({ field: 'ptname', value: localStorage.getItem('bank-ptname') })
    );
  }

  if (!!localStorage.getItem('bank-batch')) {
    dispatch(setBankFilteringState({ field: 'batch', value: localStorage.getItem('bank-batch') }));
  }
};

export const backStateToRedux = ({ dispatch }) => {
  backBankState({ dispatch });
};
