import axios from "axios";
import store from "store";

import LocalStorage from "services/LocalStorage/LocalStorage.service";
import Fetch from "services/Fetch/Fetch.service";
import { URL_SERVER } from "utils/constants/server.constants";

import { dropAlertState, setAlertState } from "store/alert/alert_actions";

export class MainTable {
  constructor() {
    this.fetch = new Fetch();
    this.localStorage = new LocalStorage();
  }

  generateHeader() {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    const headers = new Headers();
    headers.append("Token", token);
    headers.append("DeviceId", "WEB_" + device);

    return headers;
  }

  async get(dateFrom, dateTo, ptname = "ALL") {
    const headers = this.generateHeader();
    const body = {
      date_from: dateFrom,
      date_to: dateTo,
      ptname,
    };

    return this.fetch
      .post(`${URL_SERVER}/api/Terminal`, body, headers)
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  async getECommTerminals() {
    const headers = this.generateHeader();

    return this.fetch
      .get(`${URL_SERVER}/api/Terminal/Virtual`, headers)
      .then((res) => {
        if (res.ErrorCode !== 0) return res;

        const terminals = (res.terminals || []).map((item) => ({
          ...item,
          active: String(item.status).toUpperCase() === "ACTIVE",
          label: `${item.v_ptname} ${item.v_description}`,
          value: item.v_ptname,
        }));

        return { ...res, terminals };
      });
  }

  async getTerminals(search_text) {
    const headers = this.generateHeader();

    const body = {
      search_text,
    };

    const fetchMobileTerminals = this.fetch.put(
      `${URL_SERVER}/api/Terminal`,
      body,
      headers
    );

    const fetchECommTerminals = this.getECommTerminals();

    return Promise.all([fetchMobileTerminals, fetchECommTerminals])
      .then(
        ([
          {
            ErrorCode: mobileErrorCode,
            ErrorMessage: mobileErrorMessage,
            terminals: mobileTerminals,
          },
          {
            ErrorCode: eCommErrorCode,
            ErrorMessage: eCommErrorMessage,
            terminals: eCommTerminals,
          },
        ]) => {
          const f = (ptname) =>
            this.getRRO(ptname).then((rro) => ({ ptname, rro }));

          const mts = mobileTerminals || [];
          const ets = eCommTerminals || [];

          const terminalMap = mts.reduce((a, c) => {
            a[c.ptname] = c;

            return a;
          }, {});

          const rroRequests = mts.map(({ ptname }) => f(ptname));

          return Promise.allSettled(rroRequests)
            .then((rros) =>
              rros.forEach(
                ({ value: { ptname, rro } }) =>
                  (terminalMap[ptname].ppo = typeof rro === "string" ? rro : "")
              )
            )
            .then(() => {
              const result = {
                ErrorCode: mobileErrorCode || eCommErrorCode,
                ErrorMessage: mobileErrorMessage || eCommErrorMessage,
                terminals: [
                  ...mts,
                  ...ets.map(({ v_ptname, v_description, active }) => ({
                    isEComm: true,
                    ptname: v_ptname,
                    ptdescription: v_description,
                    active,
                    terminals: mts
                      .filter(({ v_terminal }) => v_terminal === v_ptname)
                      .map(({ ptname }) => ptname),
                  })),
                ],
              };

              return result;
            });
        }
      )
      .catch((error) => console.error(error));
  }

  async setTerminal(
    ptname,
    ptdescription,
    settl_time,
    receipt,
    footer,
    tipsActivated,
    receiptScale,
    enableCash,
    enableCashless,
    cashRound,
    commentMandatory,
    clientPaymentTemplateId,
    receiptCaptionDefault,
    enable_qr,
    v_merchant,
    v_terminal
  ) {
    const headers = this.generateHeader();
    settl_time = settl_time ? settl_time : "48";
    const body = {
      ptname,
      ptdescription,
      settl_time: settl_time + ":00:00",
      receipt_caption: receipt,
      receipt_footer: footer,
      tips_enabled: tipsActivated,
      receipt_scale: receiptScale,
      enable_cash: enableCash,
      enable_cashless: enableCashless,
      cash_round_enabled: cashRound,
      receipt_caption_default: receiptCaptionDefault,
      comment_mandatory: commentMandatory,
      client_payment_template_id: clientPaymentTemplateId,
      enable_qr,
      v_merchant,
      v_terminal,
    };

    return this.fetch
      .put(`${URL_SERVER}/api/TerminalStatus`, body, headers)
      .catch((error) => console.error(error));
  }

  async blockTerminal(ptname, tapx_login) {
    const headers = this.generateHeader();
    const body = {
      ptname,
      tapx_login,
    };

    return this.fetch
      .post(`${URL_SERVER}/api/TerminalUnbind`, body, headers)
      .then((res) => {
        if (!res || +res.ErrorCode !== 0) {
          throw new Error("error");
        }
        return res;
      })
      .catch((error) => {
        store.dispatch(
          setAlertState({
            show: true,
            title: "Виникла помилка!",
            subtitle: "Виникла помилка при спробі відключити термінал",
            type: "block",
            fnSubmit: () => store.dispatch(dropAlertState()),
            fnClose: () => store.dispatch(dropAlertState()),
          })
        );
      });
  }

  async addPPO(body) {
    const headers = this.generateHeader();
    return this.fetch
      .post(`${URL_SERVER}/api/Dfs/AddRroId`, body, headers)
      .then((res) => res)
      .catch(({ message }) => {
        console.error(message);
        return { error_code: -1, message };
      });
  }

  async getRRO(id) {
    const headers = this.generateHeader();

    try {
      return await this.fetch.get(
        `${URL_SERVER}/api/Dfs/GetRroId/${id}`,
        headers
      );
    } catch (e) {
      return e;
    }
  }

  async removeRRO(id) {
    const headers = this.generateHeader();
    return this.fetch
      .post(`${URL_SERVER}/api/Dfs/RemoveRroId/${id}`, {}, headers)
      .then((res) => res)
      .catch((error) => console.error(error));
  }

  async getUserDaySettings({ terminalId }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const url = `${URL_SERVER}/api/Dfs/GetShiftScheduler/${terminalId}`;

    return axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });
  }

  async saveUserDaySettings({ body }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const url = `${URL_SERVER}/api/Dfs/ShiftScheduler`;

    return axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });
  }

  async getClientPaymentTemplates() {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const url = `${URL_SERVER}/api/PaymentComment/Templates/ALL`;

    const result = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });

    const { ErrorCode, templates: data } = result?.data || {};

    if (ErrorCode === 0) {
      const templates = (data || []).map((item) => ({
        ...item,
        value: item.client_payment_template_id,
        label: item.template_name,
      }));

      return {
        ...result.data,
        templates,
      };
    }

    return (
      result?.data || {
        ErrorCode: -1,
        ErrorMessage: "Помилка запиту шаблонів призначення платежу",
        templates: [],
      }
    );
  }

  async getClientPaymentTags() {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const url = `${URL_SERVER}/api/PaymentComment/Tags`;

    const result = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });

    const { ErrorCode, tags: data } = result?.data || {};

    if (ErrorCode === 0) {
      const tags = (data || []).map((item) => {
        const { tag_name, tag_desc } = item;

        return {
          ...item,
          id: `#${tag_name}#`,
          display: tag_desc,
        };
      });

      return {
        ...result.data,
        tags,
      };
    }

    return (
      result?.data || {
        ErrorCode: -1,
        ErrorMessage: "Помилка запиту тегів шаблону",
        tags: [],
      }
    );
  }

  saveClientPaymentTemplate(template) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const url = `${URL_SERVER}/api/PaymentComment/Template`;

    return axios.post(url, template, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });
  }

  async deleteClientPaymentTemplate(templateId) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");
    const url = `${URL_SERVER}/api/PaymentComment/Template/${templateId}`;

    const result = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
        DeviceId: "WEB_" + device,
      },
    });

    return (
      result?.data || {
        ErrorCode: -1,
        ErrorMessage: "Помилка видалення шаблону призначення платежу",
        templates: [],
      }
    );
  }
}
