import styled from "styled-components";

import { font_book, font_demi } from "assets/style/fonts.variables";
import {
  gradient_green,
  grey,
  grey_light,
  mouse_pointer,
} from "assets/style/variables";

export const ZRecieptContainer = styled.div`
  width: 100%;
  margin: 20px 0 0;
  position: relative;
`;

export const RecieptToggleButton = styled.button`
  background: #13877e;
  padding: 10px 15px;
  border: none;
  outline: none;
  color: #fff;
  font-family: ${font_book};
  font-weight: 500;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.2s ease, border-radius 0.2s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  margin-left: 20px;

  ${(props) =>
    props.opened
      ? `border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;`
      : ""}

  svg {
    font-size: 24px;
    transition: transform 0.2s ease;
  }

  svg.opened {
    transform: rotate(180deg);
  }

  &:hover {
    background: #0c615a;
  }

  &:disabled {
    background: ${grey_light};
    color: ${grey};
    cursor: not-allowed !important;
  }
`;

export const RecieptContent = styled.div`
  width: 100%;
  border-radius: 10px;
  transition: max-height 0.3s ease, padding 0.3s ease;
  padding: 20px;
  background: #f6f7fa;
  border: 1px solid #edeff5;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  ${(props) =>
    props.closed
      ? `
    max-height: 0px;
    border: none;
    padding: 0px 20px;
  `
      : `
  max-height: 700px;`};

  div.buttons {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
  }
`;

export const RecieptText = styled.span`
  font-family: monospace;
  font-size: 16px;
  color: #333;
  width: 70%;
  white-space: pre-line;
  line-height: 1.3;
  /* overflow: auto; */
  max-height: 700px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    margin: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bababa;
    border-radius: 10px;
  }
`;

export const Button = styled.button`
  color: white;
  outline: none;
  border: 0;
  border-radius: 12px;
  background: ${gradient_green};
  text-align: center;
  padding: 12px 20px;
  font-family: ${font_demi};
  font-size: 16px;
  transition: all 0.5s ease;
  cursor: not-allowed ${(props) => (props.inactive ? "" : mouse_pointer)};
`;

export const AButton = styled.a`
  color: white;
  outline: none;
  border: 0;
  border-radius: 12px;
  background: ${gradient_green};
  text-align: center;
  padding: 12px 20px;
  font-family: ${font_demi};
  font-size: 16px;
  transition: all 0.5s ease;
  text-decoration: none;
  cursor: not-allowed ${(props) => (props.inactive ? "" : mouse_pointer)};
`;
