import styled from "styled-components";

import { font_demi } from "assets/style/fonts.variables";

export const SlujOperationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WarningText = styled.span`
  color: orange;
  font-size: 12px;
  align-self: center;
  font-family: ${font_demi};
  width: 200px;
`;
