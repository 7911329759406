import React from "react";
import {
  AlertGridComponent,
  AlertGridRow,
  AlertGridCell,
} from "./AlertGrid.styles";

const AlertGrid = ({ rows, rowStyle }) => {
  return (
    <AlertGridComponent>
      {rows.map((row, i) => {
        return (
          <AlertGridRow key={i} style={rowStyle}>
            {Object.entries(row).map(([key, value]) => (
              <AlertGridCell key={key} className={key}>
                {value}
              </AlertGridCell>
            ))}
          </AlertGridRow>
        );
      })}
    </AlertGridComponent>
  );
};

export default AlertGrid;
