import React, { useState } from "react";
import { useSelector } from "react-redux";

import FiscalDayContent from "./FiscalDayContent";

import { WEEK_DAYS } from "utils/constants/time";
import {
  FISCAL_AUTO_CLOSING_WARNING_TEXT,
  FISCAL_AUTO_CLOSING_ERROR_WARNING,
} from "utils/constants/server.constants";
import { isAutomaticallyFiscalDayClosingSelectedAndCheckboxesNotSelected } from "../TerminalDetail/service";
import { mobileVersion as rMobileVersion } from "store/main_table/main_table.slicer";

import { ReactComponent as CloseIcon } from "./close.svg";
import { FiscalDaySettingsContainer, WarningText } from "./styles";
import { ModalButtons } from "ui/Modal/modal.styles";
import {
  ContainedButton,
  OutlinedButton,
} from "pages/Panels/Cash/ChangeSumModal/changeSumModal.styles";

const FiscalDaySettings = (props) => {
  const {
    onClose,
    saveDaySettings,
    daySettings,
    rroDisabled,
    fiscalizationDisabled,
    isEComm,
  } = props;
  const mobileVersion = useSelector(rMobileVersion) < 900;

  const [currentSettings, setCurrentSettings] = useState(daySettings);

  const buttonIsDisabled =
    isAutomaticallyFiscalDayClosingSelectedAndCheckboxesNotSelected(
      currentSettings
    );

  return (
    <FiscalDaySettingsContainer>
      <div className="close-button" onClick={onClose}>
        <CloseIcon />
      </div>
      <div className="autoclosing-modal-content">
        <div className="heading">
          <h3 className="title">Параметри закриття</h3>
        </div>
        <div className="modal-day-content">
          <FiscalDayContent
            currentSettings={currentSettings}
            onSelectRadioBox={onSelectRadioBox}
            onTogglerTrigger={onTogglerTrigger}
            selectNewDate={selectNewDate}
            onDuplicateDateToAll={onDuplicateDateToAll}
            onSelectCheckbox={onSelectCheckbox}
            rroDisabled={rroDisabled}
            fiscalizationDisabled={fiscalizationDisabled}
            isEComm={isEComm}
          />
        </div>
        <ModalButtons
          style={{
            marginTop: "10px",
            alignItems: "flex-start",
          }}
        >
          {!mobileVersion && currentSettings.fiscalClosingRadioboxes.myself && (
            <WarningText>{FISCAL_AUTO_CLOSING_WARNING_TEXT}</WarningText>
          )}
          {!mobileVersion &&
            !currentSettings.fiscalClosingRadioboxes.myself && (
              <WarningText>{FISCAL_AUTO_CLOSING_ERROR_WARNING}</WarningText>
            )}
          <ContainedButton
            onClick={() => saveDaySettings(currentSettings)}
            disabled={buttonIsDisabled}
          >
            Зберегти
          </ContainedButton>
          <OutlinedButton onClick={onClose}>Скасувати</OutlinedButton>
        </ModalButtons>
      </div>
    </FiscalDaySettingsContainer>
  );

  function onSelectRadioBox({ name, parentField }) {
    const newCurrentSettings = { ...currentSettings };
    Object.keys(newCurrentSettings[parentField]).forEach((radiobox) => {
      if (radiobox !== name) {
        currentSettings[parentField][radiobox] = false;
      } else {
        currentSettings[parentField][name] = true;
      }
    });
    setCurrentSettings(newCurrentSettings);
  }

  function onSelectCheckbox({ name, parentField, value }) {
    const newCurrentSettings = { ...currentSettings };
    currentSettings[parentField][name] = value;
    setCurrentSettings(newCurrentSettings);
  }

  function onTogglerTrigger({ name, value }) {
    const newCurrentSettings = { ...currentSettings };
    newCurrentSettings[name] = value;

    setCurrentSettings(newCurrentSettings);
  }

  function selectNewDate({ field, parentField, value }) {
    const newCurrentSettings = { ...currentSettings };
    newCurrentSettings[parentField][field] = value;

    setCurrentSettings(newCurrentSettings);
  }

  function onDuplicateDateToAll({ hours, minutes }) {
    const newCurrentSettings = { ...currentSettings };
    WEEK_DAYS.map((el) => el.field).forEach((key) => {
      if (!!newCurrentSettings[key].checked) {
        newCurrentSettings[key] = {
          ...newCurrentSettings[key],
          hours,
          minutes,
        };
      }
    });

    setCurrentSettings(newCurrentSettings);
  }
};

export default FiscalDaySettings;
