const TABLE_GET_SERVER = "log_table/get_server";
const TABLE_SET_SERVER = "log_table/set_server";
const TABLE_SET_LOCAL = "log_table/set_local";
const TABLE_SET_SERVER_TERMINAL = "log_table/set_server_terminal";
const TABLE_SET_LOCAL_TERMINAL = "log_table/set_local_terminal";
const TABLE_LOAD = "log_table/load";
const TABLE_SERVER_LOAD = "log_table/server_load";
const TABLE_SERVER_LOAD_TERMINAL = "log_table/server_load_terminal";
const TABLE_CHANGE_BUTTON = "log_table/chang_active_button";
const TABLE_CHANGE_DATE = "log_table/chang_dates";
const TABLE_SORT = "log_table/sort";
const TABLE_SORT_TYPE = "log_table/chang_sort_type";
const TABLE_SORT_TYPE_TERMINAL = "log_table/chang_sort_terminal_type";
const TABLE_SET_HEADER = "log_table/chang_headers";
const TABLE_SET_SEARCH = "log_table/search_box_state";
const TABLE_SET_SEARCH_VAL = "log_table/search_box_state_val";
const TABLE_SET_TERMINAL_SEARCH = "log_table/search_box_terminal_state";
const TABLE_SET_TERMINAL_SEARCH_VAL = "log_table/search_box_state_terminal_val";
const TABLE_FILTER = "log_table/filter";

const TABLE_SET_FILTER_TRANSACTION = "log_table/filter_transaction";
const TABLE_SET_FILTER_CARDS = "log_table/filter_cards";
const TABLE_SET_FILTER_CASH = "log_table/filter_cash";
const TABLE_SET_FILTER_TOTAL = "log_table/filter_totals";

const TABLE_SET_FILTER_TERMINAL_ID = "log_table/filter_id";
const TABLE_SET_FILTER_TERMINAL_NAME = "log_table/filter_name";
const TABLE_SET_FILTER_TERMINAL_DESCRIBE = "log_table/filter_describe";
const TABLE_SET_FILTER_TERMINAL_DEVICE = "log_table/filter_device";

const TABLE_SET_FILTER_TERMINAL_ID_VAL = "log_table/filter_id_val";
const TABLE_SET_FILTER_TERMINAL_NAME_VAL = "log_table/filter_name_val";
const TABLE_SET_FILTER_TERMINAL_DESCRIBE_VAL = "log_table/filter_describe_val";
const TABLE_SET_FILTER_TERMINAL_DEVICE_VAL = "log_table/filter_device_val";

const TABLE_SET_FILTER_TRANSACTION_VAL = "log_table/filter_transaction_val";
const TABLE_SET_FILTER_CARDS_VAL = "log_table/filter_cards_val";
const TABLE_SET_FILTER_CASH_VAL = "log_table/filter_cash_val";
const TABLE_SET_FILTER_TOTAL_VAL = "log_table/filter_totals_val";

const TABLE_SET_SEARCH_TERMINAL = "log_table/search_box_state";

const TABLE_RESET = "log_table/reset";

const TABLE_SET_ITEM = "log_table/choose_item";
const TABLE_SET_INDEX = "log_table/set_index";
const TABLE_SET_FILTER = "log_table/set_filter";

const TABLE_SORT_DATE = "log_table/sort_date";
const TABLE_SORT_TERMINAL = "log_table/sort_terminal";
const TABLE_SORT_DEVICE = "log_table/sort_device";
const TABLE_SORT_STATUS = "log_table/sort_status";

const TABLE_SEARCH_DATE = "terminal_table/search_date";
const TABLE_SEARCH_TERMINAL = "terminal_table/search_terminal";
const TABLE_SEARCH_DEVICE = "terminal_table/search_device";
const TABLE_SEARCH_STATUS = "terminal_table/search_status";
export {
  TABLE_SEARCH_DATE,
  TABLE_SEARCH_TERMINAL,
  TABLE_SEARCH_DEVICE,
  TABLE_SEARCH_STATUS,
  TABLE_SORT_DATE,
  TABLE_SORT_TERMINAL,
  TABLE_SORT_DEVICE,
  TABLE_SORT_STATUS,
  TABLE_SET_FILTER,
  TABLE_SET_INDEX,
  TABLE_LOAD,
  TABLE_GET_SERVER,
  TABLE_SET_SERVER,
  TABLE_SET_LOCAL,
  TABLE_SET_SERVER_TERMINAL,
  TABLE_SERVER_LOAD_TERMINAL,
  TABLE_SET_LOCAL_TERMINAL,
  TABLE_SERVER_LOAD,
  TABLE_CHANGE_BUTTON,
  TABLE_CHANGE_DATE,
  TABLE_SORT,
  TABLE_SORT_TYPE,
  TABLE_SORT_TYPE_TERMINAL,
  TABLE_SET_HEADER,
  TABLE_SET_SEARCH,
  TABLE_SET_SEARCH_TERMINAL,
  TABLE_SET_SEARCH_VAL,
  TABLE_SET_TERMINAL_SEARCH,
  TABLE_SET_TERMINAL_SEARCH_VAL,
  TABLE_FILTER,
  TABLE_RESET,
  TABLE_SET_FILTER_TRANSACTION,
  TABLE_SET_FILTER_CARDS,
  TABLE_SET_FILTER_CASH,
  TABLE_SET_FILTER_TOTAL,
  TABLE_SET_FILTER_TRANSACTION_VAL,
  TABLE_SET_FILTER_CARDS_VAL,
  TABLE_SET_FILTER_CASH_VAL,
  TABLE_SET_FILTER_TOTAL_VAL,
  TABLE_SET_FILTER_TERMINAL_ID,
  TABLE_SET_FILTER_TERMINAL_DEVICE,
  TABLE_SET_FILTER_TERMINAL_DESCRIBE,
  TABLE_SET_FILTER_TERMINAL_NAME,
  TABLE_SET_FILTER_TERMINAL_ID_VAL,
  TABLE_SET_FILTER_TERMINAL_DEVICE_VAL,
  TABLE_SET_FILTER_TERMINAL_DESCRIBE_VAL,
  TABLE_SET_FILTER_TERMINAL_NAME_VAL,
  TABLE_SET_ITEM,
};
