import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";

// components
import Alert from "ui/Alert/Alert";
import TableHeaders from "components/layouts/Table/Headers/Table.headers";
import Modal from "ui/Modal/Modal";
import ChangeSumModal from "../ChangeSumModal/ChangeSumModal";
import SlujOperation from "../SlujOperation/SlujOperation";
import Preloader from "components/additional/PreLoader/Preloader";

// functions
import { CashAPI } from "api/cash.api";
import { SEARCH_TYPE } from "utils/constants/table.headers.constants";
import { getUUID } from "utils/helpers/getUUID";

// redux
import { cashRecords } from "store/cash_table/cash_table.slicer";
import { cashFilteringState } from "store/cash_table/cash_table.slicer";
import {
  cashSetFilteringState,
  cashSetLoading,
  getMainCashList,
} from "store/cash_table/cash_table.actions";
import { cashLoading } from "store/cash_table/cash_table.slicer";
import { dropAlertState, setAlertState } from "store/alert/alert_actions";

// styles
import { ResutIcon } from "components/layouts/SubTotal/SubTotal.icons";
import {
  TableContainer,
  TerminalText,
  TerminalTitle,
  Td,
} from "components/layouts/Table/Table.styles";
import {
  SubTotalItem,
  TextContainer,
  Title,
  Value,
  Price,
} from "components/layouts/SubTotal/SubTotal.styles";
import { TrMain, EditIconContainer, EditIcon } from "./cashList.styles";
import { TooltipLabel } from "ui/TooltipLabel/tooltip-label.styles";
import { ReactComponent as SlujVid } from "../sluj-vid.svg";
import { ReactComponent as SlujVnes } from "../sluj-vnes.svg";

import useCashOperations from "./hooks/useCashOperations";
import { trimMessage } from "utils/helpers/message.helpers";
import { selectUserProfile } from "store/user/user_slicer";

const DEFAULT_SLUJ_MODAL_INFO = {
  show: false,
  type: null,
  acceptButtonLabel: null,
  title: null,
  amountCaption: null,
  amountPlaceholder: null,
  descriptionCaption: null,
  descriptionPlaceholder: null,
  terminalId: null,
  totalCash: null,
};

const CashList = () => {
  const dispatch = useDispatch();
  const startRecords = useSelector(cashRecords);
  const filtetingState = useSelector(cashFilteringState);
  const loading = useSelector(cashLoading);
  const alertState = useSelector((store) => store.alert);
  const { fiskal } = useSelector(selectUserProfile);

  const [records, setRecords] = useState(startRecords);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [slujModalInfo, setSlujModalInfo] = useState(DEFAULT_SLUJ_MODAL_INFO);

  const FILTERING_FORM = ({
    terminal_id = null,
    terminal_name = null,
    terminal_description = null,
    device_id = null,
  }) => ({
    terminal_id: {
      value: terminal_id,
      placeholder: "ID: терміналу",
      filterField: "terminalid",
      formatFunction: ({ oldValue, newValue }) => {
        const regex1 = /^[0-9]{1,10}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        return newValue;
      },
    },
    terminal_name: {
      value: terminal_name,
      placeholder: "Назва терміналу",
      filterField: "ptdescription_manual",
      formatFunction: ({ oldValue, newValue }) => {
        const regex1 = /^.{1,50}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        return newValue;
      },
    },
    terminal_description: {
      value: terminal_description,
      placeholder: "Опис терміналу",
      filterField: "ptdesctiption",
      formatFunction: ({ oldValue, newValue }) => {
        const regex1 = /^.{1,100}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        return newValue;
      },
    },
    device_id: {
      value: device_id,
      placeholder: "Пристрій (Device ID)",
      filterField: "device_name",
      formatFunction: ({ oldValue, newValue }) => {
        const regex1 = /^.{1,50}$/;
        const regex2 = /^$/;

        if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

        return newValue;
      },
    },
  });

  const [filteringForm, setFilteringForm] = useState(
    filtetingState
      ? FILTERING_FORM({
          terminal_id: filtetingState.terminal_id,
          terminal_name: filtetingState.terminal_name,
          terminal_description: filtetingState.terminal_description,
          device_id: filtetingState.device_id,
        })
      : FILTERING_FORM({})
  );

  useEffect(() => {
    dispatch(getMainCashList());
  }, []);

  useEffect(() => {
    setRecords(startRecords);
  }, [startRecords]);

  useEffect(() => {
    dispatch(
      cashSetFilteringState({
        terminal_id: filteringForm.terminal_id.value,
        terminal_name: filteringForm.terminal_name.value,
        terminal_description: filteringForm.terminal_description.value,
        device_id: filteringForm.device_id.value,
      })
    );

    filterRecords();
  }, [filteringForm, startRecords]);

  const { slujActionAlertState, onServicePayment, onServiceIssuing } =
    useCashOperations({ setSlujModalInfo });

  if (loading) return <Preloader />;

  return (
    <>
      {slujActionAlertState.show && <Alert {...slujActionAlertState} />}
      {selectedRecord && (
        <Modal
          onClose={() => setSelectedRecord(null)}
          style={{ width: "600px" }}
        >
          <ChangeSumModal
            selectedRecord={selectedRecord}
            onClose={() => setSelectedRecord(null)}
            onAccept={(sum) => updateRecordSum(sum)}
          />
        </Modal>
      )}
      {slujModalInfo.show && (
        <Modal
          onClose={() => setSlujModalInfo(DEFAULT_SLUJ_MODAL_INFO)}
          style={{ width: "600px" }}
          focusSelector="input.input-active"
        >
          <SlujOperation
            onClose={() => setSlujModalInfo(DEFAULT_SLUJ_MODAL_INFO)}
            onAccept={(data) => onAcceptSlujOperation(data)}
            config={slujModalInfo}
          />
        </Modal>
      )}
      {records && records.length === 0 && (
        <TerminalTitle>За вашим запитом терміналів не знайдено</TerminalTitle>
      )}
      <TableContainer>
        <thead>
          <TrMain>
            <TableHeaders
              type={SEARCH_TYPE}
              choose_state={filteringForm.terminal_id.value}
              fn={(newValue) =>
                setFilteringItem({ newValue, field: "terminal_id" })
              }
              fn_reset={() =>
                setFilteringItem({ newValue: "", field: "terminal_id" })
              }
              text={filteringForm.terminal_id.placeholder}
              formatFunction={filteringForm.terminal_id.formatFunction}
            />
            <TableHeaders
              type={SEARCH_TYPE}
              choose_state={filteringForm.terminal_name.value}
              fn={(newValue) =>
                setFilteringItem({ newValue, field: "terminal_name" })
              }
              fn_reset={() =>
                setFilteringItem({ newValue: "", field: "terminal_name" })
              }
              text={filteringForm.terminal_name.placeholder}
              formatFunction={filteringForm.terminal_name.formatFunction}
            />
            <TableHeaders
              type={SEARCH_TYPE}
              choose_state={filteringForm.terminal_description.value}
              fn={(newValue) =>
                setFilteringItem({ newValue, field: "terminal_description" })
              }
              fn_reset={() =>
                setFilteringItem({
                  newValue: "",
                  field: "terminal_description",
                })
              }
              text={filteringForm.terminal_description.placeholder}
              formatFunction={filteringForm.terminal_description.formatFunction}
            />
            <TableHeaders
              type={SEARCH_TYPE}
              choose_state={filteringForm.device_id.value}
              fn={(newValue) =>
                setFilteringItem({ newValue, field: "device_id" })
              }
              fn_reset={() =>
                setFilteringItem({ newValue: "", field: "device_id" })
              }
              text={filteringForm.device_id.placeholder}
              formatFunction={filteringForm.device_id.formatFunction}
            />
            <th></th>
          </TrMain>
        </thead>
        <tbody>
          {records &&
            records.length !== 0 &&
            records.map((terminal) => {
              const editIconId = `${getUUID()}-edit-icon`;

              return (
                <TrMain key={terminal.terminalid} column>
                  <Td>
                    <TerminalText>
                      <TerminalTitle>{terminal.terminalid}</TerminalTitle>
                    </TerminalText>
                  </Td>
                  <Td>
                    <TerminalText>
                      <TerminalTitle>
                        {terminal.ptdescription_manual}
                      </TerminalTitle>
                    </TerminalText>
                  </Td>
                  <Td>
                    <TerminalText>
                      <TerminalTitle>{terminal.ptdesctiption}</TerminalTitle>
                    </TerminalText>
                  </Td>
                  <Td>
                    <TerminalText>
                      <TerminalTitle>{terminal.device_name}</TerminalTitle>
                    </TerminalText>
                  </Td>
                  <Td>
                    <SubTotalItem>
                      <ResutIcon />
                      <TextContainer>
                        <Title>{"Залишок"}</Title>
                        <Value>
                          {Math.floor(Number(terminal.cash))}
                          <Price>
                            {"." +
                              Number(terminal.cash).toFixed(2).slice(-2) +
                              " ₴"}
                          </Price>
                        </Value>
                      </TextContainer>
                    </SubTotalItem>
                  </Td>
                  <Td>
                    <EditIconContainer
                      onClick={() => setSelectedRecord(terminal)}
                      data-tip
                      data-for={editIconId}
                    >
                      <EditIcon />
                    </EditIconContainer>
                    <ReactTooltip id={editIconId} place="top" effect="solid">
                      <TooltipLabel>{"Редагувати"}</TooltipLabel>
                    </ReactTooltip>
                  </Td>
                  <Td>
                    <div className="sluj-buttons">
                      <button
                        className="sluj"
                        onClick={() => onServiceIssuing(terminal)}
                      >
                        <SlujVid /> <span>Службова видача</span>
                      </button>
                      <button
                        className="sluj"
                        onClick={() => onServicePayment(terminal)}
                      >
                        <SlujVnes /> <span>Службове внесення</span>
                      </button>
                    </div>
                  </Td>
                </TrMain>
              );
            })}
        </tbody>
      </TableContainer>
    </>
  );

  function setFilteringItem({ newValue, field }) {
    const newFilteringForm = {
      ...filteringForm,
      [field]: { ...filteringForm[field], value: newValue },
    };

    setFilteringForm(newFilteringForm);
  }

  function filterRecords() {
    let newRecords = startRecords;
    Object.keys(filteringForm).forEach((key) => {
      if (filteringForm[key].value && filteringForm[key].value !== "") {
        const filterField = filteringForm[key].filterField;

        newRecords =
          newRecords.length !== 0
            ? newRecords.filter(
                (el) =>
                  el[filterField] &&
                  el[filterField]
                    .toLowerCase()
                    .includes(filteringForm[key].value.toLowerCase())
              )
            : [];
      }
    });

    setRecords(newRecords);
  }

  async function updateRecordSum(newSum) {
    const api = new CashAPI();

    dispatch(cashSetLoading(true));

    try {
      const terminalid = selectedRecord.terminalid;

      setSelectedRecord(null);
      await api.updateSum({ terminalid, amount: newSum });
      dispatch(getMainCashList());
    } catch (error) {
      dispatch(
        setAlertState({
          ...alertState,
          type: "block",
          title: "Помилка",
          subtitle: error.message,
          show: true,
          fnClose: () => {
            dispatch(dropAlertState());
          },
        })
      );
    }
  }

  async function onAcceptSlujOperation(data) {
    const { type, amount, payment_comment, terminalId, rro } = data;

    setSlujModalInfo(DEFAULT_SLUJ_MODAL_INFO);

    const server = new CashAPI();
    const defaultErrorMessage = "Виникла серверна помилка";

    dispatch(cashSetLoading(true));

    try {
      const response =
        type === "sluj_vid"
          ? await server.inkass({ terminalId, amount, payment_comment })
          : await server.podkrep({ terminalId, amount, payment_comment });

      dispatch(cashSetLoading(false));

      if (!response || !response.data || +response.data.ErrorCode !== 0) {
        return dispatch(
          setAlertState({
            ...alertState,
            type: "block",
            title: "Помилка",
            subtitle:
              trimMessage(
                response?.data?.errorMessage,
                "WSAResponse: 0 Успішно"
              ) || defaultErrorMessage,
            show: true,
            fnClose: () => {
              dispatch(dropAlertState());
            },
          })
        );
      }

      const successOptions = {
        finallyAction: () =>
          dispatch(
            setAlertState({
              ...alertState,
              type: "done",
              title: "Успішно",
              subtitle:
                type === "sluj_vid"
                  ? 'Ви успішно провели операцію "Службова видача"'
                  : 'Ви успішно провели операцію "Службове внесення"',
              show: true,
              fnSubmit: () => {
                dispatch(dropAlertState());
              },
              fnClose: () => {
                dispatch(dropAlertState());
              },
            })
          ),
      };

      if (/*response && response.data &&*/ !response.data.dfs_error) {
        return dispatch(getMainCashList(successOptions));
      }

      if (/*response && response.data &&*/ !!response.data.dfs_error) {
        if (fiskal && rro) {
          return dispatch(
            setAlertState({
              ...alertState,
              type: "remove",
              title: `Виникла помилка фіскалізації в операції ${
                type === "sluj_vid"
                  ? '"Службова видача"'
                  : '"Службове внесення"'
              }`,
              subtitle: "Що буде з транзакцією?",
              cancelButtonLabel: "Скасувати транзакцію",
              acceptButtonLabel: "Залишити без фіскалізації",
              fnSubmit: () => {
                dispatch(dropAlertState());
                dispatch(getMainCashList());
              },
              fnClose: async () => {
                dispatch(dropAlertState());
                dispatch(cashSetLoading(true));
                await server.cancelCashTransaction({
                  transactionToken: response.data.transaction_token,
                });
                dispatch(getMainCashList());
              },
              show: true,
              height: "500px",
            })
          );
        } else {
          return dispatch(getMainCashList(successOptions));
        }
      }
    } catch (error) {
      dispatch(cashSetLoading(false));
      dispatch(
        setAlertState({
          ...alertState,
          type: "block",
          title: "Помилка",
          subtitle: error.message || defaultErrorMessage,
          show: true,
          fnClose: () => {
            dispatch(dropAlertState());
          },
        })
      );
    }
  }
};

export default CashList;
