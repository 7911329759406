import styled from "styled-components";
import { size } from "assets/style/global-variables";

export const ChartSwitchWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 8rem;

  @media screen and (max-width: ${size.laptop}) {
    width: 7rem;
    font-size: smaller;
  }

  @media screen and (max-width: 575px) {
    width: auto;
    overflow: hidden;
    gap: 0.25rem;
  }
`;
