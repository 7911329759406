import {
  ChartHeader as Header,
  ChartTitle as Title,
} from "../../../../Statistics.styles";
import useHeader from "./useHeader";
import Toolbar from "../ChartToolbar/ChartToolbar";
import { STAT_NAMES, STAT_TYPES } from "pages/Panels/Statistics/constants";

const ChartHeader = ({ chartState, isLoading }) => {
  const {
    beginYear,
    endYear,
    years,
    setBeginYear,
    setEndYear,
    quarterly,
    setQuarterly,
  } = useHeader(chartState);

  return (
    <Header>
      <Title>{STAT_NAMES[STAT_TYPES.BY_MONTH]}</Title>
      <Toolbar
        {...{
          beginYear,
          setBeginYear,
          endYear,
          setEndYear,
          years,
          quarterly,
          setQuarterly,
          isLoading,
        }}
      />
    </Header>
  );
};

export default ChartHeader;
