import styled from "styled-components";

import { white } from "assets/style/variables";
import { size } from "assets/style/global-variables";

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: ${white};
  width: 100%;
  min-width: 320px;
  overflow-x: auto;
  border-radius: 30px 0 0 30px;
  padding: 40px 50px;
  align-self: stretch;
  position: relative;

  @media screen and (max-width: ${size.laptop}) {
    border-radius: 0;
    padding: 20px 30px;
  }

  @media screen and (max-width: ${size.tablet}) {
    padding: 20px !important;
  }

  @media screen and (max-width: ${size.mobileL}) {
    padding: 20px 10px !important;
  }
`;
export { PanelContainer };
