import styled from "styled-components";
import { tiffany, oshad_green, grey } from "assets/style/variables";

import { ButtonSwitcher } from "../Bank/Bank.styles";

export const NewsContainer = styled.section`
  margin: 0;
  margin-bottom: 55px;
`;

export const NewsHeader = styled.section`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
`;

export const ExportButton = styled(ButtonSwitcher)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: auto;
  background-color: transparent;

  &.edit-on {
    box-shadow: inset 0 0 4px ${grey};
  }

  .toggle-edit {
    font-size: 2rem;
    color: ${oshad_green};
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;

  .ring {
    height: 44px;

    div {
      width: 44px;
      height: 44px;
    }
  }

  button {
    transition: box-shadow 0.1s ease-in;

    &:hover {
      box-shadow: 0 2px 0.75rem ${grey};
    }

    &:active {
      box-shadow: none;
    }
  }

  label {
    padding: 14px 20px;
    width: auto;
    border: 1px solid rgba(112, 120, 127, 0.2);
    box-shadow: none;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    justify-content: center;
    font-family: "Futura Book", sans-serif;
    border-radius: 12px;
    cursor: pointer;
    svg {
      font-size: 22px;
      color: ${tiffany};
    }
  }

  @media screen and (max-width: 1485px) {
    span.optional {
      display: none;
    }
  }
`;
