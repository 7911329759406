import { ReactComponent as DoneIcon } from "./done.svg";
import { ReactComponent as BlockIcon } from "./block.svg";
import { ReactComponent as RemoveIcon } from "./remove.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";

const infoBorderStyle = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: "125px",
  height: "125px",
  minHeight: "125px",
  border: "3px solid rgba(92,92,92, 0.07)",
  boxShadow: "0 0 10px rgba(92,92,92, 0.07)",
  borderRadius: "50%",
};

const infoIconStyle = { display: "inline-block", width: "75px" };

export function generateIcon(type) {
  switch (type) {
    case "done":
      return <DoneIcon />;
    case "done-button":
      return <DoneIcon />;
    case "remove":
      return <RemoveIcon />;
    case "block":
      return <BlockIcon />;
    case "info":
      return (
        <span style={infoBorderStyle}>
          <InfoIcon style={infoIconStyle} />
        </span>
      );
    default:
      return null;
  }
}
