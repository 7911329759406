import { DEF_SORT } from "utils/constants/sort.constants";
import {
  TRANSACTION_TYPE,
  PAYMENT_TYPE,
  RESULT_TYPE,
  RETURN_TYPE,
  REVERSED_TYPE,
} from "utils/constants/subtotal.constants";
import GenerateDate from "utils/helpers/date.helpers";

import {
  TABLE_SET_FILTER_TERMINAL_VAL,
  TABLE_OPEN_GET_SERVER,
  TABLE_OPEN_SET_HEADER,
  TABLE_OPEN_LOAD,
  TABLE_OPEN_SET_SEARCH,
  TABLE_CLOSED_GET_SERVER,
  TABLE_CLOSED_SET_SERVER_LOCAL,
  TABLE_CLOSED_LOAD,
  TABLE_CLOSED_SET_SEARCH,
  TABLE_CLOSED_DATE_TO,
  TABLE_CLOSED_DATE_FROM,
  TABLE_DETAIL_ITEM,
  TABLE_SET_FILTER_DATES_FROM,
  TABLE_SET_FILTER_TOTAL,
  TABLE_SET_FILTER_TRANSACTION,
  TABLE_SET_FILTER_BOX,
  TABLE_SET_FILTER_DATES_TO,
  TABLE_SET_FILTER_DATES_FROM_VAL,
  TABLE_SET_FILTER_TOTAL_VAL,
  TABLE_SET_FILTER_TRANSACTION_VAL,
  TABLE_SET_FILTER_BOX_VAL,
  TABLE_SET_FILTER_DATES_TO_VAL,
  TABLE_SORT,
  TABLE_SORT_PACKET,
  TABLE_SORT_DATE_FROM,
  TABLE_SORT_DATE_TO,
  TABLE_SORT_STATUS,
  TABLE_SORT_TRANSACTION,
  TABLE_SORT_TOTAL,
} from "./bank_table.types";

const date = GenerateDate();

const initialState = {
  bank_open_loading: true,
  bank_open_state: [],
  bank_open_search_val: null,
  bank_open_subtotal_state: [
    {
      type: TRANSACTION_TYPE,
      value: 0,
    },
    {
      type: PAYMENT_TYPE,
      value: 0,
    },
    {
      type: RETURN_TYPE,
      value: 0,
    },
    {
      type: REVERSED_TYPE,
      value: 0,
    },
    {
      type: RESULT_TYPE,
      value: 0,
    },
  ],
  bank_closed_loading: true,
  bank_closed_state_server: [],
  bank_closed_state: [],
  bank_closed_search_val: null,

  bank_detail_item: {},
  date_from: date.lastmonth(),
  date_to: date.today(),

  search_dates_from: [],
  search_dates_to: [],
  search_transaction: [],
  search_box: [],
  search_total: [],

  search_dates_from_val: null,
  search_dates_to_val: null,
  search_transaction_val: null,
  search_box_val: null,
  search_totals_val: null,
  search_terminal_val: null,
  sortType: DEF_SORT,
  sortTypePacket: DEF_SORT,
  sortTypeDateFrom: DEF_SORT,
  sortTypeDateTo: DEF_SORT,
  sortTypeStatus: DEF_SORT,
  sortTypeTransaction: DEF_SORT,
  sortTypeTotal: DEF_SORT,
};

export default function bankTableReducer(state = initialState, action) {
  switch (action.type) {
    case TABLE_OPEN_GET_SERVER:
      return {
        ...state,
        bank_open_state: action.payload,
      };
    case TABLE_CLOSED_GET_SERVER:
      return {
        ...state,
        bank_closed_state: action.payload,
      };
    case TABLE_CLOSED_SET_SERVER_LOCAL:
      return {
        ...state,
        bank_closed_state_server: action.payload,
      };

    case TABLE_OPEN_SET_HEADER:
      return {
        ...state,
        bank_open_subtotal_state: [...action.payload],
      };

    case TABLE_OPEN_LOAD:
      return {
        ...state,
        bank_open_loading: action.payload,
      };
    case TABLE_CLOSED_LOAD:
      return {
        ...state,
        bank_closed_loading: action.payload,
      };
    case TABLE_OPEN_SET_SEARCH:
      return {
        ...state,
        bank_open_search_val: action.payload,
      };
    case TABLE_CLOSED_SET_SEARCH:
      return {
        ...state,
        bank_closed_search_val: action.payload,
      };
    case TABLE_CLOSED_DATE_FROM:
      return {
        ...state,
        date_from: action.payload,
      };
    case TABLE_CLOSED_DATE_TO:
      return {
        ...state,
        date_to: action.payload,
      };
    case TABLE_DETAIL_ITEM:
      return {
        ...state,
        bank_detail_item: action.payload,
      };

    case TABLE_SET_FILTER_DATES_FROM:
      return {
        ...state,
        search_dates_from: action.payload,
      };
    case TABLE_SET_FILTER_DATES_TO:
      return {
        ...state,
        search_dates_to: action.payload,
      };
    case TABLE_SET_FILTER_TRANSACTION:
      return {
        ...state,
        search_transaction: action.payload,
      };
    case TABLE_SET_FILTER_BOX:
      return {
        ...state,
        search_box: action.payload,
      };
    case TABLE_SET_FILTER_TOTAL:
      return {
        ...state,
        search_total: action.payload,
      };
    case TABLE_SET_FILTER_DATES_FROM_VAL:
      return {
        ...state,
        search_dates_from_val: action.payload,
      };
    case TABLE_SET_FILTER_DATES_TO_VAL:
      return {
        ...state,
        search_dates_to_val: action.payload,
      };
    case TABLE_SET_FILTER_TRANSACTION_VAL:
      return {
        ...state,
        search_transaction_val: action.payload,
      };
    case TABLE_SET_FILTER_BOX_VAL:
      return {
        ...state,
        search_box_val: action.payload,
      };
    case TABLE_SET_FILTER_TOTAL_VAL:
      return {
        ...state,
        search_total_val: action.payload,
      };
    case TABLE_SET_FILTER_TERMINAL_VAL:
      return {
        ...state,
        search_terminal_val: action.payload,
      };
    case TABLE_SORT:
      return {
        ...state,
        sortType: action.payload,
      };
    case TABLE_SORT_PACKET:
      return {
        ...state,
        sortTypePacket: action.payload,
      };
    case TABLE_SORT_DATE_FROM:
      return {
        ...state,
        sortTypeDateFrom: action.payload,
      };
    case TABLE_SORT_DATE_TO:
      return {
        ...state,
        sortTypeDateTo: action.payload,
      };
    case TABLE_SORT_STATUS:
      return {
        ...state,
        sortTypeStatus: action.payload,
      };
    case TABLE_SORT_TRANSACTION:
      return {
        ...state,
        sortTypeTransaction: action.payload,
      };
    case TABLE_SORT_TOTAL:
      return {
        ...state,
        sortTypeTotal: action.payload,
      };

    default:
      return state;
  }
}

export const serverLoading = (state) => state.bank_table.bank_open_loading;
export const serverClosedLoading = (state) =>
  state.bank_table.bank_closed_loading;

export const bOpenTotal = (state) => state.bank_table.bank_open_subtotal_state;
export const bOpenState = (state) => state.bank_table.bank_open_state;
export const bOpenSearchVal = (state) => state.bank_table.bank_open_search_val;

export const bClosedState = (state) => state.bank_table.bank_closed_state;
export const bClosedSearchVal = (state) =>
  state.bank_table.bank_closed_search_val;

export const bankDetailItem = (state) => state.bank_table.bank_detail_item;

export const searchValuesDatesFrom = (state) =>
  state.bank_table.search_dates_from;
export const searchValuesDatesTo = (state) => state.bank_table.search_dates_to;
export const searchValuesTransaction = (state) =>
  state.bank_table.search_transaction;
export const searchValuesBox = (state) => state.bank_table.search_box;
export const searchValuesTotal = (state) => state.bank_table.search_total;

export const searchValDatesFrom = (state) => state.bank_table.date_from;
export const searchValDatesTo = (state) => state.bank_table.date_to;
export const searchValTerm = (state) => state.bank_table.search_terminal_val;
export const searchValTransaction = (state) =>
  state.bank_table.search_transaction_val;
export const searchValBox = (state) => state.bank_table.search_box_val;
export const searchValTotal = (state) => state.bank_table.search_total_val;

export const sort = (state) => state.bank_table.sortType;
export const sortBoxes = (state) => state.bank_table.sortTypePacket;
export const sortDateFrom = (state) => state.bank_table.sortTypeDateFrom;
export const sortDateTo = (state) => state.bank_table.sortTypeDateTo;
export const sortStatus = (state) => state.bank_table.sortTypeStatus;
export const sortTransaction = (state) => state.bank_table.sortTypeTransaction;
export const sortTotal = (state) => state.bank_table.sortTypeTotal;
