import React, { useState } from "react";

// components
import { TextField } from "components/forms";

// styles
import {
  ChangeSumContainer,
  ContainedButton,
  OutlinedButton,
  WarningText,
} from "./changeSumModal.styles";
import {
  ModalTitle,
  ModalSubTitle,
  ModalBody,
  ModalButtons,
} from "ui/Modal/modal.styles";

const ChangeSumModal = (props) => {
  const { selectedRecord, onClose, onAccept } = props;

  const [newSum, setNewSum] = useState({
    value: selectedRecord.cash.toString(),
    caption: "Залишок",
    placeholder: "Введіть залишок",
    formatFunc: ({ oldValue, newValue }) => {
      const regex1 = /^\d{1,10}[.]?\d{0,2}$/;
      const regex2 = /^$/;

      if (!regex1.test(newValue) && !regex2.test(newValue)) return oldValue;

      if (newValue[0] === "0" && newValue.length !== 1) {
        const splittedArray = newValue.split("");
        splittedArray.shift();

        return splittedArray.join("");
      }

      return newValue;
    },
    touched: false,
  });

  const setNewSumFunc = ({ value }) => {
    const newValue = newSum.formatFunc({
      oldValue: newSum.value,
      newValue: value,
    });
    setNewSum({ ...newSum, value: newValue });
  };

  const acceptButtonIsDisabled = !newSum.value || newSum.value === "";

  return (
    <ChangeSumContainer>
      <ModalTitle>Змінити залишок</ModalTitle>
      <ModalSubTitle>
        готівки в касі для терміналу {selectedRecord.terminalid}
      </ModalSubTitle>
      <ModalBody>
        <TextField
          name={newSum.caption}
          placeholder={newSum.placeholder}
          value={newSum.value}
          fn={(value) => setNewSumFunc({ value })}
          className="input-active"
          disabled={false}
        />
      </ModalBody>
      <ModalButtons style={{ alignItems: "flex-start" }}>
        <WarningText>
          Зміна залишку у цей спосіб не призведе до формування транзакції. Для
          транзакційного коригування суми готівки в касі скористайтесь кнопками
          "Службове внесення" або "Службова видача".
        </WarningText>
        <ContainedButton
          onClick={() => onAccept(Number(newSum.value))}
          disabled={acceptButtonIsDisabled}
        >
          Зберегти
        </ContainedButton>
        <OutlinedButton onClick={onClose}>Скасувати</OutlinedButton>
      </ModalButtons>
    </ChangeSumContainer>
  );
};

export default ChangeSumModal;
