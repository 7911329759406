import * as React from "react";
import {
  CHANGE_TEXT,
  REFRESH,
  SET_ACTIVE,
  SET_BUTTON,
  SET_LEFT,
  SET_SUBTITLE,
  SET_MOBILE_BACK_BUTTON,
  SET_BOUND,
} from "./header.types";

const HeaderContext = React.createContext();

function headerReducer(state, action) {
  switch (action.type) {
    case CHANGE_TEXT: {
      return {
        title: action.payload || state.title,
        left: false,
        subtitle: "",
        button: null,
        active: null,
        refresh: false,
        mobile_back: false,
        bound: false,
      };
    }
    case SET_LEFT: {
      return { ...state, left: true };
    }
    case SET_SUBTITLE: {
      return { ...state, subtitle: action.payload };
    }
    case SET_BUTTON: {
      return { ...state, button: action.payload };
    }
    case SET_ACTIVE: {
      return { ...state, active: action.payload };
    }
    case REFRESH: {
      return { ...state, refresh: !state.refresh };
    }
    case SET_MOBILE_BACK_BUTTON: {
      return { ...state, mobile_back: action.payload };
    }
    case SET_BOUND: {
      return { ...state, bound: action.payload };
    }
    default: {
      return { ...state };
    }
  }
}
function HeaderProvider({ children }) {
  const [state, dispatch] = React.useReducer(headerReducer, {
    title: "",
    left: false,
    subtitle: "",
    button: null,
    active: null,
    refresh: false,
    bound: false,
  });
  const value = { state, dispatch };
  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
}
function useHeader() {
  const context = React.useContext(HeaderContext);
  if (context === undefined) {
    throw new Error("useHeader must be used within a HeaderProvider");
  }
  return context;
}
export { HeaderProvider, useHeader };
