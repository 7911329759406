import styled from "styled-components";

export const Article = styled.div`
  height: 100%;
  font-family: "Futura Book", sans-serif;

  &,
  header {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .content {
    flex-grow: 1;
  }

  .ring {
    height: 44px;
  }
  .ring div {
    width: 44px;
    height: 44px;
  }

  * {
    font-family: inherit;
  }
`;

export const Section = styled.div``;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding-top: 3rem;
  padding-bottom: 1rem;

  button {
    width: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const ButtonContent = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: 1rem;
`;
