import React from 'react';

import { ReactComponent as BackIconImg } from 'assets/icons/transaction/transaction_detail/back.svg';

import { BackIconContainer } from './style';

const BackIcon = (props) => {
  const { className, style } = props;

  return (
    <BackIconContainer onClick={() => window.history.back()} className={className} style={style}>
      <BackIconImg />
    </BackIconContainer>
  );
};

export default BackIcon;
