import React, { useState } from "react";
import { Label, TextFieldContainer } from "./TextField.styles";
import { ReactComponent as PhotoIcon } from "assets/icons/photo.svg";
import { ReactComponent as CloseIcon } from "assets/icons/image_close.svg";

export default function TextFieldImage({
  fn,
  isEdit,
  imgURL,
  avatar_uploaded,
  className,
}) {
  const [url, setUrl] = useState(null);
  function uploadPicture(e) {
    setUrl(URL.createObjectURL(e.target.files[0]));
    fn(e.target.files[0]);
  }
  function photoUploaded() {
    if (avatar_uploaded) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gridGap: "20px",
            marginLeft: "15px",
          }}
        >
          <img
            src={imgURL}
            alt="profile"
            style={{ width: "45px", height: "45px", borderRadius: "12px" }}
          />

          <span className="photo__loaded">Фото завантажено</span>
        </div>
      );
    }
    return null;
  }

  return (
    <TextFieldContainer className={className}>
      {isEdit ? (
        <>
          <input
            type="file"
            name="file"
            id="file"
            className="inputfile"
            accept="image/png, image/gif, image/jpeg"
            onChange={uploadPicture}
          />
          <Label htmlFor="file" wide={url !== null}>
            {url ? (
              <>
                <img
                  src={url}
                  alt="profile"
                  style={{ width: "25px", height: "25px", borderRadius: "50%" }}
                />
                <span>Фото завантажено</span>
                <CloseIcon
                  className="close"
                  onClick={(e) => {
                    e.preventDefault();
                    setUrl(null);
                    fn(null);
                  }}
                />
              </>
            ) : (
              <>
                <PhotoIcon />
                <span>Додати фото</span>
              </>
            )}
          </Label>
        </>
      ) : (
        photoUploaded()
      )}
    </TextFieldContainer>
  );
}
