import axios from "axios";
import moment from "moment";

import { URL_SERVER } from "utils/constants/server.constants";

import { API_SIMPLE_DATE_FORMAT } from "../utils/constants/time";

import { LocalStorage } from "services";

export const GOODS_LIMIT = 20;

export class ZRecieptAPI {
  constructor() {
    this.localStorage = new LocalStorage();
    this.merchantId = this.localStorage.get("merchantId");
    this.shopId = this.localStorage.get("shopId");
  }

  fiscalZReciept({ rro_id, dfs_log_id }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    return axios.post(
      `${URL_SERVER}/api/Transaction/Z`,
      {
        rro_id,
        dfs_log_id,
        format: "BASE64",
      },
      {
        headers: { Token: token, DeviceId: "WEB_" + device },
      }
    );
  }

  bankZReciept({ bdd, batch, terminalId }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    let bddForSearch = "";
    if (bdd) {
      const day = bdd.slice(0, 2);
      const month = bdd.slice(3, 5);
      const year = bdd.slice(6, 10);
      bddForSearch = `${month}.${day}.${year}`;
    }

    return axios.post(
      `${URL_SERVER}/api/Transaction/ZContactless`,
      {
        bdd: bdd ? moment(bddForSearch).format(API_SIMPLE_DATE_FORMAT) : "",
        batch,
        terminalId,
        format: "BASE64",
      },
      {
        headers: { Token: token, DeviceId: "WEB_" + device },
      }
    );
  }

  cashZReciept({ bdd, batch, terminalId }) {
    const token = this.localStorage.get("token");
    const device = this.localStorage.get("device");

    let bddForSearch = "";
    if (bdd) {
      const day = bdd.slice(0, 2);
      const month = bdd.slice(3, 5);
      const year = bdd.slice(6, 10);
      bddForSearch = `${month}.${day}.${year}`;
    }

    return axios.post(
      `${URL_SERVER}/api/Transaction/ZCash`,
      {
        bdd: bdd ? moment(bddForSearch).format(API_SIMPLE_DATE_FORMAT) : "",
        batch,
        terminalId,
        format: "BASE64",
      },
      {
        headers: { Token: token, DeviceId: "WEB_" + device },
      }
    );
  }
}
