import styled from "styled-components";

import {
  Button,
  ButtonSecondaryBorder,
} from "components/forms/Button/Button.styles";

export const ChangeSumContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainedButton = styled(Button)`
  width: auto;
  padding: 12px 15px;
  font-size: 16px;
  background: #13877e;
  transition: background 0.2s ease, border-radius 0.2s ease;
  cursor: pointer;

  &:hover {
    background: #0c615a;
  }
`;

export const OutlinedButton = styled(ButtonSecondaryBorder)`
  width: auto;
  padding: 12px 15px;
  font-size: 16px;
  background: transparent;
  transition: background 0.2s ease, border-radius 0.2s ease;
  cursor: pointer;

  &:hover {
    background: #eee;
  }
`;

export const WarningText = styled.span`
  font-family: "Futura Book", sans-serif;
  font-size: 14px;
  color: red;
`;
