import axios from "axios";

import { URL_SERVER } from "utils/constants/server.constants";

export const validateDisablePRRO = async (data) => {
  const { ptname = "", app_login = "", fiskal_disabled = 0 } = data;

  const currentMerchant = JSON.parse(
    localStorage.getItem("profile")
  ).merchant_name;
  const token = localStorage.getItem("token");
  const device = localStorage.getItem("device");

  const MESSAGES = {
    ptname: (ptname) => ({
      titleMessage: "Увага!",
      subtitleMessage: `
      Для вказаного термінала (${ptname}) існує відкрита фіскальна зміна.
      Для виконання дії необхідно спершу закрити фіскальну зміну.
      `,
      success: false,
    }),
    app_login: (app_login) => ({
      titleMessage: "Увага!",
      subtitleMessage: `
      Для вказаного касира (${app_login}) існує відкрита фіскальна зміна.
      Для виконання дії необхідно спершу закрити фіскальну зміну.
      `,
      success: false,
    }),
    fiskal_disabled: () => ({
      titleMessage: "Увага!",
      subtitleMessage: `
      Для вказаного Торговця (${currentMerchant}) існує відкрита фіскальна зміна.
      Для виконання дії необхідно спершу закрити фіскальну зміну.
      `,
      success: false,
    }),
  };

  try {
    const url = `${URL_SERVER}/api/Options/ValidateDisablePRRO`;
    const requestResult = await axios.post(
      url,
      { ptname, app_login, fiskal_disabled },
      {
        headers: {
          "Content-Type": "application/json",
          Token: token,
          DeviceId: "WEB_" + device,
        },
      }
    );

    if (requestResult.data.ErrorCode === 0) {
      return { success: true };
    }

    if (ptname) {
      return MESSAGES.ptname(ptname);
    }

    if (app_login) {
      return MESSAGES.app_login(app_login);
    }

    return MESSAGES.fiskal_disabled();
  } catch (error) {
    return {
      titleMessage: "Виникла серверна помилка",
      subtitleMessage: "",
      success: false,
      error,
    };
  }
};
