import React from "react";

// styles
import { ALink } from "./styles";

const FileLink = (props) => {
  const { href, caption, target } = props;

  return (
    <ALink href={href} target={target}>
      {caption}
    </ALink>
  );
};

export default FileLink;
