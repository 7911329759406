import Switch from "react-switch";

const SwitchComponent = ({
  checked,
  onChange,
  offColor,
  onColor,
  disabled,
}) => {
  return (
    <Switch
      onChange={onChange}
      checked={checked}
      height={25}
      width={40}
      handleDiameter={17}
      uncheckedIcon={false}
      checkedIcon={false}
      offHandleColor="#fff"
      onHandleColor="#fff"
      offColor={offColor}
      onColor={onColor}
      className={"switch"}
      disabled={disabled}
    />
  );
};

export default SwitchComponent;
