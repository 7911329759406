export const FISCAL_DETAIL_LOADING = "fiscal_table/detail_loading";
export const SET_CLOSED_FILTETING_STATE =
  "fiscal_table/set_closed_filtering_state";
export const DROP_CLOSED_FILTERING_STATE =
  "fiscal_table/drop_closed_filtering_state";

export const TABLE_OPEN_GET_SERVER = "fiscal_table_open/get_server";
export const TABLE_OPEN_SET_HEADER = "fiscal_table_open/set_headers";

export const TABLE_CLOSED_GET_SERVER = "fiscal_table_closed/get_server";

export const TABLE_DETAIL_ITEM = "fiscal_table_detail/item";
export const SET_FISCAL_LOADING = "fiscal_table/set_fiscal_loading";
export const FISCAL_DETAIL_ERROR = "fiscal_table/fiscal_detail_error";
export const FISKAL_OPENED_LIST_ERROR = "fiscal_table/fiscal_opened_list_error";
export const FISKAL_CLOSED_LIST_ERROR = "fiscal_table/fiscal_closed_list_error";

export const INSERT_EXTENDED_INFO = "fiscal_table/insert_extended_info";
