import styled, { css } from "styled-components";
import { font_book } from "assets/style/fonts.variables";
import { size } from "assets/style/global-variables";

const BORDER_COLOR = "#F1F1F1";
const SELECTED_BUTTON_BACKGROUND =
  "linear-gradient(109.17deg, #179C92 -123.63%, #004B45 270.75%)";

export const GoodsTypeSwitcherContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;
  padding: 20px 0;
  position: relative;
`;

export const GoodsTypeSwitcher = styled.div`
  width: 300px;
  border: 1px solid ${BORDER_COLOR};
  border-radius: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  padding: 15px;

  @media screen and (min-width: ${size.tablet}) {
    width: fit-content;
  }
`;

export const SelectingButton = styled.button`
  width: auto;
  border-radius: 12px;
  background-color: #fff;
  padding: 10px;
  border: none;
  outline: none;
  font-family: ${font_book};
  font-weight: 400;
  font-size: 16px;
  ${(props) =>
    props.selected
      ? css`
          background: ${SELECTED_BUTTON_BACKGROUND};
          color: #fff;
        `
      : css`
          background-color: transparent;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05),
            0px 4px 14px rgba(0, 0, 0, 0.05);
          color: #000;
          transition: background-color 0.3s ease;
          &:hover {
            background-color: #eee;
          }
          cursor: pointer;
        `}
  ${(props) =>
    props.disabled
      ? css`
          background: #eee !important;
          color: #aaa !important;
          cursor: not-allowed !important;
        `
      : ""}
`;
