import { DAY_TYPE_INFO } from "./day_type_info";

const { WARNING, INFO } = DAY_TYPE_INFO;

const useAutoCloseInfo = (daySettings) => {
  const {
    autoBdayClose,
    autoFdayClose,
    fiscalClosingRadioboxes: { myself },
  } = daySettings || { fiscalClosingRadioboxes: {} };

  const noBDayClose = myself || !autoBdayClose;
  const noFDayClose = myself || !autoFdayClose;
  const bDayInfoType = noBDayClose ? WARNING : INFO;
  const fDayInfoType = noFDayClose ? WARNING : INFO;
  const bDayInfoMessage = noBDayClose
    ? `закривається автоматично через 48 годин від початку доби, у якій було проведено першу транзакцію карткою або вручну користувачем у мобільному застосунку/онлайн кабінеті`
    : `закривається автоматично за розкладом (див. "Налаштування закриття")`;
  const fDayInfoMessage = noFDayClose
    ? `закривається вручну користувачем у мобільному застосунку або в онлайн кабінеті`
    : `закривається автоматично за розкладом (див. "Налаштування закриття")`;

  return {
    myself,
    noBDayClose,
    noFDayClose,
    bDayInfoType,
    fDayInfoType,
    bDayInfoMessage,
    fDayInfoMessage,
  };
};

export default useAutoCloseInfo;
