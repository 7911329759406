const GenerateDeviceId = () => {
  let uid = "",
    i = 0;
  while (i < 4) {
    uid += Math.random().toString(36).slice(-8);
    i++;
  }
  return uid;
};

export default GenerateDeviceId;
