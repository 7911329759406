import { font_book } from "assets/style/fonts.variables";
import { grey, white } from "assets/style/variables";
import { ButtonSecondary } from "components/forms/Button/Button.styles";
import styled from "styled-components";

export const TrMain = styled.tr`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
  th {
    text-align: left;
    color: ${grey};
    font-family: ${font_book};
    font-size: 14px;
    font-weight: 400;
  }

  td:last-child {
    align-self: center;
  }
  ${(props) =>
    props.column
      ? `
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 12px;
  margin-top: 12px;
  &:hover{
    cursor: pointer;
  }
  `
      : null}
`;

export const Button = styled(ButtonSecondary)`
  font-size: 14px;
  font-family: ${font_book};
  font-weight: 400;
  border-radius: 10px;
  color: ${white};
  background: #0c615a;
  border: 1px solid #0c615a;
  padding: 8px 30px;
`;
