import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Switch from "react-switch";

import { mobileVersion as rMobileVersion } from "store/main_table/main_table.slicer";
import {
  MobileContainer,
  MobileFlex,
  TerminalDetailContainer,
  TerminalDetailHeader,
  TerminalDetailHeaderContainer,
  TerminalDetailHeaderItem,
  TerminalSettingPanel,
  ReceiptContainer,
  SwitchContainer,
  SwitchContainerItem,
  SwitchContainerTextLine,
  CashRoundContainer,
  switchGridStyle,
  infoIconStyle,
  linkStyle,
} from "./TerminalDetail.styles";
import AutoCloseInfo from "./AutoCloseInfo/AutoCloseInfo";
import CommentTemplates from "./CommentTemplates/CommentTemplates";

import { ReactComponent as LineIcon } from "assets/icons/terminalDetail/lines.svg";
import { ReactComponent as BuildIcon } from "assets/icons/terminalDetail/build.svg";
import { ReactComponent as MobileIcon } from "assets/icons/terminalDetail/phone.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { SettingPanelContainer } from "assets/style/variables";
import { TextField } from "components/forms";

import { item, stateLocalTerminal } from "store/main_table/main_table.slicer";
import { dropAlertState, setAlertState } from "store/alert/alert_actions";

import { setTransactionFilteringState } from "store/transaction_table/transaction_table.actions";
import {
  BANK_DAY_ROUTE,
  TRANSACTION_ROUTE,
} from "utils/constants/routes.constants";
import { MainTable } from "api";

import Table from "./Table/TerminalTable";
import BackIcon from "ui/BackIcon/BackIcon";

import { CheckCaption, CheckHeader, CheckDefaultsButton } from "./CheckCaption";
import { usePaymentTemplates, useQR } from "./hooks";
import QRSettings from "./QRSettings/QRSettings";
import useInfo from "./hooks/useInfo";
import useTerminalDetails from "./hooks/useTerminalDetails";
import {
  FiscalSettingsModal,
  TemplateSettingsModal,
  TurnOffTerminalModal,
} from "./modals";
import TurnOffTerminalButton from "./TurnOffTerminalButton/TurnOffTerminalButton";
import TerminalEditButtons from "./TerminalEditButtons/TerminalEditButtons";

const TerminalDetail = () => {
  const showInfo = useInfo();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const isMobileVersion = useSelector(rMobileVersion) < 1024;

  const allTerminals = useSelector(stateLocalTerminal);
  const state =
    useSelector(item) ||
    allTerminals
      .map(({ items }) => items)
      .flat()
      .find((item) => item.ptname === params.id);

  const [templateSettingsOpened, setTemplateSettingsOpened] = useState(false);
  const [commentMandatory, setCommentMandatory] = useState(
    state?.comment_mandatory
  );
  const storedPaymentTemplateId = state?.client_payment_template_id;
  const {
    clientPaymentTemplates,
    setClientPaymentTemplates,
    clientPaymentTemplate,
    setClientPaymentTemplate,
    errorMessage,
    setErrorMessage,
  } = usePaymentTemplates({
    commentMandatory,
    dispatch,
    MainTable,
    storedPaymentTemplateId,
    setAlertState,
    dropAlertState,
  });

  const isEComm = state?.isEComm;
  const {
    isEnabledQR,
    setIsEnabledQR,
    eCommTerminals,
    allECommTerminals,
    selectedTerminal,
    setSelectedTerminal,
    errorMessage: vtErrorMessage,
    setErrorMessage: setVtErrorMessage,
  } = useQR(state?.enable_qr, state?.v_terminal);

  const clientPaymentTemplateId = clientPaymentTemplate?.value;

  const {
    IS_MERCHANT_ID_READONLY,
    daySettings,
    describe,
    editFalse,
    editTrue,
    fiscalSettingsOpened,
    handlePPO,
    id,
    setId,
    merchantID,
    merchantName,
    ppo,
    saveDaySettings,
    setupBlockTerminal,
    turnOffTerminalModalIsActive,
    onCancelInkassAction,
    onCanceFiscalDayClosingAction,
    makeInkassActionFunction,
    closeOpenFiscalDayWithTransactionCheck,
    handleCloseTurnOffTerminalModal,
    onReset,
    ppoServer,
    userData,
    setFiscalSettingsOpened,
    cashBalance,
    isHaveOpenFiscalDay,
    isEdit,
    setIsEdit,
    setDescribe,
    isShowPPO,
    name,
    setName,
    tipsActivated,
    setTipsActivated,
    enableCash,
    setEnableCash,
    cashRound,
    setCashRound,
    enableCashless,
    setEnableCashless,
    receipt,
    setReceipt,
    onChangeReceiptHandler,
    footer,
    setFooter,
    initialPpo,
  } = useTerminalDetails({
    state,
    allTerminals,
    commentMandatory,
    setCommentMandatory,
    clientPaymentTemplates,
    clientPaymentTemplate,
    setClientPaymentTemplate,
    setErrorMessage,
    isEnabledQR,
    setIsEnabledQR,
    eCommTerminals,
    allECommTerminals,
    selectedTerminal,
    setSelectedTerminal,
    vtErrorMessage,
    setVtErrorMessage,
  });

  if (!state) {
    return null;
  }

  return (
    <>
      <TerminalDetailContainer>
        {templateSettingsOpened && (
          <TemplateSettingsModal
            {...{
              setTemplateSettingsOpened,
              clientPaymentTemplates,
              setClientPaymentTemplates,
              setClientPaymentTemplate,
              clientPaymentTemplateId,
              storedPaymentTemplateId,
            }}
          />
        )}
        {fiscalSettingsOpened && (
          <FiscalSettingsModal
            {...{
              daySettings,
              ppoServer,
              userData,
              saveDaySettings,
              setFiscalSettingsOpened,
            }}
          />
        )}
        {turnOffTerminalModalIsActive && (
          <TurnOffTerminalModal
            {...{
              handleCloseTurnOffTerminalModal,
              cashBalance,
              isHaveOpenFiscalDay,
              makeInkassActionFunction,
              onCancelInkassAction,
              closeOpenFiscalDayWithTransactionCheck,
              onCanceFiscalDayClosingAction,
            }}
          />
        )}
        <TerminalDetailHeader>
          <TerminalDetailHeaderContainer>
            <TerminalDetailHeaderItem
              onClick={() => {
                localStorage.removeItem("search_id");
                sessionStorage.removeItem("search_id");
                dispatch(
                  setTransactionFilteringState({
                    field: "terminal_search",
                    value: id,
                  })
                );
                history.push({ pathname: TRANSACTION_ROUTE, state: id });
              }}
            >
              <LineIcon /> Транзакції за терміналом
            </TerminalDetailHeaderItem>
            {!isEComm && (
              <TerminalDetailHeaderItem
                onClick={() =>
                  history.push({ pathname: BANK_DAY_ROUTE, state: id })
                }
              >
                <BuildIcon /> Бізнес-дні терміналу
              </TerminalDetailHeaderItem>
            )}
          </TerminalDetailHeaderContainer>
          {!isMobileVersion && (
            <TurnOffTerminalButton
              {...{ state, isEComm, setupBlockTerminal }}
            />
          )}
        </TerminalDetailHeader>

        <TerminalEditButtons
          {...{
            isMobileVersion,
            isEComm,
            isEdit,
            ppo,
            editTrue,
            editFalse,
            setIsEdit,
          }}
        />

        <TerminalSettingPanel
          {...(isEComm ? { line: true, isFlex: true } : {})}
        >
          <SettingPanelContainer
            disable={!isEdit}
            className="terminal-setting-container"
          >
            {!isEComm && (
              <TextField
                name="Назва"
                placeholder={isEdit ? "Назва терміналу" : ""}
                value={name || ""}
                fn={setName}
                className="input-active"
                disabled={!isEdit}
              />
            )}
            <TextField
              name="Опис"
              value={describe || ""}
              fn={setDescribe}
              disabled={true}
            />
            <TextField
              name="Terminal ID"
              value={id || ""}
              fn={setId}
              disabled={true}
            />
            {!isEComm && isShowPPO ? (
              <TextField
                name="ФН ПРРО"
                placeholder={isEdit ? "ФН ПРРО" : ""}
                value={ppo || ""}
                fn={handlePPO}
                className="input-active"
                disabled={!isEdit}
              />
            ) : null}

            <MobileFlex className="terminal-id">
              <MobileContainer>
                <MobileIcon />
                {state.binded ? (
                  <div className="text">
                    <p className="text__title">{state.device_model}</p>
                    <p className="text__subtitle">
                      Terminal ID: {state.ptname}{" "}
                    </p>
                  </div>
                ) : (
                  <p className="text__title">
                    {isEComm
                      ? state.terminals?.length
                        ? `Підключено до терміналів: ${state.terminals.join(
                            ","
                          )}`
                        : ""
                      : "Щоб під’єднати термінал встановіть мобільний додаток на бажаний мобільний телефон"}
                  </p>
                )}
              </MobileContainer>
              {isMobileVersion && (
                <TurnOffTerminalButton
                  {...{ state, isEComm, setupBlockTerminal }}
                />
              )}
            </MobileFlex>
            {!isEComm && (
              <>
                <SwitchContainer className="extra-services service-item">
                  <Switch
                    onChange={() => setTipsActivated((v) => !v)}
                    checked={Boolean(tipsActivated)}
                    disabled={!isEdit}
                    height={25}
                    width={40}
                    handleDiameter={17}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offHandleColor="#fff"
                    onHandleColor="#fff"
                    offColor="#c5c5c5"
                    onColor="#28ccc6"
                    className={"switch"}
                  />
                  <span className="nowrap switch-title">
                    <span>
                      Сервіс "чайові" (
                      <a
                        href="https://tips.oschadbank.ua"
                        target="_blank"
                        style={{ color: "#179c92" }}
                        rel="noreferrer"
                      >
                        https://tips.oschadbank.ua
                      </a>
                      )
                    </span>
                    <InfoIcon
                      style={infoIconStyle}
                      onClick={() => showInfo("TIPS")}
                    />
                  </span>
                </SwitchContainer>
                <SwitchContainerTextLine>
                  Додаткові сервіси оплати в мобільному застосунку
                </SwitchContainerTextLine>
                <SwitchContainer className="extra-services">
                  <SwitchContainer className="extra-service-item">
                    <SwitchContainerItem className="cash">
                      <Switch
                        onChange={() => setEnableCash((v) => !v)}
                        checked={Boolean(enableCash)}
                        disabled={!isEdit}
                        height={25}
                        width={40}
                        handleDiameter={17}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        offHandleColor="#fff"
                        onHandleColor="#fff"
                        offColor="#c5c5c5"
                        onColor="#28ccc6"
                        className={"switch"}
                      />
                      <span className="nowrap switch-title">
                        <span>Готівка</span>
                        <InfoIcon
                          style={infoIconStyle}
                          onClick={() => showInfo("CASH")}
                        />
                      </span>
                    </SwitchContainerItem>
                    <CashRoundContainer>
                      {"("}
                      <SwitchContainerItem className="cash-round">
                        <Switch
                          onChange={() => setCashRound((v) => !v)}
                          checked={Boolean(cashRound)}
                          disabled={!(isEdit && enableCash)}
                          height={25}
                          width={40}
                          handleDiameter={17}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          offHandleColor="#fff"
                          onHandleColor="#fff"
                          offColor="#c5c5c5"
                          onColor="#28ccc6"
                          className={"switch"}
                        />
                        <span>заокруглення суми чеку</span>{" "}
                        <a
                          target="_blank"
                          href="https://bank.gov.ua/ua/news/all/z-01-lipnya-2018-roku-zastosovuvatimetsya-zaokruglennya-zagalnih-sum-rozrahunkiv-gotivkoyu"
                          style={linkStyle}
                          rel="noreferrer"
                        >
                          [пояснення]
                        </a>
                      </SwitchContainerItem>
                      {")"}
                    </CashRoundContainer>
                  </SwitchContainer>
                  <SwitchContainerItem className="extra-service-item">
                    <Switch
                      onChange={() => setEnableCashless((v) => !v)}
                      checked={Boolean(enableCashless)}
                      disabled={!isEdit}
                      height={25}
                      width={40}
                      handleDiameter={17}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offHandleColor="#fff"
                      onHandleColor="#fff"
                      offColor="#c5c5c5"
                      onColor="#28ccc6"
                      className={"switch"}
                    />
                    <span className="nowrap switch-title">
                      <span>Інше (безготівково)</span>
                      <InfoIcon
                        style={infoIconStyle}
                        onClick={() => showInfo("CASHLESS")}
                      />
                    </span>
                  </SwitchContainerItem>
                  <SwitchContainerItem
                    className="extra-service-item"
                    style={switchGridStyle}
                  >
                    <Switch
                      onChange={() => setIsEnabledQR((v) => !v)}
                      checked={isEnabledQR}
                      disabled={!isEdit}
                      height={25}
                      width={40}
                      handleDiameter={17}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offHandleColor="#fff"
                      onHandleColor="#fff"
                      offColor="#c5c5c5"
                      onColor="#28ccc6"
                      className={"switch"}
                    />
                    <span className="nowrap switch-title">
                      <span>QR-оплата через термінал</span>
                      <InfoIcon
                        style={infoIconStyle}
                        onClick={() => showInfo("QR")}
                      />
                    </span>
                    {isEnabledQR && (
                      <QRSettings
                        terminal={selectedTerminal}
                        terminals={eCommTerminals}
                        onChangeTerminal={setSelectedTerminal}
                        isEdit={isEdit}
                        errorMessage={vtErrorMessage}
                        setErrorMessage={setVtErrorMessage}
                      />
                    )}
                  </SwitchContainerItem>
                  <SwitchContainerItem
                    className="extra-service-item"
                    style={switchGridStyle}
                  >
                    <Switch
                      onChange={(v) => setCommentMandatory(v)}
                      checked={Boolean(commentMandatory)}
                      disabled={!isEdit}
                      height={25}
                      width={40}
                      handleDiameter={17}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offHandleColor="#fff"
                      onHandleColor="#fff"
                      offColor="#c5c5c5"
                      onColor="#28ccc6"
                      className={"switch"}
                    />
                    <span className="nowrap switch-title">
                      <span>Шаблон призначення</span>
                      <InfoIcon
                        style={infoIconStyle}
                        onClick={() => showInfo("TEMPLATE")}
                      />
                    </span>
                    {commentMandatory ? (
                      <CommentTemplates
                        isEdit={isEdit}
                        templates={clientPaymentTemplates}
                        templateId={clientPaymentTemplateId}
                        templateName={state?.template_name}
                        setTemplate={setClientPaymentTemplate}
                        openEditor={() => setTemplateSettingsOpened(true)}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                      />
                    ) : null}
                  </SwitchContainerItem>
                </SwitchContainer>
              </>
            )}
          </SettingPanelContainer>
          {!isEComm && (
            <SettingPanelContainer className="terminal-setting-container">
              <ReceiptContainer disabled={!isEdit}>
                <>
                  <CheckHeader
                    label="Заголовок чеку"
                    headers={[merchantName || "", receipt, `ПН: ${merchantID}`]}
                    placeholder={
                      "Введіть текст, яким буде починатись кожен чек"
                    }
                    disabled={!isEdit}
                    onChange={(value) =>
                      onChangeReceiptHandler(value, setReceipt)
                    }
                    onReset={onReset}
                    isMerchantIdReadOnly={IS_MERCHANT_ID_READONLY}
                  />

                  <CheckCaption
                    label="Закінчення чеку (текст після QR)"
                    text={footer}
                    placeholder={
                      "Введіть текст, яким буде закінчуватись кожен чек"
                    }
                    disabled={!isEdit}
                    onChange={(value) =>
                      onChangeReceiptHandler(value, setFooter)
                    }
                    className="check-footer"
                  />
                  {isEdit && onReset && (
                    <CheckDefaultsButton
                      onClick={onReset}
                      disabled={!isEdit}
                      text={"Скинути до початкового стану"}
                    />
                  )}
                </>
              </ReceiptContainer>
              <AutoCloseInfo
                {...{
                  daySettings,
                  ppo: isEdit ? initialPpo : ppo,
                  setFiscalSettingsOpened,
                }}
              />
            </SettingPanelContainer>
          )}
        </TerminalSettingPanel>
        <Table />
      </TerminalDetailContainer>
      <BackIcon className="default" />
    </>
  );
};

export default TerminalDetail;
