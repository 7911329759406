import React from "react";
import { Select } from "components/forms";
import { ChartToolbarContainer } from "./ChartToolbar.styles";

const ChartToolbar = ({ year, setYear, years, isLoading }) => {
  return (
    <ChartToolbarContainer>
      <Select
        options={years}
        placeholder="рік..."
        value={year}
        onChange={setYear}
        width={"120px"}
        isDisabled={isLoading}
      />
    </ChartToolbarContainer>
  );
};

export default ChartToolbar;
