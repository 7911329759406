import styled from 'styled-components';

import { size } from 'assets/style/global-variables';

export const QuestionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  @media screen and (max-width: ${size.mobileL}) {
    row-gap: 15px;
    * {
      font-size: 16px;
    }
  }
`;

export const QuestionsItem = styled.div``;
