// >> CONFIGURATION

// NODE_ENV = "development" | "stage" | "production";
export const NODE_ENV = "production";

const IS_APP_LOCAL = window.location.host.includes("localhost");

const URL_FROM_LOCATION = document.location.origin;

const URL_SERVER_MAP = {
  development: {
    url_server: IS_APP_LOCAL
      ? "https://devoschadpay.ukrpay.net"
      : URL_FROM_LOCATION,
  },
  stage: {
    url_server: IS_APP_LOCAL
      ? "https://oschadpay.ukrpay.net"
      : URL_FROM_LOCATION,
  },
  production: {
    url_server: IS_APP_LOCAL
      ? "https://online.oschadpay.ua"
      : URL_FROM_LOCATION,
  },
};

const QR_SERVER_MAP = {
  development: {
    url_server: "http://devoschadpay.ukrpay.net",
  },
  stage: {
    url_server: "https://qrcode.ukrpay.net",
  },
  production: {
    url_server: "https://ecomm.oschadpay.ua",
  },
};

export const URL_SERVER_AUTH = URL_SERVER_MAP[NODE_ENV].url_server;
export const URL_SERVER = URL_SERVER_MAP[NODE_ENV].url_server;
export const QR_SERVER = QR_SERVER_MAP[NODE_ENV].url_server;

// export const FISCAL_INSTRUCTION_HREF = `${URL_SERVER}/m/Instr-PRRO-OschadPAY.pdf`;
export const MOBILE_INSTRUCTION_HREF = `${URL_SERVER}/m/instrNewOschadPAY.pdf`;
export const FAQ_INSTRUCTION_HREF = `https://www.oschadbank.ua/oschadpay#faq`;
export const OFFERTA_FOP_FISCAL_HTML = `${URL_SERVER}/api/Dfs/GetOferta`;

export const VERSION_TEXT = () => {
  const node_env = localStorage.getItem("node_env");

  return { TEST: "TEST", PREPROD: "PREPROD", PROD: null }[node_env] || null;
};

export const DISABLED_FISCALIZATION_MODAL_WARNING = false;
export const DISABLED_FISCALIZATION = false;
export const LIMITED_WORKING_MODAL = false;

// << CONFIGURATION

export const PAYMENT_TYPES = [
  { label: "Карткою", value: "CONTACTLESS" },
  { label: "Готівкою", value: "CASH" },
  { label: "Безготівкові", value: "CASHLESS" },
  { label: "Службові", value: "SERVICE" },
  { label: "QR-оплата", value: "QR" },
];

export const TERMINAL_BANK_DAY_TOOLTIP =
  "Банківський бізнес день закривається автоматично через 48 годин від початку доби у якій було проведено першу транзакцію.";
export const TERMINAL_FISCAL_DAY_TOOLTIP =
  "Заданий час у розкладі є приблизним. Швидкість закриття залежить від багатьох факторів, в тому числі і навантаження на сервери податкової. Будь ласка, встановлюйте час закриття з урахуванням можливих затримок.";

export const FISCAL_AUTO_CLOSING_HELPER_TEXT =
  "Автоматичне закриття за розкладом";
export const FISCAL_MANUALLY_CLOSING_HELPER_TEXT =
  "Закриття банківського бізнес-дня та фіскальної зміни виконуються користувачем самостійно в мобільному додатку або особистому кабінеті.";

export const FISCAL_AUTO_CLOSING_WARNING_TEXT =
  "При відсутності дій користувача банківський бізнес-день закривається автоматично через 48 годин від початку доби, у якій було проведено першу транзакцію";
export const FISCAL_AUTO_CLOSING_ERROR_WARNING =
  "У разі помилки на одному з обраних етапів, наступні етапи не будуть виконані, а на реєстраційний номер буде відправлене інформаційне SMS повідомлення.";

export const FISCAL_INFORMATION_TEXT_1 =
  "Фіскалізація - це процедура введення в експлуатацію та обміну даними ПРРО з податковами службами. Фіскалізація потрібна для контролювання і регулювання державою грошового обігу у підприємців";
export const FISCAL_INFORMATION_TEXT_2 =
  "Для обміну інформацією з податковою службою необхідно для кожного платіжного терміналу вказати фіскальний номер ПРРО, зареєстрований на сайті ДПСУ. Також кожному касиру кеобхідно додати індивідуальний ключ для накладання КЕП.";
