export default function GenerateDay(date) {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  const today = new Date();

  let dateParts, dateObject;
  if (date.match(regEx)) {
    dateParts = date.split("-");
    dateObject = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
  } else {
    dateParts = date.split(".");
    dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  }

  const getday = dateObject.getDay();
  const weekday = new Array(7);
  weekday[0] = "Неділя";
  weekday[1] = "Понеділок";
  weekday[2] = "Вівторок";
  weekday[3] = "Середа";
  weekday[4] = "Четверг";
  weekday[5] = "Пятниця";
  weekday[6] = "Субота";

  const dateFormat = date.match(regEx) ? `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}` :`${dateParts[0]}.${dateParts[1]}.${dateParts[2]}`;

  if (
    dateObject.getTime() ===
    new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime()
  ) {
    return `Сьогодні ${dateFormat}`;
  }

  return `${weekday[getday]} ${dateFormat}`;
}
