import moment from "moment";

import store from "store/index";

import { CashierTable } from "api/index";
import { DEF_SORT, ASC_SORT, DESC_SORT } from "utils/constants/sort.constants";
import GenerateDate from "utils/helpers/date.helpers";
import {
  TABLE_SET_SERVER,
  TABLE_SET_LOCAL,
  TABLE_LOAD,
  TABLE_SERVER_LOAD,
  TABLE_CHANGE_BUTTON,
  TABLE_CHANGE_DATE,
  TABLE_SORT_TYPE,
  TABLE_SET_HEADER,
  TABLE_SET_SEARCH,
  TABLE_RESET,
  TABLE_SET_FILTER_TRANSACTION,
  TABLE_SET_FILTER_CARDS,
  TABLE_SET_FILTER_CASH,
  TABLE_SET_FILTER_TOTAL,
  TABLE_SET_FILTER_TRANSACTION_VAL,
  TABLE_SET_FILTER_CARDS_VAL,
  TABLE_SET_FILTER_CASH_VAL,
  TABLE_SET_FILTER_TOTAL_VAL,
  TABLE_SET_SEARCH_VAL,
  TABLE_SET_SERVER_TERMINAL,
  TABLE_SET_LOCAL_TERMINAL,
  TABLE_SET_FILTER_TERMINAL_ID,
  TABLE_SET_FILTER_TERMINAL_DESCRIBE,
  TABLE_SET_FILTER_TERMINAL_NAME,
  TABLE_SET_FILTER_TERMINAL_DEVICE,
  TABLE_SET_FILTER_TERMINAL_ID_VAL,
  TABLE_SET_FILTER_TERMINAL_DESCRIBE_VAL,
  TABLE_SET_FILTER_TERMINAL_NAME_VAL,
  TABLE_SET_FILTER_TERMINAL_DEVICE_VAL,
  TABLE_SERVER_LOAD_TERMINAL,
  TABLE_SET_SEARCH_TERMINAL,
  TABLE_SET_ITEM,
  TABLE_SET_RESPONSE,
  TABLE_SORT_NAME,
  TABLE_SORT_DATE,
  TABLE_SORT_PHONE,
  TABLE_SORT_FISCAL,
  TABLE_SORT_STATUS,
  TABLE_SET_CASHIER,
  TABLE_SET_FILTER_NAME_VAL,
  TABLE_SET_FILTER_LAST_LOGIN_VAL,
  TABLE_SET_FILTER_KEY_EXPIRES_VAL,
  TABLE_SET_FILTER_PHONE_VAL,
  TABLE_SET_FILTER_FISCAL_VAL,
  TABLE_CLEAR_CASHIER_KEY,
} from "./cashier_table.types";
import { SET_CASHIERS } from "../main_table/main_table.types";

export const sortColumnCashier = (key, type, fn) => (dispatch, getState) => {
  const {
    cashier_table: { state_local_terminal, state_server_terminal },
  } = getState();

  let sortState, sortType;
  switch (type) {
    case DEF_SORT:
      sortState = state_local_terminal.map((item) => {
        const sortItems = item.items.sort((a, b) => (a[key] > b[key] ? 1 : -1));
        return { ...item, items: sortItems };
      });
      sortType = ASC_SORT;
      break;
    case ASC_SORT:
      sortState = state_local_terminal.map((item) => {
        const sortItems = item.items.sort((a, b) => (a[key] > b[key] ? -1 : 1));
        return { ...item, items: sortItems };
      });
      sortType = DESC_SORT;
      break;

    default:
      sortState = [...state_server_terminal];
      sortType = DEF_SORT;
      break;
  }
  dispatch(setLocalCashier(sortState));
  dispatch(fn(sortType));
};

export function groupByStatus(state) {
  const main_cashier = state.filter((item) => item.role === "MAIN_CACHIER");
  const active_cashier = state.filter(
    (item) =>
      (item.status === "VALIDATED" || item.status === "ACTIVE") &&
      item.role !== "MAIN_CACHIER"
  );
  const passive_cashier = state.filter(
    (item) =>
      (item.status === "SUSPENDED" || item.status === "DELETED") &&
      item.role !== "MAIN_CACHIER"
  );

  const main = { status: "Головний касир", items: main_cashier };
  const active = { status: "Активні касири", items: active_cashier };
  const passive = { status: "Заблоковані касири", items: passive_cashier };

  const vars = [main, active, passive];
  return generateVars(vars);
}

function generateVars(vars) {
  let generateState = [];

  for (let i = 0; i < vars.length; i++) {
    const el = vars[i];
    if (el.items.length !== 0) generateState.push(el);
  }

  return generateState;
}

function initServer() {
  const server = new CashierTable();

  return server.get().then(async (response) => {
    response = await Promise.all(
      (response?.users || []).map(async (item) => {
        const ctime = item.last_logon ? item.last_logon.split(" ") : "-";
        const regex = new RegExp("/", "g");
        const date = ctime[0].replace(regex, ".");

        const api = new CashierTable();
        const photoUrl = await api.getPhoto({ login: item.app_login });
        const { errorCode, keyId, validTo } = await api.getKey(item.client_id);

        return {
          ...item,
          last_logon: item.last_logon ? `${date} ${ctime[1].slice(0, 5)}` : "-",
          url: photoUrl,
          key_expires:
            errorCode === 0 && keyId
              ? moment(validTo).format("DD.MM.YYYY HH:mm:ss")
              : null,
        };
      })
    );

    store.dispatch(setGlobalCashiers(response));

    return response;
  });
}

export const fetchServer = (current) => async (dispatch) => {
  const getCashier = (res) =>
    res.filter(
      (item) => item.role === "CACHIER" || item.role === "MAIN_CACHIER"
    );

  const generateState = (res) => {
    const cashier = getCashier(res);
    dispatch(setResponse(res));
    const state = groupByStatus(cashier);
    dispatch(setServerCashier(state));
    dispatch(setLocalCashier(state));
    dispatch(setSearchCashier(res.map((item) => item.user_name)));
    dispatch(loadStateServerTerminal());

    if (current) {
      const updatedCurrent = res.find(
        (item) => item.client_id === current.client_id
      );

      if (updatedCurrent) {
        dispatch(setItem(updatedCurrent));
      }
    }
  };

  initServer().then((res) => {
    generateState(res);
  });
};

export const filterByStatus = (val) => (dispatch, getState) => {
  const { search_transactions_val, state_server_terminal } =
    getState().cashier_table;

  if (search_transactions_val) {
    let state_server = state_server_terminal.filter((item) =>
      val
        ? item.status !== "Заблоковані касири"
        : item.status === "Заблоковані касири"
    );
    dispatch(setLocalCashier(state_server));
  } else {
    dispatch(setLocalCashier(state_server_terminal));
  }
};

const generationTerminalHeadersValues = (state) => (dispatch) => {
  const state_search_last_logon = state
    .filter((item) => item.last_logon !== null)
    .map((item) => item.last_logon.split(" ")[0]);
  const state_search_phone = state
    .filter((item) => item.phone !== null)
    .map((item) => item.phone.slice(1));

  dispatch(setSearchName(state_search_last_logon));
  dispatch(setSearchDevice(state_search_phone));
};

const generationHeadersValues = (state) => (dispatch) => {
  const state_search_email = state.map((item) => item.app_login);
  const state_search_phone = state.map((item) => item.phone.slice(1));

  dispatch(setSearchCash(state_search_email));
  dispatch(setSearchTotals(state_search_phone));
};

export const setServer = (state) => ({
  type: TABLE_SET_SERVER,
  payload: state,
});

export const setLocal = (state) => ({
  type: TABLE_SET_LOCAL,
  payload: state,
});
export const setServerCashier = (state) => ({
  type: TABLE_SET_SERVER_TERMINAL,
  payload: state,
});

export const setLocalCashier = (state) => ({
  type: TABLE_SET_LOCAL_TERMINAL,
  payload: state,
});
export const setResponse = (state) => ({
  type: TABLE_SET_RESPONSE,
  payload: state,
});

export const loadState = () => ({
  type: TABLE_LOAD,
});

export const loadStateServer = () => ({
  type: TABLE_SERVER_LOAD,
});
export const loadStateServerTerminal = () => ({
  type: TABLE_SERVER_LOAD_TERMINAL,
});

export const setItem = (item) => ({
  type: TABLE_SET_ITEM,
  payload: item,
});
export const setActiveButton = (id) => ({
  type: TABLE_CHANGE_BUTTON,
  payload: id,
});

export const setDates = (dates) => ({
  type: TABLE_CHANGE_DATE,
  payload: dates,
});

export const sortColumn = () => (dispatch, getState) => {
  const state = getState().main_table;
  let sortState, sortType;
  switch (state.sortType) {
    case DEF_SORT:
      sortState = state.state_local.sort((a, b) =>
        a.ptdesctiption > b.ptdesctiption ? 1 : -1
      );
      sortType = ASC_SORT;
      break;
    case ASC_SORT:
      sortState = state.state_local.sort((a, b) =>
        b.ptdesctiption > a.ptdesctiption ? 1 : -1
      );
      sortType = DESC_SORT;
      break;

    default:
      sortState = [...state.state_server];
      sortType = DEF_SORT;
      break;
  }
  dispatch(setLocal(sortState));
  dispatch(setSortType(sortType));
};
export const setSortType = (type) => ({
  type: TABLE_SORT_TYPE,
  payload: type,
});
// export const setSortTypeTerminal = (type) => ({
//   type: TABLE_SORT_TYPE_TERMINAL,
//   payload: type,
// });
export const setSortTypeName = (type) => ({
  type: TABLE_SORT_NAME,
  payload: type,
});
export const setSortTypeDate = (type) => ({
  type: TABLE_SORT_DATE,
  payload: type,
});
export const setSortTypePhone = (type) => ({
  type: TABLE_SORT_PHONE,
  payload: type,
});
export const setSortTypeFiscal = (type) => ({
  type: TABLE_SORT_FISCAL,
  payload: type,
});
export const setSortTypeStatus = (type) => ({
  type: TABLE_SORT_STATUS,
  payload: type,
});

export const setHeaders = (headers) => ({
  type: TABLE_SET_HEADER,
  payload: headers,
});

export const setSearch = (state) => ({
  type: TABLE_SET_SEARCH,
  payload: state,
});

export const setSearchTerminal = (state) => ({
  type: TABLE_SET_SEARCH_TERMINAL,
  payload: state,
});

export const clearKey = (cashier) => ({
  type: TABLE_CLEAR_CASHIER_KEY,
  payload: cashier,
});

export const filterTable = (key, val) => (dispatch, getState) => {
  const { state_local } = getState().cashier_table;

  val = val.toString().slice(0, 2) === "38" ? "+" + val : val;

  switch (key) {
    // case "ptdesctiption":
    //   dispatch(setSearchVal(val));
    //   break;
    // case "transactions_count":
    //   dispatch(setSearchTransactionsVal(val));
    //   break;
    // case "card_amount":
    //   dispatch(setSearchCardsVal(val));
    //   break;
    case "app_login":
      dispatch(setSearchCashVal(val));
      break;
    case "phone":
      dispatch(setSearchTotalsVal(val));
      break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  let filter_state = filterState(key, val, state_local);
  const headers = filter_state.map((item) => item.items).flat(1);

  dispatch(generationHeadersValues(headers));
  dispatch(setLocal(filter_state));
};

// const filterState = (key, val, state) =>
//   state
//     .map((item) => {
//       const items = item.items.filter((trans) => {
//         switch (key) {
//           case "last_logon":
//             return trans[key]
//               ? trans[key].toString().split(" ")[0] === val.toString()
//               : null;

//           default:
//             return trans[key].toString() === val.toString();
//         }
//       });
//       if (items.length > 0) return { status: item.status, items };
//       return undefined;
//     })
//     .filter((item) => item !== undefined);

const filterState = (key, val, state) =>
  state.filter((item) => {
    if (!val) return true;

    const search = item[key]
      ?.toString()
      .toLowerCase()
      .includes(val.toLowerCase());
    return search;
  });

export const resetFilterCashier = (val) => (dispatch, getState) => {
  let {
    search_name_cashier,
    search_phone_cashier,
    search_fiscal_cashier,
    search_last_cashier,
    search_key_expires,
    response,
  } = getState().cashier_table;

  switch (val) {
    case "user_name":
      dispatch(setSearchCashierName(null));
      search_name_cashier = null;
      break;
    case "last_logon":
      dispatch(setSearchCashierLastLogin(null));
      search_last_cashier = null;
      break;
    case "phone":
      dispatch(setSearchCashierPhone(null));
      search_phone_cashier = null;
      break;
    case "fiskal":
      dispatch(setSearchCashierFiscal(null));
      search_fiscal_cashier = null;
      break;
    case "key_expires":
      dispatch(setSearchCashierKeyExpires(null));
      search_key_expires = null;
      break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  let filter_state = response;
  const generateStateByKey = (key, val) => filterState(key, val, filter_state);

  if (search_name_cashier)
    filter_state = generateStateByKey("user_name", search_name_cashier);
  if (search_phone_cashier)
    filter_state = generateStateByKey("phone", search_phone_cashier);
  if (search_fiscal_cashier)
    filter_state = generateStateByKey("fiskal", search_fiscal_cashier);
  if (search_last_cashier)
    filter_state = generateStateByKey("last_logon", search_last_cashier);
  if (search_key_expires)
    filter_state = generateStateByKey("key_expires", search_key_expires);

  // dispatch(loadStateServerTerminal());
  const state = groupByStatus(filter_state);
  dispatch(setServerCashier(state));
  dispatch(setLocalCashier(state));
  dispatch(loadStateServerTerminal());
};

export const filterTableCashier = (key, val) => (dispatch, getState) => {
  const { state_local_terminal } = getState().cashier_table;
  const response = state_local_terminal.map((item) => item.items).flat(1);

  switch (key) {
    case "user_name":
      dispatch(setSearchCashierName(val));
      break;
    case "key_expires":
      {
        const generate = GenerateDate();
        const date = generate.format(val);
        val = `${date.slice(6, 8)}.${date.slice(4, 6)}.${date.slice(0, 4)}`;
        dispatch(setSearchCashierKeyExpires(val));
      }
      break;
    case "last_logon":
      const generate = GenerateDate();
      const date = generate.format(val);
      val = `${date.slice(6, 8)}.${date.slice(4, 6)}.${date.slice(0, 4)}`;
      dispatch(setSearchCashierLastLogin(val));
      break;
    case "phone":
      dispatch(setSearchCashierPhone(val));
      break;
    case "fiskal":
      dispatch(setSearchCashierFiscal(val));
      break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  let filter_state;

  if (key !== "user_name") {
    filter_state = filterState(key, val, response);
  } else {
    filter_state = response.filter((item) => {
      const search_item = new RegExp(val, "i");
      const search = item[key].toString().search(search_item);
      const search_login = item.app_login.toString().search(search_item);
      return search !== -1 || search_login !== -1;
    });
  }

  // dispatch(loadStateServerTerminal());
  const state = groupByStatus(filter_state);
  dispatch(setServerCashier(state));
  dispatch(setLocalCashier(state));
  dispatch(loadStateServerTerminal());
};

export const filterTableTerminal = (key, val) => (dispatch, getState) => {
  const { state_local_terminal } = getState().cashier_table;

  val = val.toString().slice(0, 2) === "38" ? "+" + val : val;

  switch (key) {
    case "last_logon":
      dispatch(setSearchNameVal(val));
      break;
    // case "ptname":
    //   dispatch(setSearchIdVal(val));
    //   break;
    case "phone":
      dispatch(setSearchDeviceVal(val));
      break;
    // case "ptaddress":
    //   dispatch(setSearchDescribeVal(val));
    //   break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  let filter_state = filterState(key, val, state_local_terminal);

  const headers = filter_state.map((item) => item.items).flat(1);
  dispatch(generationTerminalHeadersValues(headers));

  dispatch(setLocalCashier(filter_state));
};

export const resetTable = () => ({ type: TABLE_RESET });

export const resetFilterTerminal = (val) => (dispatch, getState) => {
  switch (val) {
    case "last_logon":
      dispatch(setSearchNameVal(null));
      break;
    // case "ptname":
    //   dispatch(setSearchIdVal(null));
    //   break;
    case "phone":
      dispatch(setSearchDeviceVal(null));
      break;
    // case "ptaddress":
    //   dispatch(setSearchDescribeVal(null));
    //   break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  const {
    // search_describe_val_terminal,
    search_device_val_terminal,
    search_name_val_terminal,
    // search_id_val_terminal,
    state_server_terminal,
  } = getState().cashier_table;

  let filter_state = state_server_terminal;
  const generateStateByKey = (key, val) => filterState(key, val, filter_state);

  if (search_name_val_terminal)
    filter_state = generateStateByKey(
      "ptdesctiption",
      search_name_val_terminal
    );
  // if (search_id_val_terminal)
  //   filter_state = generateStateByKey("ptname", search_id_val_terminal);
  if (search_device_val_terminal)
    filter_state = generateStateByKey(
      "device_model",
      search_device_val_terminal
    );
  // if (search_describe_val_terminal)
  //   filter_state = generateStateByKey(
  //     "ptaddress",
  //     search_describe_val_terminal
  //   );
  const headers = filter_state.map((item) => item.items).flat(1);
  dispatch(generationTerminalHeadersValues(headers));

  dispatch(setLocalCashier(filter_state));
};

export const resetFilter = (value) => (dispatch, getState) => {
  switch (value) {
    case "app_login":
      dispatch(setSearchCashVal(null));
      break;
    case "phone":
      dispatch(setSearchTotalsVal(null));
      break;

    default:
      throw new Error("this type not exist in resetFilter!");
  }

  const { search_cash_val, search_totals_val, state_server } =
    getState().cashier_table;

  let filter_state = state_server;

  if (search_cash_val)
    filter_state = filterState("app_login", search_cash_val, filter_state);
  if (search_totals_val)
    filter_state = filterState("phone", search_totals_val, filter_state);

  dispatch(
    generationHeadersValues(filter_state.map((item) => item.items).flat(1))
  );

  dispatch(setLocal(filter_state));
};

export const setSearchCards = (state) => ({
  type: TABLE_SET_FILTER_CARDS,
  payload: state,
});
export const setSearchTransactions = (state) => ({
  type: TABLE_SET_FILTER_TRANSACTION,
  payload: state,
});
export const setSearchCash = (state) => ({
  type: TABLE_SET_FILTER_CASH,
  payload: state,
});
export const setSearchTotals = (state) => ({
  type: TABLE_SET_FILTER_TOTAL,
  payload: state,
});

export const setSearchCardsVal = (state) => ({
  type: TABLE_SET_FILTER_CARDS_VAL,
  payload: state,
});
export const setSearchTransactionsVal = (state) => ({
  type: TABLE_SET_FILTER_TRANSACTION_VAL,
  payload: state,
});
export const setSearchCashVal = (state) => ({
  type: TABLE_SET_FILTER_CASH_VAL,
  payload: state,
});
export const setSearchTotalsVal = (state) => ({
  type: TABLE_SET_FILTER_TOTAL_VAL,
  payload: state,
});

export const setSearchId = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_ID,
  payload: state,
});
export const setSearchDescribe = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_DESCRIBE,
  payload: state,
});
export const setSearchName = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_NAME,
  payload: state,
});
export const setSearchDevice = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_DEVICE,
  payload: state,
});

export const setSearchIdVal = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_ID_VAL,
  payload: state,
});
export const setSearchDescribeVal = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_DESCRIBE_VAL,
  payload: state,
});
export const setSearchNameVal = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_NAME_VAL,
  payload: state,
});
export const setSearchDeviceVal = (state) => ({
  type: TABLE_SET_FILTER_TERMINAL_DEVICE_VAL,
  payload: state,
});

export const setSearchVal = (state) => ({
  type: TABLE_SET_SEARCH_VAL,
  payload: state,
});

export const setSearchCashier = (state) => ({
  type: TABLE_SET_CASHIER,
  payload: state,
});
export const setSearchCashierName = (state) => ({
  type: TABLE_SET_FILTER_NAME_VAL,
  payload: state,
});
export const setSearchCashierPhone = (state) => ({
  type: TABLE_SET_FILTER_PHONE_VAL,
  payload: state,
});
export const setSearchCashierLastLogin = (state) => ({
  type: TABLE_SET_FILTER_LAST_LOGIN_VAL,
  payload: state,
});
export const setSearchCashierKeyExpires = (state) => ({
  type: TABLE_SET_FILTER_KEY_EXPIRES_VAL,
  payload: state,
});
export const setSearchCashierFiscal = (state) => ({
  type: TABLE_SET_FILTER_FISCAL_VAL,
  payload: state,
});

export const setGlobalCashiers = (state) => ({
  type: SET_CASHIERS,
  payload: state,
});
