import {
  Textarea,
  TextFieldContainer,
  SubTitle,
} from "../TextField/TextField.styles";

export default function TextField({
  name,
  placeholder,
  value,
  fn,
  disabled,
  error,
  className,
  fnFocus,
  style,
}) {
  return (
    <TextFieldContainer>
      <SubTitle error={error}>{name}</SubTitle>
      <Textarea
        className={className}
        type="text"
        value={value || ""}
        placeholder={placeholder}
        onChange={(e) => fn(e.target.value)}
        disabled={disabled}
        error={error}
        onFocus={fnFocus}
        style={style}
      />
    </TextFieldContainer>
  );
}
