import { useState, useEffect } from "react";
import {
  Th,
  TableIcon,
  TableInput,
  TableForm,
  TableFormResult,
  TableIconClose,
} from "../../Table.styles";

import { ReactComponent as CloseIcon } from "assets/icons/close_white.svg";

export default function TableHeaderSearch({
  title,
  fn,
  fn_reset,
  icon,
  choose_state = null,
  formatFunction,
}) {
  const [val, setVal] = useState(choose_state === null ? "" : choose_state);
  const [active, setActive] = useState(
    choose_state !== null && choose_state !== ""
  );

  function search(e) {
    if (formatFunction) {
      const newValue = formatFunction({
        newValue: e.target.value,
        oldValue: val,
      });
      setVal(newValue);
      return;
    }

    setVal(e.target.value);
  }

  function chooseItem() {
    setActive(true);
    setVal(val);
    fn(val);
  }

  function close() {
    fn_reset();
    setActive(false);
    setVal("");
  }

  function generateResult(e) {
    if (e.key === "Enter") {
      if (val.length !== 0) {
        chooseItem();
        return;
      }
      close();
    }
  }

  useEffect(() => {
    if (choose_state === null || choose_state === "") {
      setVal("");
      setActive(false);
    }
  }, [choose_state]);

  return (
    <Th active={active}>
      <TableIcon>
        <img src={icon} alt="icon" />
      </TableIcon>

      <TableInput
        padding={icon === null}
        placeholder={title}
        onChange={search}
        value={val}
        onKeyPress={generateResult}
      />

      <TableForm active={active}>
        <TableFormResult
          style={{
            maxWidth: "calc(100% - 10px)",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {val}
        </TableFormResult>
        <TableIconClose onClick={close}>
          <CloseIcon />
        </TableIconClose>
      </TableForm>
    </Th>
  );
}
