import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from 'utils/helpers/auth.helpers';
import { DASHBOARD_ROUTE } from 'utils/constants/routes.constants';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() && restricted ? <Redirect to={DASHBOARD_ROUTE} /> : <Component {...props} />
      }
    />
  );
};

export default PublicRoute;
