import React, { useState } from "react";

import Preloader from "components/additional/PreLoader/Preloader";
import Modal from "ui/Modal/Modal";
import ReportsPreloaderModal from "./ReportsPreloaderModal/ReportsPreloaderModal";
import { ReportsContainer, DownloadContainer } from "./styles";
import { ReactComponent as DownloadIcon } from "assets/icons/download_green.svg";
import { TRAN_REPORT_TYPE as REPORT_TYPE } from "../../utils/constants/ui";

const REPORT_FORMAT = {
  EXCEL: "EXCEL",
  PDF: "PDF",
};

const ReportsLoader = ({ handleExcelLoad, handlePdfLoad }) => {
  const [excelLoading, setExcelLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState({
    showModal: false,
    loadingFunc: null,
    reportFormat: "",
  });

  const getPreloader = () => (
    <Preloader style={{ marginTop: "-10px", marginRight: "20px" }} />
  );

  const excelLoadFunction = async (reportType) => {
    setExcelLoading(true);

    await handleExcelLoad(reportType === REPORT_TYPE.EXTENDED);

    setExcelLoading(false);
  };

  const pdfLoadFunction = async (reportType) => {
    setPdfLoading(true);

    await handlePdfLoad(reportType === REPORT_TYPE.EXTENDED);

    setPdfLoading(false);
  };

  const onCancel = () =>
    setLoadingDetails({
      showModal: false,
      loadingFunc: null,
      reportFormat: "",
    });

  const onExport = (reportType) => {
    loadingDetails.loadingFunc(reportType);
    onCancel();
  };

  return (
    <ReportsContainer>
      <DownloadContainer
        style={excelLoading ? { cursor: "default" } : null}
        onClick={() => {
          if (excelLoading) return;

          setLoadingDetails({
            showModal: true,
            loadingFunc: excelLoadFunction,
            reportFormat: REPORT_FORMAT.EXCEL,
          });
        }}
      >
        {excelLoading && getPreloader()}
        {!excelLoading && (
          <>
            <DownloadIcon />
            <span>Звіт у xlsx-файлі</span>
          </>
        )}
      </DownloadContainer>
      <DownloadContainer
        style={pdfLoading ? { cursor: "default" } : null}
        onClick={() => {
          if (pdfLoading) return;

          setLoadingDetails({
            showModal: true,
            loadingFunc: pdfLoadFunction,
            reportFormat: REPORT_FORMAT.PDF,
          });
        }}
      >
        {pdfLoading && getPreloader()}
        {!pdfLoading && (
          <>
            <DownloadIcon />
            <span>Звіт у pdf-файлі</span>
          </>
        )}
      </DownloadContainer>
      {loadingDetails.showModal && (
        <Modal closeOnBackdrop={false} className="reports-preloader">
          <ReportsPreloaderModal
            title="Формат звіту"
            subtitle={`Виберіть формат ${
              loadingDetails.reportFormat === REPORT_FORMAT.PDF ? "pdf" : "xlsx"
            }-звіту`}
            closeOnBackdrop={false}
            onCancel={onCancel}
            onExport={onExport}
          />
        </Modal>
      )}
    </ReportsContainer>
  );
};

export default ReportsLoader;
