export const STATUS_VALUES = [
  "Успішні",
  "Повернення",
  "Скасовані",
  "Не успішні",
  "Очікування оплати",
  // "Карткою",
  // "Готівка",
  // "Безготівкові",
  // "Фіскалізовані",
  // "Не фіскалізовані",
  // "Службова видача",
  // "Службове внесення",
];

export const generateStatusValueMap = ({ value, isHumanty }) => {
  const humanityMap =
    value && value.length !== 0 && isHumanty
      ? value
          .map(
            (el) =>
              ({
                Успішні: "A",
                Карткою: 1,
                Повернення: 2,
                "Не успішні": 3,
                Готівка: 4,
                Безготівкові: 9,
                Фіскалізовані: 5,
                "Не фіскалізовані": 6,
                "Службова видача": 7,
                "Службове внесення": 8,
                Скасовані: "B",
                "Очікування оплати": "D",
              }[el])
          )
          .join("")
      : null;

  const requestMap =
    value && !isHumanty
      ? value.split("").map(
          (el) =>
            ({
              1: "Карткою",
              2: "Повернення",
              3: "З помилками",
              4: "Готівка",
              9: "Безготівкові",
              5: "Фіскалізовані",
              6: "Не фіскалізовані",
              7: "Службова видача",
              8: "Службове внесення",
              A: "Успішні",
              B: "Скасовані",
            }[el])
        )
      : null;

  const mapper = isHumanty ? humanityMap : requestMap;
  return mapper;
};

export const FISCAL_STATUS = {
  1: "Без фіскалізації",
  2: "Не фіскалізована",
  3: "Помилка фіскалізації",
  4: "Фіскалізована",
};

export const FISCAL_STATUS_VALUES = [
  FISCAL_STATUS[1],
  FISCAL_STATUS[2],
  FISCAL_STATUS[3],
  FISCAL_STATUS[4],
];

export const generateFiscalStatusValueMap = (value, isHumanty) => {
  const humanityMap =
    value?.length !== 0 && isHumanty
      ? value
          .map(
            (el) =>
              ({
                [FISCAL_STATUS[1]]: 1,
                [FISCAL_STATUS[2]]: 2,
                [FISCAL_STATUS[3]]: 3,
                [FISCAL_STATUS[4]]: 4,
              }[el])
          )
          .join("")
      : null;

  const requestMap =
    value && !isHumanty
      ? value.split("").map(
          (el) =>
            ({
              1: FISCAL_STATUS[1],
              2: FISCAL_STATUS[2],
              3: FISCAL_STATUS[3],
              4: FISCAL_STATUS[4],
            }[el])
        )
      : null;

  const mapper = isHumanty ? humanityMap : requestMap;
  return mapper;
};

export const getFiscalStatus = (tran) => {
  const { fiskal_failed, transaction_state, fiskal_number, dfs_error } = tran;

  if (!!fiskal_failed) {
    if (
      fiskal_number === null &&
      dfs_error === null &&
      transaction_state === 1
    ) {
      return "1";
    }

    return "3";
  }

  if (fiskal_number && fiskal_number !== "" && Number(fiskal_number) !== 0) {
    return "4";
  }

  return "2";
};
