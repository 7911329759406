import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  ProfileContainer,
  ProfileHeader,
  ProfileInformation,
  ProfileImg,
  ProfileMenu,
  ProfileList,
  Li,
  Icon,
  LiText,
} from "./Profile.styles";
import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import { ReactComponent as Exit } from "assets/icons/exit.svg";

import { AUTH_ROUTE, DASHBOARD_ROUTE } from "utils/constants/routes.constants";

import {
  fetchServer,
  setItem,
} from "store/cashier_table/cashier_table.actions";
import { mobileVersion as rMobileVersion } from "store/main_table/main_table.slicer";
import { stateLocalTerminal } from "store/cashier_table/cashier_table.slicer";

import { Authentication, CashierTable, Profile as User } from "api";
import { toast } from "utils/toast/toast";
import { LocalStorage } from "services";
import { useHeader } from "store/header/header.context";
import ChangeTitle from "utils/helpers/changetitle.helpers";
import { setIsFiskalMenu } from "store/transaction_table/transaction_table.actions";
import { setProfile, setOutOfDateKeys, setKey } from "store/user/user_actions";

const Profile = () => {
  const history = useHistory();
  const reduxDispatch = useDispatch();
  const { dispatch } = useHeader();
  const { refresh } = useHeader().state;
  const profileListRef = useRef(null);

  const state = useSelector(stateLocalTerminal);
  const mobileVersion = useSelector(rMobileVersion) < 780;

  const [email, setEmail] = useState("");
  const [fop, setFop] = useState("");
  const [inn, setInn] = useState("");
  const [photo, setPhoto] = useState(null);
  const [appLogin, setAppLogin] = useState(null);
  const [mobileMenuIsActive, setMobileMenuIsActive] = useState(false);
  const [isDroppingInstant, setIsDroppingInstant] = useState(false);

  const clickHandler = () => setIsDroppingInstant(true);
  const mouseLeaveHandler = () => setIsDroppingInstant(false);

  function exitUser() {
    const auth = new Authentication();

    auth.exit().then((res) => {
      if (res?.ErrorCode === 0) {
        history.push(AUTH_ROUTE);
        window.location.reload();
      }
    });
    setMobileMenuIsActive(false);
  }

  useEffect(() => {
    const setupPhoto = async () => {
      if (appLogin) {
        const api = new CashierTable();
        const result = await api.getPhoto({ login: appLogin });
        setPhoto(result);
      }
    };
    setupPhoto();
  }, [appLogin]);

  useEffect(() => {
    if (refresh || email.length === 0) {
      const defaultProfileError = "Виникла помилка при завантаженні профіля";
      const defaultUserShopError =
        "Помилка при завантаженні магазина користувача";

      const profile = new User();
      const localstorage = new LocalStorage();
      const cashierTable = new CashierTable();

      const setExpiredKeysInfo = (merchantId, fiscal) => {
        if (fiscal) {
          cashierTable
            .getMerchantKeys(merchantId)
            .then((res) => {
              const keys = res
                .filter((item) => item.errorCode === 0)
                .map((item) => ({
                  ...item,
                  validTo: moment(item.validTo),
                }));

              reduxDispatch(setOutOfDateKeys(keys));
            })
            .catch((error) => toast.errorMessage(error.message));
        }
      };

      const setKeyInfo = (clientId, merchantId, fiscal) => {
        cashierTable
          .getKey(clientId)
          .then(async (res) => {
            if (res?.errorCode === 0) {
              const { errorCode, errorMessage, ...key } = res;

              reduxDispatch(setKey(key));
              setExpiredKeysInfo(merchantId, fiscal);
            } else {
              if (res?.errorCode !== 2 && res?.errorMessage)
                toast.errorMessage(res.errorMessage);
            }
          })
          .catch((error) => {
            if (error) toast.errorMessage(error.toString());
          });
      };

      profile
        .init()
        .then((res) => {
          const profileResult = res.data;

          if (profileResult.ErrorCode) {
            toast.errorMessage(
              profileResult.ErrorMessage || defaultProfileError
            );
            return exitUser();
          }

          const {
            app_login,
            merchant_id,
            merchant_name,
            unp,
            client_id,
            fiskal,
            global_fiskal_enabled,
          } = profileResult;
          reduxDispatch(setProfile(profileResult));
          reduxDispatch(setIsFiskalMenu(fiskal));
          localstorage.set("profile", JSON.stringify(profileResult));
          localstorage.set(
            "merchantId",
            JSON.stringify(profileResult.merchant_id)
          );

          setAppLogin(app_login);
          setKeyInfo(client_id, merchant_id, fiskal && global_fiskal_enabled);

          profile
            .getUserShops({ merchant_id: profileResult.merchant_id })
            .then((shopsRes) => {
              setEmail(app_login);
              setFop(merchant_name);
              setInn(unp);
              localstorage.set("name", merchant_name);
              localstorage.set("shopId", JSON.stringify(shopsRes.data[0].id));

              if (history.location.pathname === DASHBOARD_ROUTE)
                ChangeTitle(merchant_name, dispatch);
            })
            .catch((error) => {
              toast.errorMessage(defaultUserShopError);
            });
        })
        .catch((error) => {
          toast.errorMessage(defaultProfileError);
        });
    }
  }, [refresh]);

  useEffect(() => {
    if (state.length !== 0) {
      reduxDispatch(setItem(state[0].items[0]));
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (profileListRef.current.contains(e.target)) {
        return;
      }
      setMobileMenuIsActive(false);
    };

    if (mobileMenuIsActive) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileMenuIsActive]);

  function toProfile() {
    if (state.length === 0) {
      reduxDispatch(fetchServer());
    } else {
      const userAppLogin = JSON.parse(
        localStorage.getItem("profile")
      ).app_login;
      reduxDispatch(setItem(state[0].items[0]));
      history.push(`/dash/cashiers/${userAppLogin}`);
    }
    setMobileMenuIsActive(false);
  }

  return (
    <ProfileContainer
      className={isDroppingInstant && "menu__container"}
      onClick={clickHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <ProfileHeader
        className="header"
        onClick={mobileVersion ? () => setMobileMenuIsActive((v) => !v) : null}
      >
        {!mobileVersion && (
          <ProfileInformation>
            <p className="email">{email}</p>
            <p className="inform">
              {fop} ІПН: {inn}
            </p>
          </ProfileInformation>
        )}
        <ProfileImg src={photo} />
      </ProfileHeader>

      <ProfileMenu
        className="menu"
        ref={profileListRef}
        style={mobileMenuIsActive ? { display: "block" } : null}
      >
        <ProfileList>
          <Li onClick={toProfile}>
            <Icon>
              <ProfileIcon />
            </Icon>
            <LiText>Профіль</LiText>
          </Li>
          <Li onClick={exitUser}>
            <Icon>
              <Exit />
            </Icon>
            <LiText red>Вийти</LiText>
          </Li>
        </ProfileList>
      </ProfileMenu>
    </ProfileContainer>
  );
};

export default Profile;
