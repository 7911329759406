import styled from "styled-components";
import { Edit } from "@styled-icons/boxicons-solid";

import { IconContainer } from "ui/IconContainer/icon-container.styles";
import { grey } from "assets/style/variables";
import { font_book } from "assets/style/fonts.variables";

export const TrMain = styled.tr`
  display: grid;
  grid-template-columns: 0.7fr 0.8fr 1.5fr 1.2fr 0.8fr 50px 160px;
  grid-gap: 6px;

  th {
    text-align: left;
    color: ${grey};
    font-family: ${font_book};
    font-size: 14px;
    font-weight: 400;
  }
  ${(props) =>
    props.column
      ? `
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 12px;
    margin-top: 12px;
    grid-template-columns: 0.7fr 0.8fr 1.5fr 1.2fr 0.8fr 50px 160px;
    &:hover{
      cursor: pointer;
    }
  `
      : null}

  .calendar-container {
    left: -25vw;
  }

  .sluj-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }

  .sluj {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    background: #13877e;
    border: none;
    outline: none;
    color: #fff;
    font-family: "Futura Book", sans-serif;
    font-weight: 500;
    font-size: 14px;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.2s ease, border-radius 0.2s ease;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 10px;

    svg {
      width: 15px;
    }

    &:hover {
      background: #0c615a;
    }

    &:disabled {
      background-color: lightgray;
      cursor: default;
    }
  }
`;

export const EditIconContainer = styled(IconContainer)`
  width: 40px;
  height: 40px;
  margin-top: 15px;
  cursor: pointer;
`;

export const EditIcon = styled(Edit)`
  width: 20px;
  height: 20px;
`;
