import React from "react";

import {
  DisabledFiscalizationModalProvider,
  KeyExpirationProvider,
  LimitedWorkingModalProvider,
} from "components/initial_modals";

export const InitialModalsProvider = ({ children }) => {
  return (
    <DisabledFiscalizationModalProvider>
      <KeyExpirationProvider>
        <LimitedWorkingModalProvider>{children}</LimitedWorkingModalProvider>
      </KeyExpirationProvider>
    </DisabledFiscalizationModalProvider>
  );
};
