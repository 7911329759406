import styled from "styled-components";

import { mobileSize, size } from "assets/style/global-variables";
import { font_book, font_demi } from "assets/style/fonts.variables";
import {
  black,
  grey,
  grey_light,
  grey_light_search,
  grey_light_setting,
  red,
} from "assets/style/variables";
import { ButtonSecondaryBorder } from "components/forms/Button/Button.styles";

export const CashiersDetailContainer = styled.section`
  margin: 0;

  .image-field {
    margin-top: 20px;
  }
  .file-field {
    label {
      max-width: none;
      width: 100%;
    }
  }
  .file-field-error {
    label {
      border: 1px solid ${red};
    }
  }

  .title {
    font-size: 18px;
    color: ${black};
    margin-bottom: 5px;
  }
  .sub_title {
    font-size: 14px;
    color: ${grey};
    font-family: ${font_book};
  }
`;
export const CashiersDetailHeader = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-gap: 16px;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (min-width: ${size.laptop}) {
    grid-gap: 30px;
  }

  @media screen and (max-width: ${size.mobileL}) {
    grid-gap: 4px;
    margin-bottom: 24px;
  }
`;

export const CashButtonContainer = styled.section`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;
  align-items: baseline;

  button {
    max-width: 330px;
    font-weight: 500;
    font-size: 20px;
    font-family: ${font_demi};
    white-space: nowrap;
  }

  @media screen and (max-width: ${mobileSize.laptop}) {
    button {
      width: auto;
      padding-left: 1em;
      padding-right: 1em;
    }
  }

  @media screen and (min-width: ${size.laptop}) {
    justify-content: flex-start;

    button {
      width: default;
      padding-left: default;
      padding-right: default;
    }
  }
`;

export const CashInformationSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const KeyInformationSection = styled(CashInformationSection)`
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  .key-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 10px;
  }

  @media screen and (max-width: ${mobileSize.laptop}) {
    flex-wrap: wrap;
    row-gap: 30px;
  }
`;

export const CashInformationItem = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 15px;
  font-weight: 400;
  font-family: ${font_book};
`;

export const MainCashierCheckInformationItem = styled(CashInformationItem)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding: 12px 0;
`;

export const MainCashierCheckNoteItem = styled.span`
  padding-left: 24px;
  font-size: 13.33px;
`;

export const FootnoteItemContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  padding-top: 1rem;
  font-size: smaller;
  font-weight: 400;
  font-family: ${font_book};
`;

export const OneLineFootnoteItem = styled.span`
  white-space: nowrap;
`;

export const FootnoteItem = styled.span``;

export const CashInformationButton = styled(ButtonSecondaryBorder)`
  max-width: 120px;
  padding: 8px 0;
`;

export const SwitchContainer = styled.div`
  width: 100%;
  padding-left: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  margin-top: 25px;

  span {
    font-family: "Futura Book", sans-serif;
    font-weight: 500;
    font-size: 18px;
  }
`;

export const CashierSettingPanel = styled.section`
  background: ${grey_light_setting};
  border-radius: 22px;
  border: 1px solid ${grey_light_search};
  padding-bottom: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;

  @media screen and (min-width: ${size.laptop}) {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .cashier-setting-container {
      &:first-child {
        border-right: 1px solid ${grey_light};
      }
    }
  }
`;
