import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// functions
import { useHeader } from "store/header/header.context";
import { FiskalTable } from "api";
import ChangeTitle from "utils/helpers/changetitle.helpers";
import { setProfile } from "store/user/user_actions";
import { setIsFiskalMenu } from "store/transaction_table/transaction_table.actions";
import { mobileVersion as rMobileVersion } from "store/main_table/main_table.slicer";
import { LocalStorage } from "services";
import { validateDisablePRRO } from "api/global-requests";
import {
  // FISCAL_INSTRUCTION_HREF,
  DISABLED_FISCALIZATION_MODAL_WARNING,
  DISABLED_FISCALIZATION,
  FISCAL_INFORMATION_TEXT_1,
  FISCAL_INFORMATION_TEXT_2,
} from "../../../utils/constants/server.constants";

// components
import { TextField } from "components/forms";
// import FileLink from "components/forms/FileLink/FileLink";
import Alert from "ui/Alert/Alert";
import Preloader from "components/additional/PreLoader/Preloader";
import Modal from "ui/Modal/Modal";
import OpenFiscalModal from "./components/OpenFiscalModal/OpenFiscalModal";
import FiscalOnSubtitle from "./components/FiscalOnSubtitle/FiscalOnSubtitle";

// styles
import {
  FopContainer,
  TaxTitle,
  ButtonTax,
  FopText,
  FopRightInformationContainter,
} from "./Fop.styles";
import { SettingPanel, SettingPanelContainer } from "assets/style/variables";

const Fop = () => {
  const headerDispatch = useHeader().dispatch;
  const dispatch = useDispatch();
  const mobileVersion = useSelector(rMobileVersion) < 780;
  const [fopName, setFopName] = useState("");
  const [inn, setInn] = useState("-");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [text, setText] = useState(FISCAL_INFORMATION_TEXT_1);
  const [buttonText, setButtonText] = useState("Увімкнути");
  const [isFiskal, setIsFiskal] = useState(false);
  const [isFiskalGlobal, setIsFiskalGlobal] = useState(false);
  const [fiskalLoading, setFiskalLoading] = useState(false);
  const fiskalAPI = new FiskalTable();

  const [handleFn, setHandleFn] = useState(() => {});
  const [dateRegistration, setDateRegistration] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [type, setType] = useState("done");
  const [titleAlert, setTitleAlert] = useState(null);
  const [subTitleAlert, setSubTitleAlert] = useState(null);

  useEffect(() => {
    ChangeTitle("Налаштування Торговця", headerDispatch);
    const localstore = new LocalStorage();
    const data = JSON.parse(localstore.get("profile"));
    if (data.unp) {
      setInn(data.unp);
    }
    if (data.created_at) {
      data.created_at = `${data.created_at.slice(0, 4)}.${data.created_at.slice(
        4,
        6
      )}.${data.created_at.slice(6, 8)}`;
    }
    setDateRegistration(
      data.created_at ? moment(data.created_at).format("DD.MM.YYYY") : ""
    );
    setIsFiskalGlobal(data.global_fiskal_enabled);
    setIsFiskal(data.fiskal);

    if (data.fiskal) {
      setText(FISCAL_INFORMATION_TEXT_2);
      setButtonText("Вимкнути");
    }

    setFopName(localstore.get("name"));
  }, []);

  function fiskalOn() {
    setModalIsOpen(false);
    setFiskalLoading(true);
    fiskalAPI
      .turnOn()
      .then(() => {
        setIsFiskal(true);
        setText(FISCAL_INFORMATION_TEXT_2);
        setButtonText("Вимкнути");
        setType("done");
        setTitleAlert(`Фіскалізацію ввімкнено`);
        setSubTitleAlert(FiscalOnSubtitle);
        setShowAlert(true);
        const userData = JSON.parse(localStorage.getItem("profile"));
        userData.fiskal = true;
        dispatch(setIsFiskalMenu(true));

        localStorage.setItem("profile", JSON.stringify(userData));
        dispatch(setProfile(userData));
      })
      .finally(() => setFiskalLoading(false));
  }

  async function prepareFiskal() {
    if (isFiskal) {
      // check if user doesnt have active fiskal day
      const checkResult = await validateDisablePRRO({ fiskal_disable: 1 });

      if (!checkResult.success) {
        setType("block");
        setTitleAlert(checkResult.titleMessage);
        setSubTitleAlert(checkResult.subtitleMessage);
        setShowAlert(true);
        return;
      }

      setHandleFn(() => fiskalOff);
      setType("remove");
      setTitleAlert(`Вимкнути фіскалізацію?`);
      setSubTitleAlert(null);
      setShowAlert(true);
      return;
    }

    setModalIsOpen(true);
    return;
  }

  function fiskalOff() {
    setShowAlert(false);
    setFiskalLoading(true);
    fiskalAPI
      .turnOff()
      .then(() => {
        setType("block");
        setTitleAlert(`Фіскалізацію вимкнено`);
        setSubTitleAlert(null);
        setShowAlert(true);
        setIsFiskal(false);
        setText(FISCAL_INFORMATION_TEXT_1);
        setButtonText("Увімкнути");
        const userData = JSON.parse(localStorage.getItem("profile"));
        userData.fiskal = false;

        dispatch(setIsFiskalMenu(false));

        localStorage.setItem("profile", JSON.stringify(userData));
        dispatch(setProfile(userData));
      })
      .finally(() => setFiskalLoading(false));
  }

  return (
    <FopContainer>
      {modalIsOpen && (
        <Modal
          onClose={() => setModalIsOpen(false)}
          className="open-fiscal-mode"
        >
          <OpenFiscalModal
            onClose={() => setModalIsOpen(false)}
            onAccept={fiskalOn}
          />
        </Modal>
      )}
      {showAlert && (
        <Alert
          type={type}
          title={titleAlert}
          subtitle={subTitleAlert}
          fnClose={() => setShowAlert(false)}
          fnSubmit={handleFn}
          btn_close={"вимкнути"}
          height={
            subTitleAlert && typeof subTitleAlert === "object"
              ? "575px"
              : undefined
          }
        />
      )}
      <SettingPanel isFlex={mobileVersion} line={mobileVersion}>
        <SettingPanelContainer disable={true}>
          <TaxTitle>Інформація про Торговця</TaxTitle>
          <TextField
            name="Назва"
            placeholder="-"
            value={fopName}
            className="input-active"
            disabled={true}
          />
          <TextField
            name="Ідентифікаційний номер"
            placeholder="110001"
            value={inn}
            className="input-active"
            disabled={true}
          />

          <TextField
            name="Адреса"
            placeholder={"100"}
            value={"-"}
            className="input-active"
            disabled={true}
          />

          <TextField
            name="Дата реєстрації в системі"
            placeholder={"100"}
            value={dateRegistration}
            className="input-active"
            disabled={true}
          />
        </SettingPanelContainer>
        <SettingPanelContainer>
          <FopRightInformationContainter>
            {isFiskalGlobal && fiskalLoading && <Preloader />}
            {isFiskalGlobal && !fiskalLoading && (
              <>
                <TaxTitle>Фіскалізація</TaxTitle>
                <FopText>{text}</FopText>
              </>
            )}
            {!DISABLED_FISCALIZATION && (
              <ButtonTax onClick={() => prepareFiskal()}>
                {buttonText}
              </ButtonTax>
            )}
            {/* {isFiskalGlobal && (
              <div style={{ marginTop: "15px" }}>
                <FileLink
                  href={FISCAL_INSTRUCTION_HREF}
                  caption={"Інструкція користувача ПРРО «ОщадPay»"}
                />
              </div>
            )} */}
            {DISABLED_FISCALIZATION_MODAL_WARNING && (
              <FopText style={{ marginTop: "30px", color: "#333" }}>
                З 11.05 фіскалізація в ОщадPAY буде призупинена через
                заплановані технічні роботи, решта функцій у застосунку працюють
                у звичному режимі. Про запуск оновленого функціоналу
                фіскалізації буде повідомлено додатково. Детальніше:{" "}
                <a
                  href="https://bit.ly/3vN8K2I"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://bit.ly/3vN8K2I
                </a>
              </FopText>
            )}
          </FopRightInformationContainter>
        </SettingPanelContainer>
      </SettingPanel>
    </FopContainer>
  );
};

export default Fop;
