import { useState } from "react";
import { useHistory } from "react-router";
import { Authentication } from "api";

import { DASHBOARD_ROUTE } from "utils/constants/routes.constants";
import LocalStorage from "services/LocalStorage/LocalStorage.service";
import { ErrorText } from "assets/style/variables";
import { TextFieldPassw, TextField } from "components/forms";
import { ButtonLogin } from "components/forms/Button/Button.styles";
import Preloader from "components/additional/PreLoader/Preloader";

const AuthForm = () => {
  const history = useHistory();
  const [login, setLogin] = useState("");
  const [passw, setPassw] = useState("");
  const [error, setError] = useState({ isError: false, msg: "" });
  const [blockForm, setBlockForm] = useState(false);

  const auth = new Authentication();
  const localStorage = new LocalStorage();

  const validateForms = login === "" || passw === "";

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      submit();
    }
  }

  function submit() {
    if (blockForm) return;

    if (validateForms)
      return setError({ isError: true, msg: "Заповніть всі поля!" });

    setError({ isError: false, msg: "" });
    setBlockForm(true);

    auth.login(login, passw).then((res) => {
      if (res?.ErrorCode === 0) {
        localStorage.set("token", res.token);
        localStorage.set("login", login);
        localStorage.set("node_env", res.mode);
        history.push(DASHBOARD_ROUTE);
      } else {
        setError({
          isError: true,
          msg: res?.ErrorMessage || "Помилка реєстрації",
        });
        setBlockForm(false);
      }
    });
  }

  return (
    <div onKeyPress={handleKeyPress}>
      <TextField
        name="Логін"
        placeholder="Введіть логін"
        value={login}
        fn={setLogin}
        disabled={blockForm}
        error={error.isError}
        nameInput="login"
      />
      <TextFieldPassw
        name="Пароль"
        placeholder="Введіть пароль"
        value={passw}
        fn={setPassw}
        disabled={blockForm}
        error={error.isError}
        nameInput="password"
        typePassw={true}
      />
      <ErrorText>{error.msg}</ErrorText>
      {blockForm && <Preloader />}
      {!blockForm && (
        <ButtonLogin className="login" onClick={submit}>
          Вхід
        </ButtonLogin>
      )}
    </div>
  );
};

export default AuthForm;
