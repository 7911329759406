import React, { useState, useRef } from "react";

//components
import GlobalListModal from "../GlobalListModal/GlobalListModal";

// functions
import { getUUID } from "utils/helpers/getUUID";

// assets
import { ReactComponent as SelectIcon } from "assets/icons/terminalDetail/select.svg";

// styles
import {
  GlobalSelect,
  SelectContainer,
  SelectItem,
  SelectPlaceholder,
  SelectTitle,
  SelectVal,
} from "pages/Panels/Terminals/TerminalDetail/TerminalDetail.styles";

// const PADDING_FROM_WINDOW_BORDER = 30;

const SelectComponent = (props) => {
  const { data } = props;
  const {
    placeholder,
    disabled,
    selectedCaption,
    caption,
    listInfo,
    onSelectItem,
    style,
    svgStyle,
    listStyle,
    maxHeight,
  } = data;
  const { items, data_id, data_uk, data_caption } = listInfo;

  const selectRef = useRef(getUUID());
  const [isOpened, setIsOpened] = useState(false);
  // const [position, setPosition] = useState({ top: "0px", left: "0px" });
  // const [maxHeight, setMaxHeight] = useState(0);

  // useEffect(() => {
  //   if (isOpened && selectRef && selectRef.current) {
  //     const htmlPosition = selectRef.current.getBoundingClientRect();
  //     setPosition({
  //       top: `${htmlPosition.y + htmlPosition.height}px`,
  //       left: `${htmlPosition.x}px`,
  //     });
  //     setMaxHeight(
  //       Math.floor(
  //         window.innerHeight -
  //           htmlPosition.top -
  //           htmlPosition.height -
  //           PADDING_FROM_WINDOW_BORDER -
  //           160
  //       )
  //     );
  //   }
  // }, [isOpened]);

  const showList = !disabled && isOpened;

  return (
    <GlobalSelect
      onClick={!disabled && !isOpened ? () => setIsOpened(true) : null}
      onMouseLeave={!disabled ? () => setIsOpened(false) : null}
      style={style}
      disabled={!disabled}
      ref={selectRef}
    >
      {selectedCaption && (
        <SelectVal>
          {selectedCaption}
          <SelectIcon className="select-icon" style={svgStyle} />
        </SelectVal>
      )}
      {!selectedCaption && (
        <SelectPlaceholder>
          {placeholder}
          <SelectIcon className="select-icon" style={svgStyle} />
        </SelectPlaceholder>
      )}
      <SelectTitle>{caption}</SelectTitle>
      {showList && (
        <GlobalListModal
          position={/*position*/ { top: "34px", left: 0 }}
          onOutsideRange={() => setIsOpened(false)}
          target={selectRef.current}
        >
          <SelectContainer
            show={showList}
            maxHeight={maxHeight}
            style={{
              ...listStyle,
              width: "auto",
              top: "0",
              minWidth: "65px",
            }}
          >
            {items &&
              items.length !== 0 &&
              items.map((item) => {
                return (
                  <SelectItem
                    data-id={item[data_id]}
                    data-uk={item[data_uk]}
                    key={item[data_id]}
                    onClick={() => {
                      setIsOpened(false);
                      onSelectItem(item);
                    }}
                  >
                    {item[data_caption]}
                  </SelectItem>
                );
              })}
          </SelectContainer>
        </GlobalListModal>
      )}
    </GlobalSelect>
  );
};

export default SelectComponent;
