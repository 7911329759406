import styled from "styled-components";

import { font_book, font_demi } from "assets/style/fonts.variables";
import { black, grey } from "assets/style/variables";
import { size } from "assets/style/global-variables";

export const GoodsDetailContainer = styled.section`
  min-width: 320px;
  margin: 0;

  .image-field {
    margin-top: 20px;
  }
`;

export const DeleteGoodButton = styled.button`
  display: flex;
  margin-left: auto;
  flex-direction: row;
  column-gap: 10px;
  margin-bottom: 20px;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 10px 20px;
  border-radius: 5px;
  &:hover {
    background-color: #eee;
  }

  svg {
    width: 20px;
  }

  span {
    font-family: "Futura PT", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
`;

export const GoodNameLine = styled.div`
  width: 100%;
  margin: 10px 0;
  padding: 0 20px;
`;

export const BodyLine = styled.div`
  ${(props) =>
    props.column
      ? `
      width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`
      : `width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 20px;
  padding: 0 20px;`}
`;

export const SimpleBodyLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  & > *:nth-child(4) {
    order: 1;
  }

  @media screen and (min-width: ${size.laptop}) {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 2fr minmax(10rem, 1fr);
    column-gap: 20px;
    padding: 0 20px;

    & > *:nth-child(4) {
      order: default;
    }
  }
`;

export const BodyLineColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    width: 100%;
  }
`;

export const GoodsDetailHeader = styled.section`
  display: flex;
  justify-content: flex-end;
  grid-gap: 30px;
  align-items: center;
  margin-bottom: 16px;
`;

export const CashButtonContainer = styled.section`
  position: absolute;
  top: -10px;
  left: 0;
  display: flex;
  grid-gap: 15px;
  align-items: baseline;

  button {
    width: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: 500;
    font-size: 20px;
    font-family: ${font_demi};
  }

  @media screen and (min-width: ${size.laptop}) {
    position: static;
    margin-top: 30px;

    button {
      width: 330px;
    }
  }
`;

export const CashInformationSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const CashInformationItem = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 15px;
  font-weight: 400;
  font-family: ${font_book};
  .title {
    font-size: 18px;
    color: ${black};
    margin-bottom: 5px;
  }
  .sub_title {
    font-size: 14px;
    color: ${grey};
  }
`;

export const SelectImageButton = styled.label`
  display: flex;
  align-items: flex-end;
  column-gap: 10px;
  background-color: #fff;
  border: 1px solid rgba(92, 92, 92, 0.07);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 10px 20px;
  width: auto;
  max-width: 180px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #fefefe;
  }
  span {
    font-size: 16px;
    color: #111e29;
    font-family: "Futura PT", sans-serif;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;

  > div {
    position: absolute;
    top: 3px;
    right: 8px;
    padding: 5px;
    cursor: pointer;
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;

export const Image = styled.img`
  display: block;
  width: min-content;
  max-width: 300px;
  height: 100px;
  border-radius: 5px;
`;

export const ErrorBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid rgb(218, 78, 59);
  background-color: rgb(254, 242, 244);
  border-radius: 10px;
  padding: 20px;
  span {
    text-align: center;
    color: rgb(218, 78, 59);
    font-family: "Futura Demi", sans-serif;
  }

  button {
    padding: 5px 10px;
    border: none;
    color: white;
    background-color: rgb(218, 78, 59);
    border-radius: 5px;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: rgb(197, 78, 59);
    }
  }
`;
