import QRIcon from "../../../../components/ui/QRIcon";

import { IconContainer } from "./Icon.styles";

const IconWithQR = ({ icon, backgroundColor, color, style }) => {
  const Icon = icon;

  const qrIconStyle = {
    position: "absolute",
    top: "-0.35rem",
    left: "-0.35rem",
    width: "1.25rem",
    height: "1.25rem",
  };

  return (
    <IconContainer backgroundColor={backgroundColor} style={style}>
      <QRIcon color={color} style={qrIconStyle} />
      <Icon color={color} />
    </IconContainer>
  );
};

export default IconWithQR;
