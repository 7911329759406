import {
  CHANGE_TEXT,
  REFRESH,
  SET_ACTIVE,
  SET_BUTTON,
  SET_LEFT,
  SET_SUBTITLE,
  SET_MOBILE_BACK_BUTTON,
  SET_BOUND,
} from "store/header/header.types";

export default function ChangeTitle(title, fn) {
  fn({
    type: CHANGE_TEXT,
    payload: title,
  });
}

export function Refresh(fn) {
  fn({
    type: REFRESH,
  });
}

export function ChangeLeft(fn) {
  fn({
    type: SET_LEFT,
  });
}

export function ChangeSubtitle(subtitle, fn) {
  fn({
    type: SET_SUBTITLE,
    payload: subtitle,
  });
}

export function ChangeButton(bool, fn) {
  fn({
    type: SET_BUTTON,
    payload: bool,
  });
}

export function ChangeActive(bool, fn) {
  fn({
    type: SET_ACTIVE,
    payload: bool,
  });
}

export function SetMobileBackButton(boolean, fn) {
  fn({
    type: SET_MOBILE_BACK_BUTTON,
    payload: boolean,
  });
}

export function SetBound(boolean, fn) {
  fn({
    type: SET_BOUND,
    payload: boolean,
  });
}
