import React from "react";

import { LocalLoaderContainer } from "./local-loader.styles";

const LocalLoader = () => {
  return (
    <LocalLoaderContainer>
      <div className="lds-dual-ring" />
    </LocalLoaderContainer>
  );
};

export default LocalLoader;
