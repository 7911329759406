import { SET_ALERT_STATE, DROP_ALERT_STATE } from "./alert_types";

const initialState = {
  opened: false,
  type: "done",
  title: "",
  subtitle: "",
  fnSubmit: () => {},
  fnClose: () => {},
  btnCloseLabel: "",
  btn_close: "",
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERT_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case DROP_ALERT_STATE:
      return initialState;
    default:
      return state;
  }
};

export default alertReducer;
