import styled from "styled-components";
import { gradient_green } from "assets/style/variables";

const DashBoard = styled.div`
  display: flex;
  flex-direction: row;
  background: ${gradient_green};
  min-height: 100vh;

  button:hover {
    cursor: pointer;
  }
`;
export { DashBoard };
