import styled from "styled-components";

import {
  font_book,
  font_demi,
  font_medium,
} from "assets/style/fonts.variables";
import { black, grey, white, red } from "assets/style/variables";

const TransactionDetailsContainer = styled.section``;

const SectionInform = styled.section`
  background: #f6f7fa;
  border: 1px solid #edeff5;
  box-sizing: border-box;
  border-radius: 22px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  ${(props) =>
    props.column
      ? `
    flex-direction: column;
    align-items: flex-start;
  `
      : ""}

  ${(props) =>
    props.mobile
      ? `
        padding: 10px;
      `
      : ""}

  .section-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    justify-content: space-between;
  }
`;
const DivContainer = styled.div`
  display: flex;
  grid-gap: ${(props) => (props.column ? "0px" : "17px")};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
`;
const ProductContainer = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 25px;
`;

const DivContainerText = styled.div`
  margin: 0;
`;
const Li = styled.li`
  list-style: none;
  display: flex;
  align-items: flex-end;
  grid-gap: 5px;
`;
const DivContainerIcon = styled.div`
  margin: 0;
`;
const Title = styled.p`
  font-family: ${(props) => (props.bold ? font_medium : font_book)};
  font-size: 18px;
  color: ${({ error }) => (error ? red : black)};

  ${(props) => (props.noTextWrap ? "white-space: nowrap;" : "")}
`;
const SubTitle = styled.p`
  font-family: ${font_book};
  font-size: 14px;
  color: ${grey};

  ${(props) => (props.noTextWrap ? "white-space: nowrap;" : "")}
`;
const ProductText = styled.div`
  width: 100%;
`;
const Total = styled.span`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${black};
  font-weight: 400;
  font-family: ${(props) => (props.bold ? font_demi : font_book)};
  font-size: 20px;
  text-align: left;
  margin-bottom: 10px;
`;

const Table = styled.table`
  width: 100%;
  padding-top: 16px;
  border-top: 1px solid #f1f1f1;
`;

const HeaderTr = styled.tr`
  display: grid;
  grid-template-columns: 5fr 1fr;
  th {
    font-size: 14px;
    font-family: ${font_book};
    color: ${grey};
    text-align: left;
    font-weight: 400;
  }
`;

const BodyTr = styled.tr`
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  background: ${white};
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin: 12px 0px;
  padding: 15px;
  grid-gap: 40px;
  td {
    font-family: ${font_medium};
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .discount-label {
    text-align: right;
    color: #403d3d;
    font-family: "Futura Book", sans-serif;
    font-size: 13px;
  }

  .excise-label {
    text-align: left;
    color: #403d3d;
    font-family: "Futura Book", sans-serif;
    font-size: 13px;
  }
`;

const ButtonContainer = styled.div`
  align-self: flex-start;
  box-sizing: border-box;
  border-radius: 22px;
  padding: 2.25rem 0 25px 25px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    min-width: 230px;
  }

  .ring,
  .ring div {
    width: 20px;
    height: 20px;
    border-width: 4px;
  }
`;

export {
  DivContainer,
  SectionInform,
  TransactionDetailsContainer,
  ButtonContainer,
  BodyTr,
  HeaderTr,
  Table,
  Total,
  ProductText,
  Title,
  SubTitle,
  DivContainerIcon,
  ProductContainer,
  DivContainerText,
  Li,
};
