import { useSelector } from "react-redux";
import { Chart, ChartHeader, ChartTotals, ChartTotal } from "./components";
import {
  ChartContainer,
  ChartInfoContainer,
  ChartScrollContainer,
  ChartWrapper,
} from "../../Statistics.styles";
import { mobileVersion } from "store/main_table/main_table.slicer";
import useChartState from "./useChartState";
import Preloader from "components/additional/PreLoader/Preloader";

const TotalsByTranTypes = () => {
  const isMobile = useSelector(mobileVersion) < 1024;
  const { chartTotals, chartState, onCheckChartOption, data, isLoading } =
    useChartState();

  return (
    <>
      <ChartHeader
        chartState={chartState}
        onCheckChartOption={onCheckChartOption}
        isLoading={isLoading}
      />
      <ChartContainer>
        <ChartScrollContainer>
          {isLoading ? (
            <ChartInfoContainer>
              <Preloader />
            </ChartInfoContainer>
          ) : (
            <>
              {data && data.length > 0 ? (
                <>
                  <ChartTotal total={chartTotals.total} />
                  <ChartWrapper isMobile={isMobile}>
                    <Chart data={data} />
                  </ChartWrapper>
                  <ChartTotals totals={chartTotals.totals} />
                </>
              ) : (
                <ChartInfoContainer>{"відсутні дані"}</ChartInfoContainer>
              )}
            </>
          )}
        </ChartScrollContainer>
      </ChartContainer>
    </>
  );
};

export default TotalsByTranTypes;
