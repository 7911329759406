import { font_book, font_medium } from "assets/style/fonts.variables";
import { black, tiffany } from "assets/style/variables";
import styled from "styled-components";

const rdrEdge = `
position: absolute;
background: rgba(13, 119, 111, 0.24);
height: 75%;
top: 5px;
width: 61%;
content: "";
z-index: 0;
`;

export const CalendarContainer = styled.div`
  position: absolute;
  z-index: 5;
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 20px;
  transition: all 0s;
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper)
    .rdrDayHovered
    .rdrDayNumber:after {
    border: 0;
  }
  .rdrDateDisplayItem {
    border-radius: 12px;
  }
  .rdrDateDisplayWrapper {
    background: none;
  }
  .rdrDateInput input {
    color: ${black};
    font-weight: 400;
    font-family: ${font_book};
    font-size: 20px;
  }
  .rdrDateDisplayItemActive {
    border-color: ${tiffany};
  }
  span.rdrMonthAndYearPickers {
    display: none;
  }
  .rdrMonthName {
    color: ${black};
    font-family: ${font_medium};
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  .rdrWeekDay {
    display: none;
  }
  .rdrDayNumber {
    font-weight: 400;
    font-size: 14px;
    color: #222730;
    font-family: ${font_book};
  }

  .rdrDayNumber span::after {
    display: none;
  }

  .rdrMonthAndYearWrapper {
    position: absolute;
    left: 30px;
    right: 30px;
    top: 80px;
  }

  .rdrDayStartPreview.rdrDayEndPreview {
    z-index: -1;
  }
  span.rdrInRange,
  .rdrDayStartPreview,
  .rdrDayEndPreview,
  .rdrDayInPreview {
    border: none;
    background: rgba(13, 119, 111, 0.24);
  }

  .rdrDayStartPreview.rdrDayEndPreview,
  .rdrStartEdge,
  .rdrEndEdge {
    background: currentColor;
    position: absolute;
    top: 1px;
    left: 4px;
    right: 4px;
    bottom: 1px;
    border-radius: 50%;
  }
  .rdrDayHovered {
    border: none;
  }
  button.rdrDay {
    position: relative;
  }

  span.rdrStartEdge::after {
    ${rdrEdge}
    height: 26px;
    top: 4px;
  }
  span.rdrEndEdge::after {
    ${rdrEdge};
    left: -4px;
    height: 78%;
    top: 4px;
  }
  span.rdrStartEdge.rdrEndEdge::after {
    display: none;
  }

  .calendar-selection {
    .rdrMonthAndYearWrapper {
      top: 20px;
    }

    .rdrSelected {
      left: 6px;
      right: 4px;
      top: 2px;
      bottom: 2px;
      border-radius: 80%;
    }

    .rdrSelected {
      left: 6px;
      right: 4px;
      top: 2px;
      bottom: 2px;
      border-radius: 50%;
    }
  }
`;

export const CalendarContainerTable = styled(CalendarContainer)`
  position: relative;
  max-width: 820px;
  .rdrMonth {
    width: 32.667em;
  }

  .rdrDayStartPreview.rdrDayEndPreview,
  .rdrStartEdge,
  .rdrEndEdge {
    left: 9px;
    right: 9px;
  }
  /* .rdrStartEdge {
    top: 1px;
    bottom: 1px;
  } */

  /* .rdrEndEdge {
    top: -2px;
    bottom: -2px;
  } */

  span.rdrStartEdge::after {
    height: 26px;
    top: 4px;
    width: 27px;
  }

  span.rdrEndEdge::after {
    height: 26px;
    top: 4px;
    left: -9px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  grid-gap: 15px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  width: 50%;
  margin-left: auto;
  button {
    padding: 10px 37px;
  }
  .button_green {
    font-size: 14px;
    font-family: ${font_book};
    font-weight: 400;
  }
`;
