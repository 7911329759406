import styled from "styled-components";

import { size } from "assets/style/global-variables";

export const FiscalDaySettingsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 100%;
  padding: 10px;
  padding-top: 0;

  @media screen and (max-width: ${size.tablet}) {
    padding: 10px 3px;
    padding-top: 0;
  }

  .autoclosing-modal-content {
    width: 100%;
    max-height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }

  .heading {
    width: 100%;
    margin-top: 10px;
    padding: 0 10px;

    .title {
      color: #444;
      font-family: "Futura Demi", sans-serif;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .close-button {
    position: absolute;
    top: 0px;
    right: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      width: 20px;
      heigth: 20px;
    }
  }

  .modal-day-content {
    margin-top: 20px;
    width: calc(100% - 20px);
    margin-left: 10px;
    flex-shrink: 1;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bababa;
      border-radius: 10px;
    }
  }
  .modal-day-content * {
    font-family: "Futura Book", sans-serif;
    font-weight: 400;
  }

  .block {
    width: 100%;
    overflow: auto;
    border: 1px solid #eee;
    border-radius: 5px;
    background-color: #f5f5f5;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 5px;

    label {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .block:not(:last-child) {
    margin-bottom: 10px;
  }

  .block-warning {
    font-family: "Futura Book", sans-serif;
    font-size: 14px;
    color: red;
  }

  .block-title {
    color: #444;
    font-family: "Futura Demi", sans-serif;
    font-weight: 600;
    font-size: 16px;
  }

  .block-sub-title {
    color: #444;
    font-size: 15px;
  }

  .block-radio-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
  }

  .checkbox-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 3px;
  }

  .block-checkbox-item {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    column-gap: 20px;

    @media screen and (max-width: ${size.tablet}) {
      column-gap: 5px;
    }

    input[type="checkbox"] {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }

    label {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      span.week-day-label {
        width: 80px;
      }
    }
  }

  button.copy-date {
    border: none;
    background-color: #e3e3e3;
    border-radius: 3px;
    padding: 5px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      width: 20px;
    }
  }
`;

export const WarningText = styled.span`
  font-family: "Futura Book", sans-serif;
  font-size: 14px;
  color: red;
`;
