import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Preloader from "components/additional/PreLoader/Preloader";

import { Article, Section, Footer, ButtonContent } from "./NewsDetails.styles";
import {
  Button,
  ButtonSecondary,
} from "../../../../../components/forms/Button/Button.styles";

import useNewsDetail from "./useNewsDetail";

import { ReactComponent as DeleteIcon } from "../SelectedSettings/delete.svg";
import { BiArrowBack, BiEnvelope } from "react-icons/bi";

const NewsDetails = () => {
  const news = useSelector((state) => state.news_table);
  const params = useParams();
  const paramId = +params.id;
  let item = news.item;

  if (!(item && item.id === paramId)) {
    item = news.state.find((item) => item.id === paramId);
  }

  const message = item || {};
  const { message_preview: messagePreview } = message;
  const {
    contentRef,
    loading,
    messageDate,
    goBack,
    leaveMessageNotRead,
    deleteMessage,
  } = useNewsDetail(message);

  return (
    <Article>
      <header>
        <span className="message_time">{messageDate}</span>
        <h2>{messagePreview}</h2>
      </header>
      {loading ? (
        <Preloader style={{ marginTop: "0", flexGrow: "1" }} />
      ) : (
        <Section className="content" aria-label="content" ref={contentRef} />
      )}
      <Footer>
        <Button onClick={goBack}>
          <ButtonContent>
            <BiArrowBack style={{ fontSize: "1.5rem" }} /> Закрити
          </ButtonContent>
        </Button>
        <ButtonSecondary onClick={leaveMessageNotRead}>
          <ButtonContent>
            <BiEnvelope style={{ fontSize: "1.5rem" }} /> Залишити непрочитаним
          </ButtonContent>
        </ButtonSecondary>
        <ButtonSecondary onClick={deleteMessage}>
          <ButtonContent>
            <DeleteIcon /> Видалити
          </ButtonContent>
        </ButtonSecondary>
      </Footer>
    </Article>
  );
};

export default NewsDetails;
