import { black, grey, white } from "assets/style/variables";
import { font_book, font_demi } from "assets/style/fonts.variables";
import styled from "styled-components";

const MainContainer = styled.div`
  position: relative;
`;

const MainCaption = styled.p`
  position: absolute;
  z-index: 1;
  top: -0.525rem;
  left: 1.5rem;
  font-family: ${font_book};
  font-size: 0.75rem;
  color: ${grey};
  background-color: ${white};
`;

const SubTotalContainer = styled.section`
  display: flex;
  border: 1px solid #edeff5;
  box-sizing: border-box;
  border-radius: 22px;
  padding: 11px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 14px 8px;
`;

const SubTotalItem = styled.section`
  display: flex;
  grid-gap: 10px;
  align-items: center;
  align-self: stretch;
  width: 274px;
  color: ${black};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

const TextLine = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 0.25em;
  font-family: ${font_book};
  font-size: 16px;
  line-height: 1;
`;

const Title = styled.p`
  font-family: ${font_book};
  font-size: 16px;
  color: ${black};
`;

const StrongTitle = styled.p`
  font-family: ${font_book};
  font-size: 16px;
  font-weight: bold;
  color: ${black};
`;

const Value = styled.p`
  font-size: 20px;
  font-family: ${font_demi};
  white-space: nowrap;
`;

const Price = styled.span`
  font-size: 14px;
  font-family: ${font_book};
`;

const Sign = styled.span`
  font-size: 14px;
  font-family: ${font_book};
`;

export {
  TextContainer,
  SubTotalContainer,
  SubTotalItem,
  Title,
  StrongTitle,
  Value,
  Price,
  TextLine,
  MainContainer,
  MainCaption,
  Sign,
};
