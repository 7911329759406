import React, { useState } from 'react';

import {
  // OFFERTA_FOP_FISCAL_HREF,
  OFFERTA_FOP_FISCAL_HTML,
} from 'utils/constants/server.constants';

import { ModalTitle, ModalButtons, ModalBody } from 'ui/Modal/modal.styles';
import { OpenFiscalModalContainer, ContainedButton, OutlinedButton, CheckboxRow } from './styles';

const OpenFiscalModal = (props) => {
  const { onClose, onAccept } = props;

  const [acceptButtonIsDisabled, setAcceptButtonIsDisabled] = useState(true);

  return (
    <OpenFiscalModalContainer>
      <ModalTitle>Увімкнути фіскалізацію?</ModalTitle>
      <ModalBody style={{ flexGrow: 2, display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
        <iframe frameBorder='0' src={OFFERTA_FOP_FISCAL_HTML}></iframe>
        <CheckboxRow>
          <input type='checkbox' onChange={() => setAcceptButtonIsDisabled((val) => !val)} />
          <span>
            Я приймаю умови Публічного договору (оферти) про надання доступу до он-лайн сервісу
            "Фіскалізація
          </span>
        </CheckboxRow>
      </ModalBody>
      <ModalButtons>
        <ContainedButton onClick={onAccept} disabled={acceptButtonIsDisabled}>
          {'Увімкнути'}
        </ContainedButton>
        <OutlinedButton onClick={onClose}>Відміна</OutlinedButton>
      </ModalButtons>
    </OpenFiscalModalContainer>
  );
};

export default OpenFiscalModal;
