import styled from "styled-components";

import { font_book, font_demi } from "assets/style/fonts.variables";
import { size } from "assets/style/global-variables";
import { STAT_TYPES } from "./constants";

export const StatSection = styled.section`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: ${font_book};
`;

export const StatHeader = styled.h2``;

export const ChartContainer = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: auto;
`;

export const ChartScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: auto;
  min-width: 720px;

  &.perMonthByYears {
    padding-right: 1rem;
  }
`;

export const ChartWrapper = styled.div`
  flex-grow: 1;
  padding-top: ${({ statType }) =>
    statType === STAT_TYPES.BY_TERMINAL ? "2rem" : "0"};

  justify-content: stretch;
  align-items: stretch;
  font-size: 12px;
  min-height: 400px;
  overflow-x: auto;
  overflow-y: hidden;

  /*@media screen and (max-width: 1023.98) {
    max-height: ${({ isMobile }) =>
    isMobile ? "auto" : "calc(100vh - 500px)"};
  }*/

  @media screen and (min-width: ${size.laptop}) {
    max-height: ${({ statType }) =>
      statType === STAT_TYPES.BY_TERMINAL
        ? "calc(100vh - 320px)"
        : "calc(100vh - 420px)"};
  }

  @media screen and (min-width: ${size.laptopL}) {
    max-height: ${({ statType }) =>
      statType === STAT_TYPES.BY_TERMINAL
        ? "calc(100vh - 200px)"
        : "calc(100vh - 340px)"};
  }
`;

export const ChartHeader = styled.div`
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  font-family: ${font_demi};
`;

export const ChartTitle = styled.h2`
  position: relative;
  font-size: 26px;

  &.byTerminals,
  &.byTranTypes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
  }
`;

export const ChartTitleType = styled.div``;
export const ChartTitleDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  flex-wrap: wrap;
`;

export const ChartInfoContainer = styled.span`
  margin: auto;
`;

export const ChartSwitchContainer = styled.span`
  display: inline-flex;
  margin-left: 1rem;
`;

export const CHART_SWITCH_LABEL_SMALL_STYLE = {
  fontFamily: font_book,
  fontSize: "16px",
  fontWeight: "normal",
  whiteSpace: "nowrap",
};

export const CHART_SWITCH_LABEL_NORMAL_STYLE = {
  fontFamily: font_book,
  whiteSpace: "nowrap",
};
