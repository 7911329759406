import React, { useMemo, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";

import { getUUID } from "utils/helpers/getUUID";
import VersionBage from "ui/VersionBage/VersionBage";
import { PAGE_NAME } from "../../../utils/constants/ui";

import {
  DASHBOARD_ROUTE,
  TRANSACTION_ROUTE,
  BANK_DAY_ROUTE,
  FISCAL_DAY_ROUTE,
  TERMINALS_ROUTE,
  CASHIERS_ROUTE,
  SETTING_FOP_ROUTE,
  SUPPORT_ROUTE,
  QUESTION_ROUTE,
  GOODS_ROUTE,
  NEWS_ROUTE,
  CASH_DAY_ROUTE,
  STATS_ROUTE,
} from "utils/constants/routes.constants";
import { VERSION_TEXT } from "utils/constants/server.constants";

import { MobileMenuContainer } from "./style";
import logoImg from "../../../assets/images/logo.png";
import {
  HomeIcon,
  TransactionIcon,
  BankIcon,
  FiskalIcon,
  TerminalIcon,
  CashierIcon,
  GoodsIcon,
  MoneyIcon,
  NewsIcon,
  StatsIcon,
} from "components/layouts/Navigation/Navigation.icons.js";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close_white.svg";

const MobileMenu = ({ opened, setOpened, newMessages }) => {
  const { pathname } = useLocation();

  const MAIN_LINKS = useMemo(
    () => [
      {
        id: getUUID(),
        label: "Головна",
        icon: <HomeIcon className="icon" />,
        route: DASHBOARD_ROUTE,
      },
      {
        id: getUUID(),
        label: PAGE_NAME.statistics,
        icon: <StatsIcon className="icon" />,
        route: STATS_ROUTE,
      },
      {
        id: getUUID(),
        label: "Журнал транзакцій",
        icon: <TransactionIcon className="icon" />,
        route: TRANSACTION_ROUTE,
      },
      {
        id: getUUID(),
        label: "Банківські бізнес-дні",
        icon: <BankIcon className="icon" />,
        route: BANK_DAY_ROUTE,
      },
      {
        id: getUUID(),
        label: "Фіскальні зміни",
        icon: <FiskalIcon className="icon" />,
        route: FISCAL_DAY_ROUTE,
      },
      {
        id: getUUID(),
        label: "Готівка в касах",
        icon: <MoneyIcon className="icon" />,
        route: CASH_DAY_ROUTE,
      },
      {
        id: getUUID(),
        label: "Управління терміналами",
        icon: <TerminalIcon className="icon" />,
        route: TERMINALS_ROUTE,
      },
      {
        id: getUUID(),
        label: "Управління касирами",
        icon: <CashierIcon className="icon" />,
        route: CASHIERS_ROUTE,
      },
      {
        id: getUUID(),
        label: "Управління товарами",
        icon: <GoodsIcon className="icon" />,
        route: GOODS_ROUTE,
      },
      {
        id: getUUID(),
        label: "Повідомлення",
        icon: <NewsIcon className="icon news-icon" />,
        route: NEWS_ROUTE,
      },
    ],
    []
  );

  const ADDITIONAL_LINKS = useMemo(
    () => [
      {
        id: getUUID(),
        label: "Налаштування Торговця",
        route: SETTING_FOP_ROUTE,
      },
      { id: getUUID(), label: "Зв’язатися з підтримкою", route: SUPPORT_ROUTE },
      { id: getUUID(), label: "Питання та відповіді", route: QUESTION_ROUTE },
    ],
    []
  );

  useEffect(() => {
    setOpened(false);
  }, [pathname]);

  return (
    <MobileMenuContainer opened={opened}>
      <div className="mobile-menu-header">
        <div className="logo-and-version">
          <Link to="/" className="logo">
            <img src={logoImg} alt="logo" />
          </Link>
          {VERSION_TEXT() && <VersionBage />}
        </div>
        <button className="close-button" onClick={() => setOpened(false)}>
          <CloseIcon />
        </button>
      </div>
      <ul className="main-links">
        {MAIN_LINKS.map(
          (el) =>
            !el.disabled && (
              <NavLink
                key={el.id}
                to={el.route}
                exact
                className={cn("main-link", {
                  active: pathname === el.route,
                  not_active: pathname !== el.route,
                })}
              >
                <li>
                  {el.route !== NEWS_ROUTE && (
                    <>
                      {el.icon} <span>{el.label}</span>
                    </>
                  )}
                  {el.route === NEWS_ROUTE && (
                    <>
                      <figure className="news">
                        <NewsIcon className="icon news-icon" />
                        {newMessages.length > 0 && (
                          <figcaption className="news-count">
                            {newMessages.length}
                          </figcaption>
                        )}
                      </figure>
                      <span> {el.label}</span>
                    </>
                  )}
                </li>
              </NavLink>
            )
        )}
      </ul>
      <ul className="additional-links">
        {ADDITIONAL_LINKS.map((el) => (
          <NavLink
            key={el.id}
            exact
            to={el.route}
            className={cn("additional-link", {
              active: pathname === el.route,
              not_active: pathname !== el.route,
            })}
          >
            <li>{el.label}</li>
          </NavLink>
        ))}
      </ul>
    </MobileMenuContainer>
  );
};

export default MobileMenu;
