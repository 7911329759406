import React from "react";

// styles
import { GoodsTypeSwitcher, SelectingButton } from "./styles";

const GoodsTypeSwitcherComponents = (props) => {
  const { panelMode, setPanelMode } = props;

  return (
    <GoodsTypeSwitcher>
      <SelectingButton
        selected={panelMode === "goods"}
        onClick={() => setPanelMode("goods")}
      >
        Перелік товарів
      </SelectingButton>
      <SelectingButton
        selected={panelMode === "categories"}
        onClick={() => setPanelMode("categories")}
      >
        Категорії товарів
      </SelectingButton>
    </GoodsTypeSwitcher>
  );
};

export default GoodsTypeSwitcherComponents;
