import Preloader from "components/additional/PreLoader/Preloader";
import {
  TableContainer,
  TerminalText,
  TerminalDescribe,
  TerminalTitle,
  Td,
} from "components/layouts/Table/Table.styles";
import { createBrowserHistory } from "history";
import {
  TrMain,
  Button,
} from "pages/Panels/Bank/BankOpen/BankOpen.table.styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { fetchServer, closedDay } from "store/bank_table/bank_table.actions";
import { bOpenState, serverLoading } from "store/bank_table/bank_table.slicer";
import Alert from "ui/Alert/Alert";
import Refresh from "ui/Refresh/Refresh";
import { toDetailPage } from "../bank-helpers";

import { setBankFilteringState } from "store/transaction_table/transaction_table.actions";

export default function Table() {
  const location = useLocation();
  const state = useSelector(bOpenState);
  const isServerLoaded = useSelector(serverLoading);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const [ptname, setPtname] = useState(null);

  useEffect(() => {
    localStorage.setItem("bank_exit_with_drop_state", 1);

    const browHistory = createBrowserHistory();

    if (!location.state) {
      dispatch(fetchServer(true));
      browHistory.replace();
    }

    return () => {
      if (!!localStorage.getItem("bank_exit_with_drop_state")) {
        localStorage.removeItem("bank-bdd");
        localStorage.removeItem("bank-ptname");
        localStorage.removeItem("bank-batch");
        dispatch(setBankFilteringState({ field: "ptname", value: null }));
        dispatch(setBankFilteringState({ field: "bdd", value: null }));
        dispatch(setBankFilteringState({ field: "batch", value: null }));
      }
    };
  }, []);

  function closedBankDay() {
    dispatch(closedDay(ptname));
    setShowAlert(false);
  }

  if (isServerLoaded) return <Preloader />;

  if (!isServerLoaded && state.length === 0)
    return (
      <TerminalTitle style={{ marginTop: "20px" }}>
        Відсутні відкриті бізнес-дні.
        <Refresh fn={() => dispatch(fetchServer(true))} />
      </TerminalTitle>
    );

  return (
    <>
      <Refresh fn={() => dispatch(fetchServer(true))} />
      {showAlert ? (
        <Alert
          type={"remove"}
          title={"Закрити бізнес-день?"}
          subtitle={""}
          fnClose={() => setShowAlert(false)}
          fnSubmit={() => closedBankDay()}
          btn_close="закрити"
        />
      ) : null}
      <TableContainer>
        <thead>
          <TrMain>
            <th>Термінал</th>
            <th>Бд відкрито</th>
            <th>Номер пачки</th>
            <th>Транзакцій</th>
            <th>Сума на картку</th>
            <th></th>
          </TrMain>
        </thead>
        <tbody>
          {state.map((item, key) => {
            return (
              <TrMain
                column
                onClick={() => toDetailPage(item, "opened", dispatch, history)}
                key={key}
              >
                <Td>
                  <TerminalText>
                    <TerminalTitle>{item.ptdesctiption}</TerminalTitle>
                    <TerminalDescribe>
                      {item.device_name}, Terminal ID: {item.ptname}
                    </TerminalDescribe>
                    <TerminalDescribe
                      error={
                        item.batch_status_id !== 1 &&
                        item.batch_status_id !== 4 &&
                        item.batch_status_id !== 5
                      }
                    >
                      {", "}
                      {item.batch_status_name}
                    </TerminalDescribe>
                  </TerminalText>
                </Td>
                <Td>
                  <TerminalText>
                    <TerminalTitle>
                      {item.bdd
                        ? `${item.bdd.slice(6, 8)}.${item.bdd.slice(
                            4,
                            6
                          )}.${item.bdd.slice(0, 4)}`
                        : null}
                    </TerminalTitle>
                  </TerminalText>
                </Td>
                <Td>
                  <TerminalText>
                    <TerminalTitle>{item.batch}</TerminalTitle>
                  </TerminalText>
                </Td>
                <Td>
                  <TerminalText>
                    <TerminalTitle>{item.count_total}</TerminalTitle>
                  </TerminalText>
                </Td>
                <Td>
                  <TerminalText>
                    <TerminalTitle>
                      {" "}
                      {(Math.round(item.amount_total * 100) / 100).toFixed(
                        2
                      )}₴{" "}
                    </TerminalTitle>
                  </TerminalText>
                </Td>
                <Td>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setPtname(item.ptname);
                      setShowAlert(true);
                    }}
                  >
                    Закрити
                  </Button>
                </Td>
              </TrMain>
            );
          })}
        </tbody>
      </TableContainer>
    </>
  );
}
