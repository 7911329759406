import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Preloader from "components/additional/PreLoader/Preloader";
import Alert from "ui/Alert/Alert";
import Refresh from "ui/Refresh/Refresh";
import {
  fetchServer,
  setPagination,
  setServerStatus,
  setItem,
  updateFilteringStateItem,
} from "store/goods_table/goods_table.actions";
import { panelRef as reduxPanelRef } from "store/main_table/main_table.slicer";
import { pagination } from "store/goods_table/goods_table.slicer";
import { GOODS_ROUTE } from "utils/constants/routes.constants";
import { GoodsTable } from "api";

// components
import TableHeaders from "components/layouts/Table/GridHeaders/Table.headers";
import SelectSettings from "../SelectedSettings/SelectedSettings";

// functions
import { flatCategories } from "../../service";

// assets
import { ReactComponent as SearchIcon } from "assets/icons/search-small.svg";

// styles
import {
  TrMain,
  SearchTh,
  GoodsTableContainer,
  TableContainer,
  PriceContainer,
  MainPrice,
  PriceWithoutTax,
  CalculationContainer,
  NameContainer,
  ImageContainer,
} from "pages/Panels/Goods/components/GoodsTable/styles";
import { TerminalTitle } from "components/layouts/Table/GridHeaders/GridTable.styles";

import {
  UNIT_SELECTOR_TYPE,
  CATS_SELECTOR_TYPE,
} from "utils/constants/table.headers.constants";

const NAME_FIELD_TYPE = "name";
const CATEGORY_FIELD_TYPE = "category";
const CALCULATION_FIELD_TYPE = "calculations";
const INSIDE_CODE_FIELD_TYPE = "inside_code";
const PRICE_CODE_FIELD_TYPE = "price";

export default function Table({
  goods,
  setGoods,
  categories,
  setCategories,
  localLoading,
  setLocalLoading,
  isTableFull,
  setTableFull,
  isRequestWorking,
  setIsRequestWorking,
  triggerReload,
  triggerReloadFilter,
  triggerFilterReloadFunction,
  filteringState,
  setFilteringState,
  selectedGoods,
  setSelectedGoods,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const panelRef = useSelector(reduxPanelRef);

  const [taxes, setTaxes] = useState(null);
  const [calculations, setCalculations] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Видалити товар?");
  const [alertType, setAlertType] = useState("remove");

  let paginationIndex = useSelector(pagination);

  useEffect(() => {
    dispatch(setPagination(0));
    setTableFull(false);

    if (!goods) {
      dispatch(
        fetchServer({
          goods,
          setGoods,
          setTableFull,
          setIsRequestWorking,
          setLocalLoading,
          filterState: filteringState,
        })
      );
    }

    if (!calculations || !taxes || !categories) {
      const goodsAPI = new GoodsTable();

      setLocalLoading(true);

      Promise.all([
        goodsAPI.getUnits(),
        goodsAPI.getTaxes(),
        goodsAPI.getAllCategoriesForCreateProd(),
      ])
        .then((globalRes) => {
          const calculations = globalRes[0]?.units;
          const taxes = globalRes[1];
          const categories = flatCategories(globalRes[2]);

          setCalculations(calculations);
          setTaxes(taxes);
          setCategories(categories);
        })
        .finally(() => setLocalLoading(false));
    }
  }, [triggerReload, triggerReloadFilter]);

  useEffect(() => {
    if (location.state && location.state.successCreated) {
      setAlertType("done");
      setAlertTitle("Ви успішно створили новий товар");
      setShowAlert(true);
      resetServer();
    } else if (location.state && location.state.successChanged) {
      setAlertType("done");
      setAlertTitle("Ви успішно змінили товар");
      setShowAlert(true);
      resetServer();
    } else if (location.state && location.state.successDeleted) {
      setAlertType("done");
      setAlertTitle("Ви успішно видалили товар");
      setShowAlert(true);
      resetServer();
    }

    if (!!location.state) {
      history.push({ pathname: "/dash/goods", state: null });
    }
  }, [location]);

  function toDetailPage(item) {
    dispatch(setItem(item));
    history.push(GOODS_ROUTE + "/" + item.id);
  }

  function checkAll(checkedAll) {
    if (checkedAll) return;

    setSelectedGoods(goods.map((el) => el.id));
  }

  async function deleteStack() {
    const goodsAPI = new GoodsTable();
    try {
      await goodsAPI.deleteStack({ ids: selectedGoods });
      setShowAlert(true);
      setAlertTitle("Операція успішна");
      setAlertType("done");
    } catch (error) {
      console.log(error);
    }
    resetServer();
  }

  async function transerStackToCategory({ categoryId }) {
    const goodsAPI = new GoodsTable();
    try {
      await goodsAPI.transerStackToCategory({ ids: selectedGoods, categoryId });
      setShowAlert(true);
      setAlertTitle("Операція успішна");
      setAlertType("done");
    } catch (error) {
      console.log(error);
    }
    resetServer();
  }

  function resetServer() {
    setGoods(null);
    setSelectedGoods([]);
    dispatch(setServerStatus(false));
    dispatch(setPagination(0));
    setTableFull(false);
    dispatch(
      fetchServer({
        goods: null,
        setGoods,
        setTableFull,
        setIsRequestWorking,
        setLocalLoading,
        filterState: filteringState,
      })
    );
  }

  function fetchMoreItems() {
    dispatch(setPagination(++paginationIndex));
    dispatch(
      fetchServer({
        goods,
        setGoods,
        setTableFull,
        setIsRequestWorking,
        setLocalLoading,
        filterState: filteringState,
      })
    );
  }

  return (
    <>
      <Refresh fn={resetServer} />
      <SelectSettings
        active={selectedGoods.length > 0}
        checkedAllActive={goods?.length > 0}
        selectedLength={selectedGoods.length}
        checkedAll={
          !isNaN(goods?.length) && selectedGoods?.length === goods?.length
        }
        categories={categories}
        onCancel={() => setSelectedGoods([])}
        onTransfer={(categoryId) => transerStackToCategory({ categoryId })}
        onDelete={deleteStack}
        onCheckAll={checkAll}
      />
      {showAlert ? (
        <Alert
          type={alertType}
          title={alertTitle}
          subtitle={""}
          fnClose={() => setShowAlert(false)}
          fnSubmit={() => setShowAlert(false)}
          btn_close="видалити"
        />
      ) : null}
      <TableContainer>
        <GoodsTableContainer>
          <TrMain>
            <div className="head-cell"></div>
            <div className="head-cell">
              <SearchTh>
                <SearchIcon />
                <input
                  placeholder={filteringState[NAME_FIELD_TYPE].placeholder}
                  value={filteringState[NAME_FIELD_TYPE].value}
                  onChange={(event) =>
                    updateFilteringField({
                      field: NAME_FIELD_TYPE,
                      event,
                      value: null,
                    })
                  }
                />
              </SearchTh>
            </div>
            <div className="head-cell head-cell__photo">
              <span>Фото</span>
            </div>

            <div className="head-cell" style={{ margin: "0 3px" }}>
              <TableHeaders
                state={categories ? categories : []}
                choose_state={
                  filteringState.category.value
                    ? filteringState.category.value.id
                    : null
                }
                type={CATS_SELECTOR_TYPE}
                fn={(val) => {
                  const value = categories.find((el) => el.id === val);
                  updateFilteringField({
                    field: CATEGORY_FIELD_TYPE,
                    event: null,
                    value: value,
                  });
                  dispatch(
                    updateFilteringStateItem({ field: "category", value })
                  );
                  triggerFilterReloadFunction();
                }}
                fn_reset={() => {
                  updateFilteringField({
                    field: CATEGORY_FIELD_TYPE,
                    event: null,
                    value: null,
                  });
                  dispatch(
                    updateFilteringStateItem({ field: "category", value: null })
                  );
                  triggerFilterReloadFunction();
                }}
                text="Категорія"
              />
            </div>

            <div className="head-cell" style={{ margin: "0 3px" }}>
              <TableHeaders
                state={calculations ? calculations : []}
                choose_state={
                  filteringState.calculations.value
                    ? filteringState.calculations.value.unit_id
                    : null
                }
                type={UNIT_SELECTOR_TYPE}
                fn={(val) => {
                  const value = calculations.find((el) => el.unit_id === val);
                  updateFilteringField({
                    field: CALCULATION_FIELD_TYPE,
                    event: null,
                    value: value,
                  });
                  dispatch(
                    updateFilteringStateItem({
                      field: "calculation",
                      value: value,
                    })
                  );
                  triggerFilterReloadFunction();
                }}
                fn_reset={() => {
                  updateFilteringField({
                    field: CALCULATION_FIELD_TYPE,
                    event: null,
                    value: null,
                  });
                  dispatch(
                    updateFilteringStateItem({
                      field: "calculation",
                      value: null,
                    })
                  );
                  triggerFilterReloadFunction();
                }}
                text="Одиниці виміру"
              />
            </div>
            <div className="head-cell">
              <SearchTh>
                <SearchIcon />
                <input
                  placeholder={
                    filteringState[INSIDE_CODE_FIELD_TYPE].placeholder
                  }
                  value={filteringState[INSIDE_CODE_FIELD_TYPE].value}
                  onChange={(event) =>
                    updateFilteringField({
                      field: INSIDE_CODE_FIELD_TYPE,
                      event,
                      value: null,
                    })
                  }
                />
              </SearchTh>
            </div>
            <div className="head-cell">
              <SearchTh>
                <SearchIcon />
                <input
                  placeholder={
                    filteringState[PRICE_CODE_FIELD_TYPE].placeholder
                  }
                  value={filteringState[PRICE_CODE_FIELD_TYPE].value}
                  onChange={(event) =>
                    updateFilteringField({
                      field: PRICE_CODE_FIELD_TYPE,
                      event,
                      value: null,
                    })
                  }
                />
              </SearchTh>
            </div>
          </TrMain>
          {isTableFull && !goods && !localLoading && !isRequestWorking && (
            <TerminalTitle style={{ marginTop: "20px" }}>
              Товарів не знайдено.
            </TerminalTitle>
          )}
          {goods && goods.length === 0 && !localLoading && (
            <TerminalTitle style={{ marginTop: "20px" }}>
              Товарів не знайдено.
            </TerminalTitle>
          )}
          {goods && goods.length !== 0 && (
            <InfiniteScroll
              pageStart={paginationIndex}
              initialLoad={false}
              useWindow={true}
              loadMore={fetchMoreItems}
              hasMore={!isTableFull && !isRequestWorking}
              loader={<Preloader key={goods.length} />}
              getScrollParent={() => panelRef}
            >
              {goods
                ? goods.map((item) => {
                    const tax = taxes
                      ? taxes.filter((el) => el.taxesId === item.taxId)[0]
                      : null;
                    const excise =
                      !item.taxCodes || item.taxCodes.length < 2
                        ? null
                        : taxes &&
                          taxes.find(
                            (el) =>
                              el.taxesCode ===
                              item.taxCodes[item.taxCodes.length - 1]
                          );
                    const isTaxExist = !!item.taxId && !!taxes;

                    const taxString = tax?.taxesName;
                    const exciseString = excise?.taxesName;

                    let taxPrice;
                    let excisePrice;
                    if (excise) {
                      excisePrice = (
                        item.price -
                        item.price / (excise.taxesPercentage / 100 + 1)
                      ).toFixed(2);
                      taxPrice = (
                        item.price / (excise.taxesPercentage / 100 + 1) -
                        item.price /
                          (excise.taxesPercentage / 100 + 1) /
                          (tax.taxesPercentage / 100 + 1)
                      ).toFixed(2);
                    } else if (tax) {
                      taxPrice = (
                        item.price -
                        item.price / (tax.taxesPercentage / 100 + 1)
                      ).toFixed(2);
                    }

                    const unitString =
                      calculations &&
                      calculations.find((el) => el.unit_id === item.unitId)
                        ? calculations.find((el) => el.unit_id === item.unitId)
                            .unit_uk
                        : "";

                    const categoryString =
                      categories &&
                      categories.find(
                        (el) =>
                          el.id ===
                          item?.productMerchantProductToCategories[0]
                            ?.categoryId
                      )?.name;

                    const isGoodSelected = !!selectedGoods.includes(item.id);

                    return (
                      <TrMain
                        column
                        onClick={() => toDetailPage(item)}
                        key={item.id}
                      >
                        <div
                          className="body-cell"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={isGoodSelected}
                            onChange={() => {
                              if (isGoodSelected) {
                                setSelectedGoods(
                                  [...selectedGoods].filter(
                                    (el) => el !== item.id
                                  )
                                );
                              } else {
                                setSelectedGoods(
                                  [...selectedGoods].concat([item.id])
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="body-cell">
                          <NameContainer>{item.title}</NameContainer>
                        </div>
                        <div className="body-cell body-cell__photo">
                          <ImageContainer>
                            <img src={item.image} alt="" />
                          </ImageContainer>
                        </div>
                        <div className="body-cell">
                          <CalculationContainer>
                            {categoryString}
                          </CalculationContainer>
                        </div>
                        <div className="body-cell">
                          <CalculationContainer>
                            {unitString}
                          </CalculationContainer>
                        </div>
                        <div className="body-cell">
                          <CalculationContainer>
                            {item.internalCode || "-"}
                          </CalculationContainer>
                        </div>
                        <div className="body-cell">
                          <PriceContainer>
                            <MainPrice>
                              {(Math.round(item.price * 100) / 100).toFixed(2)}₴
                            </MainPrice>
                            {isTaxExist && (
                              <PriceWithoutTax>
                                <span style={{ fontSize: "12px" }}>
                                  {taxString}
                                </span>
                                {tax && tax.taxesPercentage > 0 && (
                                  <span>{taxPrice}₴</span>
                                )}
                              </PriceWithoutTax>
                            )}
                            {excisePrice && (
                              <PriceWithoutTax>
                                <span style={{ fontSize: "12px" }}>
                                  {exciseString}
                                </span>
                                <span>{excisePrice}₴</span>
                              </PriceWithoutTax>
                            )}
                          </PriceContainer>
                        </div>
                      </TrMain>
                    );
                  })
                : null}
            </InfiniteScroll>
          )}
          {/* {localLoading && <Preloader />} */}
        </GoodsTableContainer>
      </TableContainer>
    </>
  );

  function updateFilteringField({ field, event, value }) {
    const newFileteringState = { ...filteringState };

    if ([CATEGORY_FIELD_TYPE, CALCULATION_FIELD_TYPE].includes(field)) {
      newFileteringState[field].value = value;
      return setFilteringState(newFileteringState);
    }

    const newInputValue = event.target.value;

    const newFieldValue = newFileteringState[field].formatFunction({
      oldValue: newFileteringState[field].value,
      newValue: newInputValue,
    });

    newFileteringState[field].value = newFieldValue;
    return setFilteringState(newFileteringState);
  }
}
