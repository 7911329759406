import { useSelector } from "react-redux";

import ChartHeader from "./components/ChartHeader/ChartHeader";
import Chart from "./components/Chart/Chart";
import ChartTotals from "../../components/ChartTotals/ChartTotals";
import { mobileVersion } from "store/main_table/main_table.slicer";
import {
  ChartContainer,
  ChartInfoContainer,
  ChartScrollContainer,
  ChartWrapper,
} from "../../Statistics.styles";
import useChartState from "./useChartState";
import Preloader from "components/additional/PreLoader/Preloader";

const TotalsByYears = () => {
  const isMobile = useSelector(mobileVersion) < 1024;
  const { chartTotals, chartState, data, isLoading } = useChartState();

  return (
    <>
      <ChartHeader
        total={chartTotals.total}
        chartState={chartState}
        isLoading={isLoading}
      />
      <ChartContainer>
        <ChartScrollContainer>
          {isLoading ? (
            <ChartInfoContainer>
              <Preloader />
            </ChartInfoContainer>
          ) : (
            <>
              <ChartWrapper isMobile={isMobile}>
                <Chart data={data} />
              </ChartWrapper>
              <ChartTotals totals={chartTotals.totals} />
            </>
          )}
        </ChartScrollContainer>
      </ChartContainer>
    </>
  );
};

export default TotalsByYears;
