import { useDispatch } from "react-redux";
import { setAlertState, dropAlertState } from "store/alert/alert_actions";

const linkStyle = { color: "#179c92" };

const TipsSubtitle = () => (
  <>
    <p>
      Сервіс надається через{" "}
      <a
        style={linkStyle}
        href="https://tips.oschadbank.ua/"
        target="_blank"
        rel="noreferrer"
      >
        програму «Чайові» від Ощадбанку
      </a>{" "}
      при проведенні оплати карткою. Ця програма дозволяє проводити операції із
      зарахуванням коштів винагороди на картку будь якого банку.
    </p>
    <p>
      Більше інформації в{" "}
      <a
        style={linkStyle}
        href="https://online.oschadpay.ua/m/instrNewOschadPAY.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Інструкції по ОщадPAY
      </a>
      . Розділ “Сервіс «Чайові»“.
    </p>
  </>
);

const CashSubtitle = () => (
  <>
    <p>
      В сервісі ОщадPAY можна проводити оплати за допомогою готівки. У цьому
      випадку в ОщадPAY виконується реєстрація такої транзакції з обліком
      готівкових коштів в касі терміналу. Якщо увімкнений режим «Фіскалізація»,
      додатково відбувається взаємодія з сервером податкової служби.
    </p>
    <p>
      Усі взаєморозрахунки з покупцем при оплаті готівкою відбуваються під
      контролем та за відповідальності торговця. ОщадPAY не виконує зарахувань
      коштів на рахунок торговця при такій формі оплати.
    </p>
    <p>
      Більше інформації в{" "}
      <a
        style={linkStyle}
        href="https://online.oschadpay.ua/m/instrNewOschadPAY.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Інструкції по ОщадPAY
      </a>
      . Розділ “Оплата готівкою“.
    </p>
  </>
);

const CashlessSubtitle = () => (
  <>
    <p>
      В сервісі ОщадPAY можна проводити транзакції, що виконані іншими формами
      оплати. У цьому випадку в ОщадPAY виконується лише реєстрація такої
      транзакції. Якщо увімкнений режим «Фіскалізація», додатково відбувається
      взаємодія з сервером податкової служби.
    </p>
    <p>
      До категорії «ІНШЕ» можна віднести оплати, що виконані через переказ
      коштів з картки на картку, виконання транзакції через систему електронної
      комерції (інтернет оплати), безготівкове зарахування коштів на рахунок
      торговця тощо.
    </p>
    <p>
      Усі взаєморозрахунки з покупцем при оплаті ме тодом «ІНШЕ» відбуваються
      під контролем та за відповідальності торговця. ОщадPAY не виконує
      зарахувань коштів на рахунок торговця при такій формі оплати.
    </p>
    <p>
      Більше інформації в{" "}
      <a
        style={linkStyle}
        href="https://online.oschadpay.ua/m/instrNewOschadPAY.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Інструкції по ОщадPAY
      </a>
      . Розділ “Безготівкова оплата «ІНШЕ»“.
    </p>
  </>
);

const QRSubtitle = () => (
  <>
    <p>
      QR-оплата – додатковий спосіб проведення платежів в сервісі ОщадPAY. Цей
      спосіб оплати дозволяє покупцю самостійно виконати оплату покупки через
      сервіс інтернет-оплати, використовуючи будь які свої картки, у тому числі
      і такі, які не мають безконтактних інтерфейсів оплати.
    </p>
    <p>
      Також через QR-оплату торговець може побудувати дистанційні канали продажу
      та взаємодії з покупцем.
    </p>
    <p>
      Більше інформації в{" "}
      <a
        style={linkStyle}
        href="https://online.oschadpay.ua/m/instrQR-payments.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Інструкції по роботі з QR-оплатами
      </a>
      .
    </p>
  </>
);

const TemplateSubtitle = () => (
  <>
    <p>
      Сервіс дозволяє налаштувати шаблон призначення платежу (з можливістю
      показувати призначення платежу в чеку). Призначення платежу буде корисне
      торговцям, яким обов’язково потрібно фіксувати опис транзакції. Опис можна
      відображати в транзакційному чеку клієнта, а також опис фіксується у
      потранзакційній виписці, зробленій в онлайн кабінеті ОщадPAY.
    </p>
    <p>
      Більше інформації в{" "}
      <a
        style={linkStyle}
        href="https://online.oschadpay.ua/m/instrNewOschadPAY.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Інструкції по ОщадPAY
      </a>
      . Розділ “Налаштування шаблонів призначення платежу“.
    </p>
  </>
);

export const InfoType = {
  TIPS: { key: 1, component: TipsSubtitle, height: "500px", width: "550px" },
  CASH: { key: 2, component: CashSubtitle, height: "625px", width: "550px" },
  CASHLESS: {
    key: 3,
    component: CashlessSubtitle,
    height: "720px",
    width: "525px",
  },
  QR: {
    key: 4,
    component: QRSubtitle,
    height: "560px",
    width: "550px",
  },
  TEMPLATE: {
    key: 5,
    component: TemplateSubtitle,
    height: "550px",
    width: "550px",
  },
};

const useInfo = () => {
  const dispatch = useDispatch();

  const showInfo = (infoType = "TIPS") => {
    const { component: TypeComponent, height, width } = InfoType[infoType];
    dispatch(
      setAlertState({
        show: true,
        type: "info",
        title: "Зверніть увагу!",
        subtitle: <TypeComponent />,
        subtitleClassName: "info",
        fnClose: () => dispatch(dropAlertState()),
        height,
        width,
      })
    );
  };

  return showInfo;
};

export default useInfo;
