import styled from "styled-components";
import { grey } from "assets/style/variables";

export const StatSelectorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const ChartToolbarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;

  .terminal-icon path {
    stroke: ${grey};
  }
`;
