import React from "react";

import { CancelButton } from "./modal.styles";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const TimesCancelButton = (props) => {
  return (
    <CancelButton {...props}>
      <CloseIcon />
    </CancelButton>
  );
};

export default TimesCancelButton;
