import {
  GOODS_SERVER_INIT,
  GOODS_STATE,
  GOODS_SEARCH,
  GOODS_PAGINATION,
  GOODS_LOCAL_INIT,
  GOODS_ITEM,
  DROP_FILTERING_STATE,
  UPDATE_FILTERING_STATE_ITEM,
} from "./goods_table.types";

const initialFilteringState = {
  name: null,
  category: null,
  calculation: null,
  inside_code: null,
  price: null,
};

const initialState = {
  server_init: false,
  local_init: false,
  state: null,
  pagination: 0,
  search: null,
  item: null,
  taxes: null,

  filteringState: {
    ...initialFilteringState,
  },
};

export default function goodsTableReducer(state = initialState, action) {
  switch (action.type) {
    case GOODS_SERVER_INIT:
      return { ...state, server_init: action.payload };
    case GOODS_LOCAL_INIT:
      return { ...state, local_init: action.payload };
    case GOODS_STATE:
      return { ...state, state: action.payload };
    case GOODS_PAGINATION:
      return { ...state, pagination: action.payload };
    case GOODS_SEARCH:
      return { ...state, search: action.payload };
    case GOODS_ITEM:
      return { ...state, item: action.payload };
    case DROP_FILTERING_STATE:
      return { ...state, filteringState: { ...initialFilteringState } };
    case UPDATE_FILTERING_STATE_ITEM:
      const { field, value } = action.payload;
      return {
        ...state,
        filteringState: { ...state.filteringState, [field]: value },
      };
    default:
      return state;
  }
}

export const serverStatus = (state) => state.goods_table.server_init;
export const localStatus = (state) => state.goods_table.local_init;
export const searchVal = (state) => state.goods_table.search;
export const pagination = (state) => state.goods_table.pagination;
export const item = (state) => state.goods_table.item;
