import { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  TotalsByTranTypes,
  TotalsByYears,
  TotalsPerMonthByYears,
  TotalsByTerminals,
} from "./charts";
import ChangeTitle from "utils/helpers/changetitle.helpers";
import { StatSection } from "./Statistics.styles";
import { useHeader } from "store/header/header.context";
import { STAT_TYPES } from "./constants";
import { selectCurrentStatType } from "store/statistics/stat_slicer";
import { PAGE_NAME } from "../../../utils/constants/ui";

const Viewers = {
  [STAT_TYPES.BY_TRAN_TYPE]: TotalsByTranTypes,
  [STAT_TYPES.BY_YEAR]: TotalsByYears,
  [STAT_TYPES.BY_MONTH]: TotalsPerMonthByYears,
  [STAT_TYPES.BY_TERMINAL]: TotalsByTerminals,
};

const Statistics = () => {
  const { dispatch } = useHeader();
  const type = useSelector(selectCurrentStatType);
  const StatViewer = Viewers[type];

  useEffect(() => {
    ChangeTitle(PAGE_NAME.statistics, dispatch);
  }, [dispatch]);

  return <StatSection>{StatViewer && <StatViewer />}</StatSection>;
};

export default Statistics;
