import { font_medium, font_book } from "assets/style/fonts.variables";
import { black, grey } from "assets/style/variables";
import styled from "styled-components";

const MainPanelContainer = styled.section`
  .subtotal {
    margin-top: 25px;
    margin-bottom: 20px;
  }
`;

const TrMain = styled.tr`
  display: grid;
  grid-template-columns: 5fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 6px;
  margin: 12px 0;
  cursor: pointer;

  ${(props) =>
    props.column
      ? `
      padding: 17px;
      align-items: center;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      justify-items: center;`
      : "null"}
`;

const ButtonContainer = styled.div`
  display: flex;
  grid-gap: 12px;
`;

const Terminal = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 22px;
`;

const TerminalText = styled.div`
  font-size: 14px;
  text-align: ${(props) => (props.right ? "right" : "left")};
`;
const TerminalTitle = styled.p`
  color: ${black};
  font-family: ${font_medium};
`;

const TerminalDescribe = styled.span`
  color: ${grey};
  line-height: 20px;
  font-family: ${font_book};
`;

const TerminalImage = styled.img`
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 43px;
  height: 43px;
`;

export {
  TerminalImage,
  MainPanelContainer,
  TrMain,
  ButtonContainer,
  Terminal,
  TerminalText,
  TerminalTitle,
  TerminalDescribe,
};
