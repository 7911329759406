import React from "react";
import { Total } from "./ChartTotal.styles";
import { getCurrencyFormatter } from "../../helpers";

const formatter = getCurrencyFormatter(2);

const ChartTotal = ({ total }) => {
  return (
    <Total>
      <span className="title">Загальна сума:</span>
      {` ${formatter.format(total)}`}
    </Total>
  );
};

export default ChartTotal;
