import styled from "styled-components";
import { grey_light as grey, white } from "assets/style/variables";

export const TooltipContainer = styled.div`
  background-color: ${white};
  padding: 0.5rem;
  border: 1px solid ${grey};

  .label {
    margin-bottom: 0.5rem;
  }

  .values {
    list-style: none;

    li:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }
`;
