import React from "react";
import ReactTooltip from "react-tooltip";

import { TooltipLabel } from "ui/TooltipLabel/tooltip-label.styles";
import { ButtonSecondaryBorder } from "components/forms/Button/Button.styles";
import { Button } from "components/forms/Button/Button.styles";
import { TerminalButtons } from "../TerminalDetail.styles";

const TerminalEditButtons = ({
  isMobileVersion,
  isEComm,
  isEdit,
  ppo,
  editTrue,
  editFalse,
  setIsEdit,
}) => {
  return (
    <TerminalButtons isMobile={isMobileVersion}>
      {isEdit ? (
        <>
          <button
            onClick={
              (!ppo || Number(ppo) < 4000000000) && ppo !== ""
                ? () => {}
                : editTrue
            }
            data-tip
            data-for="edit-ppo-tooltip"
            className={`ocshad-pay-solid-button ${
              (!ppo || Number(ppo) < 4000000000) && ppo !== "" ? "disabled" : ""
            }`}
          >
            Підтвердити зміни
          </button>
          {(!ppo || Number(ppo) < 4000000000) && ppo !== "" && (
            <ReactTooltip id="edit-ppo-tooltip" place="top" effect="solid">
              <TooltipLabel>
                {"ФН ПРРО має бути більше 4 000 000 000"}
              </TooltipLabel>
            </ReactTooltip>
          )}
          <ButtonSecondaryBorder onClick={editFalse}>
            Скасувати
          </ButtonSecondaryBorder>
        </>
      ) : (
        <>
          {!isEComm && (
            <Button onClick={() => setIsEdit(true)}>Редагувати</Button>
          )}
        </>
      )}
    </TerminalButtons>
  );
};

export default TerminalEditButtons;
