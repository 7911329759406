import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// components
import Alert from "ui/Alert/Alert";
import ErrorComponent from "../components/ErrorComponent/ErrorComponent";
import Modal from "ui/Modal/Modal";
import CloseFiscalDayModal from "./CloseFiscalDayModal/CloseFiscalDayModal";
import Preloader from "components/additional/PreLoader/Preloader";

// functions
import {
  closedDay,
  bankDetailItem,
  fetchOpenFiscalInfo,
} from "store/fiscal_table/fiscal_table.actions";
import {
  bOpenState,
  fiscalOpenedListError,
  fiscalClosedFilteringState,
} from "store/fiscal_table/fiscal_table.slicer";
import { stateFiskal } from "store/transaction_table/transaction_table.slicer";

import { FISCAL_DAY_ROUTE } from "utils/constants/routes.constants";
import { DISABLED_FISCALIZATION } from "utils/constants/server.constants";
import { CashAPI } from "api/cash.api";

// styles
import {
  TableContainer,
  TerminalText,
  TerminalDescribe,
  TerminalTitle,
  Td,
} from "components/layouts/Table/Table.styles";
import {
  TrMain,
  Button,
} from "pages/Panels/Fiscal/FiscalOpen/FiscalOpen.table.styles";

import useCloseShift from "./hooks/useCloseShift";
import { setGlobalLoading } from "store/main_table/main_table.actions";

const Table = () => {
  const state = useSelector(bOpenState);
  const fiskalListError = useSelector(fiscalOpenedListError);
  const { startDate: closedStartDate, endDate: closedEndDate } = useSelector(
    fiscalClosedFilteringState
  );
  const loading = useSelector((state) => state.fiscal_table.fiscal_loading);
  const fiscalizationActive =
    useSelector(stateFiskal) && !DISABLED_FISCALIZATION;

  const dispatch = useDispatch();
  const history = useHistory();

  const [alertState, setAlertState] = useState({
    show: false,
    title: "",
    subtitle: "",
    type: "remove",
    fnSubmit: () => {},
  });
  const [rro, setRro] = useState(null);
  const [terminalId, setTerminalId] = useState(null);
  const [closeFiscalModalOpened, setCloseFiscalModalOpened] = useState(false);
  const [cashBalance, setCashBalance] = useState(null);
  const [shift, setShift] = useState(null);

  useEffect(() => {
    dispatch(fetchOpenFiscalInfo());
  }, [dispatch]);

  const { onCloseShift, tryCloseShift } = useCloseShift({
    setTerminalId,
    setRro,
    setupCloseFiscalDayModal,
    setAlertState,
  });

  function toDetailPage(item) {
    dispatch(bankDetailItem(item));

    history.push({
      pathname: FISCAL_DAY_ROUTE + "/" + item.terminalId,
      state: { status: "opened" },
    });
  }

  const closedBankDay = useCallback(
    function closedBankDay({ enterRro } = {}) {
      setAlertState({ show: false });
      setCloseFiscalModalOpened(false);
      dispatch(
        closedDay({
          rro: enterRro ? enterRro : rro,
          startDate: closedStartDate,
          endDate: closedEndDate,
        })
      );
    },
    [rro]
  );

  const makeInkassActionFunction = useCallback(
    async function makeInkassActionFunction() {
      setCloseFiscalModalOpened(false);

      const result = await CashAPI.makeInkassAction({
        terminalId,
        amount: cashBalance,
      });

      if (result && result.data && Number(result.data.errorCode) === 0) {
        setAlertState({
          show: true,
          title: '"Службова видача" пройшла успішно. Ви бажаєте закрити зміну?',
          subtitle: "",
          type: "done-button",
          fnSubmit: () => tryCloseShift(shift, closedBankDay),
          fnClose: () => setAlertState({ show: false }),
          btn_close: "закрити",
        });
      } else {
        setAlertState({
          show: true,
          type: "remove",
          title: "Помилка фіскалізації",
          subtitle:
            "Під час виконання службової видачі було отримано помилку фіскалізації. Ви бажаєте закрити зміну?",
          fnSubmit: () => tryCloseShift(shift, closedBankDay),
          btn_close: "закрити",
          fnClose: () => setAlertState({ show: false }),
          height: "450px",
        });
      }
    },
    [shift, terminalId, cashBalance]
  );

  async function setupCloseFiscalDayModal(shift) {
    const { terminalId } = shift;
    const errorHandler = (errorMessage) =>
      setAlertState({
        show: true,
        type: "block",
        title: errorMessage,
        subtitle: "",
        fnClose: () => setAlertState({ show: false }),
      });

    dispatch(setGlobalLoading(true));

    const cashBalance = await CashAPI.getFiscalTerminalCash({
      terminalId,
      errorHandler,
    });

    dispatch(setGlobalLoading(false));

    if (cashBalance === null) {
      return;
    }

    setCashBalance(cashBalance);

    if (cashBalance && Number(cashBalance) !== 0) {
      setCloseFiscalModalOpened(true);
    } else {
      setAlertState({
        show: true,
        title: "Закрити фіскальну зміну?",
        subtitle: "",
        type: "remove",
        fnSubmit: () => tryCloseShift(shift, closedBankDay),
        fnClose: () => setAlertState({ show: false }),
        btn_close: "закрити",
      });
    }
  }

  if (loading) {
    return <Preloader />;
  }

  if (fiskalListError) {
    return <ErrorComponent title={fiskalListError} />;
  }

  if (state && state.length === 0) {
    return (
      <TerminalTitle style={{ marginTop: "20px" }}>
        Відсутні відкриті фіскальні зміни.
      </TerminalTitle>
    );
  }

  return (
    <>
      {alertState.show && <Alert {...alertState} />}
      {closeFiscalModalOpened && (
        <Modal
          onClose={() => setCloseFiscalModalOpened(false)}
          style={{ width: "700px" }}
        >
          <CloseFiscalDayModal
            terminalId={terminalId}
            cashBalance={cashBalance}
            onCancel={() => setCloseFiscalModalOpened(false)}
            closedBankDay={() => {
              setCloseFiscalModalOpened(false);
              tryCloseShift(shift, closedBankDay);
            }}
            makeInkassActionFunction={makeInkassActionFunction}
          />
        </Modal>
      )}
      <TableContainer>
        <thead>
          <TrMain>
            <th>Термінал</th>
            <th>Касир</th>
            <th>Статус</th>
            <th>Транзакцій</th>
            <th>Сума</th>
            <th />
          </TrMain>
        </thead>
        <tbody>
          {state.map((item) => {
            return (
              <TrMain column onClick={() => toDetailPage(item)} key={item._id}>
                <Td>
                  <TerminalText>
                    <TerminalTitle>{item.terminalDescr}</TerminalTitle>
                    <TerminalDescribe>
                      Terminal ID: {item.terminalId}
                    </TerminalDescribe>
                  </TerminalText>
                </Td>
                <Td>
                  <TerminalText>
                    <TerminalTitle>{item.cashier}</TerminalTitle>
                  </TerminalText>
                </Td>
                <Td>
                  <TerminalText>
                    <TerminalTitle>Відкрита</TerminalTitle>
                  </TerminalText>
                </Td>
                <Td>
                  <TerminalText>
                    <TerminalTitle>{item.totalCount}</TerminalTitle>
                  </TerminalText>
                </Td>
                <Td>
                  <TerminalText>
                    <TerminalTitle>
                      {(Math.round(item.totalAmount * 100) / 100).toFixed(2)}₴
                    </TerminalTitle>
                  </TerminalText>
                </Td>
                <Td>
                  <Button
                    disabled={!fiscalizationActive}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShift(item);
                      onCloseShift(item);
                    }}
                  >
                    Закрити
                  </Button>
                </Td>
              </TrMain>
            );
          })}
        </tbody>
      </TableContainer>
    </>
  );
};

export default Table;
