import moment from "moment";

import { TransactionTable } from "api/index";
import { DEF_SORT, ASC_SORT, DESC_SORT } from "utils/constants/sort.constants";
import { UI_DATE_FORMAT } from "utils/constants/time";
import {
  // clean code
  SET_TRANSACTION_FILTERING_STATE,
  SET_BANK_FILTERING_STATE,
  SET_FISCAL_FILTERING_STATE,
  //
  TABLE_SET_ITEM,
  TABLE_SET_SERVER,
  TABLE_SET_LOCAL,
  TABLE_LOAD,
  TABLE_SERVER_LOAD,
  TABLE_CHANGE_BUTTON,
  TABLE_CHANGE_DATE,
  TABLE_SORT_TYPE,
  TABLE_SET_FILTER,
  TABLE_SET_SEARCH,
  TABLE_RESET,
  TABLE_SET_FILTER_TOTAL,
  TABLE_SET_PLUS_INDEX,
  TABLE_SET_FILTER_DATES,
  TABLE_SET_FILTER_METHOD,
  TABLE_SET_FILTER_RRN,
  TABLE_SORT_CASHIER,
  TABLE_SORT_TERMINAL,
  TABLE_SORT_DATE,
  TABLE_SORT_PAYMENT,
  TABLE_SORT_NUMBER,
  TABLE_SORT_TOTAL,
  TABLE_SET_FISKAL,
} from "./transaction_table.types";
// import { getFiscalStatus } from "pages/Panels/Transaction/constants";

function groupByDate(state) {
  const groups = state.reduce((groups, game) => {
    const date = game.ctime.split(" ")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(game);

    return groups;
  }, {});

  const groupArrays = Object.keys(groups)
    .map((date) => {
      return {
        date,
        items: groups[date],
      };
    })
    .sort((a, b) => {
      const aDate = moment(a.date, UI_DATE_FORMAT),
        bDate = moment(b.date, UI_DATE_FORMAT);

      if (aDate.isAfter(bDate)) {
        return -1;
      }
      if (aDate.isBefore(bDate)) {
        return 1;
      }
      return 0;
    });

  return groupArrays;
}

const calculateBdd = (startBdd) =>
  startBdd.indexOf(".") !== -1
    ? `${startBdd.slice(6, 10)}${startBdd.slice(3, 5)}${startBdd.slice(0, 2)}`
    : startBdd;

// const filterByFiscalStatus = (transactions, fiscalStatusFilter) => {
//   console.log({ transactions, fiscalStatusFilter });

//   if (!fiscalStatusFilter) return transactions;

//   const filter = [...fiscalStatusFilter];

//   const filteredTransactions = transactions
//     .map((item) => {
//       const filteredItems = item.items.filter(({ calculated_fiscal_status }) =>
//         filter.includes(calculated_fiscal_status)
//       );

//       return { ...item, items: filteredItems };
//     })
//     .filter((item) => item.items.length > 0);

//   return filteredTransactions;
// };

export const fetchServer = ({ value, businessDay, fiscalDay }) => {
  return async (dispatch, getState) => {
    const reduxState = getState();
    const rootStateLabel = !!businessDay
      ? "bank_filtering_state"
      : !!fiscalDay
      ? "fiscal_filtering_state"
      : "transactions_filtering_state";
    const rootState = reduxState.transaction_table[rootStateLabel];
    const {
      transaction_table: { start_index },
    } = reduxState;

    const server = new TransactionTable();

    if (fiscalDay) {
      const startTransactions = fiscalDay;
      let resultTransactions;
      dispatch(setFilter(true));

      resultTransactions = startTransactions.map((item) => {
        let ctime;
        if (item.ctime) {
          ctime = item.ctime.split("T");
        } else {
          ctime = item.transactionDate.split("T");
        }
        const regex = new RegExp("/", "g");

        const date = ctime[0].replace(regex, ".");

        return {
          ...item,
          ctime: `${date} ${ctime[1]}`,
        };
      });

      resultTransactions = groupByDate(resultTransactions);

      const {
        status,
        startDate,
        endDate,
        terminal_search,
        search_method_payment,
        rrn_search,
        amount_search,
      } = reduxState.transaction_table.fiscal_filtering_state;

      if ((startDate && !endDate) || (endDate && !startDate)) {
        const date = startDate || endDate;
        resultTransactions = resultTransactions.filter((el) => {
          return moment(el.date) === moment(date);
        });
      }

      if (startDate && endDate) {
        resultTransactions = resultTransactions.filter((el) => {
          return (
            moment(el.date) >= moment(startDate) &&
            moment(el.date) <= moment(endDate)
          );
        });
      }

      if (status) {
        resultTransactions = resultTransactions
          .map((el) => ({
            ...el,
            items: el.items.filter((el) => {
              if (
                status === 1 &&
                el.operation !== "RRN" &&
                el.operation !== "cancel"
              )
                return true;

              if (status === 3 && el.operation === "RRN") return true;

              if (status === 2) return false;

              return false;
            }),
          }))
          .filter((el) => el.items.length !== 0);
      }

      if (terminal_search) {
        resultTransactions = resultTransactions
          .map((el) => ({
            ...el,
            items: el.items.filter((el) => el.ptname === terminal_search),
          }))
          .filter((el) => el.items.length !== 0);
      }

      if (search_method_payment) {
        resultTransactions = resultTransactions
          .map((el) => ({
            ...el,
            items: el.items.filter((el) => {
              if (
                (search_method_payment === "SERVICE" &&
                  el.operation === "cash_inkass") ||
                el.operation === "cash_podkrep"
              )
                return true;

              if (search_method_payment === "CASH" && el.operation === "cash")
                return true;

              if (
                search_method_payment === "CONTACTLESS" &&
                el.operation === "contactless"
              )
                return true;

              return false;
            }),
          }))
          .filter((el) => el.items.length !== 0);
      }

      if (rrn_search) {
        resultTransactions = resultTransactions
          .map((el) => ({
            ...el,
            items: el.items.filter(
              (el) => el.rrn && el.rrn.includes(rrn_search)
            ),
          }))
          .filter((el) => el.items.length !== 0);
      }

      if (amount_search) {
        resultTransactions = resultTransactions
          .map((el) => ({
            ...el,
            items: el.items.filter((el) =>
              el.amount.toString().includes(amount_search.toString())
            ),
          }))
          .filter((el) => el.items.length !== 0);
      }

      dispatch(setServer(resultTransactions));
      dispatch(setLocal(resultTransactions));

      return;
    }

    dispatch(loadStateServer());
    dispatch(loadState());
    dispatch(setFilter(false));

    const state_server = reduxState.transaction_table.state_server;

    const dateFrom = rootState.startDate
      ? moment(rootState.startDate).format("YYYYMMDD")
      : null;
    const dateTo = rootState.endDate
      ? moment(rootState.endDate).format("YYYYMMDD")
      : null;
    const status = rootState.status;
    const search_rrn_val = rootState.rrn_search;
    const search_totals_val = rootState.amount_search;
    const search_method_val = rootState.search_method_payment;
    const search_terminal_val = rootState.terminal_search;
    const search_cashier_val = rootState.search_cashier;
    const fiscal_status = rootState.fiscal_status;

    server
      .get({
        businessDay: businessDay,
        fiscalDay: fiscalDay,
        start_index: start_index,
        search_text: value,
        ptname: businessDay
          ? rootState.ptname || localStorage.getItem("bank-ptname")
          : "ALL",
        bdd: businessDay
          ? rootState.bdd
            ? calculateBdd(rootState.bdd)
            : localStorage.getItem("bank-bdd")
            ? calculateBdd(localStorage.getItem("bank-bdd"))
            : null
          : null,
        batch: businessDay
          ? rootState.batch || localStorage.getItem("bank-batch")
          : null,
        dateFrom: dateFrom,
        dateTo: dateTo,
        tran_type: status,
        search_rrn: search_rrn_val,
        search_amount: search_totals_val,
        search_method_payment: search_method_val,
        search_terminal: search_terminal_val,
        search_cashier: search_cashier_val,
        fiscal_type: fiscal_status,
      })
      .then((response) => {
        localStorage.setItem(
          !businessDay ? "search_id" : "business_search_id",
          response.search_id
        );
        sessionStorage.setItem(
          !businessDay ? "search_id" : "business_search_id",
          response.search_id
        );
        if (response.transactions) {
          if (response.transactions.length < 10) dispatch(setFilter(true));
          response.transactions = response.transactions.map((item) => {
            const ctime = item.ctime.split(" ");
            const regex = new RegExp("/", "g");

            const date = ctime[0].replace(regex, ".");

            return {
              ...item,
              ctime: `${date} ${ctime[1]}`,
              // fiskal_status: item.fiskal_status || getFiscalStatus(item),
            };
          });

          let transaction = groupByDate(response.transactions);

          transaction =
            state_server.length > 0
              ? [...state_server, ...transaction]
              : transaction;
          dispatch(setServer(transaction));
          // dispatch(setLocal(filterByFiscalStatus(transaction, fiscal_status)));
          dispatch(setLocal(transaction));
          dispatch(setFilter(true));

          return dispatch(generationHeadersValues(transaction));
        } else if (state_server.length > 0) {
          return dispatch(setFilter(true));
        }
        dispatch(setFilter(true));
        dispatch(setServer([]));
        dispatch(setLocal([]));
        dispatch(generationHeadersValues([]));
      })
      .finally(() => {
        dispatch(loadStateServer());
        dispatch(loadState());
      });
  };
};

const generationHeadersValues = (state) => (dispatch) => {
  const state_search_date = state.map((item) => item.date.split(" ")[0]);
  let state_search_method = state
    .map((item) => {
      return item.items
        .filter((item) => item.payment_system !== null)
        .map((item) => item.payment_system.toString().toLowerCase());
    })
    .flat(1);
  state_search_method = [...new Set(state_search_method)];
  const state_search_rrn = state
    .map((item) => {
      return item.items
        .filter((item) => item.rrn !== null && item.transaction_id !== null)
        .map((item) => {
          return [item.rrn, item.transaction_id.toString()];
        })
        .flat(1);
    })
    .flat(1);

  const state_search_total = state
    .map((item) => {
      return item.items.map((item) => item.amount);
    })
    .flat(1);

  dispatch(setSearchDates(state_search_date));
  dispatch(setSearchMethod(state_search_method));
  dispatch(setSearchRrn(state_search_rrn));
  dispatch(setSearchTotals(state_search_total));
};

export const setServer = (state) => ({
  type: TABLE_SET_SERVER,
  payload: state,
});

export const setLocal = (state) => ({
  type: TABLE_SET_LOCAL,
  payload: state,
});

export const loadState = () => ({
  type: TABLE_LOAD,
});

export const loadStateServer = () => ({
  type: TABLE_SERVER_LOAD,
});

export const setActiveButton = (id) => ({
  type: TABLE_CHANGE_BUTTON,
  payload: id,
});

export const setPageIndex = (val) => ({
  type: TABLE_SET_PLUS_INDEX,
  payload: val,
});

export const setDates = (dates) => ({
  type: TABLE_CHANGE_DATE,
  payload: dates,
});

export const sortColumn = (key, type, fn) => (dispatch, getState) => {
  const {
    transaction_table: { state_local, state_server },
  } = getState();

  let sortState, sortType;
  switch (type) {
    case DEF_SORT:
      sortState = state_local.map((item) => {
        const sortItems = item.items.sort((a, b) => (a[key] > b[key] ? 1 : -1));
        return { ...item, items: sortItems };
      });
      sortType = ASC_SORT;
      break;
    case ASC_SORT:
      sortState = state_local.map((item) => {
        const sortItems = item.items.sort((a, b) => (a[key] > b[key] ? -1 : 1));
        return { ...item, items: sortItems };
      });
      sortType = DESC_SORT;
      break;

    default:
      sortState = [...state_server];
      sortType = DEF_SORT;
      break;
  }
  dispatch(loadState());
  dispatch(setLocal(sortState));
  dispatch(loadState());
  dispatch(fn(sortType));
};

export const setSortType = (type) => ({
  type: TABLE_SORT_TYPE,
  payload: type,
});
export const setSortCashier = (type) => ({
  type: TABLE_SORT_CASHIER,
  payload: type,
});
export const setSortTerminal = (type) => ({
  type: TABLE_SORT_TERMINAL,
  payload: type,
});
export const setSortDate = (type) => ({
  type: TABLE_SORT_DATE,
  payload: type,
});
export const setSortPayment = (type) => ({
  type: TABLE_SORT_PAYMENT,
  payload: type,
});
export const setSortNumber = (type) => ({
  type: TABLE_SORT_NUMBER,
  payload: type,
});
export const setSortTotal = (type) => ({
  type: TABLE_SORT_TOTAL,
  payload: type,
});

export const setSearch = (state) => ({
  type: TABLE_SET_SEARCH,
  payload: state,
});

export const resetTable = () => ({ type: TABLE_RESET });

// clean code

export const setTransactionFilteringState = ({ field, value }) => {
  return (dispatch) => {
    dispatch({
      type: SET_TRANSACTION_FILTERING_STATE,
      payload: { field, value },
    });
  };
};

export const setBankFilteringState = ({ field, value }) => {
  return (dispatch) => {
    dispatch({
      type: SET_BANK_FILTERING_STATE,
      payload: { field, value },
    });
  };
};

export const setFiscalFilteringState = ({ field, value }) => {
  return (dispatch) => {
    dispatch({
      type: SET_FISCAL_FILTERING_STATE,
      payload: { field, value },
    });
  };
};

export const setFilter = (bool) => ({
  type: TABLE_SET_FILTER,
  payload: bool,
});
export const setSearchDates = (state) => ({
  type: TABLE_SET_FILTER_DATES,
  payload: state,
});
export const setSearchMethod = (state) => ({
  type: TABLE_SET_FILTER_METHOD,
  payload: state,
});
export const setSearchRrn = (state) => ({
  type: TABLE_SET_FILTER_RRN,
  payload: state,
});
export const setSearchTotals = (state) => ({
  type: TABLE_SET_FILTER_TOTAL,
  payload: state,
});

export const setIsFiskalMenu = (state) => ({
  type: TABLE_SET_FISKAL,
  payload: state,
});
export const setItem = (state) => ({
  type: TABLE_SET_ITEM,
  payload: state,
});
