import { font_book } from 'assets/style/fonts.variables';
import { grey, white } from 'assets/style/variables';
import { ButtonSecondary } from 'components/forms/Button/Button.styles';
import styled from 'styled-components';

export const FiscalClosedContainer = styled.section`
  margin: 20px 0;
  .inform {
    display: flex;
    grid-gap: 30px;
    justify-content: flex-start;
  }
`;

export const TrMain = styled.tr`
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr 1fr;
  grid-gap: 6px;

  th {
    text-align: left;
    color: ${grey};
    font-family: ${font_book};
    font-size: 14px;
    font-weight: 400;
  }
  ${(props) =>
    props.column
      ? `
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 12px;
  margin-top: 12px;
  grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr 1fr;
  &:hover{
    cursor: pointer;
  }
  `
      : null}

  .calendar-container {
    left: -25vw;
  }
`;

export const Button = styled(ButtonSecondary)`
  font-size: 14px;
  font-family: ${font_book};
  font-weight: 400;
  background: ${white};
  border: 1px solid rgba(112, 120, 127, 0.2);
  border-radius: 10px;
  padding: 8px 30px;
`;
